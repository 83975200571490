import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: [],
  settings: {},
  tabIndex: 1,
  tabsArray: [],
  valuedContactFields: [],
  currentGuid: null,
  showCannotDeleteDialog: false,
  labelMap: {},
  showModal: false,
  status: null,
  errorMessage: "",
};

const myPageSlice = createSlice({
  name: "myPage",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setCurrentGuid(state, action) {
      state.currentGuid = action.payload;
    },
    deleteEventSubscription(state, action) {
      state.status = 200;
      const allEvents = action.payload.events.internalEvents;
      const guid = action.payload.guid;

      const filtered = allEvents.filter(
        (el) => el?.subDetail?.subscriptionGuid !== guid
      );

      state.components = [
        ...state.components,
        [
          ...(state.components.find(
            (v) => v.resourceType === "fi-my-page-my-events"
          ).data.internalEvents = [...filtered]),
        ],
      ];
      state.tabsArray = [
        ...state.tabsArray,
        {
          ...(state.tabsArray.find(
            (tab) => tab.id === 3
          ).components.internalEvents = [...filtered]),
        },
      ];
    },
    setSettings(state, action) {
      state.settings = action.payload;
    },
    setTabsArray(state, action) {
      // put here all the logic for building the labelMap
      state.tabsArray = action.payload;
    },
    setTabIndex(state, action) {
      state.tabIndex = action.payload;
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setShowCannotDeleteDialog(state, action) {
      state.showCannotDeleteDialog = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setValuedContactFields(state, action) {
      state.valuedContactFields = action.payload;
    },
    setLabelMap(state, action) {
      state.labelMap = Object.fromEntries(
        Object.entries(action.payload).filter(([key, _]) => key !== "undefined")
      );
    },
  },
});

export const myPageActions = myPageSlice.actions;
export default {
  name: myPageSlice.name,
  reducer: myPageSlice.reducer,
};
