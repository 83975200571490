import styled from "styled-components";

export const FocusContainer = styled.div`
  @media screen and (min-width: 1280px) {
    display: flex;
    gap: 32px;
    margin: auto;
    width: 1216px;
  }
`;

export const AsideSlot = styled.div`
  @media screen and (max-width: 1279px) {
    margin: 32px auto 0px;
    max-width: 384px;
  }
`;
