import {
  Wrapper,
  Overlay,
  BoxContainer,
  ConfirmButton,
  CloseButton,
} from "./style";

import { selectNoteModal } from "../../../../../pages/BusinessCardManagement/selectors";
import { useSelector } from "react-redux";
import { useState } from "react";

export function DialogNote({ confirmCallBack, closeCallBack }) {
  const data = useSelector(selectNoteModal());

  const userInfoStatus = useSelector(
    (state) => state.businessCardManagement.userInfo.status
  );
  const editByReviewer = useSelector(
    (state) => state.businessCardManagement.userInfo.editByReviewer
  );
  const [enable, setEnable] = useState(false);

  const handleEnable = (e) => {
    const currentValue = e.target.value !== "" ? true : false;
    setEnable(currentValue);
  };

  return (
    <Wrapper>
      <Overlay />
      <CloseButton onClick={closeCallBack}>
        <img src="/img/close-light.svg" width="40" height="40" />
      </CloseButton>
      <BoxContainer>
        <h2>{data?.title}</h2>
        {userInfoStatus !== 1 && <div>{data?.description}</div>}
        <textarea
          id="idNoteTxt"
          placeholder={data?.placeholder}
          rows="5"
          cols="33"
          onChange={(e) => handleEnable(e)}
        ></textarea>
        <ConfirmButton
          addMargin={userInfoStatus === 1}
          className={
            enable || (userInfoStatus === 1 && !editByReviewer)
              ? "submitButton"
              : "disabled"
          }
          onClick={(e) => confirmCallBack(e)}
        >
          {data?.buttonLabel}
        </ConfirmButton>
      </BoxContainer>
    </Wrapper>
  );
}
