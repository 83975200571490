import React from "react";
import PropTypes from "prop-types";
import { MapWrapper } from "./style";
import CtaIcon from "../../../ctaIcon/CtaIcon";
import linkTarget from "../../../../../utils/linkTarget";

const CafeteriaMap = ({ cafeteriaMap }) => {
  return (
    <MapWrapper>
      <div>{cafeteriaMap?.title}</div>
      <img src={cafeteriaMap?.imageMap} alt={cafeteriaMap?.imageAlt} />
      <p>{cafeteriaMap?.description}</p>
      <a
        href={cafeteriaMap?.linkHref}
        target={linkTarget(cafeteriaMap?.linkHref)}
      >
        {cafeteriaMap?.linkLabel}
        <CtaIcon showArrow={true} />
      </a>
    </MapWrapper>
  );
};
CafeteriaMap.propTypes = {
  cafeteriaMap: PropTypes.exact({
    imageMap: PropTypes.string,
    imageAlt: PropTypes.string,
    description: PropTypes.string,
    linkHref: PropTypes.string,
    title: PropTypes.string.isRequired,
    linkLabel: PropTypes.string.isRequired,
  }),
};

export default CafeteriaMap;
