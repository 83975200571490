import styled from "styled-components";

export const Wrapper = styled.h1`
  margin: 0px;
  font-size: 36px;
  line-height: 43px;
  @media screen and (max-width: 1279px) {
    font-size: 22px;
  }
`;
