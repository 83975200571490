import { useDispatch, useSelector } from "react-redux";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useEffect } from "react";
import { useRef } from "react";
// actions
import { SaveFavTool } from "../../../../../pages/Strumenti/actions";
import { toolsActions } from "../../../../../pages/Strumenti/toolsSlice";
// style
import { Wrapper, RemoveButton, SpanIcon } from "./style";
import { Link } from "react-router-dom";
import { RedirectTo } from "../../../redirectTo/RedirectTo";

const YOUR_ECARD_TOOL_ID = "ferrari-intranet:tools/your-ecard";

function ToolBox({ element, id, hideDnD, focusId }) {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.activeToken);
  const { language } = useSelector((state) => state.coreComponents);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const handleRemoveItem = () => {
    const currentToolBox = element;
    dispatch(SaveFavTool({ currentToolBox, token }));
  };

  const handleFontSize = (title) => {
    const words = title.split(" ");
    return words.find((element) => element?.length > 12);
  };

  const aReference = useRef(null);

  useEffect(() => {
    focusId && aReference.current.focus();
  }, []);

  const handleRedirectLink = () => {
    let linkResult = element?.linkHref;

    if (element?.id === YOUR_ECARD_TOOL_ID) {
      if (element?.isEcardActive) {
        linkResult = linkResult + "?isEcardActive=true";
      } else {
        linkResult = `/${language}/tools/ecard-request`;
      }
    }

    return linkResult;
  };

  return hideDnD ? (
    <Wrapper
      hide={hideDnD}
      onClick={() => dispatch(toolsActions.setShowToolsDialog(false))}
    >
      {element?.id === YOUR_ECARD_TOOL_ID ? (
        <Link to={handleRedirectLink()}>
          <SpanIcon>
            <img src={`${element?.image}`} />
          </SpanIcon>
          <span className="text">{element?.title}</span>
        </Link>
      ) : (
        <RedirectTo element={element} useRef={aReference}>
          <SpanIcon>
            <img src={`${element?.image}`} />
          </SpanIcon>
          <span
            className={
              handleFontSize(element?.title) ? "text dimension" : "text"
            }
          >
            {element?.title}
          </span>
        </RedirectTo>
      )}
    </Wrapper>
  ) : (
    <Wrapper
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      isDefault={element.isDefault}
    >
      {!element?.isDefault && <RemoveButton onClick={handleRemoveItem} />}
      <a>
        <SpanIcon>
          <img src={`${element?.image}`} />
        </SpanIcon>
        <span className="text">{element?.title}</span>
      </a>
    </Wrapper>
  );
}

export default ToolBox;
