import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./action";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import LayoutBusinessCardManagement from "../../components/UI/businessCardManagement/LayoutBusinessCardManagement";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import GenericError from "../GenericErrorPage";
import { MANAGEMENT_ECARD_SCOPE } from "./BusinessCardManagementSlice";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

export default function BusinessCardManagement({ path }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const components = useSelector(
    (state) => state.businessCardManagement.components
  );
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    dispatch(fetchComponents({ token, path }));
  }, [dispatch, path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(true));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData(MANAGEMENT_ECARD_SCOPE);
  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components && <LayoutBusinessCardManagement />
      )}
    </>
  );
}
