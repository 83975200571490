import React from "react";
import { Link } from "react-router-dom";
// style
import {
  Wrapper,
  CardCTA,
  CTATitle,
  SpanCtaIcon,
  CtaCircle,
  CtaWrapper,
  Title,
  Text,
  DescrWrapper,
  BackGroundText,
} from "./style";
import { useRef, useState } from "react";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";
import linkTarget from "../../../../utils/linkTarget";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";

function DescrCard({ data }) {
  const [loaded, setLoaded] = useState(0);
  const loader = useRef(null);
  return (
    <Wrapper ref={loader} style={{ opacity: loaded }}>
      <ShowOnIntersection
        loader={loader}
        setLoaded={setLoaded}
      ></ShowOnIntersection>
      <BackGroundText>{data?.dayNumber}</BackGroundText>
      <CardCTA>
        <CTATitle>
          <span>⏤</span>
          <span>{data?.category}</span>
        </CTATitle>
        <DescrWrapper>
          <Title>{data?.textualDate}</Title>
          <Text>{data?.description}</Text>
        </DescrWrapper>
      </CardCTA>
      <CtaWrapper>
        <a
          href={SetQueryStringPath(data?.linkHref || "#")}
          target={linkTarget(data?.linkHref)}
          aria-label={data?.category}
        >
          <span>{data?.textHref}</span>
          <SpanCtaIcon>
            <CtaCircle>
              <svg viewBox="0 0 24 24">
                <circle cx={12} cy={12} r={11} stroke={1} />
              </svg>
            </CtaCircle>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 16L11.2677 9.54697C11.7148 9.18016 12 8.62337 12 8C12 7.37663 11.7148 6.81984 11.2677 6.45304L4 0L8 8L4 16Z"
                fill="var(--cta-color)"
              />
            </svg>
          </SpanCtaIcon>
        </a>
      </CtaWrapper>
    </Wrapper>
  );
}

export default React.memo(DescrCard);
