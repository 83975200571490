import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  eventType: "",
  eventCodeList: [],
  additionalEventParams: null,
  date: "",
  status: null,
  errorMessage: "",
};

const eventDetailsSlice = createSlice({
  name: "eventDetails",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.error = "";
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setEventType(state, action) {
      state.eventType = action.payload;
    },
    setEventCodeList(state, action) {
      state.eventCodeList = action.payload;
    },
    setAdditionalEventParams(state, action) {
      state.additionalEventParams = action.payload;
    },
    setEventDate(state, action) {
      state.date = action.payload;
    },
    setStatusNull(state) {
      state.status = null;
    },
  },
});

export const eventDetailsActions = eventDetailsSlice.actions;
export default {
  name: eventDetailsSlice.name,
  reducer: eventDetailsSlice.reducer,
};
