import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_EVENT_DETAILS_COMPONENTS } from "./constants";
import { eventDetailsActions } from "./eventDetailsSlice";
import { fetchNotifications } from "../Notifications/actions";

function* fetchEventDetailsComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);

    yield put(eventDetailsActions.setComponents(result.data.data));
    yield put(
      eventDetailsActions.setEventType(result.data.data.settings.eventType)
    );
    yield put(
      eventDetailsActions.setEventCodeList(
        result.data.data.settings.eventCodeList
      )
    );
    yield put(
      eventDetailsActions.setAdditionalEventParams(
        result.data.data.settings.additionalEventParams
      )
    );
    yield put(eventDetailsActions.setEventDate(result.data.data.settings.date));
    yield put(
      fetchNotifications({
        path: `/${payload.coreLang}/notify`,
        token: payload.token,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(eventDetailsActions.setError(e));
  }
}

export default function* detailsSaga() {
  yield takeLatest(FETCH_EVENT_DETAILS_COMPONENTS, fetchEventDetailsComponents);
}
