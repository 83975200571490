import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./eventDetailsSlice";

const eventDetailsState = (state) => state.eventDetails || initialState;

export const selectEventDetailsComponents = () =>
  createSelector(eventDetailsState, (state) => state.components);

export const selectEventType = () =>
  createSelector(eventDetailsState, (state) => state.eventType);

export const selectEventCodeList = () =>
  createSelector(eventDetailsState, (state) => state.eventCodeList);

export const selectAdditionalEventParams = () =>
  createSelector(eventDetailsState, (state) => state.additionalEventParams);

export const selectEventDate = () =>
  createSelector(eventDetailsState, (state) => state.date);

export const selectTopComponents = () =>
  createSelector(eventDetailsState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "top"
      );
    }
  });

export const selectRightComponents = () =>
  createSelector(eventDetailsState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "right"
      );
    }
  });

export const selectLeftComponents = () =>
  createSelector(eventDetailsState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "left"
      );
    }
  });

export const selectBottomComponents = () =>
  createSelector(eventDetailsState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "bottom"
      );
    }
  });
