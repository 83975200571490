import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  @media (min-width: 1280px) {
    width: 384px;
  }
  &::before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &:nth-child(1) {
    margin-bottom: 32px;
  }
  img {
    display: block;
    width: 100%;
  }
  a {
    width: 90%;
    margin: 23px auto 0px;
    @media (max-width: 1279px) {
      margin: 0px auto 23px;
    }
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
  }
  a span {
    font-size: 12px;
    text-transform: uppercase;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
  img {
    min-width: 384px;
  }
  @media (max-width: 1279px) {
    width: 100%;
    img {
      width: 100%;
      min-width: 100%;
    }
  }
`;

export const CardCTA = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  @media (max-width: 1279px) {
    top: auto;
    bottom: 0;
    text-align: left;
  }
`;

export const Title = styled.div`
  font: 24px / 24px ferrari-sans-medium;
  color: rgb(255, 255, 255);
  margin: 85px auto 0px;
  width: 90%;
  @media (max-width: 1279px) {
    margin: 0px auto 23px;
  }
  text-transform: uppercase;
`;

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(143, 143, 143);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 16px;
  width: 16px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  border-color: rgb(238, 237, 237);
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.8px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  a:hover {
  }
`;
