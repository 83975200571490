import { useEffect, useState } from "react";
import { Container, Container3dMap } from "./style";
import useMediaQuery from "../../../hooks/useMediaQuery";

function LayoutGenericTemplate({ children }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  // const heightMobile = useMediaQuery("(min-height: 600px)");
  document.querySelector("body").setAttribute("style", "overflow: hidden;");
  console.log(
    document.querySelector("footer")?.querySelector("nav")?.querySelector("ul")
  );
  useEffect(() => {
    !isDesktop &&
      document
        .querySelector("footer")
        ?.querySelector("nav")
        ?.querySelector("ul")
        ?.setAttribute("style", "flex-direction: row;");
    // heightMobile &&
    //   document.querySelector("footer").setAttribute("style", "display: none;");
  }, [isDesktop]);

  return (
    <>
      <Container3dMap>{children}</Container3dMap>
    </>
  );
}

export default LayoutGenericTemplate;
