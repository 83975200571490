import { HeaderWrapper, HeroInfo, HeroSubTitle, HeroTitle } from "./style";

export default function Header({ data }) {
  return (
    <HeaderWrapper>
      <img src={data?.fileReference} />
      <HeroInfo>
        <HeroTitle>{data?.title}</HeroTitle>
        <HeroSubTitle>{data?.subTitle}</HeroSubTitle>
      </HeroInfo>
    </HeaderWrapper>
  );
}
