import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  @media (max-width: 1279px) {
  }
`;

export const Overlay = styled.div`
  background-color: rgba(43, 46, 56, 0.9);
  position: fixed;
  inset: 0px;
`;

export const CloseButton = styled.button`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  border: 0px;
  cursor: pointer;
  top: 10px;
  z-index: 3;
  @media (max-width: 1279px) {
    transform: none;
    position: static;
    margin: 10px 0;
  }
`;
export const Content = styled.div`
  margin: 72px auto 0;
  z-index: 2;
  width: 50%;
  max-height: calc(100vh - 102px);
  overflow: auto;
  background: var(--alternative-bg-color);
  @media (max-width: 1279px) {
    height: 100%;
    width: 100%;
    max-height: 100%;
    margin: 0 auto 0;
    text-align: center;
  }
`;
export const ContentWrapper = styled.div`
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 58px 0px 64px;
  align-items: center;
  h3 {
    margin: 0px;
  }
  img {
    width: 90%;
  }
  @media (max-width: 1279px) {
    padding: 32px 0;
  }
`;

export const Text = styled.div`
  display: block;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 24px;
  @media screen and (max-width: 1279px) {
    font-size: 11px;
  }
  a {
    color: var(--main-color);
    text-decoration: underline;
  }
`;
