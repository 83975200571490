import { createSlice } from "@reduxjs/toolkit";

const BANNER_STATE_VAR = "bannerState";
const BANNER_TITLE_VAR = "bannerTitle";

export const initialState = {
  components: {},
  notificationsArray: [],
  notificationsArrayNotRead: [],
  notificationsArrayRead: [],
  filterType: "all",
  year: new Date().getFullYear(),
  showNotificationDialog: false,
  status: null,
  errorMessage: "",
};

const Notification = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setNotificationsArray(state, action) {
      state.notificationsArray = action.payload;
      state.notificationsArrayNotRead = state.notificationsArray.filter(
        (el) => el.read === false
      );
      state.notificationsArrayRead = state.notificationsArray
        .filter((el) => el.read === true)
        .sort((a, b) => b.createdDate - a.createdDate)
        .slice(0, 3);
    },
    setFilterType(state, action) {
      state.filterType = action.payload;
    },
    setYear(state, action) {
      state.year = action.payload;
    },
    setShowNotificationDialog(state, action) {
      state.showNotificationDialog = action.payload;
      document
        .getElementById("notificationIcon")
        .setAttribute("aria-expanded", action.payload);
    },
    setBannerState(state, action) {
      saveBannerState(action.payload.bannerState, action.payload.title);
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

const saveBannerState = (state, title) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(BANNER_STATE_VAR, serializedState);
    if (!state && title) {
      localStorage.setItem(BANNER_TITLE_VAR, title);
    }
  } catch (err) {
    return undefined;
  }
};

export const loadBannerState = () => {
  try {
    let serializedState = localStorage.getItem(BANNER_STATE_VAR);
    if (serializedState === null) {
      saveBannerState(true);
      serializedState = true;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const loadBannerTitle = () => {
  try {
    const title = localStorage.getItem(BANNER_TITLE_VAR);
    if (title === null) {
      return undefined;
    }
    return title;
  } catch (err) {
    return undefined;
  }
};

export const notificationActions = Notification.actions;
export default {
  name: Notification.name,
  reducer: Notification.reducer,
};
