import { Container } from "./style";
import SetQueryStringPath from "../../../../../utils/setQueryStringPath";
import linkTarget from "../../../../../utils/linkTarget";

function FiButton({ data }) {
  return (
    <Container>
      <a href={SetQueryStringPath(data.link)} target={linkTarget(data.link)}>
        {data.title}
      </a>
    </Container>
  );
}

export default FiButton;
