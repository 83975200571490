import styled from "styled-components";

export const List = styled.div`
  margin: 24px 0px;
  @media screen and (min-width: 1280px) {
    margin: 24px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 48px 32px;
    width: 1216px;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 32px;
    h3 {
      font-size: 16px;
    }
  }
  a {
    display: block;
    width: 100%;
  }
`;
