import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  @media screen and (min-width: 1280px) {
    ${(props) =>
      props.template === "FAQ-HOME" ? `width:1216px;margin:50px auto` : ``}
  }
  @media screen and (max-width: 1279px) {
    margin-top: 32px;
  }
`;
