import styled from "styled-components";

export const Container = styled.section`
  width: 600px;
  margin: 0 auto 96px;
  h2 {
    margin: 96px 0 24px;
    text-align: center;
  }
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input,
  & select {
    letter-spacing: 0.5px;
  }
  textarea {
    width: 100%;
    background: transparent;
    padding: 8px;
    font: 14px / 20px ferrari-sans-regular;
    resize: vertical;
    box-sizing: border-box;
    color: var(--main-color);
  }
  form {
    margin: 56px auto 0;
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
  .dropzone-small {
    width: 176px;
    height: 98px;
  }
  .dropzone-small-covered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .dropzone-large {
    width: 100%;
    height: 173px;
  }
  @media (max-width: 1279px) {
    width: 90%;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;
export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 20px 0px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  text-transform: uppercase;
  font-size: 12px;
  &:disabled {
    background: #d4d2d2;
    color: #181818;
    cursor: not-allowed;
  }
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
export const InputSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0px 0px 0px;
  gap: 7px;
  position: relative;
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px !important;
    padding: 7px 0px;
  }
  & input::placeholder{
    color: #707070;
  }
  input:not(:placeholder-shown) + label {
    opacity: 1;
  }
`;
export const InputSectionCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 16px 0px 24px;
  gap: 12px;
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid var(--main-color);
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    padding: 0;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--main-color);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  span {
    max-width: calc(100% - 28px);
  }
`;
export const Carousel = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  img {
    max-width: 176px;
  }
`;
export const ImageContainer = styled.div`
  position: relative;
  img {
    max-width: 176px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .form-annuncio-dots {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }
  .image-overlay {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 14px;
    gap: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-sizing: border-box;
    z-index: 3;
  }
  .image-overlay img {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .image-overlay span {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #303030;
    cursor: pointer;
  }
`;
