// style
import { Wrapper } from "./style";
import { useSelector } from "react-redux";
import { getRubricaSearchResultTitle } from "../../../../pages/Rubrica/selectors";

function SearchResultTitle() {
  const searchResult = useSelector((state) => state.rubrica.searchResult);
  const titleComponent = useSelector(getRubricaSearchResultTitle());
  return (
    <Wrapper>
      {`${titleComponent?.data?.resultsLabel} (${searchResult.length})`}
    </Wrapper>
  );
}

export default SearchResultTitle;
