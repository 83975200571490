import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  date: "",
  status: null,
  errorMessage: "",
};

const map3dSlice = createSlice({
  name: "map3d",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
  },
});

export const map3dActions = map3dSlice.actions;
export default {
  name: map3dSlice.name,
  reducer: map3dSlice.reducer,
};
