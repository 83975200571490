import PropTypes from "prop-types";
// style
import { Wrapper } from "./style";

function Title({ data }) {
  return <Wrapper>{data.jcrTitle}</Wrapper>;
}
Image.propTypes = {
  data: PropTypes.shape({
    jcrTitle: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default Title;
