import LayoutFormIscrizioneGiftHouse from "../../components/UI/formIscrizioneGiftHouse/LayoutIscrizioneGiftHouse";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./action";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import { ForbiddenDialog } from "../../components/UI/formIscrizioneGiftHouse/ForbiddenDialog";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";
import { CannotModifyDialog } from "../../components/UI/formIscrizioneGiftHouse/CannotModifyDialog";

export default function FormIscrizioneGiftHouse() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector(
    (state) => state.FormIscrizioneGiftHouse.components
  );
  const location = useLocation();
  const url = location.search.replace(/\?path=/, "");

  useMemo(() => {
    dispatch(fetchComponents({ path: url, token }));
  }, [dispatch, url]);

  //cambiare con cannotEdit
  let isEventEditable = useSelector(
    (state) =>
      state.myPage.components
        .find((v) => v.resourceType === "fi-my-page-my-events")
        ?.data?.internalEvents?.find((internalEvent) =>
          internalEvent?.additionalEventParams?.find((el) => el === "giftHouse")
        )?.canDeleteSub
  );

  const formComponent = components?.components?.find(
    (v) => v.resourceType === "fi-events/fi-event-subscription-giftHouse"
  );

  const heroData = components?.components?.find(
    (v) => v.resourceType === "fi-events/fi-events-hero-subscription"
  );
  const modal = components?.components?.find(
    (v) => v.resourceType === "fi-modal"
  );

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(
    subjectCode,
    aggregatedDirection,
    components?.settings?.jcrTitle,
    url
  );
  const { status, errorData } = GetStatusAndErrorData(
    "FormIscrizioneGiftHouse"
  );
  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        status === 403 ? (
          <ForbiddenDialog data={formComponent?.data} />
        ) : (
          <GenericError data={errorData} />
        )
      ) : isEventEditable === false ? (
        <CannotModifyDialog data={formComponent?.data} />
      ) : (
        formComponent?.data && (
          <LayoutFormIscrizioneGiftHouse
            heroData={heroData?.data}
            data={formComponent?.data}
            dataModal={modal?.data}
          />
        )
      )}
    </>
  );
}
