import {
  FETCH_COMPONENTS,
  POST_DEFAULT_NUMBER,
  POST_CONTACTS,
  DELETE_SUBSCRIPTION,
} from "./constants";

export const fetchMyPageComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const postDefaultNumber = (payload) => ({
  type: POST_DEFAULT_NUMBER,
  payload,
});

export const postContacts = (payload) => ({
  type: POST_CONTACTS,
  payload,
});
export const deleteSubscription = (payload) => ({
  type: DELETE_SUBSCRIPTION,
  payload,
});
