import styled from "styled-components";

export const QrCodeContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const QrCodeImg = styled.div`
width: 180px;
height: 180px;
${props => props.light?"box-shadow:0px 13px 22px 5px rgba(255,255,255, 0.06)" :"box-shadow:  0px 13px 22px 5px rgba(0, 0, 0, 0.06)"};
background-color: #fff;
border-radius: 5px;
display:flex;
align-items:center;
justify-content: center;
img{
    width: 163px;
    height: 163px;
}
`;
export const QrCodeText = styled.h4`
p{
    font-family:ferrari-sans-medium;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--main-color);
    text-align: center;
}
`;
