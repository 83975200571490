import { useState } from "react";
import { ComponentContainer, InputSection, TextDescr } from "../style";
import DroppableImages from "./droppableImages/DroppableImages";
import ErrorField from "./ErrorField/ErrorField";

export default function ComponentUploadFile({
  data,
  allowed,
  setFiles,
  files,
}) {
  function generateHtml(argument) {
    return { __html: argument };
  }

  function onFileChange(file) {
    return new Promise((res, rej) => {
      setFiles((oldArray) => [...oldArray, { id: oldArray?.length + 1, file }]);
      res();
    });
  }

  return (
    <ComponentContainer>
      <InputSection>
        <DroppableImages
          onFileChange={onFileChange}
          files={files}
          setFiles={setFiles}
          data={data?.uploadFiles}
        />
        <TextDescr
          dangerouslySetInnerHTML={generateHtml(
            `${data?.uploadFiles?.richText}`
          )}
        />
      </InputSection>
      {!files?.length && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
