import styled from "styled-components";

export const Title = styled.div`
  font-size: 16px;
  margin: 0px 0px 32px;
  @media screen and (min-width: 1280px) {
    font-size: 28px;
  }
`;

export const Text = styled.div`
  font-size: 11px;
  line-height: 20px;
  @media screen and (min-width: 1280px) {
    font-size: 13px;
  }
`;
