import { takeLatest, put, select } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { FETCH_NEWS_COMPONENTS, FETCH_NEWS_BY_TEXT } from "./constants";
import { newsActions } from "./newsSlice";

function* fetchNewsComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    const cardsArray = result.data.data.components.find(
      (el) => el.resourceType === "news/fi-news-listing"
    );
    yield put(newsActions.setComponents(result.data.data));
    yield put(newsActions.setAllResultsList(cardsArray.data));
  } catch (e) {
    console.error(e);
    yield put(newsActions.setError(e));
  }
}

function* fetchNewsByText({ payload }) {
  try {
    yield put(newsActions.setLoadingTextAPI(true));
    const lang = yield select((state) => state.coreComponents.language);
    const url = `/${lang}/news-and-events/news?hitsPerPage=-1&text=${payload.text}`;
    const result = yield axiosCallGet(url, payload.token);
    const cardsArray = result.data.data.components.find(
      (el) => el.resourceType === "news/fi-news-listing"
    );
    yield put(newsActions.setFilteredResultsList(cardsArray.data));
    yield put(newsActions.setLoadingTextAPI(false));
  } catch (e) {
    console.error(e);
  }
}

export default function* newsSaga() {
  yield takeLatest(FETCH_NEWS_COMPONENTS, fetchNewsComponents);
  yield takeLatest(FETCH_NEWS_BY_TEXT, fetchNewsByText);
}
