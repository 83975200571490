import {
  Container,
  TitleForm,
  GenericTitle,
  RecapContainer,
  Divider,
} from "./style";
import QrCode from "./qrCode/QrCode";
import DetailInfo from "./detailsInfo/DetailInfo";
import { ButtonsCta } from "./buttonsCta/buttonsCta";
import Header from "./Header/Header";

export function LayoutRecapAndDetailFamilyDay({
  data,
  heroData,
  isRecapPage,
  buttonsData,
}) {
  function generateHtml(argument) {
    return { __html: argument };
  }

  const capitalizeName =
    data?.subscription?.name[0].toUpperCase() +
    data?.subscription?.name.substring(1).toLowerCase();
  const capitalizeLastName =
    data?.subscription?.lastname[0].toUpperCase() +
    data?.subscription?.lastname.substring(1).toLowerCase();
  const descrWithName = capitalizeName.concat(
    " ",
    capitalizeLastName,
    " ",
    data?.description
  );
  return (
    <>
      {heroData && <Header data={heroData} />}
      <Container detail={!isRecapPage}>
        <GenericTitle>
          <h5>{data?.titleTxt}</h5>
          {isRecapPage ? (
            <p dangerouslySetInnerHTML={generateHtml(descrWithName)} />
          ) : (
            <p dangerouslySetInnerHTML={generateHtml(data?.description)} />
          )}
        </GenericTitle>
        <RecapContainer detail={!isRecapPage}>
          <GenericTitle>
            {data?.subscription?.qrCode && (
              <>
                <QrCode data={data} />
                <p
                  dangerouslySetInnerHTML={generateHtml(`${data?.richText}`)}
                />
              </>
            )}
          </GenericTitle>
        </RecapContainer>
        {!isRecapPage && <Divider />}
        <RecapContainer detail={!isRecapPage}>
          <TitleForm>
            {isRecapPage ? data?.detailSectionTxt : data?.detailSectionTxt}
          </TitleForm>
          <DetailInfo data={data} />
        </RecapContainer>
        <ButtonsCta
          data={buttonsData}
          pageData={data}
          isRecapPage={isRecapPage}
        />
      </Container>
    </>
  );
}
