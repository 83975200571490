import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const MapImg = styled.div`
  display: block;
  img {
    width: 100%;
  }
`;

export const BuildingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SetColumn = styled.div`
  column-count: 2;

  @media screen and (min-width: 1280px) {
    column-count: 3 !important;
  }
`;

export const BulletPoint = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BulletPointNumber = styled.span`
  text-align: center;
  font-size: 13px;
  display: flex;
  background: rgb(218, 41, 28);
  color: rgb(255, 255, 255);
  border-radius: 100%;
  min-width: 30px;
  max-height: 20px;
  place-items: center;
  place-content: center;
  padding: 7px 3px;
`;

export const BulletPointText = styled.span`
  margin-left: 12px;
  font-size: 13px;
`;
