export default function CameraIcon() {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9416 1.2171C21.8055 0.808755 21.4234 0.533325 20.9929 0.533325H9.00444C8.57401 0.533325 8.19187 0.808755 8.05576 1.2171L6.86659 4.78462L0.96064 7.147C0.580982 7.29886 0.332031 7.66657 0.332031 8.07547V27.4623H29.6654V8.07547C29.6654 7.66657 29.4164 7.29886 29.0368 7.147L23.1308 4.78462L21.9416 1.2171ZM19.8074 3.17813H10.19L9.16409 6.25573C9.05226 6.59121 8.79841 6.86044 8.47007 6.99177L2.97684 9.18906V24.8175H27.0206V9.18906L21.5273 6.99177C21.199 6.86044 20.9451 6.59121 20.8333 6.25573L19.8074 3.17813ZM14.9987 22.0525C18.7832 22.0525 21.8512 18.9845 21.8512 15.2C21.8512 11.4155 18.7832 8.34753 14.9987 8.34753C11.2142 8.34753 8.14624 11.4155 8.14624 15.2C8.14624 18.9845 11.2142 22.0525 14.9987 22.0525ZM14.9987 19.4076C17.3225 19.4076 19.2063 17.5238 19.2063 15.2C19.2063 12.8762 17.3225 10.9923 14.9987 10.9923C12.6749 10.9923 10.791 12.8762 10.791 15.2C10.791 17.5238 12.6749 19.4076 14.9987 19.4076Z"
        fill="var(--main-color)"
      />
    </svg>
  );
}
