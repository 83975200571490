import { ComponentContainer } from "../../style";
import { useBusinessLogic } from "../../useBusinessLogic";
import Accompagnatori from "./Accompagnatori";
import PartecipazioneLe from "./PartecipazioneLe";
import SlotLightExperience from "./SlotLightExperience";

export default function ComponentLightExperience({ data, onChange, allowed }) {
  const { Guests, SlotLight, yesLight } = useBusinessLogic();

  return (
    <>
      <ComponentContainer>
        <h3>{data?.title}</h3>
        <PartecipazioneLe
          data={data}
          check={yesLight?.value}
          onChange={onChange}
          allowed={allowed}
        />
        {yesLight?.value === "true" && (
          <Accompagnatori
            data={data}
            check={Guests?.value}
            onChange={onChange}
            allowed={allowed}
          />
        )}
      </ComponentContainer>
      {Guests?.value !== null && yesLight?.value === "true" && (
        <SlotLightExperience
          data={data}
          check={SlotLight?.value}
          onChange={onChange}
          allowed={allowed}
        />
      )}
    </>
  );
}
