import { Container, ImgHeader } from "./style";

function LayoutStrumenti({ children, heroImg }) {
  return (
    <>
      <ImgHeader src={heroImg} />
      <Container>{children}</Container>
    </>
  );
}

export default LayoutStrumenti;
