import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { setComponents, setError, fetchComponents } from "./FAQSlice";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export default function* FAQHomeSaga() {
  yield takeLatest(fetchComponents.type, fetchComponentsWorker);
}
