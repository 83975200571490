import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_WEBINAR_COMPONENTS } from "./constants";
import { webinarActions } from "./webinarSlice";

function* fetchWebinarComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(webinarActions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(webinarActions.setError(e));
  }
}

export default function* webinarSaga() {
  yield takeLatest(FETCH_WEBINAR_COMPONENTS, fetchWebinarComponents);
}
