import React from "react";
// style
import { Title, Text } from "./style";

function InfoTitleSection({ data }) {
  return (
    <>
      <Title>{data?.title}</Title>
      <Text>
        <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
      </Text>
    </>
  );
}

export default React.memo(InfoTitleSection);
