import { Card, FocusText, PreTitle, EventCta } from "./style";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

function EventCard({ data }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <Link
      aria-label={data.title}
      to={data?.path ? "/event-details?path=" + data.path : "#"}
    >
      <Card>
        {isDesktop ? (
          <img src={data.image} style={{ width: "384px" }} />
        ) : (
          <img src={data.fileReferenceMobile || data.image} />
        )}
        <FocusText>
          <PreTitle>
            <span>{`${data.dateDay} ${data.dateMonth} ${new Date(
              data.date
            ).getFullYear()}`}</span>
          </PreTitle>
          <h3>{data.title}</h3>
          {data.labelLiveStreaming && (
            <EventCta>{data.labelLiveStreaming}</EventCta>
          )}
        </FocusText>
      </Card>
    </Link>
  );
}
EventCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    dateDay: PropTypes.string,
    dateMonth: PropTypes.string,
    path: PropTypes.string,
    image: PropTypes.string,
    date: PropTypes.number,
    pathBooking: PropTypes.string,
    description: PropTypes.string,
    subtitle: PropTypes.string,
  }),
};
export default EventCard;
