import React from "react";
import { useSelector, useDispatch } from "react-redux";
// style
import {
  Wrapper,
  IconContainer,
  TextContainer,
  Title,
  Description,
} from "./style";
// actions
import { fetchNotifications } from "../../../../../../pages/Notifications/actions";
// assets
import MegaphoneIconRedPriority from "../../../../../assets/notifications/megaphoneRedPriority";
import MegaphoneIconGreyPriority from "../../../../../assets/notifications/megaphoneGreyPriority";
import MeghaponeIconRed from "../../../../../assets/notifications/megaphoneRed";
import MegaphoneIconGrey from "../../../../../assets/notifications/megaphoneGrey";
import EventIconRedPriority from "../../../../../assets/notifications/eventRedPriority";
import EventIconGreyPriority from "../../../../../assets/notifications/eventGreyPriority";
import EventIconRed from "../../../../../assets/notifications/eventRed";
import EventIconGrey from "../../../../../assets/notifications/eventGreys";
import NewsIconGrey from "../../../../../assets/notifications/newsGrey";
import NewsIconRed from "../../../../../assets/notifications/newsRed";
import NewsIconGreyPriority from "../../../../../assets/notifications/newsGreyPriority";
import NewsIconRedPriority from "../../../../../assets/notifications/newsRedPriority";
// utils
import { axiosCallDownloadDoc } from "../../../../../../utils/axiosCalls/AxiosCalls";
import SetQueryStringPath from "../../../../../../utils/setQueryStringPath";

function NotificationCard({ data }) {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.activeToken);
  const coreLang = useSelector((state) => state.coreComponents.language);

  const day = new Date(data?.createdDate).toLocaleString("default", {
    day: "numeric",
  });
  const month = new Date(data?.createdDate)
    .toLocaleString("it-IT", {
      month: "long",
    })
    .substring(0, 3);
  const year = new Date(data?.createdDate).toLocaleString("it-IT", {
    year: "numeric",
  });

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const downloadDocHandler = async () => {
    axiosCallDownloadDoc(coreLang, data.idPath, data.title, token);
    //The FE is faster than the MW, we wait 1sec to make sure the notification state changes
    //not read --> read
    await sleep(2000);
    dispatch(fetchNotifications({ path: `/${coreLang}/notify`, token }));
  };

  const HandleEnterPress = (e) => {
    switch (e.KeyCode) {
      case 13:
        e.preventDefault();
        HandleActionPress();
        break;
    }
  };

  const cardUrl = SetQueryStringPath(data?.path);
  const HandleActionPress = () => {
    if (data?.type !== "document") {
      window.location.replace(cardUrl);
    } else downloadDocHandler();
  };

  const SetCardIcon = () => {
    if (data?.read && data?.priority) {
      //read with priority
      switch (data?.type) {
        case "document":
          return <MegaphoneIconGreyPriority />;
        case "event":
          return <EventIconGreyPriority />;
        case "news":
          return <NewsIconGreyPriority />;
      }
    } else if (data?.read && !data?.priority) {
      //read with no priority
      switch (data?.type) {
        case "document":
          return <MegaphoneIconGrey />;
        case "event":
          return <EventIconGrey />;
        case "news":
          return <NewsIconGrey />;
      }
    } else if (!data?.read && data?.priority) {
      //not read with priority
      switch (data?.type) {
        case "document":
          return <MegaphoneIconRedPriority />;
        case "event":
          return <EventIconRedPriority />;
        case "news":
          return <NewsIconRedPriority />;
      }
    } else {
      //not read with no priority
      switch (data?.type) {
        case "document":
          return <MeghaponeIconRed />;
        case "event":
          return <EventIconRed />;
        case "news":
          return <NewsIconRed />;
      }
    }
  };

  return (
    <Wrapper className="notification-card">
      <IconContainer>{SetCardIcon()}</IconContainer>
      <TextContainer
        role="button"
        tabIndex="0"
        aria-label={data?.title}
        onKeyDown={(e) => HandleEnterPress(e)}
        onClick={HandleActionPress}
      >
        <Title>{data?.title}</Title>
        <Description>{`${day} ${month.toUpperCase()} ${year}`}</Description>
      </TextContainer>
    </Wrapper>
  );
}

export default React.memo(NotificationCard);
