import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;
  div {
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
  }
  @media (max-width: 1279px) {
    flex-direction: column;
    text-align: left;
    h1 {
      margin: 40px 0px 32px 0px;
      padding: 0px;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  column-gap: 32px;
  margin-bottom: 60px;
  @media (max-width: 1279px) {
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
  }
`;

export const SelectWrapper = styled.div`
  @media (max-width: 1279px) {
    width: 280px;
  }
  width: ${(props) => (props.grow ? "250px" : "130px")};
  position: relative;
`;

export const Select = styled.select`
  width: 100%;
  appearance: none;
  position: relative;
  text-transform: uppercase;
  select,
  input,
  option {
    font-family: ferrari-sans-regular;
    font-size: 11px;
    padding: 12px 16px 14px;
    color: rgb(48, 48, 48);
    max-width: 100%;
  }

  select:not(:-internal-list-box) {
    overflow: visible !important;
  }
`;

export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;
