import {
  Wrapper,
  Overlay,
  BoxContainer,
  ConfirmButton,
  CloseButton,
} from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowModal,
  setShowSuccessModal,
} from "../../../../../pages/FormTestHmi/FormTestHmiSlice";
import { reloadComponents } from "../../../../../pages/FormTestHmi/action";

export function Modal({ data, handleDelete, showSuccessModal }) {
  const dispatch = useDispatch();
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const path = headerLinks?.find((v) => v.template === "adesioni-hmi")?.path;
  const token = useSelector((state) => state.login.activeToken);

  const handleClosePopup = (e) => {
    e.preventDefault();
    dispatch(setShowModal(false));
  };
  const handleCloseSuccessModal = (e) => {
    e.preventDefault();
    dispatch(reloadComponents({ path, token }));
    dispatch(setShowSuccessModal(false));
  };

  return (
    <Wrapper>
      <Overlay />
      {!showSuccessModal && (
        <CloseButton onClick={(e) => handleClosePopup(e)}>
          <img src="/img/close-light.svg" width="40" height="40" />
        </CloseButton>
      )}
      <BoxContainer>
        <h2>{data?.title}</h2>
        <div>{data?.description}</div>
        <ConfirmButton
          onClick={
            showSuccessModal
              ? (e) => handleCloseSuccessModal(e)
              : (e) => handleDelete(e)
          }
        >
          {data?.buttonLabel}
        </ConfirmButton>
      </BoxContainer>
    </Wrapper>
  );
}
