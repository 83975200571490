import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonTab,
  ContainerTabs,
  SelectWrapperWrapper,
  Select,
  SelectWrapper,
  WrapperImg,
} from "./style";

import { CardUserList } from "../CardUserList/CardUserList";
import { DialogNote } from "../DialogNote/DialogNote";

import {
  selectTabIndex,
  selectTabsArray,
  defaultNote,
} from "../../../../../pages/BusinessCardManagement/selectors";
import {
  setTabsIndex,
  setShowModal,
} from "../../../../../pages/BusinessCardManagement/BusinessCardManagementSlice";
import { saveHrManagerAction } from "../../../../../pages/BusinessCardManagement/action";
import BottomIcon from "../../../../assets/bottom";

export function TabsRequest() {
  const token = useSelector((state) => state.login.activeToken);
  const selectedTabIndex = useSelector(selectTabIndex());
  const tabsArray = useSelector(selectTabsArray());
  const showModal = useSelector(
    (state) => state.businessCardManagement.showModal
  );
  const notePlaceholder = useSelector(defaultNote());
  const userInfo = useSelector(
    (state) => state.businessCardManagement.userInfo
  );
  const dispatch = useDispatch();
  const confirmCallBack = (e) => {
    let note = document.getElementById("idNoteTxt")?.value;
    if (
      (userInfo.editByReviewer && note === "") ||
      (userInfo.status === -1 && note === "")
    ) {
      return;
    } else {
      const userInfoAndNote = {
        ...userInfo,
        note: note !== "" ? note : notePlaceholder,
      };
      dispatch(setShowModal(false));
      dispatch(
        saveHrManagerAction({ responseBody: userInfoAndNote, token: token })
      );
    }
  };
  const closeCallBack = () => {
    dispatch(setShowModal(false));
  };
  const handleTabClick = (index) => {
    if (selectedTabIndex !== index) {
      document.querySelector(".active")?.classList.remove("active");
      dispatch(setTabsIndex(index));
    }
  };
  return (
    <>
      <SelectWrapperWrapper>
        <SelectWrapper>
          <Select
            value={tabsArray?.[selectedTabIndex]?.id}
            onChange={(e) => handleTabClick(+e.target.value)}
            noborder={true}
          >
            {React.Children.toArray(
              tabsArray?.map((current) => (
                <option value={current.id}>
                  {current.components.title + " "}(
                  {current?.components?.items?.length})
                </option>
              ))
            )}
          </Select>
          <WrapperImg>
            <BottomIcon></BottomIcon>
          </WrapperImg>
        </SelectWrapper>
      </SelectWrapperWrapper>
      <ContainerTabs>
        {React.Children.toArray(
          tabsArray?.map((item, index) => (
            <ButtonTab
              onClick={() => handleTabClick(item?.id)}
              active={selectedTabIndex === index}
            >
              {item.components.title + " "}({item?.components?.items?.length})
            </ButtonTab>
          ))
        )}
      </ContainerTabs>
      {<CardUserList data={tabsArray[selectedTabIndex]?.components} />}
      {showModal && (
        <DialogNote
          confirmCallBack={confirmCallBack}
          closeCallBack={closeCallBack}
        />
      )}
    </>
  );
}
