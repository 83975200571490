import styled from "styled-components";

export const Wrapper = styled.div``;

export const DropdownSelected = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  column-gap: 10px;
  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const ListItem = styled.li`
  padding: 12px 32px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  color: var(--alternative-grey-color);
  border-bottom: 2px solid transparent;
  cursor: pointer;
  &:hover,
  &.selected {
    color: rgb(218, 41, 28);
    border-bottom: 2px solid rgb(218, 41, 28);
  }
`;

export const SelectWrapperWrapper = styled.div`
  display: block;
  @media (min-width: 1280px) {
    display: none;
  }
  & > div {
    width: 100%;
  }
  select {
    text-transform: uppercase;
    font-size: 12px;
  }
`;
