import { Wrapper, Text } from "./style";

import CtaIconFull from "../../../../ctaIconFull/CtaIconFull";
import SetQueryStringPath from "../../../../../../utils/setQueryStringPath";

function FormazioneManagerialeBox({ data }) {
  function html() {
    return { __html: data.text };
  }
  return (
    <Wrapper>
      <h3>{data.title}</h3>
      <Text dangerouslySetInnerHTML={html()}></Text>
      <CtaIconFull
        link={SetQueryStringPath(data.linkHref)}
        description={data.textButton}
        position={"static"}
      ></CtaIconFull>
    </Wrapper>
  );
}

export default FormazioneManagerialeBox;
