import React from "react";
import { DetailContainerData, DetailDataLabel, MealBox } from "./style";
import { useSelector } from "react-redux";

export function DetailInfo({ data }) {
  const { language } = useSelector((state) => state.coreComponents);
  const aemMenus = [
    data?.standardMealLbl,
    data?.vegMealLbl,
    data?.glutenFreeMealLbl,
  ];
  const entranceValue =
    data?.subscription?.optionsValue?.[0]?.value === "GES"
      ? data?.gesLbl
      : data?.subscription?.optionsValue?.[0]?.value === "GT"
      ? data?.gtLbl
      : data?.scagliettiLbl;

  const getSubscriptionTime = (startDate, endDate) => {
    let result = new Date(startDate)?.toLocaleString(language, {
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(language, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result;
  };

  const getSubscriptionDay = (startDate) => {
    let result = new Date(startDate)?.toLocaleString(language, {
      weekday: "long",
      day: "2-digit",
      month: "long",
    });
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  return (
    <>
      <DetailContainerData>
        <DetailDataLabel>{data?.dateLabel}</DetailDataLabel>
        <p>
          {getSubscriptionDay(
            data?.subscription?.secondaryAppointment?.appointmentStartDate
          )}
        </p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.timeLabel}</DetailDataLabel>
        <p>
          {getSubscriptionTime(
            data?.subscription?.secondaryAppointment?.appointmentStartDate,
            data?.subscription?.secondaryAppointment?.appointmentEndDate
          )}
        </p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.guestsLbl}</DetailDataLabel>
        <p>
          {data?.subscription?.numGuests === 0
            ? data?.noGuestsLbl
            : data?.subscription?.numGuests}
        </p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.lunchLabel}</DetailDataLabel>
        <MealBox>
          {data?.subscription?.mealMenus?.map(
            (menu, i) =>
              menu?.quantity > 0 && (
                <p key={menu?.id}>{`${aemMenus[i]} x${menu?.quantity}`}</p>
              )
          )}
        </MealBox>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.entryLabel}</DetailDataLabel>
        <p>{entranceValue}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.badgeLabel}</DetailDataLabel>
        <p>{data?.subscription?.primaryAppointment?.titleTxt}</p>
      </DetailContainerData>
    </>
  );
}

export default React.memo(DetailInfo);
