import React from "react";
import { QrCodeContainer, QrCodeImg } from "./style";
import { useSelector } from "react-redux";

export function QrCode({ data }) {
  const theme = useSelector((state) => state.coreComponents.pageLight);
  const qrCodeString = data;
  const renderQr = `data:image/jpeg;base64,${qrCodeString}`;

  return (
    <>
      <QrCodeContainer>
        <QrCodeImg light={theme === "dark" ? true : false}>
          <img src={renderQr} />
        </QrCodeImg>
      </QrCodeContainer>
    </>
  );
}

export default React.memo(QrCode);
