import { useEffect, useState } from "react";
// style
import { Wrapper, Icon, MessageText, CloseButton } from "./style";

function NotificationBadge({ timer, text, messageType, notificationRoot }) {
  const [closeTimeOut, setCloseTimeOut] = useState(null);

  useEffect(() => {
    beginCloseTimeOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const beginCloseTimeOut = () => {
    if (timer) {
      const timeout = setTimeout(() => closeNotificationBadge(), timer);
      setCloseTimeOut(timeout);
    }
  };

  const closeNotificationBadge = () => {
    clearTimeout(closeTimeOut);
    notificationRoot.unmount();
  };

  return (
    <Wrapper
      onMouseEnter={() => clearTimeout(closeTimeOut)}
      onMouseLeave={beginCloseTimeOut}
    >
      <Icon icon={messageType} />
      <MessageText>{text}</MessageText>
      <CloseButton onClick={closeNotificationBadge} />
    </Wrapper>
  );
}

export default NotificationBadge;
