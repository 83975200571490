import styled from "styled-components";

export const Container = styled.div`
  margin: 50px 0px 5px 25px;
  padding: 15px;
  font-size: 36px;
  border-left: 8px solid red;
`;

export const Divider = styled.div`
  margin: 0 auto;
  border-bottom: 1px solid rgb(212, 210, 210);
  float: center;
  clear: both;
  width: 95%;
`;
