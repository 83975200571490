import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: -92px;
  position: relative;
  width: 100%;
  max-width: 100%;
  @media (max-width: 1279px) {
    margin-bottom: ${(props) =>
      props.link || !props.noCreateWithoutLink ? "68px" : "32px"};
  }
  @media (min-width: 1280px) {
    margin-bottom: 96px;
  }
  &:before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }
`;

export const GoBack = styled.div`
  width: 1216px;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: calc(100% + 32px);
  a {
    color: var(--cta-color);
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  a:hover span.circle svg circle {
    stroke-dashoffset: 0;
  }
  span.cta-icon {
    appearance: none;
    margin-left: 10px;
    outline: 0px;
    //border: 2px solid rgb(143, 143, 143);
    background: transparent;
    display: inline-flex;
    align-items: center;
    height: 16px;
    width: 16px;
    padding: 8px 0px 8px 8px;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    font-size: 0px;
    transform: rotate(180deg);
  }
  span.circle {
    display: none;
    position: absolute;
    inset: -3px;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%) rotate(-90deg);
      circle {
        stroke: rgb(218, 41, 28);
        stroke-dasharray: 70;
        stroke-dashoffset: 70;
        stroke-width: 1px;
        fill: none;
        transition: stroke-dashoffset 0.3s ease 0s;
      }
    }
  }
  @media screen and (max-width: 1279px) {
    left: 4%;
    width: 90%;
    transform: none;
    top: calc(100% + 32px);
  }
`;

export const SvgElement = styled.svg.attrs({
  viewBox: "0 0 8 16",
  width: "8",
  height: "16",
  xmlns: "http://www.w3.org/2000/svg",
})``;

export const Path = styled.path.attrs({
  d: "M7.268 9.547L0 16l4-8-4-8 7.268 6.453C7.715 6.82 8 7.377 8 8c0 .623-.285 1.18-.732 1.547z",
})`
  fill: var(--cta-color);
`;

export const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
  color: rgb(255, 255, 255);
  padding: 40px 0px 48px;
  box-sizing: border-box;
  bottom: 0px;
  align-items: stretch;
  position: absolute;
  @media screen and (min-width: 1280px) {
    width: 1216px;
    transform: translateX(-50%);
    left: 50%;
    margin: auto;
  }
  @media screen and (max-width: 1279px) {
    padding-right: 42px;
    padding-left: 42px;
  }
`;

export const LeftContainer = styled.div`
  padding-right: 25px;
  border-right: 1px solid rgb(255, 255, 255);
  @media (min-width: 1280px) {
    text-align: center;
    max-height: 122px;
  }
  div.number {
    font-size: 64px;
    @media (max-width: 1279px) {
      font-size: 22px;
    }
  }
  div.month {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 15px;
  }
`;

export const RightContainer = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  &.padding-bottom-32 {
    padding-bottom: 32px;
  }
  div.title {
    align-self: flex-start;
    font-size: 16px;
    text-transform: uppercase;
    font-family: ferrari-sans-medium;
    @media (min-width: 1280px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  button.eventType {
    color: rgb(255, 255, 255);
    border-radius: 27px;
    padding: 5px 16px 7px;
    text-transform: uppercase;
    font: 9px ferrari-sans-medium;
    border-color: rgba(255, 255, 255, 0.4);
    max-width: 140px;
  }
`;
