import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  max-width: 600px;
  margin: auto;
  z-index: 2;
  position: fixed;
  inset: 0px;
`;

export const Overlay = styled.div`
  background: #181818;
  opacity: 0.5;
  position: fixed;
  inset: 0px;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: auto;
  z-index: 2;
  position: relative;
  background: white;
  padding: 48px;
  text-align: center;
  color: #181818;
  h2 {
    flex-grow: 8;
    font-size: 28px;
  }
  div {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const ConfirmButton = styled.button`
  width: 100%;
  padding: 20px 0px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  text-transform: uppercase;
  font-size: 12px;
`;
