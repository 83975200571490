import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  dishes: [],
  selectedCategory: "",
  dishCategories: [],
  trayItems: [],
  caloricIntake: 0,
  totalKcal: 0,
  status: null,
  errorMessage: "",
};

const mensaSlice = createSlice({
  name: "mensa",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },

    setDishes(state, action) {
      const dishes = action.payload.map((v) => {
        return { ...v, isAdded: false };
      });
      state.dishes = dishes;
      let categories = [];
      dishes?.map((dish) => {
        if (categories.filter((e) => e === dish.category).length <= 0) {
          categories.push(dish.category);
        }
      });
      state.dishCategories = categories;
      state.selectedCategory = categories[0];
    },
    setDishCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setFavoriteDish(state, action) {
      const newItem = state.dishes.find((v) => v.id === action.payload.id);
      newItem.like = !newItem.like;
      newItem.likeNum = newItem.like
        ? newItem.likeNum + 1
        : newItem.likeNum - 1;
    },
    toggleDish(state, action) {
      const dish = action.payload;
      const newItem = state.dishes.find((v) => v.id === dish.id);
      newItem.isAdded = !newItem.isAdded;
      let newKcalTotal = 0;
      if (newItem.isAdded) {
        state.trayItems.push(newItem);
        newKcalTotal = state.totalKcal + dish.kcal;
      } else {
        let index = state.trayItems.findIndex((d) => d.id === dish.id);
        state.trayItems.splice(index, 1);
        newKcalTotal = state.totalKcal - dish.kcal;
      }
      state.totalKcal = newKcalTotal;
    },
    fetchCaloircIntake(state, action) {
      state.caloricIntake = action.payload.caloricIntake;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const mensaActions = mensaSlice.actions;
export default {
  name: mensaSlice.name,
  reducer: mensaSlice.reducer,
};
