import React, { useEffect, useState, useCallback } from "react";
// components
import LoadThronPlayer from "./LoadThronPlayer";
import ThronPlayer from "./ThronPlayer";
// style
import { Container } from "./style";
import { useSelector } from "react-redux";

const ThronPlayerWrapper = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  const isImage = data.thronContentType === "IMAGE";
  const lang = useSelector((state) => state.coreComponents.language);
  const handleChange = useCallback(
    (e) => {
      window.THRONContentExperience && setLoaded(true);
    },
    [data]
  );

  useEffect(() => {
    if (!isImage) {
      LoadThronPlayer(handleChange);
    }
  }, [isImage, handleChange]);

  return (
    <Container>
      {isImage ? (
        <img src={data.fileReference} />
      ) : loaded ? (
        <ThronPlayer
          xcontentId={data.thronId}
          language={lang === "it" ? "IT" : "EN"}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default React.memo(ThronPlayerWrapper);
