import { createSlice } from "@reduxjs/toolkit";

export const RECAP_ISCRIZIONE = "RecapAndDetailGiftHouseLe";

export const initialState = {
  components: {},
  status: null,
  showModal: null,
  errorMessage: "",
};

const RecapAndDetailGiftHouseLeSlice = createSlice({
  name: RECAP_ISCRIZIONE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetRecapAndDetailGiftHouse: () => initialState,
  },
});

export const RecapAndDetailGiftHouseLeActions =
  RecapAndDetailGiftHouseLeSlice.actions;
export default RecapAndDetailGiftHouseLeSlice;
