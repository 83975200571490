import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  gap: 32px;
  margin-bottom: 96px;
  @media (min-width: 769px) {
    box-sizing: content-box;
    width: 640px;
    gap: 32px;
  }
  @media (max-width: 768px) {
    padding: 24px;
  }
  @media (max-width: 1279px) {
    margin: 0px auto 0px auto;
    gap: 24px;
    padding-top: 0;
  }
  @media (min-width: 1280px) {
    margin: 37px auto 96px auto;
    box-sizing: content-box;
    padding: 10px;
    width: 1216px;
    gap: 32px 32px;
    & > div:nth-child(5),
    & > div:nth-child(8),
    & > div:nth-child(3n + 10) {
      transition-delay: 0.5s;
    }
    & > div:nth-child(6),
    & > div:nth-child(3n + 11) {
      transition-delay: 1s;
    }
  }
`;
