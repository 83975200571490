import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// assets
import MegaphoneIconBanner from "../../../assets/notifications/megaphoneBanner";
// actions
import { notificationActions } from "../../../../pages/Notifications/notificationSlice";
// components
import NotificationDialog from "../../dialog/notifications/NotificationDialog";
// style
import {
  ButtonAlert,
  MeghaponeIcon,
  DateAlert,
  TextAlert,
  CloseButton,
  FlexBox,
} from "./style";

function NotificationBanner({
  handleNotificationBanner,
  lastPriorityNotification,
}) {
  const dispatch = useDispatch();

  const { shwowNotificationDialog } = useSelector(
    (state) => state.notifications
  );

  const day = new Date(lastPriorityNotification?.createdDate).toLocaleString(
    "default",
    {
      day: "numeric",
    }
  );
  const month = new Date(lastPriorityNotification?.createdDate)
    .toLocaleString("it-IT", {
      month: "long",
    })
    .substring(0, 3);

  return (
    <>
      {lastPriorityNotification && (
        <ButtonAlert>
          <MeghaponeIcon>
            <MegaphoneIconBanner />
          </MeghaponeIcon>
          <FlexBox
            role="button"
            tabIndex="0"
            aria-pressed="false"
            aria-label={lastPriorityNotification?.title}
            onClick={() =>
              dispatch(notificationActions.setShowNotificationDialog(true))
            }
          >
            <DateAlert>{`${day} ${month}`}</DateAlert>
            <TextAlert>{lastPriorityNotification?.title}</TextAlert>
          </FlexBox>
          <CloseButton
            role="button"
            tabIndex="0"
            aria-pressed="false"
            aria-label="hide notification"
            onClick={() => handleNotificationBanner()}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 21.3261L15.0503 25.6571C14.8551 25.8522 14.5383 25.8522 14.3431 25.6571C14.148 25.4619 14.148 25.1451 14.3431 24.95L18.6742 20.0002L14.3431 15.0505C14.148 14.8553 14.148 14.5385 14.3431 14.3434C14.5383 14.1482 14.8551 14.1482 15.0503 14.3434L20 18.6744L24.9497 14.3434C25.1449 14.1482 25.4617 14.1482 25.6569 14.3434C25.852 14.5385 25.852 14.8553 25.6569 15.0505L21.3258 20.0002L25.6569 24.95C25.852 25.1451 25.852 25.4619 25.6569 25.6571C25.4617 25.8522 25.1449 25.8522 24.9497 25.6571L20 21.3261Z"
                fill="white"
              />
            </svg>
          </CloseButton>
        </ButtonAlert>
      )}
      {shwowNotificationDialog && <NotificationDialog />}
    </>
  );
}

export default NotificationBanner;
