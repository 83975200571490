import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  setComponents,
  setError,
  fetchComponents,
  setRequestStatus,
} from "./FormRichiestaEcardSlice";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/notifications/TriggerNotification";
import { FETCH_SET_ECARD_INFORMATION } from "./constants";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export function* SetUserEcardInformation({ payload }) {
  try {
    const messageSuccess =
      payload.lang === "it"
        ? "Richiesta inviata con successo"
        : "Request sent successfully";

    yield document.body.classList.add("loading");
    yield axiosCallPost(
      "/it/digital-card",
      payload.responseBody,
      payload.token
    );
    showSuccessMessage(messageSuccess);
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(setComponents(result.data.data));
    yield put(setRequestStatus(true));
  } catch (e) {
    const messageError =
      payload.lang === "it"
        ? "Errore nel salvataggio dati"
        : "Error while saving data";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(fetchComponents.type, fetchComponentsWorker);
  yield takeLatest(FETCH_SET_ECARD_INFORMATION, SetUserEcardInformation);
}
