import { FETCH_COMPONENTS, SEND_EMAIL } from "./constants";

export const fetchContactUsComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const sendEmail = (payload) => ({
  type: SEND_EMAIL,
  payload,
});
