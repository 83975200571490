import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { selectAdsButtons } from "../../../../pages/AdsList/selectors";
import { ActionLink, Container, ServiceActions, TitleWrapper } from "./style";

function HomeAdsButton() {
  const data = useSelector(selectAdsButtons());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  if (!data) return null;
  const myAdsPath = headerLinks?.find((v) => v.template === "my-ads")?.path;
  const linkToModify = `/creazione-annuncio?&path=${myAdsPath}`;
  const { title, description, items } = data;
  return (
    <Container>
      <TitleWrapper>
        <h1>{title}</h1>
        <div>{description}</div>
      </TitleWrapper>
      <ServiceActions>
        {React.Children.toArray(
          items.map((button, indx) =>
            indx === 0 ? (
              <ActionLink to={linkToModify}>{button.title}</ActionLink>
            ) : (
              <ActionLink to={button.linkHref}>{button.title}</ActionLink>
            )
          )
        )}
      </ServiceActions>
    </Container>
  );
}

HomeAdsButton.propTypes = {
  data: PropTypes.exact({
    description: PropTypes.string,
    title: PropTypes.string,
    ads: PropTypes.shape({
      title: PropTypes.string,
      linkHref: PropTypes.string,
    }),
    myAds: PropTypes.shape({
      title: PropTypes.string,
      linkHref: PropTypes.string,
    }),
  }),
};
export default HomeAdsButton;
