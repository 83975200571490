import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// actions
import { newsActions } from "../../../../pages/News/newsSlice";

// style
import { Wrapper, ResultsLabel } from "./style";

function FilterResultsDetails({ data, resultsNumber }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.coreComponents.language);

  const removeFilterHandler = () => {
    dispatch(newsActions.resetState());
  };

  return (
    <Wrapper>
      <ResultsLabel>
        {lang === "it"
          ? `Risultati (${resultsNumber})`
          : `Results (${resultsNumber})`}
      </ResultsLabel>
      <Link to={`/${lang}/news-and-events/news`} onClick={removeFilterHandler}>
        {data?.filter?.removeFiltersLabel}
      </Link>
    </Wrapper>
  );
}

export default FilterResultsDetails;
