import PropTypes from "prop-types";
import { Text } from "./style";

function FiText({ data }) {
  function html() {
    return { __html: data.text };
  }

  return <Text dangerouslySetInnerHTML={html()}></Text>;
}

FiText.propTypes = {
  data: PropTypes.shape({
    textIsRich: PropTypes.string,
    text: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default FiText;
