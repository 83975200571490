import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 240px;
  display: block;
  @media screen and (max-width: 1279px) {
    width: 90%;
    margin: auto;
  }
  @media screen and (min-width: 1280px) {
    h1 {
      margin: 96px 0 24px;
    }
  }
`;

export const ImgHeader = styled.img`
  margin-top: -92px;
  position: relative;
  z-index: -1;
  max-width: 100%;
  width: 100%;
`;
