import styled from "styled-components";

export const Title = styled.h2`
  margin-top: 0px;
  margin-bottom: 48px;
  @media (max-width: 1279px) {
    font-size: 16px;
  }
`;

export const Place = styled.h3`
  @media (max-width: 1279px) {
    font-size: 13px;
  }
`;

export const Street = styled.div`
  font-size: 13px;
  line-height: 20px;
  max-width: 250px;
  @media (max-width: 1279px) {
    font-size: 11px;
  }
`;

export const Img = styled.img`
  display: block;
  margin: 32px 0px;
`;

export const Divider = styled.div`
  margin: 64px 0px;
  height: 0px;
  border-bottom: 1px solid #8f8f8f;
  float: left;
  clear: both;
  width: 100%;
`;
