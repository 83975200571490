import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 1s;
  @media (min-width: 1280px) {
    width: 800px;
    .carousel-custom-controllers {
      bottom: 32px;
    }
    & .carousel-custom-controllers path {
      fill: var(--main-color) !important;
    }
  }
  height: 384px;
  background: var(--alternative-bg-color);
  color: var(--main-color);
  path {
    fill: var(--main-color);
  }
  .carousel-dot {
    background: var(--main-color) !important;
  }
  .slide .home-text-animation {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition-property: opacity, transform;
    transition-timing-function: linear;
    transition-duration: 0.5s;
    transition-delay: 0.2s;
  }
  .slide.selected .home-text-animation {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0.7s;
  }
  .slide img {
    position: absolute;
    left: -100%;
    opacity: 1 !important;
    transition-property: left;
    transition-timing-function: linear;
    transition-duration: 0s;
    transition-delay: 1.5s;
  }
  .slide.selected img {
    left: 0;
    opacity: 1 !important;
    transition-property: left;
    transition-duration: 1.5s;
    transition-delay: 0s;
  }
  @media (max-width: 1279px) {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    background: none;
    .carousel.carousel-slider {
      overflow: initial;
    }
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  padding: 32px 32px 0;
  box-sizing: border-box;
  height: 384px;
  gap: 32px;
  @media (max-width: 1279px) {
    flex-direction: column;
    height: auto;
    background: var(--alternative-bg-color);
    padding-bottom: 32px;
  }
  @media (max-width: 769px) {
    padding: 24px 24px;
  }
`;
export const Col1 = styled.div`
  width: 50%;
  text-align: left;
  @media (max-width: 1279px) {
    width: auto;
  }
`;
export const Text1 = styled.div`
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  span {
  }
  span:first-of-type {
    margin-right: 10px;
    margin-bottom: 2px;
    vertical-align: middle;
  }
`;
export const Text2 = styled.div`
  font-family: ferrari-sans-regular;
  margin-top: 32px;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  @media (max-width: 1279px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const Text3 = styled.div`
  margin-top: 16px;
  font-size: 13px;
  line-height: 20px;
  @media (max-width: 1279px) {
    font-size: 11px;
  }
`;
export const Col2 = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 36px;
  width: 50%;
  img {
    height: 197px;
    width: 350px;
    padding-top: 0;
  }
  @media (max-width: 1279px) {
    width: 279.5px;
    height: 162.2556610107422px;
    padding: 0;
    max-width: 100%;
    img {
      padding: 0;
      width: 279.5px;
      height: 162.2556610107422px;
    }
  }
`;
export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 32px;
  a {
    color: var(--cta-color);
    display: flex;
    align-items: center;
  }
  a > span.span-cta-icon {
    width: 20px;
    height: 20px;
    border-color: rgb(239, 237, 237);
  }
`;

export const OverflowAnimation = styled.div`
  height: auto;
  overflow: hidden;
  padding-bottom: 3px;
`;
