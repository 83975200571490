import PropTypes from "prop-types";

import { WebinarH1 } from "./style";

function WebinarTitle({ data }) {
  return <WebinarH1>{data}</WebinarH1>;
}

WebinarTitle.propTypes = {
  data: PropTypes.string,
};

export default WebinarTitle;
