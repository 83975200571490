import { Container, Title } from "./style";
import React from "react";

import { CardsRow } from "./CardsRow/CardsRow";

function GridThreeColumns800({ data }) {
  return (
    <>
      <Container>
        <Title>{data?.title}</Title>
        {React.Children.toArray(
          data?.rows?.map((row) => {
            return <CardsRow data={row} />;
          })
        )}
      </Container>
    </>
  );
}

export default GridThreeColumns800;
