import styled from "styled-components";

export const Container = styled.section`
  width: 800px;
  margin: 0 auto 90px;
  @media (max-width: 1279px) {
    width: 90%;
    margin: 0 auto 40px;
  }
`;

export const ScrollButtons = styled.div`
  position: fixed;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  img {
    cursor: pointer;
  }
  @media screen and (max-width: 1279px) {
    right: 5px;
  }
`;
