import { createSlice } from "@reduxjs/toolkit";

export const FORM_ISCRIZIONE_SCOPE = "FormIscrizioneMondiali";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
  guestLimit: 4,
  bodyPayload: {},
  savedBodyPayload: {},
  checkedConsents: [],
};

const FormIscrizioneSlice = createSlice({
  name: FORM_ISCRIZIONE_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setBodyPayload(state, action) {
      state.bodyPayload = action.payload;
    },
    initEventInfo(state, action) {
      const data = action.payload.data;

      state.savedBodyPayload = {
        guid: data?.userSubscriptions?.[0]?.guid,
        id: data?.userSubscriptions?.[0]?.id,
        eventCode: data?.event?.code,
        numGuests: data?.userSubscriptions?.[0]?.tickets?.length - 1,
        appointmentId: data?.userSubscriptions?.[0]?.appointmentId || null,
        optionsValue: [],
        isNew: data?.userSubscriptions?.length > 0 ? false : true,
        consents: data?.event?.consents,
      };
      state.bodyPayload = state.savedBodyPayload;
    },
    setCheckedConsents(state, action) {
      state.checkedConsents = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormDataMondiali: () => initialState,
  },
});

export const {
  setComponents,
  setCheckedConsents,
  setError,
  setStatusNull,
  initEventInfo,
  setBodyPayload,
  resetFormDataMondiali,
} = FormIscrizioneSlice.actions;
export default FormIscrizioneSlice;
