import styled from "styled-components";

export const Wrapper = styled.div`
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  ${(props) =>
    props.pageLight !== "dark"
      ? ` background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 5e-5) 100%
    ),
    radial-gradient(
      332.36% 111.2% at 49.87% 0%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    #303030`
      : ` background : radial-gradient(199.89% 50% at 50% 100%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 5e-05) 100%), radial-gradient(615.7% 206% at 50% 206%, rgba(255, 255, 255, 0.5) 0%, rgba(24, 24, 24, 0.5) 100%), #303030;`};
  background-blend-mode: overlay, overlay, normal;
`;

export const Title = styled.div`
  font-family: ferrari-sans-regular, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;

  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  color: rgb(255, 255, 255);
  padding-top: 22.5px;
`;

export const Timer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const TimerSlot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  & > div {
    font-weight: 400;
    color: #ffff;
    text-transform: uppercase;
  }
  & > label {
    font-weight: 400;
    color: #ffff;
    text-transform: uppercase;
  }
`;

export const Value = styled.div`
  font-size: 28px;
  line-height: 34px;
`;
export const ValueLabel = styled.label`
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.25px;
`;
