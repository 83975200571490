import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  .th-video {
    width: 100%;
    aspect-ratio: 16/9;
    height: 100%;
  }
  video,
  img {
    width: 100%;
  }
  video {
    aspect-ratio: 16/9;
    display: block;
  }
  .th-imagegallery {
    aspect-ratio: 16/9;
  }
`;
