import { createSlice } from "@reduxjs/toolkit";

export const FORM_CONTRAFFAZIONI_SCOPE = "FormContraffazioni";

export const initialState = {
  components: {},
  checkedConsents: [],
  status: null,
  errorMessage: "",
  showThankYouPage: false,
  displayError: null,
  currentPlace: {
    cap: "",
    address: "",
    region: "",
    city: "",
  },
  notRequired: {
    note: "",
    typeProduct: "",
    numberProducts: "",
  },
  bodyPayload: {
    country: "IT",
    telephone: "",
    onSearchAddress: "",
    pointOfSale: "",
    pointOfSaleAddress: "",
    requestFrom: "web",
  },
};

const FormContraffazioniSlice = createSlice({
  name: FORM_CONTRAFFAZIONI_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setCheckedConsents(state, action) {
      state.checkedConsents = action.payload;
    },
    setBodyPayload(state, action) {
      state.bodyPayload = action.payload;
    },
    setNotRequired(state, action) {
      state.notRequired = action.payload;
    },
    setCurrentPlace(state, action) {
      state.currentPlace = action.payload;
    },
    setDisplayError(state, action) {
      state.displayError = action.payload;
    },
    setShowThankYouPage(state, action) {
      state.showThankYouPage = action.payload;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormContraffazioni: () => initialState,
  },
});

export const {
  setComponents,
  setCheckedConsents,
  setError,
  setStatusNull,
  setBodyPayload,
  setNotRequired,
  setCurrentPlace,
  setDisplayError,
  setShowThankYouPage,
  resetFormContraffazioni,
} = FormContraffazioniSlice.actions;
export default FormContraffazioniSlice;
