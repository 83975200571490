import { Carousel } from "react-responsive-carousel";
import CtaIcon from "../ctaIcon/CtaIcon";
import {
  Wrapper,
  Controllers,
  Icon,
  CarouselLeft,
  CarouselRight,
} from "./style";
import LeftIcon from "../../assets/left";
import RightIcon from "../../assets/right";
import { useState } from "react";
import StopIcon from "../../assets/stop";
import { useRef } from "react";
import PlayIcon from "../../assets/play";

function CarouselWrapper({ children, centerMode = false, itemsLength }) {
  const [autoplay, setAutoplay] = useState(true);
  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    const defStyle = {
      color: "white",
      cursor: "pointer",
      width: "8px",
      height: "8px",
    };
    return itemsLength > 1 ? (
      isSelected ? (
        <CtaIcon
          onClickHandler={onClickHandler}
          index={index}
          value={index}
          key={index}
          label={label}
          style={defStyle}
          inset={"-2px"}
          strokeWidth={"2px"}
          animationCountDown={true}
          animationCountDownTime={"5s"}
        ></CtaIcon>
      ) : (
        <span
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
          aria-label={`${label} ${index + 1}`}
          style={{
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <span
            className="carousel-dot"
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "100%",
              backgroundColor: "var(--carousel-dot-color)",
            }}
          ></span>
        </span>
      )
    ) : (
      <></>
    );
  };
  const loader = useRef(null);
  return (
    <Wrapper ref={loader}>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        autoPlay={autoplay}
        interval={5000}
        centerMode={centerMode}
        infiniteLoop={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        stopOnHover={false}
        centerSlidePercentage={65}
        transitionTime={1000}
        renderIndicator={renderIndicator}
        animationHandler={"fade"}
        renderArrowPrev={(onClickHandler, hasPrev, label) => (
          <CarouselLeft
            className="carousel-arrow-left"
            onClick={onClickHandler}
          >
            <LeftIcon></LeftIcon>
          </CarouselLeft>
        )}
        renderArrowNext={(onClickHandler, hasNext, label) => (
          <CarouselRight
            className="carousel-arrow-right"
            onClick={onClickHandler}
          >
            <RightIcon></RightIcon>
          </CarouselRight>
        )}
      >
        {children}
      </Carousel>
      {itemsLength > 1 ? (
        <Controllers className="carousel-custom-controllers">
          <Icon
            role="button"
            id="togglePlayBtn"
            tabIndex="0"
            aria-label="stop"
            onClick={(e) => {
              const btn = document.getElementById("togglePlayBtn");
              autoplay
                ? btn.setAttribute("aria-label", "stop")
                : btn.setAttribute("aria-label", "play");
              setAutoplay(!autoplay);
              const dots = loader.current.querySelectorAll(
                ".control-dots circle"
              );
              dots?.forEach((v) => {
                v.classList.toggle("paused");
              });
            }}
          >
            {autoplay ? <StopIcon></StopIcon> : <PlayIcon></PlayIcon>}
          </Icon>
          <Icon
            role="button"
            tabIndex={0}
            aria-label="back"
            onClick={(e) => {
              loader.current?.querySelector(".carousel-arrow-left").click();
            }}
          >
            <LeftIcon></LeftIcon>
          </Icon>
          <Icon
            role="button"
            tabIndex={0}
            aria-label="next"
            onClick={(e) => {
              loader.current?.querySelector(".carousel-arrow-right").click();
            }}
          >
            <RightIcon></RightIcon>
          </Icon>
        </Controllers>
      ) : (
        <></>
      )}
    </Wrapper>
  );
}

export default CarouselWrapper;
