import { takeLatest, put, select } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import {
  FETCH_CORE_COMPONENTS,
  SET_LANGUAGE,
  SET_HEADER_LINKS,
} from "./constants";
import { coreComponentsActions } from "./coreComponentsSlice";
import { selectLanguage } from "./selectors";

function* fetchCoreComponents({ payload: token }) {
  try {
    const lang = yield select(selectLanguage());
    const path = `/${lang}/_jcr_content.core`;
    const result = yield axiosCallGet(path, token);
    yield put(coreComponentsActions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    const error = { status: 401, errorMessage: e.message };
    yield put(coreComponentsActions.setError(error));
  }
}

function* setHeaderLinks({ payload: headerLinks }) {
  try {
    yield put(coreComponentsActions.setHeaderLinks(headerLinks));
  } catch (e) {
    console.error(e);
    const error = { status: 503, errorMessage: e.message };
    yield put(coreComponentsActions.setError(error));
  }
}
function* setLanguage({ payload: lang }) {
  try {
    yield put(coreComponentsActions.setLanguage(lang));
  } catch (e) {
    console.error(e);
    const error = { status: 503, errorMessage: e.message };
    yield put(coreComponentsActions.setError(error));
  }
}

export default function* coreComponentsSaga() {
  yield takeLatest(FETCH_CORE_COMPONENTS, fetchCoreComponents);
  yield takeLatest(SET_LANGUAGE, setLanguage);
  yield takeLatest(SET_HEADER_LINKS, setHeaderLinks);
}
