import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./FormAnnuncioSlice";

const State = (state) => state.FormAnnuncio || initialState;

export const getForm = () =>
  createSelector(State, (state) => {
    return state.components?.components?.filter(
      (v) => v.resourceType === "fi-ads/fi-ads-form"
    )?.[0];
  });
