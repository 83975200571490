import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  setComponents,
  setError,
  initBodyPayload,
} from "./FormIscrizioneFamilyDaySlice";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/notifications/TriggerNotification";
import { FETCH_COMPONENTS, FETCH_SET_EVENT_PREFERENCES } from "./constants";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-form-family-day.json")
    // );
    yield put(setComponents(result.data.data));
    const eventInfoData = result?.data?.data?.components?.find(
      (el) => el.resourceType === "fi-events/fi-event-subscription-family-day"
    );

    if (eventInfoData?.data?.event?.error403) {
      yield put(setError({ status: 403, message: "Unauthorized" }));
      return;
    }
    yield put(initBodyPayload(eventInfoData));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export function* SetUserEventPreferences({ payload }) {
  try {
    const token = payload.token;
    document.body.classList.add("loading");
    yield axiosCallPost(
      `/${payload.language}/news-and-events/events/subscribe?title=family-day`,
      payload.bodyPayload,
      token
    );
    window.location.replace(payload.path);
  } catch (e) {
    const messageError =
      payload.language === "it"
        ? "Errore nel salvataggio dati"
        : "Error while saving data";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(FETCH_SET_EVENT_PREFERENCES, SetUserEventPreferences);
}
