import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  img {
    height: 24px;
    width: 18px;
    margin-right: 10px;
  }
  a {
    text-decoration: none;
    color: var(--main-color);
    cursor: pointer;
  }
`;
