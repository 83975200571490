import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;
  img {
    max-width: 100%;
  }
`;

export const TitleEmptyFav = styled.div`
  font-size: 24px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-transform: uppercase;
`;

export const TextEmptyFav = styled.div`
  text-align: center;
  font-size: 13px;
  line-height: 20px;
`;
