import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  toolsMap: [],
  favToolsMap: [],
  showToolsDialog: false,
  status: null,
  errorMessage: "",
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setToolBoxes(state, action) {
      const tools = action.payload.find(
        (v) => v.resourceType === "fi-tools-catalogue"
      );
      state.toolsMap = tools?.data.tools;
      state.favToolsMap = state.toolsMap.filter(
        (tool) => tool.isFavourite === true || tool.isDefault === true
      );
    },
    setFavToolsMap(state, action) {
      if (action.payload.isDefault) return;

      state.favToolsMap = action.payload;
    },
    orderByFav(state) {
      const favouritesList = state.toolsMap.filter(
        (v) => v.isFavourite === true
      );
      const nonFavouriteList = state.toolsMap.filter(
        (v) => v.isFavourite === false
      );

      const orderFavList = favouritesList.sort((a, b) =>
        a.title > b.title ? 1 : -1
      );

      const orderNonFavList = nonFavouriteList.sort((a, b) =>
        a.title > b.title ? 1 : -1
      );

      state.toolsMap = [...orderFavList, ...orderNonFavList];
    },
    setFavouriteTool(state, action) {
      const newItem = state.toolsMap.find((v) => v.id === action.payload.id);
      if (newItem.isDefault) {
        return;
      } else if (newItem.isFavourite) {
        newItem.isFavourite = !newItem.isFavourite;
        state.favToolsMap = state.favToolsMap.filter(
          (tool) => tool.id !== newItem.id
        );
      } else {
        newItem.isFavourite = !newItem.isFavourite;
        state.favToolsMap.push(newItem);
      }
    },
    removeToolFromFavMap(state, action) {
      const tool = action.payload;

      if (tool.isDefault) return;

      state.favToolsMap = state.favToolsMap.filter(
        (element) => element.id !== tool.id
      );
      const toolFromMap = state.toolsMap.find((el) => el.id === tool.id);
      toolFromMap.isFavourite = !toolFromMap.isFavourite;
    },
    orderTools(state, action) {
      state.toolsMap = action.payload;
    },
    setShowToolsDialog(state, action) {
      state.showToolsDialog = action.payload;
      // document
      //   .getElementById("toolsIcon")
      //   .setAttribute("aria-expanded", action.payload);
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const toolsActions = toolsSlice.actions;
export default {
  name: toolsSlice.name,
  reducer: toolsSlice.reducer,
};
