import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { contactUsActions } from "../../../../../pages/ContactUs/contactUsSlice";
// components
import ContactForm from "../../contactForm/ContactForm";
// style
import { Container } from "./style";
import Freccia from "../../../../assets/freccia";

function ContactCard({ contactData }) {
  const dispatch = useDispatch();
  const containerCardRef = useRef();

  const tabId = useSelector((state) => state.contactus.tabId);

  const handleOnClick = (id) => {
    if (containerCardRef.current.classList.contains("selected")) {
      dispatch(contactUsActions.setTabId(null));
    } else {
      dispatch(contactUsActions.setTabId(id));
    }
  };

  return (
    <>
      <Container
        ref={containerCardRef}
        className={tabId === contactData.id && "selected"}
        onClick={() => handleOnClick(contactData.id)}
      >
        <img src={contactData?.image} alt="svg-icon" className="svg-image" />
        <div>{contactData?.title}</div>
        <p>{contactData?.description}</p>
        <Freccia />
      </Container>
      {tabId === contactData.id && <ContactForm data={contactData} />}
    </>
  );
}

export default React.memo(ContactCard);
