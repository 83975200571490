// components
import React from "react";
import CarouselWrapper from "../../../UI/carouselWrapper/CarouselWrapper";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";
import useMediaQuery from "../../../../hooks/useMediaQuery";
// style
import {
  Wrapper,
  Text1,
  Text2WrapperWrapper,
  Text2Wrapper,
  Frame,
  Text2Title,
  Gradient1,
  Gradient2,
  Chapter,
} from "./style";
import linkTarget from "../../../../utils/linkTarget";

function MainCarousel({ data }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <Wrapper>
      <CarouselWrapper itemsLength={data?.items.length}>
        {React.Children.toArray(
          data?.items?.map((item) => (
            <div>
              <a
                href={SetQueryStringPath(item?.linkHref) || "/news"}
                target={linkTarget(item?.linkHref)}
              >
                {item.section && (
                  <Text1>
                    <span>⏤</span>
                    <span>{item.section}</span>
                  </Text1>
                )}
                <Text2WrapperWrapper>
                  <Text2Wrapper className="home-text-animation">
                    <Chapter>
                      <div>{item?.dateLabel}</div>
                      {item?.dateLabel && <Frame></Frame>}
                      <div>{item?.category}</div>
                    </Chapter>
                    <Text2Title>{item.title}</Text2Title>
                  </Text2Wrapper>
                </Text2WrapperWrapper>
                <Gradient1></Gradient1>
                <Gradient2></Gradient2>
                {isDesktop ? (
                  <img src={item.fileReference} />
                ) : (
                  <img src={item.fileReferenceMobile || item.fileReference} />
                )}
              </a>
            </div>
          ))
        )}
      </CarouselWrapper>
    </Wrapper>
  );
}

export default MainCarousel;
