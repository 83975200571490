import styled from "styled-components";

export const DocContainer = styled.div`
  margin: 40px 0px 32px;
  padding-bottom: 24px;
  img {
    display: none;
  }
  path {
    fill: var(--main-color);
  }
  @media screen and (min-width: 1280px) {
    border-bottom: 1px solid rgb(212, 210, 210);
    margin: 96px 0px;
    img {
      display: block;
    }
  }
  @media screen and (max-width: 1279px) {
    margin: 32px 0px;
  }
`;

export const DocContainerTitle = styled.div`
  margin-bottom: 32px;
  color: var(--main-color);
  text-transform: uppercase;
  font-size: 12px;
`;
