import React from "react";
import { QrCodeContainer, QrCodeImg, QrCodeText } from "./style";
import { useSelector } from "react-redux";

export function QrCode({ data }) {
  const theme = useSelector((state) => state.coreComponents.pageLight);
  function generateHtmlTitle(argument) {
    return { __html: argument };
  }

  return (
    <>
      <QrCodeContainer>
        <QrCodeImg light={theme === "dark" ? true : false}>
          <img data-cs-mask src={data?.qrCode} />
        </QrCodeImg>
        <QrCodeText
          dangerouslySetInnerHTML={generateHtmlTitle(data?.richText)}
        />
      </QrCodeContainer>
    </>
  );
}

export default React.memo(QrCode);
