//hooks
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchComponents } from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";

// selectors
import { selectAggregatedDirection } from "../MyPage/selectors";

// components
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import HtmlBox from "../../components/UI/htmlBox/htmlBox";
import LayoutMap3d from "../../components/UI/map3d/LayoutMap3d";

// utils
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";

function Map3d({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector((state) => state.map3d.components);
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("map3d");

  const componentsMapping = {
    "fi-html-box": HtmlBox,
  };

  const initialStatePotrait =
    window.innerHeight > window.innerWidth ? true : false;
  const initialIsCanvasBP = window.innerWidth < 990;

  const headerHeight = document.querySelector?.("header")?.offsetHeight;
  const footerHeight = document.querySelector?.("footer")?.offsetHeight;

  const [isPortrait, setIsPortrait] = useState(initialStatePotrait);
  const [lastHeight, setLastHeigth] = useState();
  const [isCanvasBreakPoint, setIsCanvasBreakPoint] =
    useState(initialIsCanvasBP);

  const headerAndFooterHeight =
    Number(headerHeight + footerHeight) || lastHeight;
  !isPortrait
    ? document.querySelector("body").setAttribute("style", "overflow: hidden;")
    : document.querySelector("body").setAttribute("style", "overflow: auto;");

  // responsive se è mappa 3d e siamo in potrait mode allora footer visibile ed orizzontale
  // responsive se è mappa 3d e siamo in landscape mode allora nascondiamo header e footer e diamo altezza 100

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      const { innerHeight, innerWidth } = e.target.window;
      setIsCanvasBreakPoint(innerWidth < 990);
      setIsPortrait(innerHeight > innerWidth);
    });
    dispatch(coreComponentsActions.setIsHorizontalFooter(true));

    if (isCanvasBreakPoint && !isPortrait) {
      setLastHeigth(headerAndFooterHeight);
      dispatch(coreComponentsActions.setHideNavAndFooter(true));
    } else {
      dispatch(coreComponentsActions.setHideNavAndFooter(false));
      setLastHeigth(headerAndFooterHeight);
    }
  }, [isCanvasBreakPoint, isPortrait, dispatch]);
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components?.components && (
          <LayoutMap3d headerAndFooterHeight={headerAndFooterHeight}>
            {components?.components?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                  />
                )
              );
            })}
          </LayoutMap3d>
        )
      )}
    </>
  );
}

export default Map3d;
