import { takeLatest, put, select } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS } from "./constants";
import { companyComActions } from "./companyComSlice";

function* fetchComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);

    yield put(companyComActions.setComponents(result.data.data));

    const tabsObject = yield select(
      (state) =>
        state.companyCom.components.components.find(
          (el) => el.resourceType === "fi-corporate-comunications-catalogue"
        ).data
    );
    const tabsMap = [
      {
        category: "internal-comunications",
        title: tabsObject.internalComunicationsTitle,
      },
      {
        category: "organizational-arrangements",
        title: tabsObject.organizationalArrangementsTitle,
      },
    ];
    yield put(companyComActions.setTabsMap(tabsMap));
    yield put(companyComActions.setDocuments(tabsObject.items));
  } catch (e) {
    console.error(e);
    yield put(companyComActions.setError(e));
  }
}

export default function* corporateComSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponents);
}
