import styled from "styled-components";

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: none;
  z-index: 10;
  position: fixed;
  inset: 0px;
  h2 {
    color: #181818;
  }
`;

export const DialogOverlay = styled.div`
  background-color: rgba(43, 46, 56, 1);
  position: fixed;
  inset: 0px;
  opacity: 0.4;
  transition: opacity 0.3s ease 0s;
`;

export const CloseButton = styled.button`
  @media (min-width: 1280px) {
    position: absolute;
    top: 50%;
    right: calc(600px + 48px); // 600 is the DialogContent width
    transform: translate(0px, -50%);
    width: 40px;
    height: 40px;
    box-sizing: content-box;
    z-index: 14;
    transition: transform 0.3s ease 0s;
  }
  @media (max-width: 1279px) {
    position: inherit;
    width: 100%;
    top: 5%;
    z-index: 3;
  }
  border: 0px;
  cursor: pointer;
  img {
    max-width: 100%;
  }
`;
