import styled from "styled-components";

export const Wrapper = styled.div`
  width: 800px;
  padding: 32px 0px 32px 0px;
  border-bottom: 1px solid #d4d2d2;
  cursor: pointer;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
  line-height: 26px;
`;

export const Description = styled.p`
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #707070;
`;

export const DateSpan = styled.span`
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 400;
  text-transform: uppercase;
`;
