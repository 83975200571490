import { NewsTagContainer } from "./style";
import PropTypes from "prop-types";

function NewsTag({ data }) {
  return <NewsTagContainer>{data}</NewsTagContainer>;
}

NewsTag.propTypes = {
  data: PropTypes.string,
};

export default NewsTag;
