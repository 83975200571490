import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: auto;
  width: 800px;
  padding: 32px;
  gap: 32px;
  color: var(--cta-color);
  @media screen and (max-width: 1279px) {
    width: 100%;
    padding: 32px 0 0;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  font-size: 13px;
  text-align: center;
  > h1 {
    text-transform: capitalize;
  }
  @media (max-width: 1279px) {
    h1 {
      font-size: 22px;
      line-height: 28px;
    }
    h1,
    div {
      padding: 0 32px;
    }
  }
`;

export const ServiceActions = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  gap: 32px;
  line-height: 15px;
  letter-spacing: 1px;
  justify-content: center;
  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const ActionLink = styled(Link)`
  text-align: center;
  padding: 20px;
  cursor: pointer;
  color: var(--cta-color);
  border: 1px solid var(--carousel-dot-color);
`;
