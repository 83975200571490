import {
  FavoriteDishDiv,
  FavoriteDishNumber,
  FavoriteDishWrapper,
  FavoriteDishType,
  FavoriteDishName,
  FavoriteDishLikes,
  FavoriteDishLikesNumber,
} from "./style";

function FavoriteDish({ dish }) {
  return (
    <FavoriteDishDiv className="flex-align-center">
      <FavoriteDishNumber>{dish.dishPosition}</FavoriteDishNumber>
      <FavoriteDishWrapper>
        <FavoriteDishType className="grey-scale-black-50">
          {dish.category}
        </FavoriteDishType>
        <FavoriteDishName>{dish.name}</FavoriteDishName>
      </FavoriteDishWrapper>
      <FavoriteDishLikes className="flex-align-center desktop-body-medium">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99621 14.4524L1.63144 7.69247L1.63136 7.69238C0.335298 6.31639 0.322764 4.10129 1.59515 2.71227L1.63131 2.67395C2.91954 1.30871 4.97493 1.30865 6.26343 2.67398C6.26343 2.67398 6.26344 2.67398 6.26344 2.67398L7.53108 4.01724L8.0038 4.51815L8.47653 4.01725L9.73634 2.68237L9.73638 2.68233C11.0246 1.31708 13.08 1.31703 14.3685 2.68236C15.6772 4.06905 15.6772 6.31381 14.3685 7.7005L14.8412 8.14662L14.3685 7.7005C13.2818 8.85199 12.195 10.0035 11.1082 11.155C10.0708 12.2542 9.03346 13.3533 7.99621 14.4524Z"
            fill="var(--main-color)"
            stroke="var(--main-color)"
            strokeWidth="1.3"
          />
        </svg>
        <FavoriteDishLikesNumber>{dish.likeNum}</FavoriteDishLikesNumber>
      </FavoriteDishLikes>
    </FavoriteDishDiv>
  );
}

export default FavoriteDish;
