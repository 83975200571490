import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CameraIcon from "../../assets/camera";
import Compressor from "compressorjs";

const baseStyle = {
  border: "dashed #8F8F8F",
  display: "flex",
  color: "#8F8F8F",
  flexDirection: "column",
  gap: "15px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "11px",
  lineHeight: "20px",
  letterSpacing: "1px",
  outline: "none",
  cursor: "pointer",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  border: "dashed #2196f3",
};

const acceptStyle = {
  border: "dashed #00e676",
};

const rejectStyle = {
  border: "dashed #ff1744",
};

export default function MyDropzone({
  onChange,
  opacity = 1,
  className = "",
  inputName = "",
  uploadText = false,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      new Compressor(file, {
        quality: 0.6,
        width: 512,
        success(result) {
          onChange(result);
        },
        error(err) {
          console.error(err.message);
        },
      });
    });
  }, []);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ accept: { "image/*": [] }, onDrop });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div {...getRootProps({ style })} className={className}>
      <input {...getInputProps()} />
      <CameraIcon></CameraIcon>
      {uploadText && (
        <div style={{ maxWidth: "200px", textAlign: "center" }}>
          Trascina qui le immagini o clicca per selezionarle
        </div>
      )}
    </div>
  );
}
