// style
import { Wrapper } from "./style";

import PropTypes from "prop-types";
import CafeteriaCard from "../cafeteriaCard/CafeteriaCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CafeteriaRules from "./cafeteriaRules/CafeteriaRules";
import CafeteriaContacts from "./cafeteriaContacts/CafeteriaContacts";
import CafeteriaMap from "./cafeteriaMap/CafeteriaMap";
// import CtaIcon from "../../ctaIcon/CtaIcon";
import CarouselWrapper from "../../carouselWrapper/CarouselWrapper";

const EVENT_CARD_CAFETERIA_NAME = "cafeteria-card";

function CafeteriaDetails({ data }) {
  const { cafeteriaRule, cafeteriaMap, cafeteriaCards, cafeteriaContacts } =
    data;

  return (
    <div className="cafeteria-slot-1">
      <Wrapper>
        <CafeteriaRules cafeteriaRule={cafeteriaRule} />
        <CafeteriaContacts cafeteriaContacts={cafeteriaContacts} />
        <CafeteriaMap cafeteriaMap={cafeteriaMap} />
      </Wrapper>
      {/* desktop */}
      <div className="cafeteria-cards">
        {cafeteriaCards.items.map((currentCard, idx) => (
          <CafeteriaCard
            key={`${EVENT_CARD_CAFETERIA_NAME} - ${idx}`}
            cafeteriaCardProps={currentCard}
          />
        ))}
      </div>
      {/* mobile  */}
      <div className="cafeteria-cards-mobile">
        <CarouselWrapper itemsLength={cafeteriaCards?.items.length}>
          {cafeteriaCards.items?.map((currentCard, idx) => (
            <CafeteriaCard
              key={`${EVENT_CARD_CAFETERIA_NAME} - ${idx}`}
              cafeteriaCardProps={currentCard}
            />
          ))}
        </CarouselWrapper>
      </div>
    </div>
  );
}
CafeteriaDetails.propTypes = {
  data: PropTypes.exact({
    cafeteriaRule: PropTypes.object,
    cafeteriaMap: PropTypes.object,
    cafeteriaCards: PropTypes.object,
    cafeteriaContacts: PropTypes.object,
    position: PropTypes.string,
  }),
};

export default CafeteriaDetails;
