import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1280px) {
    width: 700px;
    padding: 48px;
  }
  position: relative;
  z-index: 10;
  margin: 0px;
  box-sizing: border-box;
  /* background: rgb(247, 247, 247); */
  background: var(--alternative-bg-color);
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 32px;
  width: 100%;
  overflow: auto;
  padding: 48px;
  @media (max-width: 1279px) {
    > h2 {
      font-size: 20px;
    }
  }
`;

export const NotificationsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 96px;
  @media (max-width: 1279px) {
    padding: 0px 0px 32px;
  }
  span {
    margin-top: 64px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    color: var(--carousel-dot-color);
    @media (max-width: 1279px) {
      margin-top: 32px;
    }
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* position: absolute;
  bottom: 36px; */
  span {
    color: var(--main-color);
  }
`;

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(239, 237, 237);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  a:hover {
  }
`;
