import { FETCH_BASKETS, FETCH_TAKE_AWAY_COMPONENTS } from "./constants";

export const fetchBaskets = () => ({
  type: FETCH_BASKETS,
});

export const fetchTakeAwayComponents = (payload) => ({
  type: FETCH_TAKE_AWAY_COMPONENTS,
  payload,
});
