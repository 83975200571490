import React from "react";
import PropTypes from "prop-types";
// style
import { Title, Place, Street, Img, Divider } from "./style";

function EventPlace({ elementData }) {
  return (
    <>
      <Divider />
      <Title>{elementData?.title}</Title>
      {React.Children.toArray(
        elementData?.items?.map((element) => {
          return (
            <>
              <Place>{element.routeTitle}</Place>
              <Street
                dangerouslySetInnerHTML={{ __html: element.richText }}
              ></Street>
              <Img src={element.image} />
            </>
          );
        })
      )}
    </>
  );
}

EventPlace.propTypes = {
  elementData: PropTypes.exact({
    title: PropTypes.string,
    items: PropTypes.array,
    position: PropTypes.string,
  }),
};

export default EventPlace;
