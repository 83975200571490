import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./mensaSlice";

const mensaState = (state) => state.mensa || initialState;

export const selectDishType = () =>
  createSelector(mensaState, (state) => state.dishType);

export const selectDishesByCategory = (category) =>
  createSelector(mensaState, (state) =>
    state.dishes.filter((dish) => dish.category === category)
  );
