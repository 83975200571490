import styled from "styled-components";

export const ContainerTabs = styled.div`
  display: flex;
  place-content: center;
  margin: 46px 0px 66px;
  padding: 0px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;
export const ButtonTab = styled.button`
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 1px;
  padding: 12px 32px;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    color: rgb(218, 41, 28);
    border-bottom: 2px solid rgb(218, 41, 28);
  }
  ${(props) =>
    props.active
      ? "color: rgb(218, 41, 28);border-bottom: 2px solid rgb(218, 41, 28);"
      : "color: rgb(112, 112, 112);"};
`;

export const SelectWrapperWrapper = styled.div`
  display: block;
  width: 90%;
  margin: auto;
}
  @media (min-width: 1280px) {
    display: none;
  }
  & > div {
    width: 100%;
    margin: 46px 0px;
  }
  select {
    text-transform: uppercase;
    font-size: 12px;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
`;

export const Select = styled.select`
  cursor: pointer;
  width: 100%;
  appearance: none;
  position: relative;
  ${(props) => (props.noborder ? "border:none" : "")};
  &,
  input,
  option {
    font-family: ferrari-sans-regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 12px 16px 14px;
    color: var(--main-color);
    max-width: 100%;
    background: transparent;
  }
  &.placeholder {
    color: rgb(112, 112, 112) !important;
  }
  select:not(:-internal-list-box) {
    overflow: visible !important;
  }
`;

export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;
