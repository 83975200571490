import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: [],
  settings: {},
  welfareCardList: [],
  status: null,
  errorMessage: "",
  tags: null,
  cardsList: null,
  inputText: "",
};

const welfareSlice = createSlice({
  name: "welfare",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload.components;
      state.settings = action.payload.settings;
      const cardsArray = state.components.find(
        (el) => el.resourceType === "fi-welfare/fi-welfare-catalogue"
      );
      state.welfareCardList = cardsArray.data.results;
    },
    setWelfareCardList(state, action) {
      state.welfareCardList = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const welfareActions = welfareSlice.actions;
export default {
  name: welfareSlice.name,
  reducer: welfareSlice.reducer,
};
