import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import LayoutSiteMap from "../../components/UI/siteMap/LayoutSiteMap";
import Title from "../../components/UI/siteMap/title/Title";
import ListMap from "../../components/UI/siteMap/listMap/ListMap";
// actions
import { fetchCoreComponents } from "../../utils/getCoreComponents/actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function SiteMap() {
  const dispatch = useDispatch();

  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const { components } = useSelector((state) => state.coreComponents);

  useEffect(() => {
    dispatch(coreComponentsActions.setHideNavAndFooter(true));
    !components && dispatch(fetchCoreComponents(token));
  }, [components, dispatch, token]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, "Site map", "/site-map");
  return (
    <>
      {components && (
        <LayoutSiteMap>
          <Title />
          <ListMap data={components} />
        </LayoutSiteMap>
      )}
    </>
  );
}

export default SiteMap;
