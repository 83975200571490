import CafeteriaCard from "../../../mensa/cafeteriaCard/CafeteriaCard";
function CardWrapper({ data }) {
  return (
    <>
      {data?.items?.map((v, i) => (
        <CafeteriaCard cafeteriaCardProps={v} key={i}></CafeteriaCard>
      ))}
    </>
  );
}

export default CardWrapper;
