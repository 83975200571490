//hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//utils
import { fetchComponents } from "./actions";
//components
import Title from "../../components/UI/welfare/title/Title";

import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import LayoutWelfare from "../../components/UI/welfare/LayoutWelfare";
import WelfareFocus from "../../components/UI/welfare/welfareFocus/WelfareFocus";
import Catalouge from "../../components/UI/welfare/catalogue/Catalouge";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function Welfare({ path }) {
  const dispatch = useDispatch();
  const welfareComponents = useSelector((state) => {
    return state.welfare.components;
  });
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const token = useSelector((state) => state.login.activeToken);
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const { status, errorData } = GetStatusAndErrorData("welfare");

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(true));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const componentsMapping = {
    "fi-title": Title,
    "fi-welfare/fi-welfare-focus": WelfareFocus,
    "fi-welfare/fi-welfare-catalogue": Catalouge,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        welfareComponents && (
          <LayoutWelfare>
            {welfareComponents?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutWelfare>
        )
      )}
    </>
  );
}

export default Welfare;
