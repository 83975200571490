import styled from "styled-components";

export const DetailContainerData = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
p{
    font-size: 13px;
    color: #181818;
    line-height: 20px;
    color: var(--main-color);
}
`;
export const DetailDataAddress = styled.p`
width: 100%;
display: flex;
flex-wrap: wrap;
color: var(--main-color);
`

export const DetailDataLabel = styled.div`
font-size: 11px;
color: #8f8f8f;
margin: 0px;
letter-spacing: 1px;
line-height: 20px;
`;
