import { AsideSlot, FocusContainer } from "./style";
import PropTypes from "prop-types";
//components
import NewsSlot from "./newsSlot/NewsSlot";
import CarouselWrapper from "../../carouselWrapper/CarouselWrapper";
import useMediaQuery from "../../../../hooks/useMediaQuery";
function NewsFocus({ data }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <>
      {isDesktop ? (
        <FocusContainer>
          {data?.news[0] ? <NewsSlot newsSlot={data.news[0]} /> : <></>}
          <AsideSlot>
            {data?.news[1] ? (
              <NewsSlot
                newsSlot={data.news[1]}
                secondNewsImgOverride={data.secondNewsImgOverride}
              />
            ) : (
              <></>
            )}
          </AsideSlot>
        </FocusContainer>
      ) : (
        <CarouselWrapper itemsLength={data?.news.length}>
          {data?.news[0] ? <NewsSlot newsSlot={data.news[0]} /> : <></>}
          {data?.news[1] ? <NewsSlot newsSlot={data.news[1]} /> : <></>}
        </CarouselWrapper>
      )}
    </>
  );
}
NewsFocus.propTypes = {
  data: PropTypes.exact({
    secondNewsImgOverride: PropTypes.string,
    news: PropTypes.array,
    position: PropTypes.string,
  }),
};
export default NewsFocus;
