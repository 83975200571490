import { FETCH_NEWS_BY_TEXT, FETCH_NEWS_COMPONENTS } from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_NEWS_COMPONENTS,
  payload,
});

export const fetchNewsByText = (payload) => ({
  type: FETCH_NEWS_BY_TEXT,
  payload,
});
