import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  margin: 24px 10px;
  max-width: 384px;
  overflow: hidden;
  text-align: initial;
  ${(props) => (props.link ? `cursor:pointer` : `cursor:initial`)};
  img {
    object-fit: cover;
    height: 250px;
    width: 384px;
  }
  @media (max-width: 1279px) {
    max-width: 100%;
    width: 100%;
    img {
      width: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }
`;
export const CardTitle = styled.h3`
  font-size: 24px;
  line-height: 26px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  font-weight: 400;
  text-transform: uppercase;
`;

export const CardDescription = styled.div`
  font-size: 18px;
  line-height: 26px;
`;
