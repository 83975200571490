import React from "react";
// components
import ContactCard from "./contactCard/ContactCard";
// style
import { ContainerCard, ContactFormContainer } from "./style";

function CardSection({ data }) {
  return (
    <>
      <ContainerCard>
        {React.Children.toArray(
          data?.contacts?.map((contact) => (
            <ContactCard contactData={contact} />
          ))
        )}
      </ContainerCard>
      <ContactFormContainer id="contact-form" />
    </>
  );
}

export default React.memo(CardSection);
