import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_THANK_YOU_COMPONENTS } from "./constants";
import { thankYouActions } from "./thankYouSlice";

function* fetchThankYouComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(thankYouActions.setComponents(result.data.data.components));
  } catch (e) {
    console.log(e);
    yield put(thankYouActions.setError(e));
  }
}

export default function* detailsSaga() {
  yield takeLatest(FETCH_THANK_YOU_COMPONENTS, fetchThankYouComponents);
}
