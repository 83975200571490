import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS } from "./constants";
import { fullWidthActions } from "./containerSlice";

function* fetchComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(fullWidthActions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(fullWidthActions.setError(e));
  }
}

export default function* ccSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponents);
}
