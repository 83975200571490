import { ComponentContainer, RadioButton, TextDescr } from "../style";

import { useDispatch, useSelector } from "react-redux";
import ErrorField from "./ErrorField/ErrorField";
import {
  resetMealQuantity,
  setBodyPayload,
  setRemainingMenus,
} from "../../../../pages/FormIscrizioneFamilyDay/FormIscrizioneFamilyDaySlice";

export default function NumGuests({ data, allowed, calcolateMenuQuantity }) {
  const dispatch = useDispatch();
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneFamilyDay.bodyPayload
  );
  const initNumGuest = useSelector(
    (state) => state.FormIscrizioneFamilyDay.initNumGuest
  );

  const handleNumGuests = (e) => {
    const intVal = parseInt(e.target.value);
    const selectedMenus = calcolateMenuQuantity();
    const result = intVal + 1 - selectedMenus;
    dispatch(setBodyPayload({ ...bodyPayload, numGuests: intVal }));
    if (intVal < selectedMenus) {
      dispatch(resetMealQuantity());
    } else {
      dispatch(setRemainingMenus(result));
    }
  };
  const slot = data?.event?.appointments?.event?.filter((event) =>
    event?.slots?.find(
      (slot) =>
        slot?.id === data?.userSubscriptions?.[0]?.secondaryAppointmentId
    )
  );

  const isGuestOptDisabled = (index) => {
    const ap = slot?.[0]?.slots?.[0]?.availablePlacesFrontEnd ?? 0;
    const ts = slot?.[0]?.slots?.[0]?.takenSeats ?? 0;
    const reserved = data?.userSubscriptions?.[0]?.tickets?.length ?? 1;
    return ap - ts + reserved <= index && reserved <= index;
  };

  return (
    <ComponentContainer>
      <h3>{data?.guestsLbl} </h3>
      <TextDescr>{data?.guestsTxt}</TextDescr>
      {[
        ...Array(
          data?.event?.configuration?.rules?.[0]?.guestsLimit + 1
        ).keys(),
      ].map((i) => (
        <RadioButton key={i}>
          <input
            type="radio"
            id={`accompagnatoriId${i}`}
            name={`accompagnatori ${i}`}
            defaultValue={i}
            disabled={
              data?.userSubscriptions?.[0]?.secondaryAppointmentId ===
                bodyPayload?.secondaryAppointmentId && isGuestOptDisabled(i)
            }
            checked={bodyPayload?.numGuests === i}
            onChange={(e) => handleNumGuests(e, "numGuest")}
          />
          <label htmlFor={`accompagnatoriId${i}`}>
            {i === 0 ? data?.noGuestsLbl : i}
          </label>
        </RadioButton>
      ))}
      {bodyPayload?.numGuests === null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
