import styled from "styled-components";

export const Carousel = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  img {
    max-width: 176px;
    display: block;
  }
`;
