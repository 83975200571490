import {
  FETCH_COMPONENTS,
  USER_SEGNALATION,
} from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const SetSegnalazione = (payload) => ({
  type: USER_SEGNALATION,
  payload,
});
