import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS } from "./constants";
import { offerDetailActions } from "./offerDetailSlice";

function* fetchOfferDetailComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(offerDetailActions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(offerDetailActions.setError(e));
  }
}

export default function* offerDetailSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchOfferDetailComponents);
}
