import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "rule map" "contact map";
  background: var(--alternative-bg-color);
  color: var(--main-color);
  a {
    color: var(--main-color);
  }
  > div {
    padding: 32px;
    border-color: rgb(239, 237, 237);
  }
  @media screen and (max-width: 1279px) {
    display: block;
    & > div {
      padding-bottom: 32px !important;
    }
  }
`;
