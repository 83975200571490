import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  activeToken: "",
  isInternalIp: null,
  tokenClaims: null,
  activeUser: null,
  isJustRegistered: null,
  reducedAccess: false,
  status: null,
  errorMessage: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setActiveToken(state, action) {
      state.activeToken = action.payload;
    },
    setIsInternalIp(state, action) {
      state.isInternalIp = action.payload;
    },
    setTokenClaims(state, action) {
      state.tokenClaims = action.payload;
    },
    setActiveUser(state, action) {
      state.status = 200;
      state.reducedAccess = false;
      const { isActive, isFirstRequest } = action.payload;
      state.activeUser = isActive;
      state.isJustRegistered = isFirstRequest;
    },
    setReducedAcces(state, action) {
      state.reducedAccess = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const loginActions = loginSlice.actions;
export default {
  name: loginSlice.name,
  reducer: loginSlice.reducer,
};
