import styled from "styled-components";

export const List = styled.div`
  margin: 24px 0px;
  a,
  a:visited,
  a:link {
    color: var(--main-color);
  }
  @media screen and (min-width: 1280px) {
    margin: 48px auto 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 48px 32px;
    width: 1216px;
  }
  @media screen and (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    h3 {
      font-size: 16px;
    }
  }
`;
