import React from "react";
// syle
import {
  Container,
  InfoRecapContainer,
  Slot,
  Content,
  Category,
} from "./style";

function InfoBox({ data }) {
  return (
    <Container>
      <InfoRecapContainer>
        <h3>{data.title}</h3>
        {data.richText && (
          <Content
            dangerouslySetInnerHTML={{ __html: data.richText }}
          ></Content>
        )}
        {React.Children.toArray(
          data.items?.map((el) => {
            return (
              <Slot>
                <Category>{el.title}</Category>
                {el.richText && (
                  <Content
                    dangerouslySetInnerHTML={{ __html: el.richText }}
                  ></Content>
                )}
              </Slot>
            );
          })
        )}
      </InfoRecapContainer>
    </Container>
  );
}

export default React.memo(InfoBox);
