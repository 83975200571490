import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./containerSlice";

const genericState = (state) => state.fullWidth || initialState;

export const selectTopComponents = () =>
  createSelector(genericState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "top"
      );
    }
  });

export const heroComponent = () =>
  createSelector(genericState, (state) => {
    if (state.components.components) {
      return state.components.components.find(
        (element) => element.resourceType === "fi-hero-image"
      );
    }
  });
