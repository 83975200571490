import styled from "styled-components";

export const Container = styled.div`
  min-width: 384px;
  width: 384px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const DocumentsWrapper = styled.div`
  background: rgb(255, 255, 255);
  padding: 24px;
  .doc-container {
    margin: 0;
  }
  .doc-container:last-child {
    border: 0;
  }
  .doc-container__title {
    font-size: 18px;
    line-height: 26px;
  }
  .doc-title-and-data {
    margin-left: 0;
  }
  .doc-title-and-data h4 {
    margin-top: 0;
  }
`;
