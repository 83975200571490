import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// style
import { Wrapper } from "./style";

function Button({ data }) {
  return (
    <Wrapper>
      <Link to="/logout">{data.title}</Link>
    </Wrapper>
  );
}
Button.propTypes = {
  data: PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default Button;
