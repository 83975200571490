import styled from "styled-components";

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Text1 = styled.p`
  font-size:80px;
  font-family:"ferrari-sans-medium", sans-serif
`;
export const Text2 = styled.p`
  font-size:16px;
  text-transform:uppercase;
  font-family:"ferrari-sans-medium", sans-serif
`;
export const Button = styled.div`
  margin-top: 10vh;
  a{
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-decoration: none;
    padding: 19px 21px 23px 21px;
    min-width: 200px;
    border: none;
    text-align: center;
    color: var(--main-bg);
    font-size:12px;
    background: var(--main-color);
    text-transform: uppercase;
  }
`;
