import { TitleTags, SpanCategory, SpanType } from "./style";

function Tags({ data }) {
  return (
    <TitleTags>
      <SpanCategory>{data.category}</SpanCategory>
      <SpanType>{data.type}</SpanType>
    </TitleTags>
  );
}

export default Tags;
