// style
import { Wrapper } from "./style";

function AdminInfo({ data }) {
  return (
    <Wrapper>
      <div className="body-info">{data?.text}</div>
      <div className="contacts">
        <div>
          <table>
            <tbody>
              <tr>
                <th>{data?.telLabel}</th>
                <td>{data?.telValue}</td>
              </tr>
              <tr>
                <th>{data?.mailLabel}</th>
                <td>{data?.mailValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
}

export default AdminInfo;
