import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: fixed;
  inset: 0px;
  position:fixed; 
  top: calc(50% - 199px);
  left: calc(50% - 672px)
  transform: translate(-50%, -50%)
`;

export const Overlay = styled.div`
  background: #181818;
  opacity: 0.5;
  position: fixed;
  inset: 0px;
`;
export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 576px;
  z-index: 2;
  position: relative;
  background: white;
  padding: 48px;
  text-align: center;
  h2 {
    font-family: ferrari-sans-medium;
    font-size: 28px;
    font-weight: 400;
    margin: 0px 0px -14px;
    line-height: 34px;
    color: #181818;
  }
  div {
    font-size: 13px;
    font-family: ferrari-sans-regular;
    line-height: 20px;
    color: #181818;
  }
  @media (max-width: 1279px) {
    max-width: 576px;
    min-width: 340px;
    width: 80vw;
    justify-content: space-around;
    h2 {
      margin: 22px auto 4px;
    }
  }
`;

export const ConfirmButton = styled.button`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
