import { List } from "./style";
import PropTypes from "prop-types";

//components
import NewsCard from "./newsCard/NewsCard";
import NewsTitleSection from "../../news/newsTitleSection/NewsTitleSection";
import { useSelector } from "react-redux";

const NEWSCARD_RELATED_COMPONENT_NAME = "news-card-related";

function RelatedNewsList({ data }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const deafultTitle = lang === "it" ? "Ultime Notizie" : "Latest News";
  return (
    <>
      <NewsTitleSection data={data.title || deafultTitle} />
      <List>
        {data.news.map((currentCard, idx) => (
          <NewsCard
            key={`${NEWSCARD_RELATED_COMPONENT_NAME}-${idx}`}
            newsCardProps={currentCard}
          />
        ))}
      </List>
    </>
  );
}

RelatedNewsList.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    news: PropTypes.array,
    position: PropTypes.string,
  }),
};
export default RelatedNewsList;
