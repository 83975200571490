import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Wrapper,
  ImageContainer,
  CardBody,
  Title,
  TitleTags,
  SpanCategory,
  SpanType,
  TitleH4,
  Body,
  Description,
  WhereBody,
  WhereText,
  WhereTitle,
} from "./style";

function WelfareCard({ data, isLoadingFunc }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <Link
      style={!loaded ? {} : { opacity: "1" }}
      to={
        data?.path ? (data.path[0] !== "/" ? "/" + data.path : data.path) : "#"
      }
    >
      <Wrapper>
        <ImageContainer>
          <img
            src={data.image}
            onLoad={() => {
              setLoaded(true);
              isLoadingFunc();
            }}
          />
        </ImageContainer>
        <CardBody>
          <Title>
            <TitleTags>
              <SpanCategory>{data.category}</SpanCategory>
              <SpanType>{data.type}</SpanType>
            </TitleTags>
            <TitleH4>{data.title}</TitleH4>
          </Title>
          <Body>
            <Description>{data.description}</Description>
            <WhereBody>
              <WhereTitle>Dove</WhereTitle>
              <WhereText>{data.where}</WhereText>
            </WhereBody>
          </Body>
        </CardBody>
      </Wrapper>
    </Link>
  );
}

export default WelfareCard;
