import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { fetchThankYouComponents } from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// components
import LayoutThankYou from "../../components/UI/thankYou/LayoutThankYou";
import Image from "../../components/UI/thankYou/image/Image";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import Title from "../../components/UI/thankYou/title/Title";
import Text from "../../components/UI/thankYou/text/Text";
import Button from "../../components/UI/thankYou/button/Button";

function ThankYou({ path }) {
  const dispatch = useDispatch();

  const thankYouComponents = useSelector((state) => state.thankYou.components);
  const token = useSelector((state) => state.login.activeToken);

  useMemo(() => {
    path && token && dispatch(fetchThankYouComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setHideNavAndFooter(true));
  }, [dispatch]);

  const componentsMapping = {
    "fi-thank-you-image": Image,
    "fi-title": Title,
    "fi-text": Text,
    "fi-red-button": Button,
  };

  const { status, errorData } = GetStatusAndErrorData("thankYou");

  return (
    <>
      {" "}
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        thankYouComponents && (
          <LayoutThankYou>
            {thankYouComponents.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutThankYou>
        )
      )}
    </>
  );
}

export default ThankYou;
