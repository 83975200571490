import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./notificationSlice";

const notificationsState = (state) => state.notifications || initialState;

export const selectNotificationsByFilterAndYear = (filterType, year) =>
  createSelector(notificationsState, (state) => {
    const dateFrom = new Date(`01/01/${year}`).getTime();
    const dateTo = new Date(`12/31/${year}`).getTime();
    switch (filterType) {
      case "all":
        return state.notificationsArray.filter(
          (el) => el.createdDate <= dateTo && el.createdDate >= dateFrom
        );
      case "priority":
        return state.notificationsArray.filter(
          (el) =>
            el.priority === true &&
            el.createdDate <= dateTo &&
            el.createdDate >= dateFrom
        );
      case "corporate-communications":
        return state.notificationsArray.filter(
          (el) =>
            el.type === "document" &&
            el.createdDate <= dateTo &&
            el.createdDate >= dateFrom
        );
      case "news":
        return state.notificationsArray.filter(
          (el) =>
            el.type === "news" &&
            el.createdDate <= dateTo &&
            el.createdDate >= dateFrom
        );
      case "event":
        return state.notificationsArray.filter(
          (el) =>
            el.type === "event" &&
            el.createdDate <= dateTo &&
            el.createdDate >= dateFrom
        );
      default:
        return state.notificationsArray.filter(
          (el) => el.createdDate <= dateTo && el.createdDate >= dateFrom
        );
    }
  });

export const selectNotificationCatalogue = () =>
  createSelector(
    notificationsState,
    (state) =>
      state.components.components.find(
        (v) => v.resourceType === "fi-notifications-catalogue"
      )?.data
  );
