import { takeLatest, put, call } from "redux-saga/effects";
import {
  axiosCallGet,
  axiosCallPost,
  axiosCallDelete,
} from "../../utils/axiosCalls/AxiosCalls";

import { setComponents, setError } from "./FormContraffazioniSlice";
import { FETCH_COMPONENTS, USER_SEGNALATION } from "./constants";
import { showErrorMessage } from "../../utils/notifications/TriggerNotification";
import axios from "axios";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-form-segnalazione.json")
    // );
    yield put(setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

function* postBody({ payload }) {
  try {
    document.body.classList.add("loading");
    yield console.log(payload);
  } catch (e) {
    const messageError =
      payload.lang === "it" ? "Opperazione fallita" : "Operation failed";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(USER_SEGNALATION, postBody);
}
