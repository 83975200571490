import { useSelector } from "react-redux";
import CustomSelect from "../../../customSelect/CustomSelect";
import { ComponentContainer, TextDescr } from "../../style";
//components
import ErrorField from "../ErrorField/ErrorField";
import { useEffect, useState } from "react";
import { useBusinessLogic } from "../../useBusinessLogic";

export default function SlotLightExperience({
  data,
  onChange,
  allowed,
  check,
}) {
  const { getSubscriptionDay, Guests } = useBusinessLogic();
  const [slot, setSlot] = useState("");
  const lang = useSelector((state) => state.coreComponents.language);

  const Appointments = data?.appointments?.filter((appointment) =>
    appointment?.configurations?.find(
      (el) =>
        el.groupName === "deliveryMode" && el.fieldValue === "employeeOnly"
    )
  );
  useEffect(() => {
    setSlot("");
  }, [Guests]);
  return (
    <ComponentContainer>
      <TextDescr>{data?.slotTxt}</TextDescr>
      <CustomSelect
        name="AppointmentIdLe"
        setValue={setSlot}
        defaultValue={slot}
        placeholderString={data?.slotLabel}
        onChange={onChange}
      >
        {Appointments?.map((appointment) => {
          if (
            appointment?.availablePlacesFrontEnd - appointment?.takenSeats >=
            Guests?.value + 1
          ) {
            return (
              <option
                className="data-evento"
                value={appointment?.id}
                key={appointment?.id}
                code={data?.event?.code}
              >
                {getSubscriptionDay(
                  appointment?.appointmentStartDate,
                  appointment?.appointmentEndDate,
                  lang
                )}
              </option>
            );
          } else {
            return "";
          }
        })}
      </CustomSelect>
      {check == null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
