import styled from "styled-components";

export const ImgHeader = styled.img`
  margin-top: -92px;
  position: relative;
  z-index: -1;
  max-width: 100%;
  width: 100%;
`;

export const Container = styled.div`
  margin: auto;
  width: 90%;
  padding: 10px;
  > h1 {
    margin: 96px 0px 64px;
  }
  .cafeteria-h2 {
    font: 24px / 30px ferrari-sans-medium;
    margin: 64px 0px;
    text-transform: uppercase;
  }

  .cafeteria-slot-2 {
    display: flex;
    column-gap: 32px;
    margin-top: 64px;
    align-items: flex-start;
    align-content: flex-start;
  }

  .cafeteria-dishes-allergens {
    margin-top: 16px;
  }

  .cafeteria-dishes-veg span {
    vertical-align: 2px;
  }

  .cafeteria-dishes-wrapper {
    flex-grow: 1;
  }

  .cafeteria-slot-1 {
    display: flex;
    column-gap: 32px;
  }

  .cafeteria .mobile-header {
    background: transparent;
  }

  .cafeteria-cards {
    max-width: 384px;
  }

  .cafeteria-cards-mobile {
    @media (min-width: 1280px) {
      max-width: 384px;
    }
    display: none;
  }

  .favorite-dishes-wrapper {
    flex-grow: 3;
  }

  @media screen and (max-width: 1279px) {
    > h1 {
      margin: revert;
    }
    .cafeteria-slot-1 {
      display: block;
    }

    .cafeteria-cards {
      display: none;
    }

    .cafeteria-cards-mobile {
      display: block;
      margin-top: 32px;
      margin-left: auto;
      margin-right: auto;
    }

    .cafeteria-slot-2 {
      flex-direction: column-reverse;
      row-gap: 32px;
    }

    .cafeteria-slot-2 .caloric-intake {
      width: 100%;
    }
    .cafeteria-dishes-wrapper {
      width: 100%;
    }
  }
`;
