import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { FETCH_COMPONENTS } from "./constants";

import axios from "axios";
import { setComponents, setError } from "./RecapAndDetailFamilyDaySlice";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-iscrizione-effettuata-family-day.json")
    // );
    yield put(setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
}
