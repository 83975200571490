import React from "react";
import { Link } from "react-router-dom";
// style
import { InputSection, BadgeError } from "./style";

function Badge({ data }) {
  return (
    <>
      <h3>{data?.title}</h3>
      <InputSection>
        <label>{data?.badgeLabel}</label>
        <input type="number" defaultValue={data?.badge} disabled />
      </InputSection>
      <BadgeError>
        <Link to={"#"}>{data?.reportWrongBadgeLabel}</Link>
      </BadgeError>
      <InputSection>
        <label>{data?.fiscalCodeLabel}</label>
        <input type="text" defaultValue={data?.fiscalCode} disabled />
      </InputSection>
    </>
  );
}

export default React.memo(Badge);
