import React from "react";
import PropTypes from "prop-types";
//components
import WebinarCard from "../../events/webinarCardList/WebinarCard/WebinarCard";
import WebinarTitle from "../webinarCardList/webinarTitle/WebinarTitle";
// style
import { List } from "./style";

function WebinarHistoricList({ data }) {
  return (
    <>
      <WebinarTitle data={data.title} />
      <List>
        {React.Children.toArray(
          data.events.map((event) => <WebinarCard data={event} />)
        )}
      </List>
    </>
  );
}

WebinarHistoricList.propTypes = {
  data: PropTypes.exact({
    events: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    position: PropTypes.string,
  }),
};
export default React.memo(WebinarHistoricList);
