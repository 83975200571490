import React from "react";
import { Wrapper, SpanExpiringDate } from "./style";

function ImgList({ data }) {
  return (
    <>
      <Wrapper>
        <img src={data?.cover} />
        {React.Children.toArray(data?.gallery?.map((el) => <img src={el} />))}
      </Wrapper>
      <SpanExpiringDate>Scadenza {data?.expiration}</SpanExpiringDate>
    </>
  );
}

export default ImgList;
