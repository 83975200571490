import { useDispatch, useSelector } from "react-redux";
import { ComponentContainer, RadioButton, TextDescr } from "../style";
import ErrorField from "./ErrorField/ErrorField";
import { setBodyPayload } from "../../../../pages/FormIscrizioneMondiali/FormIscrizioneMondialiSlice";

export default function ComponentNumGuests({ data, allowed, bodyPayload }) {
  const dispatch = useDispatch();

  const guestLimit = useSelector(
    (state) => state.FormIscrizioneMondiali.guestLimit
  );
  const handleGuest = (e) => {
    const { name, value } = e.target;
    let modifiedUserInfo = { ...bodyPayload, [name]: +value };
    dispatch(setBodyPayload(modifiedUserInfo));
  };

  const slot = data?.event?.appointments?.filter((appointment) =>
    appointment?.slots?.find(
      (slot) => slot?.id === data?.userSubscriptions?.[0]?.appointmentId
    )
  );
  const isGuestOptDisabled = (index) => {
    const ap = slot?.[0]?.slots?.[0]?.availablePlacesFrontEnd ?? 0;
    const ts = slot?.[0]?.slots?.[0]?.takenSeats ?? 0;
    const reserved = data?.userSubscriptions?.[0]?.tickets?.length ?? 1;
    return ap - ts + reserved <= index && reserved <= index;
  };

  return (
    <ComponentContainer>
      <h3>
        {data?.summaryGuestsLblEventLabel}{" "}
        <span>{data?.fieldMandatoryLblEventLabel}</span>
      </h3>
      <TextDescr>{data.guestTxtEventLabel}</TextDescr>
      <div className="fi-checkbox">
        {[...Array(guestLimit).keys()]?.map((i) => (
          <RadioButton key={i}>
            <input
              type="radio"
              id={`accompagnatoriId${i}`}
              name={`numGuests`}
              defaultValue={i}
              disabled={
                !bodyPayload?.appointmentId ||
                (data?.userSubscriptions?.[0]?.appointmentId ===
                  bodyPayload?.appointmentId &&
                  isGuestOptDisabled(i))
              }
              checked={bodyPayload?.numGuests === i}
              onChange={(e) => handleGuest(e)}
            />
            <label htmlFor={`accompagnatoriId${i}`}>
              {i === 0 ? data?.noGuestLblEventLabel : i}
            </label>
          </RadioButton>
        ))}
      </div>
      {/*checking if numGuest is null || undefined, don't need === because 0 is falsy*/}
      {bodyPayload?.numGuests == null && allowed === false && (
        <ErrorField text={data?.fieldValidationErrorTxtEventLabel} />
      )}
    </ComponentContainer>
  );
}
