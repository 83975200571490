import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./BusinessCardManagementSlice";

const eCardMngt = (state) => state.businessCardManagement || initialState;

export const selectComponents = () =>
  createSelector(eCardMngt, (state) => state.components);

export const selectNoteModal = () =>
  createSelector(
    eCardMngt,
    (state) =>
      state.components.find(
        (v) => v.resourceType === "fi-ecard-approval/fi-modal-ecard"
      ).data
  );

export const defaultNote = () =>
  createSelector(
    eCardMngt,
    (state) =>
      state.components.find(
        (v) => v.resourceType === "fi-ecard-approval/fi-modal-ecard"
      )?.data?.noNoteTxt
  );

export const selectTabIndex = () =>
  createSelector(eCardMngt, (state) => state.tabIndex);

export const selectTabsArray = () =>
  createSelector(eCardMngt, (state) => state.tabsArray);

export const selectTabComponentsByTabIndex = (tabIndex) =>
  createSelector(eCardMngt, (state) => state.tabsArray[tabIndex].components);
