import PropTypes from "prop-types";
import { Wrapper } from "./style";

function GenericTitle({ data }) {
  const SetTitleType = () => {
    let titleCmp;
    switch (data.type) {
      case "h2":
        titleCmp = <h2>{data.jcrTitle}</h2>;
        break;
      case "h3":
        titleCmp = <h3>{data.jcrTitle}</h3>;
        break;
      case "h4":
        titleCmp = <h4>{data.jcrTitle}</h4>;
        break;
      case "h5":
        titleCmp = <h5>{data.jcrTitle}</h5>;
        break;
      case "h6":
        titleCmp = <h6>{data.jcrTitle}</h6>;
        break;
      default:
        titleCmp = <h1>{data.jcrTitle}</h1>;
        break;
    }
    return titleCmp;
  };

  return <Wrapper>{SetTitleType()}</Wrapper>;
}

GenericTitle.propTypes = {
  data: PropTypes.exact({
    jcrTitle: PropTypes.string,
    type: PropTypes.string,
    position: PropTypes.string,
  }),
};
export default GenericTitle;
