import React from "react";
import { Link } from "react-router-dom";
// style
import { Divider } from "../title/style";
import { Wrapper, FirstLevel } from "./style";

function ListMap({ data }) {
  const AZorderedList = [...data?.header].sort((a, b) =>
    a.id > b.id ? 1 : -1
  );

  const childrenIteration = (children, isSitemapRedirect) => {
    if (children) {
      return React.Children.toArray(
        children?.map((child) => {
          return (
            <>
              {!isSitemapRedirect && (
                <li>
                  <Link to={child.path}>{child.title}</Link>
                </li>
              )}
              <ul className="second-level">
                {childrenIteration(child?.children, child?.isSitemapRedirect)}
              </ul>
            </>
          );
        })
      );
    }
  };

  return (
    <>
      {AZorderedList && (
        <Wrapper>
          {React.Children.toArray(
            AZorderedList?.map((firstLevel) => {
              return (
                <>
                  <FirstLevel>
                    {firstLevel.isSitemapRedirect || !firstLevel?.children ? (
                      <Link to={firstLevel.path}>{firstLevel.title}</Link>
                    ) : (
                      <Link
                        style={{ pointerEvents: "none" }}
                        to={firstLevel.path}
                      >
                        {firstLevel.title}
                      </Link>
                    )}
                  </FirstLevel>
                  <ul>
                    {childrenIteration(
                      firstLevel.children,
                      firstLevel.isSitemapRedirect
                    )}
                  </ul>
                  <Divider />
                </>
              );
            })
          )}
        </Wrapper>
      )}
    </>
  );
}

export default React.memo(ListMap);
