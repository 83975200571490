import PropTypes from "prop-types";
import linkTarget from "../../../../utils/linkTarget";
// style
import { Wrapper, CardCTA, Title, SpanCtaIcon, CtaCircle } from "./style";

function CafeteriaCard({ cafeteriaCardProps }) {
  const redirectToPage = (url) => {
    return url.includes("internal-events-subscribe-form")
      ? `/generic-subscription-form?path=${url}`
      : url;
  };

  return (
    <Wrapper>
      <img src={cafeteriaCardProps?.backgroundImage} />
      <CardCTA>
        <Title>{cafeteriaCardProps?.title}</Title>
        <a
          href={redirectToPage(cafeteriaCardProps?.linkHref) || "#"}
          target={linkTarget(cafeteriaCardProps?.linkHref)}
          aria-label={
            cafeteriaCardProps?.title + cafeteriaCardProps?.description
          }
        >
          <span>{cafeteriaCardProps?.description}</span>
          <SpanCtaIcon>
            <CtaCircle>
              <svg viewBox="0 0 24 24">
                <circle cx={12} cy={12} r={11} stroke={1} />
              </svg>
            </CtaCircle>
            <svg
              viewBox="0 0 8 16"
              width={8}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: 16, width: 8 }}
            >
              <path
                fill="rgb(255, 255, 255)"
                d="M7.268 9.547L0 16l4-8-4-8 7.268 6.453C7.715 6.82 8 7.377 8 8c0 .623-.285 1.18-.732 1.547z"
              />
            </svg>
          </SpanCtaIcon>
        </a>
      </CardCTA>
    </Wrapper>
  );
}

CafeteriaCard.propTypes = {
  cafeteriaCardProps: PropTypes.exact({
    backgroundImage: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    linkHref: PropTypes.string,
    imageAlt: PropTypes.string,
  }),
};

export default CafeteriaCard;
