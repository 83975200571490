import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { showErrorMessage } from "../../../../../utils/notifications/TriggerNotification";
import {
  CloudSvg,
  CloudSvgPath1,
  CloudSvgPath2,
  CloudSvgPath3,
  CloudSvgPath4,
  FileUploadCTA,
  FileUploadDimension,
  FileUploadText,
  FileUploadTitle,
} from "./style";

const baseStyle = {
  border: "dashed #8F8F8F 2px",
  borderRadius: "15px",
  display: "flex",
  color: "#8F8F8F",
  flexDirection: "column",
  gap: "15px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "11px",
  lineHeight: "20px",
  letterSpacing: "1px",
  outline: "none",
  cursor: "pointer",
  padding: "56px",
  width: "100%",
  background: "var(--alternative-bg-color)",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  border: "dashed #2196f3",
};

const acceptStyle = {
  border: "dashed #00e676",
};

const rejectStyle = {
  border: "dashed #ff1744",
};

export default function LoadFiles({ onChange, data }) {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        switch (err.code) {
          case "file-too-large":
            showErrorMessage(`File "${file.file.name}" is too large`);
            break;
          case "file-invalid-type":
            showErrorMessage(`File "${file.file.name}" has invalid type`);
            break;
        }
      });
    });

    acceptedFiles.forEach((file) => {
      onChange(file);
    });
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxSize: 2500000,
      multiple: false,
      accept: {
        "image/jpeg": [".jpg", ".JPG"],
        "image/png": [".png", ".PNG"],
        "application/pdf": [".pdf"],
      },
      onDrop,
    });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <CloudSvg>
        <CloudSvgPath1 />
        <CloudSvgPath2 />
        <CloudSvgPath3 />
        <CloudSvgPath4 />
      </CloudSvg>
      <FileUploadText>
        <FileUploadTitle>{data?.filesUploadNumLbl}</FileUploadTitle>
        <FileUploadDimension>{data?.filesUploadTypeLbl}</FileUploadDimension>
      </FileUploadText>
      <FileUploadCTA>{data?.searchFilesButtonLbl}</FileUploadCTA>
    </div>
  );
}
