import React from "react";
import PropTypes from "prop-types";
import TakeAwayCard from "../takeAwayCard/TakeAwayCard";
// import CafeteriaAsideOrder from "../cafeteriaAsideOrder/CafeteriaAsideOrder";

const CafeteriaBasketSection = ({ baskets }) => {
  return (
    <>
      <div className="cafeteria-slot-2">
        <div className="cafeteria-dishes-wrapper">
          {baskets.map((basket, i) => (
            <TakeAwayCard key={i} basket={basket} />
          ))}
        </div>
        {/* SCOMMENTARE QUANDO SERVE : da richiesta nascondere il dettaglio ordini perchè il servizio d'asporto non è ancora disponibile
        <CafeteriaAsideOrder /> */}
      </div>
    </>
  );
};

CafeteriaBasketSection.propTypes = {
  baskets: PropTypes.array,
};

export default CafeteriaBasketSection;
