import styled from "styled-components";

export const MealsSection = styled.div`
  > div {
    display: flex;
    place-content: space-between;
    align-items: center;
    padding: 30px 0px 30px 5px;
    border-bottom: 1px solid rgb(212, 210, 210);
    .enabled {
      color: var(--main-color);
    }
    .disabled {
      color: #8f8f8f;
    }
  }
`;

export const MealTitle = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`;

export const MealInput = styled.input`
  width: auto;
  max-width: 0px;
  padding: 20px 24px;
  min-width: 90px;
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
`;

export const RemaingSelection = styled.div`
  ${(props) =>
    props.color === "true" ? "color: red" : "color: rgb(143, 143, 143)"};
  float: right;
  border-bottom: 0px !important;
  font-size: 13px;
  line-height: 20px;
  padding-bottom: 0px !important;
`;

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid #8f8f8f;
  padding: 5px 24px;
  width: 90px;
  select {
    border: none;
    font-size: 11px;
  }
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
  }
`;

export const Select = styled.select`
  padding: 20px 24px;
  cursor: pointer;
  appearance: none;
  &,
  select,
  option {
    font-family: ferrari-sans-regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 12px 16px 14px;
    color: var(--main-color);
    min-width: 110px;
    background: transparent;
  }
  &:focus-visible {
    border: none;
    outline: -webkit-focus-ring-color auto 0px;
  }
  &.placeholder {
    color: rgb(112, 112, 112) !important;
  }
  select:not(:-internal-list-box) {
    overflow: visible !important;
  }
`;

export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;
