import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./contactUsSlice";

const contactUsState = (state) => state.contactus || initialState;

export const selectHeroImg = () =>
  createSelector(contactUsState, (state) => {
    return state.components?.filter(
      (el) => el.resourceType === "fi-hero-image"
    )[0]?.data?.fileReference;
  });
