import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 1216px;
  color: var(--main-color);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  gap: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1279px) {
    gap: 40px;
  }
`;

export const Header = styled.div`
  gap: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
`;

const actionChangeWidth = (offerType) => {
  if (!offerType) return `width: 100%;`;
};
export const DesktopContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => actionChangeWidth(props.offerType)};
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

export const Divider = styled.div`
  height: 0px;
  border-bottom: 1px solid #d4d2d2;
  margin: 2em 0px;
  float: left;
  clear: both;
  width: 100%;
`;

export const ActionLink = styled(Link)`
  text-align: center;
  padding: 20px;
  font-size: 12px;
  cursor: pointer;
  color: var(--cta-color);
  border: 1px solid var(--carousel-dot-color);
`;
