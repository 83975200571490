// utils
import linkTarget from "../../../../../utils/linkTarget";
import SetQueryStringPath from "../../../../../utils/setQueryStringPath";
// style
import {
  Slot,
  Text,
  TextHeading,
  HeadingChapter,
  HeadingTitle,
  TextWrapper,
} from "./style";

function WelfareSlot({ data }) {
  const linkHref = SetQueryStringPath(data?.linkHref);

  return (
    <a href={linkHref} target={linkTarget(linkHref)} rel="noreferrer">
      <Slot>
        <img src={data.fileReference} />
        <TextWrapper>
          <TextHeading>
            <HeadingChapter>{data.category}</HeadingChapter>
            <HeadingTitle>{data.title}</HeadingTitle>
          </TextHeading>
          <Text>{data.description}</Text>
        </TextWrapper>
      </Slot>
    </a>
  );
}

// WelfareSlot.propTypes = {
//   newsSlot: PropTypes.exact({
//     title: PropTypes.string.isRequired,
//     path: PropTypes.string.isRequired,
//     image: PropTypes.string.isRequired,
//     createdDate: PropTypes.number.isRequired,
//     lastModifiedDate: PropTypes.number,
//     lastReplicatedDate: PropTypes.number,
//     category: PropTypes.string.isRequired,
//     tags: PropTypes.array,
//   }),
// };
export default WelfareSlot;
