import React from "react";
import PropTypes from "prop-types";

import { TitleH1, TitleH2 } from "./style";

const TitleSection = ({ data }) => {
  return data?.type === "h2" ? (
    <TitleH2>{data.title}</TitleH2>
  ) : (
    <TitleH1>{data.title}</TitleH1>
  );
};
TitleSection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default TitleSection;
