import { createRoot } from "react-dom/client";
import NotificationBadge from "../../components/UI/notifications/NotificationBadge";

const triggerNotification = (message, messageType) => {
  const notifications = createRoot(
    document.getElementById("notifications-fixed-container")
  );
  notifications.render(
    <NotificationBadge
      timer={4000}
      text={message}
      messageType={messageType}
      notificationRoot={notifications}
    />
  );
};

export const showErrorMessage = (message) =>
  triggerNotification(message, "error");

export const showSuccessMessage = (message) =>
  triggerNotification(message, "success");
