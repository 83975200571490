import { Card, Description, TitleAndText, Title, Text } from "./style";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function WebinarCard({ data }) {
  return (
    <Card>
      <Link to={data?.linkHref || "#"}>
        <img src={data.image} />
      </Link>
      <Description>
        <span>{data.preTitle}</span>
      </Description>
      <TitleAndText>
        <Title>{data.title}</Title>
        <Text>{data.description}</Text>
      </TitleAndText>
    </Card>
  );
}

WebinarCard.propTypes = {
  data: PropTypes.exact({
    path: PropTypes.string,
    image: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    preTitle: PropTypes.string,
    description: PropTypes.string,
    pathBooking: PropTypes.string,
    eventType: PropTypes.string,
  }),
};

export default WebinarCard;
