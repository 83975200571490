import styled from "styled-components";

export const ProgressBarsWrapper = styled.div`
  position: relative;
  top: -${(props) => props.topBars}px;
  padding: 0 ${(props) => props.marginBars}px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 10px;
`;

export const ProgressBar = styled.div`
  width: ${(props) => props.currentWidth}%;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 2px;
  height: 10px;
  position: relative;
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  cursor: pointer;
`;
