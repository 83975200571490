import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { fetchisUserActive } from "./actions";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";

function Login() {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const isUserActive = useSelector((state) => state.login.activeUser);
  const isJustRegistered = useSelector((state) => state.login.isJustRegistered);
  const isAccessReduced = useSelector((state) => state.login.reducedAccess);

  //const isUserActive = false;
  // const isJustRegistered = true;
  const [firstTime, setFirstTime] = useState(true);
  const { status, errorData } = GetStatusAndErrorData("login");

  useEffect(() => {
    if (token) {
      firstTime && dispatch(fetchisUserActive(token));
      setFirstTime(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token, firstTime]);
  return (
    <>
      {status === null ? (
        <PageLoader></PageLoader>
      ) : status !== 200 ? (
        isAccessReduced ? (
          <Navigate to="/reduced-access" />
        ) : (
          <GenericError data={errorData} />
        )
      ) : isUserActive ? (
        <Navigate to={`/${lang}`} />
      ) : isJustRegistered ? (
        <Navigate to={`/${lang}/thank-you`} />
      ) : (
        <Navigate to={`/${lang}/thank-you-approval`} />
      )}
    </>
  );
}

export default Login;
