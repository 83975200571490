import { FETCH_COMPONENTS, FETCH_SET_ECARD_INFORMATION } from "./constants";
 
export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const FetchSetUserEcardInformation = (payload) => ({
  type: FETCH_SET_ECARD_INFORMATION,
  payload,
});