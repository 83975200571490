import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./newsDetailSlice";

const newsDetailState = (state) => state.newsDetail || initialState;

export const selectNewsComponents = () =>
  createSelector(newsDetailState, (state) => state.components);

export const selectNewsDetailSettings = () =>
  createSelector(newsDetailState, (state) => state.components.settings);
