import { FETCH_WELFARE_COMPONENTS } from "./constants";
import { welfareActions } from "./welfareSlice";
import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

function* fetchWelfareComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() => axios.get("/mocks/mock-welfare.json"));
    yield put(welfareActions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(welfareActions.setError(e));
  }
}

export default function* newsSaga() {
  yield takeLatest(FETCH_WELFARE_COMPONENTS, fetchWelfareComponents);
}
