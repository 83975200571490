import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectedCardsTotal,
  selectCatalogTitle,
} from "../../../../../pages/AdsList/selectors";
import { TitleWithCounterWrapper } from "./style";

function TitleWithCounter({ justText }) {
  const title = useSelector(selectCatalogTitle());
  const total = useSelector(selectedCardsTotal());

  if (!title?.length) return null;
  if (justText)
    return (
      title.substring(
        0,
        title.indexOf("(") >= 0 ? title.indexOf("(") : title.length
      ) +
      " (" +
      total +
      ")"
    );
  return (
    <TitleWithCounterWrapper>
      {title.substring(
        0,
        title.indexOf("(") >= 0 ? title.indexOf("(") : title.length
      ) +
        " (" +
        total +
        ")"}
    </TitleWithCounterWrapper>
  );
}

TitleWithCounter.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
};
export default TitleWithCounter;
