import React from "react";
// style
import { Wrapper } from "./style";
//components
import UsefulContactCard from "./usefulContactCard/UsefulContactCard";

function UsefulContactsList({ components }) {
  return (
    <Wrapper>
      {React.Children.toArray(
        components?.items?.map((v) => {
          return <UsefulContactCard data={v} />;
        })
      )}
    </Wrapper>
  );
}

export default UsefulContactsList;
