import { Wrapper, Title, Timer, TimerSlot, Value, ValueLabel } from "./style";
import { useSelector } from "react-redux";

function CountDown({ data, timeLeft }) {
  const { pageLight } = useSelector((state) => state.coreComponents);
  return (
    <Wrapper pageLight={pageLight}>
      <Title>{data.countDownLabel || "INIZIA IN"}</Title>
      <Timer>
        <TimerSlot>
          <Value>
            {timeLeft.days.toLocaleString("it-IT", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Value>
          <ValueLabel>{data.dayLabel || "giorni"}</ValueLabel>
        </TimerSlot>
        <TimerSlot>
          <Value>
            {timeLeft.hours.toLocaleString("it-IT", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Value>
          <ValueLabel>{data.hoursLabel || "ore"}</ValueLabel>
        </TimerSlot>
        <TimerSlot>
          <Value>
            {timeLeft.minutes.toLocaleString("it-IT", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Value>
          <ValueLabel>{data.minutesLabel || "minuti"}</ValueLabel>
        </TimerSlot>
        <TimerSlot>
          <Value>
            {timeLeft.seconds.toLocaleString("it-IT", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Value>
          <ValueLabel>{data.secondsLabel || "secondi"}</ValueLabel>
        </TimerSlot>
      </Timer>
    </Wrapper>
  );
}

export default CountDown;
