import React from "react";
import { useDispatch, useSelector } from "react-redux";
// selectors
import { selectCatalogCancelModal } from "../../../../../pages/AdsList/selectors";
// actions
import { adsListActions } from "../../../../../pages/AdsList/AdsListSlice";
// style
import { Wrapper, Overlay, BoxContainer, ConfirmButton } from "./style";

function ConfirmationModal() {
  const dispatch = useDispatch();
  const data = useSelector(selectCatalogCancelModal());

  return (
    <Wrapper>
      <Overlay />
      <BoxContainer>
        <h2>{data?.title}</h2>
        <div>
          <p>{data?.description}</p>
        </div>
        <ConfirmButton
          onClick={() => dispatch(adsListActions.setOpenModal(false))}
        >
          {data?.buttonLabel}
        </ConfirmButton>
      </BoxContainer>
    </Wrapper>
  );
}

export default React.memo(ConfirmationModal);
