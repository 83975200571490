import i18n from "i18next";
import React, { useState } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// utils
import useMediaQuery from "../../../hooks/useMediaQuery";
import useScrollDirection from "../../../hooks/useScrollDirection";
// actions
import { coreComponentsActions } from "../../../utils/getCoreComponents/coreComponentsSlice";
import { toolsActions } from "../../../pages/Strumenti/toolsSlice";
import { globalSearchActions } from "../../../pages/GlobalSearch/globalSearchSlice";
import { notificationActions } from "../../../pages/Notifications/notificationSlice";
import { selectAvatarImage } from "../../../pages/Home/selectors";
import { selectTabTitlesFromTabsArray } from "../../../pages/MyPage/selectors";
import TargetTrap from "../../../utils/targetTrap";
// components
import Submenu from "./Submenu";
import ResizeWindowHook from "./ResizeWindowHook";
import ToolsDialog from "../dialog/tools/ToolsDialog";
import NotificationDialog from "../dialog/notifications/NotificationDialog";
import AvatarDropDown from "./dropDown/AvatarDropDown";
import GlobalSearchDialog from "../../../pages/GlobalSearch";
// style
import "./Header.css";
import { BackgroundDesktopMenu, AvatarImage, DropDownAvatar } from "./style";
import { COMPONENTS_MAPPING } from "../../../App";

const setLinkActive = (isActive) => (isActive ? "selected" : undefined);

function Header() {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const avatarImageStream = useSelector(selectAvatarImage());
  const language = useSelector((state) => state.coreComponents.language);
  const tabsTitleMyPage = useSelector(selectTabTitlesFromTabsArray());
  const location = useLocation();
  const {
    components,
    mobileCurrentSection,
    openMobileMenu,
    openDesktopMenu,
    isWhiteHeader,
    mobileBack,
    hideNavAndFooter,
    pageLight,
  } = useSelector((state) => state.coreComponents);
  const { showGlobalSearchDialog } = useSelector((state) => state.globalSearch);
  const { showToolsDialog } = useSelector((state) => state.tools);
  const { notificationsArrayNotRead, showNotificationDialog } = useSelector(
    (state) => state.notifications
  );
  const userName = useSelector((state) => state.login.tokenClaims);
  const [showDropDown, setShowDropDown] = useState(false);
  const headerLinks = components?.header || [];
  const footerLinks = components?.footer || [];
  const scrollDirection = useScrollDirection();

  const setPageLight = (lightString) => {
    dispatch(coreComponentsActions.setPageLight(lightString));
    if (lightString === "dark") document.body.classList.add("dark");
    else document.body.classList.remove("dark");
  };

  const setSubMenu = (e, headerLbl) => {
    e.preventDefault();
    dispatch(coreComponentsActions.setSubMenu(headerLbl));
  };

  const openMobileNav = async () => {
    document.body.style.overflow = "hidden";
    dispatch(coreComponentsActions.setInitialComponents());
    dispatch(coreComponentsActions.setOpenMobileMenu(true));
    await sleep(100);
    document.getElementById("profileLinkId").focus();
  };

  const closeMobileNav = async () => {
    document.body.style.overflow = "";
    dispatch(coreComponentsActions.setInitialComponents());
    await sleep(100);
    document.getElementById("hamburgerMenuId")?.focus();
  };

  const showDropDownHandler = () => {
    if (document.getElementById("arrow-down").style.transform) {
      document.getElementById("arrow-down").style.transform = "";
      document
        .getElementById("avatar-dropdown")
        .setAttribute("aria-expanded", false);
    } else {
      document.getElementById("arrow-down").style.transform = "rotate(180deg)";
      document
        .getElementById("avatar-dropdown")
        .setAttribute("aria-expanded", true);
    }
    setShowDropDown(!showDropDown);
  };

  const showDropDownHandlerKeyPress = (e) => {
    switch (e.keyCode) {
      case 32:
      case 13:
        e.preventDefault();
        showDropDownHandler();
        break;
      case 40:
        e.preventDefault();
        document.getElementById("idProfile").focus();
        break;
    }
  };
  const ShowToolsHandler = () => {
    dispatch(toolsActions.setShowToolsDialog(!showToolsDialog));
  };

  const ShowGlobalSearchHandler = () => {
    dispatch(globalSearchActions.setShowGlobalSearchDialog(true));
  };

  const ShowNotificationHandler = () => {
    dispatch(notificationActions.setShowNotificationDialog(true));
  };

  const areAllChildrenHided = (children) => {
    let result = true;
    children.forEach((el) => {
      if (!el.dontShow) {
        result = false;
      }
    });
    return result;
  };

  const ChangeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    window.location.replace("/");
    dispatch(coreComponentsActions.setLanguage(e.target.value));
  };

  const MENU_ITEM_ID = "menuLabel_";
  const getMenuIds = () => {
    let result = [];
    let i;
    for (i = 0; i < headerLinks.length; i++) {
      if (!headerLinks[i].dontShow) result.push(MENU_ITEM_ID + i);
    }
    return result;
  };
  const ids = getMenuIds();
  const headerLblIds = ["menuLabelHome", ...ids];

  const HandleKeyPress = (e, headerLbl) => {
    let index = headerLblIds.indexOf(e.target.id);
    const lastItemIndex = headerLblIds.length - 1;
    if (isDesktop) {
      switch (e.keyCode) {
        //left arrow
        case 37:
          e.preventDefault();
          index > 1
            ? document.getElementById(headerLblIds[--index])?.focus()
            : document.getElementById("menuLabelHome")?.focus();
          break;
        //right arrow
        case 39:
          e.preventDefault();
          if (index <= lastItemIndex)
            document.getElementById(headerLblIds[++index])?.focus();
          break;
        //down arrow
        case 40:
          e.preventDefault();
          headerLbl &&
            !areAllChildrenHided(headerLbl.children) &&
            setSubMenu(e, headerLbl);
          focusOnFirstElemnt("subItemId_0_0");
          break;
      }
    } else {
      switch (e.keyCode) {
        //up arrow
        case 38:
          e.preventDefault();
          index > 1
            ? document.getElementById(headerLblIds[--index])?.focus()
            : document.getElementById("menuLabelHome")?.focus();
          break;
        //downarrow
        case 40:
          e.preventDefault();
          if (index <= lastItemIndex)
            document.getElementById(headerLblIds[++index])?.focus();
          break;
        //right arrow
        case 39:
          e.preventDefault();
          headerLbl &&
            !areAllChildrenHided(headerLbl.children) &&
            setSubMenu(e, headerLbl);
          focusOnFirstElemnt("subItemId_0_0");
          break;
        //Esc arrow
        case 27:
          e.preventDefault();
          closeMobileNav();
          break;
      }
    }
  };

  const HandleKeyToolsPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        ShowToolsHandler();
        break;
    }
  };

  const HandleKeySearchPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        ShowGlobalSearchHandler();
        break;
    }
  };

  const HandleKeyNotificationPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        ShowNotificationHandler();
        break;
    }
  };

  const HandleKeyHamburgerIconPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        openMobileNav();
        break;
    }
  };

  const HandleKeyCloseMenuMobilePressed = (e) => {
    switch (e.keyCode) {
      case 27:
      case 13:
      case 32:
        e.preventDefault();
        closeMobileNav();
        break;
    }
  };

  const HandleCloseNav = (e) => {
    switch (e.keyCode) {
      case 27:
        e.preventDefault();
        closeMobileNav();
        break;
    }
  };

  const HandleFocusTrapHamburgerDialog = (e) => {
    switch (e.keyCode) {
      case 9:
        TargetTrap(e, "hamburgerDialog");
        break;
    }
  };

  const updateAriaExpand = (headerLbl) => {
    return headerLbl.children ? (headerLbl.selected ? true : false) : "";
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const focusOnFirstElemnt = async (element) => {
    await sleep(100);
    const menuItem = document.getElementById(element);
    menuItem?.focus();
    //menuItem?.classList.add("selected");
  };

  const renderImage = "data:image/jpeg;base64," + avatarImageStream;
  return (
    <>
      {!hideNavAndFooter && (
        <>
          <ResizeWindowHook></ResizeWindowHook>
          <header
            className={`main-header
              ${isWhiteHeader ? "white-links" : ""}
              ${openMobileMenu ? "header-menu-mobile" : ""}
              ${scrollDirection === "down" ? "hide" : ""}
              `}
            onMouseLeave={(e) => (isDesktop ? setSubMenu(e) : null)}
          >
            <div className="main-header-bar">
              <Link to={`/${language}`} aria-label="ferrari logo">
                <img src="/img/logo-white.svg" className="logo" />
              </Link>

              <div
                tabIndex="0"
                aria-expanded="false"
                aria-label="profile menu"
                id="avatar-dropdown"
                className="dropdown"
                role="combobox"
                aria-controls="dropDownAvatar"
                aria-haspopup="listbox"
                onClick={showDropDownHandler}
                onKeyDown={(e) => showDropDownHandlerKeyPress(e)}
              >
                <DropDownAvatar>
                  {avatarImageStream.length > 0 ? (
                    <AvatarImage data-cs-mask src={renderImage} />
                  ) : (
                    <AvatarImage data-cs-mask src="/img/avatar_grey.png" />
                  )}
                  <img src="/img/arrow-down-header.svg" id="arrow-down" />
                </DropDownAvatar>
              </div>
              <AvatarDropDown
                showDropDown={showDropDown}
                showDropDownHandler={showDropDownHandler}
                setPageLight={setPageLight}
                pageLight={pageLight}
              />
            </div>
            <nav
              id="hamburgerDialog"
              onKeyDown={(e) => HandleFocusTrapHamburgerDialog(e)}
            >
              <div
                className="hamburger-icon"
                id="hamburgerMenuId"
                tabIndex="0"
                aria-label="hamburger menu"
                aria-haspopup="true"
                onClick={openMobileNav}
                onKeyDown={(e) => HandleKeyHamburgerIconPressed(e)}
              >
                {!isWhiteHeader && pageLight !== "dark" ? (
                  <img src="/img/hamburger-menu-black.svg" />
                ) : (
                  <img src="/img/hamburger-menu-white.svg" />
                )}
              </div>
              <div className="menu-mobile__first-line">
                {mobileBack ? (
                  <div
                    className="menu-mobile__back flex-align-center"
                    onClick={() =>
                      dispatch(coreComponentsActions.onMobileBack())
                    }
                  >
                    {pageLight !== "dark" ? (
                      <img src="/img/freccia.svg" />
                    ) : (
                      <img src="/img/freccia-dark.svg" />
                    )}
                    {/*<span>menù</span>*/}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="menu-mobile__close"
                  role="button"
                  aria-label="close"
                  tabIndex="0"
                  onClick={closeMobileNav}
                  onKeyDown={(e) => HandleKeyCloseMenuMobilePressed(e)}
                >
                  {pageLight !== "dark" ? (
                    <img src="/img/add-transparent.svg" />
                  ) : (
                    <img src="/img/close-dark.svg" />
                  )}
                </div>
              </div>
              {!mobileBack ? (
                <div onKeyDown={(e) => HandleCloseNav(e)}>
                  <div className="menu-mobile__user-recap">
                    {avatarImageStream.length > 0 ? (
                      <AvatarImage data-cs-mask src={renderImage} />
                    ) : (
                      <AvatarImage data-cs-mask src="/img/avatar_grey.png" />
                    )}

                    <div className="menu-mobile__user-recap-wrapper">
                      <div
                        data-cs-mask
                        className="menu-mobile__user-recap-name"
                      >
                        {userName?.name}
                      </div>
                      <div
                        className="menu-mobile__user-recap-action"
                        tabIndex="0"
                        id="profileLinkId"
                      >
                        <Link
                          to={`${language}/my-page`}
                          style={{ all: "unset", cursor: "pointer" }}
                          tabIndex="-1"
                        >
                          {tabsTitleMyPage[0]?.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="menu-mobile__theme">
                    <div className="menu-mobile__theme-recap">
                      <div className="menu-mobile__theme-recap-title">Tema</div>
                      <div className="menu-mobile__theme-recap-type">
                        {pageLight}
                      </div>
                    </div>
                    <div className="menu-mobile__theme-light">
                      <div onClick={() => setPageLight("light")}>
                        {pageLight !== "dark" ? (
                          <img src="/img/light-selected.svg" />
                        ) : (
                          <img src="/img/light-light.svg" />
                        )}
                      </div>
                      <div onClick={() => setPageLight("dark")}>
                        {pageLight === "dark" ? (
                          <img src="/img/dark-selected.svg" />
                        ) : (
                          <img src="/img/dark-light.svg" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="menu-mobile__current-section">
                  {mobileCurrentSection}
                </div>
              )}
              <div className="menu-mobile">
                <ul role="menubar">
                  <li>
                    <NavLink
                      tabIndex="0"
                      role="menuitem"
                      id="menuLabelHome"
                      onKeyDown={(e) => HandleKeyPress(e)}
                      to="/"
                      className={({ isActive }) =>
                        setLinkActive(
                          location.pathname === "/it" ? true : false
                        )
                      }
                    >
                      Home
                    </NavLink>
                  </li>
                  {headerLinks.map((headerLbl, i) => (
                    <React.Fragment key={i}>
                      {!headerLbl.dontShow && (
                        <li key={i}>
                          {headerLbl.children &&
                          !areAllChildrenHided(headerLbl.children) ? (
                            <>
                              <a
                                tabIndex="0"
                                role="menuitem"
                                aria-expanded={updateAriaExpand(headerLbl)}
                                aria-haspopup="true"
                                id={MENU_ITEM_ID + i}
                                onKeyDown={(e) => HandleKeyPress(e, headerLbl)}
                                href="#"
                                style={{ cursor: "default" }}
                                className={`${
                                  headerLbl.selected ||
                                  location.pathname.startsWith(headerLbl.path)
                                    ? "selected"
                                    : ""
                                }`}
                                onMouseOver={(e) =>
                                  isDesktop ? setSubMenu(e, headerLbl) : null
                                }
                                onClick={(e) =>
                                  !isDesktop ? setSubMenu(e, headerLbl) : null
                                }
                              >
                                {headerLbl.title}
                                {pageLight !== "dark" ? (
                                  <img src="/img/arrow-black.svg" />
                                ) : (
                                  <img
                                    src="/img/arrow-white.svg"
                                    style={{ transform: "rotate(90deg)" }}
                                  />
                                )}
                              </a>
                              {headerLbl.submenu ? (
                                <div className="menu-mobile__submenu selected">
                                  <h2>{headerLbl.title}</h2>
                                  <Submenu
                                    setLinkActive={setLinkActive}
                                    submenu={headerLbl.submenu}
                                    pageLight={pageLight}
                                    focusOnFirstElemnt={focusOnFirstElemnt}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <NavLink
                              tabIndex="0"
                              role="menuitem"
                              aria-expanded={updateAriaExpand(headerLbl)}
                              aria-haspopup="false"
                              id={MENU_ITEM_ID + i}
                              to={headerLbl.path}
                              onMouseOver={(e) =>
                                isDesktop ? setSubMenu(e) : null
                              }
                              onKeyDown={(e) => HandleKeyPress(e, headerLbl)}
                              className={({ isActive }) =>
                                setLinkActive(
                                  location.pathname.startsWith(headerLbl.path)
                                    ? true
                                    : isActive
                                )
                              }
                            >
                              {headerLbl.title}
                            </NavLink>
                          )}
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
                <div className="menu-mobile menu-mobile-footer">
                  {React.Children.toArray(
                    footerLinks.map((v) => {
                      const ReactGenericComponent =
                        COMPONENTS_MAPPING[v.template];
                      return (
                        <>
                          {ReactGenericComponent && (
                            <ul>
                              <li>
                                <a
                                  href={v.path}
                                  component={
                                    <ReactGenericComponent path={v.path} />
                                  }
                                >
                                  {v.title}
                                </a>
                              </li>
                            </ul>
                          )}
                        </>
                      );
                    })
                  )}
                </div>
              </div>
              <div className="nav-icons">
                <img
                  id="toolsIcon"
                  role="button"
                  aria-label="tools"
                  aria-expanded="false"
                  aria-haspopup="dialog"
                  tabIndex="0"
                  src={
                    isWhiteHeader || pageLight === "dark"
                      ? "/img/strumenti-icon-white.svg"
                      : "/img/strumenti-icon-black.svg"
                  }
                  onClick={() => ShowToolsHandler()}
                  onKeyDown={(e) => HandleKeyToolsPressed(e)}
                />
                <img
                  id="searchIcon"
                  role="button"
                  aria-haspopup="dialog"
                  aria-label="search"
                  aria-expanded="false"
                  tabIndex="0"
                  src={
                    isWhiteHeader || pageLight === "dark"
                      ? "/img/search-header-white.svg"
                      : "/img/search-header-black.svg"
                  }
                  onClick={() => ShowGlobalSearchHandler()}
                  onKeyDown={(e) => HandleKeySearchPressed(e)}
                />
                <div
                  id="notificationIcon"
                  role="button"
                  aria-label="notification"
                  aria-expanded="false"
                  aria-haspopup="dialog"
                  tabIndex="0"
                  className="notification-link"
                  onClick={() => ShowNotificationHandler()}
                  onKeyDown={(e) => HandleKeyNotificationPressed(e)}
                >
                  <img
                    src={
                      isWhiteHeader || pageLight === "dark"
                        ? "/img/notification-icon-without-white.svg"
                        : "/img/notification-icon-without-black.svg"
                    }
                  />
                  {notificationsArrayNotRead.length > 0 && (
                    <span>{notificationsArrayNotRead.length}</span>
                  )}
                </div>
              </div>
              {!mobileBack ? (
                <>
                  <div className="language-dropdown">
                    <select
                      defaultValue={language}
                      onChange={(e) => ChangeLanguageHandler(e)}
                    >
                      <option value="it">Italiano</option>
                      <option value="en">English</option>
                    </select>
                    {pageLight !== "dark" ? (
                      <img src="/img/arrow-black.svg" />
                    ) : (
                      <img
                        src="/img/arrow-white.svg"
                        style={{ transform: "rotate(180deg)" }}
                      />
                    )}
                  </div>
                  <div className="logout-button">
                    <Link
                      to={"logout"}
                      style={{
                        all: "unset",
                        fontSize: "16px",
                        color: "#8F8F8F",
                      }}
                    >
                      Logout
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </nav>
          </header>
          {openDesktopMenu && isDesktop ? <BackgroundDesktopMenu /> : <></>}
          {showToolsDialog && <ToolsDialog />}
          {showGlobalSearchDialog && <GlobalSearchDialog />}
          {showNotificationDialog && <NotificationDialog />}
        </>
      )}
    </>
  );
}

export default React.memo(Header);
