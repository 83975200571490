import styled from "styled-components";

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 24px;
  gap: 7px;
  label {
    font-size: 11px;
    color: rgb(112, 112, 112);
    margin: 0px;
    width: 100%;
  }
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 10px 0px;
  }
  input.highlight {
    border: 1px solid rgba(81, 203, 238, 1);
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
  div {
    padding: 7px 0px;
    border-bottom: 1px solid rgb(143, 143, 143);
    width: 100%;
    font-size: 16px;
    line-height: 15px;
    display: flex !important;
    img {
      margin-left: auto;
    }
    &:last-child {
      /* margin-bottom: 10%; */
    }
  }
`;

export const Select = styled.select`
  width: 100%;
  position: relative;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding-left: 0;
  padding-bottom: 0;
  &.highlight {
    border: 1px solid rgba(81, 203, 238, 1);
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  }
`;
