import styled from "styled-components";

export const Container = styled.section`
  width: 600px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.center ? "display: flex; justify-content: center" : "display: block"};
  h2 {
    margin: 96px 0 24px;
    text-align: center;
  }
  h3 {
    font-size: 16px;
    text-transform: uppercase;
  }
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input,
  & select {
    letter-spacing: 0.5px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-contente: center;
    gap: 30px;
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      border: none;
    }
    label {
      color: #707070;
    }
    .divContainer {
      background-color: var(--alternative-bg-color);
      padding: 25px 35px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  @media (max-width: 1279px) {
    width: 90%;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;

export const TitleForm = styled.h2`
  max-width: 800px;
  font-size: 28px;
  font-family: ferrari-sans-medium, sans-serif;
  text-align: center;
  line-height: 34px;
  display: flex;
  flex-direction: column;
  align-self: center;
  div {
    text-align: center;
    p {
      line-height: 20px;
      margin-top: 30px;
      font-family: ferrari-sans-regular, sans-serif;
      font-size: 18px;
      font-weight: 400;
    }
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;

export const RadioButton = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 100px;
  text-transform: uppercase;
  label {
    vertical-align: middle;
  }
  input {
    vertical-align: middle;
    margin-right: 8px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    &:checked {
      outline: 0.1px solid black;
      background-color: #000;
      appearance: none;
      margin: 3px 8px 0px 5px;
      padding: 6px;
      box-shadow: inset 0 0 0 2.5px #ffff;
    }
  }
`;
export const RadioButtonOpts = styled.div`
  display: flex;
  font-size: 13px;
  gap: 20px;
  div {
    label {
      color: var(--main-color);
    }
  }
`;

export const SectionForm = styled.div`
  display: flex;
  @media (max-width: 1279px) {
    flex-direction: column;
    gap: 15px;
  }
`;
export const SectionFormDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  input {
    font-size: 14px;
    vertical-align: sub;
    padding: 0 0 10px;
  }
  label {
    padding: 10px 0px;
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
    opacity: 1;
  }
`;
export const SectionRadioButton = styled.div`
  gap: 80px;
`;

export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-top: 0px;
`;
export const TextDescrGrey = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: #707070;
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  label {
    font-size: 12px;
    color: var(--carousel-dot-color);
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 7px 0px;
    padding: 10px 0px;
    color: #303030;
    :: -webkit-outer-spin-button,
    :: -webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    ::input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
  & input::placeholder {
    color: #707070;
  }
  input:not(:placeholder-shown) + label {
    opacity: 1;
  }
  input.disabled {
    border-color: none;
    border-width: 0px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  .activeModify {
    color: rgb(255, 255, 255);
    background: radial-gradient(
        199.89% 50% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        349.37% 116.89% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      rgb(218, 41, 28);
  }
  .activeDelete {
    background: unset;
    border: 1px solid #707070;
    color: var(--main-color);
  }

  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;
export const SendButton = styled.button`
  margin: auto;
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;
  &.activeSubmit {
    color: rgb(255, 255, 255);
    background: radial-gradient(
        199.89% 50% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        349.37% 116.89% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      rgb(218, 41, 28);
  }

  @media (max-width: 1279px) {
    max-width: 100%;
  }
`;
export const DeleteButton = styled.button`
  margin: auto;
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;

  @media (max-width: 1279px) {
    max-width: 100%;
  }
`;
