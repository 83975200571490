// import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
  // auth: {
  //   clientId: "6184bfb7-1241-4103-a298-d63b50d6a35c",
  //   authority: `https://devferrarib2ctenant.b2clogin.com/devferrarib2ctenant.onmicrosoft.com/B2C_1A_signup_signin_ICP`,
  //   knownAuthorities: ["devferrarib2ctenant.b2clogin.com"],
  //   redirectUri: "http://localhost:3000/login-callback",
  //   postLogoutRedirectUri: "http://localhost:3000/",
  // },
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES],
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        //   default:
        //     return;
        // }
      },
    },
  },
};

export const msalConfigInternal = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_INTERNAL_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES],
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequestInternal = {
  scopes: [process.env.REACT_APP_SCOPES],
  response_types: "token id_token",
  extraQueryParameters: { domain_hint: "Ferrari ADFS" },
  domainHint: "Ferrari ADFS",
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_SCOPES],
  response_types: "token id_token",
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_GRAPH_ME_ENDPOINT,
};
