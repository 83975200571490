import PropTypes from "prop-types";
// style
import { Container } from "./style";

function NewsImage({ data }) {
  return (
    <Container>
      <img src={data.fileReference} alt={data.alt} />
    </Container>
  );
}

NewsImage.propTypes = {
  data: PropTypes.exact({
    isDecorative: PropTypes.string,
    linkTarget: PropTypes.string,
    titleValueFromDAM: PropTypes.string,
    imageFromPageImage: PropTypes.string,
    fileReference: PropTypes.string,
    alt: PropTypes.string,
    displayPopupTitle: PropTypes.string,
    position: PropTypes.string,
    altValueFromDAM: PropTypes.string,
  }),
};

export default NewsImage;
