import { Container, TitleForm, SendButton } from "./style";
import React, { useRef, useState, useEffect } from "react";
import { useBusinessLogic } from "./useBusinessLogic";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setEventInfo,
  setCheckedConsents,
  setCurrentStep,
  setAppointmentLeWithGh,
} from "../../../pages/FormIscrizioneGiftHouseLe/FormIscrizioneGiftHouseSlice";
import { SetUserRegistration } from "../../../pages/FormIscrizioneGiftHouseLe/action";

//components
import Header from "./components/Header/Header";
import ComponentGiftHouse from "./components/ComponentGiftHouse/ComponentGiftHouse";
import ComponentLightExperience from "./components/ComponentLightExperience/ComponentLightExperience";
import ComponentPrivacy from "./components/ComponentPrivacy/ComponentPrivacy";
import { DialogNote } from "./components/ComponentGiftHouse/DialogNote/DialogNote";

function LayoutFormIscrizioneGiftHouse({ data, heroData, dataModal }) {
  const {
    updateButtonColor,
    updateCheckboxPrivacy,
    calculateAppointmentId,
    giftHouseLigthExperienceComponents,
    nucleoFamiliare,
    Guests,
    SlotGiftHouseLe,
    GtModel,
    SlotLight,
    yesLight,
  } = useBusinessLogic();
  const [allowed, setAllowed] = useState(null);

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.search.replace(/\?path=/, "");

  const pathCms = url
    .split("?")[0]
    .replace(/subscribe-form-gh/, "subscribe-recap-gh");
  const updatePathCms = pathCms.split("-subscribe-form")[0];

  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);

  //oggetto usato per il payload della post
  const eventUserInfo = useSelector(
    (state) => state.FormIscrizioneGiftHouse.defaultUserInfo
  );

  //oggetto contente info di policy checked
  const checkedConsents = useSelector(
    (state) => state.FormIscrizioneGiftHouse.checkedConsents
  );

  //selettore contente gli appuntamenti gift e light insieme
  const appointmentLightWithGh = useSelector(
    (state) => state.FormIscrizioneGiftHouse.appointmentLeWithGh
  );

  const subscribeError = useSelector(
    (state) => state.FormIscrizioneGiftHouse.subscribeError
  );

  const childsAreSubscribed = eventUserInfo?.childsSubscribed?.length > 0;
  const childsRegisteredByConsort = eventUserInfo?.childsSubscribed?.find(
    (e) => e?.subscriptionInfo?.subscribedBy !== eventUserInfo?.userFiscalCode
  )
    ? true
    : false;

  //gestione dei controlli (dropDown, radio ecc...)
  const handleValue = (e) => {
    const { name, value } = e.target;
    let currentChange = giftHouseLigthExperienceComponents?.filter(
      (el) => el?.name === name
    );
    //inserisco il value nell'array di componenti (sempre quello con step e value)
    switch (name) {
      case "AppointmentIdGhAndLe":
        dispatch(setEventInfo({ name, value: +value.split("-")?.[0] }));
        dispatch(setAppointmentLeWithGh({ value: +value.split("-")?.[1] }));
        dispatch(setCurrentStep(currentChange[0]?.step));
        setAllowed(null);
        break;
      case "NumGuests":
      case "AppointmentIdLe":
      case "AppointmentIdGift":
        dispatch(setEventInfo({ name, value: +value }));
        dispatch(setCurrentStep(currentChange[0]?.step));
        setAllowed(null);
        break;

      default:
        if (value === "ritiroLe") {
          dispatch(setEventInfo({ name: "lightExperience", value: "true" }));
        }
        dispatch(setEventInfo({ name, value }));
        dispatch(setCurrentStep(currentChange[0]?.step));
        setAllowed(null);
        break;
    }
  };

  const handlePrivacy = (e) => {
    const { id, checked } = e.currentTarget;

    let dateRegistered = checkedConsents?.filter((el) => el?.id === id);
    let subscription = checkedConsents?.filter((el) => {
      if (el?.id !== id) {
        return el;
      }
    });
    const spreadObject = dateRegistered ? subscription : checkedConsents;
    dispatch(
      setCheckedConsents([...spreadObject, { id: id, checked: checked }])
    );
  };

  const path = `subscription-recap-gh?path=${updatePathCms}-recap?eventCode=${eventUserInfo?.eventCodeGiftHouse},${eventUserInfo?.eventCodeLightExperience}`;
  //post con payload dinamico
  const onSubmitHandler = (e) => {
    e.preventDefault();
    //controllo policy prima di fare post
    if (
      checkedConsents?.length === data?.policiesRules?.items?.length &&
      !checkedConsents?.find((el) => el.checked === false)
    ) {
      const responseBody = bodyConstructor();
      setAllowed(true);
      dispatch(
        SetUserRegistration({
          responseBody,
          token,
          lang,
          path,
        })
      );
    } else {
      setAllowed(false);
    }
  };

  //popolamento familyArray
  const parseFamilyArray = () => {
    let familyArray;

    if (nucleoFamiliare?.value === "false" && !childsRegisteredByConsort) {
      familyArray = eventUserInfo?.childsInAge;
    } else if (
      childsRegisteredByConsort ||
      eventUserInfo?.childsInAge?.length < 1
    ) {
      familyArray = [];
    } else if (
      (!childsAreSubscribed ||
        (childsAreSubscribed && !childsRegisteredByConsort)) &&
      nucleoFamiliare?.value === "true"
    ) {
      familyArray = eventUserInfo?.familyComponents;
    }

    return familyArray;
  };

  const bodyConstructor = () => {
    let responseBody;
    const familyArray = parseFamilyArray();

    //payload del solo evento giftHouse
    const giftHouseSub = {
      eventCode: eventUserInfo?.eventCodeGiftHouse,
      secondaryEventCode: eventUserInfo?.eventCodeLightExperience,
      NumGuests: 0,
      title: "giftHouse",
      primaryEvent: true,
      additionalGift: GtModel?.value === "yes" ? true : false,
      appointmentId: calculateAppointmentId(data),
      consents: eventUserInfo?.consentsGiftHouse,
      familyMembers: familyArray,
    };

    if (yesLight?.value === "true" || SlotGiftHouseLe?.value !== null) {
      //payload del solo evento LightExp
      const lightExperienceSub = {
        eventCode: eventUserInfo?.eventCodeLightExperience,
        NumGuests: Guests?.value ? Guests?.value : 0,
        appointmentId:
          SlotGiftHouseLe?.value !== null
            ? appointmentLightWithGh.value
            : SlotLight?.value,
        consents: eventUserInfo?.consentsLightExperience,
        title: "ligthExperience",
        familyMembers:
          SlotGiftHouseLe?.value !== null
            ? eventUserInfo?.familyComponents
            : [],
      };

      responseBody = {
        mealMenus: [],
        type: eventUserInfo?.type,
        events: [giftHouseSub, lightExperienceSub],
      };
    } else {
      responseBody = {
        mealMenus: [],
        type: eventUserInfo?.type,
        events: [giftHouseSub],
      };
    }

    return responseBody;
  };

  useEffect(() => {
    if (document.querySelector(".checkbox-input")) updateCheckboxPrivacy();
    if (
      checkedConsents?.length === data?.policiesRules?.items?.length &&
      !checkedConsents?.find((el) => el.checked === false)
    ) {
      updateButtonColor("red");
    } else {
      updateButtonColor("disabled");
    }
  }, [
    data,
    eventUserInfo,
    updateButtonColor,
    checkedConsents,
    updateCheckboxPrivacy,
  ]);
  return (
    <>
      <Header data={heroData} />
      <Container>
        {subscribeError && (
          <DialogNote
            data={dataModal}
            id={"errore"}
            errorData={subscribeError}
          />
        )}
        <TitleForm>{data?.giftHouse?.title}</TitleForm>
        <form
          onSubmit={(e) => {
            onSubmitHandler(e);
          }}
          ref={formRef}
        >
          <ComponentGiftHouse
            data={data}
            allowed={allowed}
            onChange={handleValue}
          />
          {GtModel?.value !== null && SlotGiftHouseLe?.value === null && (
            <ComponentLightExperience
              data={data?.lightExperience}
              allowed={allowed}
              onChange={handleValue}
            />
          )}
          {((SlotGiftHouseLe?.value && GtModel?.value !== null) ||
            (Guests?.value !== null && SlotLight?.value !== null) ||
            yesLight?.value === "false") && (
            <ComponentPrivacy
              data={data?.policiesRules}
              allowed={allowed}
              onChange={handlePrivacy}
            />
          )}
          <SendButton className="submitButton">
            {data?.button?.title}
          </SendButton>
        </form>
      </Container>
    </>
  );
}

export default LayoutFormIscrizioneGiftHouse;
