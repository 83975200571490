import { RedirectButton, TitleForm, ContainerForbidden } from "../style";
import { useNavigate } from "react-router-dom";

export function ForbiddenDialog({ data }) {
  const navigate = useNavigate();

  function generateHtml(argument) {
    return { __html: argument };
  }

  return (
    <ContainerForbidden center>
      <TitleForm>
        {data?.forbiddenDialog?.title?.jcrTitle}
        <div
          dangerouslySetInnerHTML={generateHtml(
            data?.forbiddenDialog?.text?.text
          )}
        />
      </TitleForm>
      <RedirectButton onClick={() => navigate(`${data?.buttonRedirect?.link}`)}>
        {data?.buttonRedirect?.title}
      </RedirectButton>
    </ContainerForbidden>
  );
}
