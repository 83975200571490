import React from "react";
// style
import {
  Wrapper,
  Overlay,
  BoxContainer,
  ConfirmButton,
  CloseButton,
} from "./style";

function NotificaitonModal({
  data,
  confirmCallBack,
  closeModal,
  message,
  category,
  year,
}) {
  return (
    <Wrapper>
      <Overlay />
      <CloseButton onClick={closeModal}>
        <img src="/img/close-light.svg" width="40" height="40" />
      </CloseButton>
      <BoxContainer>
        <h2>{data?.modalTitle}</h2>
        <div>{message}</div>
        <ConfirmButton onClick={confirmCallBack}>
          {data?.modalCTA}
        </ConfirmButton>
      </BoxContainer>
    </Wrapper>
  );
}

export default React.memo(NotificaitonModal);
