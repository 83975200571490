import React from "react";
import {
  Wrapper,
  CardWrapper,
  Col1,
  Text1,
  Text2,
  Text3,
  Col2,
  CtaWrapper,
  OverflowAnimation,
} from "./style";
import CarouselWrapper from "../../../UI/carouselWrapper/CarouselWrapper";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";
import CtaIcon from "../../ctaIcon/CtaIcon";
import { useRef, useState } from "react";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";
import linkTarget from "../../../../utils/linkTarget";

function AsideCarousel({ data }) {
  const [loaded, setLoaded] = useState(0);
  const loader = useRef(null);
  return (
    <Wrapper ref={loader} style={{ opacity: loaded }}>
      <ShowOnIntersection
        loader={loader}
        setLoaded={setLoaded}
      ></ShowOnIntersection>
      <CarouselWrapper itemsLength={data?.items.length}>
        {React.Children.toArray(
          data?.items.map((item) => {
            return (
              <CardWrapper>
                <Col1>
                  <OverflowAnimation>
                    <Text1 className="home-text-animation">
                      <span>⏤ </span>
                      <span>{item?.category}</span>
                    </Text1>
                  </OverflowAnimation>
                  <OverflowAnimation>
                    <Text2 className="home-text-animation">{item.title}</Text2>
                  </OverflowAnimation>
                  <OverflowAnimation>
                    <Text3 className="home-text-animation">
                      {item.description}
                    </Text3>
                  </OverflowAnimation>
                  <OverflowAnimation>
                    <CtaWrapper className="home-text-animation">
                      <a
                        href={SetQueryStringPath(item.linkHref)}
                        target={linkTarget(item.linkHref)}
                        aria-label={item.title}
                      >
                        <span>{item.textHref}</span>
                        <CtaIcon showArrow={true} />
                      </a>
                    </CtaWrapper>
                  </OverflowAnimation>
                </Col1>
                <Col2>
                  <img src={item.fileReference} />
                </Col2>
              </CardWrapper>
            );
          })
        )}
      </CarouselWrapper>
    </Wrapper>
  );
}

export default React.memo(AsideCarousel);
