import { put, takeLatest } from "redux-saga/effects";
// utils
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { homeActions } from "./homeSlice";
import { FETCH_COMPONENTS } from "./constants";

function* fetchComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(homeActions.setComponents(result.data.data.components));
    yield put(homeActions.setSettings(result.data.data.settings));
    yield put(homeActions.setGlobalSearchComponents(result.data.data.header));
    yield put(
      homeActions.setNotificationsDialogComponents(
        result.data.data.header.notifications
      )
    );
    yield put(
      homeActions.setToolsDialogComponents(result.data.data.header?.tools)
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* homeSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponents);
}
