import React from "react";
import {
  ButtonContainer,
  CtaButton,
  CtaContainerButton,
  CtaLink,
} from "./style";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";
import { useDispatch, useSelector } from "react-redux";
import { myPageActions } from "../../../../pages/MyPage/myPageSlice";
import { Link } from "react-router-dom";

export function ButtonsCta({ data, isRecapPage, canEditEvent }) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.coreComponents.language);
  const handleRedirect = () => {
    dispatch(myPageActions.setTabIndex(3));
  };

  const setUrl = (url) => {
    let result = url;
    if (url) {
      if (url.includes("news-and-events/events/")) {
        const fixed = "/event-details?path=";
        const splittedUrl = url.split(`${lang}/`);
        result = fixed + `/${lang}/` + splittedUrl[1];
      }
    }

    return result;
  };

  return (
    <ButtonContainer>
      {(isRecapPage || (!isRecapPage && canEditEvent)) && (
        <CtaLink
          isClosed={!canEditEvent}
          href={
            isRecapPage
              ? SetQueryStringPath(data?.leftBtn?.link)
              : "/subscribe-form-gh?path=" + data?.leftBtn?.link
          }
        >
          <CtaButton
            color="var(--main-color)"
            bg="transparent"
            border="1px solid #d4d4d4"
          >
            {data?.leftBtn?.title}
          </CtaButton>
        </CtaLink>
      )}
      <CtaContainerButton isClosed={!canEditEvent}>
        <Link
          style={{ pointerEvents: !data?.rightBtn?.link ? "none" : "pointer" }}
          onClick={isRecapPage && handleRedirect()}
          to={setUrl(data?.rightBtn?.link)}
        >
          <CtaButton isClosed={!canEditEvent}>
            {data?.rightBtn?.title}
          </CtaButton>
        </Link>
      </CtaContainerButton>
    </ButtonContainer>
  );
}
