import { useDispatch, useSelector } from "react-redux";
// actions
import { rubricaActions } from "../../../../pages/Rubrica/rubricaSlice";
import {
  selectTabTitlesFromTabsArray,
  selectTabIndex,
} from "../../../../pages/Rubrica/selectors";
import BottomIcon from "../../../assets/bottom";
import { SelectWrapperWrapper } from "../../mensa/cafeteria-tabs/style";
import {
  Select,
  SelectWrapper,
  WrapperImg,
} from "../../strumenti/toolBoxList/style";

// style
import { Wrapper, List, ListItem } from "./style";

function RubricaTabs() {
  const dispatch = useDispatch();

  const tabIndex = useSelector(selectTabIndex());
  const tabsTitle = useSelector(selectTabTitlesFromTabsArray());

  const handleTabClick = (index) => {
    dispatch(rubricaActions.setTabIndex(index));
  };
  return (
    <Wrapper>
      {/* mobile */}
      <SelectWrapperWrapper>
        <SelectWrapper>
          <Select
            onChange={(e) => handleTabClick(+e.target.value)}
            noborder={true}
          >
            {tabsTitle?.map((current) => (
              <option value={current.id}>{current.title}</option>
            ))}
          </Select>
          <WrapperImg>
            <BottomIcon></BottomIcon>
          </WrapperImg>
        </SelectWrapper>
      </SelectWrapperWrapper>
      {/* desktop */}
      <List>
        {tabsTitle?.map((current) => (
          <ListItem
            key={current.id}
            className={tabIndex === current.id && "selected"}
            onClick={() => handleTabClick(current.id)}
          >
            {current.title}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
}

export default RubricaTabs;
