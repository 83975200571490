import { Button, Container, Text1, Text2 } from "./style";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { useEffect } from "react";
import { SetInitLang } from "../../utils/SetInitLang";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function Page404() {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  const coreLang =
    useSelector((state) => state.coreComponents.language) || SetInitLang();
  const rightOrigin = window.location.origin + "/" + coreLang; // prevent from serving the page with an incorrect lang
  if (
    !window.location.href.startsWith(rightOrigin + "/") &&
    window.location.href !== rightOrigin
  ) {
    window.location = rightOrigin;
  }

  ChangeTitleDoc(subjectCode, aggregatedDirection, "Page Error 404", "", true);
  return (
    <Container>
      <Text1>404</Text1>
      <Text2>PAGINA NON TROVATA</Text2>
      <Button>
        <Link to={"/"}>Torna alla homepage</Link>
      </Button>
    </Container>
  );
}

export default Page404;
