import styled from "styled-components";

export const Card = styled.div`
  opacity: 0;
  transition: opacity 1s;
  width: 100%;
  @media (min-width: 769px) {
    width: 304px;
  }
  @media (min-width: 1280px) {
    width: 384px;
    height: 387px;
  }
  @media (max-width: 1279px) {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    display: block;
  }
  img {
    display: block;
    transform: scale(1);
    transition: transform 0.6s;
    width: 100%;
  }
  &:hover img {
    transform: scale(1.1);
  }
`;
export const LinkWrapper = styled.div`
  display: block;
  overflow: hidden;
`;

export const Description = styled.div`
  margin: 20px 0px;
  color: rgb(230, 72, 61);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  span:nth-child(2) {
    height: 0px;
    border-bottom: 1px solid rgb(230, 72, 61);
    width: 96px;
    margin: 0px 8px;
  }
`;
export const TitleAndText = styled.div`
  height: 115px;
  margin-top: 24px;
`;
export const Title = styled.div`
  font-family: ferrari-sans-medium;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 16px;
  color: var(--main-color);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-transform: uppercase;
  @media (max-width: 1279px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Text = styled.div`
  height: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--main-color);
  flex: none;
  order: 1;
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  @media (max-width: 1279px) {
    font-size: 11px;
  }
`;
