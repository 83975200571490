import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  width: 90%;
  padding-bottom: 90px;
  h1 {
    margin-top: 32px;
  }
  @media screen and (min-width: 769px) {
    //width: 640px;
  }
  @media screen and (min-width: 1280px) {
    width: 1216px;
    h1 {
      margin-top: 96px;
    }
  }
`;
