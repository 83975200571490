import styled from "styled-components";

export const DocWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:nth-child(1)) {
    margin-top: 20px;
  }
`;

export const DocTitleAndData = styled.div`
  h4 {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 16px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: 50px;
  }
`;

export const ExtensionAndSize = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: rgb(143, 143, 143);
`;

export const DonwloadIcon = styled.div`
  color: var(--main-color);
  display: flex;
  align-items: center;
  margin-left: auto;
  text-decoration: none;
  cursor: pointer;
  span {
    display: none;
  }
  .download-tag-a {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  @media screen and (min-width: 1280px) {
    margin-right: 10px;
    display: block;
    span {
      display: inline;
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
`;
