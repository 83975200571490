import PropTypes from "prop-types";
// style
import { Container } from "./style";

// components
import EventDescription from "./eventDescription/EventDescription";
import EventPlace from "./eventPlace/EventPlace";
import EventImage from "./eventImage/EventImage";
import ThronPlayerWrapper from "../../../../thronPlayer/ThronPlayerWrapper";
import FiText from "./fi-text/FiText";
import FaqAccordionList from "./faqAccordionList/faqAccordionList";
import FiButton from "./fi-button/FiButton";

function LeftColumnContainer({ leftColumnComponents }) {
  const componentsMapping = {
    "fi-events/fi-event-info/fi-event-info-description": EventDescription,
    "fi-events/fi-event-info/fi-event-info-location": EventPlace,
    "thronconnector/components/thronmediaplayer": ThronPlayerWrapper,
    "fi-image": EventImage,
    "fi-text": FiText,
    "fi-faq-accordion-list": FaqAccordionList,
    "fi-button": FiButton,
  };

  return (
    <Container>
      {leftColumnComponents?.map((element, idx) => {
        const ReactGenericComponent = componentsMapping[element.resourceType];
        if (
          ReactGenericComponent &&
          element.resourceType === "thronconnector/components/thronmediaplayer"
        )
          return (
            <ReactGenericComponent
              key={`${element.resourceType}-${idx}`}
              data={element.data}
            />
          );
        return (
          ReactGenericComponent && (
            <ReactGenericComponent
              key={`${element.resourceType}-${idx}`}
              elementData={element.data}
            />
          )
        );
      })}
    </Container>
  );
}

LeftColumnContainer.propTypes = {
  leftColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default LeftColumnContainer;
