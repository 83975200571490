import styled from "styled-components";

export const Controllers = styled.div`
  position: relative;
  top: -${(props) => props.topController}px;
  margin: ${(props) => props.marginController}px;
  width: calc(100hv - ${(props) => props.marginController * 2}px);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
