import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: [],
  status: null,
  errorMessage: "",
};

const thankYouSlice = createSlice({
  name: "thankYou",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const thankYouActions = thankYouSlice.actions;
export default {
  name: thankYouSlice.name,
  reducer: thankYouSlice.reducer,
};
