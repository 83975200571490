import {
  Container,
  TitleForm,
  SendButton,
  InputSectionCheckbox,
  SectionForm,
  HeaderWrapper,
  HeroInfo,
  HeroTitle,
  HeroSubTitle,
  RequiredValue,
  TextDescr,
  PrivacyRules,
  EventTitle,
  GenericTitle,
  EventDescr,
} from "./style";
import CustomSelect from "../customSelect/CustomSelect";
import React, { useRef, useEffect, useState } from "react";
import { useBusinessLogic } from "./useBusinessLogic";
import { useDispatch, useSelector } from "react-redux";
import { SetUserRegistration } from "../../../pages/SimpleEventsSubscribeForm/action";
import { LayoutSimpleEventsRecap } from "./recapPage/LayoutSimpleEventsRecap";
import {
  setCheckedConsents,
  setEventInfo,
} from "../../../pages/SimpleEventsSubscribeForm/SimpleEventsSubscribeFormSlice";
import { selectHeroImg } from "../../../pages/SimpleEventsSubscribeForm/selectors";

function LayoutSimpleEventsSubscribeForm({ data }) {
  const {
    parseLabel,
    generateHtml,
    updateCheckboxPrivacy,
    getSubscriptionDay,
    updateButtonColor,
  } = useBusinessLogic();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState(null);

  //selector
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const showRecap = useSelector(
    (state) => state.SimpleEventsSubscribeForm.showRecap
  );
  const eventUserInfo = useSelector(
    (state) => state.SimpleEventsSubscribeForm.defaultUserInfo
  );
  const checkedConsents = useSelector(
    (state) => state.SimpleEventsSubscribeForm.checkedConsents
  );
  const heroData = useSelector(selectHeroImg());
  const myPagePath = headerLinks?.find((v) => v.template === "my-page")?.path;

  const allEvents = data?.eventItems?.items?.map((event) => event);
  const AppointmentWithAvailablePlaces = allEvents?.map((singleEvent) =>
    singleEvent.appointments.find(
      (el) => el.availablePlacesFrontEnd - el.takenSeats >= 1
    )
  );

  const handleEventInfo = (e, code, consents) => {
    const { value } = e.currentTarget;

    let dateRegistered = eventUserInfo?.events?.filter(
      (event) => event?.eventCode === code
    );
    let subscription = eventUserInfo?.events?.filter((event) => {
      if (event?.eventCode !== code) {
        return event;
      }
    });
    const spreadObject = dateRegistered ? subscription : eventUserInfo.events;
    dispatch(
      setEventInfo({
        ...eventUserInfo,
        events: [
          ...spreadObject,
          {
            eventCode: code,
            consents: consents,
            appointmentId: +value,
            NumGuests: 0,
          },
        ],
      })
    );
  };
  const handlePrivacy = (e) => {
    const { id, checked } = e.currentTarget;

    let dateRegistered = checkedConsents?.filter((el) => el?.id === id);
    let subscription = checkedConsents?.filter((el) => {
      if (el?.id !== id) {
        return el;
      }
    });
    const spreadObject = dateRegistered ? subscription : checkedConsents;
    dispatch(
      setCheckedConsents([...spreadObject, { id: id, checked: checked }])
    );
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (
      (!data?.policiesRules?.items ||
        (checkedConsents.length === data?.policiesRules?.items?.length &&
          !checkedConsents?.find((el) => el.checked === false))) &&
      eventUserInfo?.events?.length > 0
    ) {
      setAllowed(true);
      dispatch(
        SetUserRegistration({
          responseBody: eventUserInfo,
          myPagePath: myPagePath,
          token,
          lang,
        })
      );
    } else {
      setAllowed(false);
    }
  };

  useEffect(() => {
    if (
      eventUserInfo?.events?.[0] &&
      !eventUserInfo?.events?.[0]?.primaryEvent
    ) {
      dispatch(
        setEventInfo({
          ...eventUserInfo,
          events: [
            {
              ...eventUserInfo.events[0],
              primaryEvent: true,
            },
          ],
        })
      );
    }
    if (!showRecap && AppointmentWithAvailablePlaces) {
      updateCheckboxPrivacy();
      if (
        (!data?.policiesRules?.items ||
          (checkedConsents.length === data?.policiesRules?.items?.length &&
            !checkedConsents?.find((el) => el.checked === false))) &&
        eventUserInfo?.events?.length > 0
      ) {
        updateButtonColor("red");
      } else {
        updateButtonColor("disabled");
      }
    }
  }, [
    checkedConsents,
    eventUserInfo,
    AppointmentWithAvailablePlaces,
    showRecap,
    dispatch,
    updateButtonColor,
  ]);

  return (
    <>
      <HeaderWrapper>
        <img src={heroData?.fileReference} />
        <HeroInfo>
          <HeroTitle>{heroData?.title}</HeroTitle>
          <HeroSubTitle>{heroData?.subTitle}</HeroSubTitle>
        </HeroInfo>
      </HeaderWrapper>
      {!showRecap ? (
        <>
          <TitleForm>{data?.eventTitleDescr?.title}</TitleForm>
          <GenericTitle>
            <p
              dangerouslySetInnerHTML={generateHtml(
                data?.eventTitleDescr?.description
              )}
            />
          </GenericTitle>
          <Container>
            <form
              onSubmit={(e) => {
                onSubmitHandler(e);
              }}
              ref={formRef}
            >
              {React.Children.toArray(
                data?.eventItems?.items?.map((event) => (
                  <SectionForm>
                    <EventTitle>{event?.title}</EventTitle>
                    <EventDescr
                      dangerouslySetInnerHTML={generateHtml(event?.richText)}
                    ></EventDescr>
                    <h4>{event?.slotLabel}</h4>
                    <TextDescr>{event?.slotText}</TextDescr>
                    <CustomSelect
                      name={event?.title}
                      placeholderString={event?.slotLabel}
                      onChange={(e) =>
                        handleEventInfo(e, event?.eventCode, event?.consents)
                      }
                    >
                      {React.Children.toArray(
                        event?.appointments?.map((appointment) => {
                          if (
                            appointment?.availablePlacesFrontEnd -
                              appointment?.takenSeats >
                              0 ||
                            data?.savedAppointmentId === appointment?.id
                          ) {
                            return (
                              <option
                                className="data-evento"
                                value={appointment?.id}
                                id={appointment?.id}
                                key={appointment?.id}
                              >
                                {getSubscriptionDay(
                                  appointment?.appointmentStartDate,
                                  appointment?.appointmentEndDate,
                                  lang
                                )}
                              </option>
                            );
                          } else {
                            return "";
                          }
                        })
                      )}
                    </CustomSelect>
                    {!eventUserInfo?.events?.length > 0 &&
                      allowed === false && (
                        <RequiredValue id="required">
                          {event?.errorMessage}
                        </RequiredValue>
                      )}
                  </SectionForm>
                ))
              )}
              <PrivacyRules>
                <TextDescr>{data?.policiesRules?.title}</TextDescr>
                {React.Children.toArray(
                  data?.policiesRules?.items?.map((policie, i) => {
                    return (
                      <InputSectionCheckbox>
                        <input
                          disabled
                          id={`checkBoxPrivacy${i}`}
                          type="checkbox"
                          className="checkbox-input"
                          onChange={(e) => handlePrivacy(e)}
                          rawtext={policie?.richText}
                        />
                        <span
                          className=""
                          dangerouslySetInnerHTML={generateHtml(
                            parseLabel(`${i}`, policie?.richText)
                          )}
                        />
                      </InputSectionCheckbox>
                    );
                  })
                )}
                {(checkedConsents?.find((el) => el.checked === false) ||
                  checkedConsents.length !==
                    data?.policiesRules?.items?.length) &&
                  allowed === false && (
                    <RequiredValue id="required">
                      {data?.policiesRules?.errorMessage}
                    </RequiredValue>
                  )}
              </PrivacyRules>
              <SendButton className="submitButton">
                {data?.button?.title}
              </SendButton>
            </form>
          </Container>
        </>
      ) : (
        <LayoutSimpleEventsRecap events={data?.eventItems?.items} />
      )}
    </>
  );
}

export default LayoutSimpleEventsSubscribeForm;
