import styled from "styled-components";

export const WrapperRecap = styled.div`
  width: 700px;
  margin: 0 auto 96px;

  @media (max-width: 1279px) {
   width: 600px;
  }
  @media (max-width: 601px) {
    width: auto;
  }
`;

export const GenericTitle = styled.div`
  width: 100%;
  margin: 0px auto;
  text-align: center;
  font-size: 28px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 34px;
  padding: 0;

  p {
    font-family: ferrari-sans-regular, sans-serif;
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;

export const RecapContainer = styled.div`
  padding: 0 40px;
  background-color: none;
  @media (max-width: 1279px) {
    padding: 0;
  }
`;
export const TitleForm = styled.h3`
  font-size: 18px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 28px;
  margin: 30px 0;
  font-weight: 400;
  padding: 0 40px;
`;

