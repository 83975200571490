import styled from "styled-components";

export const WebinarH1 = styled.h1`
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 5px;
  @media (min-width: 1280px) {
    margin-bottom: 64px;
  }
  @media (max-width: 1279px) {
    font-size: 22px;
    line-height: 28px;
  }
`;
