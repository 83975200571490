import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// actions
import { newsActions } from "../../../../pages/News/newsSlice";
//components
import SearchFilterDialog from "../../dialog/news/SearchFilterDialog";

import {
  TitleH1,
  SpanFilter,
  CtaFilter,
  FilterIcon,
  FilterIconPath1,
  FilterIconPath2,
} from "./style";

function NewsTitle({ data }) {
  const dispatch = useDispatch();
  const showDialog = useSelector((state) => state.news.showDialog);

  return (
    <>
      <TitleH1>
        {data?.title}
        <SpanFilter onClick={() => dispatch(newsActions.showDialog(true))}>
          <FilterIcon>
            <FilterIconPath1 />
            <FilterIconPath2 />
          </FilterIcon>
          <CtaFilter>{data.filter?.filterLabel}</CtaFilter>
        </SpanFilter>
      </TitleH1>
      {showDialog && <SearchFilterDialog data={data?.filter} />}
    </>
  );
}

NewsTitle.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    filter: PropTypes.exact({
      filterLabel: PropTypes.string,
      placeholderKeyword: PropTypes.string,
      showNumberResultsLabel: PropTypes.string,
      removeFiltersLabel: PropTypes.string,
      categoryList: PropTypes.array,
    }),
    position: PropTypes.string,
  }),
  cardListResults: PropTypes.array,
};

export default NewsTitle;
