import PropTypes from "prop-types";
// style
import { Wrapper } from "./style";
import { useSelector } from "react-redux";
import { selectAvatarImage } from "../../../../pages/Home/selectors";

function Avatar({ components: avatarComponents }) {
  const avatarImageStream = useSelector(selectAvatarImage());
  const renderImage = "data:image/jpeg;base64," + avatarImageStream;
  return (
    <Wrapper>
      {avatarImageStream.length > 0 ? (
        <img data-cs-mask src={renderImage} alt="user_image" />
      ) : (
        <img data-cs-mask src="/img/avatar_grey.png" alt="user_image" />
      )}
      <div>
        {avatarComponents.name} {avatarComponents.surname}
      </div>
    </Wrapper>
  );
}

Avatar.propTypes = {
  fileReference: PropTypes.string,
  name: PropTypes.object,
  surname: PropTypes.object,
};

export default Avatar;
