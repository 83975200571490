import styled from "styled-components";

export const Container = styled.section`
  width: 1216px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  place-content: center;
  flex-direction: column;
  gap: 48px;
  text-align: center;
  min-height: 100vh;
  padding-top: 48px;
  box-sizing: border-box;
  @media (max-width: 1279px) {
    width: 90%;
  }
`;
