import React from "react";
import {
  CardDetailsDate,
  CardDetailsLocation,
  CardDetailsTitle,
  CardLocationContainer,
  ContainerCardDetails,
  ContainerCardImg,
  ContainerNextEvent,
  WrapperNextEvents,
} from "./style";
import { SectionTitle } from "../style";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import { Link } from "react-router-dom";

export function NextEvents({ data }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const today = Date.now();

  const nextThreeEvents = data?.nextEvents
    ?.filter((singleEvent) =>
      today < new Date(singleEvent?.eventDate) ? singleEvent : []
    )
    .sort((a, b) => Date.parse(a.eventDate) - Date.parse(b.eventDate))
    .slice(0, 3);

  return (
    <WrapperNextEvents>
      <SectionTitle>{data?.nextEventTitle}</SectionTitle>
      {React.Children.toArray(
        nextThreeEvents?.map((singleEvent) => {
          if (!singleEvent.path.includes("premio-di-competitivita")) {
            return (
              // <ContainerNextEvent
              //   href={"/event-details?path=" + singleEvent?.path}
              // >
              <Link to={"/event-details?path=" + singleEvent?.path}>
                <ContainerCardImg>
                  {isDesktop ? (
                    <img src={singleEvent?.image} style={{ width: "384px" }} />
                  ) : (
                    <img
                      src={
                        singleEvent?.fileReferenceMobile || singleEvent?.image
                      }
                    />
                  )}
                </ContainerCardImg>
                <ContainerCardDetails>
                  <CardDetailsDate>
                    {`${
                      singleEvent?.dateDay
                    } ${singleEvent?.dateMonth?.toUpperCase()} ${new Date(
                      singleEvent?.date
                    ).getFullYear()}`}
                  </CardDetailsDate>
                  <CardDetailsTitle>{singleEvent?.title}</CardDetailsTitle>
                  <CardLocationContainer>
                    <CardDetailsLocation>
                      {singleEvent?.locations?.map(
                        (location) => location + " "
                      )}
                    </CardDetailsLocation>
                  </CardLocationContainer>
                </ContainerCardDetails>
              </Link>
              // </ContainerNextEvent>
            );
          }
        })
      )}
    </WrapperNextEvents>
  );
}

export default React.memo(NextEvents);
