import styled from "styled-components";

export const ToolBoxWrapper = styled.div`
  width: 280px;
  height: 280px;
  box-sizing: border-box;
  display: flex;
  background: #fff;
  border-left: 4px solid transparent;
  color: #303030;
  a {
    color: #303030;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 1279px) {
      padding: 0px !important;
    }
  }

  img {
    max-width: 40px;
  }
  img:nth-child(1) {
    width: 40px;
  }
  img:nth-child(2) {
    margin-left: auto;
  }

  :hover {
    border-color: rgb(218, 41, 28);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 32px;
  }
  @media screen and (max-width: 1279px) {
    padding: 24px 15px;
  }
`;

export const SpanToolBoxIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div {
    background-color: #ffffff;
    border-radius: 100%;
    width: auto;
    height: auto;
  }
  img {
    padding: 2px;
  }
`;

export const SpanDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  text-transform: none;
  font-size: 11px;
  margin-top: 12px;

  span:nth-child(1) {
    font-size: 18px;
    text-transform: uppercase;
  }
  span:nth-child(2) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 80px;
`;
