import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1280px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 32px;
    gap: 16px;
    background: var(--alternative-bg-color);
    border-left: 4px solid transparent;
  }
  @media (max-width: 1279px) {
    background: var(--alternative-bg-color);
    display: flex;
    align-items: center;
    padding: 30px 38px 30px 25px;
    gap: 13px;
    border-bottom: 3px solid transparent;
  }
  &.selected {
    border-color: rgb(218, 41, 28);
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 32px;
    img.box-arrow {
      transform: rotate(270deg);
    }
  }
  .svg-image {
    width: 36px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
  }
  div {
    font-size: 13px;
    text-transform: uppercase;
  }
  p {
    font-size: 11px;
    line-height: 20px;
    display: none;
    @media (min-width: 1280px) {
      display: block !important;
    }
  }
  svg {
    width: 36px;
    margin-left: auto;
    cursor: pointer;
    @media (min-width: 1280px) {
      transform: rotate(90deg);
      margin-left: 0px !important;
    }
  }
`;
