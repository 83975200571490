import { ButtonNoContent, ButtonNoContentCircle, ButtonNoContentImg, NoContent } from "./style";

export function NoRequest({label}){
    return (
        <NoContent>
          <ButtonNoContent>
            <ButtonNoContentImg />
            <ButtonNoContentCircle />
          </ButtonNoContent>
          <p>{label}</p>
        </NoContent>)
}