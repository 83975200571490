import React from "react";
import { Link } from "react-router-dom";
// style
import { Wrapper, CardCTA, SpanCtaIcon, CtaCircle } from "./style";
import { useSelector } from "react-redux";

function ShowMore({ data }) {
  const lang = useSelector((state) => state.coreComponents.language);

  return (
    <Wrapper style={{ opacity: 1, transition: "1s opacity" }}>
      <CardCTA>
        <Link
          to={`/${lang}/news-and-events/events`}
          aria-label={data?.category}
        >
          <span>{data?.goToAllEventsLabel}</span>
          <SpanCtaIcon>
            <CtaCircle>
              <svg viewBox="0 0 24 24">
                <circle cx={12} cy={12} r={11} stroke={1} />
              </svg>
            </CtaCircle>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 16L11.2677 9.54697C11.7148 9.18016 12 8.62337 12 8C12 7.37663 11.7148 6.81984 11.2677 6.45304L4 0L8 8L4 16Z"
                fill="var(--cta-color)"
              />
            </svg>
          </SpanCtaIcon>
        </Link>
      </CardCTA>
    </Wrapper>
  );
}

export default React.memo(ShowMore);
