import { ComponentContainer, InputSection, TextDescr } from "../style";
import ErrorField from "./ErrorField/ErrorField";

export default function ComponentReport({
  data,
  bodyPayload,
  notRequired,
  allowed,
  handleEventInfo,
}) {
  function generateHtml(argument) {
    return { __html: argument };
  }

  return (
    <>
      <ComponentContainer>
        <h3>{data?.report?.reportTitle} </h3>
        <TextDescr
          dangerouslySetInnerHTML={generateHtml(`${data?.report?.richText}`)}
        />
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="pointOfSale"
            name="pointOfSale"
            placeholder={data?.report?.companyNameLbl}
            defaultValue={bodyPayload?.pointOfSale}
            type="text"
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="pointOfSale">{data?.report?.companyNameLbl}</label>
        </InputSection>
        {!bodyPayload?.pointOfSale && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="pointOfSaleAddress"
            name="pointOfSaleAddress"
            placeholder={data?.report?.companyAddressLbl}
            defaultValue={bodyPayload?.pointOfSaleAddress}
            type="text"
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="addressLink">{data?.report?.companyAddressLbl}</label>
        </InputSection>
        {!bodyPayload?.pointOfSaleAddress && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="typeProduct"
            name="typeProduct"
            placeholder={data?.report?.productTypeLbl}
            defaultValue={notRequired?.typeProduct || ""}
            type="text"
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="typeProduct">{data?.report?.productTypeLbl}</label>
        </InputSection>
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="note"
            name="note"
            placeholder={data?.report?.reasonLbl}
            defaultValue={notRequired?.note || ""}
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="note">{data?.report?.reasonLbl}</label>
        </InputSection>
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            autoComplete="nope"
            id="numberProducts"
            name="numberProducts"
            placeholder={data?.report?.numOfProductsLbl}
            defaultValue={notRequired?.numberProducts || null}
            type="number"
            onChange={(e) => {
              handleEventInfo(e);
            }}
            onWheel={(e) => e.currentTarget.blur()}
          />
          <label htmlFor="numberOfProducts">
            {data?.report?.numOfProductsLbl}
          </label>
        </InputSection>
      </ComponentContainer>
    </>
  );
}
