import React from "react";
import { useState } from "react";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";
import linkTarget from "../../../../utils/linkTarget";
// style
import {
  Wrapper,
  CardCTA,
  Title,
  SpanCtaIcon,
  CtaCircle,
  TextContainer,
  CafeteriaName,
  CtaContainer,
  Line,
  Switch,
  TextSwitch,
  CardContainerMobile,
} from "./style";
import { useRef } from "react";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";

function MensaCard({ data }) {
  const [cardSettings, SetCardSettings] = useState(data?.[0]);
  const [loaded, setLoaded] = useState(0);
  const loader = useRef(null);

  const arraySwitchElements = data?.filter((el) => el?.seeOtherRestaurant);
  const HandleSwitchRestourant = () => {
    const indexOfCurrentData = arraySwitchElements?.indexOf(cardSettings);

    arraySwitchElements?.length === indexOfCurrentData + 1
      ? SetCardSettings(data?.[0])
      : SetCardSettings(arraySwitchElements?.[indexOfCurrentData + 1]);
  };

  return (
    <Wrapper ref={loader} style={{ opacity: loaded }}>
      <ShowOnIntersection
        loader={loader}
        setLoaded={setLoaded}
      ></ShowOnIntersection>
      <img src={cardSettings?.fileReference} />
      <CardCTA>
        <Title>
          <span>⏤</span>
          <span>{cardSettings?.category}</span>
        </Title>
        <CardContainerMobile>
          <TextContainer>
            <CafeteriaName
              switchActive={
                arraySwitchElements?.length > 1 &&
                cardSettings?.seeOtherRestaurant
                  ? true
                  : false
              }
            >
              {cardSettings?.restaurantName}
            </CafeteriaName>
            <CtaContainer>
              {cardSettings?.textHref && (
                <a
                  href={SetQueryStringPath(cardSettings?.linkHref) || "#"}
                  target={linkTarget(cardSettings?.linkHref)}
                >
                  <span>{cardSettings?.textHref}</span>
                  <SpanCtaIcon>
                    <CtaCircle>
                      <svg viewBox="0 0 24 24">
                        <circle cx={12} cy={12} r={11} stroke={1} />
                      </svg>
                    </CtaCircle>
                    <svg
                      viewBox="0 0 8 16"
                      width={8}
                      height={16}
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ height: 16, width: 8 }}
                    >
                      <path
                        fill="rgb(255, 255, 255)"
                        d="M7.268 9.547L0 16l4-8-4-8 7.268 6.453C7.715 6.82 8 7.377 8 8c0 .623-.285 1.18-.732 1.547z"
                      />
                    </svg>
                  </SpanCtaIcon>
                </a>
              )}
              {cardSettings?.textHrefBasket && (
                <a
                  href={SetQueryStringPath(cardSettings?.linkHrefBasket) || "#"}
                  target={linkTarget(cardSettings?.linkHrefBasket)}
                >
                  <span>{cardSettings?.textHrefBasket}</span>
                  <SpanCtaIcon>
                    <CtaCircle>
                      <svg viewBox="0 0 24 24">
                        <circle cx={12} cy={12} r={11} stroke={1} />
                      </svg>
                    </CtaCircle>
                    <svg
                      viewBox="0 0 8 16"
                      width={8}
                      height={16}
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ height: 16, width: 8 }}
                    >
                      <path
                        fill="rgb(255, 255, 255)"
                        d="M7.268 9.547L0 16l4-8-4-8 7.268 6.453C7.715 6.82 8 7.377 8 8c0 .623-.285 1.18-.732 1.547z"
                      />
                    </svg>
                  </SpanCtaIcon>
                </a>
              )}
            </CtaContainer>
          </TextContainer>
          {arraySwitchElements?.length > 1 && cardSettings?.seeOtherRestaurant && (
            <>
              <Line></Line>
              <Switch
                onClick={() => HandleSwitchRestourant()}
                role="button"
                aria-label={cardSettings?.seeOtherRestaurant}
                tabIndex="0"
              >
                <TextSwitch>{cardSettings?.seeOtherRestaurant}</TextSwitch>
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.4483 8.26962C23.7861 7.95069 24 7.50137 24 7C24 6.49862 23.7861 6.04931 23.4483 5.73037L23.4518 5.72862L17 0L20.0636 6.12719L10.6319 6.5625C10.2831 6.5625 10 6.7585 10 7C10 7.2415 10.2831 7.4375 10.6319 7.4375L20.0636 7.87281L17 14L23.4518 8.27137L23.4483 8.26962Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.551689 15.7304C0.213938 16.0493 6.55791e-07 16.4986 6.11959e-07 17C5.68128e-07 17.5014 0.213938 17.9507 0.551689 18.2696L0.548188 18.2714L7 24L3.9364 17.8728L13.3681 17.4375C13.7169 17.4375 14 17.2415 14 17C14 16.7585 13.7169 16.5625 13.3681 16.5625L3.9364 16.1272L7 10L0.548188 15.7286L0.551689 15.7304Z"
                    fill="white"
                  />
                </svg>
              </Switch>
            </>
          )}
        </CardContainerMobile>
      </CardCTA>
    </Wrapper>
  );
}

export default React.memo(MensaCard);
