import styled from "styled-components";

export const TitleForm = styled.h2`
  max-width: 819px;
  font-size: 36px;
  font-family: ferrari-sans-medium, sans-serif;
  text-align: center;
  line-height: 43px;
  display: flex;
  align-self: center;
  margin: 96px 0 24px;
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    margin: 40px 0 20px;
  }
`;

export const Container = styled.section`
  width: 800px;
  margin: 0 auto 96px;
  padding-bottom: 50px;
  ${(props) =>
    props.center ? "display: flex; justify-content: center" : "display: block"};
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input,
  & select {
    letter-spacing: 0.5px;
  }
  form {
    input {
      border-width: 0px 0px 0px;
    }
  }
  input::placeholder {
    color: #8f8f8f;
  }
  .active {
    box-shadow: inset 4px 0px #da291c;
    input {
      border-width: 0px 0px 1px;
    }
    input::placeholder {
      color: #8f8f8f;
    }
  }

  @media (max-width: 1279px) {
    width: 90%;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;

export const ScrollButtons = styled.div`
  position: fixed;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  img {
    cursor: pointer;
  }
  @media screen and (max-width: 1279px) {
    right: 5px;
  }
`;
