import { useDispatch, useSelector } from "react-redux/es/exports";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { fetchComponents } from "./actions";
import { selectNewsComponents } from "./selectors";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";

//components
import GoToAllNews from "../../components/UI/news/goToAllNews/GoToAllNews";
import NewsDetailContainer from "../../components/UI/news/newsDetailContainer/NewsDetailContainer";
import RelatedNewsList from "../../components/UI/news/relatedNewsList/RelatedNewsList";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import LayoutNewsDetails from "../../components/UI/news/LayoutNewsDetails";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { selectAggregatedDirection } from "../MyPage/selectors";

function NewsDetail() {
  const location = useLocation();
  const dispatch = useDispatch();

  const newsDetailComponents = useSelector(selectNewsComponents());
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);

  const url = location.search.replace(/\?path=/, "");
  const coreLang = useSelector((state) => state.coreComponents.language);

  const relatedCardList = newsDetailComponents?.components?.find(
    (el) => el.resourceType === "news-detail/fi-related-news"
  );
  const { status, errorData } = GetStatusAndErrorData("newsDetail");

  useMemo(() => {
    url && token && dispatch(fetchComponents({ path: url, token, coreLang }));
  }, [url]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(
    subjectCode,
    aggregatedDirection,
    newsDetailComponents?.settings?.jcrTitle,
    url
  );
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        newsDetailComponents?.components && (
          <LayoutNewsDetails>
            <GoToAllNews />
            <NewsDetailContainer data={newsDetailComponents?.components} />
            {relatedCardList && <RelatedNewsList data={relatedCardList.data} />}
          </LayoutNewsDetails>
        )
      )}
    </>
  );
}

export default NewsDetail;
