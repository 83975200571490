import React from "react";
import CafeteriaTabs from "../cafeteria-tabs/CafeteriaTabs";
import CaloricIntake from "../caloricIntake/CaloricIntake";
import CafeteriaDish from "../cafeteriaDishes/CafeteriaDish";
import PropTypes from "prop-types";

const CafeteriaDishSection = ({ data, currentDishes }) => {
  const { cafeteriaCaloricIntake } = data;
  return (
    <>
      <CafeteriaTabs />
      <div className="cafeteria-slot-2">
        <div className="cafeteria-dishes-wrapper">
          {React.Children.toArray(
            currentDishes.map((dish) => <CafeteriaDish dish={dish} />)
          )}
          <div className="desktop-small grey-scale-black-100 cafeteria-dishes-veg">
            <img src="/img/veg.svg" />
            <span>- vegetariano</span>
          </div>
          <div className="desktop-small grey-scale-black-100 cafeteria-dishes-allergens">
            <div>
              *Piatti prodotti in una cucina in cui si utilizzano ingredienti
              contenenti tutti&nbsp; i 14 allergeni.&nbsp;
            </div>
            <div>
              Non è possibile escludere una contaminazione crociata. In caso di
              allergie o forti intolleranze rivolgersi al servizio Diete
              Speciali.
            </div>
          </div>
        </div>
        <CaloricIntake cafeteriaCaloricIntake={cafeteriaCaloricIntake} />
      </div>
    </>
  );
};
CafeteriaDishSection.propTypes = {
  currentDishes: PropTypes.array,
  data: PropTypes.object,
};

export default CafeteriaDishSection;
