import styled from "styled-components";

export const Container3dMap = styled.div`
  position: relative;
  width: 100vw;
  gap: 50px;
  top: -3px;
  iframe {
    position: relative;
    display: block; /* iframes are inline by default */
    background: #000;
    border: none; /* Reset default border */
    height: calc(100vh - 225px);
    width: 100%;
  }
  @media screen and (min-width: 990px) and (max-width: 1279px) {
    top: 50px;
  }
  @media screen and (max-width: 1279px) {
    height: calc(100vh + 50px);
    max-height: calc(100vh - 285px);
    margin-bottom: 10px;
    top: -10px;
    iframe {
      height: calc(100vh - 100px);
      max-height: calc(100vh - 225px);
    }
  }
  @media screen and (max-height: 600px) {
    height: calc(100vh);
    max-height: calc(100vh - 285px);
    margin-bottom: 10px;
    top: -10px;
    iframe {
      height: calc(100vh - 100px);
      max-height: calc(100vh - 225px);
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  iframe {
    display: block; /* iframes are inline by default */
    background: #000;
    border: none; /* Reset default border */
    height: 100vh; /* Viewport-relative units */
    width: 100vw;
  }
`;

export const ImgHeader = styled.img`
  margin-top: -92px;
  position: relative;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  @media screen and (max-width: 1279px) {
    margin-top: 0;
  }
`;
