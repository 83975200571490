import { useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActiveToken } from "../pages/Login/actions";

//-----DEV------------------------------------------------------
//REFRESH_THRESHOLD = 3540 --> 59 minutes in seconds
//TOKEN_CHECK_INTERVAL = 3000 --> 3 seconds in milliseconds
//-----PROD------------------------------------------------------
//REFRESH_THRESHOLD = 600 --> 10 minutes in seconds
//TOKEN_CHECK_INTERVAL = = 300000 --> 5 minute in milliseconds
//---------------------------------------------------------------
const REFRESH_THRESHOLD = parseInt(process.env.REACT_APP_REFRESH_THRESHOLD);
const TOKEN_CHECK_INTERVAL = parseInt(
  process.env.REACT_APP_TOKEN_CHECK_INTERVAL
);

export const useBackendTokenCheckExpirationTime = (isInternal) => {
  const interval = useRef(null);
  const { instance, accounts, inProgress } = useMsal();
  const activeTokenClaims = useSelector((state) => state.login.tokenClaims);
  const status = useSelector((state) => state.login.status);
  const isInError = status && status !== 200 ? true : false;
  const dispatch = useDispatch();

  const acquireTokenWithRefreshToken = async () => {
    await new Promise((resolve) => {
      dispatch(
        fetchActiveToken({
          instance,
          accounts,
          isInternal,
          inProgress,
          resolve,
        })
      );
    });
  };

  const checkTokenExpiry = async () => {
    if (activeTokenClaims && !isInError) {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const timeUntilExpiry = activeTokenClaims.exp - currentTime;
      if (timeUntilExpiry <= REFRESH_THRESHOLD) {
        // Token is about to expire or has expired, refresh it
        await acquireTokenWithRefreshToken();
      }
    }
  };

  useEffect(() => {
    //run function every TOKEN_CHECK_INTERVAL
    //(note: we don't run checkTokenExpiry immediatly after mounting but we wait untill the first interval is reached )
    interval.current = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
    return () => clearInterval(interval.current);
  }, [activeTokenClaims, isInError]);
  return null;
};
