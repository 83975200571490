import { Container, TitleForm, ScrollButtons } from "./style";
import { TabsRequest } from "./components/TabsRequest/TabsRequests";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { coreComponentsActions } from "../../../utils/getCoreComponents/coreComponentsSlice";
import CardOnScroll from "./cardOnScroll";
import GoToTop from "../../assets/goToTop";
import ShowFooterBlack from "../../assets/showFooterBlack";

function LayoutBusinessCardManagement() {
  const components = useSelector(
    (state) => state.businessCardManagement.components
  );
  const dispatch = useDispatch();
  const [isOnTop, setIsOnTop] = useState(true);
  const { isFooterFixedOpened } = useSelector((state) => state.coreComponents);

  const approvalTitle = components?.find((v) => v.resourceType === "fi-title")
    ?.data?.jcrTitle;

  return (
    <>
      <TitleForm>{approvalTitle}</TitleForm>
      <Container>
        <TabsRequest />
      </Container>
      <ScrollButtons>
        <div
          style={isOnTop ? { display: "none" } : {}}
          onClick={() => window.scrollTo(0, 0)}
        >
          <GoToTop></GoToTop>
        </div>
        <div
          style={{ transform: isFooterFixedOpened ? "rotate(45deg)" : "none" }}
          onClick={() =>
            dispatch(coreComponentsActions.toggleIsFooterFixedOpened())
          }
        >
          <ShowFooterBlack></ShowFooterBlack>
        </div>
      </ScrollButtons>
      <CardOnScroll isOnTop={isOnTop} setIsOnTop={setIsOnTop}></CardOnScroll>
    </>
  );
}

export default LayoutBusinessCardManagement;
