import { createSlice } from "@reduxjs/toolkit";

export const FAQ_HOME_SCOPE = "FAQHome";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
};

const FAQHomeSlice = createSlice({
  name: FAQ_HOME_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    fetchComponents: () => {},
  },
});

export const { setComponents, setError, setStatusNull, fetchComponents } =
  FAQHomeSlice.actions;
export default FAQHomeSlice;
