import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
// style
import {
  Wrapper,
  CardCTA,
  Title,
  SpanCtaIcon,
  CtaCircle,
  EventDescrWrapper,
  EventDate,
  EvenMonth,
  EventDateWrapper,
  EventInfo,
  EventTitle,
  EventSubtitle,
  CtaWrapper,
  Ewrapper,
} from "./style";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";

function EventCard({ data }) {
  const [loaded, setLoaded] = useState(0);
  const loader = useRef(null);

  const eventsFiltered = data?.events?.filter(
    (el) => !el?.path?.includes("premio-di-competitivita")
  );

  const isIntervalDatePresent = eventsFiltered
    ?.slice(0, 3)
    .find((el) => el?.dateDay?.length > 2)
    ? true
    : false;

  return (
    <Wrapper ref={loader} style={{ opacity: loaded, transition: "1s opacity" }}>
      <ShowOnIntersection
        loader={loader}
        setLoaded={setLoaded}
        threshold={0}
      ></ShowOnIntersection>
      <CardCTA>
        <Ewrapper>
          <Title>
            <span>⏤</span>
            <span>{data?.category}</span>
          </Title>
          {React.Children.toArray(
            eventsFiltered?.slice(0, 3).map((event) => {
              const linkToEventDetail = `/event-details?path=${event?.path}`;
              return (
                <EventDescrWrapper>
                  <EventDateWrapper>
                    <EventDate isIntervalDate={isIntervalDatePresent}>
                      {event?.dateDay}
                    </EventDate>
                    <EvenMonth>{event?.dateMonth}</EvenMonth>
                  </EventDateWrapper>
                  <EventInfo>
                    <a href={linkToEventDetail}>
                      <EventTitle>{event?.title}</EventTitle>
                    </a>
                    <EventSubtitle>{event?.subtitle}</EventSubtitle>
                  </EventInfo>
                </EventDescrWrapper>
              );
            })
          )}
        </Ewrapper>
        <CtaWrapper>
          <Link to={data?.linkHref || "#"} aria-label={data?.category}>
            <span>{data?.textHref}</span>

            <SpanCtaIcon>
              <CtaCircle>
                <svg viewBox="0 0 24 24">
                  <circle cx={12} cy={12} r={11} stroke={1} />
                </svg>
              </CtaCircle>

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 16L11.2677 9.54697C11.7148 9.18016 12 8.62337 12 8C12 7.37663 11.7148 6.81984 11.2677 6.45304L4 0L8 8L4 16Z"
                  fill="var(--cta-color)"
                />
              </svg>
            </SpanCtaIcon>
          </Link>
        </CtaWrapper>
      </CardCTA>
    </Wrapper>
  );
}

export default React.memo(EventCard);
