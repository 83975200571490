import styled from "styled-components";

export const Wrapper = styled.h1`
  margin: 96px auto 64px;
  position: relative;
  text-align: center;
  font-size: 36px;
  line-height: 43px;

  @media screen and (max-width: 1279px) {
    margin-left: auto;
    margin-right: auto;
  }
`;
