import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 384px;
  a {
    color: rgb(255, 255, 255);
    display: block;
  }
  text-transform: uppercase;
  font-size: 12px;
  padding: 20px 0px;
  width: 100%;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
`;
