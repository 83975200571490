import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// actions
import {
  fetchCoreComponents,
  setLanguage,
  setHeaderLinks,
} from "./utils/getCoreComponents/actions";
import { coreComponentsActions } from "./utils/getCoreComponents/coreComponentsSlice";
import { fetchStrumentiComponents } from "./pages/Strumenti/actions";
import { fetchMyPageComponents } from "./pages/MyPage/actions";
import { fetchHomeComponents } from "./pages/Home/actions";
import { fetchNotifications } from "./pages/Notifications/actions";
import GenericError from "./pages/GenericErrorPage";
import GetStatusAndErrorData from "./utils/getStatusAndErrorData";
// components
import Header from "./components/UI/header/Header";
import Footer from "./components/UI/footer/Footer";
// routes functions
import { PUBLIC_ROUTES } from "./publicRoutes";
import Home from "./pages/Home";
import Mensa from "./pages/Mensa";
import TakeAway from "./pages/TakeAway";
import TopRatedDishes from "./pages/TopRatedDishes";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import NewsFilterResults from "./pages/NewsFilterResults";
import Events from "./pages/Events";
import Webinar from "./pages/Webinar";
import EventDetails from "./pages/EventDetails";
import Container800 from "./pages/Container800";
import Strumenti from "./pages/Strumenti";
import MyPage from "./pages/MyPage";
import ThankYou from "./pages/ThankYou/Index";
import TwoThirdTemplate from "./pages/TwoThirdTemplate";
import Page404 from "./pages/Page404";
import PageLoader from "./components/UI/pageLoader/PageLoader";
import Login from "./pages/Login";
import Logout from "./components/logout/Logout";
import ContactUs from "./pages/ContactUs";
import FAQHome from "./pages/FAQ-Home";
import FAQDetail from "./pages/FAQ-Detail";
import Rubrica from "./pages/Rubrica";
import Welfare from "./pages/Welfare";
import CompanyCom from "./pages/CompanyCom";
import SiteMap from "./pages/SiteMap";
import OfferDetail from "./pages/OfferDetail";
import CompanyMap from "./pages/CompanyMap";
import Notifications from "./pages/Notifications";
import GenericTemplate from "./pages/GenericTemplate";
import FormIscrizioneFamilyDay from "./pages/FormIscrizioneFamilyDay";
import RecapAndDetailFamilyDay from "./pages/RecapAndDetailFamilyDay";
// MSAL imports
import { loginRequest, loginRequestInternal } from "./authConfig";
import { fetchActiveToken } from "./pages/Login/actions";
import { InteractionStatus } from "@azure/msal-browser";
import { useBackendTokenCheckExpirationTime } from "./hooks/useBackedTokenCheckExpirationTime";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import AdsList from "./pages/AdsList";
import FormAnnuncio from "./pages/FormAnnuncio";
import ProgrammaCoinvolgimento from "./pages/ProgrammiCoinvolgimento";
import AccessReduced from "./pages/AccessReduced";
import FormRichiestaEcard from "./pages/FormRichiestaEcard";
import BusinessCardManagement from "./pages/BusinessCardManagement";
import FormIscrizioneMondiali from "./pages/FormIscrizioneMondiali";
import RecapAndDetailMondiali from "./pages/RecapAndDetailMondiali";
import SimpleEventsSubscribeForm from "./pages/SimpleEventsSubscribeForm";
import SimpleEventDetail from "./pages/SimpleEventsDetail";
import FormIscrizioneGiftHouse from "./pages/FormIscrizioneGiftHouseLe";
import RecapAndDetailGiftHouseLe from "./pages/RecapAndDetailGiftHouseLe";
import FormTestHmi from "./pages/FormTestHmi";
import FullWidth from "./pages/FullWidth";
import Map3d from "./pages/Map3d";
import Map3dPublic from "./pages/Map3dPublic";
import FormContraffazione from "./pages/FormContraffazioni";

export const COMPONENTS_MAPPING = {
  mensa: Mensa,
  news: News,
  "favourite-dishes": TopRatedDishes,
  "take-away": TakeAway,
  "webinars-homepage": Webinar,
  "events-homepage": Events,
  "collective-agreement": Container800,
  tools: Strumenti,
  "development-and-training": Container800,
  "development-and-training-two-columns": TwoThirdTemplate,
  "my-page": MyPage,
  "thank-you-page": ThankYou,
  "cafeteria-rule": TwoThirdTemplate,
  "ecard-approval": BusinessCardManagement,
  faq: FAQHome,
  "faq-detail": FAQDetail,
  "contact-us": ContactUs,
  "address-book": Rubrica,
  "request-ecard-form": FormRichiestaEcard,
  "home-page": Home,
  "generic-template-two-columns": TwoThirdTemplate,
  "welfare-detail-page": TwoThirdTemplate,
  "welfare-home-page": Welfare,
  "generic-template-width-800": Container800,
  "generic-template": GenericTemplate,
  "company-map": CompanyMap,
  "ads-homepage": AdsList,
  "my-ads": AdsList,
  "corporate-communications": CompanyCom,
  "notifications-page": Notifications,
  "ad-detail": OfferDetail,
  "ferrari-intranet-engagement-programs": ProgrammaCoinvolgimento,
  "adesioni-hmi": FormTestHmi,
  "full-width": FullWidth,
  "3d-map": Map3d,
  "anticounterfeiting-form": FormContraffazione,
};

function App({ pca, lang, isInternal }) {
  const dispatch = useDispatch();
  const { components } = useSelector((state) => state.coreComponents);
  const coreStatus = useSelector((state) => state.coreComponents.status);
  const coreIsInError = coreStatus && coreStatus !== 200 ? true : false;
  const coreLang =
    useSelector((state) => state.coreComponents.language) || lang;

  const token = useSelector((state) => state.login.activeToken);
  const loginStatus = useSelector((state) => state.login.status);
  const loginIsInError = loginStatus && loginStatus !== 200 ? true : false;

  const myPageStatus = useSelector((state) => state.myPage.status);
  const toolsStatus = useSelector((state) => state.tools.status);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const setFlatArray = useCallback(
    (acc, arr) =>
      arr.forEach((v) => {
        acc.push(v);
        v.children && setFlatArray(acc, v.children);
      }),
    []
  );

  let headerLinks = useMemo(
    () =>
      components?.header?.reduce((acc, v) => {
        acc.push(v);
        v.children && setFlatArray(acc, v.children);
        return acc;
      }, []) || [],
    [components, setFlatArray]
  );

  const strumentiPath = useMemo(
    () => headerLinks?.find((v) => v.template === "tools")?.path,
    [headerLinks]
  );
  const myPagePath = useMemo(
    () => headerLinks?.find((v) => v.template === "my-page")?.path,
    [headerLinks]
  );
  const homePath = useMemo(
    () => headerLinks?.find((v) => v.template === "home-page")?.path,
    [headerLinks]
  );

  const handleLogin = async (loginType) => {
    if (loginType === "popup") {
      pca.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      if (
        !coreIsInError &&
        inProgress === InteractionStatus.None &&
        accounts.length === 0
      ) {
        console.log("handleLogin: redirect to iam");
        pca
          .loginRedirect(isInternal ? loginRequestInternal : loginRequest)
          .then((response) => {
            // handle response
          })
          .catch((err) => {
            // handle error
            console.log("loginRedirect error: ", err);
            dispatch(
              coreComponentsActions.setError({
                status: err.errorCode,
                errorMessage: err.errorMessage,
              })
            );
          });
      }
    }
  };

  // //not working on production environment
  // const db = firebase.database().ref("events").limitToLast(1);
  // useMemo(() => {
  //   db.on("value", (snapshot) => {
  //     const event = snapshot.val(); // Do something with the events data
  //     const eventValues = Object.values(event);
  //     if (eventValues[0].lang === coreLang) {
  //       token &&
  //         dispatch(fetchNotifications({ path: `/${coreLang}/notify`, token }));
  //     }
  //   });
  // }, [token]);

  useMemo(() => {
    token &&
      dispatch(fetchNotifications({ path: `/${coreLang}/notify`, token }));
  }, [token]);

  useMemo(() => {
    myPagePath &&
      token &&
      dispatch(fetchMyPageComponents({ path: myPagePath, token }));
  }, [myPagePath, token]);

  useMemo(() => {
    strumentiPath &&
      token &&
      dispatch(fetchStrumentiComponents({ path: strumentiPath, token }));
  }, [strumentiPath]);

  useMemo(() => {
    homePath &&
      token &&
      dispatch(fetchHomeComponents({ path: homePath, token }));
  }, [homePath]);

  useBackendTokenCheckExpirationTime(isInternal);

  const isPublicPage = PUBLIC_ROUTES.includes(window.location.pathname);

  useEffect(() => {
    setFirstRender(false);

    if (inProgress === InteractionStatus.None) {
      if (!isPublicPage && !isAuthenticated) {
        handleLogin("redirect");
      }
      if (isAuthenticated && !loginIsInError && !coreIsInError) {
        if (!token) {
          dispatch(
            fetchActiveToken({ instance, accounts, isInternal, inProgress })
          );
        } else if (Object.keys(components).length === 0) {
          dispatch(setLanguage(coreLang));
          dispatch(fetchCoreComponents(token));
        } else {
          dispatch(setHeaderLinks(headerLinks));
        }
      }
    }
  }, [
    dispatch,
    instance,
    inProgress,
    accounts,
    token,
    coreLang,
    components,
    headerLinks,
    isAuthenticated,
  ]);

  if (firstRender) {
    return null;
  }

  return (
    <>
      <AuthenticatedTemplate>
        {!coreStatus && <PageLoader></PageLoader>}
        {/* brutto ma costretto a gestirla così!!
        Se l'utente ha accesso ridotto sicuramente le chiamate myPage e tools falliscono,
        non ha senso controllarle e bisogna poter renderizzare la pagina reduced Access*/}
        {coreStatus === 200 && components?.header ? (
          //Un altro page loader fintanto che non ho completato le chiamate alla myPage e tools,
          //l'attesa delle chiamate evita problemi sia di render sia legati a GA4
          myPageStatus === 200 && toolsStatus === 200 ? (
            <>
              <Header />
              <Routes>
                <Route
                  path="/"
                  element={<Navigate replace to={`/${coreLang}`} />}
                />
                <Route path="/login-callback" element={<Login />} />
                {/* DA DEPUBBLICARE
                 <Route
                  path="/mappa"
                  element={<Map3dPublic path={"/mappa"} />}
                /> */}
                <Route path="event-details" element={<EventDetails />} />
                <Route path="news/news-detail" element={<NewsDetail />} />
                <Route
                  path="subscribe-form-family-day"
                  element={<FormIscrizioneFamilyDay />}
                />
                <Route
                  path="subscription-recap-family-day"
                  element={<RecapAndDetailFamilyDay />}
                />
                <Route
                  path="subscription-detail-family-day"
                  element={<RecapAndDetailFamilyDay />}
                />
                <Route
                  path="subscribe-form"
                  element={<FormIscrizioneMondiali />}
                />
                <Route
                  path="subscription-recap"
                  element={<RecapAndDetailMondiali />}
                />
                <Route
                  path="subscription-detail"
                  element={<RecapAndDetailMondiali />}
                />
                <Route
                  path="generic-subscription-form"
                  element={<SimpleEventsSubscribeForm />}
                />
                <Route
                  path="generic-subscription-detail"
                  element={<SimpleEventDetail />}
                />
                <Route
                  path="subscribe-form-gh"
                  element={<FormIscrizioneGiftHouse />}
                />
                <Route
                  path="subscription-recap-gh"
                  element={<RecapAndDetailGiftHouseLe />}
                />
                <Route
                  path="subscription-detail-gh"
                  element={<RecapAndDetailGiftHouseLe />}
                />
                <Route path="site-map" element={<SiteMap />} />
                <Route
                  path="news-filter-results"
                  element={<NewsFilterResults />}
                />
                <Route path="creazione-annuncio" element={<FormAnnuncio />} />
                {!isInternal && (
                  <Route
                    path="logout"
                    element={<Logout pca={pca} inProgress={inProgress} />}
                  />
                )}
                {headerLinks.map((v, i, a) => {
                  const ReactGenericComponent = COMPONENTS_MAPPING[v.template];
                  return (
                    <React.Fragment key={i}>
                      {ReactGenericComponent && (
                        <Route
                          path={v.path}
                          element={
                            <ReactGenericComponent
                              path={v.path}
                              type={v.template}
                            />
                          }
                        />
                      )}
                      {a.length - 1 === i && (
                        <Route path="*" element={<Page404 />} />
                      )}
                    </React.Fragment>
                  );
                })}
              </Routes>
              <Footer />
            </>
          ) : (
            <PageLoader></PageLoader>
          )
        ) : (
          <Routes>
            <Route path="/login-callback" element={<Login />} />
            <Route
              path="/reduced-access"
              element={<AccessReduced lang={coreLang} />}
            />
            {!isInternal && (
              <Route
                path="logout"
                element={<Logout pca={pca} inProgress={inProgress} />}
              />
            )}
          </Routes>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* MAPPA DA DEPUBBLICARE
        <Routes>
          <Route path="/mappa" element={<Map3dPublic path={"/mappa"} />} />
        </Routes> */}
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
