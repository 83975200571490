import styled from "styled-components";

export const Card = styled.div`
  h3 {
    font-family: ferrari-sans-medium;
    text-transform: uppercase;
  }
  @media (min-width: 1280px) {
    max-width: 384px;
  }
`;

export const Description = styled.div`
  margin: 24px 0px 16px;
  color: rgb(230, 72, 61);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  span:nth-child(2) {
    height: 0px;
    border-bottom: 1px solid rgb(230, 72, 61);
    width: 96px;
    margin: 0px 8px;
  }
`;
