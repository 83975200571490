//hooks
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchComponents } from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";

// components
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import HtmlBox from "../../components/UI/htmlBox/htmlBox";
import LayoutMap3d from "../../components/UI/map3dPublic/LayoutMap3dPublic";

// utils
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { ChangeTitlePublicDoc } from "../../utils/changeTitleDoc";

function Map3dPublic({ path }) {
  const dispatch = useDispatch();
  const mapComponents = useSelector((state) => state.map3dPublic.components);
  const settings = useSelector((state) => state.map3dPublic.settings);
  const title = settings?.jcrTitle;
  useMemo(() => {
    path && dispatch(fetchComponents({ path }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setHideNavAndFooter(true));
  }, [dispatch]);

  const { status, errorData } = GetStatusAndErrorData("map3dPublic");

  const componentsMapping = {
    "fi-html-box": HtmlBox,
  };

  ChangeTitlePublicDoc(title, path);
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} isPublicPage={true} />
      ) : (
        mapComponents?.components && (
          <LayoutMap3d>
            {mapComponents?.components?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutMap3d>
        )
      )}
    </>
  );
}

export default Map3dPublic;
