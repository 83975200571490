import {
  MealInput,
  MealTitle,
  MealsSection,
  RemaingSelection,
  SelectWrapper,
  Select,
  WrapperImg,
} from "./style";
import { ComponentContainer, TextDescr } from "../../style";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomIcon from "../../../../assets/bottom";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import {
  setRemainingMenus,
  setMealQuantity,
} from "../../../../../pages/FormIscrizioneFamilyDay/FormIscrizioneFamilyDaySlice";

export default function MealMenus({ data, allowed, calcolateMenuQuantity }) {
  const dispatch = useDispatch();

  const remainingMenus = useSelector(
    (state) => state.FormIscrizioneFamilyDay.remainingMenus
  );
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneFamilyDay.bodyPayload
  );

  const theme = useSelector((state) => state.coreComponents.pageLight);
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const aemMenuTitles = [
    data?.standardMealLbl,
    data?.vegMealLbl,
    data?.glutenFreeMealLbl,
  ];

  function RemainingSelection(e) {
    const selectedMenus = calcolateMenuQuantity();
    const result = bodyPayload?.numGuests + 1 - selectedMenus;
    const intValue = parseInt(e.target.value);
    e.target.value = intValue;
    if (result >= 0) {
      dispatch(setRemainingMenus(result));
      dispatch(
        setMealQuantity({
          id: parseInt(e.target.id),
          value: intValue,
        })
      );
    }
  }

  function SelectInputValue(e) {
    e.target.select();
  }

  const isMealsError = remainingMenus > 0 && allowed === false;
  useEffect(() => {
    const disabledBorderColor =
      theme !== "dark"
        ? "1px solid #D4D2D2"
        : "1px solid var(--alternative-grey-color)";
    bodyPayload?.mealMenus?.forEach((menu) => {
      const menuElement = document.querySelector(`.menu${menu?.mealMenuId}`);
      const checkMenuQuantity = !menu?.quantity && !remainingMenus;

      menuElement.parentElement.lastChild.style.border = checkMenuQuantity
        ? disabledBorderColor
        : " 1px solid var(--main-color)";
    });
  }, [remainingMenus, bodyPayload, isDesktop]);

  return (
    <>
      <ComponentContainer>
        <h3>{data?.lunchLbl}</h3>
        <TextDescr>{data?.lunchTxt}</TextDescr>
        <MealsSection>
          {bodyPayload?.mealMenus?.map((meal, i) => (
            <div key={meal?.mealMenuId}>
              <MealTitle
                id={meal?.name}
                className={
                  !meal?.quantity && !remainingMenus ? "disabled" : "enabled"
                }
              >
                {aemMenuTitles[i]}
              </MealTitle>
              {isDesktop ? (
                <MealInput
                  name={aemMenuTitles[i]}
                  description={meal?.description}
                  type="number"
                  className={`menu${meal?.mealMenuId}`}
                  disabled={!meal?.quantity && !remainingMenus}
                  id={meal?.mealMenuId}
                  value={meal?.quantity}
                  min="0"
                  max={meal?.quantity + remainingMenus}
                  onChange={(e) => RemainingSelection(e)}
                  onFocus={(e) => SelectInputValue(e)}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              ) : (
                <SelectWrapper className={`menu${meal?.mealMenuId}`}>
                  <Select
                    id={meal?.mealMenuId}
                    disabled={!meal?.quantity && !remainingMenus}
                    onChange={(e) => RemainingSelection(e)}
                    value={meal?.quantity}
                  >
                    {[
                      ...Array(meal?.quantity + remainingMenus + 1).keys(),
                    ]?.map((guest) => (
                      <option
                        key={guest}
                        name={aemMenuTitles[i]}
                        description={meal?.description}
                        value={guest}
                      >
                        {guest}
                      </option>
                    ))}
                  </Select>
                  <WrapperImg>
                    <BottomIcon></BottomIcon>
                  </WrapperImg>
                </SelectWrapper>
              )}
            </div>
          ))}
          <RemaingSelection
            color={isMealsError ? "true" : "false"}
            id={isMealsError ? "required" : ""}
          >
            {remainingMenus === -1 ? remainingMenus + 1 : remainingMenus}{" "}
            {data?.mealsRemainingLbl}
          </RemaingSelection>
        </MealsSection>
      </ComponentContainer>
    </>
  );
}
