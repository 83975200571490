import React, { useState } from "react";
import BuildingModal from "../buildingModal/BuildingModal";
import { companyMapActions } from "../../../../pages/CompanyMap/containerSlice";
import { useSelector } from "react-redux";
import {
  Wrapper,
  MapImg,
  BuildingsContainer,
  SetColumn,
  BulletPoint,
  BulletPointNumber,
  BulletPointText,
} from "./style";
import { useDispatch } from "react-redux";
function BuildingList({ data }) {
  const dispatch = useDispatch();

  const showModal = useSelector((state) => state.companyMap.showModal);
  const [modalData, setModalData] = useState();

  const HandleshowModal = (el) => {
    dispatch(companyMapActions.setShowModal(true));
    setModalData(el?.modal);
    return;
  };

  return (
    <>
      <Wrapper>
        <MapImg>
          <img src={data.fileReference} />
        </MapImg>
        <BuildingsContainer>
          <SetColumn>
            {React.Children.toArray(
              data?.items.map((el) => (
                <BulletPoint onClick={() => HandleshowModal(el)}>
                  <BulletPointNumber>{el.id}</BulletPointNumber>
                  <BulletPointText>{el.title}</BulletPointText>
                </BulletPoint>
              ))
            )}
          </SetColumn>
        </BuildingsContainer>
      </Wrapper>
      {showModal && <BuildingModal data={modalData} />}
    </>
  );
}
export default BuildingList;
