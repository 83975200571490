import { Wrapper, ScrollLoader } from "./style";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import WelfareCard from "./welfareCard/WelfareCard";

const WELFARE_COMPONENT_NAME = "welfare-card";

function WelfareCardList() {
  const results = useSelector((state) => state.welfare.welfareCardList);
  const resultsLength = results.length;
  const howManyMore = 3;
  const [slice, setSlice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const loader = useRef(null);
  let loaded = 0;

  const isLoadingFunc = () => {
    loaded++;
    if (loaded === 3) {
      setIsLoading(false);
      loaded = 0;
    }
  };

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setSlice((prev) => prev + howManyMore);
        setIsLoading(true);
      }
    },
    [howManyMore]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "60px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, results]);

  return (
    <>
      <Wrapper>
        {results.slice(0, slice).map((currentCard, idx) => (
          <WelfareCard
            key={`${WELFARE_COMPONENT_NAME}-${idx}`}
            data={currentCard}
            isLoadingFunc={isLoadingFunc}
          />
        ))}
      </Wrapper>
      {slice < resultsLength && (
        <ScrollLoader
          ref={loader}
          style={isLoading ? { display: "none" } : {}}
        />
      )}
    </>
  );
}

export default WelfareCardList;
