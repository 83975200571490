import { DateCategory } from "./style";
import { useSelector } from "react-redux";
import { selectNewsDetailSettings } from "../../../../../pages/NewsDetail/selectors";

function DateCategoryInfo() {
  const newsDetailsettings = useSelector(selectNewsDetailSettings());
  const lang = useSelector((state) => state.coreComponents.language);
  const today = Date.now();
  const thisYear = new Date(today).getFullYear();
  const newsYear = new Date(newsDetailsettings.newsDate).getFullYear();
  const locale = lang === "it" ? "it-IT" : "en-EN";
  const date = new Date(newsDetailsettings.newsDate);
  const options =
    thisYear !== newsYear
      ? { month: "short", day: "numeric", year: "numeric" }
      : { month: "short", day: "numeric" };
  const newsDate = date.toLocaleDateString(locale, options);

  return (
    <DateCategory>
      <span>{newsDate}</span>
      <span />
      <span>{newsDetailsettings?.category}</span>
    </DateCategory>
  );
}

export default DateCategoryInfo;
