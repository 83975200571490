import { Wrapper, SpanCategory, SpanTag, SpanPrice, Text } from "./style";
function CategoryTags({ data, properties }) {
  const categoryList = [
    ...properties.categories,
    ...properties.categoriesObject,
    ...properties.types,
  ];

  const offerType = categoryList.find((el) => el.key === data?.offerType);
  const category = categoryList.find((el) => el.key === data?.category);
  function html() {
    return { __html: data?.description };
  }
  return (
    <>
      <Wrapper>
        <SpanCategory>{offerType?.label}</SpanCategory>
        <SpanTag>{category?.label}</SpanTag>
        <SpanPrice>
          <strong>€ {data?.price}</strong>
        </SpanPrice>
      </Wrapper>
      <h3>{data.title}</h3>
      <Text data-cs-mask dangerouslySetInnerHTML={html()}></Text>
    </>
  );
}

export default CategoryTags;
