import i18n from "i18next";
export const SetInitLang = () => {
  let lang;
  switch (i18n.language) {
    case "it":
    case "en":
      lang = i18n.language;
      break;
    default:
      lang = "it";
      break;
  }
  return lang;
};
