import { useCallback, useEffect } from "react";

export default function ShowOnIntersection({
  loader,
  setLoaded,
  threshold = 1.0,
}) {
  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setLoaded("1");
      }
    },
    [setLoaded]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver, loader, threshold]);

  return null;
}
