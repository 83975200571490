import PropTypes from "prop-types";
// components
import AsideBox from "./asideBox/AsideBox";

function RightColumnContainer({ rightColumnComponents }) {
  const componentsMapping = {
    "fi-events/fi-event-info/fi-event-info-recap": AsideBox,
  };

  return (
    <>
      {rightColumnComponents?.map((element, idx) => {
        const ReactGenericComponent = componentsMapping[element.resourceType];
        return (
          ReactGenericComponent && (
            <ReactGenericComponent
              key={`${element.resourceType}-${idx}`}
              elementData={element.data}
            />
          )
        );
      })}
    </>
  );
}

RightColumnContainer.propTypes = {
  rightColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default RightColumnContainer;
