import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { selectCatalogNoAds } from "../../../../../pages/AdsList/selectors";
import { ActionLink } from "../style";
import { NoAdsWrapper, CardImage, Description } from "./style";

function NoAds() {
  const noAdsConfig = useSelector(selectCatalogNoAds());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  if (!noAdsConfig) return null;
  const { description, linkHref, noAdsButtonLabel } = noAdsConfig;
  const myAdsPath = headerLinks?.find((v) => v.template === "my-ads")?.path;
  const linkToForm = `/creazione-annuncio?&path=${myAdsPath}`;
  return (
    <NoAdsWrapper>
      <CardImage>
        <img className="img-card" src="/img/placeholder-card-image.svg"></img>
        <img className="img-plus" src="/img/card-plus-icon.svg"></img>
      </CardImage>
      <Description>{description}</Description>
      <ActionLink to={linkToForm}>{noAdsButtonLabel}</ActionLink>
    </NoAdsWrapper>
  );
}

NoAds.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
};
export default NoAds;
