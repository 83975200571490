import { Container } from "./style";

function Layout(props) {
  return (
    <>
      <Container>{props.children}</Container>
    </>
  );
}

export default Layout;
