import styled from "styled-components";

export const Container3dMap = styled.div`
  div {
    flex-grow: 1;
    position: relative;
    width: 100%;
    height: 100vh;
    height: 100svh;
  }
  iframe {
    height: 100vh;
    height: 100svh;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
  }
`;
