import React from "react";
import PropTypes from "prop-types";
// style
import {
  DetailsSection,
  Divider,
  Wrapper,
  Fragment,
  WrapperNoBusinessCard,
} from "./style";
// components
import QrCode from "./qrCode/QrCode";
import DetailInfo from "./detailInfo/DetailInfo";
import { selectMyPageComponent } from "../../../../pages/MyPage/selectors";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function BusinessCard({ components: profileComponents }) {
  const myPageComponent = useSelector(selectMyPageComponent());
  const checkQrCode = myPageComponent?.ecard?.qrCode;

  return checkQrCode ? (
    <Fragment>
      <Wrapper>
        <DetailsSection>
          <QrCode data={profileComponents} />
        </DetailsSection>
        <Divider />
        <DetailsSection>
          <DetailInfo data={profileComponents} />
        </DetailsSection>
      </Wrapper>
      <Link to={profileComponents?.linkHref}>
        {profileComponents?.modifyEcardBtnLbl}
      </Link>
    </Fragment>
  ) : (
    <Fragment>
      <WrapperNoBusinessCard>
        <p>{profileComponents?.newEcardTxt}</p>
      </WrapperNoBusinessCard>
      <Link qrCode={false} to={profileComponents?.linkHref}>
        {profileComponents?.newEcardBtnLbl}
      </Link>
    </Fragment>
  );
}

BusinessCard.propTypes = {
  QrCode: PropTypes.object,
  DetailInfo: PropTypes.object,
};

export default React.memo(BusinessCard);
