import { FETCH_COMPONENTS, SAVE_ACTION } from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const saveHrManagerAction = (payload) => ({
  type: SAVE_ACTION,
  payload,
});
