export default function TeamsIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40.0391C31.0457 40.0391 40 31.0848 40 20.0391C40 8.99337 31.0457 0.0390625 20 0.0390625C8.9543 0.0390625 0 8.99337 0 20.0391C0 31.0848 8.9543 40.0391 20 40.0391ZM20 38.0391C29.9411 38.0391 38 29.9802 38 20.0391C38 10.0979 29.9411 2.03906 20 2.03906C10.0589 2.03906 2 10.0979 2 20.0391C2 29.9802 10.0589 38.0391 20 38.0391Z"
        fill="#C4C4C4"
      />
      <path
        d="M21.042 15.5605H12.1045C11.7248 15.5605 11.417 15.8684 11.417 16.248V24.498C11.417 24.8777 11.7248 25.1855 12.1045 25.1855H21.042C21.4217 25.1855 21.7295 24.8777 21.7295 24.498V16.248C21.7295 15.8684 21.4217 15.5605 21.042 15.5605Z"
        stroke="var(--cta-color)"
        strokeWidth="1.54687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7291 16.9355H24.4791C24.6614 16.9355 24.8363 17.008 24.9652 17.1369C25.0942 17.2658 25.1666 17.4407 25.1666 17.623V23.8105C25.1666 25.0869 24.6596 26.311 23.7571 27.2135C22.8545 28.116 21.6305 28.623 20.3541 28.623V28.623C19.3176 28.6223 18.3089 28.2875 17.4776 27.6683C16.6463 27.0491 16.0367 26.1785 15.7393 25.1855"
        stroke="var(--cta-color)"
        strokeWidth="1.54687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1201 18.3105H15.0264"
        stroke="var(--cta-color)"
        strokeWidth="1.54687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5732 22.4355V18.3105"
        stroke="var(--cta-color)"
        strokeWidth="1.54687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9737 15.5599C17.7014 15.0889 17.575 14.5478 17.6103 14.005C17.6456 13.4622 17.8412 12.942 18.1721 12.5103C18.5031 12.0786 18.9547 11.7548 19.4698 11.5798C19.9848 11.4048 20.5402 11.3865 21.0657 11.5272C21.5912 11.6678 22.0631 11.9612 22.4218 12.3702C22.7806 12.7791 23.0099 13.2852 23.081 13.8246C23.152 14.3639 23.0614 14.9122 22.8208 15.4C22.5802 15.8879 22.2003 16.2934 21.7291 16.5654"
        stroke="var(--cta-color)"
        strokeWidth="1.54687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9406 13.2571C23.2508 12.89 23.6711 12.6327 24.1392 12.5234C24.6072 12.4141 25.098 12.4586 25.5387 12.6504C25.9794 12.8421 26.3465 13.1708 26.5856 13.5878C26.8247 14.0047 26.923 14.4876 26.8658 14.9648C26.8087 15.442 26.5992 15.8881 26.2685 16.2368C25.9377 16.5855 25.5034 16.8183 25.0299 16.9006C24.5563 16.9829 24.0689 16.9103 23.6399 16.6936C23.2109 16.4769 22.8632 16.1277 22.6484 15.6977"
        stroke="var(--cta-color)"
        strokeWidth="1.54687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1664 16.9355H27.5125C27.6196 16.9355 27.7224 16.9781 27.7981 17.0538C27.8739 17.1296 27.9164 17.2323 27.9164 17.3395V22.4355C27.9164 23.1649 27.6267 23.8644 27.1109 24.3801C26.5952 24.8958 25.8958 25.1855 25.1664 25.1855H24.9688"
        stroke="var(--cta-color)"
        strokeWidth="1.54687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
