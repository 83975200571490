import styled from "styled-components";

export const Row = styled.div`
  width: 800px;
  display: flex;
  justify-content: space-around;

  @media (max-width: 1279px) {
    flex-wrap: wrap;
    width: auto;
  }
`;
