//import "./LayoutMensa.css";
import { ImgHeader, Container } from "./style";
import PropTypes from "prop-types";
function LayoutMensa({ children, heroImg }) {
  return (
    <>
      <ImgHeader src={heroImg} />
      <Container>{children}</Container>
    </>
  );
}
LayoutMensa.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  heroImg: PropTypes.string,
};
export default LayoutMensa;
