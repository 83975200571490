import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./homeSlice";

const homeState = (state) => state.home || initialState;

export const selectTitleName = () =>
  createSelector(homeState, (state) => {
    return state.components.filter((el) => el.resourceType === "fi-title")[0]
      ?.data;
  });

export const selectHomeComponents = () =>
  createSelector(homeState, (state) => {
    return state.components.filter((el) => el.resourceType !== "fi-title");
  });

export const selectAvatarImage = () =>
  createSelector(homeState, (state) => {
    const img = state.components.filter((el) => el.resourceType === "fi-title");

    return img[0]?.data?.idPicture || "";
  });
