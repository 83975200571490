import styled from "styled-components";

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: none;
  z-index: 10;
  position: fixed;
  inset: 0px;
  .notification-card {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .notification-card h3 {
    font: 13px/20px ferrari-sans-regular;
    text-transform: initial;
  }
  .notification-card p {
    font-size: 10px;
    line-height: 20px;
  }
`;

export const DialogOverlay = styled.div`
  background-color: var(--alternative-bg-color);
  position: fixed;
  inset: 0px;
  opacity: 0.4;
  transition: opacity 0.3s ease 0s;
`;

export const CloseButton = styled.button`
  @media (min-width: 1280px) {
    position: absolute;
    top: 50%;
    right: calc(700px + 48px); // 700 is the DialogContent box width.
    transform: translate(0px, -50%);
    width: 40px;
    height: 40px;
    box-sizing: content-box;
    z-index: 14;
    transition: transform 0.3s ease 0s;
  }
  @media (max-width: 1279px) {
    position: inherit;
    width: 100%;
    top: 3%;
    z-index: 14;
  }
  border: 0px;
  cursor: pointer;
  img {
    max-width: 100%;
  }
`;
