import styled from "styled-components";

export const List = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const ListItem = styled.li`
  flex: 1;
  white-space: nowrap;
  text-align: center;
  padding: 12px 32px;
  color: var(--alternative-grey-color);
  border-bottom: 2px solid transparent;
  cursor: pointer;
  &:hover,
  &.selected {
    color: rgb(218, 41, 28);
    border-bottom: 2px solid rgb(218, 41, 28);
  }
`;
