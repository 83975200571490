import styled from "styled-components";

export const Card = styled.div`
  width: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: var(--main-color);
  background: var(--alternative-bg-color);
  box-sizing: border-box;
  padding: 24px;
  gap: 24px;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  a {
    color: var(--cta-color);
    overflow: hidden;
    position: relative;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.img`
  height: 220px;
  width: 100%;
  object-fit: cover;
  @media screen and (min-width: 1279px) {
    max-width: 384px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 16px;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardTitle = styled.h4`
  color: var(--main-color);
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const CardTag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 9px;
  min-width: 76px;
  height: 26px;
  padding: 4px 10px;
  border-radius: 30px;
  font-size: 9px;
  line-height: 14px;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: var(--cta-color);
  background: #efeded;

  &.category {
    color: #303030;
  }
  &.primary {
    border: 1px solid var(--menu-cta-color);
    background: var(--alternative-bg-color);
  }
`;

export const CardPrice = styled.div`
  font-size: 13px;
  line-height: 24px;
`;

export const CardTags = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CardFooter = styled.div`
  color: var(--carousel-dot-color);
  line-height: 20px;
  font-size: 11px;
`;

export const CardButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  > button {
    box-sizing: border-box;
    height: 56px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @media (max-width: 1279px) {
    > button {
      border: 0;
      justify-content: start;
    }
  }
`;

export const ActionLink = styled.button`
  @media (max-width: 1279px) {
    display: flex;
    gap: 10px;
  }
  color: var(--cta-color);
  @media (min-width: 1280px) {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    color: var(--cta-color);
    border: 1px solid var(--carousel-dot-color);
    img {
      display: none;
    }
  }
`;

export const Descr = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;
