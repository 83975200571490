import React, { useState } from "react";
import { Wrapper, Title, TitleText, TitleTrigger, Text } from "./style";

function Accordion({ title, text, id = null }) {
  const [openAccordion, setOpenAccordion] = useState(false);
  function html() {
    return { __html: text };
  }
  return (
    <Wrapper className="accordion" id={id}>
      <Title onClick={() => setOpenAccordion(!openAccordion)}>
        <TitleText>{title}</TitleText>
        <TitleTrigger className={openAccordion}>
          <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0625 12.4375L4.5 12C4.224 12 4 11.776 4 11.5C4 11.224 4.224 11 4.5 11L11.0625 10.5625L11.5 4C11.5 3.724 11.724 3.5 12 3.5C12.276 3.5 12.5 3.724 12.5 4L12.9375 10.5625L19.5 11C19.776 11 20 11.224 20 11.5C20 11.776 19.776 12 19.5 12L12.9375 12.4375L12.5 19C12.5 19.276 12.276 19.5 12 19.5C11.724 19.5 11.5 19.276 11.5 19L11.0625 12.4375V12.4375Z"
              fill="var(--main-color)"
            />
          </svg>
        </TitleTrigger>
      </Title>
      {openAccordion && <Text dangerouslySetInnerHTML={html()}></Text>}
    </Wrapper>
  );
}

export default Accordion;
