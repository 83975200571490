import { Divider } from "./style";

function EventDivider({ elementData }) {
  return (
    <Divider>
    </Divider>
  );
}

export default EventDivider;
