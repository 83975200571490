import styled from "styled-components";

export const ContainerRequest = styled.div`
  width: 800px;
  @media (max-width: 1279px) {
    width: 90%;
    margin: auto;
  }
`;

export const ContainerRequestForm = styled.form`
  margin-top: 25px;
  background: var(--alternative-bg-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 32px;
  gap: 28px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  @media (max-width: 1279px) {
    gap: 20px;
  }
`;

export const ContainerRequestSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 33px;
  ${(props) => (props.max ? "width: 100%" : "width: 80%")};
  &:first-child {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1279px) {
    padding: 10px 0 0 0;
    ${(props) => (props.gap ? "gap: 0px" : "gap: 20px")};
    flex-direction: column;
    align-items: flex-start;
    &:first-child {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
`;

export const NomeUtente = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;

  h3 {
    text-transform: capitalize;
    font-size: 18px, line-height 26px;
    font-family: ferrari-sans-medium;
    font-weight: 400;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-left: auto;
  svg {
    opacity: 1;
    cursor: pointer;
  }
  .inactive {
    opacity: 0.4;
    cursor: initial;
  }
`;

export const SectionForm = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 4px;
  ${(props) =>
    props.short ? "min-width: 200px; width: 200px;" : "width: 100%"};
  position: relative;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0px 0px 0px;
  gap: 7px;
  position: relative;
  input {
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 5px 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    caret-color: var(--main-color);
    &::placeholder {
      color: #8f8f8f;
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
  input:-internal-autofill-selected,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--alternative-bg-color) inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: var(--main-color) !important;
  }
  input:not(:placeholder-shown) + label {
    opacity: 1;
    color: #8f8f8f;
  }
  .required {
    border-bottom-color: #da291c;
    border-width: 0px 0px 1px;
    color: #da291c !important;
  }
  .required::placeholder {
    color: #da291c !important;
  }
  label {
    font-size: 11px;
    line-height: 13.2px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }

  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const SectionDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  p {
    font-family: ferrari-sans-regular;
    font-weight: 400;
    line-height: 20px;
    font-size: 10px;
    color: #8f8f8f;
    letter-spacing: 1px;
  }
`;
//svg
export const ButtonFloppy = styled.svg.attrs({
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
`;
export const ButtonFloppyCircle = styled.path.attrs({
  fillRule: "evenodd",
  d: "M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z",
})`
  fill: #d4d2d2;
  pointer-events: none;
`;
export const ButtonFloppyImg = styled.path.attrs({
  d: "M27.233 15.9735L24.613 13.5535C24.4597 13.4135 24.2597 13.3335 24.0463 13.3335H14.133C13.693 13.3335 13.333 13.6668 13.333 14.0735V27.3935C13.333 27.8002 13.693 28.1335 14.133 28.1335H26.6663C27.1063 28.1335 27.4663 27.8002 27.4663 27.3935V16.4935C27.4663 16.3002 27.3797 16.1068 27.233 15.9735ZM21.8997 14.5335V16.6735C21.8997 16.7468 21.8397 16.8068 21.7663 16.8068H19.033C18.9597 16.8068 18.8997 16.7468 18.8997 16.6735V14.5335H21.8997ZM17.693 26.9335V22.2335C17.693 22.1602 17.753 22.1002 17.8263 22.1002H22.9797C23.053 22.1002 23.113 22.1602 23.113 22.2335V26.9335H17.693ZM26.2663 26.9335H24.3063V22.2335C24.3063 21.5002 23.7063 20.9002 22.973 20.9002H17.8197C17.0863 20.9002 16.4863 21.5002 16.4863 22.2335V26.9335H14.533V14.5335H17.6997V16.6735C17.6997 17.4068 18.2997 18.0068 19.033 18.0068H21.7663C22.4997 18.0068 23.0997 17.4068 23.0997 16.6735V14.5335H23.913L26.2663 16.7135V26.9335Z",
})`
  fill: var(--main-color);
  pointer-events: none;
`;

export const ButtonEdit = styled.svg.attrs({
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
`;
export const ButtonEditCircle = styled.path.attrs({
  fillRule: "evenodd",
  d: "M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z",
})`
  fill: #d4d2d2;
  pointer-events: none;
`;
export const ButtonEditImg = styled.path.attrs({
  stroke: "var(--main-color)",
  strokeWidth: "1.5",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  d: "M20.7997 15.4668L24.533 19.2001M20.7997 15.4668L22.933 13.3335L26.6663 17.0668L24.533 19.2001L20.7997 15.4668ZM20.7997 15.4668L13.5539 22.7126C13.4125 22.854 13.333 23.0459 13.333 23.2459V26.6668H16.7539C16.954 26.6668 17.1458 26.5874 17.2873 26.4459L24.533 19.2001L20.7997 15.4668Z",
})`
  fill: var(reverse --main-color);
  pointer-events: none;
`;

export const ButtonAccept = styled.svg.attrs({
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
`;
export const ButtonAcceptCircle = styled.path.attrs({
  fillRule: "evenodd",
  d: "M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z",
})`
  fill: #d4d2d2;
  pointer-events: none;
`;
export const ButtonAcceptImg = styled.path.attrs({
  d: "M18.3161 26C17.8637 26 17.4584 25.8166 17.1706 25.5271L17.169 25.5301L12.0003 20L18.3161 23L28.667 16L19.4632 25.5301L19.4616 25.5271C19.1739 25.8166 18.7685 26 18.3161 26Z",
})`
  fill: var(--main-color);
  pointer-events: none;
`;

export const ButtonReject = styled.svg.attrs({
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
`;
export const ButtonRejectCircle = styled.path.attrs({
  fillRule: "evenodd",
  d: "M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z",
})`
  fill: #d4d2d2;
  pointer-events: none;
`;
export const ButtonRejectImg = styled.path.attrs({
  d: "M20 21.3261L15.0503 25.6571C14.8551 25.8522 14.5383 25.8522 14.3431 25.6571C14.148 25.4619 14.148 25.1451 14.3431 24.95L18.6742 20.0002L14.3431 15.0505C14.148 14.8553 14.148 14.5385 14.3431 14.3434C14.5383 14.1482 14.8551 14.1482 15.0503 14.3434L20 18.6744L24.9497 14.3434C25.1449 14.1482 25.4617 14.1482 25.6569 14.3434C25.852 14.5385 25.852 14.8553 25.6569 15.0505L21.3258 20.0002L25.6569 24.95C25.852 25.1451 25.852 25.4619 25.6569 25.6571C25.4617 25.8522 25.1449 25.8522 24.9497 25.6571L20 21.3261Z",
})`
  fill: var(--main-color);
  pointer-events: none;
`;
