import styled from "styled-components";

export const Container = styled.div`
  ${(props) => (props.detail ? "width: 512px" : "width: 700px")};
  margin: 0 auto 96px;

  @media (max-width: 1279px) {
    ${(props) => (props.detail ? "width: 512px" : "width: 600px")};
  }
  @media (max-width: 601px) {
    width: auto;
  }
`;

export const GenericTitle = styled.div`
  width: 100%;
  margin: 0px auto;
  text-align: center;
  font-size: 28px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 34px;
  padding: 0;

  p {
    font-family: ferrari-sans-regular, sans-serif;
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;

export const RecapContainer = styled.div`
  ${(props) => (props.detail ? "padding: 40px" : "padding: 0 50px")};
  ${(props) =>
    props.detail
      ? "background-color:var(--alternative-bg-color)"
      : "background-color: none"};
`;
export const TitleForm = styled.h3`
  font-size: 18px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 28px;
  margin: 30px 0;
  font-weight: 400;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #efeded;
  width: 100%;
`;
