import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  width: 90%;
  margin-bottom: 48px;
  h2 {
    text-align: left;
    margin: 40px 0px 48px;
  }

  @media screen and (min-width: 1280px) {
    width: 1216px;
    margin: auto;
    margin-bottom: 48px;
    h2 {
      text-align: left;
      margin: 96px 0px 48px;
    }
  }
`;
