import { createSlice } from "@reduxjs/toolkit";

export const FAQ_DETAIL_SCOPE = "FAQDetail";

export const initialState = {
  components: {},
  status: null,
  title: "",
  errorMessage: "",
  topics: [],
};

const FAQDetailSlice = createSlice({
  name: FAQ_DETAIL_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
      state.title = state.components.components.find(
        (el) => el.resourceType === "fi-title"
      )?.data.jcrTitle;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    fetchComponents: () => {},
  },
});

export const { setComponents, setError, setStatusNull, fetchComponents } =
  FAQDetailSlice.actions;
export default FAQDetailSlice;
