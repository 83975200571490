import { createSlice, current } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  language: null,
  initialComponents: null,
  headerLinks: [],
  mobileCurrentSection: "",
  mobileBack: false,
  idRoot: false,
  level: 0,
  openMobileMenu: false,
  isWhiteHeader: false,
  cacheWhiteHeader: false,
  openDesktopMenu: false,
  isFooterFixed: false,
  isFooterFixedOpened: false,
  isHorizontalFooter: false,
  hideNavAndFooter: false,
  selected: null,
  status: null,
  errorMessage: "",
  pageLight:
    window.localStorage.getItem("ferrari.intranet.pageLight") || "light",
};

const coreComponentsSlice = createSlice({
  name: "coreComponents",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      const components = action.payload;
      components.header.forEach((v, i) => {
        v.id = i;
      });
      state.initialComponents = components;
      state.components = components;
      // set page light
      if (state.pageLight === "dark") {
        document.body.classList.add("dark");
      } else document.body.classList.remove("dark");
    },
    setHeaderLinks(state, action) {
      state.headerLinks = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setInitialComponents(state) {
      state.components = state.initialComponents;
      state.mobileBack = false;
      state.openMobileMenu = false;
      state.openDesktopMenu = false;
    },
    setOpenMobileMenu(state, action) {
      state.openMobileMenu = action.payload;
    },
    removeSubMenu(state, action) {
      state.components.header.forEach((v) => {
        if (v.id === action.payload.idRoot) {
          v.submenu = null;
          v.selected = null;
        }
      });
      state.openDesktopMenu = false;
    },
    setSubMenu(state, action) {
      const root = state.components.header.find(
        (v) => v.id === action?.payload?.id
      );
      if (root) {
        if (root.selected) return;
        root.selected = !root.selected;
        state.components.header.forEach((v) => {
          if (v.id !== action.payload.id && v.submenu) v.submenu = null;
          if (v.id !== action.payload.id && v.selected) v.selected = null;
        });
        state.mobileCurrentSection = action.payload.title;
        root.submenu = root.submenu
          ? null
          : [
              {
                level: 0,
                selected: true,
                children: root.children.map((v, i) => {
                  v.id = i;
                  v.idRoot = action.payload.id;
                  v.level = 0;
                  return v;
                }),
              },
              { level: 1 },
              { level: 2 },
            ];
        state.openDesktopMenu = root.submenu ? true : false;
        state.idRoot = action.payload.id;
        state.level = 0;
        state.mobileBack = true;
        state.selected = root;
        if (root.submenu && window.innerWidth >= 1280) {
          state.isWhiteHeader = false;
        }
      } else {
        state.components.header.forEach((v) => {
          v.submenu = null;
          v.selected = null;
        });
        state.openDesktopMenu = false;
        state.isWhiteHeader = state.cacheWhiteHeader;
      }
    },
    toggleHover(state, action) {
      const root = state.components.header.find(
        (v) => v.id === action.payload.item.idRoot
      );
      const levelObj = root.submenu[action.payload.item.level];
      const selected = levelObj.children.find((v) => v.selected);
      levelObj.children.forEach((v) => {
        if (v.id !== action.payload.item.id) {
          v.unselected = selected ? false : action.payload.hStatus;
        } else {
          v.unselected = false;
        }
      });
    },
    removeSubSubMenu(state, action) {
      const root = state.components.header.find(
        (v) => v.id === action.payload.idRoot
      );
      root.submenu[action.payload.level] = {
        level: action.payload.level,
        children: null,
      };
      const levelObj = root.submenu[action.payload.level - 1];
      levelObj.children.forEach((v) => {
        v.selected = null;
      });
    },
    toggleSubSubMenu(state, action) {
      const root = state.components.header.find(
        (v) => v.id === action.payload.idRoot
      );
      const levelObj = root.submenu[action.payload.level];
      levelObj.children.forEach((v) => {
        if (v.id !== action.payload.id) {
          v.selected = null;
        }
      });
      const item = levelObj.children.find((v) => v.id === action.payload.id);
      if (item.selected) return;
      item.selected = !item.selected;
      const level = action.payload.level;
      const nextLevelNumber = level + 1;
      let nextLevel = root.submenu[nextLevelNumber];
      if (item.selected) {
        if (!nextLevel) {
          nextLevel = { level };
          root.submenu.push(nextLevel);
        }
        levelObj.children.forEach((v) => {
          if (v.id !== action.payload.id) {
            v.unselected = true;
          }
        });
      }
      root.submenu.forEach((v, i) => {
        if (i > nextLevelNumber) v.children = null;
      });
      nextLevel.children = item.children?.reduce((acc, v, i) => {
        v.id = i;
        v.idRoot = action.payload.idRoot;
        v.level = nextLevelNumber;
        !v.dontShow && acc.push(v);
        return acc;
      }, []);
      nextLevel.selected = item.selected;
      state.level = nextLevelNumber;
    },
    onMobileBack(state) {
      const root = state.components.header.find((v) => v.id === state.idRoot);
      const submenu = root.submenu;
      submenu[state.level].selected = false;
      if (state.level === 0) {
        state.mobileBack = false;
        root.submenu = false;
      } else state.level -= 1;
    },
    setWhiteHeader(state, action) {
      state.isWhiteHeader = action.payload;
      state.cacheWhiteHeader = state.isWhiteHeader;
    },
    setIsFooterFixed(state, action) {
      state.isFooterFixed = action.payload;
    },
    setIsHorizontalFooter(state, action) {
      state.isHorizontalFooter = action.payload;
    },
    toggleIsFooterFixedOpened(state) {
      state.isFooterFixedOpened = !state.isFooterFixedOpened;
    },
    setHideNavAndFooter(state, action) {
      state.hideNavAndFooter = action.payload;
    },
    setPageLight(state, action) {
      state.pageLight = action.payload;
      window.localStorage.setItem(
        "ferrari.intranet.pageLight",
        state.pageLight
      );
    },
  },
});

export const coreComponentsActions = coreComponentsSlice.actions;
export default {
  name: coreComponentsSlice.name,
  reducer: coreComponentsSlice.reducer,
};
