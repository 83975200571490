import SetQueryStringPath from "../../../../../../utils/setQueryStringPath";
import { useBusinessLogic } from "../../../useBusinessLogic";
import { Wrapper, Overlay, BoxContainer, ConfirmButton } from "./style";

export function DialogNote({ data, id }) {
  const { generateHtml } = useBusinessLogic();

  const path = SetQueryStringPath(data?.linkHref);

  const close = (e) => {
    e.preventDefault();
    const currentPopup = document.getElementById(id);
    currentPopup.setAttribute("style", "display: none");
  };

  let handleOnClick = (e) => {
    close(e);
    if (id !== "popupConiugi") {
      window.location.replace(path);
    }
  };
  return (
    <Wrapper id={id}>
      <Overlay />
      <BoxContainer>
        <h2>{data?.title}</h2>
        <div dangerouslySetInnerHTML={generateHtml(data?.description)} />
        <ConfirmButton onClick={(e) => handleOnClick(e)}>
          {data?.buttonLabel}
        </ConfirmButton>
      </BoxContainer>
    </Wrapper>
  );
}
