import styled from "styled-components";

export const NewsTitleH2 = styled.div`
  font-family: ferrari-sans-medium, sans-serif;
  font-size: 28px;
  text-align: center;
  @media screen and (max-width: 1279px) {
    font-size: 16px;
    text-align: left;
  }
`;
