import { Container, NewsButtons } from "./style";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { coreComponentsActions } from "../../../utils/getCoreComponents/coreComponentsSlice";
import NewsOnScroll from "./newsOnScroll";
import GoToTop from "../../assets/goToTop";
import ShowFooterBlack from "../../assets/showFooterBlack";

function LayoutNews(props) {
  const [isOnTop, setIsOnTop] = useState(true);
  const { isFooterFixedOpened } = useSelector((state) => state.coreComponents);
  const dispatch = useDispatch();
  return (
    <>
      <Container>{props.children}</Container>
      <NewsButtons>
        <div
          style={isOnTop ? { display: "none" } : {}}
          onClick={() => window.scrollTo(0, 0)}
        >
          <GoToTop></GoToTop>
        </div>
        <div
          style={{ transform: isFooterFixedOpened ? "rotate(45deg)" : "none" }}
          onClick={() =>
            dispatch(coreComponentsActions.toggleIsFooterFixedOpened())
          }
        >
          <ShowFooterBlack></ShowFooterBlack>
        </div>
      </NewsButtons>
      <NewsOnScroll isOnTop={isOnTop} setIsOnTop={setIsOnTop}></NewsOnScroll>
    </>
  );
}

export default LayoutNews;
