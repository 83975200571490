import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Wrapper,
  Container,
  Header,
  DesktopContentHeader,
  Divider,
} from "./style";
// components
import AdsList from "./adsList/adsList";
import Tabs from "./tabs/tabs";
import MobileContentHeader from "./mobileContentHeader/mobileContentHeader";
import FiltersDropdown from "./filtersDropdown/filtersDropdown";
import TitleWithCounter from "./titleWithCounter/titleWithCounter";
import ConfirmationModal from "./confirmationModal/ConfirmationModal";
import {
  initialCardsTotal,
  selectCatalogOfferType,
  selectCatalog,
} from "../../../../pages/AdsList/selectors";
import NoAds from "./noAds/noAds";
import {
  setCardsState,
  setFiltersState,
  setTabsState,
} from "../../../../pages/AdsList/AdsListSlice";

function HomeAdsCatalogue() {
  const dispatch = useDispatch();

  const offerType = useSelector(selectCatalogOfferType());
  const catalog = useSelector(selectCatalog());
  const cardsTotal = useSelector(initialCardsTotal());
  const { openModal } = useSelector((state) => state.AdsList);

  useEffect(() => {
    dispatch(setTabsState());
    dispatch(setFiltersState());
    dispatch(setCardsState());
  }, [dispatch, offerType, catalog]);

  return (
    <Wrapper>
      <Divider></Divider>
      {!offerType && (!cardsTotal || cardsTotal <= 0) ? (
        <NoAds></NoAds>
      ) : (
        <Container>
          <Header>
            {offerType && <Tabs />}
            {/* MOBILE */}
            <MobileContentHeader />
            {/* DESKTOP */}
            <DesktopContentHeader offerType={offerType}>
              {!offerType && (
                <h2>
                  <TitleWithCounter justText={true} /> {"" + offerType}
                </h2>
              )}
              <FiltersDropdown />
            </DesktopContentHeader>
          </Header>

          <AdsList offerType={offerType} />
        </Container>
      )}
      {openModal && <ConfirmationModal />}
    </Wrapper>
  );
}
HomeAdsCatalogue.propTypes = {
  resourceType: PropTypes.string,
  cardsTotal: PropTypes.number,
};
export default HomeAdsCatalogue;
