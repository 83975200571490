import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./globalSearchSlice";

const globalSearchState = (state) => state.globalSearch || initialState;

export const selectResultType = () =>
  createSelector(globalSearchState, (state) => state.resultType);

export const selectResultsByType = (type) =>
  createSelector(globalSearchState, (state) => {
    if (state.results.length > 0) {
      return state.results
        .filter((result) => result.type === type)
        .sort((a, b) => b.date - a.date);
    }
  });
