import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";
// components
import NotificationCard from "./notificationCard/NotificationCard";
// actions
import { notificationActions } from "../../../../../pages/Notifications/notificationSlice";
// style
import {
  Container,
  NotificationsContainer,
  CtaWrapper,
  SpanCtaIcon,
  CtaCircle,
} from "./style";

function DialogContent() {
  const dispatch = useDispatch();

  const { notificationsDialogComponents } = useSelector((state) => state.home);
  const { notificationsArrayNotRead, notificationsArrayRead } = useSelector(
    (state) => state.notifications
  );

  const newNotificationReference = useRef(null);

  useEffect(() => {
    newNotificationReference.current.focus();
  }, []);

  return (
    <>
      {notificationsDialogComponents &&
        notificationsArrayNotRead &&
        notificationsArrayRead && (
          <Container>
            <h2>{notificationsDialogComponents.titleNotifications}</h2>
            <NotificationsContainer>
              <span
                id="idNewNotification"
                tabIndex="0"
                role="combobox"
                aria-label="nuove"
                ref={newNotificationReference}
              >
                {notificationsDialogComponents.resultsLabel.replace(
                  "${number}",
                  notificationsArrayNotRead.length
                )}
              </span>
              {React.Children.toArray(
                notificationsArrayNotRead.map((element) => {
                  return <NotificationCard data={element} />;
                })
              )}
              <span
                id="idOldNotification"
                tabIndex="0"
                role="combobox"
                aria-label="lette"
                className="read"
              >
                {notificationsDialogComponents.alreadyRead.replace(
                  "${number}",
                  notificationsArrayRead.length
                )}
              </span>
              {React.Children.toArray(
                notificationsArrayRead.map((element) => {
                  return <NotificationCard data={element} />;
                })
              )}
            </NotificationsContainer>
            <CtaWrapper>
              <Link
                to={notificationsDialogComponents.linkHref}
                onClick={() =>
                  dispatch(notificationActions.setShowNotificationDialog(false))
                }
              >
                <span>
                  {notificationsDialogComponents.ctaNotificationsLabel}
                </span>
                <SpanCtaIcon>
                  <CtaCircle>
                    <svg viewBox="0 0 24 24">
                      <circle cx={12} cy={12} r={11} stroke={1} />
                    </svg>
                  </CtaCircle>

                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4 16L11.2677 9.54697C11.7148 9.18016 12 8.62337 12 8C12 7.37663 11.7148 6.81984 11.2677 6.45304L4 0L8 8L4 16Z"
                      fill="var(--cta-color)"
                    />
                  </svg>
                </SpanCtaIcon>
              </Link>
            </CtaWrapper>
          </Container>
        )}
    </>
  );
}

export default DialogContent;
