import React from "react";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";

// style
import {
  Wrapper,
  TitleAndTextWrapper,
  CardTitle,
  CtaWrapper,
  Text,
  DescrWrapper,
  Mask1,
  Mask2,
} from "./style";
import { useRef, useState } from "react";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";
import CtaIcon from "../../ctaIcon/CtaIcon";
import linkTarget from "../../../../utils/linkTarget";

function ImageCardSmall({ item }) {
  const [loaded, setLoaded] = useState(0);
  const loader = useRef(null);

  return (
    <Wrapper ref={loader} style={{ opacity: loaded }}>
      <ShowOnIntersection
        loader={loader}
        setLoaded={setLoaded}
      ></ShowOnIntersection>
      {/* <Mask1 /> */}
      <Mask2 />
      <img src={item.fileReference} />
      <TitleAndTextWrapper>
        <CardTitle>
          <span>⏤</span>
          <span>{item.category}</span>
        </CardTitle>
        <DescrWrapper>
          <Text>{item.title}</Text>
        </DescrWrapper>
      </TitleAndTextWrapper>
      <CtaWrapper>
        <a
          href={SetQueryStringPath(item.linkHref) || "#"}
          target={linkTarget(item.linkHref)}
          aria-label={item?.title}
        >
          <span>{item.textHref}</span>
          <CtaIcon showArrow={true}></CtaIcon>
        </a>
      </CtaWrapper>
    </Wrapper>
  );
}

export default React.memo(ImageCardSmall);
