/* eslint-disable react/style-prop-object */
export default function EventIconGrey() {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8887 27.3162C39.3108 23.465 39.3784 19.2443 38.0802 15.3495C37.9202 14.8696 37.7503 14.3895 37.5503 13.9296C38.1481 13.586 38.6908 13.1545 39.1603 12.6496C39.3803 13.1496 39.5803 13.6396 39.7703 14.1496C41.3649 18.4996 41.4109 23.2666 39.9006 27.6467C38.3903 32.0268 35.4159 35.7522 31.479 38.1949C27.542 40.6376 22.8833 41.6483 18.288 41.0564C13.6928 40.4646 9.44214 38.3064 6.25263 34.9459C3.06312 31.5853 1.12983 27.2278 0.77866 22.6079C0.427489 17.9881 1.67992 13.3884 4.32482 9.58432C6.96972 5.78024 10.8453 3.00442 15.2983 1.72479C19.7513 0.445156 24.5093 0.739975 28.7702 2.55955C28.944 2.64452 29.1298 2.73248 29.3194 2.82228C29.6207 2.96495 29.9318 3.11225 30.2202 3.25954C30.7102 3.51953 31.1801 3.79956 31.6401 4.08955C31.0572 4.46934 30.5599 4.96661 30.1801 5.54955C29.7468 5.28955 29.3068 5.04955 28.8601 4.82955C28.4101 4.60957 27.9502 4.40953 27.4702 4.21955C23.642 2.73667 19.4228 2.60234 15.5081 3.83868C11.5933 5.07502 8.21656 7.60825 5.93442 11.0209C3.65228 14.4335 2.60093 18.5218 2.95366 22.6119C3.30638 26.7021 5.04213 30.5501 7.87481 33.5216C10.7075 36.4931 14.4681 38.4108 18.5367 38.9587C22.6053 39.5066 26.7392 38.6519 30.257 36.5355C33.7749 34.4192 36.4666 31.1674 37.8887 27.3162ZM31.6401 4.08955C34.45 5.85467 36.7766 8.29119 38.4102 11.1796C38.6835 11.6595 38.9336 12.1496 39.1603 12.6496C38.6908 13.1545 38.1481 13.586 37.5503 13.9296C37.3503 13.4596 37.1301 13.0096 36.8901 12.5596C37.4864 12.2133 38.0028 11.7452 38.4057 11.1858C38.0017 11.7416 37.4854 12.2062 36.8902 12.5496C35.3359 9.64636 33.015 7.22517 30.1801 5.54955C30.5599 4.96661 31.0572 4.46934 31.6401 4.08955Z"
        fill="#D4D2D2"
      />
      <g clipPath="url(#clip0_17170_27045)">
        <path
          d="M27.0013 15.666C27.7377 15.666 28.3346 16.263 28.3346 16.9993V27.666C28.3346 28.4024 27.7377 28.9993 27.0013 28.9993H15.0013C14.2649 28.9993 13.668 28.4024 13.668 27.666V16.9993C13.668 16.263 14.2649 15.666 15.0013 15.666H27.0013ZM27.0013 16.9993H15.0013V27.666H27.0013V16.9993Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3333 13V15.6667H17V14.3333C17 13.597 17.597 13 18.3333 13Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9993 13V15.6667H23.666V14.3333C23.666 13.597 24.263 13 24.9993 13Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.998 21.668H18.998C19.7344 21.668 20.3314 22.2649 20.3314 23.0013H16.998V21.668Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.998 24.334H18.998C19.7344 24.334 20.3314 24.9309 20.3314 25.6673H16.998V24.334Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.666 21.668H23.666C24.4024 21.668 24.9993 22.2649 24.9993 23.0013H21.666V21.668Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.666 24.334H23.666C24.4024 24.334 24.9993 24.9309 24.9993 25.6673H21.666V24.334Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.9316 19.0541V19.6673L14.3316 19.6673V18.334L27.2115 18.334C27.6092 18.334 27.9316 18.6564 27.9316 19.0541Z"
          fill="var(--main-color)"
        />
      </g>
      <defs>
        <clipPath id="clip0_17170_27045">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
