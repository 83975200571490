// hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// actions
import { fetchComponents } from "./actions";
import { fetchRubricaComponents } from "../Rubrica/actions";

// components
import DetailsHeader from "../../components/UI/event/eventDetails/header/DetailsHeader";
import Layout from "../../components/UI/event/eventDetails/Layout";
import TwoColumnContainer from "../../components/UI/event/eventDetails/twoColumnContainer/TwoColumnContainer";
import LeftColumnContainer from "../../components/UI/offerDetail/leftColumnContainer/LeftColumnContainer";
import RightColumnContainer from "../../components/UI/offerDetail/rightColumnContainer/RightColumnContainer";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import ContactModal from "../../components/UI/offerDetail/rightColumnContainer/contactModal/ContactModal";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// selectors
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import {
  selectContactCard,
  selectLeftComponents,
  selectRightComponents,
  selectTopComponents,
} from "./selectors";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function OfferDetail() {
  const dispatch = useDispatch();
  const components = useSelector((state) => {
    return state.offerDetail.components;
  });
  const location = useLocation();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const openModal = useSelector((state) => state.offerDetail.openContactModal);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const path = location.pathname + location.search.replace("?id=", "/");

  const addressBookPagePath = headerLinks?.find(
    (v) => v.template === "address-book"
  )?.path;
  const title = headerLinks?.find((v) => v.template === "ad-detail")?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useMemo(() => {
    addressBookPagePath &&
      token &&
      dispatch(fetchRubricaComponents({ path: addressBookPagePath, token }));
  }, [addressBookPagePath]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);

  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path, true);
  const { status, errorData } = GetStatusAndErrorData("offerDetail");
  const topComponents = useSelector(selectTopComponents());
  const rightComponents = useSelector(selectRightComponents());
  const leftComponents = useSelector(selectLeftComponents());
  const contactCardInfo = useSelector(selectContactCard());

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <>
          {components?.components && (
            <>
              <DetailsHeader
                headerComponents={topComponents}
                goBackText={"TORNA INDIETRO"}
                link={""}
                onlyBack={true}
              />
              <Layout>
                <TwoColumnContainer gap="32px" flexDirection="column">
                  <LeftColumnContainer leftColumnComponents={leftComponents} />
                  <RightColumnContainer
                    rightColumnComponents={rightComponents}
                  />
                </TwoColumnContainer>
              </Layout>
              {openModal && <ContactModal data={contactCardInfo} />}
            </>
          )}
        </>
      )}
    </>
  );
}

export default OfferDetail;
