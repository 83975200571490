import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 32px;
  width: 800px;
  padding: 32px 0px 32px 0px;
  border-bottom: 1px solid #d4d2d2;
  div.date {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--alternative-grey-color);
    font-weight: 400;
    text-align: left;
    min-width: 56px;
    @media (max-width: 1279px) {
      font-size: 11px;
    }
  }
  div.title-description {
    flex-grow: 4;
    @media (max-width: 1279px) {
      width: 60%;
    }
  }
  div.download {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    span {
      margin-right: 10px;
      @media (max-width: 1279px) {
        display: none;
      }
    }
    svg {
      cursor: pointer;
    }
    @media (max-width: 1279px) {
      flex-grow: 1;
    }
  }
  @media (max-width: 1279px) {
    width: 100%;
    align-items: center;
    gap: 16px;
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
  text-align: left;
  overflow: hidden;
  max-width: 520px;
  @media (max-width: 1279px) {
    font-size: 11px;
  }
`;

export const Description = styled.div`
  display: flex;
  gap: 8px;
  font-size: 13px;
  font-weight: 400;
  color: var(--main-color);
  span {
    color: var(--alternative-grey-color);
    @media (max-width: 1279px) {
      display: none;
    }
  }
  div {
    color: var(--alternative-grey-color);
  }
  @media (max-width: 1279px) {
    flex-direction: column;
    font-size: 11px;
  }
`;
