import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  selectCatalogCategoryLabel,
  selectCatalogOfferTypeLabel,
} from "../../../../../pages/AdsList/selectors";
import {
  Card,
  ImageWrapper,
  CardContent,
  CardHeader,
  CardPrice,
  CardFooter,
  CardTitle,
  CardTag,
  ActionLink,
  CardButtons,
  CardTags,
  Descr,
} from "./style";
// actions
import { deleteAds } from "../../../../../pages/AdsList/actions";

function AdsCard({
  id,
  category,
  offerType,
  title,
  description,
  image,
  price,
  date,
  name,
  surname,
  deleteLabel,
  modifyLabel,
  showCategory,
}) {
  const formatCardPrice = (price) => `
    ${
      typeof price === "string"
        ? price
        : "€ " + new Intl.NumberFormat("it-IT").format(price)
    }
    ${offerType === "rent" ? "/mese" : ""}
    ${offerType === "timeOff" ? "/pezzo" : ""}
  `;
  const categoryLabel = useSelector(selectCatalogCategoryLabel(category));
  const offerTypeLabel = useSelector(selectCatalogOfferTypeLabel(offerType));

  const token = useSelector((state) => state.login.activeToken);
  const dispatch = useDispatch();
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const offerDetailPath = headerLinks?.find(
    (v) => v.template === "ad-detail"
  )?.path;
  const myAdsPath = headerLinks?.find((v) => v.template === "my-ads")?.path;
  const linkToOffer = `${offerDetailPath}?id=${id}`;
  const linkToModify = `/creazione-annuncio?id=${id}&path=${myAdsPath}`;
  const navigate = useNavigate();
  const { pageLight } = useSelector((state) => state.coreComponents);
  return (
    <Card>
      {
        <Link to={linkToOffer ?? "/"}>
          <ImageWrapper
            src={image ? image : "/img/noImgAvailable.png"}
          ></ImageWrapper>
        </Link>
      }
      <CardContent>
        <Link to={linkToOffer ?? "/"}>
          <CardHeader>
            <CardTags>
              {showCategory && categoryLabel && (
                <CardTag className="category">{categoryLabel}</CardTag>
              )}
              {offerTypeLabel && (
                <CardTag className="primary">{offerTypeLabel}</CardTag>
              )}
            </CardTags>

            <CardTitle>{title}</CardTitle>
          </CardHeader>
          {price && <CardPrice>{formatCardPrice(price)}</CardPrice>}
          <Descr data-cs-mask>{description}</Descr>
        </Link>
        <CardFooter data-cs-mask>
          <div>Inserito il {date.replaceAll("-", ".")}</div>
          {name?.length && (
            <div>
              Da {name} {surname ?? ""}
            </div>
          )}
        </CardFooter>
        {modifyLabel && (
          <CardButtons>
            <ActionLink onClick={() => navigate(linkToModify)}>
              {pageLight === "dark" ? (
                <img src="/img/modifica_annuncio_black.svg" />
              ) : (
                <img src="/img/modifica_annuncio_white.svg" />
              )}
              {modifyLabel}
            </ActionLink>
            <ActionLink onClick={() => dispatch(deleteAds({ id, token }))}>
              {pageLight === "dark" ? (
                <img src="/img/elimina_annuncio_black.svg" />
              ) : (
                <img src="/img/elimina_annuncio_white.svg" />
              )}
              {deleteLabel}
            </ActionLink>
          </CardButtons>
        )}
      </CardContent>
    </Card>
  );
}

AdsCard.propTypes = {
  id: PropTypes.string,
  category: PropTypes.string,
  offerType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  date: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  deleteLabel: PropTypes.string,
  modifyLabel: PropTypes.string,
  showCategory: PropTypes.bool,
};
export default AdsCard;
