import { createSlice } from "@reduxjs/toolkit";

export const GENERIC_TEMPLATE_SCOPE = "GenericTemplate";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
};

const GenericTemplateSlice = createSlice({
  name: GENERIC_TEMPLATE_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    fetchComponents: () => {},
  },
});

export const { setComponents, setError, setStatusNull, fetchComponents } =
  GenericTemplateSlice.actions;
export default GenericTemplateSlice;
