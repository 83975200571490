import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// style
import { Slot, FocusText, Description } from "./style";

function NewsSlot({ newsSlot, secondNewsImgOverride }) {
  const d = new Date(newsSlot.createdDate);
  const options = { month: "short", day: "numeric" };
  const newsDate = d.toLocaleDateString(undefined, options);
  return (
    <Link to={newsSlot?.path ? "/news/news-detail?path=" + newsSlot.path : "#"}>
      <Slot>
        <img src={secondNewsImgOverride || newsSlot.image} />
        <FocusText>
          <Description>
            <span>{newsDate}</span>
            <span></span>
            <span>{newsSlot.category}</span>
          </Description>
          <h3>{newsSlot.title}</h3>
        </FocusText>
      </Slot>
    </Link>
  );
}

NewsSlot.propTypes = {
  newsSlot: PropTypes.exact({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    createdDate: PropTypes.number.isRequired,
    lastModifiedDate: PropTypes.number,
    lastReplicatedDate: PropTypes.number,
    category: PropTypes.string.isRequired,
    tags: PropTypes.array,
  }),
};
export default NewsSlot;
