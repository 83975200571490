import { Wrapper, StrumentiH1, Description } from "./style";

function StrumentiTitle({ data, setTextAlignToStart }) {
  return (
    <Wrapper setTextAlignToStart={setTextAlignToStart}>
      <StrumentiH1 setTextAlignToStart={setTextAlignToStart}>
        {data.title}
      </StrumentiH1>
      <Description
        dangerouslySetInnerHTML={{ __html: data.description }}
      ></Description>
    </Wrapper>
  );
}

export default StrumentiTitle;
