import styled from "styled-components";

export const DetailContainerData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-size: 13px;
    color: #181818;
    line-height: 20px;
    color: var(--main-color);
  }
`;

export const DetailDataLabel = styled.div`
  font-size: 11px;
  color: #707070;
  margin: 0px;
  letter-spacing: 1px;
  line-height: 20px;
`;

export const TitleDetail = styled.p`
  font-family: ferrari-sans-medium, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
`;
export const ContainerLe = styled.div`
  margin-top: 50px;
`;
