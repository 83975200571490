import {
  Wrapper,
  Container,
  ImgHeader,
  Title,
  ContainerAccordion,
} from "./style";
import FaqMenu from "./faqMenu/faqMenu";
import { useSelector } from "react-redux";
import {
  getAccordionsList,
  getAccordionsTitles,
  getNonAccordions,
} from "../../../pages/FAQ-Detail/selectors";
import FaqAccordionList from "./faqAccordionList/faqAccordionList";
import NewsText from "../news/newsDetailContainer/newsText/NewsText";

function LayoutFAQDetail({ heroImg, title }) {
  const othersComponents = useSelector(getNonAccordions());
  const accordions = useSelector(getAccordionsList());
  const titles = useSelector(getAccordionsTitles());
  const componentsMapping = {
    "fi-text": NewsText,
  };
  return (
    <>
      <Container>
        <Title>{title}</Title>
        {othersComponents?.map((current, idx) => {
          const ReactGenericComponent = componentsMapping[current.resourceType];
          return (
            ReactGenericComponent && (
              <ReactGenericComponent
                key={`${current.resourceType}-${idx}`}
                data={current.data}
              ></ReactGenericComponent>
            )
          );
        })}
        <Wrapper>
          {titles.length ? <FaqMenu titles={titles} /> : <></>}
          <ContainerAccordion>
            {accordions.map((v, i) => {
              return (
                <FaqAccordionList
                  data={v.data}
                  id={i}
                  key={v}
                ></FaqAccordionList>
              );
            })}
          </ContainerAccordion>
        </Wrapper>
      </Container>
    </>
  );
}

export default LayoutFAQDetail;
