import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//utils
import { fetchComponents } from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
//components
import LayoutWebinar from "../../components/UI/webinar/LayoutWebinar";
import WebinarCardList from "../../components/UI/webinar/webinarCardList/WebinarCardList";
import GoToAllWebinars from "../../components/UI/webinar/goToAllWebinars/GoToAllWebinars";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import WebinarHistoricList from "../../components/UI/webinar/webinarHistoricList/WebinarHistoricList";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function Webinar({ path }) {
  const dispatch = useDispatch();

  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const webinarComponents = useSelector((state) => state.webinar.components);

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("webinar");

  const componentsMapping = {
    "fi-events/fi-events-webinar-listing": WebinarCardList,
    "fi-events/fi-events-webinar-multifield": WebinarHistoricList,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <>
          {webinarComponents?.components && (
            <LayoutWebinar>
              {webinarComponents?.components?.map((current, idx) => {
                const ReactGenericComponent =
                  componentsMapping[current.resourceType];
                return (
                  webinarComponents &&
                  ReactGenericComponent && (
                    <ReactGenericComponent
                      key={`${current.resourceType}-${idx}`}
                      data={current.data}
                    ></ReactGenericComponent>
                  )
                );
              })}
            </LayoutWebinar>
          )}
        </>
      )}
    </>
  );
}

export default Webinar;
