import React from "react";

import { DetailsSection, Divider, Wrapper } from "./style";
import { SubscribedEvents } from "./subscribedEvents/SubscribedEvents";
import { NextEvents } from "./nextEvents/NextEvents";
import { ExpiredEvents } from "./expiredEvents/ExpiredEvents";
import ShowMore from "./arrow/ShowMore";
import { myPageActions } from "../../../../pages/MyPage/myPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubscription } from "../../../../pages/MyPage/actions";
import { DialogNote } from "./DialogNote/DialogNote";

function MyEvents({ components: profileComponents }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const showModal = useSelector((state) => state.myPage.showModal);
  const isModalInfo = useSelector((state) => state.myPage.modalInfo);
  const currentGuid = useSelector((state) => state.myPage.currentGuid);
  const isDividerVisible = (index) => {
    const eventsCompLength = [
      profileComponents?.nextEvents.length,
      profileComponents?.pastEvents.length,
    ];

    for (let i = index; i < eventsCompLength.length; i++) {
      if (eventsCompLength[i] > 0) {
        return <Divider />;
      }
    }
  };

  const handlePopupInfo = (e, show) => {
    e.preventDefault();
    dispatch(myPageActions.setShowModal(show));
    dispatch(myPageActions.setShowCannotDeleteDialog(show));
  };
  const handlePopupVisualization = (e, show, guid) => {
    e.preventDefault();
    dispatch(myPageActions.setShowCannotDeleteDialog(false));
    dispatch(myPageActions.setCurrentGuid(guid));
    dispatch(myPageActions.setShowModal(show));
  };

  const handleConfirmDelete = (e) => {
    e.preventDefault();
    dispatch(myPageActions.setShowModal(false));
    dispatch(
      deleteSubscription({
        guid: currentGuid,
        token: token,
        lang: lang,
      })
    );
  };

  return (
    <>
      {showModal && (
        <DialogNote
          isModalInfo={isModalInfo}
          confirmCallBack={(e) =>
            isModalInfo ? handlePopupInfo(e, false) : handleConfirmDelete(e)
          }
          closeCallBack={(e) => handlePopupVisualization(e, false)}
        />
      )}
      <Wrapper>
        {profileComponents?.internalEvents?.length > 0 && (
          <>
            <DetailsSection className="first">
              <SubscribedEvents
                data={profileComponents}
                showModalDelete={handlePopupVisualization}
                showModalCannotDelete={handlePopupInfo}
              />
            </DetailsSection>
            {isDividerVisible(0)}
          </>
        )}
        {profileComponents?.nextEvents.length > 0 && (
          <>
            <DetailsSection>
              <NextEvents data={profileComponents} />
              <ShowMore data={profileComponents} />
            </DetailsSection>
            {isDividerVisible(1)}
          </>
        )}
        {profileComponents?.pastEvents.length > 0 && (
          <DetailsSection>
            <ExpiredEvents data={profileComponents} />
          </DetailsSection>
        )}
      </Wrapper>
    </>
  );
}

export default React.memo(MyEvents);
