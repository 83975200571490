import styled from "styled-components";

export const Container3dMap = styled.div`
  div {
    flex-grow: 1;
    position: relative;
    width: 100%;
    height: calc(100vh - ${(props) => props.heightdynamic}px);
    height: calc(100svh - ${(props) => props.heightdynamic}px);
  }
  iframe {
    height: calc(100vh - ${(props) => props.heightdynamic}px);
    height: calc(100svh - ${(props) => props.heightdynamic}px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
  }
  @media screen and (max-width: 989px) and (orientation: landscape) {
    div {
      height: 100vh;
      height: 100svh;
    }
    iframe {
      height: 100vh;
      height: 100svh;
    }
  }
`;
