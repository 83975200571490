import { takeAwayActions } from "../../../../pages/TakeAway/takeAwaySlice";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Kilos } from "../cafeteriaDishes/style";
import { AllergensDiv } from "./style";

const DISHES_NAME = "dishes-name";

function TakeAwayCard({ basket }) {
  const dispatch = useDispatch();
  const book = useSelector((state) => state.takeAway.book);
  const offer = useSelector((state) => state.takeAway.offer);
  const dishes = basket.dishes.split(",");
  return (
    <Wrapper tabIndex="0" aria-label={basket.name}>
      <h4>{basket.name}</h4>
      {dishes.map((v, idx) => {
        return (
          <div className="desktop-body" key={`${DISHES_NAME}-${idx}`}>
            {v}
          </div>
        );
      })}

      <AllergensDiv>Allergeni*: {basket.allergens}</AllergensDiv>
      <p className="desktop-small">{basket.ingredients}</p>
      {book && book.Title === basket.Title ? (
        <Kilos className="flex-align-center pointer">
          <img src="/img/offer-taken.svg" />
        </Kilos>
      ) : book && book.Title !== basket.Title ? (
        <></>
      ) : offer && offer.Title === basket.Title ? (
        <Kilos className="flex-align-center pointer">
          <img
            src="/img/dish-added.svg"
            onClick={() => dispatch(takeAwayActions.setOffer())}
          />
        </Kilos>
      ) : (
        <Kilos
          className="flex-align-center pointer"
          onClick={() => dispatch(takeAwayActions.setOffer(basket))}
        >
          {/* DA SCOMMENTARE QUANDO SERVE: NON c'è il servizio d'asporto quindi togliere la funzionalità di prenotazione
          <span>AGGIUNGI</span>
          <img src="/img/add.svg" /> */}
        </Kilos>
      )}
    </Wrapper>
  );
}

export default TakeAwayCard;
