/* eslint-disable default-case */
import { createSlice } from "@reduxjs/toolkit";

export const INIT_ADVANCED_FILTERS = {
  name: "",
  surname: "",
  direction: "",
  costCenter: "",
  company: "",
  phone: "",
  mobilePhone: "",
  dect: "",
  fax: "",
  email: "",
};

export const initialState = {
  components: [],
  settings: {},
  tabIndex: 1,
  tabsArray: [],
  valuedContactFields: [],
  labelMap: {},
  favContacts: [],
  favUseful: [],
  isSearchButtonEnabled: false,
  searchResult: [],
  showSearchResult: false,
  showDialog: false,
  filterCount: 0,
  advancedSearchFilters: INIT_ADVANCED_FILTERS,
  status: null,
  errorMessage: "",
};

const rubricaSlice = createSlice({
  name: "rubrica",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;

      const tabs = state.components.find(
        (v) => v.resourceType === "fi-address-book/fi-address-book-tabs"
      );

      state.favContacts = tabs?.data.favorites.items || [];

      const usefulFavMaranello = tabs?.data?.usefulMaranello?.items.filter(
        (el) => el.isFavourite === true
      );
      const usefulFavScaglietti = tabs?.data?.usefulScaglietti?.items.filter(
        (el) => el.isFavourite === true
      );
      const useful = tabs?.data?.useful?.items.filter(
        (el) => el.isFavourite === true
      );

      state.favUseful = [
        ...usefulFavMaranello,
        ...usefulFavScaglietti,
        ...useful,
      ];
    },
    setSettings(state, action) {
      state.settings = action.payload;
    },
    setTabsArray(state, action) {
      // put here all the logic for building the labelMap
      state.tabsArray = action.payload;
    },
    setTabIndex(state, action) {
      state.tabIndex = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setValuedContactFields(state, action) {
      state.valuedContactFields = action.payload;
    },
    setLabelMap(state, action) {
      state.labelMap = Object.fromEntries(
        Object.entries(action.payload).filter(([key, _]) => key !== "undefined")
      );
    },
    showDialog(state, action) {
      state.showDialog = action.payload;
    },
    setFilterCount(state, action) {
      state.filterCount = action.payload;
    },
    setAdvancedSearchFilters(state, action) {
      state.advancedSearchFilters = action.payload;
    },
    setSearchButton(state, action) {
      state.isSearchButtonEnabled = action.payload;
    },
    setFavouriteContact(state, action) {
      let contactReceived = { ...action.payload };
      const contactInStore = state.favContacts.find(
        (v) => v.subjectCode === contactReceived.subjectCode
      );

      if (contactInStore) {
        contactInStore.isFavourite = !contactInStore.isFavourite;
        if (contactInStore.isFavourite) {
          state.favContacts.push(contactReceived);
        } else {
          state.favContacts = state.favContacts.filter(
            (contact) => contact.subjectCode !== contactInStore.subjectCode
          );
        }
      } else {
        contactReceived.isFavourite = true;
        state.favContacts.push(contactReceived);
      }
    },
    SetSearchResult(state, action) {
      state.searchResult = action.payload;
      state.showSearchResult = true;
    },
    RemoveSearchResult(state) {
      state.searchResult = [];
      state.showSearchResult = false;
    },
    setFavouriteUsefulContact(state, action) {
      let contactReceived = { ...action.payload };
      const contactInStore = state.favUseful.find(
        (v) => v.uniqueId === contactReceived.uniqueId
      );

      if (contactInStore) {
        contactInStore.isFavourite = !contactInStore.isFavourite;
        if (contactInStore.isFavourite) {
          state.favUseful.push(contactReceived);
        } else {
          state.favUseful = state.favUseful.filter(
            (contact) => contact.uniqueId !== contactInStore.uniqueId
          );
        }
      } else {
        contactReceived.isFavourite = true;
        state.favUseful.push(contactReceived);
      }
    },
    updateTabComponents(state, action) {
      const contactReceived = action.payload.contact;
      let tabIndex;
      switch (contactReceived.tag) {
        case "usefulMaranello":
          tabIndex = 1;
          break;
        case "usefulScaglietti":
          tabIndex = 2;
          break;
        case "useful":
          tabIndex = 3;
          break;
      }
      const contactInStore = state.tabsArray[tabIndex].components.items.find(
        (el) => el.uniqueId === contactReceived.uniqueId
      );

      contactInStore.isFavourite = !contactInStore.isFavourite;
    },
  },
});

export const rubricaActions = rubricaSlice.actions;
export default {
  name: rubricaSlice.name,
  reducer: rubricaSlice.reducer,
};
