import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { selectCardsState } from "../../../../../pages/AdsList/selectors";
import AdsCard from "../adsCard/adsCard";
import { Content } from "./style";

function AdsList({ offerType }) {
  const cards = useSelector(selectCardsState());
  if (!cards) return null;
  const { selectedList, deleteLabel, modifyLabel } = cards;

  return (
    <Content>
      {React.Children.toArray(
        selectedList?.map((card) => (
          <AdsCard
            id={card.id}
            category={card.category}
            offerType={card.offerType}
            title={card.title}
            description={card.description}
            image={card.cover}
            price={card.price}
            date={card.creationDate}
            name={card.name}
            surname={card.surname}
            deleteLabel={deleteLabel}
            modifyLabel={modifyLabel}
            showCategory={!offerType?.length}
          ></AdsCard>
        ))
      )}
    </Content>
  );
}

AdsList.propTypes = {
  resourceType: PropTypes.string,
  selectedList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      category: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      price: PropTypes.number,
      date: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
      deleteLabel: PropTypes.string,
      modifyLabel: PropTypes.string,
    })
  ),
};
export default AdsList;
