import { LayoutRecapAndDetailMondiali } from "../../components/UI/recapAndDetailMondiali/LayoutRecapAndDetailMondiali";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./action";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import { useLocation } from "react-router-dom";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

export default function RecapAndDetailMondiali() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector(
    (state) => state.RecapAndDetailMondiali.components
  );
  const location = useLocation();
  const url = location.search.replace(/\?path=/, "");
  useMemo(() => {
    dispatch(fetchComponents({ path: url, token }));
  }, [dispatch, url]);

  const recapData = components?.components?.find(
    (v) =>
      v.resourceType === "fi-events/fi-event-subscription-recap-finali-mondiali"
  );
  const detailData = components?.components?.find(
    (v) =>
      v.resourceType ===
      "fi-events/fi-event-subscription-detail-finali-mondiali"
  );

  const settingButtons = components?.components?.find(
    (v) => v.resourceType === "fi-two-buttons"
  );

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);

  ChangeTitleDoc(
    subjectCode,
    aggregatedDirection,
    components?.settings?.jcrTitle,
    url
  );
  const { status, errorData } = GetStatusAndErrorData("RecapAndDetailMondiali");
  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <>
          {recapData?.data && (
            <LayoutRecapAndDetailMondiali
              data={recapData?.data}
              isRecapPage
              buttonsData={settingButtons?.data}
            />
          )}

          {detailData?.data && (
            <LayoutRecapAndDetailMondiali
              data={detailData?.data}
              buttonsData={settingButtons?.data}
            />
          )}
        </>
      )}
    </>
  );
}
