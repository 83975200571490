import {
  FETCH_COMPONENTS,
  SET_FAV_CONTACT,
  SEARCH_BY_NAME,
  ADVANCED_SEARCH,
  SET_FAV_USEFUL_CONTACT,
} from "./constants";

export const fetchRubricaComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const SaveFavContact = (payload) => ({
  type: SET_FAV_CONTACT,
  payload,
});

export const SaveFavUsefulContact = (payload) => ({
  type: SET_FAV_USEFUL_CONTACT,
  payload,
});

export const SearchByName = (payload) => ({
  type: SEARCH_BY_NAME,
  payload,
});

export const AdvancedSearch = (payload) => ({
  type: ADVANCED_SEARCH,
  payload,
});
