//hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchComponents } from "./genericTemplateSlice";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// components
import NewsDocContainer from "../../components/UI/news/newsDetailContainer/newsDocContainer/NewsDocContainer";
import GenericTitle from "../../components/UI/genericTemplate/title/GenericTitle";
import EventDivider from "../../components/UI/event/eventDetails/twoColumnContainer/leftColumnContainer/eventDivider/EventDivider";
import ContractContainer from "../../components/UI/contratto/contractContainer/ContractContainer";
import NewsImage from "../../components/UI/news/newsDetailContainer/newsImage/NewsImage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import ThronPlayerWrapper from "../../components/UI/thronPlayer/ThronPlayerWrapper";
import FormazioneManagerialeBoxes from "../../components/UI/formazioneManageriale/formazioneManagerialeBoxes/formazioneManagerialeBoxes";
import StrumentiTitle from "../../components/UI/strumenti/strumentiTitle/StrumentiTitle";
import LayoutGenericTemplate from "../../components/UI/genericTemplate/LayoutGenericTemplate";
import GenericText from "../../components/UI/genericTemplate/text/GenericText";
import FiButton from "../../components/UI/twoThirdTemplate/leftColumnContainer/fi-button/FiButton";
import MainCarousel from "../../components/UI/home/mainCarousel/MainCarousel";
import Wrapper from "../../components/UI/twoThirdTemplate/leftColumnContainer/divider/Divider";
import FaqAccordionList from "../../components/UI/genericTemplate/faqAccordionList/faqAccordionList";
import DetailsHeader from "../../components/UI/event/eventDetails/header/DetailsHeader";
import HtmlBox from "../../components/UI/htmlBox/htmlBox";
import { selectTopComponents, heroComponent } from "./selectors";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";
import GridThreeColumns1216 from "../../components/UI/genericTemplate/gridThreeColumns1216/GridThreeColumns1216";

function GenericTemplate({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector((state) => state.GenericTemplate.components);
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const topComponents = useSelector(selectTopComponents());
  const heroComp = useSelector(heroComponent());
  const { status, errorData } = GetStatusAndErrorData("GenericTemplate");
  //  •	Text
  // •	Title
  // •	Title Description
  // •	Button
  // •	Carousel
  // •	Divider
  // •	Divider Gray
  // •	Documents
  // •	Image
  // •	Thron Universal Player
  // •	Formazione Manageriale
  // •	FAQ Accordion List

  const componentsMapping = {
    "fi-text": GenericText,
    "fi-title": GenericTitle,
    "fi-tools-title-description": StrumentiTitle,
    "fi-button": FiButton,
    "fi-carousel": MainCarousel,
    "fi-divider": EventDivider,
    "fi-welfare/fi-welfare-divider": Wrapper,
    "fi-contratto-documents": NewsDocContainer,
    "fi-image": NewsImage,
    "thronconnector/components/thronmediaplayer": ThronPlayerWrapper,
    "fi-formazione-manageriale": FormazioneManagerialeBoxes,
    "fi-faq-accordion-list": FaqAccordionList,
    "fi-contract-container": ContractContainer,
    "fi-cards-grid": GridThreeColumns1216,
    "fi-html-box": HtmlBox,
  };
  const backLink = heroComp?.data.link && heroComp?.data.textHref;
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components?.components && (
          <>
            <DetailsHeader
              headerComponents={topComponents}
              goBackText={heroComp?.data.textHref || "TORNA INDIETRO"}
              link={heroComp?.data.link || ""}
              onlyBack={true}
              noCreateWithoutLink={true}
            />
            <LayoutGenericTemplate backLink={backLink}>
              {components?.components?.map((current, idx) => {
                const ReactGenericComponent =
                  componentsMapping[current.resourceType];
                return (
                  ReactGenericComponent && (
                    <ReactGenericComponent
                      key={`${current.resourceType}-${idx}`}
                      template="GenericTemplate"
                      data={current.data}
                    ></ReactGenericComponent>
                  )
                );
              })}
            </LayoutGenericTemplate>
          </>
        )
      )}
    </>
  );
}

export default GenericTemplate;
