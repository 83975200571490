import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 384px;
  opacity: 0;
  transition: opacity 1s;
  @media (min-width: 769px) {
    width: 304px;
  }
  @media (min-width: 1280px) {
    width: 384px;
  }
  @media (max-width: 1279px) {
    max-height: 304px;
  }
  overflow: hidden;
  img {
    transform: scale(1);
    transition: transform 0.6s;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    @media (max-width: 1279px) {
      height: auto;
    }
  }
  &:hover img {
    transform: scale(1.1);
  }
  &::before {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 9.39%,
      rgba(25, 25, 25, 0.46) 57.61%,
      rgba(48, 48, 48, 0) 94.84%
    );
    transform: rotate(-180deg);
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  img {
    display: block;
  }
  a {
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
`;

export const CardCTA = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 384px;
  height: 384px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1279px) {
    height: 304px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(143, 143, 143);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  a:hover {
  }
`;

export const Title = styled.div`
  font: 11px / 11px ferrari-sans-regular;
  color: #ffffff;
  padding: 32px;
  @media (max-width: 1279px) {
    width: auto;
  }
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  span:first-of-type {
    margin-right: 10px;
    margin-bottom: 2px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 32px 32px 32px;
  @media (max-width: 1279px) {
    box-sizing: border-box;
    padding: 0 32px 16px 32px;
  }
  gap: 24px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const CafeteriaName = styled.div`
  height: 34px;
  font-weight: 400;
  ${(props) => (props.switchActive ? `font-size: 28px;` : `font-size: 24px;`)}
  line-height: 34px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  @media (max-width: 1279px) {
    font-size: 22px;
    line-height: 28px;
    font-family: ferrari-sans-medium;
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const Line = styled.div`
  // padding-top: 24px;
  height: 0px;
  border-bottom: 1px solid #ffffff;
`;

export const Switch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  @media and (max-width: 1279px) {
    padding: 24px 32px;
  }
  cursor: pointer;
`;

export const TextSwitch = styled.div`
  height: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  display: flex;
  align-items: center;
  color: #ffffff;
`;
export const CardContainerMobile = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
