import React from "react";
import { ComponentContainer } from "../../style";
//components
import ErrorField from "../ErrorField/ErrorField";
import InputRadioButton from "../RadioButton/RadioButton";
import { useSelector } from "react-redux";

export default function Accompagnatori({ data, onChange, allowed, check }) {
  const guestLimit = useSelector(
    (state) => state.FormIscrizioneGiftHouse.guestLimit
  );

  return (
    <ComponentContainer>
      <InputRadioButton
        noOne={data?.noGuestLabel}
        description={data?.guestTxt}
        array={[...Array(guestLimit).keys()]}
        name={`NumGuests`}
        defaultValue={true}
        checked={check}
        onChange={onChange}
      />
      {check == null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
