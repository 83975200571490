import { useSelector } from "react-redux";
import ErrorField from "../ErrorField/ErrorField";
import { InputSectionCheckbox, PrivacyRules, TextDescr } from "../../style";
import React from "react";
import { useBusinessLogic } from "../../useBusinessLogic";

export default function ComponentPrivacy({ data, onChange, allowed }) {
  const { generateHtml, parseLabel } = useBusinessLogic();

  const checkedConsents = useSelector(
    (state) => state.FormIscrizioneGiftHouse.checkedConsents
  );

  return (
    <PrivacyRules>
      <TextDescr>{data?.title}</TextDescr>
      {React.Children.toArray(
        data?.items?.map((policie, i) => {
          return (
            <InputSectionCheckbox>
              <input
                disabled
                id={`checkBoxPrivacy${i}`}
                type="checkbox"
                className="checkbox-input"
                onChange={onChange}
                rawtext={policie?.richText}
              />
              <span
                className=""
                dangerouslySetInnerHTML={generateHtml(
                  parseLabel(`${i}`, policie?.richText)
                )}
              />
            </InputSectionCheckbox>
          );
        })
      )}
      {(checkedConsents?.find((el) => el.checked === false) ||
        checkedConsents?.length !== data?.items?.length) &&
        allowed === false && (
          <ErrorField text={data?.errorMessage} id="required" />
        )}
    </PrivacyRules>
  );
}
