import styled from "styled-components";

export const TitleTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
`;

export const SpanCategory = styled.span`
  padding: 4px 10px;
  background: rgb(239, 237, 237);
  border-radius: 30px;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: rgb(48, 48, 48);
`;

export const SpanType = styled.span`
  background: rgb(255, 255, 255);
  border: 1px solid rgb(24, 24, 24);
  border-radius: 30px;
  padding: 4px 10px;
  border-radius: 30px;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: rgb(48, 48, 48);
`;
