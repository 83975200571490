import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toolsActions } from "../../../../pages/Strumenti/toolsSlice";

import {
  Divider,
  Wrapper,
  ToolsPageH2,
  SelectWrapper,
  Select,
  WrapperImg,
  ToolListWrapper,
} from "./style";

import ToolBox from "./toolBox/ToolBox";
import BottomIcon from "../../../assets/bottom";
import CustomSelectPlugin from "../../customSelectPlugin/CustomSelectPlugin";

function ToolBoxList({ data }) {
  const dispatch = useDispatch();
  const toolsMap = useSelector((state) => state.tools.toolsMap);
  const [thematicValue, setThematicValue] = useState("Favourites");
  const inputChangeHandler = (event) => {
    const { value } = event;
    const initlist = Object.entries(toolsMap);
    let orderedList;
    switch (value) {
      case "A-Z":
        orderedList = initlist
          .sort((a, b) => (a[1].title > b[1].title ? 1 : -1))
          .map((x) => x[1]);

        dispatch(toolsActions.orderTools(orderedList));
        break;
      case "Z-A":
        orderedList = initlist
          .sort((a, b) => (a[1].title < b[1].title ? 1 : -1))
          .map((x) => x[1]);

        dispatch(toolsActions.orderTools(orderedList));
        break;
      case "Favourites":
        dispatch(toolsActions.orderByFav());
        break;
      default:
        break;
    }
    setThematicValue(value);
  };

  return (
    <Wrapper>
      <Divider />
      <ToolsPageH2>
        <h2>{data.title}</h2>
        <SelectWrapper>
          <CustomSelectPlugin
            hideLabel={true}
            onChange={inputChangeHandler}
            placeholderString={
              thematicValue === "Favourites" ? "PREFERITI" : thematicValue
            }
            defaultValue={thematicValue}
            options={[
              {
                value: "Favourites",
                label: "PREFERITI",
              },
              {
                value: "A-Z",
                label: "A-Z",
              },
              {
                value: "Z-A",
                label: "Z-A",
              },
            ]}
          />
        </SelectWrapper>
      </ToolsPageH2>
      <ToolListWrapper>
        {React.Children.toArray(
          toolsMap.map((currentToolBox) => {
            return <ToolBox currentToolBox={currentToolBox} />;
          })
        )}
      </ToolListWrapper>
    </Wrapper>
  );
}

ToolBoxList.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    tools: PropTypes.array,
    position: PropTypes.string,
  }),
};

export default ToolBoxList;
