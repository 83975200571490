import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  width: 90%;
  .news-h2 {
    font-size: 16px;
    text-align: left;
  }
  @media screen and (min-width: 1280px) {
    width: 1216px;
  }
`;

export const NewsButtons = styled.div`
  position: fixed;
  right: 26px;
  bottom: 5%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > div {
    cursor: pointer;
  }
  @media screen and (max-width: 1279px) {
    right: 5px;
  }
`;
