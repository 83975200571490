import styled from "styled-components";

export const MapWrapper = styled.div`
  grid-area: map / map / map / map;
  border-top: 1px solid;
  @media (min-width: 1280px) {
    border-left: 1px solid;
    border-top: 0;
  }
  div {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
  }
  img {
    max-width: 336px;
    margin-top: 14px;
    @media (max-width: 1279px) {
      width: 270px;
    }
  }
  p {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
  }
  a {
    margin: auto 0 32px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 12px;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
`;
