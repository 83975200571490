import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 32px;
  margin-bottom: 32px;
  & > a {
    display: block;
  }
  @media screen and (max-width: 1279px) {
    place-content: center;
    margin: 32px 0;
    & > a {
      width: 100%;
    }
  }
`;

export const ScrollLoader = styled.div`
  border-left: 1px solid #8f8f8f;
  width: 0;
  height: 125px;
  margin: 60px auto;
`;
