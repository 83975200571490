import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { mensaActions } from "../../../../pages/Mensa/mensaSlice";
//style
import { List, ListItem, SelectWrapperWrapper } from "./style";
import {
  Select,
  SelectWrapper,
  WrapperImg,
} from "../../strumenti/toolBoxList/style";
import BottomIcon from "../../../assets/bottom";

function CafeteriaTabs() {
  const dispatch = useDispatch();

  const { selectedCategory, dishCategories } = useSelector(
    (state) => state.mensa
  );

  const handleClick = (type) => {
    // TODO implement mobile behaviour
    dispatch(mensaActions.setDishCategory(type));
  };
  const handleChange = (event) => {
    dispatch(mensaActions.setDishCategory(event.target.value));
  };

  return (
    <>
      {/* mobile */}
      <SelectWrapperWrapper>
        <SelectWrapper>
          <Select onChange={handleChange} noborder={true}>
            {React.Children.toArray(
              dishCategories.map((current) => (
                <option value={current}>{current}</option>
              ))
            )}
          </Select>
          <WrapperImg>
            <BottomIcon></BottomIcon>
          </WrapperImg>
        </SelectWrapper>
      </SelectWrapperWrapper>
      {/* desktop */}
      <List id="categories-dishes">
        {React.Children.toArray(
          dishCategories?.map((current) => (
            <ListItem
              className={selectedCategory === current && "selected"}
              onClick={() => handleClick(current)}
            >
              {current}
            </ListItem>
          ))
        )}
      </List>
    </>
  );
}

export default CafeteriaTabs;
