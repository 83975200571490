import { useDispatch } from "react-redux";
import { offerDetailActions } from "../../../../../pages/OfferDetail/offerDetailSlice";
import {
  Wrapper,
  CardHeader,
  CardUserName,
  CardCompany,
  CardInfo,
  CardLabel,
  Cardtext,
  CardButton,
  CardHeadingDetailsImg,
  CardHeadingDetailsInitialCircle,
  CardHeadingDetailsInitialText,
} from "./style";
function AdvContact({ data }) {
  const dispatch = useDispatch();
  const initialsName = data?.name?.charAt(0) + data?.surname?.charAt(0);
  return (
    <Wrapper>
      <CardHeader>
        {/* <img src="/img/dealer_profile.jpg" /> */}
        {data?.idPicture?.length > 0 ? (
          <CardHeadingDetailsImg src={data.idPicture} />
        ) : (
          <CardHeadingDetailsInitialCircle>
            <CardHeadingDetailsInitialText>
              {initialsName}
            </CardHeadingDetailsInitialText>
          </CardHeadingDetailsInitialCircle>
        )}
        <CardUserName>
          <div>
            <strong>
              {data?.name} {data?.surname}
            </strong>
          </div>
          <CardCompany>{data?.company}</CardCompany>
        </CardUserName>
      </CardHeader>
      <CardInfo>
        <CardLabel>{data?.emailLabel}</CardLabel>
        <Cardtext>{data?.email}</Cardtext>
      </CardInfo>
      <CardInfo>
        <CardLabel>{data?.telephoneLabel}</CardLabel>
        <Cardtext>{data?.phone}</Cardtext>
      </CardInfo>
      <CardButton
        onClick={() => dispatch(offerDetailActions.setContactModal(true))}
      >
        {data?.buttonLabel}
      </CardButton>
    </Wrapper>
  );
}
export default AdvContact;
