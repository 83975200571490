import {
  Container,
  TitleForm,
  GenericTitle,
  RecapContainer,
  Divider,
} from "./style";
import QrCode from "./qrCode/QrCode";
import DetailInfo from "./detailsInfo/DetailInfo";
import { ButtonsCta } from "./buttonsCta/buttonsCta";

export function LayoutRecapAndDetailMondiali({
  data,
  isRecapPage,
  buttonsData,
}) {
  const fullName = `${data?.subscription?.name} ${data?.subscription?.lastname}`;
  const userFullName = fullName?.replace(
    /\w+/g,
    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
  );

  function generateHtml(argument) {
    return { __html: argument };
  }

  return (
    <Container detail={!isRecapPage && data?.qrCode}>
      <GenericTitle>
        <h5>{isRecapPage ? data?.thankYouTitleLbl : data?.summaryTitleLbl}</h5>
      </GenericTitle>
      <RecapContainer detail={!isRecapPage}>
        <GenericTitle>
          {isRecapPage && (
            <p
              dangerouslySetInnerHTML={generateHtml(
                `${userFullName} ${data?.thankYouInfoLbl}`
              )}
            />
          )}
          {data?.subscription?.qrCode && <QrCode data={data} />}
          <p dangerouslySetInnerHTML={generateHtml(`${data?.richText}`)} />
        </GenericTitle>
      </RecapContainer>
      {!isRecapPage && data?.subscription?.qrCode && <Divider />}
      <RecapContainer detail={!isRecapPage}>
        <TitleForm>
          {isRecapPage ? data?.thankYouSubTitleLbl : data?.summarySubTitleLbl}
        </TitleForm>
        <DetailInfo data={data} />
      </RecapContainer>
      <ButtonsCta
        data={buttonsData}
        pageData={data}
        isRecapPage={isRecapPage}
      />
    </Container>
  );
}
