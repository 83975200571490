import React from "react";
import { CardWrapper, CardInfos, Info } from "./style";

function FamilyCards({ data }) {
  return (
    <>
      {Array.isArray(data) ? (
        React.Children.toArray(
          data?.map((card) => {
            return (
              <CardWrapper>
                <h4>{`${card?.name} ${card?.surname}`}</h4>
                <CardInfos>
                  <Info>
                    <div className="small">{card?.relationshipLabel}</div>
                    <div>{card?.relationship}</div>
                  </Info>
                  <Info>
                    <div className="small">{card?.dateLabel}</div>
                    <div>{new Date(card?.birthDate).toLocaleDateString()}</div>
                  </Info>
                  <Info>
                    <div className="small">{card?.sexLabel}</div>
                    <div>{card?.gender}</div>
                  </Info>
                </CardInfos>
              </CardWrapper>
            );
          })
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default FamilyCards;
