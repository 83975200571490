import linkTarget from "../../../../../utils/linkTarget";
import { Wrapper } from "./style";
function Location({ data }) {
  const google_Maps_link = `https://www.google.com/maps/search/?api=1&query=${data?.address}`;
  return (
    <Wrapper data-cs-mask>
      <img src="/img/map-pointer.svg" className="map-pointer" />
      <a href={google_Maps_link || "#"} target={linkTarget(google_Maps_link)}>
        {data?.address}
      </a>
    </Wrapper>
  );
}

export default Location;
