import { TagContainer, TagLabel } from "./style";
import { useSelector } from "react-redux";
import NewsTag from "./newsTag/NewsTag";
import { selectNewsDetailSettings } from "../../../../../pages/NewsDetail/selectors";

function NewsTagContainer() {
  const NEWS_TAG_NAME = "news-tag";
  const newsDetailsettings = useSelector(selectNewsDetailSettings());
  return (
    <TagContainer>
      {newsDetailsettings.length && <TagLabel>TOPIC:</TagLabel>}
      {newsDetailsettings?.cqTags?.map((current, idx) => (
        <NewsTag key={`${NEWS_TAG_NAME}-${idx}`} data={current} />
      ))}
    </TagContainer>
  );
}

export default NewsTagContainer;
