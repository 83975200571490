import { takeLatest, put } from "redux-saga/effects";
import { loginRequest, loginRequestInternal } from "../../authConfig";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_ACTIVE_TOKEN, FETCH_IS_USER_ACTIVE } from "./constants";
import { loginActions } from "./loginSlice";
import { InteractionStatus } from "@azure/msal-browser";

function* fetchActiveToken({ payload }) {
  const { instance, accounts, isInternal, inProgress, resolve } = payload;
  try {
    if (
      inProgress === InteractionStatus.None &&
      instance &&
      accounts?.length > 0
    ) {
      let activeToken;
      let tokenClaims;
      try {
        const loginParam = isInternal ? loginRequestInternal : loginRequest;
        const orderedAccounts = [...accounts].sort(
          (a, b) => b.idTokenClaims.auth_time - a.idTokenClaims.auth_time
        );

        const resp = yield instance.acquireTokenSilent({
          ...loginParam,
          account: orderedAccounts[0],
        });
        activeToken = resp.idToken;
        tokenClaims = resp.idTokenClaims;
      } catch (err) {
        console.log(err);
        if (err.errorMessage.includes("AADB2C90077")) {
          console.log("fetchActiveToken: redirect to iam");
          yield instance.loginRedirect(
            isInternal ? loginRequestInternal : loginRequest
          );
        }
      }

      if (activeToken) {
        yield put(loginActions.setActiveToken(activeToken));
        yield put(loginActions.setTokenClaims(tokenClaims));
        isInternal
          ? yield put(loginActions.setIsInternalIp(true))
          : yield put(loginActions.setIsInternalIp(false));
      } else {
        const errorToThrow = {
          status: 500,
          errorMessage: "Unable to retrieve a valid token",
        };
        throw errorToThrow;
      }
    }
  } catch (e) {
    console.error(e);
    yield put(loginActions.setError(e));
  }
  if (resolve) {
    resolve(true);
  }
}

function* fetchisUserActive({ payload: token }) {
  try {
    const result = yield axiosCallGet("/it/users/isActive", token);
    yield put(loginActions.setActiveUser(result?.data?.data));
  } catch (e) {
    console.error(e);
    if (e.reducedAccess) {
      yield put(loginActions.setReducedAcces(true));
      return;
    }
    const error = { status: e.status, errorMessage: e.message };
    yield put(loginActions.setError(error));
  }
}

export default function* eventsSaga() {
  yield takeLatest(FETCH_ACTIVE_TOKEN, fetchActiveToken);
  yield takeLatest(FETCH_IS_USER_ACTIVE, fetchisUserActive);
}
