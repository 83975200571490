import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 32px;
  gap: 16px;
  background: var(--alternative-bg-color);
`;

export const CardHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 100%;
`;

export const CardHeadingText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 32px 0px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1279px) {
    padding: 24px 24px 0px;
  }
`;

export const CardHeadingDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
`;

export const CardHeadingDetailsInitialCircle = styled.div`
  background: rgb(239, 237, 237);
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: flex;
  color: black;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
`;
export const CardHeadingDetailsInitialText = styled.div`
  width: 72px;
`;
export const CardHeadingDetailsUseful = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const CardHeadingDetailsUsefulName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
`;

export const CardHeadingDetailsUsefulInterno = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  @media (max-width: 1279px) {
    padding-left: 24px;
  }
`;

export const CardDetailsFrstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  @media (max-width: 1279px) {
    gap: 128px;
  }
`;

export const CardDetailsFrstRowFisso = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const CardDetailsFrstRowCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const CardLocation = styled.div`
  color: rgb(143, 143, 143);
  letter-spacing: 0.28125px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 11px;
`;

export const CardBody = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

export const HeadingDetailsLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  margin-left: auto;
  img {
    cursor: pointer;
  }
`;
