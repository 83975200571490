import React from "react";
// style
import { InputSection } from "./style";

function PersonalInfo({ data }) {
  return (
    <>
      <h3>{data?.title}</h3>
      <InputSection>
        <label>{data?.companyLabel}</label>
        <input type="text" defaultValue={data?.company} disabled />
      </InputSection>
      <InputSection>
        <label>{data?.managementLabel}</label>
        <input type="text" defaultValue={data?.direction} disabled />
      </InputSection>
      <InputSection>
        <label>{data?.costCenterLabel}</label>
        <input type="text" defaultValue={data?.costCenter} disabled />
      </InputSection>
    </>
  );
}

export default React.memo(PersonalInfo);
