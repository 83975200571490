import React from "react";
import { Link } from "react-router-dom";
// style
import {
  Wrapper,
  SpanCtaIcon,
  CtaCircle,
  CtaWrapper,
  Title,
  DescrWrapper,
  Description,
} from "./style";

function AsideCard({ data }) {
  // TBD fi-homepage-card-image --> items need to be an array of object
  return (
    <Wrapper>
      <img src={data.fileReference} width={384} height={505} />
      <DescrWrapper>
        <Title>{data.titleRightCard}</Title>
        <Description>{data.descriptionRightCard}</Description>
        <CtaWrapper>
          <Link to={data.linkHref || "#"}>
            <span>{data.textButtonRightCard}</span>
            <SpanCtaIcon>
              <CtaCircle>
                <svg viewBox="0 0 24 24">
                  <circle cx={12} cy={12} r={11} stroke={1} />
                </svg>
              </CtaCircle>

              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 16L11.2677 9.54697C11.7148 9.18016 12 8.62337 12 8C12 7.37663 11.7148 6.81984 11.2677 6.45304L4 0L8 8L4 16Z"
                  fill="white"
                />
              </svg>
            </SpanCtaIcon>
          </Link>
        </CtaWrapper>
      </DescrWrapper>
    </Wrapper>
  );
}

export default React.memo(AsideCard);
