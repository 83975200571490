import axios from "axios";

export async function axiosCallDownloadDoc(lang, url, filename, token) {
  let queryParam = url.split("?");
  let fullPath = queryParam[1]
    ? `${process.env.REACT_APP_BASE_MW_URL}/${lang}/documents/${filename}?path=${queryParam[0]}&${queryParam[1]}`
    : `${process.env.REACT_APP_BASE_MW_URL}/${lang}/documents/${filename}?path=${url}`;
  const result = await axios
    .get(fullPath, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    })
    .then((response) => {
      const fileURL = URL.createObjectURL(response.data);
      window.open(fileURL, "_blank");
      // // create file link in browser's memory
      // const href = URL.createObjectURL(response.data);
      // // create "a" HTML element with href to file & click
      // const link = document.createElement("a");
      // link.href = href;
      // //il deisderata è aprirlo in preview, il commento sotto è per scaricare il file
      // // link.setAttribute("download", filename); //or any other extension
      // link.setAttribute("target", "_blank");
      // document.body.appendChild(link);
      // link.click();

      // // clean up "a" element & remove ObjectURL
      // document.body.removeChild(link);
      // URL.revokeObjectURL(href);
    })
    .catch((e) => {
      console.error(e);
    });

  return result;
}

export async function axiosCallGet(url, token, isAService = false) {
  const urlToGet = isAService
    ? `${process.env.REACT_APP_BASE_MW_URL_SERVICES.concat(url)}`
    : `${process.env.REACT_APP_BASE_MW_URL.concat(url)}`;

  const result = await axios
    .get(urlToGet, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((e) => {
      if (url === "/it/users/isActive") {
        let error = new Error();
        error.message = e.response.data.message;
        error.reducedAccess = e.response.data.reducedAccess;
        throw error;
      } else {
        let errorData = { status: e.response.status, errorMessage: e.message };
        throw errorData;
      }
    });

  if (result.data.error) {
    let errorData = {
      status: result.data.status,
      errorMessage: result.data.statusText,
    };
    throw errorData;
  }

  return result;
}

export async function axiosCallGetPublic(url, isAService = false) {
  const urlToGet = isAService
    ? `${process.env.REACT_APP_BASE_MW_URL_SERVICES.concat(url)}`
    : `${process.env.REACT_APP_BASE_MW_URL.concat(url)}`;

  const result = await axios.get(urlToGet).catch((e) => {
    let errorData = { status: e.response.status, errorMessage: e.message };
    throw errorData;
  });

  if (result.data.error) {
    let errorData = {
      status: result.data.status,
      errorMessage: result.data.statusText,
    };
    throw errorData;
  }

  return result;
}

export async function axiosCallPost(
  url,
  body,
  token,
  isAService = false,
  checkForErrors = true
) {
  const urlToPost = isAService
    ? `${process.env.REACT_APP_BASE_MW_URL_SERVICES.concat(url)}`
    : `${process.env.REACT_APP_BASE_MW_URL.concat(url)}`;

  let result;

  if (checkForErrors) {
    result = await axios
      .post(urlToPost, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((e) => {
        let errorData = { status: e.response.status, errorMessage: e.message };
        throw errorData;
      });

    if (result.data.error) {
      let errorData = {
        status: result.data.status,
        errorMessage: result.data.statusText,
      };
      throw errorData;
    }
  } else {
    result = await axios.post(urlToPost, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  return result;
}

export async function axiosCallDelete(url, token, isAService = false) {
  const urlToDelete = isAService
    ? `${process.env.REACT_APP_BASE_MW_URL_SERVICES.concat(url)}`
    : `${process.env.REACT_APP_BASE_MW_URL.concat(url)}`;

  const result = await axios
    .delete(urlToDelete, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((e) => {
      let errorData = { status: e.response.status, errorMessage: e.message };
      throw errorData;
    });

  if (result.data.error) {
    let errorData = {
      status: result.data.status,
      errorMessage: result.data.statusText,
    };
    throw errorData;
  }

  return result;
}
