import React from "react";
import { SpanCtaIcon, CtaCircle, CtaWrapper } from "./style";
import PropTypes from "prop-types";
import linkTarget from "../../../utils/linkTarget";

const CtaIconFull = ({ link, description, position = "absolute" }) => {
  return (
    <CtaWrapper position={position}>
      <a
        href={link || "#"}
        className="flex-align-center"
        target={linkTarget(link)}
      >
        <span>{description}</span>
        <SpanCtaIcon>
          <CtaCircle>
            <svg viewBox="0 0 24 24">
              <circle cx={12} cy={12} r={11} stroke={1} />
            </svg>
          </CtaCircle>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 16L11.2677 9.54697C11.7148 9.18016 12 8.62337 12 8C12 7.37663 11.7148 6.81984 11.2677 6.45304L4 0L8 8L4 16Z"
              fill="#303030"
            />
          </svg>
        </SpanCtaIcon>
      </a>
    </CtaWrapper>
  );
};
CtaIconFull.propTypes = {
  style: PropTypes.object,
  onClickHandler: PropTypes.func,
  index: PropTypes.number,
  label: PropTypes.string,
};
export default CtaIconFull;
