import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  initEventInfo,
  setComponents,
  setError,
} from "./FormIscrizioneMondialiSlice";
import { showErrorMessage } from "../../utils/notifications/TriggerNotification";
import { FETCH_COMPONENTS, SET_USER_REGISTRATION } from "./constants";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    //   const result = yield call(() =>
    //   axios.get("/mocks/mock-iscrizione-mondiali.json")
    // );
    yield put(setComponents(result.data.data));
    const eventInfoData = result?.data?.data?.components?.find(
      (el) =>
        el.resourceType === "fi-events/fi-event-subscription-finali-mondiali"
    );

    if (eventInfoData?.data?.event?.error403) {
      yield put(setError({ status: 403, message: "Unauthorized" }));
      return;
    }

    yield put(initEventInfo(eventInfoData));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export function* SetUserRegistration({ payload }) {
  try {
    const token = payload.token;
    // const messageSuccess =
    //   payload.lang === "it"
    //     ? "Richiesta inviata con successo"
    //     : "Request sent successfully";
    document.body.classList.add("loading");
    yield axiosCallPost(
      `/${payload.lang}/news-and-events/events/subscribe?title=finali-mondiali`,
      payload.bodyPayload,
      token
    );
    // showSuccessMessage(messageSuccess);
    window.location.replace(payload.path);
  } catch (e) {
    const messageError =
      payload.lang === "it"
        ? "Errore nel salvataggio dati"
        : "Error while saving data";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(SET_USER_REGISTRATION, SetUserRegistration);
}
