import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it"; // the locale you want
import "./react-datepicker.css";
import DatePickerIcon from "../../assets/datepicker";
import { Wrapper, CloseButton } from "./style";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { DateTime } from "luxon";
import { parseISO, subDays } from "date-fns";

export default function DatePickerCustom({
  startDate,
  setStartDate,
  inputName,
  onChange,
  minDate,
  maxDate = null,
  peekNextMonth,
  showMonthDropdown,
  showYearDropdown,
  dropdownMode,
  selected,
}) {
  registerLocale("it", it);
  const { pageLight, language } = useSelector((state) => state.coreComponents);
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const [closeButtonDisplay, setCloseButtonDisplay] = useState("none");
  const setDate = (date) => {
    const dateIso = DateTime.fromJSDate(date).toISODate();
    setStartDate(dateIso);
    onChange(dateIso, inputName);
  };
  return (
    <>
      <CloseButton
        style={{ display: closeButtonDisplay }}
        onClick={(e) => {
          e.preventDefault();
          setCloseButtonDisplay("none");
          return false;
        }}
      >
        <img src="/img/close-light-news.svg" width="40" height="40" />
      </CloseButton>
      <Wrapper pageLight={pageLight}>
        <DatePickerIcon />
        <DatePicker
          locale={language}
          selected={selected ? parseISO(startDate) : undefined}
          onChange={setDate}
          name={inputName}
          minDate={minDate || subDays(new Date(), 0)}
          maxDate={maxDate}
          onCalendarOpen={() => setCloseButtonDisplay("block")}
          onCalendarClose={() => setCloseButtonDisplay("none")}
          dateFormat="yyyy-MM-dd"
          withPortal={!isDesktop}
          peekNextMonth={peekNextMonth}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode={dropdownMode ? "select" : false}
        />
      </Wrapper>
    </>
  );
}
