import React, { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// utils
import useMediaQuery from "../../hooks/useMediaQuery";
// components
import ResultSection from "../../components/UI/dialog/globalSearch/resultSection/ResultSection";
import NoContent from "../../components/UI/dialog/globalSearch/resultSection/noContent/NoContent";
import SuggestedItem from "../../components/UI/dialog/globalSearch/suggestedItem/SuggestedItem";
// actions
import { fetchResults } from "./actions";
import { globalSearchActions } from "./globalSearchSlice";
import TargetTrap from "../../utils/targetTrap";
// selectors
import { selectResultType, selectResultsByType } from "./selectors";
// style
import {
  MainWrapper,
  Overlay,
  Content,
  SearchBox,
  SearchInput,
  CloseButton,
  Suggested,
  Wrapper,
  Inputs,
} from "./style";

function GlobalSearchDialog() {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const token = useSelector((state) => state.login.activeToken);
  const { globalSearchComponents } = useSelector((state) => state.home);
  const isSearchActive = useSelector(
    (state) => state.globalSearch.isSearchActive
  );
  const resultType = useSelector(selectResultType());
  const currentResults = useSelector(selectResultsByType(resultType));

  const fromSearch = useMemo(() => true, []);
  const [isTyping, setIsTyping] = useState(false);
  const [word, setWord] = useState("");

  const closeButtonHandler = () => {
    dispatch(globalSearchActions.resetState());
    dispatch(globalSearchActions.setShowGlobalSearchDialog(false));
  };

  const removeFilterHandler = () => {
    setWord("");
    setIsTyping(false);
    dispatch(globalSearchActions.resetState(true));
  };

  const handleInputEvent = (e) => {
    setWord(e.currentTarget.value);
    if (e.currentTarget.value !== "") {
      setIsTyping(true);
    } else {
      setIsTyping(false);
      dispatch(globalSearchActions.setIsSearchActive());
    }
  };

  const handleSearchSubmit = (event) => {
    if (word.length >= 3 && event.keyCode === 13) {
      dispatch(fetchResults({ keyword: word, token }));
    }
  };
  const handleSubmitSearch = (e) => {
    e.preventDefault();
    if (word.length >= 3) {
      dispatch(fetchResults({ keyword: word, token }));
    }
  };

  const suggestedClickHandler = useCallback((suggestedRef) => {
    const word = suggestedRef.current.innerHTML;
    setWord(word);
    setIsTyping(true);
    dispatch(fetchResults({ keyword: word, token }));
  }, []);

  const HandleKeyPress = (e) => {
    switch (e.keyCode) {
      //Esc
      case 27:
        e.preventDefault();
        closeButtonHandler();
        document.getElementById("searchIcon").focus();
        break;
      case 9:
        TargetTrap(e, "searchDialog");
        break;
    }
  };

  const idSuggetionsList = () => {
    let result = [];
    for (let i = 0; i < globalSearchComponents?.items?.length; i++) {
      const idSuggestedItem = `suggestedItem_${i}`;
      result.push(idSuggestedItem);
    }
    return result;
  };

  const suggestionsList = idSuggetionsList();
  const HandleKeyPressOnSuggestions = (e) => {
    let index = suggestionsList.indexOf(e.target.id);
    const lastItemIndex = suggestionsList.length - 1;

    switch (e.keyCode) {
      //arrow up
      case 38:
        e.preventDefault();
        index <= 0
          ? document.getElementById("suggestedItem_" + lastItemIndex).focus()
          : document.getElementById("suggestedItem_" + --index).focus();
        break;
      //arrow down
      case 40:
        e.preventDefault();
        index >= lastItemIndex
          ? document.getElementById("suggestedItem_0").focus()
          : document.getElementById("suggestedItem_" + ++index).focus();
        break;
      case 32:
      case 13:
        e.preventDefault();
        setWord(e.target.textContent);
        setIsTyping(true);
        document.getElementById("searchInput").focus();
        break;
    }
  };

  const removeFilterKeyPressHandler = (e) => {
    switch (e.keyCode) {
      case 32:
      case 13:
        e.preventDefault();
        setWord("");
        setIsTyping(false);
        dispatch(globalSearchActions.resetState(true));
        document.getElementById("searchInput").focus();
        break;
    }
  };

  if (word === "" && currentResults) {
    removeFilterHandler();
  }

  return (
    <MainWrapper
      onKeyDown={(e) => HandleKeyPress(e)}
      role="dialog"
      aria-modal="true"
      id="searchDialog"
    >
      <Overlay></Overlay>
      <Content>
        {!isDesktop && (
          <img
            tabIndex="0"
            src="/img/logo-black.svg"
            width="40"
            height="40"
            className="mobile-logo"
          />
        )}
        <CloseButton
          tabIndex="0"
          aria-label="close"
          onClick={closeButtonHandler}
        >
          <img src="/img/close-light.svg" width="40" height="40" />
        </CloseButton>
        <Wrapper>
          <SearchBox>
            <Inputs color={word?.length >= 3 ? "true" : "false"}>
              <img
                onClick={handleSubmitSearch}
                src={
                  isTyping && word.length >= 3
                    ? "/img/Icon-Search-red.svg"
                    : "/img/Icon-Search-grey.svg"
                }
                width="16"
                height="16"
              />
              <SearchInput
                placeholder={globalSearchComponents.placeholder}
                onChange={(e) => handleInputEvent(e)}
                onKeyDown={handleSearchSubmit}
                id="searchInput"
                tabIndex="0"
                value={word}
                autoFocus
              />
              {isTyping && (
                <span
                  role="button"
                  tabIndex="0"
                  aria-label="search"
                  className="searchLabel"
                  onChange={handleInputEvent}
                  onKeyDown={handleSearchSubmit}
                  onClick={handleSubmitSearch}
                >
                  {globalSearchComponents?.searchLabel}
                </span>
              )}
              {isTyping && globalSearchComponents?.deleteLabel && (
                <span
                  role="button"
                  tabIndex="0"
                  aria-label="clear"
                  onKeyDown={(e) => removeFilterKeyPressHandler(e)}
                  onClick={removeFilterHandler}
                >
                  {globalSearchComponents.deleteLabel}
                </span>
              )}
            </Inputs>
          </SearchBox>
          {currentResults && <ResultSection currentResults={currentResults} />}
          {!currentResults && isSearchActive && (
            <NoContent title={globalSearchComponents?.noResultsText} />
          )}
          {!isSearchActive && (
            <Suggested>
              <h4>{globalSearchComponents.tipsLabel}</h4>
              {React.Children.toArray(
                globalSearchComponents?.items?.map((item, i) => {
                  const idSuggestedItem = `suggestedItem_${i}`;
                  return (
                    <SuggestedItem
                      item={item}
                      id={idSuggestedItem}
                      suggestedClickHandler={suggestedClickHandler}
                      suggestedKeyDownHandler={HandleKeyPressOnSuggestions}
                    />
                  );
                })
              )}
            </Suggested>
          )}
        </Wrapper>
      </Content>
    </MainWrapper>
  );
}

export default GlobalSearchDialog;
