import React from "react";
// components
import EventCard from "../eventCard/EventCard";
import DescrCard from "../descrCard/DescrCard";
import MensaCard from "../mensaCard/MensaCard";
import DescrCardBlack from "../descCardBlack/DescrCardBlack";
import AsideCarousel from "../asideCarousel/AsideCarousel";
import ImageCard from "../imageCard/ImageCard";
import ImageCardSmall from "../imageCardSmall/ImageCardSmall";
import NewsCard from "../newsCard/NewsCard";
import SingleEventCardContainer from "../singleEventCard/SingleEventCardContainer";

function CardList({ data }) {
  return (
    <>
      {data?.resourceType === "fi-homepage-main-sections" && (
        <>
          {data?.data?.nextEvents &&
          data?.data?.nextEvents?.events.length > 1 ? (
            <EventCard data={data.data.nextEvents} />
          ) : data?.data?.nextEvents?.events.length === 1 ? (
            <SingleEventCardContainer
              item={data?.data?.nextEvents?.events[0]}
              category={data?.data?.nextEvents?.category}
            />
          ) : (
            <SingleEventCardContainer
              item={data?.data?.nextEvents?.eventDefault}
            />
          )}

          {/* VECCHIA CARD RASSEGNA STAMPA, NON CANCELLARE */}
          {/* {data?.data?.pressReview && (
            <DescrCard data={data?.data?.pressReview} />
          )} */}
          {data?.data?.cardImageSmall && (
            <ImageCardSmall item={data?.data?.cardImageSmall} />
          )}
          {data?.data?.cafeteria && <MensaCard data={data?.data?.cafeteria} />}
        </>
      )}
      {data?.resourceType === "fi-homepage-comunications" && (
        <>
          <DescrCardBlack data={data?.data?.card} />
          <AsideCarousel data={data?.data?.carousel} />
        </>
      )}
      {data?.resourceType === "fi-homepage-card-image" && (
        <>
          {React.Children.toArray(
            data?.data?.items.map((item) => {
              return <ImageCard item={item} />;
            })
          )}
        </>
      )}
      {data?.resourceType === "fi-homepage-cards" && (
        <>
          {React.Children.toArray(
            data?.data?.items.map((card) => {
              return <NewsCard card={card} />;
            })
          )}
        </>
      )}
    </>
  );
}

export default React.memo(CardList);
