import styled from "styled-components";

export const Wrapper = styled.div`
  opacity: 0;
  transition: opacity 1s;
  position: relative;
  overflow: hidden;
  width: 100%;
  img {
    width: 100%;
  }
  @media (min-width: 769px) {
    width: 304px;
    box-sizing: border-box;
  }
  @media (min-width: 1280px) {
    width: 384px;
    height: 384px;
  }
  @media (max-width: 768px) {
    max-height: 310px;
  }

  img {
    display: block;
    position: relative;
    z-index: -1;
    transform: scale(1);
    transition: transform 0.6s;
  }
  &:hover img {
    transform: scale(1.1);
  }
`;

export const TitleAndTextWrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
`;

export const CardTitle = styled.div`
  font: 11px / 11px ferrari-sans-regular;
  color: #ffffff;
  width: 90%;
  margin: 32px 32px;
  @media (max-width: 769px) {
    margin: 24px;
  }
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  span:first-of-type {
    margin-right: 10px;
    margin-bottom: 2px;
  }
`;

export const DescrWrapper = styled.div`
  margin-left: 32px;
  position: absolute;
  bottom: 32px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  @media (max-width: 769px) {
    margin-left: 24px;
  }
  @media (max-width: 1279px) {
    bottom: 32px;
    width: 90%;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
  }
`;

export const Text = styled.div`
  width: 90%;
  @media (min-width: 1280px) {
    width: 320px;
  }
  height: 90px;
  font-weight: 400;
  font-size: 24px;
  @media (max-width: 1279px) {
    font-size: 16px;
    line-height: 24px;
    height: auto;
  }
  line-height: 30px;
  text-transform: uppercase;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  position: absolute;
  height: 40px;
  bottom: 45px;
  @media (max-width: 1279px) {
    bottom: 24px;
  }
`;

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(143, 143, 143);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  a:hover {
  }
`;

export const Mask1 = styled.div`
  position: absolute;
  width: 100%;
  @media (min-width: 1280px) {
    width: 384px;
  }
  height: 155px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 9.39%,
    rgba(25, 25, 25, 0.46) 57.61%,
    rgba(48, 48, 48, 0) 94.84%
  );
  transform: rotate(-180deg);
`;

export const Mask2 = styled.div`
  position: absolute;
  width: 100%;
  @media (min-width: 1280px) {
    width: 384px;
  }
  height: 301px;
  bottom: 0px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.65) 19.78%,
    rgba(48, 48, 48, 0) 83.93%
  );
  transform: rotate(-180deg);
`;
