import { ComponentContainer } from "../../style";
//components
import ErrorField from "../ErrorField/ErrorField";
import InputRadioButton from "../RadioButton/RadioButton";

export default function SedeDiLavoro({ data, onChange, check, allowed }) {
  const locations = [
    { text: data?.locationMaranello, value: "Maranello", id: "mar0" },
    { text: data?.locationMilano, value: "Milano", id: "mil0" },
    { text: data?.locationModena, value: "Modena", id: "mod0" },
  ];

  return (
    <ComponentContainer>
      <h3>{data?.titleLocation}</h3>
      <InputRadioButton
        description={data?.textLocation}
        array={locations}
        name={`SedeLavoro`}
        checked={check}
        onChange={onChange}
      />
      {check == null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
