import { useNavigate } from "react-router-dom";
import { Wrapper } from "./style";

function ImageTitle({ data }) {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`${data.linkHref}`);
  };

  return (
    <Wrapper onClick={handleOnClick}>
      <h3>{data.title}</h3>
      <img src={data.fileReference} />
    </Wrapper>
  );
}

export default ImageTitle;
