import styled from "styled-components";

export const CardWrapper = styled.div`
  border: 1px solid rgb(212, 210, 210);
  padding: 32px;
  &:not(:first-of-type) {
    margin-top: 32px;
    @media (max-width: 1279px) {
      padding: 22px;
    }
  }
`;

export const CardInfos = styled.div`
  display: flex;
  gap: 48px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 24px;
  gap: 7px;
  font-size: 14px;
  @media (max-width: 1279px) {
    padding: 0px !important;
  }
  div.small {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
  }
`;
