import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 10;
  display: ${(props) => (props.showDialog ? "flex" : "none")};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  @media (max-width: 1279px) {
  }
`;

export const Overlay = styled.div`
  background-color: rgba(43, 46, 56, 0.9);
  position: fixed;
  inset: 0px;
`;

export const CloseButton = styled.button`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  border: 0px;
  cursor: pointer;
  top: 10px;
  z-index: 3;
  @media (max-width: 1279px) {
    transform: none;
    position: static;
    margin: 10px 0;
  }
`;
export const Content = styled.div`
  margin: 72px auto 0;
  z-index: 2;
  width: 50%;
  max-height: calc(100vh - 102px);
  overflow: auto;
  background: rgb(255, 255, 255);
  @media (max-width: 1279px) {
    height: 100%;
    width: 100%;
    max-height: 100%;
    margin: 0 auto 0;
    text-align: center;
  }
`;
export const ContentWrapper = styled.div`
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 54px;
  padding: 58px 0px 64px;
  align-items: center;
  @media (max-width: 1279px) {
    padding: 32px 0;
  }
`;

export const InputMask = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 64px;
  display: flex;
  flex-wrap: wrap;
  gap: 38px 32px;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  color: rgb(112, 112, 112);

  select {
    color: black !important;
    width: 100%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    appearance: none;
    padding: 12px 0px 14px;
    padding-right: 30px;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  select:invalid {
    color: gray;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    pointer-events: none;
  }
  option {
    padding: 0px;
    max-width: 100%;
  }
`;

export const Input = styled.div`
  width: calc(50% - 32px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 24px;
  gap: 7px;
  @media (max-width: 1279px) {
    width: 100%;
  }
  input {
    color: black !important;
    margin-top: 22px;
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 7px 0px;
    color: rgb(48, 48, 48);
    max-width: 100%;
  }

  input::placeholder {
    color: #8f8f8f;
  }
`;

const actionButtonProperties = (isSearchButtonEnabled) => {
  if (isSearchButtonEnabled) {
    return `
    text-transform: uppercase;
    font-size: 12px;
    padding: 20px 0px;
    max-width: 240px;
    width: 100%;
    cursor: pointer;
    color: rgb(255, 255, 255);
    border: 0px;
    background: radial-gradient(
        199.89% 50% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        349.37% 116.89% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      rgb(218, 41, 28);
    background-blend-mode: overlay, overlay, normal;
    `;
  }
  return `
    text-transform: uppercase;
    font-size: 12px;
    max-width: 240px;
    width: 100%;
    padding: 20px 0px;
    border: 0px;
    background: #EFEDED;
    color: #D4D2D2;
  `;
};

export const SearchBtn = styled.button`
  ${(props) => actionButtonProperties(props.isSearchButtonEnabled)};
`;

export const InputLabel = styled.label`
  font-size: 11px;
  color: rgb(112, 112, 112);
  margin: 0px;
  display: none;
  position: absolute;
`;
