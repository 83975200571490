import { useSelector } from "react-redux";
import { GreyPopup, InfoCircle, InfoIcon, InfoImg } from "./style";

export default function PopupConiugi({ text }) {
  const theme = useSelector((state) => state.coreComponents.pageLight);
  return (
    <GreyPopup color={theme}>
      <InfoIcon>
        <InfoCircle />
        <InfoImg />
      </InfoIcon>
      <p>{text}</p>
    </GreyPopup>
  );
}
