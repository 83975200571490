import styled from "styled-components";

export const PrivacyRules = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin-top: 20px;
`;

export const InputSectionCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  padding: 0px 0px 18px;
  gap: 12px;
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid var(--main-color);
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    padding: 0;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--main-color);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  span {
    max-width: calc(100% - 28px);
    & a {
      color: var(--main-color);
      text-decoration: revert;
    }
  }
`;

export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 32px;
`;
