import { createSlice, current } from "@reduxjs/toolkit";

export const MANAGEMENT_ECARD_SCOPE = "businessCardManagement";

export const initialState = {
  components: [],
  settings: {},
  status: null,
  errorMessage: "",
  tabIndex: 0,
  tabsArray: [],
  showModal: false,
  toApproveInitCards: [],
  userInfo: {},
  editActiveOnCardId: "",
};

const BusinessCardManagementSlice = createSlice({
  name: MANAGEMENT_ECARD_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;

      const toApproveCards = state.components.find(
        (el) => el.resourceType === "fi-ecard-approval/fi-ecard-approval-tabs"
      )?.data?.toApprove?.items;

      state.toApproveInitCards = toApproveCards;
    },
    setSettings(state, action) {
      state.settings = action.payload;
    },
    setTabsArray(state, action) {
      state.tabsArray = action.payload;
    },
    updateCard(state, action) {
      const cardInfo = action.payload;

      let foundIndex = state.tabsArray[0]?.components?.items?.findIndex(
        (el) => el._id === cardInfo._id
      );

      state.tabsArray[0].components.items[foundIndex] = cardInfo;
    },
    updateTabsArray(state, action) {
      const userCardToMove = action.payload;
      const tabsArrayToUpdate = JSON.parse(
        JSON.stringify(current(state.tabsArray))
      );

      tabsArrayToUpdate[0].components.items =
        tabsArrayToUpdate[0]?.components?.items?.filter(
          (el) => el.userId !== userCardToMove.userId
        );

      if (userCardToMove.status === 1) {
        tabsArrayToUpdate[1]?.components?.items?.unshift(userCardToMove);
      } else {
        tabsArrayToUpdate[2]?.components?.items?.unshift(userCardToMove);
      }

      state.tabsArray = tabsArrayToUpdate;
    },
    setTabsIndex(state, action) {
      state.tabIndex = action.payload;
      state.editActiveOnCardId = "";
    },
    setEditActiveOnCardId(state, action) {
      state.editActiveOnCardId = action.payload;
    },
    setUserInfoStatus(state, action) {
      state.userInfo.status = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload.cardInfo;
      const currentInfo = action.payload.cardInfo;
      const toApproveInitCards = current(state.toApproveInitCards);

      const initUserInfo = toApproveInitCards?.find((el) => {
        if (el.userId === currentInfo.userId) return el;
      });

      let editByReviewer = false;
      if (!action.payload.rejected) {
        editByReviewer =
          initUserInfo.email === currentInfo.email &&
          initUserInfo.depAndJobPos === currentInfo.depAndJobPos &&
          initUserInfo.corporateAddress === currentInfo.corporateAddress &&
          initUserInfo.company === currentInfo.company &&
          initUserInfo.mobilePhone === currentInfo.mobilePhone &&
          initUserInfo.phone === currentInfo.phone &&
          initUserInfo.fax === currentInfo.fax
            ? false
            : true;
      }

      state.userInfo = { ...currentInfo, editByReviewer: editByReviewer };
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
  },
});

export const {
  setComponents,
  setSettings,
  setError,
  setStatusNull,
  fetchComponents,
  setTabsArray,
  setTabsIndex,
  setShowModal,
  setUserInfo,
  updateTabsArray,
  setUserInfoStatus,
  setEditActiveOnCardId,
  updateCard,
} = BusinessCardManagementSlice.actions;
export default BusinessCardManagementSlice;
