import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  background: var(--alternative-bg-color);
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
  }
`;

export const CardUserName = styled.div`
  margin-left: 20px;
  line-height: 25px;
`;

export const CardCompany = styled.div`
  font-size: 13px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 5px;
`;

export const CardLabel = styled.div`
  color: rgb(143, 143, 143);
  letter-spacing: 0.28125px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 11px;
`;

export const Cardtext = styled.div`
  display: block;
`;

export const CardButton = styled.button`
  width: 100%;
  padding: 20px;
  cursor: pointer;
  color: var(--main-color);
  background: transparent;
  border: 1px solid var(--main-color);
  outline: none;
  font-family: ferrari-sans-regular, sans-serif;
`;

export const CardHeadingDetailsInitialCircle = styled.div`
  background: rgb(239, 237, 237);
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: flex;
  color: black;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
`;
export const CardHeadingDetailsInitialText = styled.div`
  width: 72px;
`;

export const CardHeadingDetailsImg = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 100%;
`;
