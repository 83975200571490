import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 24px;
  background: var(--alternative-bg-color);
  width: 384px;
  box-sizing: border-box;

  & > div {
    margin-bottom: 0;
  }
  svg path {
    fill: var(--main-color);
  }
  & > h3 {
    margin: 0;
  }
  & > div:nth-child(3) {
    margin-top: auto;
  }
  @media screen and (max-width: 1279px) {
    width: 100%;
    border-bottom: 2px solid rgb(218, 41, 28);
    flex-direction: row;
    align-items: center;
    & h3 {
      font-size: 13px;
    }
    & > div:nth-child(3) {
      margin-top: 0;
      margin-left: auto;
    }
    & a > span:nth-child(1) {
      display: none;
    }
  }
`;
export const Text = styled.div`
  font-size: 11px;
  line-height: 20px;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;
