import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;

  img {
    max-width: 354px;
    height: 230px;
    &:first-child {
      margin-top: 0px;
    }
  }
`;

export const SpanExpiringDate = styled.span`
  color: #8f8f8f;
  font-size: 11px;
`;
