import styled from "styled-components";

export const Container = styled.section`
  width: 600px;
  margin: 0 auto 96px;
  ${(props) =>
    props.center
      ? "display: flex; flex-direction:column; align-items: center"
      : "display: block"};
  h2 {
    margin: 96px 0 24px;
    text-align: center;
  }
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input {
    letter-spacing: 0.5px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 1279px) {
    width: 90%;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;
export const TitleForm = styled.h2`
  max-width: 800px;
  font-size: 36px;
  font-family: ferrari-sans-medium, sans-serif;
  text-align: center;
  line-height: 34px;
  display: flex;
  flex-direction: column;
  align-self: center;
  div {
    text-align: center;
    p {
      line-height: 20px;
      margin-top: 30px;
      font-family: ferrari-sans-regular, sans-serif;
      font-size: 13px;
      font-weight: 400;
    }
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;

export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;
  &.activeSubmit {
    color: rgb(255, 255, 255);
    background: radial-gradient(
        199.89% 50% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        349.37% 116.89% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      rgb(218, 41, 28);
  }

  @media (max-width: 1279px) {
    max-width: 100%;
  }
`;

export const ComponentContainer = styled.div`
  h3 {
    text-transform: uppercase;
    span {
      text-transform: lowercase;
      color: #8f8f8f;
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
`;
export const RadioButton = styled.div`
  margin-bottom: 18px;
  text-transform: uppercase;
  label {
    vertical-align: middle;
  }
  input {
    vertical-align: middle;
    margin-right: 8px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    &:checked {
      outline: 0.1px solid black;
      background-color: #000;
      appearance: none;
      margin: 3px 8px 0px 5px;
      padding: 6px;
      box-shadow: inset 0 0 0 2.5px #ffff;
    }
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0px 0px 0px;
  gap: 7px;
  position: relative;
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
  }
  input {
    background: transparent;
    width: 100%;
    border: none;
    font-size: 14px;
    padding: 7px 0px;
  }
  input:not(:placeholder-shown) + label {
    opacity: 1;
  }
`;

export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 32px;
`;
export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;

export const RedirectButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
