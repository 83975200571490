import styled from "styled-components";

export const ButtonAlert = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  background-color: rgb(218, 41, 28);
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 5e-5) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    #da291c;
  background-blend-mode: overlay;
  padding: 20px 0px;
  width: 100%;
`;

export const MeghaponeIcon = styled.span`
  padding-left: 16px;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  @media (max-width: 1279px) {
    flex-direction: column;
    align-items: start;
    gap: 6px;
  }
`;

export const DateAlert = styled.span`
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 2px;
  @media (max-width: 1279px) {
    margin-top: 0;
  }
`;

export const TextAlert = styled.span`
  font-size: 16px;
`;

export const CloseButton = styled.span`
  float: right;
  margin-left: auto;
  margin-right: 16px;
  cursor: pointer;
`;
