import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  resultType: null,
  results: [],
  titlesMap: [],
  showGlobalSearchDialog: false,
  isSearchActive: false,
};

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {
    setResultType(state, action) {
      state.resultType = action.payload;
    },
    setResults(state, action) {
      state.results = action.payload;
      state.isSearchActive = true;
    },
    setIsSearchActive(state, action) {
      state.isSearchActive = false;
    },
    setTitlesMap(state, action) {
      state.titlesMap = action.payload;
    },
    setShowGlobalSearchDialog(state, action) {
      state.showGlobalSearchDialog = action.payload;
      // document
      //   .getElementById("searchIcon")
      //   .setAttribute("aria-expanded", action.payload);
    },
    resetState: (_, action) => {
      return {
        resultType: null,
        results: [],
        titlesMap: [],
        showGlobalSearchDialog: action.payload,
        isSearchActive: false,
      };
    },
  },
});

export const globalSearchActions = globalSearchSlice.actions;
export default {
  name: globalSearchSlice.name,
  reducer: globalSearchSlice.reducer,
};
