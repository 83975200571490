import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 1279px) {
    width: 100%;
    .carousel .slide img {
      height: 356px;
      width: auto;
      object-fit: cover;
    }
    .carousel .carousel-dot {
      background: var(--main-color) !important;
    }
  }
  @media (min-width: 1279px) {
    .carousel .carousel-dot {
      background: #fff !important;
    }
  }
  .slide .home-text-animation {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 0.7s;
  }
  .slide.selected .home-text-animation {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 1s;
  }
`;
export const Text1 = styled.div`
  position: absolute;
  height: 20px;
  left: 32px;
  top: 32px;
  display: flex;
  @media (max-width: 769px) {
    left: 24px;
    top: 24px;
  }

  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  /* identical to box height, or 182% */

  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 2;
  /* Grey Scale/White */

  color: #ffffff;
  span:first-of-type {
    margin-right: 10px;
    margin-bottom: 2px;
  }
`;

export const Text2WrapperWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  z-index: 2;
  position: absolute;
  width: 768px;
  height: 140px;
  left: 32px;
  bottom: 80px;
  @media (max-width: 1279px) {
    bottom: 64px;
    left: 32px;
    top: auto;
    width: auto;
    height: auto;
  }
  @media (max-width: 769px) {
    bottom: 64px;
    left: 24px;
    top: auto;
    width: auto;
    height: auto;
  }
`;

export const Text2Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const Chapter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
`;
export const Frame = styled.div`
  width: 96px;
  height: 1px;

  /* Grey Scale/White */

  background: #ffffff;
`;
export const Text2Title = styled.div`
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  text-align: left;
  color: #ffffff;
  @media (max-width: 1279px) {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Gradient1 = styled.div`
  position: absolute;
  left: 0%;
  top: 0;
  width: 333px;
  height: 333px;
  background: radial-gradient(
      68.96% 68.96% at 100% 0%,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.5) 49.53%,
      rgba(0, 0, 0, 0.0001) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  transform: rotate(-90deg);
`;
export const Gradient2 = styled.div`
  position: absolute;
  left: 0%;
  bottom: 0;
  width: 100%;
  height: 333px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.4) 62.97%,
    rgba(0, 0, 0, 0.0001) 100%
  );
`;
