import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  date: "",
  showModal: false,
  status: null,
  errorMessage: "",
};

const companyMapSlice = createSlice({
  name: "companyMap",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
  },
});

export const companyMapActions = companyMapSlice.actions;
export default {
  name: companyMapSlice.name,
  reducer: companyMapSlice.reducer,
};
