//import "./LayoutMensa.css";
import { Container } from "./style";
import PropTypes from "prop-types";
function LayoutHome({ children }) {
  return (
    <>
      <Container>{children}</Container>
    </>
  );
}
LayoutHome.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default LayoutHome;
