import PropTypes from "prop-types";
// style
import { Container, Image } from "./style";

function EventImage({ elementData }) {
  return (
    <Container>
      <Image src={elementData.fileReference} />
    </Container>
  );
}

export default EventImage;
