// components
import CategoryTags from "../categoryTags/CategoryTags";
import Location from "../location/Location";
import ImgList from "../imgList/ImgList";

function Offer({ data }) {
  return (
    <>
      <CategoryTags data={data.item} properties={data.properties} />
      <Location data={data.item} />
      <ImgList data={data.item} />
    </>
  );
}

export default Offer;
