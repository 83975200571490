import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  @media (max-width: 1279px) {
  }
`;

export const Overlay = styled.div`
  background-color: rgba(43, 46, 56, 0.9);
  position: fixed;
  inset: 0px;
`;

export const CloseButton = styled.button`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  border: 0px;
  cursor: pointer;
  top: 135px;
  z-index: 3;
  @media (max-width: 1279px) {
    transform: none;
    position: static;
    margin: 150px 0 20px;
  }
`;
export const Content = styled.div`
  margin: 200px auto 0;
  z-index: 2;
  width: 50%;
  overflow: auto;
  @media (max-width: 1279px) {
    height: 100%;
    width: 100%;
    max-height: 100%;
    margin: 0 auto 0;
    text-align: center;
  }
`;
