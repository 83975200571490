import { SelectWrapper, WrapperImg } from "./style";
import BottomIcon from "../../assets/bottom";
import { useState } from "react";
import Select, { components } from "react-select";
function CustomSelectPlugin({
  options,
  onChange,
  placeholderString = null,
  name = "",
  defaultValue = "",
  labelString = "",
}) {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <WrapperImg>
          <BottomIcon></BottomIcon>
        </WrapperImg>
      </components.DropdownIndicator>
    );
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      border: "1px solid #707070",
      borderRadius: "none",
      font: "11px Ferrari-sans-regular",
      padding: "16px 12px",
      color: "var(--main-color)",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "var(--main-color)",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      backgroundColor: "#F7F7F7",
      font: "11px Ferrari-sans-regular",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused || isSelected ? "#D4D2D2" : "transparent",
        color: "#303030",
        padding: "16px",
      };
    },
    placeholder: (base) => ({
      ...base,
      color: "var(--main-color)",
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };
  return (
    <SelectWrapper hideLabel={!defaultValue}>
      <Select
        options={options}
        onChange={onChange}
        components={{
          DropdownIndicator,
        }}
        name={name}
        defaultValue={defaultValue}
        value={defaultValue}
        styles={customStyles}
        placeholder={placeholderString}
        classNamePrefix="react-select"
      />
      {labelString && <label>{labelString}</label>}
    </SelectWrapper>
  );
}

export default CustomSelectPlugin;
