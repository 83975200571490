import styled from "styled-components";

export const TextWrapper = styled.div`
  font-size: 13px;
  line-height: 20px;
  h2 {
    margin-bottom: 24px;
  }
  @media screen and (min-width: 1280px) {
    max-width: 697px;
    margin-top: 96px;
    margin-bottom: 48px;
  }
`;
