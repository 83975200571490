import { Card, Description } from "./style";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import { useSelector } from "react-redux";

function NewsCard({ newsCardProps, isLoadingFunc }) {
  const [loaded, setLoaded] = useState(false);
  const today = Date.now();
  const thisYear = new Date(today).getFullYear();
  const newsYear = new Date(newsCardProps.createdDate).getFullYear();
  const lang = useSelector((state) => state.coreComponents.language);
  const locale = lang === "it" ? "it-IT" : "en-EN";
  const d = new Date(newsCardProps.createdDate);
  const options =
    thisYear !== newsYear
      ? { month: "short", day: "numeric", year: "numeric" }
      : { month: "short", day: "numeric" };
  const newsDate = d.toLocaleDateString(locale, options);
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <Link
      style={!loaded ? {} : { opacity: "1" }}
      to={
        newsCardProps?.path
          ? "/news/news-detail?path=" + newsCardProps.path
          : "#"
      }
    >
      <Card>
        {isDesktop ? (
          newsCardProps.image ? (
            <img
              src={newsCardProps.image}
              onLoad={() => {
                setLoaded(true);
                isLoadingFunc();
              }}
            />
          ) : (
            <img
              src="/img/logo-white.svg"
              style={{ height: "216px" }}
              onLoad={() => {
                setLoaded(true);
                isLoadingFunc();
              }}
            />
          )
        ) : newsCardProps.fileReferenceMobile ? (
          <img
            src={newsCardProps.fileReferenceMobile}
            onLoad={() => {
              setLoaded(true);
              isLoadingFunc();
            }}
          />
        ) : newsCardProps.image ? (
          <img
            src={newsCardProps.image}
            onLoad={() => {
              setLoaded(true);
              isLoadingFunc();
            }}
          />
        ) : (
          <img
            src="/img/logo-white.svg"
            style={{ height: "216px" }}
            onLoad={() => {
              setLoaded(true);
              isLoadingFunc();
            }}
          />
        )}

        <Description>
          <span>{newsDate}</span>
          <span />
          <span>{newsCardProps.category}</span>
        </Description>
        <h3 style={{ fontWeight: 400 }}>{newsCardProps.title}</h3>
      </Card>
    </Link>
  );
}
NewsCard.propTypes = {
  newsCardProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    path: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    createdDate: PropTypes.number.isRequired,
    lastModifiedDate: PropTypes.number,
    lastReplicatedDate: PropTypes.number,
    category: PropTypes.string.isRequired,
    tags: PropTypes.array,
  }),
};
export default NewsCard;
