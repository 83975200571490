import styled from "styled-components";

export const DocumentsWrapper = styled.div`
  background: var(--alternative-bg-color);
  padding: 24px;
  @media (min-width: 1280px) {
    width: 384px;
    box-sizing: border-box;
    min-width: 384px;
  }
  @media (max-width: 1279px) {
    width: 100%;
    box-sizing: border-box;
  }
  .doc-container {
    margin: 0;
  }
  .doc-container:last-child {
    border: 0;
  }
  .doc-container__title {
    font-size: 18px;
    line-height: 26px;
  }
  .doc-title-and-data {
    margin-left: 0;
  }
  .doc-title-and-data h4 {
    margin-top: 0;
  }
`;

export const DocContainer = styled.div`
  margin: 40px 0px 32px;
  img {
    display: none;
  }
  path:not(.doc-download-path) {
    fill: var(--main-color);
  }
  @media screen and (min-width: 1280px) {
    border-bottom: 1px solid rgb(212, 210, 210);
    margin: 96px 0px;
    img {
      display: block;
    }
  }
  @media screen and (max-width: 1279px) {
    margin: 32px 0px;
    width: auto;
  }
`;

export const DocContainerTitle = styled.div`
  margin-bottom: 32px;
  color: var(--main-color);
  text-transform: uppercase;
  font-size: 12px;
`;
