import { Container } from "./style";
import SetQueryStringPath from "../../../../../../../utils/setQueryStringPath";
import linkTarget from "../../../../../../../utils/linkTarget";

function FiButton({ elementData }) {
  return (
    <Container>
      <a
        href={SetQueryStringPath(elementData.link)}
        target={linkTarget(elementData.link)}
      >
        {elementData.title}
      </a>
    </Container>
  );
}

export default FiButton;
