import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1280px) {
    width: 100%;
    box-sizing: border-box;
    padding-right: 120px;
    padding-left: 135px;
  }
`;
