import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { fetchComponents } from "./actions";
import { selectEventsComponents } from "./selectors";
import { selectAggregatedDirection } from "../MyPage/selectors";

import LayoutEvents from "../../components/UI/events/LayoutEvents";
import MainEvent from "../../components/UI/events/mainEvent/MainEvent";
import EventsFocus from "../../components/UI/events/eventsFocus/EventsFocus";
import EventsCardList from "../../components/UI/events/eventsCardList/EventsCardList";
import WebinarCardList from "../../components/UI/events/webinarCardList/WebinarCardList";
import GoToAllEvents from "../../components/UI/events/goToAllEvents/GoToAllEvents";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";

function Events({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const eventsComponents = useSelector(selectEventsComponents());
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const componentsMapping = {
    "fi-events/fi-events-hero": MainEvent,
    "fi-events/fi-events-featured": EventsFocus,
    "fi-events/fi-events-discover": EventsCardList,
    "fi-events/fi-events-webinar": WebinarCardList,
  };

  const { status, errorData } = GetStatusAndErrorData("events");

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <>
          {token && eventsComponents?.components && (
            <LayoutEvents>
              {eventsComponents?.components?.map((current, idx) => {
                const ReactGenericComponent =
                  componentsMapping[current.resourceType];
                return (
                  ReactGenericComponent && (
                    <ReactGenericComponent
                      key={`${current.resourceType}-${idx}`}
                      data={current.data}
                    ></ReactGenericComponent>
                  )
                );
              })}
            </LayoutEvents>
          )}
        </>
      )}
    </>
  );
}

export default Events;
