import PropTypes from "prop-types";
// style
import { Wrapper } from "./style";
// components
import AdminInfo from "./adminInfo/AdminInfo";
import FamilyCards from "./familyCards/FamilyCards";

function Family({ components: familyComponents }) {
  return (
    <Wrapper>
      <AdminInfo data={familyComponents?.infoAmministratore} />
      <FamilyCards data={familyComponents?.cardFamiliare} />
    </Wrapper>
  );
}

Family.propTypes = {
  title: PropTypes.string,
  infoAmministratore: PropTypes.object,
  cardFamiliare: PropTypes.arrayOf(PropTypes.object),
};

export default Family;
