import styled from "styled-components";

export const Card = styled.div`
  max-width: 384px;
  position: relative;
  @media screen and (max-width: 1279px) {
    width: 384px;
    margin: auto;
    margin-top: 32px;
  }
`;

export const Description = styled.div`
  margin: 20px 0px;
  color: rgb(230, 72, 61);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
`;
export const TitleAndText = styled.div`
  width: 384px;
  height: 115px;
  margin-top: 21px;
  @media screen and (max-width: 1279px) {
    text-align: left;
  }
`;
export const Title = styled.div`
  width: 384px;
  font-family: ferrari-sans-medium;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 15px;
  color: #181818;
`;

export const Text = styled.div`
  width: 383px;
  height: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #181818;

  flex: none;
  order: 1;
  flex-grow: 0;
`;
