import styled from "styled-components";

export const Title = styled.h4`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Text = styled.div`
  font-size: 11px;
  line-height: 20px;
  p:nth-child(1) {
    margin-top: 0px;
  }
  p:last-child {
    margin-bottom: 0px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 13px;
  }
`;
