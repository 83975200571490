import React, { useState, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
// actions
import { sendEmail } from "../../../../pages/ContactUs/actions";
// components
import ModalForm from "./modalForm/ModalForm";
// style
import { Text, FormWrapper, SelectContainer, SendButton } from "./style";

function ContactForm({ data }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);

  const [openModal, setOpenModal] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [activeTextArea, setActiveTextArea] = useState(false);

  const selectOptionRef = useRef();
  const textAreaRef = useRef();
  const container = document.getElementById("contact-form");

  const enableTextArea = () => {
    if (selectOptionRef.current.value !== data?.form?.placeholder) {
      setActiveTextArea(true);
    } else setActiveTextArea(false);
  };

  const enableButton = () => {
    if (textAreaRef.current.value !== "") {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  };

  const handleSubmitButton = () => {
    // call the logic for sending email based on the select and textarea
    dispatch(
      sendEmail({
        emailTo: selectOptionRef.current.value,
        subject:
          selectOptionRef.current.options[selectOptionRef.current.selectedIndex]
            .text,
        message: textAreaRef.current.value,
        token,
      })
    );
    setOpenModal(true);
  };

  const handleCloseModal = useCallback(
    (event) => {
      event.preventDefault();
      // reset the state of the select and the textarea to empty
      textAreaRef.current.value = "";
      selectOptionRef.current.value = data?.form?.placeholder;
      setOpenModal(false);
      setActiveTextArea(false);
      setActiveButton(false);
    },
    [data]
  );

  return createPortal(
    <>
      <Text dangerouslySetInnerHTML={{ __html: data?.textInfoForm }} />
      <FormWrapper>
        <SelectContainer>
          <select
            defaultValue={data?.form?.placeholder}
            ref={selectOptionRef}
            onChange={enableTextArea}
          >
            <option disabled>{data?.form?.placeholder}</option>
            {React.Children.toArray(
              data?.form?.items?.map((op) => (
                <option value={op.value}>{op.title}</option>
              ))
            )}
          </select>
          <img src="/img/arrow-down.svg" alt="arrow-down" />
        </SelectContainer>
        {activeTextArea && (
          <textarea
            placeholder={data?.form?.textPlaceholder}
            ref={textAreaRef}
            rows={10}
            onChange={enableButton}
          />
        )}
        <SendButton onClick={handleSubmitButton} disabled={!activeButton}>
          {data?.form?.textButton}
        </SendButton>
      </FormWrapper>
      {openModal && (
        <ModalForm data={data?.modal} closeModal={handleCloseModal} />
      )}
    </>,
    container
  );
}

export default React.memo(ContactForm);
