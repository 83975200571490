import { createSlice } from "@reduxjs/toolkit";

export const SIMPLE_EVENTS_FORM_ISCRIZIONE_SCOPE = "SimpleEventsSubscribeForm";

export const initialState = {
  components: {},
  checkedConsents: [],
  status: null,
  errorMessage: "",
  showRecap: false,
  resultPost: [],
  defaultUserInfo: {},
};

const SimpleEventsSubscribeForm = createSlice({
  name: SIMPLE_EVENTS_FORM_ISCRIZIONE_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setEventInfo(state, action) {
      state.defaultUserInfo = action.payload;
    },
    setShowRecap(state, action) {
      state.showRecap = action.payload;
    },
    setCheckedConsents(state, action) {
      state.checkedConsents = action.payload;
    },
    initEventInfo(state, action) {
      const checkList = action.payload;

      state.defaultUserInfo = {
        checklist: checkList,
        type: "eventi-semplici",
        events: [],
      };
    },
    setResultPost(state, action) {
      state.resultPost = action.payload;
      state.showRecap = true;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormDataSimpleEvents: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  setEventInfo,
  setCheckedConsents,
  initEventInfo,
  setShowRecap,
  setResultPost,
  resetFormDataSimpleEvents,
} = SimpleEventsSubscribeForm.actions;
export default SimpleEventsSubscribeForm;
