import React from "react";
// components
import HomeSearchTab from "./tab/GlobalSearchTab";
import CardResultPage from "./card/cardResultPage/CardResultPage";
import CardResultEvent from "./card/cardResultEvent/CardResultEvent";
import CardResultDocument from "./card/cardResultDocument/CardResultDocument";
import CardResultNews from "./card/cardResultNews/CardResultNews";
import CardResultTool from "./card/cardResultTool/CardResultTool";
// style
import { ResultsContainer } from "./style";

const cardMapping = {
  PAGE: CardResultPage,
  NEWS: CardResultNews,
  EVENT: CardResultEvent,
  DOCUMENT: CardResultDocument,
  TOOLS: CardResultTool,
};

const SEARCH_CARD_ID = "searchCardId_";
function ResultSection({ currentResults }) {
  const HandleTabFocus = (e) => {
    const lastItemIndex = currentResults.length - 1;
    const lastId = SEARCH_CARD_ID + lastItemIndex;
    switch (e.keyCode) {
      case 9:
        if (!e.shiftKey && e.target.id === lastId) {
          e.preventDefault();
          document.getElementById(lastId).focus();
        }
        break;
    }
  };

  return (
    <>
      <HomeSearchTab />
      <ResultsContainer onKeyDown={(e) => HandleTabFocus(e)}>
        {React.Children.toArray(
          currentResults.map((result, i) => {
            const CardGenericComponent = cardMapping[result.type];
            return (
              <CardGenericComponent id={SEARCH_CARD_ID + i} data={result} />
            );
          })
        )}
      </ResultsContainer>
    </>
  );
}

export default React.memo(ResultSection);
