// Hook
import { useEffect, useState } from "react";
import { coreComponentsActions } from "../../../utils/getCoreComponents/coreComponentsSlice";
import { useDispatch } from "react-redux";

export default function ResizeWindowHook() {
  const dispatch = useDispatch();
  const query = "(min-width: 1280px)";
  const isDesktopOnLanding = window.matchMedia(query).matches;
  const [isDesktop, setIsDesktop] = useState(isDesktopOnLanding);

  useEffect(() => {
    const listener = () => {
      const media = window.matchMedia(query);
      if (media.matches && !isDesktop) {
        // from mobile to desktop
        document.body.style.overflow = "";
        dispatch(coreComponentsActions.setInitialComponents());
      } else if (!media.matches && isDesktop) {
        // from dekstop to mobile;
        dispatch(coreComponentsActions.setInitialComponents());
      }
      setIsDesktop(media.matches);
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [isDesktop, query, dispatch]);

  return null;
}
