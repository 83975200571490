import { takeLatest, put, select } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  FETCH_COMPONENTS,
  SET_FAV_CONTACT,
  SEARCH_BY_NAME,
  SET_FAV_USEFUL_CONTACT,
  ADVANCED_SEARCH,
} from "./constants";
import { rubricaActions } from "./rubricaSlice";
import { selectComponents } from "./selectors";

const FAV_CONTACT_SERVICE_URL = "/contactsdata";
const SEARCH_BY_NAME_URL = "/it/contacts?fullName=";
const ADVANCED_SEARCH_URL = "/it/contacts?";

function* fetchRubricaComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() => axios.get("/mocks/mock-rubrica.json"));
    yield put(rubricaActions.setComponents(result.data.data.components));
    yield put(rubricaActions.setSettings(result.data.data.settings));

    const components = yield select(selectComponents());
    const tabsComponents = components.find(
      (v) => v.resourceType === "fi-address-book/fi-address-book-tabs"
    );

    const pickedComponents = (({
      favorites,
      usefulMaranello,
      usefulScaglietti,
      useful,
    }) => ({
      favorites,
      usefulMaranello,
      usefulScaglietti,
      useful,
    }))(tabsComponents.data);

    const tabsArray = Object.entries(pickedComponents).map(([_, value], i) => {
      return { id: i + 1, components: value };
    });

    yield put(rubricaActions.setTabsArray(tabsArray));
  } catch (e) {
    console.error(e);
    yield put(rubricaActions.setError(e));
  }
}

function* SaveFavContact({ payload }) {
  try {
    const body = { id: payload.data.subjectCode, tag: "users" };
    yield axiosCallPost(
      FAV_CONTACT_SERVICE_URL,
      body,
      payload.token,
      true,
      false
    );
  } catch (e) {
    console.error(e);
  }
}

function* SaveFavUsefulContact({ payload }) {
  try {
    const body = { id: payload.data.uniqueId, tag: payload.data.tag };
    yield axiosCallPost(
      FAV_CONTACT_SERVICE_URL,
      body,
      payload.token,
      true,
      false
    );
  } catch (e) {
    console.error(e);
  }
}

function* SearchByName({ payload }) {
  try {
    const path = SEARCH_BY_NAME_URL.concat(payload.fullName);
    document.body.classList.add("loading");
    const result = yield axiosCallGet(path, payload.token);
    yield put(rubricaActions.SetSearchResult(result.data.data));
  } catch (e) {
    console.error(e);
  } finally {
    document.body.classList.remove("loading");
  }
}

function* AdvancedSearch({ payload }) {
  try {
    const path = ADVANCED_SEARCH_URL.concat(payload.queryString);
    document.body.classList.add("loading");
    const result = yield axiosCallGet(path, payload.token);
    yield put(rubricaActions.SetSearchResult(result.data.data));
  } catch (e) {
    console.error(e);
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* myPageSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchRubricaComponents);
  yield takeLatest(SET_FAV_CONTACT, SaveFavContact);
  yield takeLatest(SEARCH_BY_NAME, SearchByName);
  yield takeLatest(ADVANCED_SEARCH, AdvancedSearch);
  yield takeLatest(SET_FAV_USEFUL_CONTACT, SaveFavUsefulContact);
}
