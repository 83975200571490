import styled from "styled-components";

export const Container = styled.div`
  width: 1216px;
  margin: 0 auto 96px;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1279px) {
    width: auto;
  }
`;
export const Title = styled.h2`
  font-size: 36px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 34px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 1279px) {
    width: auto;
  }
`;
