import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  gap: 24px;
  background: var(--alternative-bg-color);
  border: 1px solid var(--main-color);
  width: 384px;
  box-sizing: border-box;
  @media screen and (max-width: 1279px) {
    width: 100%;
    border-bottom: 2px solid rgb(218, 41, 28);
  }
  & > div {
    margin-bottom: 0;
  }
  svg path {
    fill: var(--main-color);
  }
`;
export const Text = styled.div`
  font-size: 11px;
  line-height: 20px;
`;
