import React from "react";
import { ComponentContainer, TextDescr } from "../../style";
//components
import ErrorField from "../ErrorField/ErrorField";
import PopupConiugi from "./PopupConiugi/PopupConiugi";
import InputRadioButton from "../RadioButton/RadioButton";
import { DialogNote } from "./DialogNote/DialogNote";
import { useSelector } from "react-redux";

export default function NucleoFamiliare({ data, onChange, check, allowed }) {
  const eventUserInfo = useSelector(
    (state) => state.FormIscrizioneGiftHouse.defaultUserInfo
  );

  const isMarried = eventUserInfo?.isMarried;
  const childsAreSubscribed = eventUserInfo?.childsSubscribed?.length > 0;
  const childsRegisteredByConsort = eventUserInfo?.childsSubscribed?.find(
    (e) => e?.subscriptionInfo?.subscribedBy !== eventUserInfo?.userFiscalCode
  )
    ? true
    : false;
  const familyValues = [
    { text: data?.giftHouse?.yesLabel, value: "true", id: "withFamily" },
    { text: data?.giftHouse?.noLabel, value: "false", id: "withoutFamily" },
  ];

  return (
    <ComponentContainer>
      {isMarried && childsAreSubscribed && childsRegisteredByConsort && (
        <PopupConiugi text={data?.giftHouse?.alreadySubTxt} />
      )}
      {(!childsAreSubscribed || !childsRegisteredByConsort) && (
        <>
          <h3>{data?.giftHouse?.titleFamily}</h3>
          <InputRadioButton
            description={data?.giftHouse?.descFamily}
            array={familyValues}
            name={`NucleoFamiliare`}
            checked={check}
            onChange={onChange}
          />
          {check == null && allowed === false && (
            <ErrorField text={data?.giftHouse?.errorMessage} />
          )}
        </>
      )}
      {check === "true" && (
        <>
          <TextDescr>{data?.giftHouse?.titleFamilyMembers}</TextDescr>
          <div className="fi-checkbox">
            {React.Children.toArray(
              eventUserInfo?.familyComponents?.map((familiare) => (
                <p>
                  {familiare.name} {familiare.surname}
                </p>
              ))
            )}
          </div>
          {isMarried && (!childsAreSubscribed || !childsRegisteredByConsort) && (
            <>
              <DialogNote data={data?.modal} id={"popupConiugi"} />
              <PopupConiugi text={data?.giftHouse?.getGiftMex} />
            </>
          )}
        </>
      )}
    </ComponentContainer>
  );
}
