import CustomSelect from "../../customSelect/CustomSelect";
import { ComponentContainer, InputSection, TextDescr } from "../style";
import ErrorField from "./ErrorField/ErrorField";
import { useEffect } from "react";
import {
  setBodyPayload,
  setCurrentPlace,
} from "../../../../pages/FormContraffazioni/FormContraffazioniSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";

export default function ComponentGift({
  data,
  bodyPayload,
  allowed,
  handleEventInfo,
}) {
  const currentPlaceInitialState = {
    cap: "",
    address: "",
    region: "",
    city: "",
  };

  const dispatch = useDispatch();
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const currentPlace = useSelector(
    (state) => state.FormContraffazioni.currentPlace
  );

  function generateHtml(argument) {
    return { __html: argument };
  }

  const handleChangeLocation = (e) => {
    const { name, value } = e.currentTarget;
    dispatch(
      setCurrentPlace({
        ...currentPlace,
        [name]: value,
      })
    );
  };

  const handleChangeCountry = (e) => {
    const { value } = e.currentTarget;
    dispatch(
      setBodyPayload({
        ...bodyPayload,
        country: value,
        onSearchAddress: "",
      })
    );
    dispatch(setCurrentPlace(currentPlaceInitialState));
    inputRef.current.value = "";

    //   //Non più necessario eliminare il listener e distruggere/creare una nuova istanza autocomplete
    //   // window.google.maps.event.removeListener(autoCompleteListener);
    //   // window.google.maps.event.clearInstanceListeners(inputRef);

    //   //LEGGERE:
    //   //Il glitch degli indirizzi è causato dalla presenza di più pac-container che è
    //   //il div dove places innesta i suggerimenti.
    //   //Per ovviare al problema mi sono assicurato che l'oggetto autocomplete fosse inizializzato
    //   //solo una volta, infatti lo useEffect viene eseguito una volta e non ha dipendenze [].
    //   //Essendo noi in StrictMode però lo UseEffect viene eseguito due volte e quindi vengono creati
    //   //due pac-container --> Bisogna verificare che in dev venga creato solo 1 pac container.

    autoCompleteRef.current.setOptions({
      componentRestrictions: { country: value },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
  };

  const handleFocus = () => {
    inputRef.current.value = bodyPayload.onSearchAddress;
  };

  const calcSub4 = (sublocal_3, sublocal_4) =>
    sublocal_3 && sublocal_4
      ? `-${sublocal_4}`
      : sublocal_4 && ` ${sublocal_4}`;

  useEffect(() => {
    const options = {
      componentRestrictions: { country: bodyPayload?.country },
      fields: ["address_components", "geometry"],
      types: ["address"],
    };

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef?.current?.addListener("place_changed", fillAddress);
  }, []);

  async function fillAddress() {
    const place = await autoCompleteRef.current.getPlace();
    const completeAddress = [];

    //cap
    const chosenCap = place?.address_components?.find((v) =>
      v.types.includes("postal_code")
    )?.short_name;

    //country
    const chosenCountry = place?.address_components?.find((v) =>
      v.types.includes("country")
    )?.long_name;

    //address
    const route = place?.address_components?.find((v) =>
      v.types.includes("route")
    )?.long_name;
    route && completeAddress.push(`${route}`);

    const sublocal_3 = place?.address_components?.find((v) =>
      v.types.includes("sublocality_level_3")
    )?.long_name;
    sublocal_3 && completeAddress.push(` ${sublocal_3}`);

    const sublocal_4 = place?.address_components?.find((v) =>
      v.types.includes("sublocality_level_4")
    )?.long_name;
    let resultSublocal4 = calcSub4(sublocal_3, sublocal_4);
    resultSublocal4 && completeAddress.push(resultSublocal4);

    const premise = place?.address_components?.find((v) =>
      v.types.includes("premise")
    )?.long_name;
    premise && completeAddress.push(`-${premise}`);

    const sublocal_1 = place?.address_components?.find((v) =>
      v.types.includes("sublocality_level_1")
    )?.long_name;
    sublocal_1 && completeAddress.push(` ${sublocal_1}`);

    const sublocal_2 = place?.address_components?.find((v) =>
      v.types.includes("sublocality_level_2")
    )?.long_name;
    sublocal_2 && completeAddress.push(` ${sublocal_2}`);

    const streetNumber = place?.address_components?.find((v) =>
      v.types.includes("street_number")
    )?.long_name;
    streetNumber && completeAddress.push(` ${streetNumber}`);

    let updatedAddress = "";
    completeAddress.forEach((el) => {
      updatedAddress += el;
    });
    const choosenAddress = updatedAddress;

    //region
    const area2 = place?.address_components?.find((v) =>
      v.types.includes("administrative_area_level_2")
    )?.short_name;
    const area1 = place?.address_components?.find((v) =>
      v.types.includes("administrative_area_level_1")
    )?.long_name;

    const chosenRegion =
      (area2 || area1) && (area2 !== undefined ? `${area2}, ${area1}` : area1);

    //city
    const area3 = place?.address_components?.find((v) =>
      v.types.includes("administrative_area_level_3")
    )?.long_name;
    const locality = place?.address_components?.find((v) =>
      v.types.includes("locality")
    )?.long_name;
    const postalTown = place?.address_components?.find((v) =>
      v.types.includes("postal_town")
    )?.long_name;

    const chosenCity = area3
      ? area3
      : locality
      ? locality
      : postalTown
      ? postalTown
      : null;

    dispatch(
      setCurrentPlace({
        city: chosenCity || "",
        cap: chosenCap || "",
        region: chosenRegion || "",
        address: choosenAddress || "",
      })
    );
  }

  return (
    <>
      <ComponentContainer>
        <h3>{data?.gadgetDelivery?.gadgetDeliveryTitle} </h3>
        <TextDescr
          dangerouslySetInnerHTML={generateHtml(
            `${data?.gadgetDelivery?.richText}`
          )}
        />
        <ComponentContainer>
          <InputSection hideLabel={true}>
            <input
              autoComplete="nope"
              id="telephone"
              name="telephone"
              placeholder={data?.gadgetDelivery?.phoneLbl}
              defaultValue={bodyPayload?.telephone}
              type="number"
              onChange={(e) => {
                handleEventInfo(e);
              }}
              onWheel={(e) => e.currentTarget.blur()}
            />
            <label htmlFor="telephone">{data?.gadgetDelivery?.phoneLbl}</label>
          </InputSection>
          {(!bodyPayload?.telephone || bodyPayload?.telephone?.length < 5) &&
            allowed === false && <ErrorField text={data?.errorMessage} />}
        </ComponentContainer>
        <CustomSelect
          className={"customSelect-contraffazione"}
          name="country"
          defaultValue={bodyPayload?.country}
          placeholderString={data?.gadgetDelivery?.nationLbl}
          onChange={(e) => {
            handleChangeCountry(e);
          }}
        >
          {data?.countryList?.map((nation, ind) => (
            <option
              checked={bodyPayload?.country === nation?.code}
              className="data-evento"
              key={nation?.code}
              value={nation?.code}
            >
              {nation?.description}
            </option>
          ))}
        </CustomSelect>
        {!bodyPayload?.country && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      <ComponentContainer>
        <InputSection hideLabel={true}>
          <input
            ref={inputRef}
            id="onSearchAddress"
            name="onSearchAddress"
            placeholder={data?.gadgetDelivery?.searchAddressLbl}
            defaultValue={bodyPayload?.onSearchAddress}
            type="text"
            onFocus={handleFocus}
            onChange={(e) => {
              handleEventInfo(e);
            }}
          />
          <label htmlFor="onSearchAddress">
            {data?.gadgetDelivery?.searchAddressLbl}
          </label>
        </InputSection>
        {!bodyPayload?.onSearchAddress && allowed === false && (
          <ErrorField text={data?.errorMessage} />
        )}
      </ComponentContainer>
      {bodyPayload?.onSearchAddress?.length > 0 && (
        <>
          <ComponentContainer>
            <InputSection hideLabel={true}>
              <input
                autoComplete="nope"
                id="address"
                name="address"
                placeholder={data?.gadgetDelivery?.addressLbl}
                type="text"
                value={currentPlace?.address}
                onChange={(e) => {
                  handleChangeLocation(e);
                }}
              />
              <label htmlFor="address">
                {data?.gadgetDelivery?.addressLbl}
              </label>
            </InputSection>
            {!currentPlace?.address && allowed === false && (
              <ErrorField text={data?.errorMessage} />
            )}
          </ComponentContainer>
          <ComponentContainer>
            <InputSection hideLabel={true}>
              <input
                autoComplete="nope"
                id="cap"
                name="cap"
                type="text"
                placeholder={data?.gadgetDelivery?.capLbl}
                value={currentPlace?.cap}
                onChange={(e) => {
                  handleChangeLocation(e);
                }}
              />
              <label htmlFor="cap">{data?.gadgetDelivery?.capLbl}</label>
            </InputSection>
            {!currentPlace?.cap && allowed === false && (
              <ErrorField text={data?.errorMessage} />
            )}
          </ComponentContainer>
          <ComponentContainer>
            <InputSection hideLabel={true}>
              <input
                autoComplete="nope"
                id="city"
                name="city"
                placeholder={data?.gadgetDelivery?.cityLbl}
                value={currentPlace?.city}
                onChange={(e) => {
                  handleChangeLocation(e);
                }}
              />
              <label htmlFor="region">{data?.gadgetDelivery?.cityLbl}</label>
            </InputSection>
            {!currentPlace?.city && allowed === false && (
              <ErrorField text={data?.errorMessage} />
            )}
          </ComponentContainer>
          <ComponentContainer>
            <InputSection hideLabel={true}>
              <input
                autoComplete="nope"
                id="region"
                name="region"
                placeholder={data?.gadgetDelivery?.regionLbl}
                value={currentPlace?.region}
                onChange={(e) => {
                  handleChangeLocation(e);
                }}
              />
              <label htmlFor="region">{data?.gadgetDelivery?.regionLbl}</label>
            </InputSection>
            {!currentPlace?.region && allowed === false && (
              <ErrorField text={data?.errorMessage} />
            )}
          </ComponentContainer>
        </>
      )}
    </>
  );
}
