import { useEffect, useRef } from "react";
import React from "react";
const ThronPlayer = ({ xcontentId, language }) => {
  const current = useRef(null);
  const id = "id-" + xcontentId;
  useEffect(() => {
    const options = {
      clientId: "ferrari",
      xcontentId: xcontentId,
      sessId: "o2t8hu",
      language: { interface: language, content: language, subTitle: language },
    };
    if (window.THRONContentExperience) {
      const newPlayer = window.THRONContentExperience(id, options);
      if (!window.THRONPLAYER) window.THRONPLAYER = [];
      window.THRONPLAYER.push(newPlayer);
    }
  }, [id, xcontentId]);
  return <div id={id} ref={current} />;
};
export default ThronPlayer;
