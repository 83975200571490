import { Container, RedirectButton, TitleForm } from "./../style";
import { useNavigate } from "react-router-dom";

export function LimitReachedDialog({ data }) {
  const navigate = useNavigate();

  function generateHtml(argument) {
    return { __html: argument };
  }
  return (
    <>
      <Container center>
        <TitleForm>
          {data?.limitReachedDialog?.title?.jcrTitle}
          <div
            dangerouslySetInnerHTML={generateHtml(
              data?.limitReachedDialog?.text?.text
            )}
          />
        </TitleForm>
        <RedirectButton
          onClick={() => navigate(`${data?.buttonLimitReached?.link}`)}
        >
          {data?.buttonLimitReached?.title}
        </RedirectButton>
      </Container>
    </>
  );
}
