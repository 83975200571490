import PropTypes from "prop-types";
import { TextWrapper } from "./style";

function TitleAndText({ data }) {
  return (
    <TextWrapper>
      <h2>{data.title}</h2>
      {data.description}
    </TextWrapper>
  );
}

TitleAndText.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default TitleAndText;
