import styled from "styled-components";

export const Container = styled.section`
  width: 1216px;
  margin: auto;
  padding-bottom: 90px;
  @media (max-width: 1279px) {
    margin-top: 30px;
    width: 90%;
  }
`;
