import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  place-content: center;
  flex-direction: column;
  align-items: center;
  margin: 96px 0px;
  img {
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 100%;
  }
  div {
    font-size: 24px;
    margin-top: 18px;
  }
`;
