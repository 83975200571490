import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownSelected = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  @media screen and (min-width: 1280px) {
    display: none;
  }
  select {
    padding: 10px 0px;
    background: transparent;
    border: none;
    appearance: none;
    text-transform: uppercase;
  }
  div {
    max-width: 100%;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const ListItem = styled.li`
  padding: 12px 32px;
  color: rgb(112, 112, 112);
  border-bottom: 2px solid transparent;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  &:hover,
  &.selected {
    color: rgb(218, 41, 28);
    border-bottom: 2px solid rgb(218, 41, 28);
  }
`;
