import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetFiltersAndCards } from "../../../../../pages/AdsList/AdsListSlice";
import {
  selectCatalogOfferType,
  selectedCardsTotal,
} from "../../../../../pages/AdsList/selectors";
import OffroCasaDialog from "../../../dialog/offroCasa/OffroCasaDialog";
import {
  Button,
  DialogContent,
  FilterIcon,
  FilterIconPath1,
  FilterIconPath2,
  MobileSection,
  SpanFilter,
  TitleWithFilter,
} from "./style";
import FiltersDropdown from "../filtersDropdown/filtersDropdown";
import TitleWithCounter from "../titleWithCounter/titleWithCounter";

function MobileContentHeader() {
  const dispatch = useDispatch();
  const [isDialogOpen, openDialog] = useState(false);
  const total = useSelector(selectedCardsTotal());
  const offerType = useSelector(selectCatalogOfferType());
  return (
    <MobileSection>
      <TitleWithFilter>
        <h1>
          {offerType ? (
            "Catalogue"
          ) : (
            <>
              <TitleWithCounter justText={true} /> {"" + offerType}
            </>
          )}
        </h1>
        <SpanFilter onClick={() => openDialog(true)}>
          <FilterIcon>
            <FilterIconPath1 />
            <FilterIconPath2 />
          </FilterIcon>
        </SpanFilter>
      </TitleWithFilter>

      <OffroCasaDialog showDialog={isDialogOpen} openDialog={openDialog}>
        <DialogContent>
          <FiltersDropdown></FiltersDropdown>
          <Button primary={true} onClick={() => openDialog(false)}>
            Show {total} results
          </Button>
          <Button onClick={() => dispatch(resetFiltersAndCards())}>
            Remove Filters
          </Button>
        </DialogContent>
      </OffroCasaDialog>
    </MobileSection>
  );
}

MobileContentHeader.propTypes = {
  children: PropTypes.node,
  offerType: PropTypes.string,
  total: PropTypes.number,
  resetFiltersAndCards: PropTypes.func,
};
export default MobileContentHeader;
