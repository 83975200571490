import { useSelector } from "react-redux";

function SetQueryStringPath(url) {
  const lang = useSelector((state) => state.coreComponents.language);
  let result = url;
  const baseUrl = process.env.REACT_APP_ENV.startsWith("local")
    ? "http://localhost:3000"
    : window.location.origin;
  if (url) {
    if (url.includes("news-and-events/news/")) {
      const fixed = "/news/news-detail?path=";
      const splittedUrl = url.split(`${lang}/`);
      result = baseUrl + fixed + `/${lang}/` + splittedUrl[1];
    } else if (
      url.includes("news-and-events/events/") ||
      url.includes("news-and-events/webinars/")
    ) {
      const fixed = "/event-details?path=";
      const splittedUrl = url.split(`${lang}/`);
      result = baseUrl + fixed + `/${lang}/` + splittedUrl[1];
    }
  }

  return result;
}

export default SetQueryStringPath;
