import styled from "styled-components";

const paddingSize = (hide, isDefault = false) => {
  if (hide || isDefault) {
    return `
    padding: 40px 15px;
    cursor: ${isDefault ? "grab" : "pointer"};
    `;
  }
  return `
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 0px;
    cursor: grab;
  `;
};

export const Wrapper = styled.div`
  width: 136px;
  height: 136px;
  background: #fff;
  box-sizing: border-box;
  a {
    @media (max-width: 1279px) {
      padding: 0px !important;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(24, 24, 24);
    ${(props) => paddingSize(props.hide, props.isDefault)}
    &:first-child {
      span:first-of-type {
        @media (max-width: 1279px) {
          display: inline-block;
          padding: 16px;
          background: rgb(255, 255, 255);
          border-radius: 100%;
        }
      }
    }
    span.text {
      font-size: 11px;
      text-transform: uppercase;
      margin-top: 12px;
      color: #303030;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    span.dimension {
      font-size: 9px;
    }
    @media (max-width: 1279px) {
      span.text {
        color: var(--main-color);
      }
      span.dimension {
        font-size: 11px;
      }
    }
  }
  @media (max-width: 1279px) {
    background: transparent;
    padding: 33px 15px;
    width: 100px;
  }
`;

export const RemoveButton = styled.i`
  & {
    box-sizing: border-box;
    position: relative;
    left: 90%;
    top: -7%;
    z-index: 1;
    background-color: #d4d2d2;
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 22px;
    cursor: pointer;
  }
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 2px;
    background: currentColor;
    border-radius: 5px;
    top: 11px;
    left: 7px;
  }
`;

export const SpanIcon = styled.span`
  background-color: #ffffff;
  border-radius: 100%;
  width: auto;
  height: auto;
  img {
    width: 40px;
    height: 40px;
  }
`;
