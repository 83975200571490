import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "../TwoThirdTemplate/twoThirdTemplateSlice";

const twoThirdState = (state) => state.twoThird || initialState;

export const selectTopComponents = () =>
  createSelector(twoThirdState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "top"
      );
    }
  });

export const selectRightComponents = () =>
  createSelector(twoThirdState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "right"
      );
    }
  });

export const selectLeftComponents = () =>
  createSelector(twoThirdState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "left"
      );
    }
  });

export const selectBottomComponents = () =>
  createSelector(twoThirdState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "bottom"
      );
    }
  });
export const heroComponent = () =>
  createSelector(twoThirdState, (state) => {
    if (state.components.components) {
      return state.components.components.find(
        (element) => element.resourceType === "fi-hero-image"
      );
    }
  });
