import { createSlice } from "@reduxjs/toolkit";

export const FORM_ISCRIZIONE_FAMILY_DAY_SCOPE = "FormIscrizioneFamilyDay";

export const initialState = {
  components: {},
  checkedConsents: [],
  status: null,
  errorMessage: "",
  remainingMenus: 0,
  initNumGuest: null,
  bodyPayload: {},
  savedBodyPayload: {},
};

const FormIscrizioneSlice = createSlice({
  name: FORM_ISCRIZIONE_FAMILY_DAY_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setRemainingMenus(state, action) {
      state.remainingMenus = action.payload;
    },
    setCheckedConsents(state, action) {
      state.checkedConsents = action.payload;
    },
    resetMealQuantity(state) {
      state.remainingMenus = 1 + state.bodyPayload.numGuests;
      state.bodyPayload?.mealMenus?.map((menu) => {
        menu.quantity = 0;
        return menu;
      });
    },
    setMealQuantity(state, action) {
      const mealMenu = action.payload;

      state.bodyPayload?.mealMenus?.map((menu) => {
        if (menu.mealMenuId === mealMenu.id) menu.quantity = mealMenu.value;
        return menu;
      });
    },
    setBodyPayload(state, action) {
      state.bodyPayload = action.payload;
    },
    initBodyPayload(state, action) {
      const data = action.payload.data;
      state.initNumGuest =
        data?.userSubscriptions?.length > 0
          ? data?.userSubscriptions?.[0]?.tickets?.length - 1
          : null;
      state.remainingMenus = data?.userSubscriptions?.length > 0 ? 0 : 1;
      state.savedBodyPayload = {
        eventCode: data?.event?.code,
        guid: data?.userSubscriptions?.[0]?.guid,
        id: data?.userSubscriptions?.[0]?.id,
        secondaryAppointmentId:
          data?.userSubscriptions?.[0]?.secondaryAppointmentId || null,
        isNew: data?.userSubscriptions?.length > 0 ? false : true,
        appointmentId: data?.userSubscriptions?.[0]?.appointmentId || null,
        numGuests: state.initNumGuest,
        consents: data?.event?.consents,
        mealMenus: [
          {
            mealMenuId: data?.event?.mealMenus?.[0]?.id,
            quantity:
              data?.userSubscriptions?.[0]?.mealMenus?.[0]?.quantity || 0,
            name: data?.event?.mealMenus?.[0]?.name,
            description: data?.event?.mealMenus?.[0]?.description,
          },
          {
            mealMenuId: data?.event?.mealMenus?.[1]?.id,
            quantity:
              data?.userSubscriptions?.[0]?.mealMenus?.[1]?.quantity || 0,
            name: data?.event?.mealMenus?.[1]?.name,
            description: data?.event?.mealMenus?.[1]?.description,
          },
          {
            mealMenuId: data?.event?.mealMenus?.[2]?.id,
            quantity:
              data?.userSubscriptions?.[0]?.mealMenus?.[2]?.quantity || 0,
            name: data?.event?.mealMenus?.[2]?.name,
            description: data?.event?.mealMenus?.[2]?.description,
          },
        ],
        optionsValue: [
          {
            idOption: data?.event?.optionsBox?.[0]?.id,
            description:
              data?.userSubscriptions?.[0]?.optionsValue?.[0]?.description ||
              null,
            value:
              data?.userSubscriptions?.[0]?.optionsValue?.[0]?.value || null,
          },
        ],
      };
      state.bodyPayload = state.savedBodyPayload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormDataFamilyDay: () => initialState,
  },
});

export const {
  setComponents,
  setCheckedConsents,
  setError,
  setStatusNull,
  setBodyPayload,
  setRemainingMenus,
  setMealQuantity,
  initBodyPayload,
  resetMealQuantity,
  resetFormDataFamilyDay,
} = FormIscrizioneSlice.actions;
export default FormIscrizioneSlice;
