import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
//actions
import { rubricaActions } from "../../../../pages/Rubrica/rubricaSlice";
import { SearchByName } from "../../../../pages/Rubrica/actions";
import { INIT_ADVANCED_FILTERS } from "../../../../pages/Rubrica/rubricaSlice";
//components
import SearchFilterRubricaDialog from "../../dialog/rubrica/SearchFilterRubricaDialog";
//selectors
import { getSearchComponent } from "../../../../pages/Rubrica/selectors";

import {
  SearchWrapper,
  SearchField,
  RemoveBtn,
  SearchBtn,
  AdvSearchWrapper,
  ActiveFilter,
  FilterIcon,
  FilterIconPath1,
  FilterIconPath2,
} from "./style";

function Search() {
  const dispatch = useDispatch();
  const showDialog = useSelector((state) => state.rubrica.showDialog);
  const filterCount = useSelector((state) => state.rubrica.filterCount);
  const searchComponent = useSelector(getSearchComponent());
  const token = useSelector((state) => state.login.activeToken);

  const [searchActive, SetSearchActive] = useState(false);

  const removeFilters = () => {
    document.getElementById("nameInpt").value = "";
    document.getElementById("nameLbl").style.display = "none";
    document.getElementById("surnameInpt").value = "";
    document.getElementById("surnameLbl").style.display = "none";
    document.getElementById("cmbDirection").selectedIndex = 0;
    document.getElementById("directionLbl").style.display = "none";
    document.getElementById("cmbCostCenter").selectedIndex = 0;
    document.getElementById("costCenterLbl").style.display = "none";
    document.getElementById("companyInpt").value = "";
    document.getElementById("companyLbl").style.display = "none";
    document.getElementById("phoneInpt").value = "";
    document.getElementById("phoneLbl").style.display = "none";
    document.getElementById("mobilePhoneInpt").value = "";
    document.getElementById("mobilePhoneLbl").style.display = "none";
    document.getElementById("dectInpt").value = "";
    document.getElementById("dectLbl").style.display = "none";
    document.getElementById("faxInpt").value = "";
    document.getElementById("faxLbl").style.display = "none";
    document.getElementById("emailInpt").value = "";
    document.getElementById("emailLbl").style.display = "none";
    document.getElementById("searchBtn").disabled = true;

    dispatch(rubricaActions.setFilterCount(0));
    dispatch(rubricaActions.setAdvancedSearchFilters(INIT_ADVANCED_FILTERS));
    dispatch(rubricaActions.setSearchButton(false));
  };

  const removeFilterHandler = () => {
    document.getElementById("searchTxt").value = "";
    removeFilters();
    SetSearchActive(false);
    dispatch(rubricaActions.RemoveSearchResult());
  };

  const HandleSearchByName = () => {
    const txtInput = document.getElementById("searchTxt");
    const noSpacesTxt = txtInput.value.trim();
    if (noSpacesTxt !== "") {
      removeFilters();
      dispatch(SearchByName({ fullName: txtInput.value, token: token }));
    }
  };

  const inputChangeHandler = (event) => {
    const { value } = event.target;
    if (value !== "") {
      SetSearchActive(true);
    } else SetSearchActive(false);
  };

  const enterCheck = (event) => {
    if (event.keyCode === 13) {
      const txtInput = document.getElementById("searchTxt");
      const noSpacesTxt = txtInput.value.trim();
      if (noSpacesTxt !== "") {
        removeFilters();
        dispatch(SearchByName({ fullName: txtInput.value, token: token }));
      }
    }
  };

  return (
    <>
      <SearchWrapper>
        <SearchField>
          {searchActive ? (
            <img src="/img/search-red.svg" />
          ) : (
            <img src="/img/search.svg" />
          )}
          <input
            id="searchTxt"
            placeholder={searchComponent.data.placeholder}
            onChange={(e) => inputChangeHandler(e)}
            onKeyDown={(e) => enterCheck(e)}
          />
          <RemoveBtn onClick={removeFilterHandler}>
            {searchComponent.data.removeLabel}
          </RemoveBtn>
        </SearchField>
        <SearchBtn onClick={HandleSearchByName}>
          {searchComponent.data.searchLabel}
        </SearchBtn>
      </SearchWrapper>
      <AdvSearchWrapper>
        <FilterIcon onClick={() => dispatch(rubricaActions.showDialog(true))}>
          <FilterIconPath1 />
          <FilterIconPath2 />
        </FilterIcon>
        {filterCount > 0 ? <ActiveFilter>{filterCount}</ActiveFilter> : <></>}
        <span>{searchComponent.data.advancedSearchLabel}</span>
      </AdvSearchWrapper>
      <SearchFilterRubricaDialog showDialog={showDialog} />
    </>
  );
}

export default Search;
