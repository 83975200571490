import { useDispatch } from "react-redux";
// actions
import { toolsActions } from "../../../../pages/Strumenti/toolsSlice";
import TargetTrap from "../../../../utils/targetTrap";
// components
import DialogContent from "./dialogContent/DialogContent";
// style
import { DialogWrapper, DialogOverlay, CloseButton } from "./style";

function ToolsDialog() {
  const dispatch = useDispatch();

  const HandleKeyPress = (e) => {
    switch (e.keyCode) {
      case 27:
        e.preventDefault();
        dispatch(toolsActions.setShowToolsDialog(false));
        document.getElementById("toolsIcon").focus();
        break;
      case 9:
        TargetTrap(e, "toolsDialog");
        break;
    }
  };

  return (
    <DialogWrapper id="toolsDialog" onKeyDown={(e) => HandleKeyPress(e)}>
      <DialogOverlay
        onClick={() => dispatch(toolsActions.setShowToolsDialog(false))}
      />
      <CloseButton
        onClick={() => dispatch(toolsActions.setShowToolsDialog(false))}
      >
        <img
          role="button"
          tabIndex="0"
          aria-label="close"
          src="/img/close-light.svg"
          width="40"
          height="40"
        />
      </CloseButton>
      <DialogContent />
    </DialogWrapper>
  );
}

export default ToolsDialog;
