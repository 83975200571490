import React, { useCallback } from "react";
import { Controllers } from "./style";

const VideoControllers = ({
  videoMargin: margin,
  videoTop: top,
  videoHeight: height,
  videoWidth: width,
  setPlayerState,
  playerState,
}) => {
  const togglePlay = useCallback(() => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  }, [playerState, setPlayerState]);

  const toggleVolume = useCallback(() => {
    setPlayerState({
      ...playerState,
      isVolumeShown: !playerState.isVolumeShown,
    });
  }, [playerState, setPlayerState]);

  return (
    <Controllers
      marginController={margin}
      topController={(top * height) / 100}
      widthController={width}
    >
      <div>
        <button
          type="button"
          onClick={togglePlay}
          style={{
            color: "transparent",
            cursor: "pointer",
          }}
        >
          <img
            style={{
              width: (96 * width) / 100 + "px",
              height: (96 * height) / 100 + "px",
              position: "relative",
            }}
            src={!playerState.isPlaying ? "img/play.svg" : "img/pause.svg"}
          />
        </button>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {playerState.isVolumeShown && (
          <div style={{ width: "200px", color: "white" }}>controllo volume</div>
        )}
        <div>
          <button
            type="button"
            onClick={toggleVolume}
            style={{ color: "transparent", cursor: "pointer" }}
          >
            <img
              style={{
                width: (96 * width) / 200 + "px",
                height: (96 * height) / 200 + "px",
                position: "relative",
              }}
              src="/img/volume.svg"
            />
          </button>
        </div>
      </div>
    </Controllers>
  );
};

export default React.memo(VideoControllers);
