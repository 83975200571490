import React from "react";
import { NoRequest } from "../NoRequest/NoRequest";
import CardUser from "./CardUser/CardUser";

export function CardUserList({ data }) {
  let sorted = [];
  if (data?.items?.length > 0) {
    sorted = [...data?.items].sort((a, b) => b.responseDate - a.responseDate);
  }

  return (
    <>
      {sorted?.length > 0 ? (
        React.Children.toArray(sorted?.map((cardInfo) => <CardUser cardInfo={cardInfo} />))
      ) : (
        <NoRequest label={data?.emptyLabel} />
      )}
    </>
  );
}
