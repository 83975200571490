import styled from "styled-components";

export const Category = styled.div`
  color: rgb(112, 112, 112);
  display: block;
  margin: 10px 0px;
  font-size: 11px;
`;

export const TrayDish = styled.div`
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
`;

export const TrayDishInner = styled.span`
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  line-height: 20px;
  > span{
    margin-right: 10px;
  }
`;

