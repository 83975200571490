import styled from "styled-components";

export const Wrapper = styled.div`
  background: var(--alternative-bg-color);

  @media (min-width: 1280px) {
    width: 384px;
    box-sizing: border-box;
    min-width: 384px;
  }
  @media (max-width: 1279px) {
    width: 100%;
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 24px;
  cursor: pointer;
  h3 {
    margin: 0px;
    font-size: 18px;
    line-height: 26px;
  }
  img {
    width: 100%;
  }
`;
