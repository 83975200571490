import styled from "styled-components";

export const Wrapper = styled.div`
  width: 592px;
  margin: 96px auto;
  @media (max-width: 1279px) {
    width: 100%;
    margin-top: 40px;
  }
  h3:not(:first-child) {
    margin-top: 96px;
    display: flex;
    span {
      font-size: 11px;
      margin-left: auto;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;
