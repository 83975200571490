import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./FAQDetailSlice";

const State = (state) => state.FAQDetail || initialState;

export const getNonAccordions = () =>
  createSelector(State, (state) => {
    return state.components.components.filter(
      (v) => v.resourceType !== "fi-faq-accordion-list"
    );
  });

export const getAccordionsList = () =>
  createSelector(State, (state) => {
    return state.components.components.filter(
      (v) => v.resourceType === "fi-faq-accordion-list"
    );
  });

export const getAccordionsTitles = () =>
  createSelector(State, (state) => {
    const accordions = state.components.components.filter(
      (v) => v.resourceType === "fi-faq-accordion-list"
    );
    return accordions.filter((v) => v.data?.title?.trim());
  });
export const selectTopComponents = () =>
  createSelector(State, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "top"
      );
    }
  });
export const heroComponent = () =>
  createSelector(State, (state) => {
    if (state.components.components) {
      return state.components.components.find(
        (element) => element.resourceType === "fi-hero-image"
      );
    }
  });
