import styled from "styled-components";

export const Card = styled.div`
  position: relative;
  img {
    width: 100%;
  }
  @media screen and (min-width: 1280px) {
    width: 384px;
  }
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  margin: 20px 0px;
  color: rgb(230, 72, 61);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  @media screen and (max-width: 1279px) {
    font-size: 11px;
  }
`;
export const TitleAndText = styled.div`
  height: 115px;
  @media screen and (max-width: 1279px) {
    text-align: left;
  }
`;
export const Title = styled.div`
  font-family: ferrari-sans-medium;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 16px;
  color: var(--main-color);
  @media screen and (max-width: 1279px) {
    font-size: 16px;
  }
`;

export const Text = styled.div`
  height: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: var(--main-color);

  flex: none;
  order: 1;
  flex-grow: 0;
  p:first-of-type {
    margin: 0;
  }
  @media screen and (max-width: 1279px) {
    font-size: 11px;
  }
`;

export const TopText = styled.div`
  display: ${(props) => (props.isVisibile ? "block" : "none")};
  position: absolute;
  color: white;
  top: 0px;
  left: 0px;
  font-size: 11px;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  text-align: left;
  span {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    background: linear-gradient(
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.5) 47.48%,
      rgba(0, 0, 0, 0) 95.86%
    );
    opacity: 0.7;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;
