import { createSlice } from "@reduxjs/toolkit";

export const PROGRAMMA_COINVOLGIMENTO_SCOPE = "ProgrammaCoinvolgimento";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
  showDialog: false,
  showDialogKO: false,
};

const ProgrammaCoinvolgimentoSlice = createSlice({
  name: PROGRAMMA_COINVOLGIMENTO_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    fetchComponents: () => {},
    setShowDialog(state, action) {
      state.showDialog = action.payload;
    },
    setShowDialogKO(state, action) {
      state.showDialogKO = action.payload;
    },
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  fetchComponents,
  setShowDialog,
  setShowDialogKO,
} = ProgrammaCoinvolgimentoSlice.actions;
export default ProgrammaCoinvolgimentoSlice;
