import { RedirectButton, TitleForm, ContainerForbidden } from "./style";
import { useBusinessLogic } from "./useBusinessLogic";
import { useNavigate } from "react-router-dom";

export function ForbiddenDialog({ data }) {
  const { generateHtml } = useBusinessLogic();
  const navigate = useNavigate();
  return (
    <ContainerForbidden center>
      <TitleForm>
        {data?.forbiddenDialog?.title?.jcrTitle}
        <div
          dangerouslySetInnerHTML={generateHtml(
            data?.forbiddenDialog?.text?.text
          )}
        />
      </TitleForm>
      <RedirectButton onClick={() => navigate(`${data?.buttonRedirect?.link}`)}>
        {data?.buttonRedirect?.title}
      </RedirectButton>
    </ContainerForbidden>
  );
}
