import React from "react";

import RecapCard from "./recapCard/RecapCard";
export function RecapCardsList({ subscriptions, data }) {
  return (
    <>
      {React.Children.toArray(
        subscriptions?.map((subscription) => (
          <RecapCard subscription={subscription} data={data} />
        ))
      )}
    </>
  );
}

export default React.memo(RecapCardsList);
