export default function StopIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="var(--main-color)"
        fillRule="evenodd"
        d="M8.333 0v20H5V3.333A3.333 3.333 0 018.167.004L8.333 0zM15 0v20h-3.333V3.333A3.333 3.333 0 0114.834.004L15 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
