import styled from "styled-components";

export const ContainerCard = styled.div`
  display: flex;
  gap: 32px;

  @media (min-width: 1280px) {
    margin-top: 64px;
    margin-bottom: 48px;
  }
  @media (max-width: 1279px) {
    margin-left: 24px;
    margin-right: 24px;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 64px;
  }
`;

export const ContactFormContainer = styled.div``;
