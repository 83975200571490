export default function BottomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      style={{ transform: "rotate(-90deg)" }}
    >
      <path
        fill="var(--main-color)"
        fillRule="evenodd"
        d="M15 0L5.915 8.066A2.495 2.495 0 005 10c0 .78.357 1.475.915 1.934L15 20l-5-10 5-10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
