import styled from "styled-components";
export const Wrapper = styled.div`
  position: relative;
  &::before {
    position: absolute;
    width: 100%;
    height: 272px;
    @media (min-width: 1280px) {
      width: 384px;
    }
    left: 0px;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    content: "";
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const DescrWrapper = styled.div`
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px 32px 32px;
  gap: 16px;
  position: absolute;
  @media (min-width: 1280px) {
    width: 384px;
    height: 272px;
  }
  left: 0px;
  bottom: 0px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 36px;
`;

export const Description = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

export const CtaWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
`;

export const SpanCtaIcon = styled.span`
  border: 2px solid rgb(143, 143, 143);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 16px;
  width: 16px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  a:hover {
  }
`;
