import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { FETCH_NEWS_DETAIL_COMPONENTS } from "./constants";
import { newsDetailActions } from "./newsDetailSlice";
import { fetchNotifications } from "../Notifications/actions";
import { notificationActions } from "../Notifications/notificationSlice";

function* fetchNewsDetailComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(newsDetailActions.setComponents(result.data.data));
    yield put(
      fetchNotifications({
        path: `/${payload.coreLang}/notify`,
        token: payload.token,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(newsDetailActions.setError(e));
  }
}

export default function* newsSaga() {
  yield takeLatest(FETCH_NEWS_DETAIL_COMPONENTS, fetchNewsDetailComponents);
}
