import React from "react";
import { WrapperCardEvent } from "./style";
import { SectionTitle } from "../style";
import { SubscribedCard } from "./SubscribedCard";

export function SubscribedEvents({
  data,
  showModalDelete,
  showModalCannotDelete,
}) {
  document.body.addEventListener("mousedown", function () {
    document.body.classList.add("using-mouse");
  });

  // Re-enable focus styling when Tab is pressed
  document.body.addEventListener("keydown", function (event) {
    if (event.keyCode === 9) {
      document.body.classList.remove("using-mouse");
    }
  });

  function compareDates(a, b) {
    let date1 = new Date(a?.startDate);
    let date2 = new Date(b?.startDate);

    if (isNaN(date1)) {
      return 1;
    }

    if (isNaN(date2)) {
      return -1;
    }

    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    }

    return 0;
  }

  function GetMostNearAppointment(event) {
    let sortedEventAppointments = [...event?.subDetail?.appointments].sort(
      (a, b) => compareDates(a, b)
    );

    ////PLACEHOLDER PER TEST
    //let placeHolder = new Date("2024-09-24T12:00:00");
    // let filteredArray = sortedEventAppointments.filter(
    //   (el) => new Date(el.startDate) >= placeHolder
    // );
    let filteredArray = sortedEventAppointments.filter(
      (el) => new Date(el.startDate) >= Date.now()
    );
    return filteredArray[0];
  }

  function CompareEvents(event1, event2) {
    let mostNearDateEvent1 = GetMostNearAppointment(event1);
    let mostNearDateEvent2 = GetMostNearAppointment(event2);
    return compareDates(mostNearDateEvent1, mostNearDateEvent2);
  }
  const SortInternalEvents = () => {
    let sorted = [...data?.internalEvents].sort((a, b) => CompareEvents(a, b));
    return sorted;
  };

  return (
    <WrapperCardEvent>
      <SectionTitle>{data?.subscribedEventTitle}</SectionTitle>
      {React.Children.toArray(
        SortInternalEvents().map((singleEvent) => {
          return (
            <SubscribedCard
              data={data}
              singleEvent={singleEvent}
              showModalDelete={showModalDelete}
              showModalCannotDelete={showModalCannotDelete}
            />
          );
        })
      )}
    </WrapperCardEvent>
  );
}

export default React.memo(SubscribedEvents);
