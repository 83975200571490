import React from "react";
import { ButtonContainer, CtaButton, CtaContainerButton } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { myPageActions } from "../../../../pages/MyPage/myPageSlice";
import { Link } from "react-router-dom";

export function ButtonsCta({ data, isRecapPage, pageData }) {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.coreComponents);
  const isEventClosed = pageData?.subscription?.endSubBool;
  const handleRedirect = () => {
    if (data?.rightBtn?.link?.includes("tabEvents=true")) {
      dispatch(myPageActions.setTabIndex(3));
    }
  };

  const setUrl = (url) => {
    let result = url;
    if (url) {
      if (url.includes("news-and-events/events/")) {
        const fixed = "/event-details?path=";
        const splittedUrl = url.split(`${language}/`);
        result = fixed + `/${language}/` + splittedUrl[1];
      } else {
        result = url;
      }
    }

    return result;
  };

  return (
    <ButtonContainer isClosed={isEventClosed}>
      {!isEventClosed && (
        //   <CtaLink
        //     isClosed={isEventClosed}
        //     href={
        //       isRecapPage
        //         ? SetQueryStringPath(data?.leftBtn?.link)
        //         : "/subscribe-form-family-day?path=" +
        //           data?.leftBtn?.link +
        //           `?eventCode=${pageData?.subscription?.eventCode}`
        //     }
        //   >
        //     <CtaButton
        //       color="var(--main-color)"
        //       bg="transparent"
        //       border="1px solid #d4d4d4"
        //     >
        //       {data?.leftBtn?.title}
        //     </CtaButton>
        //   </CtaLink>
        // )}
        <Link
          to={
            isRecapPage
              ? "/event-details?path=" + data?.leftBtn?.link
              : "/subscribe-form-family-day?path=" +
                data?.leftBtn?.link +
                `?eventCode=${pageData?.subscription?.eventCode}`
          }
        >
          <CtaButton
            isClosed={false}
            color="var(--main-color)"
            bg="transparent"
            border="1px solid #d4d4d4"
          >
            {data?.leftBtn?.title}
          </CtaButton>
        </Link>
      )}
      <CtaContainerButton>
        <Link
          style={{ pointerEvents: !data?.rightBtn?.link ? "none" : "pointer" }}
          onClick={() => handleRedirect()}
          to={setUrl(data?.rightBtn?.link)}
        >
          <CtaButton isClosed={isEventClosed}>
            {data?.rightBtn?.title}
          </CtaButton>
        </Link>
      </CtaContainerButton>
    </ButtonContainer>
  );
}
