export default function ShowFooterBlack({ mainBg }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      style={{
        background: mainBg || "var(--main-bg)",
        borderRadius: "100%",
      }}
      viewBox="0 0 40 40"
    >
      <path
        fill="#8F8F8F"
        fillRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm0-2c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18z"
        clipRule="evenodd"
      ></path>
      <path
        fill="var(--main-color)"
        fillRule="evenodd"
        d="M20.938 20.938L20.5 27.5a.5.5 0 01-1 0l-.438-6.563L12.5 20.5a.5.5 0 010-1l6.563-.438.437-6.562a.5.5 0 011 0l.438 6.563 6.562.437a.5.5 0 010 1l-6.563.438z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
