import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: [],
  settings: {},
  tabId: null,
  status: null,
  errorMessage: "",
};

const contactUsSlice = createSlice({
  name: "contactus",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setSettings(state, action) {
      state.settings = action.payload;
    },
    setTabId(state, action) {
      state.tabId = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const contactUsActions = contactUsSlice.actions;
export default {
  name: contactUsSlice.name,
  reducer: contactUsSlice.reducer,
};
