import styled from "styled-components";

export const BackgroundDesktopMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.4;
  z-index: 1;
`;

export const DropDownAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const AvatarImage = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 100%;
`;
