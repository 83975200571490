import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 90px;
  display: block;
  width: 1216px;
  margin: auto;
  @media screen and (max-width: 1279px) {
    width: 90%;
    margin: auto;
    h1 {
      text-align: start;
    }
  }
  @media screen and (min-width: 1280px) {
    margin: 0 auto 0;
  }
`;

export const ImgHeader = styled.img`
  margin-top: -92px;
  position: relative;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  @media screen and (max-width: 1279px) {
    margin-top: 0;
  }
`;
