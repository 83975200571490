import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./companyComSlice";

const companyComState = (state) => state.companyCom || initialState;

export const selectTabsMap = () =>
  createSelector(companyComState, (state) => state.tabsMap);

export const selectTabCategory = () =>
  createSelector(companyComState, (state) => state.tabCategory);

export const selectFilterYear = () =>
  createSelector(companyComState, (state) => state.filterYear);

export const selectDocumentByYearAndCategory = (category, year) =>
  createSelector(companyComState, (state) => {
    const dateFrom = new Date(`01/01/${year}`).getTime();
    const dateTo = new Date(`12/31/${year}`).getTime();
    return (
      state.documents.filter(
        (el) =>
          el.subCategory === category &&
          el.createdDate <= dateTo &&
          el.createdDate >= dateFrom
      ) || []
    );
  });
