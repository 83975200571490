import React, { useState } from "react";
import {
  ExpiredEventsContainer,
  ClosedEventDate,
  ClosedEventTextContainer,
  ClosedEventTitle,
  ClosedEventLocation,
  ClosedEventHead,
  SelectWrapper,
  Select,
  WrapperImg,
  ClosedEventWrapper,
} from "./style";
import { SectionTitle } from "../style";
import { useSelector } from "react-redux";

export function ExpiredEvents({ data }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const theme = useSelector((state) => state.coreComponents.pageLight);
  const today = Date.now();
  const thisYear = new Date(today).getFullYear();
  // const [eventsYear, setEventsYear] = useState(thisYear);

  const YearsPastEvents = data?.pastEvents?.map((el) =>
    new Date(
      el?.subDetail?.appointments?.find((el) => el.type === "Event")?.startDate
    ).getFullYear()
  );
  const [eventsYear, setEventsYear] = useState(YearsPastEvents?.[0]);
  const arrayOfYears = YearsPastEvents?.filter(
    (item, pos) => YearsPastEvents?.indexOf(item) === pos
  ).sort((a, b) => Date.parse(b) - Date.parse(a));

  const expiredEvents = data?.pastEvents
    ?.filter((singleEvent) =>
      new Date(
        singleEvent?.subDetail?.appointments?.find(
          (el) => el.type === "Event"
        )?.startDate
      ).getFullYear() === +eventsYear
        ? singleEvent
        : ""
    )
    .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate));

  return (
    <ClosedEventWrapper>
      <ClosedEventHead>
        <SectionTitle>{data?.pastEventTitle}</SectionTitle>
        {data?.pastEvents?.length > 0 && (
          <SelectWrapper>
            <Select
              value={eventsYear}
              onChange={(e) => setEventsYear(e.target.value)}
            >
              {arrayOfYears?.map((year) => (
                <option key={year} name={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
            <WrapperImg>
              <img
                src={
                  theme !== "dark"
                    ? "/img/arrow-black.svg"
                    : "/img/arrow-down-header.svg"
                }
              />
            </WrapperImg>
          </SelectWrapper>
        )}
      </ClosedEventHead>
      {React.Children.toArray(
        expiredEvents?.map((singleEvent) => {
          const dateOfPastEvent = new Date(
            singleEvent?.subDetail?.appointments?.find(
              (el) => el.type === "Event"
            )?.startDate
          )
            .toLocaleString(lang, { day: "2-digit", month: "short" })
            .toUpperCase();
          return (
            <ExpiredEventsContainer>
              <ClosedEventDate>{dateOfPastEvent}</ClosedEventDate>
              <ClosedEventTextContainer>
                <ClosedEventTitle>
                  {singleEvent?.subDetail?.eventTitleTxt}
                </ClosedEventTitle>
                <ClosedEventLocation>
                  {
                    singleEvent?.subDetail?.appointments?.find(
                      (el) => el.type === "Event"
                    )?.locationTxt
                  }
                </ClosedEventLocation>
              </ClosedEventTextContainer>
            </ExpiredEventsContainer>
          );
        })
      )}
    </ClosedEventWrapper>
  );
}

export default React.memo(ExpiredEvents);
