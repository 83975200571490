import {
  FETCH_COMPONENTS,
  FETCH_DISHES,
  FETCH_DISH_TO_TRAY,
  FETCH_SET_FAVORITE,
  SET_USER_DATA,
  FETCH_CALORIC_INTAKE,
} from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const fetchDishes = () => ({
  type: FETCH_DISHES,
});

export const fetchDishToTray = (payload) => ({
  type: FETCH_DISH_TO_TRAY,
  payload,
});

export const FetchSetFavourite = (payload) => ({
  type: FETCH_SET_FAVORITE,
  payload,
});

export const SetUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
});

export const fetchCaloricIntake = (payload) => ({
  type: FETCH_CALORIC_INTAKE,
  payload,
});
