import styled from "styled-components";

export const MainEventWrapper = styled.div`
  position: relative;
  margin-bottom: 48px;

  img {
    display: block;
    max-width: 100%;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 96px;
  }

  @media screen and (max-width: 1279px) {
    img {
      object-fit: cover;
      margin: auto;
      min-height: 320px;
    }
  }
`;

export const EventContainer = styled.div`
  position: relative;
`;

export const DescrContainer = styled.div`
  width: 100%;
  color: rgb(255, 255, 255);
  padding: 40px;
  box-sizing: border-box;
  bottom: 0px;
  position: absolute;
  display: flex;
  align-items: center;
`;

export const FirstLine = styled.div`
  padding-right: 25px;
  border-right: 1px solid rgb(255, 255, 255);
  @media screen and (min-width: 1280px) {
    text-align: center;
    max-height: 122px;
  }
`;

export const DataNumber = styled.div`
  font-size: 22px;

  @media screen and (min-width: 1280px) {
    font-size: 64px;
  }
`;

export const Month = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 15px;
`;

export const SecondLine = styled.div`
  max-width: 542px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  @media screen and (min-width: 1280px) {
    gap: 12px;
  }
`;

export const Title = styled.div`
  align-self: flex-start;
  font-size: 16px;
  text-transform: uppercase;
  font-family: ferrari-sans-medium;

  @media screen and (min-width: 1280px) {
    font-size: 28px;
    line-height: 34px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 211px;
  }
`;

export const EventCta = styled.button`
  color: rgb(255, 255, 255);
  border-radius: 27px;
  padding: 5px 16px 7px;
  text-transform: uppercase;
  font: 8px/12px ferrari-sans-medium;
  border-color: rgba(255, 255, 255, 0.4);
  max-width: 140px;

  background: transparent;
  outline: none;
`;

export const EventSubscribeBtn = styled.button`
  color: rgb(255, 255, 255);
  border: none;

  background-color: rgb(218, 41, 28);
  background-image: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    50%,
    rgba(255, 255, 255, 0) 100%
  );

  background-blend-mode: overlay;
  padding: 20px 0px;
  width: 100%;
  cursor: pointer;

  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  outline: none;

  @media screen and (min-width: 1280px) {
    position: absolute;
    bottom: 32px;
    right: 32px;
    width: auto;
    padding: 19px 32px 22px;
    min-width: 280px;
  }
`;
