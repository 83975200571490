import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFilter,
  changeSort,
} from "../../../../../pages/AdsList/AdsListSlice";
import { selectFiltersState } from "../../../../../pages/AdsList/selectors";
import { SelectContainer, SelectItem } from "./style";
import CustomSelectPlugin from "../../../customSelectPlugin/CustomSelectPlugin";

function FiltersDropdown() {
  const dispatch = useDispatch();
  const filters = useSelector(selectFiltersState());
  const { filter, sort, filterSelect, sortSelect } = filters;
  const [filterSelectValue, setFilterSelectValue] = useState(
    filterSelect?.[0]?.label
  );
  const [sortSelectValue, setSortSelectValue] = useState(
    sortSelect?.[0]?.label
  );
  const changeFilterValue = (value) => {
    setFilterSelectValue(filterSelect.find((v) => v.key === value)?.label);
    dispatch(changeFilter(value));
  };
  const changeSortValue = (value) => {
    setSortSelectValue(sortSelect.find((v) => v.key === value)?.label);
    dispatch(changeSort(value));
  };
  useEffect(() => {
    setFilterSelectValue(sortSelect?.[0]?.label);
    setSortSelectValue(sortSelect?.[0]?.label);
  }, [sortSelect]);
  return (
    <>
      {filters && (
        <SelectContainer>
          {!!filterSelect?.length && (
            <SelectItem>
              <CustomSelectPlugin
                hideLabel={true}
                onChange={(ev) => changeFilterValue(ev.value)}
                placeholderString={filterSelectValue?.toUpperCase()}
                defaultValue={filterSelect?.[0]?.key}
                options={filterSelect.map((sort) => {
                  return { value: sort.key, label: sort.label };
                })}
              />
            </SelectItem>
          )}
          {!!sortSelect?.length && (
            <SelectItem>
              <CustomSelectPlugin
                hideLabel={true}
                onChange={(ev) => changeSortValue(ev.value)}
                value={sort}
                placeholderString={sortSelectValue?.toUpperCase()}
                defaultValue={sortSelect[0].key}
                options={sortSelect.map((sort) => {
                  return { value: sort.key, label: sort.label };
                })}
              />
            </SelectItem>
          )}
        </SelectContainer>
      )}
    </>
  );
}

FiltersDropdown.propTypes = {
  resourceType: PropTypes.string,
  // Form management
  filter: PropTypes.string,
  sort: PropTypes.string,
  // Select
  filterSelect: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  sortSelect: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
export default FiltersDropdown;
