import React from "react";
import PropTypes from "prop-types";

import FavoriteDish from "../favoriteDish/FavoriteDish";
import CafeteriaCard from "../cafeteriaCard/CafeteriaCard";
import CarouselWrapper from "../../carouselWrapper/CarouselWrapper";

const CafeteriaTopRatedSection = ({ dishes, data }) => {
  return (
    <>
      <div className="cafeteria-slot-1">
        <div className="favorite-dishes-wrapper">
          {dishes.map((dish, i) => (
            <FavoriteDish key={i} dish={dish} />
          ))}
        </div>
        {/* desktop */}
        <div className="cafeteria-cards">
          {React.Children.toArray(
            data?.cafeteriaCards?.items.map((currentCard) => (
              <CafeteriaCard cafeteriaCardProps={currentCard} />
            ))
          )}
        </div>
        {/* mobile  */}
        <div className="cafeteria-cards-mobile">
          <CarouselWrapper itemsLength={data?.cafeteriaCards?.items.length}>
            {React.Children.toArray(
              data?.cafeteriaCards?.items.map((currentCard) => (
                <CafeteriaCard cafeteriaCardProps={currentCard} />
              ))
            )}
          </CarouselWrapper>
        </div>
      </div>
    </>
  );
};
CafeteriaTopRatedSection.propTypes = {
  dishes: PropTypes.array,
  data: PropTypes.object,
};

export default CafeteriaTopRatedSection;
