import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { coreComponentsActions } from "../../../utils/getCoreComponents/coreComponentsSlice";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../../hooks/useMediaQuery";

function Submenu({ submenu, setLinkActive, pageLight, focusOnFirstElemnt }) {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const inlineStyle = isDesktop ? "inline" : "";
  const toggleSubSubMenu = (e, item) => {
    e.preventDefault();
    dispatch(coreComponentsActions.toggleSubSubMenu(item));
  };

  const setHover = (e, item, hStatus) => {
    e.preventDefault();
    const t = e.target;
    const parent = t.closest("ul");
    const listChildren = [...parent.children];
    const selected = parent.querySelector("a.selected");
    if (hStatus) {
      listChildren.forEach((v) => {
        const a = v.querySelector("a");
        a && a !== t && selected !== a && a.classList.add("unselected");
        a && a === t && a.classList.remove("unselected");
      });
    } else {
      listChildren.forEach((v) => {
        const a = v.querySelector("a");
        if (selected && selected !== a) a.classList.add("unselected");
        else a.classList.remove("unselected");
      });
    }
    //dispatch(coreComponentsActions.toggleHover({ item, hStatus }));
  };

  const updateAriaExpand = (item) => {
    return item.children ? (item.selected ? true : false) : undefined;
  };
  const areAllChildrenHided = (children) => {
    let result = true;
    children.forEach((el) => {
      if (!el.dontShow) {
        result = false;
      }
    });
    return result;
  };

  const SUBITEM_ID = "subItemId_";
  const getSubMenuIds = () => {
    let result = [];
    submenu.forEach((sub) => {
      if (sub.selected) {
        for (let i = 0; i < sub.children?.length; i++) {
          if (!sub.children[i].dontShow) {
            result.push(SUBITEM_ID + sub.level + "_" + i);
          }
        }
      }
    });
    return result;
  };

  const HandleKeyPress = (e, item) => {
    const itemsArray = getSubMenuIds();
    let index = itemsArray.indexOf(e.target.id);
    const lastItemIndex = itemsArray.length - 1;
    switch (e.keyCode) {
      //ESC
      case 27:
        e.preventDefault();
        document.getElementById("menuLabel_" + item?.idRoot)?.focus();
        dispatch(coreComponentsActions.removeSubMenu(item));
        break;
      //up arrow
      case 38:
        index >= 0 && document.getElementById(itemsArray[--index])?.focus();
        break;
      //right arrow
      case 32:
      case 13:
      case 39:
        if (item?.children) {
          const nextLevel = item.level + 1;
          toggleSubSubMenu(e, item);
          focusOnFirstElemnt(SUBITEM_ID + nextLevel + "_0");
        }
        break;
      //left arrow
      case 37:
        e.preventDefault();
        if (item?.level > 0) {
          const matches = document.querySelectorAll("a.selected");
          const array = Array.from(matches);
          const previousLevel = item.level - 1;

          array.forEach((el) => {
            const currentId = el.id;
            if (currentId.includes(SUBITEM_ID + previousLevel)) {
              document.getElementById(currentId)?.focus();
            }
          });
          dispatch(coreComponentsActions.removeSubSubMenu(item));
        }
        break;
      //down arrow
      case 40:
        e.preventDefault();
        if (index <= lastItemIndex)
          document.getElementById(itemsArray[++index])?.focus();
        break;
    }
  };

  return (
    <>
      {React.Children.toArray(
        submenu.map((sub) => (
          <ul className={`${sub.selected ? "selected" : ""}`}>
            {React.Children.toArray(
              sub.children?.map((item, i) => (
                <>
                  {!item.dontShow && (
                    <li>
                      {item.children && !areAllChildrenHided(item.children) ? (
                        <a
                          id={SUBITEM_ID + sub.level + "_" + i}
                          tabIndex="0"
                          role="menuitem"
                          aria-haspopup="true"
                          aria-expanded={updateAriaExpand(item)}
                          href="#"
                          style={{ cursor: "default", display: inlineStyle }}
                          className={`${
                            item.selected
                              ? "selected"
                              : item.unselected
                              ? "unselected"
                              : ""
                          }`}
                          onMouseOver={(event) =>
                            isDesktop ? toggleSubSubMenu(event, item) : null
                          }
                          onClick={(event) => {
                            if (!isDesktop) toggleSubSubMenu(event, item);
                          }}
                          onMouseEnter={(event) => setHover(event, item, true)}
                          onMouseOut={(event) => setHover(event, item, false)}
                          onKeyDown={(e) => HandleKeyPress(e, item)}
                        >
                          {item.title}
                          {pageLight !== "dark" ? (
                            <img src="/img/arrow-black.svg" />
                          ) : (
                            <img
                              src="/img/arrow-white.svg"
                              style={{ transform: "rotate(90deg)" }}
                            />
                          )}
                        </a>
                      ) : (
                        <NavLink
                          id={SUBITEM_ID + sub.level + "_" + i}
                          role="menuitem"
                          aria-haspopup="false"
                          to={item.path}
                          className={({ isActive }) => setLinkActive(isActive)}
                          style={{ display: inlineStyle }}
                          onMouseOver={(event) =>
                            isDesktop ? toggleSubSubMenu(event, item) : null
                          }
                          onMouseEnter={(event) => {
                            setHover(event, item, true);
                          }}
                          onMouseLeave={(event) => setHover(event, item, false)}
                          onKeyDown={(e) => HandleKeyPress(e, item)}
                        >
                          {item.title}
                        </NavLink>
                      )}
                    </li>
                  )}
                </>
              ))
            )}
          </ul>
        ))
      )}
    </>
  );
}
export default Submenu;
