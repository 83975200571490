import styled from "styled-components";

export const Wrapper = styled.div`
  width: 592px;
  margin: 96px auto 0px;
  margin-bottom: 80px;
  @media (max-width: 1279px) {
    width: 100%;
    margin-top: 40px;
  }
  h3:not(:first-child) {
    margin-top: 96px;
    display: flex;
    span {
      font-size: 11px;
      margin-left: auto;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 30px 0 40px 0;
  background-color: #d4d2d2;
`;

export const DetailsSection = styled.div`
  gap: 20px;
`;

export const SectionTitle = styled.h3`
  display: flex;
  font-family: ferrari-sans-medium, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
`;
