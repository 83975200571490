import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { notificationActions } from "../Notifications/notificationSlice";
import { fetchHomeComponents } from "./actions";
import { selectTitleName, selectHomeComponents } from "./selectors";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
// components
import LayoutHome from "../../components/UI/home/LayoutHome";
import HomeTitle from "../../components/UI/home/homeTitle/HomeTitle";
import NotificationBanner from "../../components/UI/home/alert/NotificationBanner";
import MainCarousel from "../../components/UI/home/mainCarousel/MainCarousel";
import CardList from "../../components/UI/home/CardList/CardList";
// utils
import useMediaQuery from "../../hooks/useMediaQuery";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import {
  loadBannerState,
  loadBannerTitle,
} from "../Notifications/notificationSlice";
import { selectAggregatedDirection } from "../MyPage/selectors";

const componentsMapping = {
  "fi-carousel": MainCarousel,
  "fi-homepage-main-sections": CardList,
  "fi-homepage-comunications": CardList,
  "fi-homepage-card-image": CardList,
  "fi-homepage-cards": CardList,
};

function Home({ path }) {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const userNameLogin = useSelector(
    (state) => state.login?.tokenClaims?.given_name
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const welcomeTitleData = useSelector(selectTitleName());

  const { notificationsArray, notificationsArrayNotRead } = useSelector(
    (state) => state.notifications
  );
  const homeComponents = useSelector(selectHomeComponents());

  const [notificationBanner, SetNotificationBanner] = useState(
    loadBannerState()
  );

  const handleNotificationBanner = () => {
    SetNotificationBanner(!notificationBanner);
    dispatch(
      notificationActions.setBannerState({
        bannerState: !notificationBanner,
        title: lastPriorityNotification.title,
      })
    );
  };

  const lastPriorityNotification = useMemo(() => {
    return notificationsArrayNotRead
      .filter((el) => el.priority === true)
      .sort((a, b) => b.createdDate - a.createdDate)[0];
  }, [notificationsArrayNotRead]);

  const isANewNotification = useMemo(() => {
    if (lastPriorityNotification?.title) {
      const currentBannerTitle = loadBannerTitle();
      if (lastPriorityNotification?.title === currentBannerTitle) {
        return false;
      } else {
        SetNotificationBanner(true);
        dispatch(
          notificationActions.setBannerState({
            bannerState: true,
            title: undefined,
          })
        );
        return true;
      }
    }
    return false;
  }, [notificationsArrayNotRead]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  return (
    <>
      {!notificationsArray && welcomeTitleData && userNameLogin ? (
        <PageLoader />
      ) : // MOBILE
      !isDesktop ? (
        <>
          {notificationBanner && isANewNotification && (
            <NotificationBanner
              handleNotificationBanner={handleNotificationBanner}
              lastPriorityNotification={lastPriorityNotification}
            />
          )}
          <LayoutHome>
            <>
              <HomeTitle
                data={welcomeTitleData}
                userNameLogin={userNameLogin}
              />
            </>
            {homeComponents?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return ReactGenericComponent &&
                current.resourceType !== "fi-carousel" ? (
                <ReactGenericComponent
                  key={`${current.resourceType}-${idx}`}
                  data={current}
                />
              ) : (
                <ReactGenericComponent
                  key={`${current.resourceType}-${idx}`}
                  data={current.data}
                />
              );
            })}
          </LayoutHome>
        </>
      ) : (
        <LayoutHome>
          <>
            <HomeTitle data={welcomeTitleData} userNameLogin={userNameLogin} />
            {notificationBanner && isANewNotification && (
              <NotificationBanner
                handleNotificationBanner={handleNotificationBanner}
                lastPriorityNotification={lastPriorityNotification}
              />
            )}
          </>
          {homeComponents?.map((current, idx) => {
            const ReactGenericComponent =
              componentsMapping[current.resourceType];
            if (ReactGenericComponent) {
              return current.resourceType !== "fi-carousel" ? (
                <ReactGenericComponent
                  key={`${current.resourceType}-${idx}`}
                  data={current}
                />
              ) : (
                <ReactGenericComponent
                  key={`${current.resourceType}-${idx}`}
                  data={current.data}
                />
              );
            }
          })}
        </LayoutHome>
      )}
    </>
  );
}

export default React.memo(Home);
