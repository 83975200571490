export default function GoToTop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      style={{ background: "var(--main-bg)", borderRadius: "100%" }}
      viewBox="0 0 40 40"
    >
      <path
        fill="#8F8F8F"
        fillRule="evenodd"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20zm0-2c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18z"
        clipRule="evenodd"
      ></path>
      <path
        fill="var(--main-color)"
        fillRule="evenodd"
        d="M21.451 12.63A1.99 1.99 0 0020 12a1.99 1.99 0 00-1.451.63l-.002-.004L12 20l7.003-3.501.497 10.779c0 .398.224.722.5.722s.5-.324.5-.722l.497-10.78L28 20l-6.547-7.373-.002.003z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
