import PropTypes from "prop-types";

// style
import { Wrapper } from "./style";

function FiImage({ data }) {
  return (
    <Wrapper>
      <img src={data.fileReference} alt={data.alt} />
    </Wrapper>
  );
}

FiImage.propTypes = {
  data: PropTypes.exact({
    isDecorative: PropTypes.string,
    linkTarget: PropTypes.string,
    titleValueFromDAM: PropTypes.string,
    imageFromPageImage: PropTypes.string,
    fileReference: PropTypes.string,
    alt: PropTypes.string,
    displayPopupTitle: PropTypes.string,
    position: PropTypes.string,
    altValueFromDAM: PropTypes.string,
  }),
};

export default FiImage;
