import styled from "styled-components";

export const ImgHeader = styled.img`
  margin-top: -92px;
  position: relative;
  z-index: -1;
  min-width: 100%;
`;

export const Section = styled.section`
  margin: 0 auto;
  width: 90%;
  padding-bottom: 90px;
  text-align: center;
  @media (min-width: 1280px) {
    width: 800px;
  }
`;
