import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 10;
  display: ${(props) => (props.showDialog ? "flex" : "none")};
  max-width: 600px;
  position: fixed;
  inset: 0px;
  margin: auto;
`;

export const Overlay = styled.div`
  background-color: rgba(43, 46, 56, 0.9);
  position: fixed;
  inset: 0px;
`;

export const Content = styled.div`
  margin: auto;
  z-index: 2;
  position: relative;
  background-color: white;
  padding: 48px;
  text-align: center;
  h2,
  p {
    color: #181818;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -84px;
  transform: translateX(-50%);
  left: 50%;
  border: 0px;
  cursor: pointer;
`;

export const Button = styled.button`
  color: #fff;
  #book-basket {
    color: rgb(255, 255, 255);
  }
`;
