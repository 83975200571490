import React from "react";
import { CardContainer, CardDescription, CardTitle } from "./style";
import SetQueryStringPath from "../../../../../utils/setQueryStringPath";

export function Card({ data }) {
  function generateHtml(argument) {
    return { __html: argument };
  }
  const redirectToUrl = SetQueryStringPath(data?.linkHref);
  return (
    <CardContainer
      onClick={() =>
        data?.linkHref ? window.location.replace(redirectToUrl) : ""
      }
      link={data?.linkHref}
    >
      <img src={data?.fileReference} />
      <CardTitle>{data?.title}</CardTitle>
      <CardDescription dangerouslySetInnerHTML={generateHtml(data?.richText)} />
    </CardContainer>
  );
}
