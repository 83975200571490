import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: var(--alternative-bg-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  box-sizing: border-box;
  @media screen and (max-width: 1279px) {
    gap: 24px !important;
  }
  & > div {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  a {
    color: var(--main-color);
  }
  p {
    margin-top: 0px;
  }
  p:last-child {
    margin-bottom: 0px;
  }
`;
