import { useDispatch, useSelector } from "react-redux";
import { fetchDishToTray } from "../../../../pages/Mensa/actions";
import { Wrapper, DishesCTA, Kilos, DishTitle } from "./style";
import { FetchSetFavourite } from "../../../../pages/Mensa/actions";
import ShowFooterBlack from "../../../assets/showFooterBlack";
import { useLocation } from "react-router-dom";

function CafeteriaDish({ dish }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const location = useLocation();
  const splittedUrl = location.pathname.split("cafeteria/");
  const restaurant = splittedUrl[1];
  return (
    <Wrapper>
      <DishTitle>
        {dish.title}
        {dish.isVegetarian && (
          <span>
            <img src="/img/veg.svg" />
          </span>
        )}
      </DishTitle>

      <div className="desktop-small">Allergeni*: {dish.allergens}</div>
      <p className="desktop-small">{dish.ingredients}</p>
      <Kilos>
        {dish.kcal}&nbsp;
        <span>kcal</span>
      </Kilos>
      <DishesCTA>
        <div>
          <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            className="pointer"
            onClick={() =>
              dispatch(FetchSetFavourite({ dish, restaurant, token }))
            }
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
              fill="#EFEDED"
            />
            <path
              d="M19.9962 27.4524L13.6314 20.6925L13.6314 20.6924C12.3353 19.3164 12.3228 17.1013 13.5951 15.7123L13.6313 15.6739C14.9195 14.3087 16.9749 14.3087 18.2634 15.674C18.2634 15.674 18.2634 15.674 18.2634 15.674L19.5311 17.0172L20.0038 17.5182L20.4765 17.0173L21.7363 15.6824L21.7364 15.6823C23.0246 14.3171 25.08 14.317 26.3685 15.6824C27.6772 17.069 27.6772 19.3138 26.3685 20.7005L26.8412 21.1466L26.3685 20.7005C25.2818 21.852 24.195 23.0035 23.1082 24.155C22.0708 25.2542 21.0335 26.3533 19.9962 27.4524Z"
              stroke="var(--cta-color)"
              fill={dish.like ? "var(--main-color)" : "transparent"}
              strokeWidth="1.3"
            />
          </svg>
          <span>{dish.likeNum}</span>
        </div>
        <div
          className="pointer"
          onClick={() => dispatch(fetchDishToTray(dish))}
        >
          {dish.isAdded ? (
            <img src="/img/dish-added.svg" />
          ) : (
            <>
              <span>AGGIUNGI</span>
              <ShowFooterBlack mainBg="transparent"></ShowFooterBlack>
            </>
          )}
        </div>
      </DishesCTA>
    </Wrapper>
  );
}

export default CafeteriaDish;
