import { useNavigate } from "react-router-dom";
import { TitleForm } from "../../formRichiestaEcard/style";
import { Container, DialogWrapper, RedirectButton } from "../style";
import { useDispatch } from "react-redux";
import { setShowThankYouPage } from "../../../../pages/FormContraffazioni/FormContraffazioniSlice";

export default function ThankYouPage({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function generateHtml(argument) {
    return { __html: argument };
  }

  return (
    <DialogWrapper>
      <TitleForm>
        {data?.title}
        <div dangerouslySetInnerHTML={generateHtml(data?.richText)} />
      </TitleForm>
      <Container center>
        <RedirectButton
          onClick={() => {
            dispatch(setShowThankYouPage(false));
            navigate(`${data?.linkHref}`);
          }}
        >
          {data?.buttonLabel}
        </RedirectButton>
      </Container>
    </DialogWrapper>
  );
}
