import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function WelfareOnScroll({ isOnTop, setIsOnTop }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY === 0 && !isOnTop) setIsOnTop(true);
      else if (window.scrollY > 0 && isOnTop) setIsOnTop(false);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [isOnTop, dispatch, setIsOnTop]);

  return null;
}
