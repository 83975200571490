import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// style
import { Card, Description, TitleAndText, Title, Text, TopText } from "./style";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

function WebinarCard({ data }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <Card>
      <Link
        aria-label={data.title}
        to={data?.path ? "/event-details?path=" + data.path : "#"}
      >
        <TopText isVisibile={data.availableUntil}>
          <span>{data.availableUntil}</span>
        </TopText>
        {isDesktop ? (
          <img src={data.image} />
        ) : (
          <img src={data.fileReferenceMobile || data.image} />
        )}
        <Description>
          <span>{data.subtitle}</span>
        </Description>
        <TitleAndText>
          <Title>{data.title}</Title>
          <Text>{data.description}</Text>
        </TitleAndText>
      </Link>
    </Card>
  );
}
WebinarCard.propTypes = {
  newsCardProps: PropTypes.exact({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    availableUntil: PropTypes.string,
  }),
};
export default React.memo(WebinarCard);
