import { Container, Divider } from "./style";

function Title() {
  return (
    <>
      <Container>Mappa del Sito</Container>
      <Divider />
    </>
  );
}

export default Title;
