import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
};

const twoThirdTemplateSlice = createSlice({
  name: "twoThird",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
  },
});

export const twoThirdTemplateActions = twoThirdTemplateSlice.actions;
export default {
  name: twoThirdTemplateSlice.name,
  reducer: twoThirdTemplateSlice.reducer,
};
