import React from "react";
import PropTypes from "prop-types";

const TitleSection = ({ data }) => {
  return data?.type === "h2" ? (
    <div className="cafeteria-h2">{data.jcrTitle}</div>
  ) : (
    <h1>{data.jcrTitle}</h1>
  );
};
TitleSection.propTypes = {
  data: PropTypes.shape({
    jcrTitle: PropTypes.string.isRequired,
    type: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default TitleSection;
