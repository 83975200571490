import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  MainEventWrapper,
  EventContainer,
  DescrContainer,
  FirstLine,
  DataNumber,
  Month,
  SecondLine,
  Title,
  EventCta,
  EventSubscribeBtn,
} from "./style";

import TitleSection from "../titleSection/TitleSection";
import useMediaQuery from "../../../../hooks/useMediaQuery";

function MainEvent({ data }) {
  const { event } = data;
  const title = { title: data.title, type: "h1" };
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const handleOnClick = (event) => {
    event.preventDefault();
    //redirect
  };

  return (
    <>
      <TitleSection data={title} />
      <MainEventWrapper>
        <Link to={event?.path ? "/event-details?path=" + event.path : "#"}>
          <EventContainer>
            {/* image path inside the MD JSON is not correct */}
            {isDesktop ? (
              <img src={data.fileReference} />
            ) : (
              <img src={event.fileReferenceMobile || data.fileReference} />
            )}
            <DescrContainer>
              <FirstLine>
                <DataNumber>{event.dateDay}</DataNumber>
                <Month>{event.dateMonth}</Month>
              </FirstLine>
              <SecondLine>
                <Title>{event.title}</Title>
                {/* type of Event missing inside the JSON */}
                {event.labelLiveStreaming && (
                  <EventCta>{event.labelLiveStreaming}</EventCta>
                )}
              </SecondLine>
            </DescrContainer>
          </EventContainer>
          {/* Lack of CTA description text */}
          <EventSubscribeBtn onClick={handleOnClick}>
            prenota ora
          </EventSubscribeBtn>
        </Link>
      </MainEventWrapper>
    </>
  );
}

MainEvent.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    link: PropTypes.string,
    fileReference: PropTypes.string,
    event: PropTypes.shape({
      title: PropTypes.string,
      dateDay: PropTypes.string,
      dateMonth: PropTypes.string,
      path: PropTypes.string,
      image: PropTypes.string,
      date: PropTypes.number,
      eventType: PropTypes.string,
      pathBooking: PropTypes.string,
      description: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    position: PropTypes.string,
  }),
};

export default MainEvent;
