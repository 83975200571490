import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  ${(props) => (props.id === 0 ? `margin:64px 0` : `margin:20px 0`)}
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;
export const TitleText = styled.div`
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
`;
export const TitleTrigger = styled.div`
  margin-left: auto;
  &.true {
    transform: rotate(45deg);
  }
`;
export const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  a {
    color: var(--main-color);
  }
`;
