import React from "react";
// style
import { Wrapper } from "./style";
//components
import ContactCard from "./contactCard/contactCard";
import UsefulContactCard from "../usefulContactsList/usefulContactCard/UsefulContactCard";
import { useSelector } from "react-redux";
import EmptyFavourites from "../emptyFavourites/EmptyFavourites";

function ContactsList() {
  const favorites = useSelector((state) => state.rubrica.favContacts);
  const usefulFav = useSelector((state) => state.rubrica.favUseful);

  return (
    <Wrapper>
      {!favorites.length && !usefulFav.length && <EmptyFavourites />}
      {React.Children.toArray(
        usefulFav.map((v) => {
          return <UsefulContactCard data={v} />;
        })
      )}
      {React.Children.toArray(
        favorites.map((v) => {
          return <ContactCard data={v} showDropDownArrow={true} />;
        })
      )}
    </Wrapper>
  );
}

export default ContactsList;
