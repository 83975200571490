import React from "react";
import { List } from "./style";
import PropTypes from "prop-types";
import useMediaQuery from "../../../../hooks/useMediaQuery";
//components
import WebinarCard from "./WebinarCard/WebinarCard";
import TitleAndText from "../TitleAndText/TitleAndText";
import CarouselWrapper from "../../carouselWrapper/CarouselWrapper";

const WEBINAR_CARD_COMPONENT_NAME = "webinar-card";

function WebinarCardList({ data }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const mapWebinar = data.events.map((currentCard, idx) => (
    <WebinarCard
      key={`${WEBINAR_CARD_COMPONENT_NAME}-${idx}`}
      data={currentCard}
    />
  ));

  const titleAndTextData = data.title
    ? { title: data.title, description: data.description }
    : { title: "Webinar", description: "Lorem ipsum dolor sit amet" };
  return (
    <>
      <TitleAndText data={titleAndTextData} />
      {isDesktop ? (
        <List>{mapWebinar}</List>
      ) : (
        <CarouselWrapper itemsLength={data?.events?.length}>
          {mapWebinar}
        </CarouselWrapper>
      )}
    </>
  );
}

WebinarCardList.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    description: PropTypes.string,
    path: PropTypes.string,
    hitsPerPage: PropTypes.number,
    events: PropTypes.array,
    position: PropTypes.string,
  }),
};
export default React.memo(WebinarCardList);
