export default function DatePickerIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3809_152013)">
        <path
          d="M21.0007 4C22.1053 4 23.0007 4.89543 23.0007 6V22C23.0007 23.1046 22.1053 24 21.0007 24H3.00073C1.89616 24 1.00073 23.1046 1.00073 22V6C1.00073 4.89543 1.89616 4 3.00073 4H21.0007ZM21.0007 6H3.00073V22H21.0007V6Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00061 0V4H6.00061V2C6.00061 0.895431 6.89604 0 8.00061 0Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0002 0V4H16.0002V2C16.0002 0.895431 16.8957 0 18.0002 0Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99805 13H8.99805C10.1026 13 10.998 13.8954 10.998 15H5.99805V13Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99805 17H8.99805C10.1026 17 10.998 17.8954 10.998 19H5.99805V17Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9994 13H15.9994C17.104 13 17.9994 13.8954 17.9994 15H12.9994V13Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9994 17H15.9994C17.104 17 17.9994 17.8954 17.9994 19H12.9994V17Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3982 9.08015V10L1.99819 10V8L21.318 8C21.9146 8 22.3982 8.4836 22.3982 9.08015Z"
          fill="var(--main-color)"
        />
      </g>
      <defs>
        <clipPath id="clip0_3809_152013">
          <rect width="24" height="24" fill="var(--main-color)" />
        </clipPath>
      </defs>
    </svg>
  );
}
