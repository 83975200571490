import { takeLatest, put } from "redux-saga/effects";

import { FETCH_COMPONENTS, SEND_EMAIL } from "./constants";
import { contactUsActions } from "./contactUsSlice";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

function* fetchContactUsComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(contactUsActions.setComponents(result.data.data.components));
    yield put(contactUsActions.setSettings(result.data.data.settings));
  } catch (e) {
    console.error(e);
    yield put(contactUsActions.setError(e));
  }
}

function* sendEmail({ payload }) {
  try {
    const body = {
      emailTo: payload.emailTo,
      subjectcode: payload.subject,
      message: payload.message,
    };
    yield axiosCallPost("/it/send-email", body, payload.token);
  } catch (e) {
    yield put(contactUsActions.setError(e));
  }
}

export default function* contactUsSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchContactUsComponents);
  yield takeLatest(SEND_EMAIL, sendEmail);
}
