import { createSlice } from "@reduxjs/toolkit";

export const FORM_RICHIESTA_ECARD_SCOPE = "FormRichiestaEcard";

export const initialState = {
  components: {},
  status: null,
  requestStatus: null,
  errorMessage: "",
  savedBodyPayload: {},
  userInfo: {},
  existEcard: null,
  hrList: [],
};

const FormRichiestaEcardSlice = createSlice({
  name: FORM_RICHIESTA_ECARD_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
      const data = state.components.components.find(
        (el) => el.resourceType === "fi-ecard-request/fi-ecard-request-form"
      )?.data;
      state.requestStatus = data?.pendingCard;
      const automatedFields = data?.automatedEcardFields;
      const hrList =
        data?.hrManagers?.isDebugMode === "true"
          ? data?.hrManagers?.items
          : data?.automatedEcardFields?.hrBusinessPartners;
      const isOneHr = hrList?.length === 1;
      /*
        When the user doesn't have an eCard fax and phone are undefined.
        When an eCard is already approved and we want to modify it the MW returns a null or an empty string value on fax and phone,
        therefore we force to undefined fax and phone if they are null or "".
        In this way when we compare savedBody with userInfo, for each attribute of both objects we have a value or it is not present(undefined). 
      */
      state.savedBodyPayload = {
        name: automatedFields?.name,
        lastName: automatedFields?.lastName,
        fax: undefined,
        depAndJobPos: automatedFields?.depAndJobPos,
        corporateAddress: undefined,
        phone: undefined,
        mobilePhone: undefined,
        email: automatedFields?.email,
        company: automatedFields?.company,
        hrReviewerId: isOneHr ? hrList?.[0]?.subjectCode : undefined,
        hrReviewerName: isOneHr ? hrList?.[0]?.name : undefined,
        hrReviewerLastName: isOneHr ? hrList?.[0]?.surname : undefined,
        hrReviewerEmail: isOneHr ? hrList?.[0]?.email : undefined,
      };

      state.existEcard = data?.status ? true : null;
      state.userInfo = state.savedBodyPayload;
      state.hrList = hrList;
    },
    setRequestStatus(state, action) {
      state.requestStatus = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setStatusNull(state) {
      state.status = null;
    },
    fetchComponents: () => {},
    resetFormDataRequestEcard: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  fetchComponents,
  setUserInfo,
  setRequestStatus,
  resetFormDataRequestEcard,
} = FormRichiestaEcardSlice.actions;
export default FormRichiestaEcardSlice;
