import styled from "styled-components";

export const NoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  color: var(--main-color);
  img {
    width: 40px;
    height: 40px;
  }
`;

export const ButtonNoContent = styled.svg.attrs({
    width: "40",
    height: "40",
    viewBox: "0 0 40 40",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
  })`
    cursor: pointer;
    pointer-events: none;
  `;
  export const ButtonNoContentCircle = styled.path.attrs({
    fillRule: "evenodd",
    d: "M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z",
  })`
    fill: #d4d2d2;
    pointer-events: none;
  `;
  export const ButtonNoContentImg = styled.path.attrs({
    d: "M13.3333 16.3332C14.0697 16.3332 14.6667 15.7362 14.6667 14.9998C14.6667 14.2635 14.0697 13.6665 13.3333 13.6665C12.597 13.6665 12 14.2635 12 14.9998C12 15.7362 12.597 16.3332 13.3333 16.3332ZM13.3333 21.6665C14.0697 21.6665 14.6667 21.0696 14.6667 20.3332C14.6667 19.5968 14.0697 18.9998 13.3333 18.9998C12.597 18.9998 12 19.5968 12 20.3332C12 21.0696 12.597 21.6665 13.3333 21.6665ZM14.6667 25.6665C14.6667 26.4029 14.0697 26.9998 13.3333 26.9998C12.597 26.9998 12 26.4029 12 25.6665C12 24.9301 12.597 24.3332 13.3333 24.3332C14.0697 24.3332 14.6667 24.9301 14.6667 25.6665ZM26 13.9998H16.6667V15.9998H28C28 14.8953 27.1046 13.9998 26 13.9998ZM16.6667 19.3332H26C27.1046 19.3332 28 20.2286 28 21.3332H16.6667V19.3332ZM26 24.6665H16.6667V26.6665H28C28 25.5619 27.1046 24.6665 26 24.6665Z",
  })`
    fill: var(--main-color);
    pointer-events: none;
  `;
  