import styled from "styled-components";

export const Container = styled.div`
  width: 512px;
  margin: 0 auto 96px;

  @media (max-width: 1279px) {
    width: 512px;
  }
  @media (max-width: 601px) {
    width: auto;
  }
`;

export const GenericTitle = styled.div`
  width: 100%;
  margin: 0px auto;
  text-align: center;
  font-size: 28px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 34px;
  padding: 0;

  p {
    font-family: ferrari-sans-regular, sans-serif;
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;

export const RecapContainer = styled.div`
  padding: 40px;
  background-color:var(--alternative-bg-color);
`;
export const TitleForm = styled.h3`
  font-size: 18px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 28px;
  margin: 30px 0;
  font-weight: 400;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #efeded;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  margin-top: -92px;
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 100%;

  &::before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &::after {
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    left: 0px;
    top: 0px;
    width: 100%;
    height: 205px;
    content: "";
  }
  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 96px;
  }
  @media screen and (max-width: 1279px) {
    img {
      object-fit: cover;
      margin: auto;
      min-height: 320px;
    }
  }
`;