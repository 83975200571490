import { Title, Text } from "./style";

function EventDescription({ elementData }) {
  return (
    <>
      <Title>{elementData.title}</Title>
      <Text dangerouslySetInnerHTML={{ __html: elementData.richText }}></Text>
    </>
  );
}

export default EventDescription;
