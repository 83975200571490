import styled from "styled-components";

export const ClosedEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const ExpiredEventsContainer = styled.div`
  width: 100%;
  display: flex;
  line-height: 15px;
  letter-spacing: 1px;
`;
export const ClosedEventDate = styled.div`
  width: 100px;
  gap: 24px;
  font-family: ferrari-sans-medium;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 1px;
`;
export const ClosedEventTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  gap: 4px;
`;
export const ClosedEventTitle = styled.div`
  text-align: start;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;
export const ClosedEventLocation = styled.div`
  font-family: ferrari-sans-regular;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: start;
  color: #707070;
`;
export const ClosedEventHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const SelectWrapper = styled.div`
  width: 176px;
  height: 56px;
  border: 1px solid #D4D2D2;
  position: relative;
  display: flex;
  select {
    border: none;
    font-size: 11px;
  }
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
  }
  @media (max-width: 1279px) {
    width: 95%;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 20px 24px;
  cursor: pointer;
  appearance: none;
  &,
  select,
  option {
    min-width: 110px;
    font-family: ferrari-sans-regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: var(--main-color);
    padding: 12px 16px 14px;
    background: transparent;
  }
  &.placeholder {
    color: rgb(112, 112, 112) !important;
  }
`;
export const WrapperImg = styled.div`
  max-width: 100%;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  img{
    width: 16px;
    height: 16px;
  }
`;
