import styled from "styled-components";

export const WrapperNextEvents = styled.div`
  margin-bottom: 40px;
  a {
    display: flex;
    align-items: center;
    margin-top: 30px;
    line-height: 15px;
    letter-spacing: 1px;
    width: 100%;
    color: var(--main-color);
    &:first-child {
      margin-top: 10px;
    }
  }
`;
// export const ContainerNextEvent = styled.a`
//   display: flex;
//   align-items: center;
//   margin-top: 30px;
//   line-height: 15px;
//   letter-spacing: 1px;
//   width: 100%;
//   color: var(--main-color);
//   &:first-child {
//     margin-top: 10px;
//   }
// `;
export const ContainerCardImg = styled.div`
  min-width: 103px;
  width: 103px;
  height: 103px;
  margin-right: 25px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: scale-down;
  border-radius: 4px;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 185px;
    min-width: 177px;
  }
  @media (max-width: 1279px) {
    min-width: 115px;
    width: 115px;
    height: 118px;
  }
`;
export const ContainerCardDetails = styled.div`
  justify-content: start;
  align-items: center;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1279px) {
    width: 80%;
  }
`;
export const CardDetailsDate = styled.div`
  font-family: ferrari-sans-regular;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  font-weight: 400;
`;
export const CardDetailsTitle = styled.div`
  font-size: 18px;
  text-align: start;
  font-weight: 400;
  line-height: 26px;
  white-space: wrap;
`;
export const CardLocationContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const CardDetailsLocation = styled.div`
  font-family: ferrari-sans-regular;
  font-size: 13px;
  text-align: start;
  color: #707070;
  line-height: 20px;
  font-weight: 400;
  margin-top: 8px;
  letter-spacing: 0.4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
