import styled from "styled-components";
import arrowLeftBlack from "../../assets/arrow-datepicker-left-black.svg";
import arrowRightBlack from "../../assets/arrow-datepicker-right-black.svg";
import arrowLeftWhite from "../../assets/arrow-datepicker-left-white.svg";
import arrowRightWhite from "../../assets/arrow-datepicker-right-white.svg";
import datePickerSelectedBlack from "../../assets/datepicker-selected-black.svg";
import datePickerSelectedWhite from "../../assets/datepicker-selected-white.svg";
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 18px;
    padding: 0 0 16px;
    @media (max-width: 1279px) {
      font-size: 13px;
    }
  }
  & > svg {
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
  }
  .react-datepicker__month-container {
    width: 384px;
    max-width: 100%;
  }
  .react-datepicker__current-month {
    font-family: "ferrari-sans-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--main-color);
  }
  .react-datepicker {
    font-family: "ferrari-sans-regular";
    background: var(--main-bg);
    padding: 32px 32px 14px;
    box-sizing: content-box;
    max-width: calc(100vw - 66px);
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    color: var(--main-bg);
  }
  .react-datepicker__month-container .react-datepicker__header {
    background: var(--main-bg);
    border: 0;
    padding: 0;
  }
  .react-datepicker__day {
    color: var(--main-color);
  }
  .react-datepicker__day-name {
    font-size: 9px;
    line-height: 14px;
    /* identical to box height, or 156% */

    text-align: center;
    letter-spacing: 1.22727px;
    text-transform: uppercase;
    color: var(--carousel-dot-color);
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    font-size: 11px;
    width: calc(100% / 7);
    margin: 0;
    line-height: normal;
    padding: 13px 0;
  }
  .react-datepicker__day-names {
    margin-bottom: 0;
    margin-top: 27px;
  }

  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__navigation-icon--previous {
    background: url(${(props) =>
      props.pageLight === "dark" ? arrowLeftWhite : arrowLeftBlack});
    width: 8px;
    height: 16px;
  }
  .react-datepicker__navigation-icon--next {
    background: url(${(props) =>
      props.pageLight === "dark" ? arrowRightWhite : arrowRightBlack});
    width: 8px;
    height: 16px;
  }
  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    display: none;
  }
  .react-datepicker__navigation--previous {
    left: 32px;
    top: 27px;
  }
  .react-datepicker__navigation--next {
    right: 32px;
    top: 27px;
  }
  .react-datepicker__day--outside-month {
    opacity: 0.4;
  }
  .react-datepicker__day--selected {
    border-radius: 100%;
    background: url(${(props) =>
      props.pageLight === "dark"
        ? datePickerSelectedWhite
        : datePickerSelectedBlack});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--main-bg);
  }
  .react-datepicker__day--disabled {
    opacity: 0.1;
  }
  @media (max-width: 1279px) {
    .react-datepicker {
      border: 0;
    }
    .react-datepicker__portal {
      z-index: 999;
    }
    .react-datepicker__portal > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: var(--main-bg);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export const CloseButton = styled.button`
  background: transparent;
  border: 0;
  position: fixed;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2147483648;
  cursor: pointer;
  @media (min-width: 1280px) {
    display: none !important;
  }
`;
