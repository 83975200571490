import { useDispatch, useSelector } from "react-redux";
// style
import { Wrapper, Overlay, Content, CloseButton, Button } from "./style";
import { useState } from "react";

function BasicConfirmDialog({
  showDialog = false,
  title,
  text,
  closeButtonText,
  closeFunction = () => {},
}) {
  const dispatch = useDispatch();
  return (
    <Wrapper showDialog={showDialog}>
      <Overlay></Overlay>
      <Content>
        <CloseButton onClick={() => closeFunction()}>
          <img src="/img/close-light.svg" width="40" height="40" />
        </CloseButton>
        <h2>{title}</h2>
        <p className="desktop-body">{text}</p>
        <Button
          className="button-main-red desktop-cta"
          id="book-basket"
          onClick={(e) => {
            closeFunction();
          }}
        >
          {closeButtonText}
        </Button>
      </Content>
    </Wrapper>
  );
}

export default BasicConfirmDialog;
