import styled from "styled-components";

export const Container = styled.section`
  width: 600px;
  margin: 0 auto 96px;
  ${(props) =>
    props.center ? "display: flex; justify-content: center" : "display: block"};
  h2 {
    margin: 96px 0 24px;
    text-align: center;
  }
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input,
  & select {
    letter-spacing: 0.5px;
  }
  textarea {
    width: 100%;
    background: transparent;
    padding: 8px;
    font: 14px / 20px ferrari-sans-regular;
    resize: vertical;
    box-sizing: border-box;
  }
  form {
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    gap: 44px;
  }

  @media (max-width: 1279px) {
    width: 90%;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  z-index: 2;
  position: relative;
  inset: calc(50% - 250px) 0px 0px;

  @media (max-width: 1279px) {
    inset: calc(50% - 350px) 0px 0px;
  }
`;
export const TitleForm = styled.h2`
  max-width: 800px;
  font-size: 36px;
  font-family: ferrari-sans-medium, sans-serif;
  text-align: center;
  line-height: 34px;
  display: flex;
  flex-direction: column;
  align-self: center;
  div {
    text-align: center;
    p {
      line-height: 20px;
      margin-top: 30px;
      font-family: ferrari-sans-regular, sans-serif;
      font-size: 13px;
      font-weight: 400;
    }
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;

export const SectionForm = styled.div`
  h3 {
    text-transform: uppercase;
  }
`;

export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 32px;
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0px 0px 0px;
  gap: 7px;
  position: relative;
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 7px 0px;
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
  & input::placeholder {
    color: #707070;
  }
  input:not(:placeholder-shown) + label {
    opacity: 1;
  }
`;

export const RequiredValue = styled.p`
  color: #da291c;
  font-weight: 400;
  font-size: 11px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0;
`;

export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  margin: auto;
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;
  &.activeSubmit {
    color: rgb(255, 255, 255);
    background: radial-gradient(
        199.89% 50% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        349.37% 116.89% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      rgb(218, 41, 28);
  }

  @media (max-width: 1279px) {
    max-width: 100%;
  }
`;
export const RedirectButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  margin-top: -92px;
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 100%;

  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 96px;
    margin-top: -92px;
    position: relative;
    z-index: 1;
    max-width: 100%;
    width: 100%;
  }
  @media screen and (max-width: 1279px) {
    img {
      object-fit: cover;
      margin: auto;
      min-height: 320px;
    }
  }
`;
