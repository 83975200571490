// components
import { useDispatch } from "react-redux";
import DialogContent from "./dialogContent/DialogContent";
// style
import { DialogWrapper, DialogOverlay, CloseButton } from "./style";
// actions
import { notificationActions } from "../../../../pages/Notifications/notificationSlice";
import TargetTrap from "../../../../utils/targetTrap";

function NotificationDialog() {
  const dispatch = useDispatch();
  document.body.style.overflow = "hidden";
  const closeButtonHandler = () => {
    document.body.style.overflow = "";
    dispatch(notificationActions.setShowNotificationDialog(false));
  };

  const HandleKeyPress = (e) => {
    switch (e.keyCode) {
      //Esc
      case 27:
        e.preventDefault();
        closeButtonHandler();
        document.getElementById("notificationIcon").focus();
        break;
      case 9:
        TargetTrap(e, "notificationDialog");
        break;
    }
  };

  return (
    <DialogWrapper id="notificationDialog" onKeyDown={(e) => HandleKeyPress(e)}>
      <DialogOverlay onClick={closeButtonHandler} />
      <CloseButton tabIndex="0" aria-label="close" onClick={closeButtonHandler}>
        <img src="/img/close-light.svg" width="40" height="40" />
      </CloseButton>
      <DialogContent />
    </DialogWrapper>
  );
}

export default NotificationDialog;
