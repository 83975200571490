import styled from "styled-components";

export const Wrapper = styled.div`
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1280px) {
    margin-bottom: 2em;
  }
  @media screen and (max-width: 1279px) {
  }
`;

export const SelectMobileWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  gap: 32px;
  display: flex;
  select {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
`;

export const SelectContainer = styled.div`
  gap: 32px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const SelectItem = styled.div`
  position: relative;
  display: block;
  width: 298px;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;
