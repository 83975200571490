import { useDispatch } from "react-redux";
import { Wrapper, Overlay, BoxContainer, ConfirmButton } from "./style";
import { RecapAndDetailGiftHouseLeActions } from "../../../../pages/RecapAndDetailGiftHouseLe/RecapAndDetailGiftHouseLeSlice";

export function DialogNote({ data }) {
  const dispatch = useDispatch();
  function generateHtml(argument) {
    return { __html: argument };
  }

  let handleOnClick = (e) => {
    dispatch(RecapAndDetailGiftHouseLeActions.setShowModal(false));
  };
  return (
    <Wrapper>
      <Overlay />
      <BoxContainer>
        <h2>{data?.title}</h2>
        <div dangerouslySetInnerHTML={generateHtml(data?.description)} />
        <ConfirmButton onClick={(e) => handleOnClick(e)}>
          {data?.buttonLabel}
        </ConfirmButton>
      </BoxContainer>
    </Wrapper>
  );
}
