import styled from "styled-components";

export const Fragment = styled.div`
a{
  width: 592px;
  margin: 46px auto 0px;
  border 1px solid;
  border-color: reverse var(--alternative-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => (props.qrCode ? "margin-bottom: 0" : "margin-bottom: 80px")};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  @media (max-width: 1279px) {
    max-width: 592px;
    width: 100%;
    margin-top: 40px;
  }
}`;
export const Wrapper = styled.div`
  width: 592px;
  margin: 96px auto 0px;
  background-color: var(--alternative-bg-color);
  margin-bottom: 80px;
  @media (max-width: 1279px) {
    width: 100%;
    margin-top: 40px;
  }
  h3:not(:first-child) {
    margin-top: 96px;
    display: flex;
    span {
      font-size: 11px;
      margin-left: auto;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;
export const Divider = styled.div`
  height: 1px;
  background-color: #d4d2d2;
  width: 100%;
`;

export const DetailsSection = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const WrapperNoBusinessCard = styled.div`
  max-width: 592px;
  margin: 96px auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
