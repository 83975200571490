import { Container } from "./style";

function LayoutContratto({ children, backLink }) {
  return (
    <>
      <Container>{children}</Container>
    </>
  );
}

export default LayoutContratto;
