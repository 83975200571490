import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// utils
import { axiosCallDownloadDoc } from "../../../../../../../utils/axiosCalls/AxiosCalls";
import SetQueryStringPath from "../../../../../../../utils/setQueryStringPath";
// actions
import { globalSearchActions } from "../../../../../../../pages/GlobalSearch/globalSearchSlice";
// style
import { Wrapper, Title, Description } from "./style";

function NewsDoc({ data, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.activeToken);
  const theme = useSelector((state) => state.coreComponents.pageLight);
  const lang = useSelector((state) => state.coreComponents.language);

  const downloadDocHandler = () => {
    axiosCallDownloadDoc(lang, data.documentPath, data.title, token);
  };

  const baseUrl = process.env.REACT_APP_ENV.startsWith("local")
    ? "http://localhost:3000"
    : window.location.origin;
  const linkToDoc = SetQueryStringPath(data?.linkHref);

  const navigateToPath = () => {
    navigate(linkToDoc.replace(baseUrl, ""));
    dispatch(globalSearchActions.resetState(false));
  };

  const HandleKeySearchItemPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        navigateToPath();
        break;
    }
  };

  return (
    <Wrapper
      tabIndex="0"
      id={id}
      onKeyDown={(e) => HandleKeySearchItemPressed(e)}
    >
      <div>
        <Title>{data?.title}</Title>
        <Description onClick={navigateToPath}>
          da: {data?.description}
        </Description>
      </div>
      <div className="download" onClick={downloadDocHandler}>
        <svg
          width={41}
          height={40}
          viewBox="0 0 41 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40ZM20.5 38C30.4411 38 38.5 29.9411 38.5 20C38.5 10.0589 30.4411 2 20.5 2C10.5589 2 2.5 10.0589 2.5 20C2.5 29.9411 10.5589 38 20.5 38Z"
            fillOpacity={theme === "dark" ? "1" : "0.4"}
            fill="var(--main-color)"
          ></path>
          <path
            fill="var(--main-color)"
            clipRule="evenodd"
            d="M20.5 25.3333C19.927 25.3333 19.4135 25.0888 19.049 24.7028L19.047 24.7068L12.5 17.3333L19.5025 20.8346L20 10.0555C20 9.65687 20.224 9.33331 20.5 9.33331C20.776 9.33331 21 9.65687 21 10.0555L21.4975 20.8346L28.5 17.3333L21.953 24.7068L21.951 24.7028C21.5865 25.0888 21.073 25.3333 20.5 25.3333ZM20.5 29.3333H12.5C12.5 28.597 13.3955 28 14.5 28H20.5H26.5C27.6045 28 28.5 28.597 28.5 29.3333H20.5Z"
          ></path>
        </svg>
      </div>
    </Wrapper>
  );
}

export default React.memo(NewsDoc);
