import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  dishes: [],
  components: {},
  status: null,
  errorMessage: "",
};

const topRatedDishesSlice = createSlice({
  name: "topRatedDishes",
  initialState,
  reducers: {
    setDishes(state, action) {
      state.status = 200;
      state.dishes = action.payload.slice(0, 10);
    },
    setComponents(state, action) {
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const topRatedDishesAction = topRatedDishesSlice.actions;
export default {
  name: topRatedDishesSlice.name,
  reducer: topRatedDishesSlice.reducer,
};
