import styled from "styled-components";

export const RadioButton = styled.div`
  margin-bottom: 18px;
  text-transform: uppercase;
  label {
    vertical-align: middle;
  }
  input {
    vertical-align: middle;
    margin-right: 8px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    &:disabled {
      box-shadow: inset 0 0 0 2.5px #white;
      outline: 0.1px solid #d4d2d2;
      background-color: white;
      appearance: none;
      margin: 3px 8px 0px 5px;
      padding: 6px;
    }
    &:checked {
      outline: 0.1px solid black;
      background-color: #000;
      appearance: none;
      margin: 3px 8px 0px 5px;
      padding: 6px;
      box-shadow: inset 0 0 0 2.5px #ffff;
      &:disabled {
        box-shadow: inset 0 0 0 2.5px white;
        outline: 0.1px solid #d4d2d2;
        background-color: #d4d2d2;
        appearance: none;
        margin: 3px 8px 0px 5px;
        padding: 6px;
      }
    }
  }
`;

export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 32px;
`;
