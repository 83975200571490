import React, { useRef } from "react";

function SuggestedItem({
  item,
  id,
  suggestedClickHandler,
  suggestedKeyDownHandler,
}) {
  const suggestedRef = useRef();

  const handleOnClick = () => {
    suggestedClickHandler(suggestedRef);
    document.getElementById("searchInput").focus();
  };

  return (
    <p
      id={id}
      role="button"
      tabIndex="0"
      aria-label={item.tip}
      ref={suggestedRef}
      onClick={handleOnClick}
      onKeyDown={(e) => suggestedKeyDownHandler(e)}
    >
      {item.tip}
    </p>
  );
}

export default React.memo(SuggestedItem);
