import styled from "styled-components";

export const Slot = styled.div`
  position: relative;
  &::before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  h3 {
    color: rgb(255, 255, 255);
    font-family: ferrari-sans-medium;
    text-transform: uppercase;
    max-width: none;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1px;
  }
  img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 1279px) {
  }
`;

export const FocusText = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 32px;
  @media screen and (max-width: 1279px) {
    text-align: left;
  }
`;

export const PreTitle = styled.div`
  color: rgb(255, 255, 255);
  display: flex;
  margin: 24px 0px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  @media screen and (min-width: 1280px) {
    margin-bottom: 8px;
  }
`;

export const EventCta = styled.button`
  color: rgb(255, 255, 255);
  border-radius: 27px;
  padding: 5px 16px 7px;
  text-transform: uppercase;
  font: 8px/12px ferrari-sans-medium;
  border-color: rgba(255, 255, 255, 0.4);
  max-width: 140px;

  background: transparent;
  outline: none;
`;
