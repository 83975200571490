import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1279px) {
    flex-direction: column;
  }
`;
export const CtaLink = styled.a`
  width: 100%;
  max-width: ${(props) => (props.isClosed ? "100%" : "300px")};
  @media (max-width: 1279px) {
    width: 100%;
    max-width: 100%;
  }
`;
export const CtaContainerButton = styled.div`
  width: 100%;
  max-width: ${(props) => (props.isClosed ? "100%" : "300px")};
  @media (max-width: 1279px) {
    width: 100%;
    max-width: 100%;
  }
`;
export const CtaButton = styled.button.attrs((props) => ({
  type: "button",
}))`
  color: ${(props) => props.color || "#fff"};
  white-space: nowrap;
  width: 100%;
  max-width: ${(props) => (props.isClosed ? "100%" : "300px")};
  padding: 20px 0px;
  cursor: pointer;
  border: ${(props) => props.border || "0px"};
  background: ${(props) =>
    props.bg ||
    "radial-gradient(199.89% 50% at 50% 100%,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0) 100% ),radial-gradient(349.37% 116.89% at 50% 100%,rgba(255, 255, 255, 0.5) 0%,rgba(0, 0, 0, 0.5) 100%), rgb(218, 41, 28);"};
  background-blend-mode: overlay, overlay, normal;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 15px;
  &:disabled {
    background: #d4d2d2;
    color: #181818;
    cursor: not-allowed;
  }
  @media (max-width: 1279px) {
    width: 100%;
    max-width: 100%;
  }
`;
