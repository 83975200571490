import styled from "styled-components";

export const Wrapper = styled.div`
  display: none;
  @media screen and (min-width: 1280px) {
    width: 350px;
    align-self: flex-start;
    position: sticky;
    top: 0;
    display: block;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  li {
    padding: 0 0 0 15px;
    border-left: 4px solid transparent;
    width: 100%;
  }
  li.selected {
    border-color: #da291c;
  }
  a {
    color: var(--main-color);
  }
`;
