import { Link } from "react-router-dom";
import SetQueryStringPath from "../../../utils/setQueryStringPath";
import linkTarget from "../../../utils/linkTarget";

//spostarlo in ui
export function RedirectTo({ element, useRef, children }) {
  const baseUrl = process.env.REACT_APP_ENV.startsWith("local")
    ? "http://localhost:3000"
    : window.location.origin;

  const target = linkTarget(element?.linkHref);
  const isInsideTheIntranet = target === "_self";

  let linkResult = SetQueryStringPath(element?.linkHref);

  return isInsideTheIntranet ? (
    <Link ref={useRef && useRef} to={linkResult?.replace(baseUrl, "")}>
      {children}
    </Link>
  ) : (
    <a ref={useRef && useRef} href={element?.linkHref} target={target}>
      {children}
    </a>
  );
}
