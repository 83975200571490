export default function PlayIcon() {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.625 0L9.84188 8.18375L9.83687 8.18625C10.3194 8.64187 10.625 9.28375 10.625 10C10.625 10.7162 10.3194 11.3581 9.83687 11.8138L9.84188 11.8162L0.625 20V0Z"
        fill="var(--main-color)"
      />
    </svg>
  );
}
