import { createSlice } from "@reduxjs/toolkit";

export const FORM_ISCRIZIONE_GIFTHOUSE_SCOPE = "FormIscrizioneGiftHouse";

export const initialState = {
  components: {},
  checkedConsents: [],
  status: null,
  errorMessage: "",
  guestLimit: 4,
  defaultUserInfo: {},
  giftHouseLeComponents: [],
  appointmentLeWithGh: null,
  currentStep: null,
  subscribeError: null,
};

const FormIscrizioneGiftHouseSlice = createSlice({
  name: FORM_ISCRIZIONE_GIFTHOUSE_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setEventInfo(state, action) {
      state.giftHouseLeComponents.forEach((component) => {
        if (component.name === action.payload.name) {
          component.value = action.payload.value;
        }
      });
    },
    setCheckedConsents(state, action) {
      state.checkedConsents = action.payload;
    },
    setAppointmentLeWithGh(state, action) {
      state.appointmentLeWithGh = action.payload;
    },
    initEventInfo(state, action) {
      const Data = action.payload.data;
      const coniuge = Data?.familyData?.familyMembers?.find(
        (member) =>
          member.relationship === "CONVIV" || member.relationship === "CONIUG"
      );
      const figli = Data?.familyData?.familyMembers?.filter(
        (member) => member.relationship === "FIGLIO"
      );
      const childrensInAge = figli?.filter((figlio) => {
        let year = +new Date(figlio?.birthDate).getFullYear();
        if (year > 2012) return figlio;
      });
      const childrensSubscribed = childrensInAge?.filter(
        (figlio) => figlio?.subscriptionInfo?.isSubscribed === true
      );
      state.giftHouseLeComponents = [
        { name: "NucleoFamiliare", value: null, step: 1 },
        { name: "SedeLavoro", value: null, step: 2 },
        {
          name: "AppointmentIdGhAndLe",
          value: null,
          secondaryValue: null,
          step: 2,
        },
        { name: "modalitaRitiro", value: null, step: 3 },
        { name: "AppointmentIdGift", value: null, step: 4 },
        { name: "GtModel", value: null, step: 5 },
        { name: "lightExperience", value: null, step: 6 },
        { name: "NumGuests", value: null, step: 7 },
        { name: "AppointmentIdLe", value: null, step: 8 },
      ];

      state.defaultUserInfo = {
        type: "giftHouse",
        userFiscalCode: Data?.familyData?.fiscalCode,
        eventCodeGiftHouse: Data?.giftHouse?.eventCode,
        eventCodeLightExperience: Data?.lightExperience?.eventCode,
        consentsGiftHouse: Data?.giftHouse?.consents,
        consentsLightExperience: Data?.lightExperience?.consents,
        familyComponents: Data?.familyData?.familyMembers,
        isMarried: coniuge && coniuge?.isEmployee,
        consortRegistered:
          coniuge?.subscriptionInfo?.isSubscribed && coniuge?.isEmployee,
        childsInAge: childrensInAge,
        childsSubscribed: childrensSubscribed,
      };
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setCurrentStep(state, action) {
      if (state.currentStep > action.payload) state.checkedConsents = [];
      const modalitaRitiro = state.giftHouseLeComponents.find(
        (e) => e.name === "modalitaRitiro"
      );
      state.currentStep = action.payload;
      state.giftHouseLeComponents.forEach((component) => {
        if (component.step > action.payload) {
          if (
            component.name === "lightExperience" &&
            modalitaRitiro.value === "ritiroLe"
          )
            return;
          return (component.value = null);
        }
      });
    },
    setSubscribeError(state, action) {
      const response = action.payload;
      state.subscribeError = response.data;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormDataGiftHouse: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  initEventInfo,
  setEventInfo,
  setCheckedConsents,
  setAppointmentLeWithGh,
  setCurrentStep,
  setSubscribeError,
  resetFormDataGiftHouse,
} = FormIscrizioneGiftHouseSlice.actions;
export default FormIscrizioneGiftHouseSlice;
