import { takeLatest, put } from "redux-saga/effects";
import {
  axiosCallGet,
  axiosCallDelete,
} from "../../utils/axiosCalls/AxiosCalls";
// actions
import { DELETE_ADS } from "./constants";
import { adsListActions } from "./AdsListSlice";
import { setData, setError, fetchComponents } from "./AdsListSlice";

const DELETE_BASE_URL = "/ad_board/";
function* fetchComponentsWorker({ payload: { path, token } }) {
  try {
    // const mock = payload.type === 'my-ads' ? '/mocks/mock-my-ads.json' : '/mocks/mock-offro-casa.json'
    // const result = yield call(() => axios.get(mock));
    const result = yield axiosCallGet(path, token);
    yield put(setData(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

function* deleteAdWorker({ payload }) {
  try {
    const path = `${DELETE_BASE_URL}${payload.id}`;
    yield axiosCallDelete(path, payload.token, true);
    yield put(adsListActions.deleteAd(payload.id));
    yield put(adsListActions.setOpenModal(true));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export default function* AdsListSaga() {
  yield takeLatest(fetchComponents.type, fetchComponentsWorker);
  yield takeLatest(DELETE_ADS, deleteAdWorker);
}
