import React from "react";
import {
  ContainerLe,
  DetailContainerData,
  DetailDataLabel,
  TitleDetail,
} from "./style";
import { useSelector } from "react-redux";
import { QrCode } from "../qrCode/QrCode";
import { GenericTitle } from "../style";

export function DetailInfoLightExperience({ data }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const getSubscriptionTime = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result;
  };

  const getSubscriptionDay = (startDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
    });
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  function generateHtml(argument) {
    return { __html: argument };
  }
  return (
    <ContainerLe>
      <TitleDetail>{data?.title}</TitleDetail>
      <GenericTitle>
        {data?.qrCode && <QrCode data={data?.qrCode} />}
        <p dangerouslySetInnerHTML={generateHtml(`${data?.richText}`)} />
      </GenericTitle>
      <DetailContainerData>
        <DetailDataLabel>{data?.guestsLabel}</DetailDataLabel>
        <p>{data?.NumGuests === 0 ? data?.noGuestLabel : data?.NumGuests}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.dateLabel}</DetailDataLabel>
        <p>
          {getSubscriptionDay(data?.appointment?.appointmentStartDate, lang)}
        </p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.slotLabel}</DetailDataLabel>
        <p>
          {getSubscriptionTime(
            data?.appointment?.appointmentStartDate,
            data?.appointment?.appointmentEndDate,
            lang
          )}
        </p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.locationLabel}</DetailDataLabel>
        <p>{data?.locationTxt}</p>
      </DetailContainerData>
    </ContainerLe>
  );
}

export default React.memo(DetailInfoLightExperience);
