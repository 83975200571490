import React from "react";
import { Title, Container } from "./style";
// components
import WebinarCard from "./webinarCard/WebinarCard";

function OthersWebinar({ webinarComponents }) {
  return (
    <>
      <Title>{webinarComponents?.data?.title}</Title>
      <Container>
        {React.Children.toArray(
          webinarComponents?.data?.events?.map((event) => {
            return <WebinarCard data={event} />;
          })
        )}
      </Container>
    </>
  );
}

export default OthersWebinar;
