// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import {
  selectTabComponentsById,
  selectTabIndex,
  selectAvatarComponent,
  selectAggregatedDirection,
} from "./selectors";
import { myPageActions } from "./myPageSlice";
// components
import LayoutMyPage from "../../components/UI/myPage/LayoutMyPage";
import MyPageTabs from "../../components/UI/myPage/myPageTabs/MyPageTabs";
import Avatar from "../../components/UI/myPage/avatar/Avatar";
import Profile from "../../components/UI/myPage/profile/Profile";
import Family from "../../components/UI/myPage/family/Family";
import BusinessCard from "../../components/UI/myPage/businessCard/BusinessCard";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import MyEvents from "../../components/UI/myPage/myEvents/MyEvents";

function MyPage({ path }) {
  const urlParams = new URLSearchParams(window.location.search);
  const isEcardActive = urlParams.get("isEcardActive");

  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const tabIndex = useSelector(selectTabIndex());
  const currentTabComponents = useSelector(selectTabComponentsById(tabIndex));
  const avatarComponents = useSelector(selectAvatarComponent());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  const { status, errorData } = GetStatusAndErrorData("myPage");

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
    isEcardActive && dispatch(myPageActions.setTabIndex(4));
  }, [isEcardActive, dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        avatarComponents &&
        currentTabComponents && (
          <LayoutMyPage>
            <Avatar components={avatarComponents} />
            <MyPageTabs />
            {tabIndex === 1 && (
              <Profile components={currentTabComponents[0]?.components} />
            )}
            {tabIndex === 2 && (
              <Family components={currentTabComponents[0]?.components} />
            )}
            {tabIndex === 3 && (
              <MyEvents components={currentTabComponents[0]?.components} />
            )}
            {tabIndex === 4 && (
              <BusinessCard components={currentTabComponents[0]?.components} />
            )}
          </LayoutMyPage>
        )
      )}
    </>
  );
}

export default MyPage;
