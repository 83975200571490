import { ComponentContainer, InputSection } from "../style";

export default function ComponentUserData({ data }) {
  return (
    <>
      <ComponentContainer>
        <h3>{data?.personalInfoLblEventLabel}</h3>
        <InputSection>
          <input
            disabled
            id="fullName"
            value={`${data?.user?.name} ${data?.user?.surname}`}
            type="text"
          />
          <label htmlFor="nome">
            {data?.introductionUserFullNameLblEventLabel}
          </label>
        </InputSection>
      </ComponentContainer>
      <ComponentContainer>
        <InputSection>
          <input disabled id="badge" value={data?.user?.badge} type="text" />
          <label htmlFor="badge">
            {data?.introductionUserBadgeLblEventLabel}
          </label>
        </InputSection>
      </ComponentContainer>
      <ComponentContainer>
        <InputSection>
          <input
            disabled
            id="company"
            autoComplete="none"
            value={data?.user?.company}
            type="text"
          />
          <label htmlFor="company">
            {data?.introductionUserCompanyLblEventLabel}
          </label>
        </InputSection>
      </ComponentContainer>
      <ComponentContainer>
        <InputSection>
          <input
            disabled
            autoComplete="none"
            id="email"
            value={data?.user?.email}
            type="text"
          />
          <label htmlFor="email">
            {data?.introductionUserCompanyEmailAddressLblEventLabel}
          </label>
        </InputSection>
      </ComponentContainer>
    </>
  );
}
