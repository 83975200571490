import { Wrapper } from "./style";
import FormazioneManagerialeBox from "./formazioneManagerialeBox/formazioneManagerialeBox";

function FormazioneManagerialeBoxes({ data, template }) {
  return (
    <Wrapper template={template}>
      {data.items.map((v, i) => (
        <FormazioneManagerialeBox key={i} data={v}></FormazioneManagerialeBox>
      ))}
    </Wrapper>
  );
}
export default FormazioneManagerialeBoxes;
