import styled from "styled-components";

export const SearchWrapper = styled.div`
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 20px 32px);
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 32px;
  display: flex;
  background: rgb(255, 255, 255);
  margin-bottom: 26px;
  @media screen and (min-width: 1280px) {
    padding-left: 32px;
  }
  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const SearchField = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding-right: 30px;
  align-items: center;
  img {
    width: 20px;
  }

  input {
    width: 100%;
    border-width: 0px 0px 0px 1px;
    border-top-style: initial;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: solid;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: rgb(139, 139, 139);
    border-image: initial;
    padding: 0px 8px;
    margin-left: 8px;
    margin-right: 8px;
    color: black !important;
  }

  @media screen and (max-width: 1279px) {
    padding: 24px 16px;
  }
`;

export const RemoveBtn = styled.div`
  color: rgb(112, 112, 112);
  font-size: 11px;
  cursor: pointer;
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const SearchBtn = styled.button`
  text-transform: uppercase;
  font-size: 12px;
  padding: 20px 0px;
  width: 100%;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;

  @media screen and (min-width: 1280px) {
    width: 176px;
  }
`;

export const AdvSearchWrapper = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
  gap: 8px;
  display: flex;
  align-items: center;
  position: relative;
  img {
    cursor: pointer;
    max-width: 100%;
  }
`;

export const ActiveFilter = styled.span`
  background: rgb(218, 41, 28);
  color: rgb(255, 255, 255);
  padding: 4px 6px;
  border-radius: 16px;
  font-size: 9px;
  position: absolute;
  top: -1px;
  left: 30px;
`;
export const FilterIcon = styled.svg.attrs({
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  vertical-align: middle;
  cursor: pointer;
`;
export const FilterIconPath1 = styled.path.attrs({
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z",
  fill: "#D4D2D2",
})``;

export const FilterIconPath2 = styled.path.attrs({
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M25.916 17.3333C25.62 18.4835 24.5759 19.3333 23.3333 19.3333C22.0908 19.3333 21.0467 18.4835 20.7507 17.3333H12C12 16.597 12.597 16 13.3333 16H20.7507C21.0467 14.8498 22.0908 14 23.3333 14C24.5759 14 25.62 14.8498 25.916 16H28V17.3333H25.916ZM24.6667 16.6667C24.6667 17.403 24.0697 18 23.3333 18C22.597 18 22 17.403 22 16.6667C22 15.9303 22.597 15.3333 23.3333 15.3333C24.0697 15.3333 24.6667 15.9303 24.6667 16.6667ZM14.084 22.6667C14.38 21.5165 15.4241 20.6667 16.6667 20.6667C17.9092 20.6667 18.9533 21.5165 19.2493 22.6667H28C28 23.403 27.403 24 26.6667 24H19.2493C18.9533 25.1502 17.9092 26 16.6667 26C15.4241 26 14.38 25.1502 14.084 24H12V22.6667H14.084ZM15.3333 23.3333C15.3333 22.597 15.9303 22 16.6667 22C17.403 22 18 22.597 18 23.3333C18 24.0697 17.403 24.6667 16.6667 24.6667C15.9303 24.6667 15.3333 24.0697 15.3333 23.3333Z",
})`
  fill: var(--main-color);
`;
