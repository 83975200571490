import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./SimpleEventsSubscribeFormSlice";

const simpleEventsState = (state) =>
  state.SimpleEventsSubscribeForm || initialState;

export const selectRecapPage = () =>
  createSelector(simpleEventsState, (state) => {
    const recapComponent = state?.components.components?.find(
      (v) => v.resourceType === "fi-events/fi-internal-events-recap"
    )?.data;
    return recapComponent;
  });

export const selectHeroImg = () =>
  createSelector(simpleEventsState, (state) => {
    const heroData = state?.components?.components?.find(
      (v) => v.resourceType === "fi-events/fi-events-hero-subscription"
    );
    return heroData;
  });
