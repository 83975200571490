// style
import { Wrapper } from "./style";
import { useSelector } from "react-redux";
import { getRubricaTitle } from "../../../../pages/Rubrica/selectors";

function Title() {
  const titleComponent = useSelector(getRubricaTitle());
  return <Wrapper>{titleComponent.data.jcrTitle}</Wrapper>;
}

export default Title;
