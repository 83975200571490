import styled from "styled-components";

export const Wrapper = styled.div`
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 0px;
  @media (max-width: 1279px) {
    flex-direction: column-reverse;
    row-gap: 25px;
    margin-bottom: 40px;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
`;

export const ListItem = styled.li`
  flex-grow: 2;
  padding: 12px 32px;
  color: rgb(112, 112, 112);
  border-bottom: 2px solid transparent;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  &:hover,
  &.selected {
    color: rgb(218, 41, 28);
    border-bottom: 2px solid rgb(218, 41, 28);
  }
`;

export const SelectWrapper = styled.div`
  @media (max-width: 1279px) {
    width: 100%;
  }
  width: 130px;
  position: relative;
`;

export const Select = styled.select`
  width: 100%;
  appearance: none;
  position: relative;

  select,
  input,
  option {
    font-family: ferrari-sans-regular;
    font-size: 11px;
    padding: 12px 16px 14px;
    color: rgb(48, 48, 48);
    max-width: 100%;
  }

  select:not(:-internal-list-box) {
    overflow: visible !important;
  }
`;

export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;
