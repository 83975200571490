import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// actions
import { globalSearchActions } from "../../../../../../../pages/GlobalSearch/globalSearchSlice";
// style
import {
  Wrapper,
  DateContainer,
  Day,
  MonthYear,
  Title,
  Description,
  Container,
} from "./style";

function CardResultEvent({ data, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const day = new Date(data?.date).toLocaleString("default", {
    day: "numeric",
  });
  const month = new Date(data?.date)
    .toLocaleString("it-IT", {
      month: "long",
    })
    .substring(0, 3);
  const year = new Date(data?.date)
    .toLocaleString("it-IT", {
      year: "numeric",
    })
    .substring(2);

  const navigateToPath = () => {
    navigate(`event-details?path=${data?.linkHref}`);
    dispatch(globalSearchActions.resetState(false));
  };

  const HandleKeySearchItemPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        navigateToPath();
        break;
    }
  };

  return (
    <Wrapper
      tabIndex="0"
      id={id}
      onClick={navigateToPath}
      onKeyDown={(e) => HandleKeySearchItemPressed(e)}
    >
      <DateContainer>
        <Day>{day}</Day>
        <MonthYear>{`${month} - ${year}`}</MonthYear>
      </DateContainer>
      <Container>
        <Title>{data?.title}</Title>
        <Description>{data?.description}</Description>
      </Container>
    </Wrapper>
  );
}

export default React.memo(CardResultEvent);
