import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 32px 0px 32px 0px;
  border-bottom: 1px solid #d4d2d2;
  @media (max-width: 1279px) {
    column-gap: 26px;
    padding: 24px 0px 0;
  }
`;

export const IconContainer = styled.div`
  @media (max-width: 1279px) {
    min-width: fit-content;
  }
`;

export const TextContainer = styled.div`
  line-height: 13px;
  width: 80%;
  cursor: pointer;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
  @media (max-width: 1279px) {
    font-size: 13px;
    text-transform: none;
  }
`;

export const Description = styled.p`
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--alternative-grey-color);
  @media (max-width: 1279px) {
    font-size: 11px;
    text-transform: none;
  }
`;
