import {
  AllNews,
  AllNewsCta,
  AllNewsCtaIcon,
  AllNewsCtaIconCircle,
  ArrowIcon,
} from "./style";
import { useSelector } from "react-redux";

function GoToAllNews() {
  const lang = useSelector((state) => state.coreComponents.language);

  return (
    <AllNews>
      <AllNewsCta
        href={
          lang === "it"
            ? "/it/news-and-events/news"
            : "/en/news-and-events/news"
        }
      >
        <AllNewsCtaIcon>
          <AllNewsCtaIconCircle></AllNewsCtaIconCircle>
          <ArrowIcon>
            <path d="M7.268 9.547L0 16l4-8-4-8 7.268 6.453C7.715 6.82 8 7.377 8 8c0 .623-.285 1.18-.732 1.547z"></path>
          </ArrowIcon>
        </AllNewsCtaIcon>
        <span>{lang === "it" ? "TUTTE LE NEWS" : "ALL NEWS"}</span>
      </AllNewsCta>
    </AllNews>
  );
}

export default GoToAllNews;
