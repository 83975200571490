function TargetTrap(e, idDialog) {
  
  let focusable = document
    .getElementById(idDialog)
    .querySelectorAll("input,button,select,textarea,p,li,a, img");
  if (focusable.length) {
    let first = focusable[0];
    let last = focusable[focusable.length - 1];
    let shift = e.shiftKey;
    if (shift) {
      if (e.target === first) {
        // shift-tab pressed on first input in dialog
        last.focus();
        e.preventDefault();
      }
    } else {
      if (e.target === last) {
        // tab pressed on last input in dialog
        first.focus();
        e.preventDefault();
      }
    }
  }
}

export default TargetTrap;
