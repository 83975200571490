import styled from "styled-components";

export const FilesList = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  img {
    max-width: 176px;
    display: block;
  }
`;

export const FileContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;
export const UploadedFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UploadedFileText = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
  }
`;
export const UploadedFileName = styled.p`
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--main-color);
`;
export const UploadedFileSize = styled.p`
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: var(--alternative-grey-color);
`;
export const UploadedFileRemove = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px;
  gap: 8px;
  cursor: pointer;
  button {
    cursor: pointer;
    border: none;
  }
`;
export const FileUploadText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  p {
    margin: 0;
  }
`;
export const FileUploadTitle = styled.div`
  font-weight: 400;
  line-height: 15.6px;
  font-size: 13px;
  color: var(--main-color);
`;
export const FileUploadDimension = styled.div`
  font-weight: 400;
  line-height: 14.4px;
  font-size: 12px;
`;
export const FileUploadCTA = styled.div`
  width: 240px;
  padding: 19.25px 32px 21.75px 32px;
  border: 1px solid #d4d2d2;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  font-weight: 400;
  color: var(--main-color);
  margin: auto;
  text-align: center;
  cursor: pointer;
`;

export const CloudSvg = styled.svg.attrs({
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  pointer-events: none;
`;
export const CloudSvgPath1 = styled.path.attrs({
  fillRule: "evenodd",
  d: "M32.9058 32.418L24.9058 24.418L16.9058 32.418",
  stroke: "var(--main-color)",
  strokeOpacity: "0.4",
  strokeWidth: "2",
  strokeLinecap: "round",
  strokeLinejoin: "round",
})`
  pointer-events: none;
`;
export const CloudSvgPath2 = styled.path.attrs({
  fillRule: "evenodd",
  d: "M24.9058 24.418V42.418",
  stroke: "var(--main-color)",
  strokeOpacity: "0.4",
  strokeWidth: "2",
  strokeLinecap: "round",
  strokeLinejoin: "round",
})`
  pointer-events: none;
`;
export const CloudSvgPath3 = styled.path.attrs({
  fillRule: "evenodd",
  d: "M41.6858 37.198C43.6364 36.1345 45.1774 34.4517 46.0655 32.4152C46.9536 30.3787 47.1382 28.1044 46.5902 25.9513C46.0422 23.7983 44.7927 21.889 43.0391 20.5249C41.2855 19.1607 39.1275 18.4194 36.9058 18.418H34.3858C33.7804 16.0765 32.6521 13.9026 31.0857 12.06C29.5192 10.2173 27.5555 8.75367 25.342 7.77918C23.1285 6.8047 20.7229 6.34468 18.306 6.43373C15.8891 6.52278 13.5239 7.15857 11.3881 8.2933C9.25233 9.42803 7.40158 11.0322 5.975 12.9851C4.54843 14.9381 3.58314 17.189 3.15172 19.5688C2.7203 21.9485 2.83397 24.395 3.48419 26.7245C4.1344 29.054 5.30424 31.2057 6.90576 33.018",
  stroke: "var(--main-color)",
  strokeOpacity: "0.4",
  strokeWidth: "2",
  strokeLinecap: "round",
  strokeLinejoin: "round",
})`
  pointer-events: none;
`;
export const CloudSvgPath4 = styled.path.attrs({
  fillRule: "evenodd",
  d: "M32.9058 32.418L24.9058 24.418L16.9058 32.418",
  stroke: "var(--main-color)",
  strokeOpacity: "0.4",
  strokeWidth: "2",
  strokeLinecap: "round",
  strokeLinejoin: "round",
})`
  pointer-events: none;
`;
