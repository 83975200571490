import React from "react";
import { Wrapper, BulletPoint, Number, Text } from "./style";

function BulletPointInfo({ data }) {
  return (
    <Wrapper>
      <h3>{data.title}</h3>
      {React.Children.toArray(
        data?.items?.map((el, idx) => {
          ++idx;
          return (
            <BulletPoint>
              {data.items.length > 1 ? <Number>{idx}</Number> : ""}
              <Text dangerouslySetInnerHTML={{ __html: el.richText }}></Text>
            </BulletPoint>
          );
        })
      )}
    </Wrapper>
  );
}

export default BulletPointInfo;
