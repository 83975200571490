import React from "react";
import { Wrapper } from "./style";
import CarouselWrapper from "../../../carouselWrapper/CarouselWrapper";
import WelfareSlot from "../welfareSlot/WelfareSlot";
// import CtaIcon from "../../ctaIcon/CtaIcon";

function AsideCarousel({ data }) {
  return (
    <Wrapper>
      <CarouselWrapper itemsLength={data?.items?.length}>
        {React.Children.toArray(
          data?.items.map((item) => {
            return <WelfareSlot data={item}></WelfareSlot>;
          })
        )}
      </CarouselWrapper>
    </Wrapper>
  );
}

export default React.memo(AsideCarousel);
