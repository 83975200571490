import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS } from "./constants";
import { container800Actions } from "./containerSlice";

function* fetchCCComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(container800Actions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(container800Actions.setError(e));
  }
}

export default function* ccSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchCCComponents);
}
