import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  left: -32px;
  width: calc(100% + 64px);
  height: 24px;
  background: var(--main-bg);
  display: block;
`;
