import { Container, ImgHeader } from "./style";

function LayoutGenericTemplate({ children }) {
  return (
    <>
      <Container>{children}</Container>
    </>
  );
}

export default LayoutGenericTemplate;
