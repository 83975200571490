import { useDispatch } from "react-redux";
import CustomSelect from "../../customSelect/CustomSelect";
import { ComponentContainer, TextDescr } from "../style";
import ErrorField from "./ErrorField/ErrorField";
import { setBodyPayload } from "../../../../pages/FormIscrizioneMondiali/FormIscrizioneMondialiSlice";
import { useState } from "react";
import { useEffect } from "react";

export default function ComponentAppointment({ data, allowed, bodyPayload }) {
  const [placeholderString, setPlaceholderString] = useState(
    data?.dateAndTimePlaceholder
  );
  const dispatch = useDispatch();

  const sortedAppointments = data?.event?.appointments
    ?.map((appointment) => appointment)
    .sort(
      (a, b) =>
        Date.parse(a?.slots?.[0]?.appointmentStartDate) -
        Date.parse(b?.slots?.[0]?.appointmentStartDate)
    );
  const handleDate = (e) => {
    const { name, value } = e.target;
    let modifiedUserInfo = { ...bodyPayload, [name]: +value };

    if (
      modifiedUserInfo?.appointmentId !==
      data?.userSubscriptions?.[0]?.appointmentId
    ) {
      modifiedUserInfo = { ...modifiedUserInfo, numGuests: undefined };
    } else {
      modifiedUserInfo = {
        ...modifiedUserInfo,
        numGuests: data?.savedNumGuests,
      };
    }
    dispatch(setBodyPayload(modifiedUserInfo));
  };

  const existSlots = sortedAppointments?.length > 0;

  useEffect(() => {
    if (existSlots && bodyPayload?.appointmentId) {
      let currentGroupSelected = data?.event?.appointments?.find(
        (appointment) =>
          appointment?.slots?.[0]?.id == bodyPayload?.appointmentId
      );

      setPlaceholderString(currentGroupSelected?.title);
    }
  }, [existSlots, bodyPayload?.appointmentId]);

  return (
    <ComponentContainer>
      <h3>
        {data?.dateAndTimeLblEventLabel}{" "}
        <span>{data?.fieldMandatoryLblEventLabel}</span>
      </h3>
      <TextDescr>{data?.dateAndTimeTxtEventLabel}</TextDescr>
      <CustomSelect
        name="appointmentId"
        defaultValue={data?.userSubscriptions?.[0]?.appointmentId}
        placeholderString={placeholderString}
        onChange={(e) => handleDate(e)}
      >
        {sortedAppointments &&
          sortedAppointments?.map((appointment, ind) => {
            if (appointment?.slots?.length > 0) {
              return (
                <optgroup key={"appointment" + ind} label={appointment?.title}>
                  <option
                    checked={
                      data?.userSubscriptions?.[0]?.appointmentId ===
                      appointment?.slots?.[0]?.id
                    }
                    className="data-evento"
                    value={appointment?.slots?.[0]?.id}
                    key={appointment?.slots?.[0]?.id}
                    code={data?.event?.code}
                  >
                    {appointment?.slots?.[0]?.appointmentStartDate.slice(
                      11,
                      16
                    )}{" "}
                    -{" "}
                    {appointment?.slots?.[0]?.appointmentEndDate.slice(11, 16)}
                  </option>
                </optgroup>
              );
            }
          })}
      </CustomSelect>
      {!bodyPayload?.appointmentId && allowed === false && (
        <ErrorField text={data?.fieldValidationErrorTxtEventLabel} />
      )}
    </ComponentContainer>
  );
}
