import { useDispatch } from "react-redux";
// style
import { Category, TrayDishInner, TrayDish } from "./style";
import { fetchDishToTray } from "../../../../pages/Mensa/actions";

function CaloricIntakeRecap({ dish }) {
  const dispatch = useDispatch();

  return (
    <li>
      <Category>{dish.category}</Category>
      <TrayDish>
        <span className="desktop-body">{dish.title}</span>
        <TrayDishInner>
          <span>{dish.kcal} kcal</span>
          <img
            className="pointer"
            src="/img/remove.svg"
            onClick={() => dispatch(fetchDishToTray(dish))}
          />
        </TrayDishInner>
      </TrayDish>
    </li>
  );
}

export default CaloricIntakeRecap;
