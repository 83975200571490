import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// style
import { Slot, FocusText, PreTitle, EventCta } from "./style";

function EventSlot({ event, imageOverride }) {
  const ImageToshow = imageOverride ? imageOverride : event.image;
  return (
    // TODO path to event
    <Link
      aria-label={event.title}
      to={event?.path ? "/event-details?path=" + event.path : "#"}
    >
      <Slot>
        <img src={ImageToshow} />
        <FocusText>
          <PreTitle>
            <span>{`${event.dateDay} ${event.dateMonth} ${new Date(
              event.date
            ).getFullYear()}`}</span>
          </PreTitle>
          <h3>{event.title}</h3>
          {event.labelLiveStreaming && (
            <EventCta>{event.labelLiveStreaming} </EventCta>
          )}
        </FocusText>
      </Slot>
    </Link>
  );
}

EventSlot.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    dateDay: PropTypes.string,
    dateMonth: PropTypes.string,
    path: PropTypes.string,
    image: PropTypes.string,
    eventType: PropTypes.string,
    date: PropTypes.number,
    linkHref: PropTypes.string,
  }),
};
export default EventSlot;
