import {
  Container,
  TitleForm,
  SendButton,
  HeaderWrapper,
  RequiredValue,
  InputSection,
  SectionForm,
  RedirectButton,
  DialogWrapper,
} from "./style";
import { isEqual } from "lodash";
import CustomSelect from "../customSelect/CustomSelect";

import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FetchSetUserEcardInformation } from "../../../pages/FormRichiestaEcard/action";
import { setUserInfo } from "../../../pages/FormRichiestaEcard/FormRichiestaEcardSlice";

function LayoutFormRichiestaEcard({ data, hero }) {
  //selectors post
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const path = headerLinks?.find(
    (v) => v.template === "request-ecard-form"
  )?.path;

  //selectors render form
  const hrList = useSelector((state) => state.FormRichiestaEcard.hrList);
  const requestStatus = useSelector(
    (state) => state.FormRichiestaEcard.requestStatus
  );
  const savedBodyPayload = useSelector(
    (state) => state.FormRichiestaEcard.savedBodyPayload
  );
  const existEcard = useSelector(
    (state) => state.FormRichiestaEcard.existEcard
  );
  const userInfo = useSelector((state) => state.FormRichiestaEcard.userInfo);

  //states
  const [displayError, setDisplayError] = useState(existEcard);

  //hooks
  const navigate = useNavigate();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  function generateHtmlPageDescription(argument) {
    return { __html: argument };
  }

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "hr") {
      const chosenManager = hrList?.filter(
        (manager) => manager.subjectCode === value
      );
      dispatch(
        setUserInfo({
          ...userInfo,
          hrReviewerId: value,
          hrReviewerName: chosenManager?.[0]?.name,
          hrReviewerLastName: chosenManager?.[0]?.surname,
          hrReviewerEmail: chosenManager?.[0]?.email,
        })
      );
    } else {
      dispatch(
        setUserInfo({ ...userInfo, [name]: value !== "" ? value : undefined })
      );
    }
  };

  const isMailValid = () => {
    if (data?.hrManagers?.isDebugMode === "true") {
      return true;
    }
    return userInfo.email.split("@")?.[1] === "ferrari.com";
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    const { phone, fax, ...rest } = userInfo;
    if (
      Object.values(rest).includes(undefined) ||
      isEqual(userInfo, savedBodyPayload) ||
      !isMailValid()
    ) {
      setDisplayError(false);
      //   const element =
      //     document.getElementById("required")?.previousElementSibling;
      //   if (element)
      //     element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setDisplayError(true);
      dispatch(
        FetchSetUserEcardInformation({
          responseBody: userInfo,
          token,
          path,
          lang,
        })
      );
    }
  };

  useEffect(() => {
    const { phone, fax, ...rest } = userInfo;
    if (requestStatus !== true) {
      if (
        Object.values(rest).includes(undefined) ||
        isEqual(userInfo, savedBodyPayload) ||
        !isMailValid()
      ) {
        document
          .querySelector?.(".submitButton")
          ?.classList.remove("activeSubmit");
      } else {
        document
          .querySelector?.(".submitButton")
          ?.classList.add("activeSubmit");
      }
    }
    // const element = document.getElementById("required")?.previousElementSibling;
    // if (element)
    //   element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [userInfo, displayError]);

  return (
    <>
      {hero?.fileReference && (
        <HeaderWrapper>
          <img src={hero?.fileReference} />
        </HeaderWrapper>
      )}
      {requestStatus !== true ? (
        <>
          <TitleForm>
            {data?.title}
            <div
              dangerouslySetInnerHTML={generateHtmlPageDescription(
                data?.richText
              )}
            />
          </TitleForm>
          <Container>
            <form
              onSubmit={(e) => {
                onSubmitHandler(e);
              }}
              ref={formRef}
            >
              <SectionForm>
                <h3>{data?.personalDataTitle}</h3>
                <InputSection hideLabel={true}>
                  <input
                    autoComplete="nope"
                    id="name"
                    name="name"
                    placeholder={data?.nameLabel}
                    type="text"
                    onChange={onInputChange}
                    defaultValue={userInfo?.name}
                  />
                  <label htmlFor="name">{data?.nameLabel}</label>
                </InputSection>
              </SectionForm>
              <SectionForm>
                <InputSection hideLabel={true}>
                  <input
                    autoComplete="nope"
                    id="lastName"
                    name="lastName"
                    placeholder={data?.lastNameLabel}
                    type="text"
                    onChange={onInputChange}
                    defaultValue={userInfo?.lastName}
                  />
                  <label htmlFor="lastName">{data?.lastNameLabel}</label>
                </InputSection>
              </SectionForm>
              {(!userInfo.name || !userInfo.lastName) &&
                displayError !== null && (
                  <RequiredValue id="required">
                    {data?.errorLabel}
                  </RequiredValue>
                )}
              <SectionForm>
                <h3>{data?.professionalDataTitle}</h3>
                <InputSection hideLabel={true}>
                  <input
                    autoComplete="nope"
                    id="company"
                    name="company"
                    placeholder={data?.departmentLabel}
                    type="text"
                    onChange={onInputChange}
                    defaultValue={userInfo?.company}
                  />
                  <label htmlFor="company">{data?.departmentLabel}</label>
                </InputSection>
              </SectionForm>
              <SectionForm>
                <InputSection hideLabel={true}>
                  <input
                    autoComplete="nope"
                    id="depAndJobPos"
                    name="depAndJobPos"
                    placeholder={data?.roleLabel}
                    type="text"
                    onChange={onInputChange}
                    defaultValue={userInfo?.depAndJobPos}
                  />
                  <label htmlFor="depAndJobPos">{data?.roleLabel}</label>
                </InputSection>
              </SectionForm>
              <SectionForm>
                <CustomSelect
                  autoComplete="nope"
                  name="corporateAddress"
                  defaultValue={userInfo?.corporateAddress || ""}
                  placeholderString={data?.companyAddress?.placeholder}
                  onChange={onInputChange}
                >
                  {data?.companyAddress?.items?.map((sede, index) => (
                    <option key={index} value={sede.address}>
                      {sede.address}
                    </option>
                  ))}
                </CustomSelect>
              </SectionForm>
              {(!userInfo.company ||
                !userInfo.depAndJobPos ||
                !userInfo.corporateAddress) &&
                displayError !== null && (
                  <RequiredValue id="required">
                    {data?.errorLabel}
                  </RequiredValue>
                )}
              <SectionForm>
                <h3>{data?.contactsTitle}</h3>
                <InputSection hideLabel={true}>
                  <input
                    autoComplete="nope"
                    id="email"
                    name="email"
                    placeholder={data?.emailLabel}
                    type="email"
                    onChange={onInputChange}
                    defaultValue={userInfo?.email}
                  />
                  <label htmlFor="email">{data?.emailLabel}</label>
                </InputSection>
              </SectionForm>
              <SectionForm>
                <InputSection hideLabel={true}>
                  <input
                    autoComplete="nope"
                    id="mobilePhone"
                    name="mobilePhone"
                    placeholder={data?.mobilePhoneLabel}
                    type="tel"
                    onChange={onInputChange}
                    defaultValue={userInfo?.mobilePhone}
                  />
                  <label htmlFor="mobilePhone">{data?.mobilePhoneLabel}</label>
                </InputSection>
              </SectionForm>
              <SectionForm>
                <InputSection hideLabel>
                  <input
                    autoComplete="nope"
                    id="phone"
                    name="phone"
                    placeholder={data?.phoneLabel}
                    type="tel"
                    onChange={onInputChange}
                    defaultValue={userInfo?.phone}
                  />
                  <label htmlFor="phone">{data?.phoneLabel}</label>
                </InputSection>
              </SectionForm>
              <SectionForm>
                <InputSection hideLabel={true}>
                  <input
                    autoComplete="nope"
                    id="fax"
                    name="fax"
                    placeholder={data?.faxLabel}
                    type="text"
                    onChange={onInputChange}
                    defaultValue={userInfo?.fax}
                  />
                  <label htmlFor="fax">{data?.faxLabel}</label>
                </InputSection>
              </SectionForm>
              {(!userInfo.mobilePhone || !userInfo.email || !isMailValid()) &&
                displayError !== null && (
                  <RequiredValue id="required">
                    {data?.errorLabel}
                  </RequiredValue>
                )}
              <SectionForm>
                <h3>{data?.hrManagers?.hrTitle}</h3>
                <CustomSelect
                  autoComplete="nope"
                  name="hr"
                  defaultValue={userInfo?.hrReviewerId || ""}
                  placeholderString={data?.hrManagers?.placeholder}
                  onChange={onInputChange}
                >
                  {hrList?.map((manager, index) => (
                    <option key={index} value={manager.subjectCode}>
                      {manager.name} {manager.surname} - {manager.directions}
                    </option>
                  ))}
                </CustomSelect>
              </SectionForm>
              {!userInfo.hrReviewerId && displayError === false && (
                <RequiredValue id="required">{data?.errorLabel}</RequiredValue>
              )}
              <SendButton className="submitButton">
                {data?.buttonLabel}
              </SendButton>
            </form>
          </Container>
        </>
      ) : (
        <DialogWrapper>
          <TitleForm>
            {data?.pendingTitleDescr?.title?.jcrTitle}
            <div
              dangerouslySetInnerHTML={generateHtmlPageDescription(
                data?.pendingTitleDescr?.text?.text
              )}
            />
          </TitleForm>
          <Container center>
            <RedirectButton
              onClick={() => navigate(`${data?.buttonRedirect?.link}`)}
            >
              {data?.buttonRedirect?.title}
            </RedirectButton>
          </Container>
        </DialogWrapper>
      )}
    </>
  );
}

export default LayoutFormRichiestaEcard;
