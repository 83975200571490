import { Container, TitleForm, SendButton } from "./style";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Hero from "./components/Hero/Hero";
import ComponentPrivacy from "./components/ComponentPrivacy/ComponentPrivacy";
import { LimitReachedDialog } from "./components/LimitReachedDialog";
import ComponentUserData from "./components/ComponentUserData";
import ComponentNumGuests from "./components/ComponentNumGuests";
import ComponentAppointment from "./components/ComponentAppointment";
import { SetUserRegistration } from "../../../pages/FormIscrizioneMondiali/action";
import { isEqual } from "lodash";

function LayoutFormIscrizioneMondiali({ data, title, heroData }) {
  const location = useLocation();
  const url = location.search.replace(/\?path=/, "");

  const pathCms = url
    .split("?")[0]
    .replace(/subscribe-form/, "subscribe-recap");
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneMondiali.bodyPayload
  );
  const checkedConsents = useSelector(
    (state) => state.FormIscrizioneMondiali.checkedConsents
  );
  const savedBodyPayload = useSelector(
    (state) => state.FormIscrizioneMondiali.savedBodyPayload
  );

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const [allowed, setAllowed] = useState(null);
  const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState(false);

  const isSubmitActive =
    !isEqual(bodyPayload, savedBodyPayload) &&
    bodyPayload?.numGuests !== undefined &&
    bodyPayload?.appointmentId &&
    (bodyPayload.appointmentId !== data?.savedAppointmentId ||
      bodyPayload?.numGuests !== data?.savedNumGuests) &&
    checkedConsents?.length === data?.policiesRules?.items?.length &&
    !checkedConsents?.find((el) => el.checked === false);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setButtonHasBeenClicked(true);
    if (!isEqual(bodyPayload, savedBodyPayload) && isSubmitActive) {
      setAllowed(true);
      const path = `subscription-recap?path=${pathCms}?eventCode=${data?.event?.code}`;
      dispatch(
        SetUserRegistration({
          bodyPayload,
          token,
          path,
          lang,
        })
      );
    } else {
      setAllowed(false);
      const element =
        document.getElementById("required")?.previousElementSibling;
      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    const element = document.getElementById("required")?.previousElementSibling;
    if (buttonHasBeenClicked) {
      element &&
        !allowed &&
        element?.scrollIntoView({ behavior: "smooth", block: "center" });
      setButtonHasBeenClicked(false);
    }
  }, [allowed]);

  return (
    <>
      {heroData?.fileReference && <Hero data={heroData} />}
      {data?.event?.appointments?.length > 0 ? (
        <>
          <TitleForm>{title?.jcrTitle}</TitleForm>
          <Container>
            <form
              onSubmit={(e) => {
                onSubmitHandler(e);
              }}
              ref={formRef}
            >
              <ComponentUserData data={data} />
              <ComponentAppointment
                data={data}
                allowed={allowed}
                bodyPayload={bodyPayload}
              />
              <ComponentNumGuests
                data={data}
                allowed={allowed}
                bodyPayload={bodyPayload}
              />
              <ComponentPrivacy data={data} allowed={allowed} />
              <SendButton
                className={
                  isSubmitActive ? "submitButton activeSubmit" : "submitButton"
                }
              >
                {!data?.userSubscriptions?.[0]?.appointmentId
                  ? data?.button?.subscribeLbl
                  : data?.button?.modifyLbl}
              </SendButton>
            </form>
          </Container>
        </>
      ) : (
        <LimitReachedDialog data={data} />
      )}
    </>
  );
}

export default LayoutFormIscrizioneMondiali;
