import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  status: null,
  errorMessage: "",
};

const eventiSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload.data;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const eventiActions = eventiSlice.actions;
export default {
  name: eventiSlice.name,
  reducer: eventiSlice.reducer,
};
