import styled from "styled-components";

export const GenericTitle = styled.div`
  width: 100%;
  margin: 0px auto;
  text-align: center;
  font-size: 28px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 34px;
  padding: 0;

  p {
    font-family: ferrari-sans-regular, sans-serif;
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;

export const Container = styled.section`
  width: 800px;
  margin: 0 auto 96px;
  ${(props) =>
    props.center
      ? "display: flex; flex-direction:column; align-items: center"
      : "display: block"};
  h2 {
    margin: 96px 0 24px;
    text-align: center;
  }
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  form {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1279px) {
    width: 90%;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;
export const TitleForm = styled.h2`
  max-width: 800px;
  font-size: 36px;
  font-family: ferrari-sans-medium, sans-serif;
  text-align: center;
  line-height: 34px;
  display: flex;
  flex-direction: column;
  align-self: center;
  div {
    text-align: center;
    p {
      line-height: 20px;
      margin-top: 30px;
      font-family: ferrari-sans-regular, sans-serif;
      font-size: 13px;
      font-weight: 400;
    }
  }
  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;
export const EventTitle = styled.h3`
  font-size: 18px;
  text-align: center;
  font-family: ferrari-sans-regular, sans-serif;
  width: 100%;
  text-transform: uppercase;
`;

export const EventDescr = styled.p`
  font-family: ferrari-sans-regular, sans-serif;
  font-size: 13px;
  line-height: 20px;
  font-weight: 200;
`;

export const InputSectionCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  padding: 0px 0px 18px;
  gap: 12px;
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid var(--main-color);
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    padding: 0;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--main-color);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  span {
    max-width: calc(100% - 28px);
    & a {
      color: var(--main-color);
      text-decoration: revert;
    }
  }
`;

export const RequiredValue = styled.p`
  color: #da291c;
  font-weight: 400;
  font-size: 11px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0;
`;

export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const SectionForm = styled.div`
  background: var(--alternative-bg-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  margin: 20px 0;
  h4 {
    text-transform: uppercase;
    span {
      text-transform: lowercase;
      color: #8f8f8f;
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
`;

export const PrivacyRules = styled.div`
  padding: 32px;
  font-size: 13px;
  line-height: 20px;
`;
export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 32px;
`;
export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;

export const HeaderWrapper = styled.div`
  margin-top: -92px;
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 100%;

  &::before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &::after {
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    left: 0px;
    top: 0px;
    width: 100%;
    height: 205px;
    content: "";
  }
  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 96px;
  }
  @media screen and (max-width: 1279px) {
    img {
      object-fit: cover;
      margin: auto;
      min-height: 320px;
    }
  }
`;

export const HeroInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  text-align: center;
`;

export const HeroTitle = styled.h1`
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
`;

export const HeroSubTitle = styled.div`
  color: rgb(255, 255, 255);
  text-align: center;
`;

export const RedirectButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
