import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 800px;
  background: var(--alternative-bg-color);
  color: var(--main-color);
  a {
    cursor: pointer;
    color: var(--main-color);
  }
  path {
    fill: var(--main-color);
  }
  .carousel-dot {
    background: var(--main-color) !important;
  }
  @media (max-width: 1279px) {
    width: 100%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    background: none;
  }
`;
