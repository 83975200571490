import { List } from "./style";
import PropTypes from "prop-types";

//components
import WebinarCard from "../../events/webinarCardList/WebinarCard/WebinarCard";
import WebinarTitle from "./webinarTitle/WebinarTitle";

const WEBINAR_CARD_COMPONENT_NAME = "webinar-card";

function WebinarCardList({ data }) {
  const mapWebinar = data.results.map((currentCard, idx) => (
    <WebinarCard
      key={`${WEBINAR_CARD_COMPONENT_NAME}-${idx}`}
      data={currentCard}
    />
  ));
  return (
    <>
      <WebinarTitle data={data.title} />
      <List>{mapWebinar}</List>
    </>
  );
}

WebinarCardList.propTypes = {
  data: PropTypes.exact({
    hitsPerPage: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
    results: PropTypes.array,
    resultsNumber: PropTypes.number,
    position: PropTypes.string,
  }),
};
export default WebinarCardList;
