import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companyComActions } from "../../../../../pages/CompanyCom/companyComSlice";
// selectors
import {
  selectTabCategory,
  selectTabsMap,
} from "../../../../../pages/CompanyCom/selectors";
// style
import {
  Wrapper,
  List,
  ListItem,
  SelectWrapper,
  Select,
  WrapperImg,
} from "./style";
import BottomIcon from "../../../../assets/bottom";
import CustomSelectPlugin from "../../../customSelectPlugin/CustomSelectPlugin";

function TabsYear() {
  const dispatch = useDispatch();
  const tabs = useSelector(selectTabsMap());
  const tabCategory = useSelector(selectTabCategory());
  const [yearValue, setYearValue] = useState(new Date().getFullYear());
  return (
    <Wrapper>
      <List>
        {tabs.map((tab, idx) => (
          <ListItem
            key={idx}
            className={tabCategory === tab.category && "selected"}
            onClick={() =>
              dispatch(companyComActions.setTabCategory(tab.category))
            }
          >
            {tab.title}
          </ListItem>
        ))}
      </List>
      <SelectWrapper>
        <CustomSelectPlugin
          hideLabel={true}
          onChange={(event) => {
            const { value } = event;
            setYearValue(value);
            dispatch(companyComActions.setFilterYear(value));
          }}
          defaultValue={yearValue}
          placeholderString={yearValue}
          options={[
            {
              value: new Date().getFullYear(),
              label: new Date().getFullYear(),
            },
            {
              value: new Date().getFullYear() - 1,
              label: new Date().getFullYear() - 1,
            },
          ]}
        />
      </SelectWrapper>
    </Wrapper>
  );
}

export default React.memo(TabsYear);
