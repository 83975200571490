import React from "react";
import SetQueryStringPath from "../../../../utils/setQueryStringPath"; // style
import {
  Card,
  Description,
  TitleAndText,
  Title,
  Text,
  LinkWrapper,
} from "./style";
import { useRef, useState } from "react";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import linkTarget from "../../../../utils/linkTarget";

function NewsCard({ card }) {
  const [loaded, setLoaded] = useState(0);
  const loader = useRef(null);
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <Card ref={loader} style={{ opacity: loaded }}>
      <ShowOnIntersection
        loader={loader}
        setLoaded={setLoaded}
      ></ShowOnIntersection>
      <a
        href={SetQueryStringPath(card?.linkHref) || "#"}
        target={linkTarget(card?.linkHref)}
      >
        <LinkWrapper>
          {isDesktop ? (
            <img src={card.fileReference} />
          ) : (
            <img src={card.fileReferenceMobile || card.fileReference} />
          )}
        </LinkWrapper>
        <Description>
          <span>{card.category}</span>
        </Description>
        <TitleAndText>
          <Title>{card.title}</Title>
          <Text>{card.description}</Text>
        </TitleAndText>
      </a>
    </Card>
  );
}

export default React.memo(NewsCard);
