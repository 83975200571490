import { Title, Text, ContainerAccordion } from "./style";
import Accordion from "../../accordion/accordion";

function FaqAccordionList({ data, id }) {
  function html() {
    return { __html: data.richText };
  }
  return (
    <ContainerAccordion>
      <Title id={`faq-detail-${id}`}>{data.title}</Title>
      {data.richText && <Text dangerouslySetInnerHTML={html()}></Text>}
      {data.items.map((v, i) => (
        <Accordion key={i} title={v.title} text={v.richText} id={i}></Accordion>
      ))}
    </ContainerAccordion>
  );
}
export default FaqAccordionList;
