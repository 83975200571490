import { ComponentContainer, TextDescr } from "../../style";
import { useBusinessLogic } from "../../useBusinessLogic";
//components
import ErrorField from "../ErrorField/ErrorField";
import InputRadioButton from "../RadioButton/RadioButton";

export default function RegaloAggiuntivo({ data, onChange, allowed, check }) {
  const { generateHtml } = useBusinessLogic();
  const gtModelsOpt = [
    { text: data?.yesLabel, value: "yes", id: "yesGtModle" },
    { text: data?.noLabel, value: "no", id: "noGtModle" },
  ];
  return (
    <ComponentContainer>
      <TextDescr dangerouslySetInnerHTML={generateHtml(data?.richText)} />
      <InputRadioButton
        array={gtModelsOpt}
        name={`GtModel`}
        checked={check}
        onChange={onChange}
      />
      {check == null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
