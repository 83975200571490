import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGetPublic } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS } from "./constants";
import { map3dPublicActions } from "./containerSlice";

function* fetchComponents({ payload }) {
  try {
    const result = yield axiosCallGetPublic(payload.path);
    yield put(map3dPublicActions.setComponents(result.data));
    yield put(map3dPublicActions.setSettings(result?.data?.settings));
  } catch (e) {
    console.error(e);
    yield put(map3dPublicActions.setError(e));
  }
}

export default function* ccSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponents);
}
