import styled from "styled-components";

export const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 24px;
  a {
    color: var(--main-color);
  }
  @media screen and (min-width: 1280px) {
    margin-bottom: 48px !important;
  }
  @media screen and (max-width: 1279px) {
    font-size: 11px;
  }
`;
