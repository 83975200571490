import React from "react";
// syle
import {
  Container,
  InfoRecapContainer,
  Slot,
  Wrapper,
  Div,
  Label,
  Content,
  Category,
} from "./style";

function InfoBox({ data }) {
  return (
    <Container>
      <InfoRecapContainer>
        <h3>{data.title}</h3>
        <Slot>
          <Category>{data.labelTimetable}</Category>
          <Wrapper>
            {React.Children.toArray(
              data.items.map((el) => {
                return (
                  <Div>
                    <Label>{el.title}</Label>
                    <Content
                      dangerouslySetInnerHTML={{ __html: el.text }}
                    ></Content>
                  </Div>
                );
              })
            )}
          </Wrapper>
        </Slot>
        <Slot>
          <Category>{data.labelEmail}</Category>
          <Content>{data.email}</Content>
        </Slot>
        <Slot>
          <Category>{data.labelTelNumber}</Category>
          <Content>{data.telNumber}</Content>
        </Slot>
      </InfoRecapContainer>
    </Container>
  );
}

export default React.memo(InfoBox);
