import styled from "styled-components";

export const Divider = styled.div`
  margin: 32px 0px;
  height: 0px;
  border-bottom: 1px solid #8f8f8f;
  clear: both;
  width: 100%;
  @media (min-width: 1280px) {
    margin: 64px 0px;
  }
`;
