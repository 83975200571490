/* eslint-disable no-template-curly-in-string */
import React from "react";
import { useSelector } from "react-redux";
// style
import { Wrapper, Overlay, BoxContainer, BackButton } from "./style";

function ModalForm({ data, closeModal }) {
  const mail = useSelector((state) => state.login.tokenClaims.email);
  const strReplacedWithMail = data?.richText.replace(
    "${mail}",
    "<b>" + mail + "</b>"
  );

  return (
    <Wrapper>
      <Overlay />
      <BoxContainer>
        <h2>{data?.title}</h2>
        <div>
          <p dangerouslySetInnerHTML={{ __html: strReplacedWithMail }}></p>
        </div>
        <BackButton onClick={closeModal}>{data?.textButton}</BackButton>
      </BoxContainer>
    </Wrapper>
  );
}

export default React.memo(ModalForm);
