import { useSelector } from "react-redux";
import NucleoFamiliare from "./NucleoFamiliare";
import SedeDiLavoro from "./SedeDiLavoro";
import SlotGhLe from "./SlotGhLe";
import RitiroGift from "./RitiroGift";
import RegaloAggiuntivo from "./RegaloAggiuntivo";
import { useEffect } from "react";
import { useBusinessLogic } from "../../useBusinessLogic";

export default function ComponentGiftHouse({ data, onChange, allowed }) {
  const {
    giftHouseLigthExperienceComponents,
    nucleoFamiliare,
    SedeLavoro,
    SlotGiftHouseLe,
    ModalitaRitiro,
    AppointmentGift,
    GtModel,
  } = useBusinessLogic();
  const eventUserInfo = useSelector(
    (state) => state.FormIscrizioneGiftHouse.defaultUserInfo
  );
  const haveChildsInAge = eventUserInfo?.childsInAge;
  const childsRegisteredByConsort = eventUserInfo?.childsSubscribed?.find(
    (e) => e?.subscriptionInfo?.subscribedBy !== eventUserInfo?.userFiscalCode
  )
    ? true
    : false;

  const ShowSedeLavoro =
    nucleoFamiliare?.value === "false" ||
    childsRegisteredByConsort ||
    haveChildsInAge?.length < 1;
  const ShowSlotGiftHouse =
    (nucleoFamiliare?.value !== null || childsRegisteredByConsort) &&
    nucleoFamiliare?.value === "true";
  const ShowAdditionalGift =
    SlotGiftHouseLe?.value ||
    AppointmentGift?.value ||
    ModalitaRitiro?.value === "spedizione" ||
    ModalitaRitiro?.value === "ritiroLe" ||
    AppointmentGift?.value;

  useEffect(() => {}, [giftHouseLigthExperienceComponents]);

  return (
    <>
      {haveChildsInAge?.length > 0 && (
        <NucleoFamiliare
          check={nucleoFamiliare?.value}
          data={data}
          onChange={onChange}
          allowed={allowed}
        />
      )}
      {ShowSedeLavoro && (
        <SedeDiLavoro
          check={SedeLavoro?.value}
          data={data?.giftHouse}
          onChange={onChange}
          allowed={allowed}
        />
      )}
      {ShowSlotGiftHouse ? (
        <SlotGhLe
          data={data}
          check={SlotGiftHouseLe?.value}
          allowed={allowed}
          eventUserInfo={eventUserInfo}
          onChange={onChange}
        />
      ) : (
        SedeLavoro?.value !== null && (
          <RitiroGift
            data={data?.giftHouse}
            checkRitiro={ModalitaRitiro?.value}
            checkAppointmentID={AppointmentGift?.value}
            allowed={allowed}
            eventUserInfo={eventUserInfo}
            onChange={onChange}
          />
        )
      )}
      {ShowAdditionalGift && (
        <RegaloAggiuntivo
          data={data?.giftHouse}
          check={GtModel?.value}
          allowed={allowed}
          onChange={onChange}
        />
      )}
    </>
  );
}
