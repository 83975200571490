import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { FETCH_BASKETS, FETCH_TAKE_AWAY_COMPONENTS } from "./constants";
import { takeAwayActions } from "./takeAwaySlice";

export function* fetchBaskets() {
  try {
    const result = yield call(() => axios.get("/mocks/mock-baskets.json"));
    // add an unique id to baskets
    result.data.forEach((v, i) => {
      v.id = i;
      v.date = "13:00 - 13:15";
    });
    yield put(takeAwayActions.setBaskets(result.data));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchTakeAwayComponents({ payload }) {
  try {
    const { path, token } = payload;
    // now the endpoint adds the date of the current day, so if there are no plates on AEM nothing appears
    // the only date with plates is 2022/11/7
    const result = yield axiosCallGet(path, token);
    yield put(takeAwayActions.setComponents(result.data.data));

    const dishesOfTheDay = result.data.data.components.find(
      (v) => v.resourceType === "fi-cafeteria-dishes"
    );
    yield put(takeAwayActions.setBaskets(dishesOfTheDay.data.dishes));
  } catch (e) {
    console.error(e);
    yield put(takeAwayActions.setError(e));
  }
}

export default function* takeAwaySaga() {
  yield takeLatest(FETCH_BASKETS, fetchBaskets);
  yield takeLatest(FETCH_TAKE_AWAY_COMPONENTS, fetchTakeAwayComponents);
}
