import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 384px;
  padding: 32px;
  background: var(--alternative-bg-color);
  box-sizing: border-box;
  color: var(--main-color);
  form {
    display: ${(props) => (props.hideForm ? "none" : "")};
    border-bottom: 1px solid rgb(239, 237, 237);
  }
  img {
    transform: ${(props) => (props.hideForm ? "rotate(-90deg)" : "")};
  }
  h3 {
    margin-top: 0px;
  }
  input:not([type="radio"]) {
    background: transparent;
    color: var(--main-color);
    appearance: none;
    border: 1px solid rgb(118, 118, 118);
  }
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;
export const InputRadio = styled.div`
  border: 1px solid var(--main-color);
  position: relative;
  border-radius: 100%;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 8px;
  &:first-of-type {
    margin-left: 0;
  }
  input[type="radio"] + span {
    background: var(--main-color);
    display: none;
    border-radius: 100%;
  }
  input[type="radio"]:checked + span {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  input[type="radio"] {
    background: transparent;
    padding: 0;
    appearance: none;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
  }
`;

export const SelectWrapperWrapper = styled.div`
  display: flex;
  & > div {
    width: 100%;
  }
  select {
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  img {
    margin-left: auto;
    transform: ${(props) =>
      props.hideForm ? "rotate(-90deg)" : "rotate(90deg)"};
    cursor: pointer;
  }
`;

export const Form = styled.form``;
export const Label = styled.label`
  display: block;
  color: var(--alternative-grey-color);
  margin: 10px 0px;
  font-size: 11px;
  span {
    margin-bottom: 6px;
    display: block;
  }
`;
export const CheckBoxWrapper = styled.div`
  font-size: 12px;
  label {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

export const InnerForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  input {
    float: left;
    clear: both;
    min-width: 100%;
  }
  ${Label}:not(.width-100) {
    width: 45%;
    margin-right: 5%;
  }
`;

export const Disclaimer = styled.div`
  color: rgb(143, 143, 143);
  font-size: 8px;
  line-height: 10px;
`;

export const Button = styled.button.attrs({
  type: "submit",
})`
  padding: 20px 0px;
  width: 100%;
  color: var(--cta-color);
  font-size: 12px;
  margin: 20px 0px 40px;
  cursor: pointer;
`;

export const TrayWrapper = styled.div`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgb(239, 237, 237);
`;

export const TrayTitle = styled.div`
  margin: 24px 0px 26px;
`;

export const TrayEmpty = styled.p`
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  color: var(--cta-color);
`;

export const List = styled.ul`
  margin: 0px 0px 32px;
  padding: 0px 0px 32px;
  list-style: none;
  border-bottom: 1px solid rgb(239, 237, 237);
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const Span = styled.span`
  margin-left: auto;
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
`;
