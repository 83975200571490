import PropTypes from "prop-types";
import { axiosCallDownloadDoc } from "../../../../../../utils/axiosCalls/AxiosCalls";
import { useSelector } from "react-redux";

import {
  DocWrapper,
  DocTitleAndData,
  ExtensionAndSize,
  DonwloadIcon,
} from "./style";

function NewsDoc({ data }) {
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);

  const DownloadDocHandler = () => {
    const result = axiosCallDownloadDoc(
      lang,
      data.documentPath,
      data.name,
      token
    );
  };
  return (
    <DocWrapper className="doc-wrapper">
      {data.icon && <img src={data.icon} />}
      <DocTitleAndData className="doc-title-and-data">
        <h4>{data.name}</h4>
        <ExtensionAndSize>
          {data.extension} - {data.sizeLabel}
        </ExtensionAndSize>
      </DocTitleAndData>
      <DonwloadIcon className="doc-download">
        <div onClick={() => DownloadDocHandler()} className="download-tag-a">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
              fill="#EFEDED"
              className="doc-download-path"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 25.3333C19.427 25.3333 18.9135 25.0888 18.549 24.7028L18.547 24.7068L12 17.3333L19.0025 20.8345L19.5 10.0555C19.5 9.65681 19.724 9.33325 20 9.33325C20.276 9.33325 20.5 9.65681 20.5 10.0555L20.9975 20.8345L28 17.3333L21.453 24.7068L21.451 24.7028C21.0865 25.0888 20.573 25.3333 20 25.3333ZM20 29.3333H12C12 28.5969 12.8955 27.9999 14 27.9999H20H26C27.1045 27.9999 28 28.5969 28 29.3333H20Z"
              className="doc-download-path"
              fill="var(--main-color)"
            />
          </svg>
        </div>
      </DonwloadIcon>
    </DocWrapper>
  );
}

NewsDoc.propTypes = {
  data: PropTypes.exact({
    name: PropTypes.string,
    extension: PropTypes.string,
    size: PropTypes.number,
    sizeLabel: PropTypes.string,
    documentPath: PropTypes.string,
    icon: PropTypes.string,
  }),
};
export default NewsDoc;
