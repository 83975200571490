import styled from "styled-components";

export const FocusContainer = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 16px;

  @media screen and (max-width: 1279px) {
    flex-direction: column;
    width: auto;
  }
  @media (min-width: 1279px) {
    .carousel .carousel-dot {
      background: #fff !important;
    }
  }
  @media (min-width: 1279px){
    .carousel .carousel-dot {
      background: #fff !important;
    }
  }
`;
