import styled from "styled-components";

export const Container = styled.section`
  width: 800px;
  margin: 0 auto 96px;
  text-align: center;
  h1 {
    margin: 96px 0 24px;
  }
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input,
  & select {
    letter-spacing: 0.5px;
    color: var(--main-color);
  }
  & input::placeholder {
    color: rgb(112, 112, 112) !important;
  }
  textarea {
    width: 100%;
    background: transparent;
    padding: 8px;
    font: 14px / 20px ferrari-sans-regular;
    resize: vertical;
    box-sizing: border-box;
    color: var(--main-color);
  }
  form {
    width: 600px;
    margin: 80px auto 0;
    display: flex;
    flex-direction: column;
    gap: 36px;
    text-align: left;
  }
  .dropzone-small {
    width: 176px;
    height: 98px;
  }
  .dropzone-small-covered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .dropzone-large {
    width: 100%;
    height: 173px;
  }
  @media (max-width: 1279px) {
    width: 90%;
    form {
      width: 100%;
    }
    & input,
    & select,
    & textarea {
      font-size: 13px;
    }
    h1 {
      margin: 32px 0 24px;
      font-size: 22px;
      line-height: 28px;
    }
    & > form {
      margin-top: 0;
    }
    & > div {
      text-align: left;
      margin-bottom: 40px;
    }
    & {
      margin-bottom: 40px;
    }
  }
`;
export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  text-transform: uppercase;
  font-size: 12px;
  &:disabled {
    background: #d4d2d2;
    color: #181818;
    cursor: not-allowed;
  }
  @media (max-width: 1279px) {
    width: 100%;
    max-width: 100%;
  }
`;
export const InputSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0px 0px 0px;
  gap: 7px;
  position: relative;
  label {
    font-size: 11px;
    color: var(--carousel-dot-color);
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 7px 0px;
  }
  input:not(:placeholder-shown) + label {
    opacity: 1;
  }
`;
export const InputSectionCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 16px 0px 24px;
  gap: 12px;
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid var(--main-color);
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    padding: 0;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--main-color);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  span {
    max-width: calc(100% - 28px);
  }
`;
export const Carousel = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  img {
    max-width: 176px;
  }
`;
export const ImageContainer = styled.div`
  position: relative;
  img {
    max-width: 176px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .form-annuncio-dots {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }
  .image-overlay {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 14px;
    gap: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-sizing: border-box;
    z-index: 3;
  }
  .image-overlay img {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .image-overlay span {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #303030;
    cursor: pointer;
  }
`;

const actionChangeArrowColor = (tema) => {
  if (tema !== "dark") {
    return `
    .anchor:after {
      position: absolute;
      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' fill='black' viewBox='0 0 20 20'><path  fill='black' fillRule='evenodd' d='M15 0L5.915 8.066A2.495 2.495 0 005 10c0 .78.357 1.475.915 1.934L15 20l-5-10 5-10z' clipRule='evenodd'/></svg>");
      padding: 5px;
      right: 6px;
      top: 20%;
      transform: rotate(270deg);
    }
    `;
  }
  return `
  .anchor:after {
    position: absolute;
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' fill='white' viewBox='0 0 20 20'><path  fill='white' fillRule='evenodd' d='M15 0L5.915 8.066A2.495 2.495 0 005 10c0 .78.357 1.475.915 1.934L15 20l-5-10 5-10z' clipRule='evenodd'/></svg>");
    padding: 5px;
    right: 6px;
    top: 20%;
    transform: rotate(270deg);
  }
  `;
};

export const DropDownTematiche = styled.div`
  display: inline-block;
  .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    border-bottom: 1px solid #8f8f8f;
    width: 100%;
    padding: 14px 0px 16px 0px;
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  #thematicsPlaceHolder {
    ${(props) =>
      props.color === "true"
        ? "color: var(--main-color)"
        : "color: rgb(112,112,112)"};
  }
  @media (max-width: 1279px) {
    font-size: 13px;
  }
  ${(props) => actionChangeArrowColor(props.tema)};
`;

export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;

export const List = styled.ul`
  ${(props) => (props.isOpen ? "display:block" : "display:none")};
  padding: 2px;
  margin: 0;
  background: #f7f7f7;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.1);
  li {
    list-style: none;
    font-family: ferrari-sans-regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    color: #303030;
    padding: 8px;
  }
  span {
    padding-left: 8px;
  }

  /* .myinput[type="checkbox"]:before {
    align-items: center;
    display: block;
    width: 10px;
    height: 10px;
    border: 2px solid #303030;
    content: "";
    background: #fff;
  }

  .myinput[type="checkbox"]:after {
    display: block;
    position: relative;
    margin: 2px;
    bottom: 12.5px;
    content: "";
  }

  .myinput[type="checkbox"]:checked:after {
    width: 9px;
    height: 8px;
    background-color: black;
    content: "";
  } */
`;

export const TematicheLabel = styled.label`
  ${(props) => (props.isShown ? "display:block" : "display:none")};
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--carousel-dot-color);
`;
