import { useSelector } from "react-redux";
import CustomSelect from "../../../customSelect/CustomSelect";
import { ComponentContainer, TextDescr } from "../../style";
//components
import ErrorField from "../ErrorField/ErrorField";
import InputRadioButton from "../RadioButton/RadioButton";
import { useState, useEffect } from "react";
import { useBusinessLogic } from "../../useBusinessLogic";

export default function RitiroGift({
  data,
  onChange,
  allowed,
  checkRitiro,
  checkAppointmentID,
}) {
  const { getSubscriptionDay, SedeLavoro, ModalitaRitiro } = useBusinessLogic();
  const [slot, setSlot] = useState("");
  const lang = useSelector((state) => state.coreComponents.language);

  const Appointments = data?.appointments?.filter((appointment) =>
    appointment?.configurations?.find(
      (el) =>
        el.groupName === "deliveryMode" && el.fieldValue === "employeeOnly"
    )
  );
  const giftOptions = [
    {
      text: data?.deliveryLabel,
      value: "spedizione",
      id: "spedizione",
    },
    {
      text: data?.collectLabel,
      value: "ritiro",
      id: "ritiro ",
    },
    {
      text: data?.collectLabelAtLe,
      value: "ritiroLe",
      id: "ritiroLe",
    },
  ];

  useEffect(() => {
    setSlot("");
  }, [SedeLavoro, ModalitaRitiro]);
  return (
    <>
      <ComponentContainer>
        <h3>{data?.titleGetGift} </h3>
        {SedeLavoro?.value !== null && SedeLavoro?.value === "Maranello" && (
          <TextDescr>{data?.descrGetGift}</TextDescr>
        )}
        {SedeLavoro?.value !== null && SedeLavoro?.value !== "Maranello" && (
          <>
            <InputRadioButton
              description={data?.howToGetGiftTxt}
              array={giftOptions}
              name={`modalitaRitiro`}
              checked={checkRitiro}
              onChange={onChange}
            />
            {checkRitiro == null && allowed === false && (
              <ErrorField text={data?.errorMessage} />
            )}
          </>
        )}
      </ComponentContainer>
      {((SedeLavoro?.value !== "Maranello" && checkRitiro === "ritiro") ||
        SedeLavoro?.value === "Maranello") && (
        <ComponentContainer>
          <CustomSelect
            name="AppointmentIdGift"
            setValue={setSlot}
            defaultValue={slot}
            placeholderString={data?.slotLabel}
            onChange={onChange}
          >
            {Appointments?.map((appointment) => {
              if (
                appointment?.availablePlacesFrontEnd - appointment?.takenSeats >
                0
              ) {
                return (
                  <option
                    className="data-evento"
                    value={appointment?.id}
                    key={appointment?.id}
                  >
                    {getSubscriptionDay(
                      appointment?.appointmentStartDate,
                      appointment?.appointmentEndDate,
                      lang
                    )}
                  </option>
                );
              } else {
                return "";
              }
            })}
          </CustomSelect>
          {checkAppointmentID == null && allowed === false && (
            <ErrorField text={data?.errorMessage} />
          )}
        </ComponentContainer>
      )}
    </>
  );
}
