import React from "react";
// style
import { Wrapper, Container } from "./style";

function NoContent({ title }) {
  return (
    <Wrapper>
      <Container>
        <img src="/img/Icon-Search-grey.svg" />
        <p>{title}</p>
      </Container>
    </Wrapper>
  );
}

export default React.memo(NoContent);
