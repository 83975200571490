import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdvancedSearch } from "../../../../pages/Rubrica/actions";
import { rubricaActions } from "../../../../pages/Rubrica/rubricaSlice";
import {
  getAdvancedSearchLabels,
  getCostCenters,
  getDepartments,
} from "../../../../pages/Rubrica/selectors";
// style
import {
  Wrapper,
  Overlay,
  Content,
  InputMask,
  Input,
  SelectWrapper,
  SearchBtn,
  CloseButton,
  ContentWrapper,
  InputLabel,
} from "./style";

function SearchFilterRubricaDialog({ showDialog }) {
  document.body.style.overflow = showDialog ? "hidden" : "";

  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const isSearchButtonEnabled = useSelector(
    (state) => state.rubrica.isSearchButtonEnabled
  );
  const advancedLbl = useSelector(getAdvancedSearchLabels());
  const costCenter = useSelector(getCostCenters());
  const direction = useSelector(getDepartments());

  const advancedSearchFilters = useSelector(
    (state) => state.rubrica.advancedSearchFilters
  );

  //hardCoded direction

  const keyDownHandler = (event) => {
    if (event.keyCode === 8) {
      const { id, name } = event.target;
      const idLbl = name.concat("Lbl");
      document.getElementById(idLbl).style.display = "none";
      document.getElementById(id).selectedIndex = 0;
      const newResponse = { ...advancedSearchFilters, [name]: "" };
      dispatch(rubricaActions.setAdvancedSearchFilters(newResponse));
      activateSearchButton(newResponse);
    }
  };

  const activateSearchButton = (body) => {
    const onlyValued = Object.entries(body).filter(([_, v]) => v !== "");
    if (onlyValued.length > 0) {
      dispatch(rubricaActions.setFilterCount(onlyValued.length));
      document.getElementById("searchBtn").disabled = false;
      dispatch(rubricaActions.setSearchButton(true));
    } else {
      dispatch(rubricaActions.setFilterCount(0));
      document.getElementById("searchBtn").disabled = true;
      dispatch(rubricaActions.setSearchButton(false));
    } //remove span counter for filter
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    const id = name.concat("Lbl");

    if (value !== "") {
      document.getElementById(id).style.display = "block";
    } else {
      document.getElementById(id).style.display = "none";
    }

    const newResponse = { ...advancedSearchFilters, [name]: value };
    dispatch(rubricaActions.setAdvancedSearchFilters(newResponse));
    activateSearchButton(newResponse);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    //Form submission happens here
    const onlyValued = Object.fromEntries(
      Object.entries(advancedSearchFilters).filter(([_, v]) => v !== "")
    );
    var queryString = Object.keys(onlyValued)
      .map((key) => key + "=" + advancedSearchFilters[key])
      .join("&");
    dispatch(AdvancedSearch({ queryString: queryString, token: token }));
    dispatch(rubricaActions.showDialog(false));
  };

  return (
    <Wrapper showDialog={showDialog}>
      <Overlay></Overlay>
      <Content>
        <CloseButton>
          <img
            onClick={() => dispatch(rubricaActions.showDialog(false))}
            src="/img/close-light.svg"
            width="40"
            height="40"
          />
        </CloseButton>
        <ContentWrapper>
          <h3 style={{ color: "black" }}>{advancedLbl.title}</h3>
          <InputMask>
            <Input>
              <InputLabel id="nameLbl">
                {advancedLbl.namePlaceholder}
              </InputLabel>
              <input
                id="nameInpt"
                placeholder={advancedLbl.namePlaceholder}
                name="name"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
            <Input>
              <InputLabel id="surnameLbl">
                {advancedLbl.lastNamePlaceholder}
              </InputLabel>
              <input
                id="surnameInpt"
                placeholder={advancedLbl.lastNamePlaceholder}
                name="surname"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
            <Input>
              <InputLabel id="directionLbl">
                {advancedLbl.departmentPlaceholder}
              </InputLabel>
              <SelectWrapper>
                <select
                  id="cmbDirection"
                  className="placeholder"
                  name="direction"
                  defaultValue=""
                  onChange={(e) => inputChangeHandler(e)}
                  onKeyDown={(e) => keyDownHandler(e)}
                >
                  <option value="" disabled hidden>
                    {advancedLbl.departmentPlaceholder}
                  </option>
                  {React.Children.toArray(
                    direction.map((v) => <option value={v}>{v}</option>)
                  )}
                </select>
                <img src="/img/select-black-cursor.svg" />
              </SelectWrapper>
            </Input>
            <Input>
              <InputLabel id="costCenterLbl">
                {advancedLbl.costCenterPlaceholder}
              </InputLabel>
              <SelectWrapper>
                <select
                  id="cmbCostCenter"
                  className="placeholder"
                  name="costCenter"
                  defaultValue=""
                  onChange={(e) => inputChangeHandler(e)}
                  onKeyDown={(e) => keyDownHandler(e)}
                >
                  <option value="" disabled hidden>
                    {advancedLbl.costCenterPlaceholder}
                  </option>
                  {React.Children.toArray(
                    costCenter.map((v) => <option value={v}>{v}</option>)
                  )}
                </select>
                <img src="/img/select-black-cursor.svg" />
              </SelectWrapper>
            </Input>
            <Input>
              <InputLabel id="companyLbl">
                {advancedLbl.companyPlaceholder}
              </InputLabel>
              <input
                id="companyInpt"
                placeholder={advancedLbl.companyPlaceholder}
                name="company"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
            <Input>
              <InputLabel id="phoneLbl">
                {advancedLbl.telephonePlaceholder}
              </InputLabel>
              <input
                id="phoneInpt"
                placeholder={advancedLbl.telephonePlaceholder}
                name="phone"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
            <Input>
              <InputLabel id="mobilePhoneLbl">
                {advancedLbl.mobilePlaceholder}
              </InputLabel>
              <input
                id="mobilePhoneInpt"
                placeholder={advancedLbl.mobilePlaceholder}
                name="mobilePhone"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
            <Input>
              <InputLabel id="dectLbl">
                {advancedLbl.superCordlessPlaceholder}
              </InputLabel>
              <input
                id="dectInpt"
                placeholder={advancedLbl.superCordlessPlaceholder}
                name="dect"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
            <Input>
              <InputLabel id="faxLbl">{advancedLbl.faxPlaceholder}</InputLabel>
              <input
                id="faxInpt"
                placeholder={advancedLbl.faxPlaceholder}
                name="fax"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
            <Input>
              <InputLabel id="emailLbl">
                {advancedLbl.emailPlaceholder}
              </InputLabel>
              <input
                id="emailInpt"
                placeholder={advancedLbl.emailPlaceholder}
                name="email"
                onChange={(e) => inputChangeHandler(e)}
              />
            </Input>
          </InputMask>
          <SearchBtn
            id="searchBtn"
            isSearchButtonEnabled={isSearchButtonEnabled}
            onClick={onSubmitHandler}
          >
            {advancedLbl.searchLabel}
          </SearchBtn>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
}

export default SearchFilterRubricaDialog;
