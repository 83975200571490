import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS } from "./constants";
import { eventiActions } from "./eventiSlice";

function* fetchEventsComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(eventiActions.setComponents(result.data));
  } catch (e) {
    console.error(e);
    yield put(eventiActions.setError(e));
  }
}

export default function* eventsSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchEventsComponents);
}
