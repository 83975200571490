import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1280px) {
    width: 600px;
    padding: 48px;
  }
  position: relative;
  z-index: 2;
  text-align: center;
  margin: 0px;
  box-sizing: border-box;
  background: var(--main-bg);
  color: var(--main-color);
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 32px;
  width: 100%;
  overflow: auto;
  padding: 48px;
  h2 {
    color: var(--main-color) !important;
  }
  @media (max-width: 1279px) {
    > h2 {
      display: none;
    }
  }
`;

export const ToolsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin: auto;
  max-width: 475px;
  @media (max-width: 1279px) {
    margin-top: 5%;
    width: auto;
    justify-content: space-between;
  }
`;

export const Divider = styled.div`
  height: 0px;
  border-bottom: 1px solid;
  border-color: rgb(212, 210, 210);
  margin: 2em 0px;
  float: left;
  clear: both;
  width: 100%;
`;

export const CtaButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 1px;
  font-size: 12px;
  clear: both;
  a {
    color: var(--main-color);
    text-decoration: none;
    text-transform: uppercase;
  }
  span {
    cursor: pointer;
    text-transform: uppercase;
  }
`;
