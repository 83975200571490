import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  setComponents,
  setError,
  fetchComponents,
  saveAd,
  getDetail,
  setDetail,
  setStatusNull,
} from "./FormAnnuncioSlice";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/notifications/TriggerNotification";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(setComponents(result.data.data));
    if (payload.id) {
      yield put(setStatusNull());
    }
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}
function* getDetailWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(setDetail(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}
function* saveAdWorker({ payload }) {
  try {
    const body = payload.body;
    try {
      document.body.classList.add("loading");
      yield axiosCallPost("/ad_board", body, payload.token, true, false);
      showSuccessMessage("Ok");
    } catch (e) {
      showErrorMessage("ko");
    } finally {
      document.body.classList.remove("loading");
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(fetchComponents.type, fetchComponentsWorker);
  yield takeLatest(saveAd.type, saveAdWorker);
  yield takeLatest(getDetail.type, getDetailWorker);
}
