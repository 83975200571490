import styled from "styled-components";

export const Container = styled.div`
  padding: ${(props) => (props.hideDetails ? "0px" : "0px 0px 32px")};
  background: var(--alternative-bg-color);
`;

export const HeadingText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 24px;
  padding: 32px 32px 0px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1279px) {
    padding: 18px 18px 0px;
    align-items: center;
    flex-direction: column;
  }
`;

export const HeadingDetailsText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 32px;
  row-gap: 8px;
  @media (max-width: 1279px) {
    text-align: center;
    padding-bottom: 24px;
    display: block;
    margin-top: 16px;
  }
`;

export const HeadingDetailsLine = styled.span`
  border-left: 1px solid rgb(143, 143, 143);
  height: 16px;
`;

export const HeadingDetailsLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  column-gap: 8px;
  margin-left: auto;
  img {
    max-width: 100%;
    min-width: 40px;
  }
  @media (max-width: 1279px) {
    img {
      min-width: 25px;
      min-height: 25px;
    }
  }
`;

export const CardDropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
  width: 100%;
  border-top: 1px solid rgb(239, 237, 237);
  svg {
    transform: ${(props) => (props.hideDetails ? "" : "rotate(180deg)")};
  }
`;

export const CardDetails = styled.div`
  display: ${(props) => (props.hideDetails ? "none" : "flex")};
  flex-direction: column;
  row-gap: 32px;
  padding-left: 128px;
  width: 100%;
  @media (max-width: 1279px) {
    padding-left: 28px;
  }
`;

export const CardDetailsFrstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
`;

export const CardDetailsFrstRowFisso = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const CardLocation = styled.div`
  color: rgb(143, 143, 143);
  letter-spacing: 0.28125px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 11px;
`;

export const HeadingBody = styled.div`
  display: flex;
  column-gap: 8px;
  font-size: 13px;
  line-height: 20px;
  @media (max-width: 1279px) {
    width: 100%;
    margin-top: 18.5px;
    padding-bottom: 16px;
  }
`;

export const CardBody = styled.span`
  font-size: 13px;
  line-height: 20px;
`;
export const DefaultNumText = styled.span`
  margin-left: 16px;
  font-size: 13px;
  line-height: 20px;
`;

export const CardEmail = styled.div`
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;
`;

export const CardDetailsFrstRowCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const CardDetailsSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
`;

export const CardDetailsEmail = styled.div`
  display: block;
`;

export const CardDetailsDove = styled.div`
  display: block;
`;

export const CardHeadingDetailsInitialCircle = styled.div`
  background: rgb(239, 237, 237);
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: flex;
  color: black;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
`;
export const CardHeadingDetailsInitialText = styled.div`
  width: 72px;
`;

export const CardHeadingDetailsImg = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 100%;
  pointer-events:none;
`;
