import { Container, TitleForm, SendButton } from "./style";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchSetEventPreferences } from "../../../pages/FormIscrizioneFamilyDay/action";
import {
  resetMealQuantity,
  setBodyPayload,
} from "../../../pages/FormIscrizioneFamilyDay/FormIscrizioneFamilyDaySlice";

import Hero from "./components/Hero/Hero";
import SecondaryAppointment from "./components/SecondaryAppointment";
import NumGuests from "./components/NumGuests";
import MealMenus from "./components/ComponentMealMenu/MealMenus";
import Entrance from "./components/Entrance";
import AppointmentId from "./components/AppointmentId";
import ComponentPrivacy from "./components/ComponentPrivacy/ComponentPrivacy";
import { LimitReachedDialog } from "./components/LimitReachedDialog";
import { isEqual } from "lodash";

function LayoutFormIscrizioneFamilyDay({ title, data, heroData }) {
  const [allowed, setAllowed] = useState(null);
  const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState(false);

  const dispatch = useDispatch();
  const formRef = useRef(null);

  //selectors
  const token = useSelector((state) => state.login.activeToken);
  const { language } = useSelector((state) => state.coreComponents);
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneFamilyDay.bodyPayload
  );
  const checkedConsents = useSelector(
    (state) => state.FormIscrizioneFamilyDay.checkedConsents
  );

  const remainingMenus = useSelector(
    (state) => state.FormIscrizioneFamilyDay.remainingMenus
  );
  const savedBodyPayload = useSelector(
    (state) => state.FormIscrizioneFamilyDay.savedBodyPayload
  );

  const isSubmitActive =
    !Object.values(bodyPayload).includes(null) &&
    // !isEqual(bodyPayload, savedBodyPayload) &&
    bodyPayload?.optionsValue?.[0]?.value !== null &&
    remainingMenus <= 0 &&
    checkedConsents?.length === data?.policiesRules?.items?.length &&
    !checkedConsents?.find((el) => el.checked === false);

  function calcolateMenuQuantity() {
    return bodyPayload.mealMenus
      .map((menu) => parseInt(document.getElementById(menu.mealMenuId).value))
      .reduce((a, b) => parseInt(a + b));
  }

  const handleEventInfo = (e) => {
    const { name, value } = e.currentTarget;
    if (name === "secondaryAppointmentId" && !bodyPayload?.isNew) {
      dispatch(
        setBodyPayload({
          ...bodyPayload,
          [name]: parseInt(value),
          numGuests: null,
        })
      );
      dispatch(resetMealQuantity());
    } else {
      dispatch(
        setBodyPayload({
          ...bodyPayload,
          [name]: parseInt(value),
        })
      );
    }
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setButtonHasBeenClicked(true);
    const path = `subscription-recap-family-day?path=${data?.linkHref}?eventCode=${data?.event?.code}`;
    if (
      // !isEqual(bodyPayload, savedBodyPayload) &&
      isSubmitActive
    ) {
      setAllowed(true);
      dispatch(
        FetchSetEventPreferences({
          bodyPayload,
          language,
          token,
          path,
        })
      );
    } else {
      setAllowed(false);
      const element =
        document.getElementById("required")?.previousElementSibling;
      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    const element = document.getElementById("required")?.previousElementSibling;
    if (buttonHasBeenClicked) {
      element &&
        !allowed &&
        element?.scrollIntoView({ behavior: "smooth", block: "center" });
      setButtonHasBeenClicked(false);
    }
  }, [allowed]);

  return (
    <>
      {heroData?.fileReference && <Hero data={heroData} />}
      {(!bodyPayload.secondaryAppointmentId &&
        data?.event?.appointments?.event?.length > 0) ||
      bodyPayload.secondaryAppointmentId ? (
        <Container>
          <TitleForm>{title?.jcrTitle}</TitleForm>
          <form
            onSubmit={(e) => {
              onSubmitHandler(e);
            }}
            ref={formRef}
          >
            <SecondaryAppointment
              data={data}
              onChange={handleEventInfo}
              allowed={allowed}
            />
            <NumGuests
              data={data}
              allowed={allowed}
              calcolateMenuQuantity={calcolateMenuQuantity}
            />
            <MealMenus
              data={data}
              allowed={allowed}
              calcolateMenuQuantity={calcolateMenuQuantity}
            />
            <Entrance data={data} allowed={allowed} />
            <AppointmentId
              data={data}
              onChange={handleEventInfo}
              allowed={allowed}
            />
            <ComponentPrivacy data={data} allowed={allowed} />
            <SendButton
              className={
                isSubmitActive ? "submitButton activeSubmit" : "submitButton"
              }
            >
              {bodyPayload?.isNew
                ? data?.button?.subscribeLbl
                : data?.button?.modifyLbl}
            </SendButton>
          </form>
        </Container>
      ) : (
        <LimitReachedDialog data={data} />
      )}
    </>
  );
}

export default LayoutFormIscrizioneFamilyDay;
