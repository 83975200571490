import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  FETCH_COMPONENTS,
  SAVE_FAV_TOOLS_ORDER,
  SAVE_FAV_TOOL,
} from "./constants";
import { toolsActions } from "./toolsSlice";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/notifications/TriggerNotification";

const ORDERING_TOOLS_SERVICE_URL = "/toolsdata";

function* fetchStrumentiComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(toolsActions.setComponents(result.data.data));
    yield put(toolsActions.setToolBoxes(result.data.data.components));
    yield put(toolsActions.orderByFav());
  } catch (e) {
    console.error(e);
    yield put(toolsActions.setError(e));
  }
}

function* saveFavToolsOrder({ payload }) {
  try {
    const toolsList = payload.favToolsMap;
    //Retriving only the ids
    const toolsListIds = toolsList.map((v) => {
      const el = v.id;
      return el;
    });

    const body = { id: toolsListIds };
    yield axiosCallPost(
      ORDERING_TOOLS_SERVICE_URL,
      body,
      payload.token,
      true,
      false
    );
  } catch (e) {
    console.error(e);
  }
}

function* saveFavTool({ payload }) {
  try {
    //Retriving only the ids
    const body = { id: payload.currentToolBox.id };
    yield axiosCallPost(
      ORDERING_TOOLS_SERVICE_URL,
      body,
      payload.token,
      true,
      false
    );
    yield put(toolsActions.setFavouriteTool(payload.currentToolBox));
    if (payload.currentToolBox.isFavourite) {
      if (payload.lang === "it") {
        showSuccessMessage("Strumento rimosso dai preferiti");
      } else showSuccessMessage("Tool removed from favourites");
    } else {
      if (payload.lang === "it") {
        showSuccessMessage("Strumento aggiunto ai preferiti");
      } else showSuccessMessage("Tool added to favourites");
    }
  } catch (e) {
    console.error(e);
    if (payload.lang === "it") {
      showErrorMessage("Errore nel salvataggio del preferito");
    } else showErrorMessage("Failed to add the tool as favourite");
  }
}

export default function* strumentiSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchStrumentiComponents);
  yield takeLatest(SAVE_FAV_TOOLS_ORDER, saveFavToolsOrder);
  yield takeLatest(SAVE_FAV_TOOL, saveFavTool);
}
