import styled from "styled-components";

export const Text = styled.div`
  margin: 0 24px;
  font-size: 13px;
  line-height: 20px;
  a {
    color: var(--main-color);
    text-decoration: underline;
  }
`;

export const FormWrapper = styled.div`
  max-width: 592px;
  margin: 64px auto auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  @media (max-width: 1279px) {
    gap: 32px !important;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    max-width: none;
  }
  textarea {
    width: 100%;
    background: transparent;
    padding: 8px;
    color: var(--main-color);
    font: 14px / 20px ferrari-sans-regular;
    resize: vertical;
    box-sizing: border-box;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  select {
    width: 100%;
    border-left: 0px rgb(143, 143, 143);
    border-right: 0px rgb(143, 143, 143);
    border-top: 0px rgb(143, 143, 143);
    background: transparent;
    border-bottom-color: rgb(143, 143, 143);
    font-family: ferrari-sans-regular;
    font-size: 11px;
    padding: 12px 16px 14px;
    color: rgb(48, 48, 48);
    max-width: 100%;
    appearance: none;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    pointer-events: none;
  }
`;

export const SendButton = styled.button`
  width: 40%;
  padding: 20px 0px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 5%;
  &:disabled {
    background: #d4d2d2;
    color: #181818;
    cursor: not-allowed;
  }
  @media (max-width: 1279px) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 5%;
  }
`;
