import PropTypes from "prop-types";
// style
import { Wrapper } from "./style";

function Text({ data }) {
  function html() {
    return { __html: data.text };
  }
  return <Wrapper dangerouslySetInnerHTML={html()}></Wrapper>;
}
Image.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default Text;
