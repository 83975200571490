import React from "react";
import PropTypes from "prop-types";
// components
import AdvContact from "./advContact/AdvContact";
// style
import { Container } from "./style";

function RightColumnContainer({ rightColumnComponents }) {
  const componentsMapping = {
    "fi-contact-recap": AdvContact,
  };

  return (
    <Container data-cs-mask>
      {React.Children.toArray(
        rightColumnComponents?.map((element) => {
          const ReactGenericComponent = componentsMapping[element.resourceType];
          return (
            ReactGenericComponent && (
              <ReactGenericComponent
                data={element.data}
              ></ReactGenericComponent>
            )
          );
        })
      )}
    </Container>
  );
}

RightColumnContainer.propTypes = {
  rightColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default RightColumnContainer;
