import { List, ScrollLoader } from "./style";
import React, { useRef, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

//components
import NewsCard from "./newsCard/NewsCard";

const NEWSCARD_COMPONENT_NAME = "news-card";

export default function NewsCardList({ data }) {
  const results = data.results;
  const resultsLength = results.length;
  const [slice, setSlice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const loader = useRef(null);

  let loaded = 0;
  let numOfCardLoaded = 18;
  const isLoadingFunc = () => {
    loaded++;
    if (loaded === 3) {
      setIsLoading(false);
      loaded = 0;
    }
  };

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setSlice((prev) => prev + numOfCardLoaded);
        setIsLoading(true);
        numOfCardLoaded = 3;
      }
    },
    [numOfCardLoaded]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <>
      <List>
        {results.slice(0, slice).map((currentCard, idx) => (
          <NewsCard
            key={`${NEWSCARD_COMPONENT_NAME}-${idx}`}
            newsCardProps={currentCard}
            isLoadingFunc={isLoadingFunc}
          />
        ))}
      </List>
      {slice < resultsLength && (
        <ScrollLoader
          ref={loader}
          style={isLoading ? { display: "none" } : {}}
        />
      )}
    </>
  );
}

NewsCardList.propTypes = {
  data: PropTypes.exact({
    resultsNumber: PropTypes.number,
    total: PropTypes.number,
    results: PropTypes.array,
    position: PropTypes.string,
  }),
};
