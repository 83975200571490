import React from "react";
//components
import ErrorField from "../ErrorField/ErrorField";
import InputRadioButton from "../RadioButton/RadioButton";
import { useBusinessLogic } from "../../useBusinessLogic";

export default function PartecipazioneLe({ data, onChange, allowed, check }) {
  const { ModalitaRitiro } = useBusinessLogic();

  const lightExperience = [
    { text: data?.yesLabel, value: "true", id: "yesLightExperience" },
    { text: data?.noLabel, value: "false", id: "noLightExperience" },
  ];

  return (
    <>
      <InputRadioButton
        description={data?.description}
        array={lightExperience}
        name={`lightExperience`}
        checked={check}
        onChange={onChange}
        disabled={ModalitaRitiro?.value === "ritiroLe" ? true : false}
      />
      {check == null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </>
  );
}
