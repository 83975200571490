import styled from "styled-components";

export const RulesWrapper = styled.div`
  grid-area: rule / rule / rule / rule;
  border-bottom: 1px solid;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  a {
    margin: auto 0 32px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 12px;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
  > div {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
  }
  > p {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
  }
`;
