import { List, Wrapper } from "./style";
import { useLocation } from "react-router-dom";
import React, { useState } from "react";

function FaqMenu({ titles }) {
  const location = useLocation();
  const [currentFaq, setCurrentFaq] = useState(
    location.hash || "#faq-detail-0"
  );
  return (
    <Wrapper>
      <List>
        {titles.map((v, i) => {
          const anchor = `#faq-detail-${i}`;
          return (
            <li
              key={v}
              className={anchor === currentFaq ? "selected" : ""}
              onClick={() => setCurrentFaq(anchor)}
            >
              <a href={anchor}>{v.data.title}</a>
            </li>
          );
        })}
      </List>
    </Wrapper>
  );
}
export default FaqMenu;
