import styled from "styled-components";

export const DocWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(212, 210, 210);
  padding-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
`;

export const DocTitleAndData = styled.div`
  h4 {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    margin-bottom: 8px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: 50px;
  }
`;

export const ExtensionAndSize = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: rgb(143, 143, 143);
`;

export const DonwloadContainer = styled.div`
  margin-left: auto;
`;

export const DonwloadIconAndText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 32px;

  color: var(--main-color);

  margin-left: auto;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;

  @media screen and (min-width: 1280px) {
    span {
      margin-right: 10px;
      display: block !important;
    }
  }
  @media screen and (max-width: 1279px) {
    span {
      display: none;
    }
  }
`;
