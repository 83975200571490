import styled from "styled-components";

export const CtaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  & > div {
    position: static;
  }
  a {
    color: var(--main-color);
    letter-spacing: 1px;
  }
  path {
    fill: var(--cta-color);
  }
  @media (min-width: 1280px) {
    justify-content: flex-end;
  }
`;
