import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 32px;
  width: 800px;
  padding: 32px 0px 32px 0px;
  border-bottom: 1px solid #d4d2d2;
  cursor: pointer;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  line-height: 13px;
  width: 80%;
  @media (max-width: 1279px) {
    max-width: 60%;
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
`;

export const Description = styled.p`
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #707070;
`;

export const DateContainer = styled.div`
  text-transform: uppercase;
  @media (max-width: 1279px) {
    min-width: fit-content;
  }
`;

export const Day = styled.div`
  font-size: 28px;
  text-align: center;
  line-height: 34px;
`;

export const MonthYear = styled.div`
  text-align: center;
  font-size: 13px;
  line-height: 34px;
`;
