import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { FETCH_COMPONENTS } from "./constants";

import { RecapAndDetailMondialiActions } from "./RecapAndDetailMondialiSlice";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    //   const result = yield call(() =>
    //   axios.get("/mocks/mock-iscrizione-effettuata-mondiali.json")
    // );
    yield put(RecapAndDetailMondialiActions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(RecapAndDetailMondialiActions.setError(e));
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
}
