import React from "react";
import {
  FileContainer,
  UploadedFileText,
  UploadedFile,
  UploadedFileName,
  UploadedFileSize,
  UploadedFileRemove,
} from "./style";
export function SingleFile({ files, file, id, setFiles, data }) {
  const dimension = (file.size / 1000000).toFixed(3);

  const handleRemove = (e) => {
    e.preventDefault();
    setFiles(
      files.reduce((acc, item) => {
        if (item.id !== id) {
          acc.push(item);
          item.id = acc.length;
        }
        return acc;
      }, [])
    );
  };
  return (
    <FileContainer>
      <UploadedFile>
        <UploadedFileText>
          <UploadedFileName>{file.name}</UploadedFileName>
          <UploadedFileSize>
            {file.type} - {dimension} Mb
          </UploadedFileSize>
        </UploadedFileText>
        <UploadedFileRemove onClick={(e) => handleRemove(e)}>
          {data?.removeFilesLbl}
          <button>
            <img src="./../../img/close-light.svg" />
          </button>
        </UploadedFileRemove>
      </UploadedFile>
    </FileContainer>
  );
}
