import styled from "styled-components";

export const Title = styled.h1`
  margin: 96px 0px 64px;
  font-size: 36px;
  line-height: 43px;
  @media (max-width: 1279px) {
    margin: 48px auto 32px;
    font-size: 24px;
  }
`;

export const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  @media (max-width: 1279px) {
    margin: 0 24px;
  }
`;
