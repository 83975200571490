// hooks
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// style
import {
  ToolBoxWrapper,
  SpanToolBoxIcon,
  SpanDescription,
  Text,
} from "./style";
import { RedirectTo } from "../../../redirectTo/RedirectTo";
//actions to call for ext services (MW)
import { SaveFavTool } from "../../../../../pages/Strumenti/actions";
// notification actions
import { showErrorMessage } from "../../../../../utils/notifications/TriggerNotification";

const MAX_FAV_TOOLS_NUMBER = 9;
const YOUR_ECARD_TOOL_ID = "ferrari-intranet:tools/your-ecard";

function ToolBox({ currentToolBox }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const favToolsMap = useSelector((state) => state.tools.favToolsMap);
  const lang = useSelector((state) => state.coreComponents.language);

  const handleOnClickFav = (event) => {
    event.preventDefault();
    if (!currentToolBox.isDefault) {
      if (favToolsMap.length < MAX_FAV_TOOLS_NUMBER) {
        dispatch(SaveFavTool({ currentToolBox, token, lang }));
      } else {
        if (currentToolBox.isFavourite) {
          dispatch(SaveFavTool({ currentToolBox, token, lang }));
        } else {
          if (lang === "it") {
            showErrorMessage("Hai superato il limite massimo di preferiti");
          } else showErrorMessage("Favourite tools limit reached");
        }
      }
    }
  };

  const handleRedirectLink = () => {
    let linkResult;

    if (currentToolBox?.id === YOUR_ECARD_TOOL_ID) {
      if (currentToolBox?.isEcardActive) {
        linkResult = linkResult + "?isEcardActive=true";
      } else {
        linkResult = `/${lang}/tools/ecard-request`;
      }
    }

    return linkResult;
  };

  return (
    <ToolBoxWrapper>
      {currentToolBox?.id === YOUR_ECARD_TOOL_ID ? (
        <Link to={handleRedirectLink()}>
          <SpanToolBoxIcon>
            <div>
              <img src={currentToolBox?.image} />
            </div>
            <div>
              <img
                onClick={handleOnClickFav}
                src={
                  currentToolBox?.isFavourite || currentToolBox?.isDefault
                    ? "/img/tool-selected.svg"
                    : "/img/tool-unselected.svg"
                }
              />
            </div>
          </SpanToolBoxIcon>
          <SpanDescription>
            <span>{currentToolBox?.title}</span>
            <Text>{currentToolBox?.description}</Text>
          </SpanDescription>
        </Link>
      ) : (
        <RedirectTo element={currentToolBox}>
          <SpanToolBoxIcon>
            <div>
              <img src={currentToolBox?.image} />
            </div>
            <div>
              <img
                onClick={handleOnClickFav}
                src={
                  currentToolBox?.isFavourite || currentToolBox?.isDefault
                    ? "/img/tool-selected.svg"
                    : "/img/tool-unselected.svg"
                }
              />
            </div>
          </SpanToolBoxIcon>
          <SpanDescription>
            <span>{currentToolBox?.title}</span>
            <Text>{currentToolBox?.description}</Text>
          </SpanDescription>
        </RedirectTo>
      )}
    </ToolBoxWrapper>
  );
}

ToolBox.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    linkHref: PropTypes.string,
    description: PropTypes.string,
    isFavourite: PropTypes.bool,
  }),
};

export default ToolBox;
