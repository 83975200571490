import LayoutFormAnnunci from "../../components/UI/formAnnunci/LayoutFormAnnunci";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents, getDetail } from "./ProgrammaCoinvolgimentoSlice";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import { getForm } from "./selectors";
import LayoutProgrammaCoinvolgimento from "../../components/UI/ProgrammaCoinvolgimento/LayoutProgrammaCoinvolgimento";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

export default function ProgrammaCoinvolgimento({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const formLabels = useSelector(getForm());
  const components = useSelector(
    (state) => state.ProgrammaCoinvolgimento.components
  );

  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const titleDoc = headerLinks?.find((v) => v.path === path)?.title;
  useMemo(() => {
    path &&
      token &&
      dispatch(
        fetchComponents({
          path,
          token,
        })
      );
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch, path]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, titleDoc, path);
  const { status, errorData } = GetStatusAndErrorData(
    "ProgrammaCoinvolgimento"
  );
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        formLabels?.data && (
          <LayoutProgrammaCoinvolgimento
            heroImg={components.settings.heroImage}
            data={formLabels.data}
          ></LayoutProgrammaCoinvolgimento>
        )
      )}
    </>
  );
}
