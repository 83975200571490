import styled from "styled-components";

export const Wrapper = styled.section`
  width: 1216px;
  clear: both;
  margin: auto;
  display: block;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const Divider = styled.div`
  height: 0px;
  margin: 2em 0px;
  float: left;
  clear: both;
  width: 100%;
  border-bottom: 1px solid;
  border-color: rgb(212, 210, 210);
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const ToolsPageH2 = styled.div`
  clear: both;
  display: flex;
  justify-content: space-between;

  align-items: center;

  @media screen and (min-width: 1280px) {
    margin-bottom: 76px;
  }

  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const SelectWrapper = styled.div`
  width: 280px;
  position: relative;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;

export const Select = styled.select`
  width: 100%;
  appearance: none;
  position: relative;
  ${(props) => (props.noborder ? "border:none" : "")};
  &,
  input,
  option {
    font-family: ferrari-sans-regular;
    font-size: 11px;
    padding: 12px 16px 14px;
    color: var(--main-color);
    max-width: 100%;
    background: transparent;
  }

  select:not(:-internal-list-box) {
    overflow: visible !important;
  }
`;

export const WrapperImg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  max-width: 100%;
`;

export const ToolListWrapper = styled.div`
  justify-content: left;
  clear: both;
  max-width: none;
  gap: 28px;

  display: flex;
  flex-wrap: wrap;
  margin: auto;

  @media screen and (max-width: 1279px) {
    margin-top: 32px;
    place-content: center;
  }
`;
