import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./myPageSlice";

export const NOT_SET_DEPARTMENT = "no-department";

const myPageState = (state) => state.myPage || initialState;

export const selectMyPageComponent = () =>
  createSelector(
    myPageState,
    (state) =>
      state.components.find((v) => v.resourceType === "fi-my-page")?.data
  );

export const selectMyEventComponent = () =>
  createSelector(
    myPageState,
    (state) =>
      state.components.find((v) => v.resourceType === "fi-my-page-my-events")
        ?.data
  );
export const selectDialogDelete = () =>
  createSelector(
    myPageState,
    (state) =>
      state.components.find(
        (v) => v.resourceType === "fi-my-page-my-events-dialogs"
      )?.data?.cannotDeleteModal
  );
export const selectDialogConfirm = () =>
  createSelector(
    myPageState,
    (state) =>
      state.components.find(
        (v) => v.resourceType === "fi-my-page-my-events-dialogs"
      )?.data?.deleteConfirmation
  );

export const selectTabIndex = () =>
  createSelector(myPageState, (state) => state.tabIndex);

export const selectTabComponentsById = (tabIndex) =>
  createSelector(myPageState, (state) =>
    state.tabsArray.filter((tab) => tab.id === tabIndex)
  );

export const selectAvatarComponent = () =>
  createSelector(
    myPageState,
    (state) =>
      state.components?.find((v) => v.resourceType === "fi-my-page")?.data
        ?.avatar
  );

export const selectTabTitlesFromTabsArray = () =>
  createSelector(myPageState, (state) => {
    const tabsTitles = state.tabsArray.map((tab) => {
      return { id: tab?.id, title: tab.components?.title };
    });
    return tabsTitles;
  });

export const selectIsEcardActive = () =>
  createSelector(myPageState, (state) => state?.isEcardActive);

export const selectAggregatedDirection = () =>
  createSelector(
    myPageState,
    (state) =>
      state.components?.find((v) => v.resourceType === "fi-my-page")?.data
        ?.profilo?.infoPersonali?.aggregatedDirection || NOT_SET_DEPARTMENT
  );
