import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CaloricIntakeRecap from "../caloricIntakeRecap/CaloricIntakeRecap";
import {
  SetUserData,
  fetchCaloricIntake,
} from "../../../../pages/Mensa/actions";

// style
import {
  Wrapper,
  Details,
  Form,
  Label,
  CheckBoxWrapper,
  InnerForm,
  Disclaimer,
  Button,
  TrayWrapper,
  List,
  ListItem,
  Span,
  TrayTitle,
  TrayEmpty,
  SelectWrapperWrapper,
  InputRadio,
} from "./style";
import {
  SelectWrapper,
  Select,
  WrapperImg,
} from "../../strumenti/toolBoxList/style";
import BottomIcon from "../../../assets/bottom";

function CaloricIntake({ cafeteriaCaloricIntake }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  useEffect(() => {
    dispatch(fetchCaloricIntake(token));
  }, [dispatch, token]);
  const { pageLight } = useSelector((state) => state.coreComponents);
  const [hideForm, setHideForm] = useState(false);
  const { trayItems, totalKcal, caloricIntake } = useSelector(
    (state) => state.mensa
  );

  const caloricIntakeLbl = caloricIntake + " kcal";
  const Gender = {
    Male: 1,
    Female: 2,
  };

  const [checked, setChecked] = useState(() => {
    if (cafeteriaCaloricIntake.gender === Gender.Male)
      return { m: true, f: false };
    else return { m: false, f: true };
  });

  const SetGender = (e) => {
    setChecked(() => {
      const value = e.target.value === "m" ? 1 : 2;
      setResponseBody({ ...responseBody, gender: value });
      return {
        m: false,
        f: false,
        [e.target.value]: true,
      };
    });
  };

  const Activity = [
    { name: "leggero", val: 1 },
    { name: "moderato", val: 2 },
    { name: "pesante", val: 3 },
  ];

  let weightArray = [];
  let ageArray = [];

  const InitParams = () => {
    //setting weigth interval
    var i;
    for (i = 30; i < 200; i++) {
      weightArray.push(i);
    }

    //setting age interval
    for (i = 16; i < 100; i++) {
      ageArray.push(i);
    }
  };

  InitParams();

  const formData = {
    weight: cafeteriaCaloricIntake.weight,
    height: cafeteriaCaloricIntake.height,
    gender: cafeteriaCaloricIntake.gender,
    activity: cafeteriaCaloricIntake.activity,
    age: cafeteriaCaloricIntake.age,
  };

  const [responseBody, setResponseBody] = useState(formData);

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setResponseBody({ ...responseBody, [name]: parseInt(value) });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    //Form submission happens here
    dispatch(SetUserData({ responseBody, token }));
    // dispatch(fetchCaloricIntake());
  };

  return (
    <Wrapper hideForm={hideForm}>
      <h3>{cafeteriaCaloricIntake.title}</h3>
      <Details hideForm={hideForm}>
        <span>{cafeteriaCaloricIntake.myDataLabel}</span>
        {pageLight !== "dark" ? (
          <img src="/img/freccia.svg" onClick={() => setHideForm(!hideForm)} />
        ) : (
          <img
            src="/img/freccia-light.svg"
            onClick={() => setHideForm(!hideForm)}
          />
        )}
      </Details>
      <Form onSubmit={onSubmitHandler}>
        <Label>{cafeteriaCaloricIntake.genderLabel}</Label>
        <CheckBoxWrapper>
          <InputRadio>
            <input
              type="radio"
              id="male"
              name="gender"
              defaultValue="m"
              checked={checked.m}
              onChange={SetGender}
            />
            <span></span>
          </InputRadio>
          <label htmlFor="male">Maschio</label>
          <InputRadio>
            <input
              type="radio"
              id="female"
              name="gender"
              defaultValue="f"
              checked={checked.f}
              onChange={SetGender}
            />
            <span></span>
          </InputRadio>
          <label htmlFor="female">Femmina</label>
        </CheckBoxWrapper>
        <InnerForm>
          <Label>
            <span>{cafeteriaCaloricIntake.weightLabel}</span>
            <SelectWrapperWrapper>
              <SelectWrapper>
                <Select
                  defaultValue={cafeteriaCaloricIntake.weight}
                  name="weight"
                  onChange={(e) => inputChangeHandler(e)}
                >
                  {React.Children.toArray(
                    weightArray.map((v) => {
                      const label = v + " KG";
                      return <option value={v}>{label}</option>;
                    })
                  )}
                </Select>
                <WrapperImg>
                  <BottomIcon></BottomIcon>
                </WrapperImg>
              </SelectWrapper>
            </SelectWrapperWrapper>
          </Label>
          <Label>
            <span>{cafeteriaCaloricIntake.heightLabel}</span>
            <input
              type="number"
              name="height"
              min={100}
              max={240}
              defaultValue={cafeteriaCaloricIntake.height}
              onChange={(e) => inputChangeHandler(e)}
            />
          </Label>
          <Label>
            <span>{cafeteriaCaloricIntake.ageLabel}</span>
            <SelectWrapperWrapper>
              <SelectWrapper>
                <Select
                  name="age"
                  defaultValue={cafeteriaCaloricIntake.age}
                  onChange={(e) => inputChangeHandler(e)}
                >
                  {React.Children.toArray(
                    ageArray.map((v) => <option value={v}>{v}</option>)
                  )}
                </Select>
                <WrapperImg>
                  <BottomIcon></BottomIcon>
                </WrapperImg>
              </SelectWrapper>
            </SelectWrapperWrapper>
          </Label>
          <Label className="width-100">
            <span>{cafeteriaCaloricIntake.physicalLabel}</span>
            <SelectWrapperWrapper>
              <SelectWrapper>
                <Select
                  defaultValue={cafeteriaCaloricIntake.activity}
                  name="activity"
                  onChange={(e) => inputChangeHandler(e)}
                >
                  {React.Children.toArray(
                    Activity.map((v) => <option value={v.val}>{v.name}</option>)
                  )}
                </Select>
                <WrapperImg>
                  <BottomIcon></BottomIcon>
                </WrapperImg>
              </SelectWrapper>
            </SelectWrapperWrapper>
          </Label>
        </InnerForm>
        <Button>{cafeteriaCaloricIntake.saveButtonLabel}</Button>
      </Form>
      <TrayWrapper>
        <TrayTitle>{cafeteriaCaloricIntake.trayTitle}</TrayTitle>
        {!trayItems.length ? (
          <TrayEmpty>{cafeteriaCaloricIntake.trayEmptyLabel}</TrayEmpty>
        ) : (
          <>
            <List>
              {trayItems.map((dish, key) => (
                <CaloricIntakeRecap key={key} dish={dish} />
              ))}
            </List>
            <List>
              <ListItem>
                <span className="desktop-body">Totale</span>
                <Span className="desktop-body">{totalKcal} kcal</Span>
              </ListItem>
              <ListItem>
                <span className="desktop-body">Fabbisogno consigliato</span>
                <Span className="desktop-body">{caloricIntakeLbl}</Span>
              </ListItem>
            </List>
          </>
        )}
      </TrayWrapper>
      <Disclaimer
        dangerouslySetInnerHTML={{ __html: cafeteriaCaloricIntake.disclaimer }}
      ></Disclaimer>
    </Wrapper>
  );
}
CaloricIntake.propTypes = {
  cafeteriaCaloricIntake: PropTypes.shape({
    title: PropTypes.string,
    myDataLabel: PropTypes.string,
    genderLabel: PropTypes.string,
    weightLabel: PropTypes.string,
    heightLabel: PropTypes.string,
    ageLabel: PropTypes.string,
    physicalLabel: PropTypes.string,
    saveButtonLabel: PropTypes.string,
    trayTitle: PropTypes.string,
    trayEmptyLabel: PropTypes.string,
    disclaimer: PropTypes.string,
  }),
};

export default CaloricIntake;
