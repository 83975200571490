import styled from "styled-components";

export const Slot = styled.div`
  position: relative;
  &::before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  h3 {
    color: rgb(255, 255, 255);
    font-family: ferrari-sans-medium;
    max-width: 384px;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 24px;
    text-align: left;
  }
  img {
    display: block;
  }
`;

export const FocusText = styled.div`
  position: absolute;
  bottom: 0px;
  left: 32px;
`;

export const Description = styled.div`
  color: rgb(255, 255, 255);
  display: flex;
  margin: 0 0 24px;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  span:nth-child(2) {
    height: 0px;
    border-bottom: 1px solid rgb(255, 255, 255);
    width: 96px;
    margin: 0px 8px;
  }
`;
