import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { FETCH_COMPONENTS } from "./constants";
import { topRatedDishesAction } from "./topRatedDishesSlice";

export function* fetchComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(topRatedDishesAction.setComponents(result.data.data));

    const topRatedDishes = result.data.data.components.find(
      (element) => element.resourceType === "fi-cafeteria-favourite-dishes"
    );
    yield put(topRatedDishesAction.setDishes(topRatedDishes.data.dishes));
  } catch (e) {
    console.error(e);
    yield put(topRatedDishesAction.setError(e));
  }
}

export default function* topRatedDishesSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponents);
}
