import PropTypes from "prop-types";
import React from "react";
// style
import { Container } from "./style";

import Offer from "./offer/Offer";

function LeftColumnContainer({ leftColumnComponents }) {
  const componentsMapping = {
    "fi-ads/fi-ads-item-placeholder": Offer,
  };

  return (
    <Container>
      {React.Children.toArray(
        leftColumnComponents?.map((element) => {
          const ReactGenericComponent = componentsMapping[element.resourceType];
          return (
            ReactGenericComponent && (
              <ReactGenericComponent
                data={element.data}
              ></ReactGenericComponent>
            )
          );
        })
      )}
    </Container>
  );
}

LeftColumnContainer.propTypes = {
  leftColumnComponents: PropTypes.arrayOf(PropTypes.object),
};

export default LeftColumnContainer;
