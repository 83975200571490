import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  takeAwayComponents: {},
  baskets: [],
  offer: undefined,
  book: undefined,
  showDialog: false,
  status: null,
  errorMessage: "",
};

const takeAwaySlice = createSlice({
  name: "takeAway",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.takeAwayComponents = action.payload;
    },
    setBaskets(state, action) {
      const baskets = action.payload.map((v) => {
        return { ...v, isAdded: false };
      });
      state.baskets = baskets;
    },
    setOffer(state, action) {
      state.offer = action.payload;
    },
    showDialog(state, action) {
      state.showDialog = action.payload;
    },
    setOfferDate(state, action) {
      state.offer.date = action.payload;
    },
    setBook(state, action) {
      state.book = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const takeAwayActions = takeAwaySlice.actions;
export default {
  name: takeAwaySlice.name,
  reducer: takeAwaySlice.reducer,
};
