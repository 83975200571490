import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// components
import LayoutThankYou from "../../components/UI/thankYou/LayoutThankYou";
import Image from "../../components/UI/thankYou/image/Image";
import Title from "../../components/UI/thankYou/title/Title";
import Text from "../../components/UI/thankYou/text/Text";
import Button from "../../components/UI/thankYou/button/Button";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function AccessReduced({ lang }) {
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(coreComponentsActions.setHideNavAndFooter(true));
  }, [dispatch]);

  const imageData = {
    imageFromPageImage: "false",
    fileReference: "/img/brand-portal-tool.svg",
    position: "left",
  };
  const titleData =
    lang === "it"
      ? { jcrTitle: "Grazie!" }
      : {
          jcrTitle: "Thank you!",
        };
  const textData =
    lang === "it"
      ? {
          text: "<p>Abbiamo ricevuto la tua richiesta.</p>\r\nEntro pochi minuti potrai scoprire NoiFerrari, la nostra nuova Intranet.</p>\r\n",
        }
      : {
          text: "<p>We received your request.</p>\r\nYou will be able to discover the new Intranet NoiFerrari in a few minutes.</p>\r\n",
        };
  const buttonData = {
    title: "LOGOUT",
  };
  // ChangeTitleDoc(
  //   subjectCode,
  //   aggregatedDirection,
  //   "Access Reduced",
  //   "/reduced-access"
  // );
  return (
    <LayoutThankYou>
      <Image data={imageData} />
      <Title data={titleData} />
      <Text data={textData} />
      <Button data={buttonData} />
    </LayoutThankYou>
  );
}

export default AccessReduced;
