import { Container, ScrollButtons } from "./style";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { coreComponentsActions } from "../../../utils/getCoreComponents/coreComponentsSlice";
import WelfareOnScroll from "./WelfareOnScroll";
import GoToTop from "../../assets/goToTop";
import ShowFooterBlack from "../../assets/showFooterBlack";

function LayoutWelfare(props) {
  const [isOnTop, setIsOnTop] = useState(true);
  const { isFooterFixedOpened } = useSelector((state) => state.coreComponents);
  const dispatch = useDispatch();
  return (
    <>
      <Container>{props.children}</Container>
      <ScrollButtons>
        <div
          style={isOnTop ? { display: "none" } : {}}
          onClick={() => window.scrollTo(0, 0)}
        >
          <GoToTop></GoToTop>
        </div>
        <div
          style={{ transform: isFooterFixedOpened ? "rotate(45deg)" : "none" }}
          onClick={() =>
            dispatch(coreComponentsActions.toggleIsFooterFixedOpened())
          }
        >
          <ShowFooterBlack></ShowFooterBlack>
        </div>
      </ScrollButtons>
      <WelfareOnScroll
        isOnTop={isOnTop}
        setIsOnTop={setIsOnTop}
      ></WelfareOnScroll>
    </>
  );
}

export default LayoutWelfare;
