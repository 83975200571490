import { Container } from "./style";

function LayoutWebinar(props) {
  return (
    <>
      <Container>{props.children}</Container>
    </>
  );
}

export default LayoutWebinar;
