import { createSlice } from "@reduxjs/toolkit";
import { ADS_CATALOG, MY_ADS_CATALOG } from "./constants";

export const ADS_LIST_SCOPE = "AdsList";

export const initialState = {
  components: [],
  catalog: {},
  settings: {},
  tabs: {
    offerTab: "",
    lookingTab: "",
    selected: "",
  },
  filters: {
    filter: "",
    sort: "",
    filterType: "",
    filterSelect: [],
    sortSelect: [],
  },
  cards: {
    tabsList: {},
    list: [],
    selectedList: [],
    deleteLabel: "",
    modifyLabel: "",
  },
  openModal: false,
  status: null,
  errorMessage: "",
};

const AdsListSlice = createSlice({
  name: ADS_LIST_SCOPE,
  initialState,
  reducers: {
    setData(state, action) {
      state.status = 200;
      state.components = action.payload.components;
      const myCatalog = action.payload.components.find(
        (component) =>
          component.resourceType === ADS_CATALOG ||
          component.resourceType === MY_ADS_CATALOG
      );
      state.catalog = myCatalog?.data;
      state.settings = action.payload.settings;
    },
    setTabsState(state) {
      state.tabs = {
        offerTab: state.catalog?.properties?.lookingForLabel,
        lookingTab: state.catalog?.properties?.offerLabel,
        selected: state.catalog?.properties?.lookingForLabel,
      };
    },
    setCardsState(state) {
      const adsProperties = state.catalog?.properties ?? {};
      const adsCards = state.catalog.items ?? null;
      state.cards.deleteLabel = adsProperties.deleteLabel;
      state.cards.modifyLabel = adsProperties.modifyLabel;
      if (!adsProperties.offerType) {
        state.cards.list = [...adsCards];
        state.cards.selectedList = [...adsCards];
      } else {
        state.cards.tabsList[state.tabs.lookingTab] = [
          ...Object.values(adsCards)[0],
        ];
        state.cards.tabsList[state.tabs.offerTab] = [
          ...Object.values(adsCards)[1],
        ];
        const defaultCards = [...state.cards.tabsList[state.tabs.offerTab]];
        state.cards.list = [...defaultCards];
        state.cards.selectedList = [...defaultCards];
      }
    },
    setFiltersState(state) {
      const adsProperties = state.catalog?.properties ?? {};
      const defaultFilter = {
        key: "",
        label: adsProperties.categoryPlaceholder,
      };
      const defaultSort = { key: "", label: adsProperties.orderByPlaceholder };
      state.filters.filterSelect = [defaultFilter];
      state.filters.sortSelect = [defaultSort, ...adsProperties.order];
      switch (adsProperties.offerType) {
        case "home":
          state.filters.filterSelect.push(...adsProperties.types);
          state.filters.filterType = "offerType";
          break;
        case "foundObject":
          state.filters.filterSelect = [];
          state.filters.filterType = "";
          break;
        case "object":
          state.filters.filterSelect.push(...adsProperties.categoriesObject);
          state.filters.filterType = "offerType";
          break;
        default:
          state.filters.filterSelect.push(...adsProperties.categories);
          state.filters.filterType = "category";
          break;
      }
    },
    setTab(state, action) {
      state.tabs.selected = action.payload;
      state.cards.list = state.cards.tabsList[action.payload];
      state.cards.selectedList = state.cards.tabsList[action.payload];
      state.filters.filter = initialState.filters.filter;
      state.filters.sort = initialState.filters.sort;
    },
    changeFilter(state, action) {
      const filter = action.payload;
      state.filters.filter = filter;
      if (filter?.length) {
        state.cards.selectedList = state.cards.list.filter(
          (card) =>
            card[state.filters.filterType] &&
            card[state.filters.filterType].toLowerCase() ===
              filter.toLowerCase()
        );
      } else {
        state.cards.selectedList = state.cards.list;
      }
    },
    changeSort(state, action) {
      const sortKey = action.payload;
      state.filters.sort = sortKey;
      if (!sortKey?.length) {
        state.cards.selectedList = state.cards.list;
      } else {
        state.cards.selectedList.sort((a, b) => {
          switch (sortKey) {
            case "views":
              return a.counter > b.counter ? -1 : 1;
            case "date":
              return a.creationTimestamp > b.creationTimestamp ? -1 : 1;
            case "decreasingDate":
              return a.creationTimestamp < b.creationTimestamp ? -1 : 1;
            case "price":
              return (a.price ?? 0) > (b.price ?? 0) ? -1 : 1;
            case "decreasingPrice":
              return (a.price ?? 0) < (b.price ?? 0) ? -1 : 1;
            default:
              return 1;
          }
        });
      }
    },
    resetFiltersAndCards(state) {
      state.filters.filter = initialState.filters.filter;
      state.filters.sort = initialState.filters.sort;
      state.cards.selectedList = [...state.cards.list];
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    deleteAd(state, action) {
      const filtered = state.cards.list.filter(
        (card) => card.id !== action.payload
      );
      state.cards.list = [...filtered];
      state.cards.selectedList = [...filtered];
    },
    setStatusNull(state) {
      state.status = null;
    },
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
    fetchComponents: () => {},
  },
});

export const {
  setData,
  setTabsState,
  setCardsState,
  setFiltersState,
  setError,
  setStatusNull,
  fetchComponents,
  setTab,
  changeFilter,
  changeSort,
  resetFiltersAndCards,
  deleteAd,
} = AdsListSlice.actions;
export const adsListActions = AdsListSlice.actions;
export default AdsListSlice;
