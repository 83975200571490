import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./rubricaSlice";

const rubricaState = (state) => state.rubrica || initialState;

export const selectComponents = () =>
  createSelector(rubricaState, (state) => state.components);

export const selectTabIndex = () =>
  createSelector(rubricaState, (state) => state.tabIndex);

export const selectTabComponentsById = (tabIndex) =>
  createSelector(rubricaState, (state) => {
    const tabsComp = state.tabsArray.filter((tab) => tab.id === tabIndex);
    return tabsComp[0].components;
  });

export const selectTabTitlesFromTabsArray = () =>
  createSelector(rubricaState, (state) => {
    const tabsTitles = state.tabsArray.map((tabs) => {
      return { id: tabs.id, title: tabs.components.title };
    });
    return tabsTitles;
  });

export const getRubricaTitle = () =>
  createSelector(rubricaState, (state) => {
    const titleComponent =
      state.components.find((v) => v.resourceType === "fi-title") || "";
    return titleComponent;
  });

export const getRubricaSearchResultTitle = () =>
  createSelector(rubricaState, (state) => {
    const searchResultComp =
      state.components.find(
        (v) => v.resourceType === "fi-address-book/fi-address-book-search"
      ) || "";
    return searchResultComp;
  });

export const getSearchComponent = () =>
  createSelector(rubricaState, (state) => {
    const searchComponent =
      state.components.find(
        (v) => v.resourceType === "fi-address-book/fi-address-book-search"
      ) || "";
    return searchComponent;
  });

export const getAdvancedSearchLabels = () =>
  createSelector(rubricaState, (state) => {
    const searchComponent =
      state.components.find(
        (v) => v.resourceType === "fi-address-book/fi-address-book-search"
      ) || "";
    return searchComponent?.data?.advancedSearch;
  });

export const getCostCenters = () =>
  createSelector(rubricaState, (state) => {
    const searchComponent =
      state.components.find(
        (v) => v.resourceType === "fi-address-book/fi-address-book-search"
      ) || "";
    return searchComponent?.data?.costCenters;
  });

export const getDepartments = () =>
  createSelector(rubricaState, (state) => {
    const searchComponent =
      state.components.find(
        (v) => v.resourceType === "fi-address-book/fi-address-book-search"
      ) || "";
    return searchComponent?.data?.departments;
  });

export const getContactLabels = () =>
  createSelector(rubricaState, (state) => {
    const tabs = state.components.find(
      (v) => v.resourceType === "fi-address-book/fi-address-book-tabs"
    );
    return tabs?.data.favorites.contactCard || {};
  });

export const getUsefulMaranelloContactLabels = () =>
  createSelector(rubricaState, (state) => {
    const tabs = state.components.find(
      (v) => v.resourceType === "fi-address-book/fi-address-book-tabs"
    );
    return {
      internalNumberLabel: tabs?.data.usefulMaranello.internalNumberLabel,
      externalNumberLabel: tabs?.data.usefulMaranello.externalNumberLabel,
    };
  });

export const getUsefulScagliettiContactLabels = () =>
  createSelector(rubricaState, (state) => {
    const tabs = state.components.find(
      (v) => v.resourceType === "fi-address-book/fi-address-book-tabs"
    );
    return {
      internalNumberLabel: tabs?.data.usefulScaglietti.internalNumberLabel,
      externalNumberLabel: tabs?.data.usefulScaglietti.externalNumberLabel,
    };
  });

export const getUsefulContactLabels = () =>
  createSelector(rubricaState, (state) => {
    const tabs = state.components.find(
      (v) => v.resourceType === "fi-address-book/fi-address-book-tabs"
    );
    return {
      internalNumberLabel: tabs?.data.useful.internalNumberLabel,
      externalNumberLabel: tabs?.data.useful.externalNumberLabel,
    };
  });
export const getEmptyFavLabels = () =>
  createSelector(rubricaState, (state) => {
    const tabs = state.components.find(
      (v) => v.resourceType === "fi-address-book/fi-address-book-tabs"
    );
    const result = {
      addFavoriteLabel: tabs?.data.favorites.addFavoriteLabel,
      addFavoriteDescription: tabs?.data.favorites.addFavoriteDescription,
    };
    return result || {};
  });
