import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/notifications/TriggerNotification";
import {
  FETCH_DISHES,
  FETCH_COMPONENTS,
  FETCH_DISH_TO_TRAY,
  FETCH_SET_FAVORITE,
  SET_USER_DATA,
  FETCH_CALORIC_INTAKE,
} from "./constants";
import { mensaActions } from "./mensaSlice";

function* fetchMensaComponents({ payload }) {
  try {
    const { path, token } = payload;
    // now the endpoint adds the date of the current day, so if there are no plates on AEM nothing appears
    // the only date with plates is 2022/11/7
    const result = yield axiosCallGet(path, token);
    yield put(mensaActions.setComponents(result.data.data));

    const dishesOfTheDay = result.data.data.components.find(
      (v) => v.resourceType === "fi-cafeteria-dishes"
    );
    yield put(mensaActions.setDishes(dishesOfTheDay.data.dishes));
  } catch (e) {
    console.error(e);
    yield put(mensaActions.setError(e));
  }
}

function* fetchDishes() {
  try {
    const result = yield call(() => axios.get("/mocks/mock-dishes.json"));
    // add an unique id to dishes
    result.data.forEach((v, i) => (v.id = i));
    yield put(mensaActions.setDishes(result.data));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchDishToTray(dish) {
  try {
    const result = yield call(() => axios.get("/mocks/mock-ok.json"));
    if (result.data.message !== "ok") new Error("error from fetchDishesToTray");
    yield put(mensaActions.toggleDish(dish.payload));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchSetFavourite({ payload }) {
  try {
    const body = { id: payload.dish.id, restaurant: payload.restaurant };
    try {
      yield axiosCallPost("/piatti_like", body, payload.token, true, false);
      yield put(mensaActions.setFavoriteDish(payload.dish));
      showSuccessMessage("Preferito salvato");
    } catch (e) {
      showErrorMessage("Errore nel salvataggio del preferito");
    }
  } catch (e) {
    console.error(e);
  }
}

export function* SetUserData({ payload }) {
  try {
    const token = payload.token;
    document.body.classList.add("loading");
    yield axiosCallPost("/userdata", payload.responseBody, token, true);
    yield fetchCaloricIntake({ payload: token });
    showSuccessMessage("Data saved");
  } catch (e) {
    showErrorMessage("Errore nel salvataggio dei dati");
  } finally {
    document.body.classList.remove("loading");
  }
}

export function* fetchCaloricIntake({ payload }) {
  try {
    const url = "/userdata";
    const result = yield axiosCallGet(url, payload, true);
    yield put(mensaActions.fetchCaloircIntake(result?.data?.docs[0]));
  } catch (e) {
    console.error(e);
  }
}

export default function* mensaSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchMensaComponents);
  yield takeLatest(FETCH_DISHES, fetchDishes);
  yield takeLatest(FETCH_DISH_TO_TRAY, fetchDishToTray);
  yield takeLatest(FETCH_SET_FAVORITE, fetchSetFavourite);
  yield takeLatest(SET_USER_DATA, SetUserData);
  yield takeLatest(FETCH_CALORIC_INTAKE, fetchCaloricIntake);
}
