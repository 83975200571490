import styled from "styled-components";

export const Container = styled.div`
  margin-top: 48px;
  margin-bottom: 76px;
`;

export const Image = styled.img`
  width: 90%;
  height: auto;
`;
