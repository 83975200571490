import styled from "styled-components";

export const Wrapper = styled.section`
  text-align: left;
  @media screen and (min-width: 1280px) {
    width: 800px;
    text-align: center;
    margin: auto;
  }
`;
export const StrumentiH1 = styled.h1`
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  margin: 96px 0px 64px;

  @media screen and (max-width: 1279px) {
    margin: 48px auto 32px;
    font-size: 24px;
    text-align: left;
  }
`;

export const Description = styled.div`
  font-size: 13px;
  line-height: 20px;
`;
