import { createSelector } from "@reduxjs/toolkit";
import { ADS_LIST_SCOPE, initialState } from "./AdsListSlice";
import { ADS_BUTTONS } from "./constants";

const adsListState = (state) => state[ADS_LIST_SCOPE] || initialState;

export const selectComponents = () =>
  createSelector(adsListState, (state) => state.components);

export const selectSettings = () =>
  createSelector(adsListState, (state) => state.settings);

export const selectHeader = () =>
  createSelector(adsListState, (state) => state.header);

export const selectFiltersState = () =>
  createSelector(adsListState, (state) => state.filters);

export const selectCardsState = () =>
  createSelector(adsListState, (state) => state.cards);

export const selectedCardsTotal = () =>
  createSelector(
    adsListState,
    (state) => state.cards.selectedList?.length ?? 0
  );

export const initialCardsTotal = () =>
  createSelector(adsListState, (state) => state.cards.list?.length ?? 0);

export const selectTabsState = () =>
  createSelector(adsListState, (state) => state.tabs);

export const selectAdsButtons = () =>
  createSelector(
    adsListState,
    (state) =>
      state.components.find(
        (component) => component.resourceType === ADS_BUTTONS
      )?.data
  );

export const selectCatalog = () =>
  createSelector(adsListState, (state) => state.catalog);

export const selectCatalogTitle = () =>
  createSelector(
    adsListState,
    (state) => state.catalog?.properties?.title ?? ""
  );

export const selectCatalogOfferType = () =>
  createSelector(
    adsListState,
    (state) => state.catalog?.properties?.offerType ?? ""
  );

export const selectCatalogCategoryLabel = (key) =>
  createSelector(
    adsListState,
    (state) =>
      state.catalog?.properties?.categories.find(
        (category) => category.key === key
      )?.label ?? key
  );

export const selectCatalogOfferTypeLabel = (key) =>
  createSelector(
    adsListState,
    (state) =>
      state.catalog?.properties?.types.find((type) => type.key === key)
        ?.label ?? key
  );

export const selectCatalogNoAds = () =>
  createSelector(
    adsListState,
    (state) => state.catalog?.properties?.noAds ?? {}
  );

export const selectCatalogCancelModal = () =>
  createSelector(
    adsListState,
    (state) => state.catalog?.properties?.cancelModal ?? {}
  );
