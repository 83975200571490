import { takeLatest, put, call } from "redux-saga/effects";
import {
  axiosCallGet,
  axiosCallPost,
  axiosCallDelete,
} from "../../utils/axiosCalls/AxiosCalls";

import {
  setShowSuccessModal,
  setComponents,
  setDisplayError,
  setError,
  setInitBodyPayload,
  setRequestStatus,
} from "./FormTestHmiSlice";
import {
  DELETE_SUBSCRIPTION,
  RELOAD_COMPONENTS,
  FETCH_COMPONENTS,
  SET_USER_INFORMATION_TEST_HMI,
} from "./constants";
import { showErrorMessage } from "../../utils/notifications/TriggerNotification";
// import axios from "axios";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-form-test-hmi.json")
    // );
    yield put(setComponents(result.data.data));
    yield put(
      setInitBodyPayload(
        result?.data?.data?.components?.find(
          (el) => el.resourceType === "fi-form-hmi"
        )?.data?.formValues
      )
    );
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

function* reloadComponents({ payload }) {
  try {
    document.body.classList.add("loading");
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(setComponents(result.data.data));
    yield put(
      setInitBodyPayload(
        result?.data?.data?.components?.find(
          (el) => el.resourceType === "fi-form-hmi"
        )?.data?.formValues
      )
    );
  } catch (e) {
    console.error(e);
    const messageError =
      payload.lang === "it" ? "Opperazione fallita" : "Operation failed";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}

function* postBody({ payload }) {
  try {
    document.body.classList.add("loading");
    yield axiosCallPost(
      `/${payload.lang}/tools/adesioni-hmi`,
      payload.formattedBody,
      payload.token
    );
    yield put(setShowSuccessModal(true));
    yield put(setRequestStatus(1));
    yield put(setDisplayError(true));
  } catch (e) {
    const messageError =
      payload.lang === "it" ? "Opperazione fallita" : "Operation failed";
    showErrorMessage(messageError);
  } finally {
    document.body.classList.remove("loading");
  }
}
function* deleteSubscription({ payload }) {
  try {
    yield axiosCallDelete(
      `/${payload.lang}/tools/adesioni-hmi/${payload?.id}`,
      payload.token
    );
    yield put(setRequestStatus(0));
    yield put(setInitBodyPayload(null));
  } catch (e) {
    const messageError =
      payload.lang === "it" ? "Opperazione fallita" : "Operation failed";
    showErrorMessage(messageError);
    console.log(e);
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(RELOAD_COMPONENTS, reloadComponents);
  yield takeLatest(SET_USER_INFORMATION_TEST_HMI, postBody);
  yield takeLatest(DELETE_SUBSCRIPTION, deleteSubscription);
}
