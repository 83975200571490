import React from "react";
import { RadioButton, TextDescr } from "./style";

export default function InputRadioButton({
  name,
  onChange,
  description,
  array,
  defaultValue,
  checked,
  noOne,
  disabled,
}) {
  return (
    <>
      {description && <TextDescr>{description}</TextDescr>}
      <div className="fi-checkbox">
        {React.Children.toArray(
          array?.map((el, i) => {
            return (
              <RadioButton>
                <input
                  type="radio"
                  defaultValue={defaultValue ? el : el.value}
                  checked={defaultValue ? checked === i : checked === el.value}
                  id={defaultValue ? i : el?.id}
                  name={name}
                  disabled={disabled}
                  onChange={onChange}
                />
                <label htmlFor={defaultValue ? i : el?.id}>
                  {!defaultValue ? el?.text : i === 0 ? noOne : i}
                </label>
              </RadioButton>
            );
          })
        )}
      </div>
    </>
  );
}
