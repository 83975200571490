import { RedirectButton, TitleForm, ContainerForbidden } from "./style";
import { useBusinessLogic } from "./useBusinessLogic";
import { useNavigate } from "react-router-dom";

export function CannotModifyDialog({ data }) {
  const { generateHtml } = useBusinessLogic();
  const navigate = useNavigate();
  return (
    <ContainerForbidden center>
      <TitleForm>
        {data?.cannotEditDialog?.title}
        <div>
          <p
            dangerouslySetInnerHTML={generateHtml(
              data?.cannotEditDialog?.description
            )}
          />
        </div>
      </TitleForm>
      <RedirectButton
        onClick={() => navigate(`${data?.cannotEditDialog?.linkHref}`)}
      >
        {data?.cannotEditDialog?.buttonLabel}
      </RedirectButton>
    </ContainerForbidden>
  );
}
