import {
  Container,
  InputSection,
  SendButton,
  DropDownTematiche,
  List,
  TematicheLabel,
} from "./style";
import React, { useRef, useState } from "react";
import CustomSelect from "../customSelect/CustomSelect";
import { Text } from "../news/newsDetailContainer/newsText/style";
import { ImgHeader } from "../strumenti/style";
import {
  setShowDialog,
  setShowDialogKO,
} from "../../../pages/ProgrammiCoinvolgimento/ProgrammaCoinvolgimentoSlice";
import { useDispatch, useSelector } from "react-redux";
import BasicConfirmDialog from "../dialog/basicConfirmDialog/BasicConfirmDialog";
import { axiosCallPost } from "../../../utils/axiosCalls/AxiosCalls";
import { useEffect } from "react";

function LayoutProgrammaCoinvolgimento({ data, heroImg }) {
  const campaignInitValue =
    data?.campaign?.items?.length > 1 ? "" : data?.campaign?.items?.[0]?.label;

  const dispatch = useDispatch();
  function html(text) {
    return { __html: text };
  }
  const [enableButton, setEnableButton] = useState(false);
  const [thematics, setThematics] = useState([]);
  const [campaign, setCampaign] = useState(campaignInitValue);
  const [oneFerrariPrinciple, setOneFerrariPrinciple] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef(null);
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const { showDialog, showDialogKO } = useSelector(
    (state) => state.ProgrammaCoinvolgimento
  );
  const { pageLight } = useSelector((state) => state.coreComponents);
  function isFormAble() {
    const formData = new FormData(formRef.current);
    const everyFieldFilled = [...formData.entries()].every((v) => v[1]);
    const isCampaignFilled = formRef.current["campaign"].value;
    const isFerrariPrincipleFilled =
      formRef.current["oneFerrariPrinciple"].value;
    const isThematicFilled = IsThematicFilled();
    setEnableButton(
      everyFieldFilled &&
        isCampaignFilled &&
        isThematicFilled &&
        isFerrariPrincipleFilled
    );
  }

  const IsThematicFilled = () => {
    let result = false;
    let ul = document.getElementById("thematics");
    let items = ul.getElementsByTagName("li");
    setThematics([]);
    for (var i = 0; i < items.length; ++i) {
      if (items[i].children[0].checked) {
        const newItem = items[i].textContent;
        setThematics((prevArray) => [...prevArray, newItem]);
        result = true;
      }
    }

    return result;
  };

  const ClearThematics = () => {
    let items = document.getElementById("thematics").getElementsByTagName("li");
    setThematics([]);
    for (var i = 0; i < items.length; ++i) {
      items[i].children[0].checked = false;
    }
    setIsOpen(false);
  };

  async function submitForm(e) {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    let formJson = {};
    formData.forEach((value, key) => {
      formJson[key] = value;
    });
    try {
      const body = { ...formJson, thematics };
      document.body.classList.add("loading");
      await axiosCallPost(
        "/suggestions-pole-position",
        body,
        token,
        true,
        false
      );

      formRef.current.reset();
      dispatch(setShowDialog(true));
      setEnableButton(false);
      setCampaign(campaignInitValue);
      setOneFerrariPrinciple("");
      ClearThematics();
    } catch (e) {
      dispatch(setShowDialogKO(true));
    } finally {
      isFormAble();
      document.body.classList.remove("loading");
    }
  }

  const selectedLabel = lang === "it" ? "selezionati " : "selected ";
  const HandleDropDownTematiche = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };
  useEffect(() => {}, [isOpen, thematics]);

  return (
    <>
      <ImgHeader src={heroImg} />
      <Container>
        <h1>{data?.title}</h1>
        <Text dangerouslySetInnerHTML={html(data.richText)}></Text>
        <form ref={formRef} onSubmit={submitForm}>
          <DropDownTematiche
            tema={pageLight}
            id="list1"
            color={(isOpen || (!isOpen && thematics?.length > 0)).toString()}
            onChange={(e) => {
              isFormAble();
            }}
          >
            <TematicheLabel
              id="tematicheLbl"
              isShown={!isOpen && thematics?.length > 0}
            >
              {data.topic.placeholder}
            </TematicheLabel>
            <span
              id="thematicsPlaceHolder"
              className="anchor"
              onClick={HandleDropDownTematiche}
            >
              {!isOpen && thematics?.length > 0
                ? selectedLabel + thematics?.length
                : data?.topic?.placeholder}
            </span>

            <List id="thematics" isOpen={isOpen}>
              {React.Children.toArray(
                data?.topic?.items.map((v, i) => (
                  <li>
                    <input
                      className="myinput"
                      type="checkbox"
                      key={i}
                      value={v.key}
                    />
                    <span>{v?.label}</span>
                  </li>
                ))
              )}
            </List>
          </DropDownTematiche>
          <CustomSelect
            onChange={() => {
              isFormAble();
            }}
            hideLabel={true}
            name="campaign"
            setValue={setCampaign}
            key={"programma-annunci-campaign"}
            defaultValue={campaign}
            placeholderString={data?.campaign?.placeholder}
          >
            {data?.campaign?.items?.map((v, i) => (
              <option key={i} value={v.key}>
                {v?.label}
              </option>
            ))}
          </CustomSelect>
          <CustomSelect
            onChange={() => {
              isFormAble();
            }}
            hideLabel={true}
            name="oneFerrariPrinciple"
            setValue={setOneFerrariPrinciple}
            key={"programma-annunci-oneFerrariPrinciple"}
            defaultValue={oneFerrariPrinciple}
            placeholderString={data?.oneFerrariPrinciple?.placeholder}
          >
            {data?.oneFerrariPrinciple?.items?.map((v, i) => (
              <option key={i} value={v.key}>
                {v.label}
              </option>
            ))}
          </CustomSelect>

          <InputSection hideLabel={true}>
            <input
              type="text"
              name="topic"
              onChange={(e) => {
                isFormAble();
              }}
              placeholder={data?.topicLabel}
              maxLength="255"
            />
            <label>{data?.topicLabel}</label>
          </InputSection>
          <textarea
            placeholder={data?.currentSituationPlaceholder}
            rows={10}
            name="asIs"
            onChange={(e) => {
              isFormAble();
            }}
            maxLength="2000"
          ></textarea>
          <textarea
            placeholder={data?.suggestionPlaceholder}
            rows={10}
            name="toBe"
            onChange={(e) => {
              isFormAble();
            }}
            maxLength="2000"
          ></textarea>
          <SendButton disabled={!enableButton}>{data?.buttonLabel}</SendButton>
        </form>
      </Container>
      <BasicConfirmDialog
        showDialog={showDialog}
        title={data?.modal?.title}
        text={data?.modal?.description}
        closeButtonText={data?.modal?.buttonLabel}
        closeFunction={() => {
          dispatch(setShowDialog(false));
        }}
      ></BasicConfirmDialog>
      <BasicConfirmDialog
        showDialog={showDialogKO}
        title={data?.modalKO?.title}
        text={data?.modalKO?.description}
        closeButtonText={data?.modalKO?.buttonLabel}
        closeFunction={() => {
          dispatch(setShowDialogKO(false));
        }}
      ></BasicConfirmDialog>
    </>
  );
}

export default LayoutProgrammaCoinvolgimento;
