import styled from "styled-components";

export const Wrapper = styled.div`
  width: 800px;
  margin: 0px auto;
  text-align: center;
  @media (max-width: 1279px) {
    width: auto;
  }
`;
