import React from "react";
import {
  DetailContainerData,
  DetailDataAddress,
  DetailDataLabel,
} from "./style";

export function DetailInfo({ data }) {
  return (
    <>
      <h3>{data?.detailsTitle}</h3>
      <DetailContainerData>
        <DetailDataLabel>{data?.nameLabel}</DetailDataLabel>
        <p>{data?.name}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.lastNameLabel}</DetailDataLabel>
        <p>{data?.lastName}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.departmentLabel}</DetailDataLabel>
        <p>{data?.company}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.roleLabel}</DetailDataLabel>
        <p>{data?.depAndJobPos}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.companyAddressLabel}</DetailDataLabel>
        <DetailDataAddress>{data?.corporateAddress}</DetailDataAddress>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.emailLabel}</DetailDataLabel>
        <p>{data?.email}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.mobilePhoneLabel}</DetailDataLabel>
        <p>{data?.mobilePhone}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.phoneLabel}</DetailDataLabel>
        <p>{data?.phone}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.faxLabel}</DetailDataLabel>
        <p>{data?.fax}</p>
      </DetailContainerData>
    </>
  );
}

export default React.memo(DetailInfo);
