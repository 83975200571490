import styled from "styled-components";

export const Loader = styled.div`
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:black;
  display:flex;
  align-items: center;
  justify-content: center;
  z-index:9;
  img{
    width:68px;
    animation-name:pulse;
    animation-duration:1s;
    animation-iteration-count: infinite;
  }
  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05,1.05,1.05);
    }
    100% {
      transform: scaleX(1);
    }
  }
`;

