import React from "react";
import { DetailContainerData, DetailDataLabel, TitleDetail } from "./style";
import { useSelector } from "react-redux";
import { QrCode } from "../qrCode/QrCode";
import { GenericTitle } from "../style";

export function DetailInfoGiftHouse({ data }) {
  const lang = useSelector((state) => state.coreComponents.language);
  const typeOfEvent = (fieldValue) => {
    let text;
    switch (fieldValue) {
      case "employeeAndFamily":
        text = data?.withFamilyLabel;
        break;
      case "afterLigthExperience":
        text = data?.afterLeLabel;
        break;
      case "shipmentMilano":
      case "shipmentModena":
        text = data?.shipmentLabel;
        break;
      default:
        text = data?.employeeOnlyLabel;
        break;
    }
    return text;
  };
  const getSubscriptionTime = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result;
  };

  const getSubscriptionDay = (startDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
    });
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  function generateHtml(argument) {
    return { __html: argument };
  }

  const isQrCodeVisible = (fieldValue) => {
    return fieldValue === "employeeAndFamily" ||
      fieldValue === "employeeOnly" ||
      fieldValue === "afterLigthExperience"
      ? true
      : false;
  };

  const isDataAndLocationVisible = (fieldValue) => {
    return fieldValue === "employeeAndFamily" || fieldValue === "employeeOnly"
      ? true
      : false;
  };

  const showQrCode = isQrCodeVisible(
    data?.appointment?.configurations?.[1]?.fieldValue
  );

  const showDateAndLocation = isDataAndLocationVisible(
    data?.appointment?.configurations?.[1]?.fieldValue
  );
  return (
    <>
      <TitleDetail>{data?.title}</TitleDetail>
      {showQrCode && (
        <GenericTitle>
          <QrCode data={data?.qrCode} />
          <p dangerouslySetInnerHTML={generateHtml(`${data?.richText}`)} />
        </GenericTitle>
      )}
      <DetailContainerData>
        <DetailDataLabel>{data?.typeLabel}</DetailDataLabel>
        <p>{typeOfEvent(data?.appointment?.configurations?.[1]?.fieldValue)}</p>
      </DetailContainerData>
      {showDateAndLocation && (
        <>
          <DetailContainerData>
            <DetailDataLabel>{data?.dateLabel}</DetailDataLabel>
            <p>
              {getSubscriptionDay(
                data?.appointment?.appointmentStartDate,
                lang
              )}
            </p>
          </DetailContainerData>
          <DetailContainerData>
            <DetailDataLabel>{data?.slotLabel}</DetailDataLabel>
            <p>
              {getSubscriptionTime(
                data?.appointment?.appointmentStartDate,
                data?.appointment?.appointmentEndDate,
                lang
              )}
            </p>
          </DetailContainerData>
          <DetailContainerData>
            <DetailDataLabel>{data?.locationLabel}</DetailDataLabel>
            <p>{data?.locationMaranelloTxt}</p>
          </DetailContainerData>
        </>
      )}
      <DetailContainerData>
        <DetailDataLabel>{data?.additionalGiftLabel}</DetailDataLabel>
        <p>
          {data?.additionalGift === true
            ? data?.additionalGiftYesLabel
            : data?.additionalGiftNoLabel}
        </p>
      </DetailContainerData>
    </>
  );
}

export default React.memo(DetailInfoGiftHouse);
