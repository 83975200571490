import styled from "styled-components";

export const Wrapper = styled.div`
  background: var(--alternative-bg-color);
  padding: 32px;
  position: relative;
  margin-bottom: 32px;
  h4 {
    margin-top: 0px;
  }
  p.desktop-small {
    color: var(--alternative-grey-color);
  }
  @media screen and (min-width: 1280px) {
    h4 {
      max-width: calc(100% - 100px);
    }
  }
  @media screen and (max-width: 1279px) {
    flex-direction: column;
    display: flex;
  }
`;

export const DishTitle = styled.h4`
   {
    span:nth-child(1) {
      margin-left: 8px;
    }
  }
`;
export const DishesCTA = styled.div`
  display: flex;
  > div:nth-child(2) {
    margin-left: auto;
  }
  > div {
    display: flex;
    align-content: center;
    align-items: center;
  }
  > div > span {
    margin: 0px 10px;
  }
`;

export const Kilos = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  > span {
    margin: 0px 10px;
  }
  span {
    font-size: 11px;
  }
  @media screen and (max-width: 1279px) {
    position: static;
    margin-bottom: 16px;
  }
`;
