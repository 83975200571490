import React from "react";

// style
import {
  TitleAndTextWrapper,
  CardTitle,
  Text,
  DescrWrapper,
  Mask1,
  Mask2,
} from "./style";
import { useRef, useState } from "react";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";
import useMediaQuery from "../../../../hooks/useMediaQuery";

function EventCard({ item, category, loader, setLoaded }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <>
      <ShowOnIntersection
        loader={loader}
        setLoaded={setLoaded}
      ></ShowOnIntersection>
      <Mask1 />
      <Mask2 />
      {isDesktop ? (
        <img src={item?.image} />
      ) : (
        <img src={item?.fileReferenceMobile || item?.image} />
      )}
      <TitleAndTextWrapper>
        <CardTitle>
          <span>⏤ </span>
          <span>{category || item?.category}</span>
        </CardTitle>
        <DescrWrapper>
          <span>
            {item?.dateDay} {item?.dateMonth}
          </span>
          <Text>{item?.title}</Text>
        </DescrWrapper>
      </TitleAndTextWrapper>
    </>
  );
}

export default React.memo(EventCard);
