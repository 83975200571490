import React from "react";
// style
import { Wrapper } from "./style";
//components
import ContactCard from "../contactsList/contactCard/contactCard";
import { useSelector } from "react-redux";
import SearchResultTitle from "../searchResultTitle/SearchResultTitle";

function SearchResultContactsList() {
  const searchResult = useSelector((state) => state.rubrica.searchResult);

  return (
    <Wrapper>
      <SearchResultTitle />
      {React.Children.toArray(
        searchResult.map((v) => {
          return <ContactCard data={v} />;
        })
      )}
    </Wrapper>
  );
}

export default SearchResultContactsList;
