import { useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";
// components
import NotificationModal from "./modal/NotificationModal";

// actions
import { notificationActions } from "../../../../pages/Notifications/notificationSlice";
// style
import {
  Container,
  FilterContainer,
  SelectWrapper,
  Select,
  WrapperImg,
  ButtonsWrapper,
} from "./style";
import BottomIcon from "../../../assets/bottom";

function NotificationTop({
  data,
  markAllReadHandler,
  markSelectedReadHandler,
}) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState({
    visible: false,
    btnPressed: "",
  });
  const categoryRef = useRef();
  const yearRef = useRef();

  const confirmSelected = useCallback(() => {
    markSelectedReadHandler();
    setShowModal({
      visible: false,
      btnPressed: "",
    });
  }, [markSelectedReadHandler]);

  const confirmReadAll = useCallback(() => {
    markAllReadHandler();
    setShowModal({
      visible: false,
      btnPressed: "",
    });
  }, [markAllReadHandler]);

  const closeModal = useCallback(() => {
    setShowModal({
      visible: false,
      btnPressed: "",
    });
  }, []);

  return (
    <>
      <Container>
        <h1>{data?.title}</h1>
        <ButtonsWrapper>
          <div
            onClick={() =>
              setShowModal({ visible: true, btnPressed: "readAll" })
            }
          >
            {data?.allAlreadyReadLabel}
          </div>
          <div
            onClick={() =>
              setShowModal({ visible: true, btnPressed: "readSelected" })
            }
          >
            {data?.alreadyReadLabel}
          </div>
        </ButtonsWrapper>
      </Container>
      <FilterContainer>
        <SelectWrapper grow={true}>
          <Select
            ref={categoryRef}
            onChange={() =>
              dispatch(
                notificationActions.setFilterType(categoryRef.current.value)
              )
            }
          >
            <option value="all">
              {data?.categoryAllPlaceholder || "tutte le categorie"}
            </option>
            <option value="priority">
              {data?.priorityPlaceholder || "prioritarie"}
            </option>
            <option value="corporate-communications">
              {data?.corporateCommunicationsPlaceholder ||
                "comunicazioni aziendali"}
            </option>
            <option value="news">{data?.newsPlaceholder || "news"}</option>
            <option value="event">{data?.eventsPlaceholder || "eventi"}</option>
          </Select>
          <WrapperImg>
            <BottomIcon></BottomIcon>
          </WrapperImg>
        </SelectWrapper>
        <SelectWrapper>
          <Select
            ref={yearRef}
            onChange={() =>
              dispatch(notificationActions.setYear(yearRef.current.value))
            }
          >
            <option>{new Date().getFullYear()}</option>
            <option>{new Date().getFullYear() - 1}</option>
          </Select>
          <WrapperImg>
            <BottomIcon></BottomIcon>
          </WrapperImg>
        </SelectWrapper>
      </FilterContainer>
      {showModal.visible ? (
        showModal.btnPressed === "readAll" ? (
          <>
            <NotificationModal
              data={data}
              confirmCallBack={confirmReadAll}
              closeModal={closeModal}
              message={data.modalAllDescription}
              //passo anno e category
              // category={categoryRef.current.value}
              // year={yearRef.current.value}
            />
          </>
        ) : (
          <NotificationModal
            data={data}
            confirmCallBack={confirmSelected}
            message={data.modalDescription}
            closeModal={closeModal}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default NotificationTop;
