import styled from "styled-components";

export const Wrapper = styled.div`
  div.body-info {
    font-size: 13px;
    line-height: 20px;
  }
  div.contacts {
    margin: 32px 0px 64px;
    div {
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
      th {
        color: rgb(143, 143, 143);
        padding-right: 32px;
        text-align: left;
      }
    }
  }
`;
