import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto 80px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  line-height: 32px;
  a {
    color: var(--main-color);
    cursor: pointer;
    padding-bottom: 5px;
    border-bottom: 3px solid var(--main-color);
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
  }
`;
export const ResultsLabel = styled.div`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  margin-bottom: 32px;
`;
