import React from "react";
import { DetailContainerData, DetailDataLabel } from "./style";

export function DetailInfo({ data }) {
  const appointment = `${data?.subscription?.primaryAppointment?.startDateTxt} 
  ${data?.subscription?.primaryAppointment?.appointmentStartDate.slice(11, 16)} 
  - 
  ${data?.subscription?.primaryAppointment?.appointmentEndDate.slice(11, 16)}`;

  return (
    <>
      <DetailContainerData>
        <DetailDataLabel>{data?.summaryDateLbl}</DetailDataLabel>
        <p>{appointment}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.summaryLocationLbl}</DetailDataLabel>
        <p>{data?.subscription?.primaryAppointment?.locationTxt}</p>
      </DetailContainerData>
      <DetailContainerData>
        <DetailDataLabel>{data?.summaryGuestsLbl}</DetailDataLabel>
        <p>
          {data?.subscription?.numGuests === 0
            ? data?.noGuestLblEventLabel?.toUpperCase()
            : data?.subscription?.numGuests}
        </p>
      </DetailContainerData>
    </>
  );
}

export default React.memo(DetailInfo);
