import { Card, Description } from "./style";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function NewsCard({ newsCardProps }) {
  const d = new Date(newsCardProps.createdDate);
  const options = { month: "short", day: "numeric" };
  const newsDate = d.toLocaleDateString(undefined, options);
  return (
    <Link
      to="#"
      onClick={(e) => {
        e.preventDefault();
        window.location = newsCardProps?.path
          ? "/news/news-detail?path=" + newsCardProps.path
          : "#";
        //window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
    >
      <Card>
        <img src={newsCardProps.image} />
        <Description>
          <span>{newsDate}</span>
          <span />
          <span>{newsCardProps.category}</span>
        </Description>
        <h3>{newsCardProps.title}</h3>
      </Card>
    </Link>
  );
}
NewsCard.propTypes = {
  newsCardProps: PropTypes.exact({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    createdDate: PropTypes.number.isRequired,
    lastModifiedDate: PropTypes.number,
    lastReplicatedDate: PropTypes.number,
    category: PropTypes.string.isRequired,
    tags: PropTypes.array,
  }),
};
export default NewsCard;
