import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// components
import NewsTitle from "../../components/UI/news/newsTitle/NewsTitle";
import FilterResultsDetails from "../../components/UI/news/filterResultsDetails/filterResultsDetails";
import NewsCardList from "../../components/UI/news/newsCardList/NewsCardList";
import LayoutNews from "../../components/UI/news/LayoutNews";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function NewsFilterResults() {
  const dispatch = useDispatch();

  const { state } = useLocation();
  const filteredCardList = { results: state };
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const { components } = useSelector((state) => state.news);
  const newsTitleData = useMemo(() => {
    return components?.components.find(
      (el) => el.resourceType === "news/fi-news-title"
    ).data;
  }, [components]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(true));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, "News Filter Results", "/news-filter-results");
  return (
    <LayoutNews>
      <NewsTitle data={newsTitleData} />
      <FilterResultsDetails data={newsTitleData} resultsNumber={state.length} />
      <NewsCardList data={filteredCardList} />
    </LayoutNews>
  );
}

export default NewsFilterResults;
