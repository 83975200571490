import React from "react";
// style
import { ImgHeader, Section } from "./style";

function LayoutContactUs({ heroImg, children }) {
  return (
    <>
      <ImgHeader src={heroImg} />
      <Section>{children}</Section>
    </>
  );
}

export default React.memo(LayoutContactUs);
