import styled from "styled-components";

export const AllEvents = styled.div`
  margin: 15px auto;

  span:nth-child(2) {
    margin-left: 10px;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }

  @media screen and (min-width: 1280px) {
    margin: 96px auto 64px;
    position: relative;
    width: 1216px;
    &.webinars {
      margin: 75px auto 0;
    }
  }
`;

export const AllEventsCta = styled.a.attrs({
  href: "/it/news-and-events/events" || "#",
})`
  color: var(--cta-color);
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
`;

export const AllEventsCtaIcon = styled.span`
  margin-left: 10px;
  transform: rotate(180deg);
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(143, 143, 143);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 16px;
  width: 16px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
`;

export const AllEventsCtaIconCircle = styled.span`
  position: absolute;
  inset: -3px;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
    overflow: hidden;
  }
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
`;

export const ArrowIcon = styled.svg.attrs({
  width: "8",
  height: "16",
  viewBox: "0 0 8 16",
  xmlns: "http://www.w3.org/2000/svg",
})`
  cursor: pointer;
`;

export const FilterIconPath = styled.path.attrs({
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M20.874 8C20.4299 9.72523 18.8638 11 17 11C15.1362 11 13.5701 9.72523 13.126 8H0C0 6.89543 0.895431 6 2 6H13.126C13.5701 4.27477 15.1362 3 17 3C18.8638 3 20.4299 4.27477 20.874 6H24V8H20.874ZM19 7C19 8.10457 18.1046 9 17 9C15.8954 9 15 8.10457 15 7C15 5.89543 15.8954 5 17 5C18.1046 5 19 5.89543 19 7ZM3.12602 16C3.57006 14.2748 5.13616 13 7 13C8.86384 13 10.4299 14.2748 10.874 16H24C24 17.1046 23.1046 18 22 18H10.874C10.4299 19.7252 8.86384 21 7 21C5.13616 21 3.57006 19.7252 3.12602 18H0V16H3.12602ZM5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17C9 18.1046 8.10457 19 7 19C5.89543 19 5 18.1046 5 17Z",
})`
  fill: var(--main-color);
`;
