import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { postDefaultNumber } from "../../../../../pages/MyPage/actions";
// style
import { InputSection, Select } from "./style";

function Number({ data }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const { valuedContactFields: defaultOptions, labelMap } = useSelector(
    (state) => state.myPage
  );

  const defaultOpt = Object.keys(labelMap).find(
    (key) => labelMap[key] === data.defaultNumber
  );
  const [selectedOption, setSelectedOption] = useState(defaultOpt);

  const handleModifyButtonClick = (event) => {
    const favouriteNumber = document.getElementById("favourite-number");
    if (event.target.textContent === data.modify) {
      event.target.textContent = "salva";
      favouriteNumber.classList.toggle("highlight");
      favouriteNumber.disabled = false;
    } else {
      event.target.textContent = data.modify;
      favouriteNumber.classList.toggle("highlight");
      favouriteNumber.disabled = true;

      dispatch(
        postDefaultNumber({ defaultNumber: labelMap[selectedOption], token })
      );
    }
  };

  const handleOnChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <h3>
        {data?.defaultNumberTitle}
        <span onClick={handleModifyButtonClick}>{data?.modify}</span>
      </h3>
      <InputSection>
        <div>
          <Select
            id="favourite-number"
            disabled
            value={selectedOption !== undefined ? selectedOption : defaultOpt}
            onChange={handleOnChange}
          >
            {defaultOptions.length === 0 && (
              <option value={"default"}>{data?.defaultNumberLabel}</option>
            )}
            {React.Children.toArray(
              defaultOptions.map((option) => {
                return <option value={option}>{option}</option>;
              })
            )}
          </Select>
        </div>
      </InputSection>
    </>
  );
}

export default React.memo(Number);
