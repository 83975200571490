import { takeLatest, put, select, call } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";
import { selectComponents } from "./selectors";
import { SAVE_ACTION, FETCH_COMPONENTS } from "./constants";
import axios from "axios";
import {
  setComponents,
  setSettings,
  setError,
  setTabsArray,
  updateTabsArray,
} from "./BusinessCardManagementSlice";
import { showErrorMessage } from "../../utils/notifications/TriggerNotification";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-ecard-approvement-management.json")
    // );
    yield put(setComponents(result?.data?.data?.components));
    yield put(setSettings(result?.data?.data?.settings));

    const components = yield select(selectComponents());
    const tabsComponents = components.find(
      (v) => v.resourceType === "fi-ecard-approval/fi-ecard-approval-tabs"
    );

    const pickedComponents = (({ toApprove, approved, rejected }) => ({
      toApprove,
      approved,
      rejected,
    }))(tabsComponents.data);

    const tabsArray = Object.entries(pickedComponents).map(([_, value], i) => {
      return { id: i, components: value };
    });

    yield put(setTabsArray(tabsArray));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

function* saveHrManagerAction({ payload }) {
  try {
    document.body.classList.add("loading");
    yield axiosCallPost(
      "/it/digital-card-approval",
      payload.responseBody,
      payload.token
    );
    yield put(updateTabsArray(payload.responseBody));
  } catch (e) {
    showErrorMessage("Error while saving data");
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(SAVE_ACTION, saveHrManagerAction);
}
