import firebase from "firebase/compat/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import "firebase/compat/database";

const app_key =
  "BEi6N_jtzIKwe3AlebjhS-Ab9bQHJ5LJR6Ap0Vzs0nOzzugJdTEeMzDaxH1RJyBGfE-VerfY1HWkR_FsjQxda8U";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const notification_options = {
  icon: "/img/icon.png",
  tag: "notification 1",
  badge: "/img/icon.png",
  image: "/img/background/sm.jpg",
  renotify: true,
  click_action: "/", // To handle notification click when notification is moved to notification tray
  data: {
    click_action: "/",
  },
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const m = getMessaging();

function onNotification(theNotification) {
  const { title, link_url, ...options } = theNotification;
  notification_options.data.link_url = link_url;

  if ("serviceWorker" in navigator) {
    // this will register the service worker or update it. More on service worker soon
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js", { scope: "./" })
      .then(function (registration) {
        console.log("Service Worker Registered");
        setTimeout(() => {
          // display the notificaiton
          registration
            .showNotification(title, { ...notification_options, ...options })
            .then((done) => {
              console.log("sent notificaiton to user");
              const audio = new Audio("./util/sound/one_soft_knock.mp3"); // only works on windows chrome
              audio.play();
            })
            .catch((err) => {
              console.error("Error sending notificaiton to user", err);
            });
          registration.update();
        }, 100);
      })
      .catch(function (err) {
        console.log("Service Worker Failed to Register", err);
      });
  }
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
onMessage(m, (payload) => {
  console.log("Received Notification:", payload);
  const theNotification = payload.data;

  if (Notification.permission === "granted") onNotification(theNotification);
});

// m.onBackgroundMessage((payload) => {
//   console.log("Received background message", payload);
//   const { title, link_url, ...options } = payload.data;
//   notification_options.data.link_url = link_url;

//   // // Customize notification here
//   // self.registration.showNotification(title, {
//   //   ...notification_options,
//   //   ...options,
//   // });
// });

function sendTokenToDB() {
  getToken(m, {
    vapidKey: app_key,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        // ... add you logic to send token to server
        onNotification({
          title: "Successful",
          body: "Your device has been register",
          tag: "welcome",
        });
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
}

export function registerUserFCM() {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a token for that user and send to server
    sendTokenToDB();
  } else if (Notification.permission !== "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, create a token and send to server
      if (permission === "granted") {
        sendTokenToDB((done) => {
          console.log("done", done);
          if (done) {
            onNotification({
              title: "Successful",
              body: "Your device has been register",
              tag: "welcome",
            });
          }
        });
      } else {
        alert("You won't be able to receive important notifications 😥!");
      }
    });
  }
}

// if (window.Notification) {
//   if (Notification.permission === "granted") {
//     getToken(messaging, {
//       vapidKey:
//         "BFJOyR8PYFMiKKAXj3uCQtVv0q_pQdaL3yYIrUWyyEIchyksoIVZ1sUSTv_x0-r4T5IlD_TBXRmUL_5U8-vXAZ8",
//     })
//       .then((currentToken) => {
//         if (currentToken) {
//           // Send the token to your server and update the UI if necessary
//           // ...
//           console.log("currentToken", currentToken);
//           const arrayToken = [currentToken];
//           messaging
//             .subscribeToTopic(currentToken, "push-notification-it")
//             .then((response) => {
//               // See the MessagingTopicManagementResponse reference documentation
//               // for the contents of response.
//               console.log("Successfully subscribed to topic:", response);
//             })
//             .catch((error) => {
//               console.log("Error subscribing to topic:", error);
//             });

//           onMessage(messaging, (payload) => {
//             debugger;
//             console.log("Message received. ", payload);
//             // ...
//           });
//         } else {
//           // Show permission request UI
//           console.log(
//             "No registration token available. Request permission to generate one."
//           );
//           // ...
//         }
//       })
//       .catch((err) => {
//         console.log("An error occurred while retrieving token. ", err);
//         // ...
//       });
//   } else if (Notification.permission === "denied") {
//     console.log("request permission");
//     Notification.requestPermission((permission) => {
//       console.log("permission: ", permission);
//       if (permission === "granted") {
//         getToken(messaging, {
//           vapidKey:
//             "BFJOyR8PYFMiKKAXj3uCQtVv0q_pQdaL3yYIrUWyyEIchyksoIVZ1sUSTv_x0-r4T5IlD_TBXRmUL_5U8-vXAZ8",
//         })
//           .then((currentToken) => {
//             if (currentToken) {
//               // Send the token to your server and update the UI if necessary
//               // ...
//               onMessage(messaging, (payload) => {
//                 debugger;
//                 console.log("Message received. ", payload);
//                 // ...
//               });

//               console.log("currentToken", currentToken);
//             } else {
//               // Show permission request UI
//               console.log(
//                 "No registration token available. Request permission to generate one."
//               );
//               // ...
//             }
//           })
//           .catch((err) => {
//             console.log("An error occurred while retrieving token. ", err);
//             // ...
//           });
//       }
//     });
//   }
// }
