import { InputSectionCheckbox, PrivacyRules, TextDescr } from "./style";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorField from "../ErrorField/ErrorField";
import { setCheckedConsents } from "../../../../../pages/FormIscrizioneFamilyDay/FormIscrizioneFamilyDaySlice";

export default function ComponentPrivacy({ data, allowed }) {
  //valutare se creare un componente a se stante valido per ogni evento ed evitare ripetizioni di codice
  const dispatch = useDispatch();
  const checkedConsents = useSelector(
    (state) => state.FormIscrizioneFamilyDay.checkedConsents
  );

  function generateHtml(argument) {
    return { __html: argument };
  }

  function parseLabel(id, stringToParse) {
    if (!stringToParse?.includes("<a")) {
      return stringToParse;
    } else {
      const splittedArray = stringToParse.split("<a");
      const firstPart = splittedArray[0].concat(
        `<a count='${id}' id='ruleLink${id}'`
      );
      const result = firstPart.concat(splittedArray[1]);
      return result;
    }
  }

  const enablePrivacyCheckbox = (e, id) => {
    const linkElem = e.target;
    linkElem.setAttribute("listener", "true");

    if (document.querySelector(id).disabled === true) {
      document.querySelector(id).disabled = false;
      document.querySelector(id).parentElement.style.color =
        "var(--main-color)";
    }
  };

  const updateCheckboxPrivacy = () => {
    document.querySelectorAll(".checkbox-input").forEach((checkbox) => {
      const rawText = checkbox.getAttribute("rawtext");
      if (!rawText?.includes("<a")) {
        checkbox.disabled = false;
      }
      checkbox.disabled
        ? (checkbox.parentElement.style.color = "var(--alternative-grey-color)")
        : (checkbox.parentElement.style.color = "var(--main-color)");
    });

    document.querySelectorAll("a[count]").forEach((link) => {
      if (link.getAttribute("listener") !== "true") {
        const count = link.getAttribute("count");
        link.addEventListener("click", (e) =>
          enablePrivacyCheckbox(e, `#checkBoxPrivacy${count}`)
        );
      }
    });
  };

  const handlePrivacy = (e) => {
    const { id, checked } = e.currentTarget;

    let dateRegistered = checkedConsents?.filter((el) => el?.id === id);
    let subscription = checkedConsents?.filter((el) => {
      if (el?.id !== id) {
        return el;
      }
    });
    const spreadObject = dateRegistered ? subscription : checkedConsents;
    dispatch(
      setCheckedConsents([...spreadObject, { id: id, checked: checked }])
    );
  };

  useEffect(() => {
    if (document.querySelector(".checkbox-input")) updateCheckboxPrivacy();
  }, [updateCheckboxPrivacy]);

  return (
    <PrivacyRules>
      <TextDescr>{data?.policiesRules?.title}</TextDescr>
      {React.Children.toArray(
        data?.policiesRules?.items?.map((policie, i) => {
          return (
            <InputSectionCheckbox>
              <input
                disabled
                id={`checkBoxPrivacy${i}`}
                type="checkbox"
                className="checkbox-input"
                name={data?.event?.items?.[i]?.title}
                onChange={handlePrivacy}
                rawtext={policie?.richText}
              />
              <span
                className=""
                dangerouslySetInnerHTML={generateHtml(
                  parseLabel(`${i}`, policie?.richText)
                )}
              />
            </InputSectionCheckbox>
          );
        })
      )}
      {(checkedConsents?.length !== data?.policiesRules?.items?.length ||
        checkedConsents?.find((el) => el.checked === false)) &&
        allowed === false && (
          <ErrorField text={data?.policiesRules?.errorMessage} id="required" />
        )}
    </PrivacyRules>
  );
}
