import { Text } from "./style";

function ContractContainer({ data }) {
  function html() {
    return { __html: data.text.text };
  }

  return (
    <>
      <h1>{data.title.jcrTitle}</h1>
      <Text dangerouslySetInnerHTML={html()}></Text>
    </>
  );
}

export default ContractContainer;
