import {
  Container,
  TitleForm,
  SendButton,
  InputSection,
  SectionForm,
  RadioButton,
  TextDescr,
  RadioButtonOpts,
  SectionRadioButton,
  SectionFormDatePicker,
  TextDescrGrey,
  ButtonContainer,
  DeleteButton,
} from "./style";

import React, { useState, useEffect } from "react";
import { parseISO } from "date-fns";
import { isEqual } from "lodash";

//hooks
import { useDispatch } from "react-redux";
import {
  SetUserInformationTestHmi,
  deleteSubscription,
} from "../../../pages/FormTestHmi/action";
import {
  setBodyPayload,
  setDisplayError,
  setShowModal,
} from "../../../pages/FormTestHmi/FormTestHmiSlice";
//components
import CustomSelect from "../customSelect/CustomSelect";
import ErrorField from "./components/ErrorField/ErrorField";
import DatePickerCustom from "../datePickerCustom/datePickerCustom";
import { useSelector } from "react-redux";
import { Modal } from "./components/Modal/Modal";

function LayoutFormTestHmi({ data }) {
  const bodyPayload = useSelector((state) => state.FormTestHmi.bodyPayload);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const id = useSelector((state) => state.FormTestHmi.deleteId);
  const path = headerLinks?.find((v) => v.template === "adesioni-hmi")?.path;
  const savedBodyPayload = useSelector(
    (state) => state.FormTestHmi.savedBodyPayload
  );
  const requestStatus = useSelector((state) => state.FormTestHmi.requestStatus);
  const token = useSelector((state) => state.login.activeToken);
  const lang = useSelector((state) => state.coreComponents.language);
  const showModal = useSelector((state) => state.FormTestHmi.showModal);
  const showSuccessModal = useSelector(
    (state) => state.FormTestHmi.showSuccessModal
  );
  const displayError = useSelector((state) => state.FormTestHmi.displayError);

  const [startUserDate, setStartUserDate] = useState(
    bodyPayload?.Birthdate || undefined
  );
  const [modelCar, setModelCar] = useState(bodyPayload?.CarModel);
  const [brandCar, setBrandCar] = useState(bodyPayload?.CarBrand);
  const currentStatus = requestStatus === 1 ? true : null;

  const dispatch = useDispatch();

  const handleOpenDialog = (e) => {
    e.preventDefault();
    dispatch(setShowModal(true));
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "CarBrand") {
      const { CarModel, ...rest } = bodyPayload;
      dispatch(
        setBodyPayload({
          ...rest,
          CarBrand: value,
          CarModel: null,
        })
      );
      setModelCar("");
    } else if (name === "HasChild" && value === "false") {
      dispatch(
        setBodyPayload({
          ...bodyPayload,
          [name]: value,
          YoungestChildBirthYear: null,
        })
      );
    } else {
      dispatch(setBodyPayload({ ...bodyPayload, [name]: value }));
    }
  };

  //this function is triggered before onInputChange
  const onlyIntegers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
  };

  const onlyFourDigits = (e) => {
    onlyIntegers(e);
    const { value } = e.target;
    if (value?.length >= 5) {
      e.target.value = value.slice(0, -1);
    }
  };

  const onDateChange = (newDate, inputName) => {
    dispatch(setBodyPayload({ ...bodyPayload, [inputName]: newDate }));
  };

  const todayDate = new Date(Date.now())?.toLocaleString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const isEveryFieldFilled = (bodyPayload) => {
    let result = Object.entries(bodyPayload).every((v) => {
      if (v[0] !== "YoungestChildBirthYear" && !v[1]) {
        return false;
      } else if (bodyPayload?.HasChild === "true" && !v[1]) {
        return false;
      }
      return true;
    });
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedBody = {
      Birthdate: bodyPayload?.Birthdate,
      Phone: bodyPayload?.Phone,
      Nationality: bodyPayload.Nationality,
      Lang: bodyPayload?.Lang,
      HasChild: bodyPayload?.HasChild === "true",
      YoungestChildBirthYear: bodyPayload?.YoungestChildBirthYear
        ? +bodyPayload?.YoungestChildBirthYear
        : null,
      CarBrand: bodyPayload?.CarBrand,
      CarModel: bodyPayload?.CarModel,
      CarModelYear: +bodyPayload?.CarModelYear,
      AnnualKm: +bodyPayload?.AnnualKm,
    };

    if (
      isEveryFieldFilled(bodyPayload) &&
      isValidYear(bodyPayload?.CarModelYear) &&
      ((bodyPayload?.HasChild === "true" &&
        isValidYear(bodyPayload?.YoungestChildBirthYear)) ||
        bodyPayload?.HasChild === "false") &&
      !isEqual(bodyPayload, savedBodyPayload)
    ) {
      dispatch(setDisplayError(false));
      dispatch(
        SetUserInformationTestHmi({
          formattedBody,
          token,
          lang,
          path,
        })
      );
    } else {
      dispatch(setDisplayError(true));
    }
  };

  const isValidYear = (yearStr) => {
    const currentYear = new Date().getFullYear();
    const yearInt = +yearStr;

    return yearInt >= 1900 && yearInt <= currentYear ? true : false;
  };

  const brands = data?.carModels?.map((carBrand) => carBrand?.Brand);
  let filteredBrands = brands?.filter(
    (brand, i) => brands.indexOf(brand) === i
  );
  let isCarBrandInjected = false;
  if (!filteredBrands?.includes(bodyPayload?.CarBrand)) {
    isCarBrandInjected = true;
    filteredBrands = [...filteredBrands, bodyPayload?.CarBrand];
  }

  const carsOfBrand = data?.carModels?.filter(
    (cars) => cars?.Brand === bodyPayload?.CarBrand
  );

  const resetHtml = () => {
    const childYear = document.querySelector("#YoungestChildBirthYear");
    document.querySelector("#phoneNumber").value = "";
    document.querySelector("#Nationality").value = "";
    document.querySelector("#Lang").value = "";
    document.querySelector("#CarModelYear").value = "";
    document.querySelector("#carKm").value = "";
    setStartUserDate(undefined);
    setModelCar(undefined);
    setBrandCar(undefined);
    if (childYear) document.querySelector("#YoungestChildBirthYear").value = "";
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(
      deleteSubscription({
        id,
        token,
        lang,
        path,
      })
    );
    dispatch(setShowModal(false));
    dispatch(setDisplayError(null));
    resetHtml();
  };

  useEffect(() => {
    if (
      isEveryFieldFilled(bodyPayload) &&
      isValidYear(bodyPayload?.CarModelYear) &&
      ((bodyPayload?.HasChild === "true" &&
        isValidYear(bodyPayload?.YoungestChildBirthYear)) ||
        bodyPayload?.HasChild === "false") &&
      !isEqual(bodyPayload, savedBodyPayload)
    ) {
      document.querySelector?.(".submitButton")?.classList.add("activeSubmit");
      document.querySelector?.(".modify")?.classList.add("activeModify");
    } else {
      document
        .querySelector?.(".submitButton")
        ?.classList.remove("activeSubmit");
      document.querySelector?.(".modify")?.classList.remove("activeModify");
    }
  }, [displayError, bodyPayload, savedBodyPayload, currentStatus]);

  return (
    <>
      <TitleForm>
        {data?.title}
        {data?.description && (
          <div>
            <p>{data?.description}</p>
          </div>
        )}
      </TitleForm>
      <Container>
        {showModal && (
          <Modal data={data?.deleteModal} handleDelete={handleDelete} />
        )}
        {showSuccessModal && (
          <Modal
            data={data?.successModal}
            showSuccessModal={showSuccessModal}
          />
        )}
        <form>
          <div className="divContainer">
            <h3>{data?.form?.personalDataTitle}</h3>
            {/**nome cognome */}
            <SectionForm>
              <InputSection hideLabel={true}>
                <input
                  className="disabled"
                  autoComplete="nope"
                  disabled
                  id="nome"
                  name="Name"
                  placeholder={data?.form?.nameLabel}
                  type="text"
                  defaultValue={data?.formValues?.name}
                />
                <label htmlFor="nome">{data?.form?.nameLabel}</label>
              </InputSection>
              <InputSection hideLabel={true}>
                <input
                  className="disabled"
                  autoComplete="nope"
                  disabled
                  id="cognome"
                  name="Title"
                  placeholder={data?.form?.surnameLabel}
                  type="text"
                  defaultValue={data?.formValues?.lastName}
                />
                <label htmlFor="cognome">{data?.form?.surnameLabel}</label>
              </InputSection>
            </SectionForm>
            {/**email */}
            <SectionForm>
              <InputSection hideLabel={true}>
                <input
                  className="disabled"
                  autoComplete="nope"
                  disabled
                  id="mail"
                  name="Email"
                  placeholder={data?.form?.emailLabel}
                  type="email"
                  defaultValue={data?.formValues?.email}
                />
                <label htmlFor="mail">{data?.form?.emailLabel}</label>
              </InputSection>
            </SectionForm>
            {/**numero telefono */}
            <SectionForm>
              <InputSection hideLabel={true}>
                <input
                  autoComplete="nope"
                  id="phoneNumber"
                  name="Phone"
                  placeholder={data?.form?.telLabel}
                  type="number"
                  defaultValue={bodyPayload?.Phone}
                  onChange={(e) => onInputChange(e)}
                />
                <label htmlFor="phoneNumber">{data?.form?.telLabel}</label>
              </InputSection>
            </SectionForm>
            {displayError && !bodyPayload?.Phone && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            {/**eta utente */}
            <SectionFormDatePicker className="dataPickerHmi">
              <label htmlFor="Birthdate">{data?.form?.dateLabel}</label>
              <DatePickerCustom
                startDate={startUserDate}
                selected={startUserDate}
                setStartDate={setStartUserDate}
                minDate={parseISO("1950-01-01")}
                maxDate={parseISO(todayDate)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode
                id={"Birthdate"}
                inputName={"Birthdate"}
                onChange={onDateChange}
              />
            </SectionFormDatePicker>
            {displayError && !bodyPayload?.Birthdate && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            {/**nazionalità */}
            <SectionForm>
              <InputSection hideLabel={true}>
                <input
                  autoComplete="nope"
                  id="Nationality"
                  name="Nationality"
                  placeholder={data?.form?.nationalityLabel}
                  type="text"
                  onChange={(e) => onInputChange(e)}
                  defaultValue={bodyPayload?.Nationality}
                />
                <label htmlFor="Nationality">
                  {data?.form?.nationalityLabel}
                </label>
              </InputSection>
            </SectionForm>
            {displayError && !bodyPayload?.Nationality && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            {/**lingua madre */}
            <SectionForm>
              <InputSection hideLabel={true}>
                <input
                  autoComplete="nope"
                  id="Lang"
                  name="Lang"
                  placeholder={data?.form?.languageLabel}
                  type="text"
                  onChange={(e) => onInputChange(e)}
                  defaultValue={bodyPayload?.Lang}
                />
                <label htmlFor="Lang">{data?.form?.languageLabel}</label>
              </InputSection>
            </SectionForm>
            {displayError && !bodyPayload?.Lang && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            {/**hai figli */}
            <SectionRadioButton>
              <TextDescrGrey>{data?.form?.hasChildrenLabel}</TextDescrGrey>
              <RadioButtonOpts>
                <RadioButton>
                  <input
                    type="radio"
                    value={"true"}
                    checked={"true" === bodyPayload?.HasChild}
                    id={"HasChild"}
                    name={"HasChild"}
                    onChange={(e) => onInputChange(e)}
                  />
                  <label htmlFor={"HasChild"}>{data?.form?.yesValue}</label>
                </RadioButton>
                <RadioButton>
                  <input
                    type="radio"
                    value={"false"}
                    checked={"false" === bodyPayload?.HasChild}
                    id={"nHasChild"}
                    name={"HasChild"}
                    onChange={(e) => onInputChange(e)}
                  />
                  <label htmlFor={"nHasChild"}>{data?.form?.noValue}</label>
                </RadioButton>
              </RadioButtonOpts>
            </SectionRadioButton>
            {displayError && !bodyPayload?.HasChild && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            {/**età figlio */}
            {bodyPayload?.HasChild === "true" && (
              <>
                <SectionForm>
                  <InputSection hideLabel={true}>
                    <input
                      autoComplete="nope"
                      id="YoungestChildBirthYear"
                      name="YoungestChildBirthYear"
                      placeholder={data?.form?.youngestChildDateLabel}
                      type="text"
                      onInput={(e) => onlyFourDigits(e)}
                      onChange={(e) => onInputChange(e)}
                      defaultValue={bodyPayload?.YoungestChildBirthYear}
                    />
                    <label htmlFor="CarModelYear">
                      {data?.form?.youngestChildDateLabel}
                    </label>
                  </InputSection>
                </SectionForm>
                {((displayError && !bodyPayload?.YoungestChildBirthYear) ||
                  (displayError &&
                    !isValidYear(bodyPayload?.YoungestChildBirthYear))) && (
                  <ErrorField text={data?.form?.errorMessage} />
                )}
              </>
            )}
            <h3>{data?.form?.carInfoTitle}</h3>
            {/**vettura in possesso */}
            <TextDescr>{data?.form?.carInfoDescr}</TextDescr>
            <SectionForm>
              <CustomSelect
                autoComplete="nope"
                id="CarBrand"
                name="CarBrand"
                setValue={setBrandCar}
                defaultValue={brandCar || ""}
                placeholderString={data?.form?.carBrandLabel}
                onChange={(e) => onInputChange(e)}
              >
                {filteredBrands?.map((brand, index) => {
                  return (
                    <option
                      hidden={
                        isCarBrandInjected &&
                        index === filteredBrands?.length - 1
                      }
                      key={index}
                      value={brand}
                    >
                      {brand}
                    </option>
                  );
                })}
              </CustomSelect>
            </SectionForm>
            {displayError && !bodyPayload?.CarBrand && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            <SectionForm>
              <CustomSelect
                autoComplete="nope"
                id="CarModel"
                name="CarModel"
                setValue={setModelCar}
                defaultValue={modelCar || ""}
                placeholderString={data?.form?.carModelLabel}
                onChange={(e) => onInputChange(e)}
              >
                {carsOfBrand.length > 0 ? (
                  carsOfBrand?.map((car, index) => (
                    <option key={index} value={car?.LinkTitle}>
                      {car?.LinkTitle}
                    </option>
                  ))
                ) : (
                  <option hidden key={"noKey"} value={bodyPayload?.CarModel}>
                    {bodyPayload?.CarModel}
                  </option>
                )}
              </CustomSelect>
            </SectionForm>
            {displayError && !bodyPayload?.CarModel && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            {/**model year */}
            <SectionForm>
              <InputSection hideLabel={true}>
                <input
                  autoComplete="nope"
                  id="CarModelYear"
                  name="CarModelYear"
                  step={1}
                  placeholder={data?.form?.modelYearLabel}
                  type="text"
                  onInput={(e) => onlyFourDigits(e)}
                  onChange={(e) => onInputChange(e)}
                  defaultValue={bodyPayload?.CarModelYear}
                />
                <label htmlFor="CarModelYear">
                  {data?.form?.modelYearLabel}
                </label>
              </InputSection>
            </SectionForm>
            {((displayError && !bodyPayload?.CarModelYear) ||
              (displayError && !isValidYear(bodyPayload?.CarModelYear))) && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
            {/**numero km */}
            <SectionForm>
              <InputSection hideLabel={true}>
                <input
                  autoComplete="nope"
                  id="carKm"
                  name="AnnualKm"
                  min={"0"}
                  max={"1000000"}
                  defaultValue={bodyPayload?.AnnualKm}
                  placeholder={data?.form?.carKmLabel}
                  type="text"
                  onInput={(e) => onlyIntegers(e)}
                  onChange={(e) => onInputChange(e)}
                />
                <label htmlFor="carKm">{data?.form?.carKmLabel}</label>
              </InputSection>
            </SectionForm>
            {displayError && !bodyPayload?.AnnualKm && (
              <ErrorField text={data?.form?.errorMessage} />
            )}
          </div>
          {requestStatus === 0 ? (
            <SendButton
              onClick={(e) => handleSubmit(e)}
              className="submitButton"
            >
              {data?.buttons?.submitTxt}
            </SendButton>
          ) : (
            <ButtonContainer>
              <SendButton onClick={(e) => handleSubmit(e)} className="modify">
                {data?.buttons?.modifyTxt}
              </SendButton>
              <DeleteButton
                onClick={(e) => handleOpenDialog(e)}
                className="activeDelete"
              >
                {data?.buttons?.deleteTxt}
              </DeleteButton>
            </ButtonContainer>
          )}
        </form>
      </Container>
    </>
  );
}

export default LayoutFormTestHmi;
