import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  @media (min-width: 1280px) {
    width: 384px;
  }
  background: var(--alternative-bg-color);
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  color: var(--main-color);
  img {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 212px;
  img {
    max-width: 100%;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 16px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

export const TitleTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
`;

export const SpanCategory = styled.span`
  padding: 4px 10px;
  background: rgb(239, 237, 237);
  border-radius: 30px;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: rgb(48, 48, 48);
`;

export const SpanType = styled.span`
  background: rgb(255, 255, 255);
  border: 1px solid rgb(24, 24, 24);
  border-radius: 30px;
  padding: 4px 10px;
  border-radius: 30px;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: rgb(48, 48, 48);
`;

export const TitleH4 = styled.h4`
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  height: 48px;
  font-family: "ferrari-sans-medium";
  @media (max-width: 1279px) {
    font-size: 13px;
    line-height: 20px;
    height: auto;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

export const Description = styled.div`
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const WhereBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const WhereTitle = styled.div`
  color: rgb(143, 143, 143);
  font-size: 11px;
`;

export const WhereText = styled.div`
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  height: 28px;
`;
