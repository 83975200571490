import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// actions
import { myPageActions } from "../../../../pages/MyPage/myPageSlice";
import {
  selectMyPageComponent,
  selectTabTitlesFromTabsArray,
} from "../../../../pages/MyPage/selectors";
// style
import { Container, Divider, Theme, ImgWrapper, ThemeRecap } from "./style";

function AvatarDropDown({
  showDropDown,
  showDropDownHandler,
  fromSearch = false,
  setPageLight,
  pageLight,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.coreComponents.language);
  const isInternalIp = useSelector((state) => state.login.isInternalIp);
  const tabsTitleMyPage = useSelector(selectTabTitlesFromTabsArray());
  const idLinks = isInternalIp
    ? ["idProfile", "idFamily", "idEvents", "btnTemaLigth", "btnTemaDark"]
    : [
        "idProfile",
        "idFamily",
        "idEvents",
        "idLogout",
        "btnTemaLigth",
        "btnTemaDark",
      ];

  const myPageComponents = useSelector(selectMyPageComponent());
  const isEcardTabActive = myPageComponents?.ecard.showTab;

  const updateIdLinks = isEcardTabActive
    ? [...idLinks.slice(0, 3), "idBusinessCard", ...idLinks.slice(3)]
    : idLinks;

  const HandleKeyPress = (e) => {
    let index = updateIdLinks.indexOf(e.target.id);
    const newIndexValue = updateIdLinks.length;
    switch (e.keyCode) {
      case 9:
        e.preventDefault();
        //shift + tab
        if (e.shiftKey) {
          index > 0 && document.getElementById(updateIdLinks[--index]).focus();
        } else {
          index < newIndexValue - 1 &&
            document.getElementById(updateIdLinks[++index]).focus();
        }
        break;
      //down arrow
      case 40:
        e.preventDefault();
        index < newIndexValue - 1 &&
          document.getElementById(updateIdLinks[++index]).focus();
        break;
      //up arrow
      case 38:
        e.preventDefault();
        index > 0 && document.getElementById(updateIdLinks[--index]).focus();
        break;
      case 27:
        e.preventDefault();
        showDropDownHandler();
        document.getElementById("avatar-dropdown").focus();
        break;
    }
  };

  const HandleSwitchTheme = (e) => {
    e.preventDefault();
    switch (e.keyCode) {
      case 13:
      case 32:
        e.target.id === "btnTemaLigth"
          ? setPageLight("light")
          : setPageLight("dark");
        break;
    }
  };

  return (
    <Container
      light={pageLight === "dark" ? true : false}
      visible={showDropDown}
      fromSearch={fromSearch}
      onKeyDown={(e) => HandleKeyPress(e)}
      role="combobox"
      onMouseLeave={(e) => showDropDownHandler()}
    >
      <Link
        id="idProfile"
        to={`${lang}/my-page`}
        onClick={() => {
          showDropDownHandler();
          dispatch(myPageActions.setTabIndex(1));
        }}
      >
        {tabsTitleMyPage[0]?.title}
      </Link>
      <Link
        id="idFamily"
        to={`${lang}/my-page`}
        onClick={() => {
          showDropDownHandler();
          dispatch(myPageActions.setTabIndex(2));
        }}
      >
        {tabsTitleMyPage[1]?.title}
      </Link>
      <Link
        id="idEvents"
        to={`${lang}/my-page`}
        onClick={() => {
          showDropDownHandler();
          dispatch(myPageActions.setTabIndex(3));
        }}
      >
        {tabsTitleMyPage[2]?.title}
      </Link>
      {isEcardTabActive && (
        <Link
          id="idBusinessCard"
          to={`${lang}/my-page`}
          onClick={() => {
            showDropDownHandler();
            dispatch(myPageActions.setTabIndex(4));
          }}
        >
          {tabsTitleMyPage[3]?.title}
        </Link>
      )}

      {!isInternalIp && (
        <Link id="idLogout" to={"logout"}>
          Logout
        </Link>
      )}
      <Divider />
      <Theme>
        <ImgWrapper>
          <div
            id="btnTemaLigth"
            role="button"
            tabIndex="0"
            aria-pressed={pageLight !== "dark" ? true : false}
            aria-label="tema light"
            onClick={() => setPageLight("light")}
            onKeyDown={(e) => HandleSwitchTheme(e)}
          >
            {pageLight !== "dark" ? (
              <img src="/img/light-selected.svg" />
            ) : (
              <img src="/img/light-light.svg" />
            )}
          </div>
          <div
            id="btnTemaDark"
            tabIndex="0"
            role="button"
            aria-pressed={pageLight === "dark" ? true : false}
            aria-label="tema dark"
            onClick={() => setPageLight("dark")}
            onKeyDown={(e) => HandleSwitchTheme(e)}
          >
            {pageLight === "dark" ? (
              <img src="/img/dark-selected.svg" />
            ) : (
              <img src="/img/dark-light.svg" />
            )}
          </div>
        </ImgWrapper>
      </Theme>
      <ThemeRecap>
        <div className="type">tema</div>
        <div className="title">{pageLight}</div>
      </ThemeRecap>
    </Container>
  );
}

export default React.memo(AvatarDropDown);
