import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  selectEventType,
  selectEventDate,
  selectEventCodeList,
  selectAdditionalEventParams,
} from "../../../../../../../pages/EventDetails/selectors";
import linkTarget from "../../../../../../../utils/linkTarget";
import CountDown from "../countDown/CountDown";
import SetQueryStringPath from "../../../../../../../utils/setQueryStringPath";
//style
import {
  Container,
  InfoRecapContainer,
  Label,
  Content,
  RedButton,
  GreyButton,
} from "./style";
import { Link } from "react-router-dom";

const CLOSED_SUBSCRIBES_USER_REGISTERED = 0;
const OPEN_SUBSCRIBES_USER_NOT_REGISTERED_AVAILABLE_PLACES = 2;
const OPEN_SUBSCRIBES_USER_REGISTERED = 4;

function AsideBox({ elementData }) {
  const eventType = useSelector(selectEventType());
  const eventCodeList = useSelector(selectEventCodeList());
  const eventName = useSelector(selectAdditionalEventParams())
    ?.find((el) => el.includes("event="))
    ?.split("=")?.[1];
  const dueDate = useSelector(selectEventDate());
  const isScheduled = eventType === "scheduled" ? true : false;
  const isInternal = eventType === "internal-events" ? true : false;

  const setEventFormPage = (eventName) => {
    switch (eventName) {
      case "familyDay":
        return `/subscribe-form-family-day?path=${elementData.pathBooking}?eventCode=${eventCodeList[0]}`;
      case "finaliMondiali":
        return `/subscribe-form?path=${elementData.pathBooking}?eventCode=${eventCodeList[0]}`;
      case "giftHouse":
        return `/subscribe-form-gh?path=${elementData.pathBooking}?eventCode=${eventCodeList[0]}`;
      default:
        break;
    }
  };
  const setEventDetailPage = (eventName) => {
    switch (eventName) {
      case "familyDay":
        return `/subscription-detail-family-day?path=${elementData.linkHref}?eventCode=${eventCodeList[0]}`;
      case "finaliMondiali":
        return `/subscription-detail?path=${elementData.linkHref}?eventCode=${eventCodeList[0]}`;
      case "giftHouse":
        return `/subscription-detail-gh?path=${elementData.linkHref}?eventCode=${eventCodeList}`;
      default:
        break;
    }
  };

  const redirectToPage = (buttonValue) => {
    switch (buttonValue) {
      case OPEN_SUBSCRIBES_USER_REGISTERED:
      case OPEN_SUBSCRIBES_USER_NOT_REGISTERED_AVAILABLE_PLACES:
        return setEventFormPage(eventName);

      case CLOSED_SUBSCRIBES_USER_REGISTERED:
        return setEventDetailPage(eventName);

      default:
        break;
    }
  };
  const countDownResult = (timeStampEnd, timeLeft = null) => {
    let difference = timeStampEnd - Date.now();

    if (difference > 0) {
      var seconds = Math.floor(difference / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
      timeLeft = {
        days,
        hours,
        minutes,
        seconds,
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(countDownResult(dueDate));
  const [firstTime, setFirstTime] = useState(false);
  if (!firstTime && isScheduled && dueDate) {
    setTimeLeft(countDownResult(dueDate));
    setFirstTime(true);
  }

  useEffect(() => {
    if (isScheduled) {
      const timer = setTimeout(() => {
        setTimeLeft(countDownResult(dueDate));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft, dueDate, isScheduled]);

  const buttonLabel =
    elementData.buttonLabel ||
    elementData.labelSubscribe ||
    "Accedi / Iscriviti";

  return (
    <Container>
      <InfoRecapContainer>
        {React.Children.toArray(
          elementData?.items?.map((el) => {
            return (
              <>
                {el?.title && <Label>{el?.title}</Label>}
                <Content
                  dangerouslySetInnerHTML={{ __html: el?.text }}
                ></Content>
              </>
            );
          })
        )}
      </InfoRecapContainer>
      {isInternal ? (
        //inserito Link essendo pagina interna, questo evita chiamate non necessarie (già in cache)
        <Link
          aria-label={buttonLabel}
          to={`/generic-subscription-form?path=${elementData?.pathBooking}`}
        >
          <RedButton>{buttonLabel}</RedButton>
        </Link>
      ) : // <a
      //   aria-label={buttonLabel}
      //   href={`generic-subscription-form?path=${elementData?.pathBooking}`}
      //   target={linkTarget(elementData?.pathBooking)}
      // >
      //   <RedButton>{buttonLabel}</RedButton>
      // </a>
      isScheduled && timeLeft ? (
        <CountDown data={elementData} timeLeft={timeLeft} />
      ) : isScheduled && eventCodeList?.length > 0 ? (
        elementData?.buttonValue >= 0 ? (
          // <a
          //   aria-label={buttonLabel}
          //   href={redirectToPage(elementData?.buttonValue)}
          //   target={linkTarget(elementData?.pathBooking)}
          // >
          //   <RedButton>{buttonLabel}</RedButton>
          // </a>

          //inserito Link essendo pagina interna, questo evita chiamate non necessarie (già in cache)
          <Link
            aria-label={buttonLabel}
            to={redirectToPage(elementData?.buttonValue)}
          >
            <RedButton>{buttonLabel}</RedButton>
          </Link>
        ) : (
          <GreyButton>{buttonLabel}</GreyButton>
        )
      ) : (
        <a
          aria-label={buttonLabel}
          href={SetQueryStringPath(elementData?.pathBooking)}
          target={linkTarget(elementData?.pathBooking)}
        >
          <RedButton>{buttonLabel}</RedButton>
        </a>
      )}
    </Container>
  );
}

AsideBox.propTypes = {
  elementData: PropTypes.shape({
    pathBooking: PropTypes.string,
    items: PropTypes.array,
    buttonLabel: PropTypes.string,
    countDownLabel: PropTypes.string,
    dayLabel: PropTypes.string,
    hoursLabel: PropTypes.string,
    minutesLabel: PropTypes.string,
    secondsLabel: PropTypes.string,
  }),
};

export default AsideBox;
