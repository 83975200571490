import PropTypes from "prop-types";
import { DocContainer, DocContainerTitle } from "./style";

import NewsDoc from "./newsDoc/NewsDoc";

function NewsDocContainer({ data }) {
  const NEWS_DOC_KEY = "NEWS-DOCUMENT-";
  return (
    <DocContainer className="doc-container">
      <DocContainerTitle className="doc-container__title">{data.title}</DocContainerTitle>
      {data.assets.map((currentDoc, idx) => (
        <NewsDoc key={`${NEWS_DOC_KEY}-${idx}`} data={currentDoc} />
      ))}
    </DocContainer>
  );
}

NewsDocContainer.propTypes = {
  data: PropTypes.exact({
    title:PropTypes.string,
    assets: PropTypes.array,
    position: PropTypes.string,
  }),
};

export default NewsDocContainer;
