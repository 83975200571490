import React from "react";
import PropTypes from "prop-types";

const TitleFormazioneManageriale = ({ data }) => {
  switch (data?.type) {
    case "h2":
      return <h2>{data.jcrTitle}</h2>;
    case "h3":
      return <h3>{data.jcrTitle}</h3>;
    default:
      return <h1>{data.jcrTitle}</h1>;
  }
};

TitleFormazioneManageriale.propTypes = {
  data: PropTypes.exact({
    jcrTitle: PropTypes.string.isRequired,
    type: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default TitleFormazioneManageriale;
