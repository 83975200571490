/* eslint-disable react/style-prop-object */
export default function NewsIconRed() {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8887 27.3162C39.3108 23.465 39.3784 19.2443 38.0802 15.3495C37.9202 14.8696 37.7503 14.3895 37.5503 13.9296C38.1481 13.586 38.6908 13.1545 39.1603 12.6496C39.3803 13.1496 39.5803 13.6396 39.7703 14.1496C41.3649 18.4996 41.4109 23.2666 39.9006 27.6467C38.3903 32.0268 35.4159 35.7522 31.479 38.1949C27.542 40.6376 22.8833 41.6483 18.288 41.0564C13.6928 40.4646 9.44214 38.3064 6.25263 34.9459C3.06312 31.5853 1.12983 27.2278 0.77866 22.6079C0.427489 17.9881 1.67992 13.3884 4.32482 9.58432C6.96972 5.78024 10.8453 3.00442 15.2983 1.72479C19.7513 0.445156 24.5093 0.739975 28.7702 2.55955C28.944 2.64452 29.1298 2.73248 29.3194 2.82228C29.6207 2.96495 29.9318 3.11225 30.2202 3.25954C30.7102 3.51953 31.1801 3.79956 31.6401 4.08955C31.0572 4.46934 30.5599 4.96661 30.1801 5.54955C29.7468 5.28955 29.3068 5.04955 28.8601 4.82955C28.4101 4.60957 27.9502 4.40953 27.4702 4.21955C23.642 2.73667 19.4228 2.60234 15.5081 3.83868C11.5933 5.07502 8.21656 7.60825 5.93442 11.0209C3.65228 14.4335 2.60093 18.5218 2.95366 22.6119C3.30638 26.7021 5.04213 30.5501 7.87481 33.5216C10.7075 36.4931 14.4681 38.4108 18.5367 38.9587C22.6053 39.5066 26.7392 38.6519 30.257 36.5355C33.7749 34.4192 36.4666 31.1674 37.8887 27.3162ZM31.6401 4.08955C34.45 5.85467 36.7766 8.29119 38.4102 11.1796C38.6835 11.6595 38.9336 12.1496 39.1603 12.6496C38.6908 13.1545 38.1481 13.586 37.5503 13.9296C37.3503 13.4596 37.1301 13.0096 36.8901 12.5596C37.4864 12.2133 38.0028 11.7452 38.4057 11.1858C38.0017 11.7416 37.4854 12.2062 36.8902 12.5496C35.3359 9.64636 33.015 7.22517 30.1801 5.54955C30.5599 4.96661 31.0572 4.46934 31.6401 4.08955Z"
        fill="#DA291C"
      />
      <path
        d="M38.0802 15.3495C39.3784 19.2443 39.3108 23.465 37.8887 27.3162C36.4666 31.1674 33.7749 34.4192 30.257 36.5355C26.7392 38.6519 22.6053 39.5066 18.5367 38.9587C14.4681 38.4108 10.7075 36.4931 7.87481 33.5216C5.04213 30.5501 3.30638 26.7021 2.95366 22.6119C2.60093 18.5218 3.65228 14.4335 5.93442 11.0209C8.21656 7.60825 11.5933 5.07502 15.5081 3.83868C19.4228 2.60234 23.642 2.73667 27.4702 4.21955C27.8342 3.61527 28.2708 3.0578 28.7702 2.55955C24.5093 0.739975 19.7513 0.445156 15.2983 1.72479C10.8453 3.00442 6.96972 5.78024 4.32482 9.58432C1.67992 13.3884 0.427489 17.9881 0.77866 22.6079C1.12983 27.2278 3.06312 31.5853 6.25263 34.9459C9.44214 38.3064 13.6928 40.4646 18.288 41.0564C22.8833 41.6483 27.542 40.6376 31.479 38.1949C35.4159 35.7522 38.3903 32.0268 39.9006 27.6467C41.4109 23.2666 41.3649 18.4997 39.7702 14.1495C39.2607 14.6204 38.6927 15.0237 38.0802 15.3495Z"
        fill="url(#paint0_radial_14153_43702)"
        fillOpacity="0.5"
        style={{ mixBlendMode: "overlay" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9993 14.9993V26.9993H26.9993V14.9993H14.9993ZM13.666 14.7327C13.666 14.1436 14.1436 13.666 14.7327 13.666H27.266C27.8552 13.666 28.3327 14.1436 28.3327 14.7327V27.266C28.3327 27.8551 27.8551 28.3327 27.266 28.3327H14.7327C14.1436 28.3327 13.666 27.8552 13.666 27.266V14.7327ZM16.3327 16.9993C16.3327 16.6312 16.6312 16.3327 16.9993 16.3327H24.9993C25.3675 16.3327 25.666 16.6312 25.666 16.9993C25.666 17.3675 25.3675 17.666 24.9993 17.666H16.9993C16.6312 17.666 16.3327 17.3675 16.3327 16.9993ZM16.3327 19.666C16.3327 19.2978 16.6312 18.9993 16.9993 18.9993H24.9993C25.3675 18.9993 25.666 19.2978 25.666 19.666C25.666 20.0342 25.3675 20.3327 24.9993 20.3327H16.9993C16.6312 20.3327 16.3327 20.0342 16.3327 19.666ZM16.3327 22.3327C16.3327 21.9645 16.6312 21.666 16.9993 21.666H24.9993C25.3675 21.666 25.666 21.9645 25.666 22.3327C25.666 22.7009 25.3675 22.9993 24.9993 22.9993H16.9993C16.6312 22.9993 16.3327 22.7009 16.3327 22.3327ZM16.3327 24.9993C16.3327 24.6312 16.6312 24.3327 16.9993 24.3327H24.9993C25.3675 24.3327 25.666 24.6312 25.666 24.9993C25.666 25.3675 25.3675 25.666 24.9993 25.666H16.9993C16.6312 25.666 16.3327 25.3675 16.3327 24.9993Z"
        fill="var(--main-color)"
      />
    </svg>
  );
}
