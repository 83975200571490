import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "../Welfare/welfareSlice";

const welfareState = (state) => state.welfare || initialState;

export const selectWelfareCards = () =>
  createSelector(welfareState, (state) => {
    const result = state.components.find(
      (el) => el.resourceType === "fi-welfare/fi-welfare-catalogue"
    );
    return result?.data?.results;
  });
