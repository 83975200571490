import React, { useEffect, useRef, useState, useCallback } from "react";

import ProgressBars from "./progressBars/ProgressBars";
import VideoControllers from "./videoControllers/VideoControllers";

import { VideoPlayerWrapper } from "./style";

const VideoPlayer = ({ style, url }) => {
  const { margin, top, width, height } = style;
  const videoElement = useRef(null);

  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    volume: 0.5,
    isMuted: false,
    isVolumeShown: false,
  });

  const handleOnTimeUpdate = useCallback(() => {
    const progress =
      (videoElement.current.currentTime / videoElement.current.duration) * 100;
    setPlayerState({
      ...playerState,
      progress,
    });
  }, [playerState]);

  const handleOnProgress = useCallback(() => {
    const buff =
      (videoElement.current.buffered.end(0) / videoElement.current.duration) *
      100;
    setPlayerState({
      ...playerState,
      buff,
    });
  }, [playerState]);

  const handleVideoProgress = useCallback(
    (manualChange) => {
      videoElement.current.currentTime =
        (videoElement.current.duration / 100) * manualChange;
      setPlayerState({
        ...playerState,
        progress: manualChange,
      });
    },
    [playerState]
  );

  useEffect(() => {
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  return (
    <VideoPlayerWrapper width={width} height={height}>
      <video
        ref={videoElement}
        controls={false}
        onTimeUpdate={handleOnTimeUpdate}
        onProgress={handleOnProgress}
        width="100%"
        height="100%"
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <VideoControllers
        videoMargin={margin}
        videoTop={top}
        videoHeight={height}
        videoWidth={width}
        setPlayerState={setPlayerState}
        playerState={playerState}
      ></VideoControllers>
      <ProgressBars
        topBars={(top * height) / 100}
        marginBars={margin}
        playerState={playerState}
        handleVideoProgress={handleVideoProgress}
      />
    </VideoPlayerWrapper>
  );
};

export default React.memo(VideoPlayer);
