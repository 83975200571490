import styled from "styled-components";

export const FavoriteDishDiv = styled.div`
  border-left: 3px solid rgb(218, 41, 28);
  padding: 30px 40px 30px 0px;
  background: var(--alternative-bg-color);
  column-gap: 32px;
  position: relative;
  margin-bottom: 32px;
  @media screen and (max-width: 1279px) {
    display: block;
    padding: 30px 40px;
  }
`;

export const FavoriteDishNumber = styled.div`
  color: rgb(218, 41, 28);
  font: 40px ferrari-sans-medium;
  text-align: center;
  min-width: 140px;
  max-width: 140px;
  flex-grow: 1;
  @media screen and (max-width: 1279px) {
    font-size: 26px;
    margin-bottom: 24px;
    text-align: left;
  }
`;

export const FavoriteDishWrapper = styled.div`
  flex-grow: 2;
`;

export const FavoriteDishType = styled.div`
  font-size: 11px;
  margin-bottom: 10px;
`;

export const FavoriteDishName = styled.div`
  font-size: 18px;
`;

export const FavoriteDishLikes = styled.div`
  @media screen and (max-width: 1279px) {
    position: absolute;
    top: 37px;
    right: 32px;
  }
`;

export const FavoriteDishLikesNumber = styled.span`
  padding-left: 5px;
`;
