import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";
import { FETCH_COMPONENTS } from "./constants";

import { SimpleEventDetailActions } from "./simpleEventsDetailSlice";
import axios from "axios";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
      // const result = yield call(() =>
      // axios.get("/mocks/mock-simple-event-iscrizione-effettuata.json")
    // );
    yield put(SimpleEventDetailActions.setComponents(result.data.data));
  } catch (e) {
    console.error(e);
    yield put(SimpleEventDetailActions.setError(e));
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
}
