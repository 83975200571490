import { Text } from "./style";

function GenericText({ data }) {
  function html() {
    return { __html: data.text };
  }

  return <Text dangerouslySetInnerHTML={html()}></Text>;
}

export default GenericText;
