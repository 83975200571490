import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  status: null,
  showDialog: false,
};

const webinarSlice = createSlice({
  name: "webinar",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
  },
});

export const webinarActions = webinarSlice.actions;
export default {
  name: webinarSlice.name,
  reducer: webinarSlice.reducer,
};
