import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  DndContext,
  PointerSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCenter,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
// components
import ToolBox from "../toolBox/ToolBox";
// store
import { toolsActions } from "../../../../../pages/Strumenti/toolsSlice";
// utils
import useMediaQuery from "../../../../../hooks/useMediaQuery";
//actions to call for ext services (MW)
import { SaveFavToolsOrder } from "../../../../../pages/Strumenti/actions";
// style
import { Container, ToolsGrid, Divider, CtaButtons } from "./style";

function DialogContent() {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.activeToken);
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const { language } = useSelector((state) => state.coreComponents);
  const favToolsMap = useSelector((state) => state.tools.favToolsMap);
  const { toolsDialogComponents } = useSelector((state) => state.home);
  const [hideDnD, setHideDnD] = useState(true);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = favToolsMap.map((item) => item.id).indexOf(active.id);
      const newIndex = favToolsMap.map((item) => item.id).indexOf(over.id);
      const sortedfavToolsMap = arrayMove(favToolsMap, oldIndex, newIndex);
      dispatch(toolsActions.setFavToolsMap(sortedfavToolsMap));
    }
  };

  const handleDragOver = (event) => {
    const { active, over } = event;

    if (over == null) return;

    if (active.id !== over.id) {
      const oldIndex = favToolsMap.map((item) => item.id).indexOf(active.id);
      const newIndex = favToolsMap.map((item) => item.id).indexOf(over.id);
      const sortedfavToolsMap = arrayMove(favToolsMap, oldIndex, newIndex);
      dispatch(toolsActions.setFavToolsMap(sortedfavToolsMap));
    }
  };

  const handleModifySaveTools = () => {
    setHideDnD(!hideDnD);
    //User pressed on Save, here we dispatch the action to save the tools order into the db
    if (!hideDnD) {
      dispatch(SaveFavToolsOrder({ favToolsMap, token }));
    }
  };

  return (
    <Container>
      <h2>{toolsDialogComponents?.titleTools || "Strumenti"}</h2>
      {hideDnD ? (
        <ToolsGrid>
          {React.Children.toArray(
            favToolsMap.map((el, i) => {
              if (i === 0)
                return (
                  <ToolBox element={el} hideDnD={hideDnD} focusId={true} />
                );
              return <ToolBox element={el} hideDnD={hideDnD} />;
            })
          )}
        </ToolsGrid>
      ) : (
        <ToolsGrid>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
          >
            <SortableContext items={favToolsMap.map((item) => item.id)}>
              {React.Children.toArray(
                favToolsMap.map((el) => {
                  return <ToolBox element={el} id={el.id} />;
                })
              )}
            </SortableContext>
          </DndContext>
        </ToolsGrid>
      )}
      <Divider />
      <CtaButtons>
        <Link
          tabIndex="0"
          to={`${language}/tools`}
          onClick={() => dispatch(toolsActions.setShowToolsDialog(false))}
        >
          {toolsDialogComponents?.seeAllLabel || "Vedi tutti"}
        </Link>
        {isDesktop && (
          <span onClick={handleModifySaveTools}>
            {hideDnD
              ? toolsDialogComponents?.modifyLabel || "Modifica"
              : toolsDialogComponents?.saveLabel || "Salva"}
          </span>
        )}
      </CtaButtons>
    </Container>
  );
}

export default DialogContent;
