import React from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { globalSearchActions } from "../../../../../../pages/GlobalSearch/globalSearchSlice";
// style
import { Wrapper, DropdownSelected, List, ListItem } from "./style";

function GlobalSearchTab() {
  const dispatch = useDispatch();

  const tabType = useSelector((state) => state.globalSearch.resultType);
  const tabsTitle = useSelector((state) => state.globalSearch.titlesMap);

  const handleTabClick = (type) => {
    dispatch(globalSearchActions.setResultType(type));
  };

  const HandleTabKeyPress = (e, type) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      dispatch(globalSearchActions.setResultType(type));
    }
  };
  return (
    <Wrapper>
      <DropdownSelected>
        {/* mobile */}
        <select onChange={({ target }) => handleTabClick(target.value)}>
          {tabsTitle?.map((tab, idx) => (
            <option
              key={idx}
              value={tab.type}
            >{`${tab.title} (${tab.number})`}</option>
          ))}
        </select>
        <div>
          <img src="/img/arrow-black.svg" />
        </div>
      </DropdownSelected>
      {/* desktop */}
      <List role="tablist">
        {tabsTitle?.map((tab) => (
          <ListItem
            key={tab.type}
            className={tabType === tab.type && "selected"}
            onClick={() => handleTabClick(tab.type)}
            onKeyDown={(e) => HandleTabKeyPress(e, tab.type)}
            tabIndex="0"
            aria-label={tab.title}
            role="tab"
            aria-controls={tab.type}
          >
            {`${tab.title} (${tab.number})`}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
}

export default React.memo(GlobalSearchTab);
