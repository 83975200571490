import React, { useCallback } from "react";

import { ProgressBarsWrapper, ProgressBar } from "./style";

const ProgressBars = ({
  topBars,
  marginBars,
  playerState,
  handleVideoProgress,
}) => {
  const handleOnChange = useCallback(
    (e) => {
      const { clientX, target } = e;
      const { offsetWidth, parentElement, offsetLeft } = target;
      const { offsetLeft: offsetLeftParent } = parentElement;
      const perc =
        ((clientX - offsetLeft - offsetLeftParent) / offsetWidth) * 100;

      const percChecked = perc < 1 ? 1 : perc > 100 ? 100 : perc;

      handleVideoProgress(percChecked);
    },
    [handleVideoProgress]
  );

  return (
    <ProgressBarsWrapper topBars={topBars} marginBars={marginBars}>
      <ProgressBar
        currentWidth={100}
        backgroundColor="lightgrey"
        zIndex={0}
      ></ProgressBar>
      <ProgressBar
        currentWidth={playerState.buff}
        backgroundColor="grey"
        zIndex={1}
        top="-10px"
      ></ProgressBar>
      <ProgressBar
        currentWidth={playerState.progress}
        backgroundColor="red"
        zIndex={2}
        top="-20px"
      ></ProgressBar>
      <ProgressBar
        currentWidth={100}
        backgroundColor="rgba(0,0,0,0)"
        zIndex={3}
        top="-30px"
        onClick={handleOnChange}
      ></ProgressBar>
    </ProgressBarsWrapper>
  );
};

export default React.memo(ProgressBars);
