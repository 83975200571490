import { FETCH_COMPONENTS, SET_USER_REGISTRATION } from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const SetUserRegistration = (payload) => ({
  type: SET_USER_REGISTRATION,
  payload,
});
