import styled from "styled-components";

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(239, 237, 237, 0.4);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 16px;
  width: 16px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  margin-left: 10px;
  box-sizing: content-box;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: ${(props) => props.inset || "-3px"};
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: ${(props) => props.strokeWidth || "1.5px"};
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
    &.paused {
      animation-play-state: paused;
    }
  }
  ${(props) =>
    props.animationCountDown
      ? `circle {
    animation-name: animation;
    animation-fill-mode: forwards;
    animation-duration: ${props.animationCountDownTime};
  }`
      : `&:hover circle {
    stroke-dashoffset: 0;
  }`}
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  @keyframes animation {
    from {
      stroke-dashoffset: 70;
    }

    to {
      stroke-dashoffset: 0;
    }
  }
`;
