import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./action";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";
import LayoutFormContraffazione from "../../components/UI/formContraffazione/LayoutFormContraffazione";

export default function FormContraffazione({ path }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const components = useSelector(
    (state) => state.FormContraffazioni.components
  );

  useMemo(() => {
    dispatch(fetchComponents({ path, token }));
  }, [dispatch, path]);

  const formComponent = components?.components?.find(
    (v) => v.resourceType === "fi-form-anti-counterfeiting"
  );

  const heroData = components?.components?.find(
    (v) => v.resourceType === "fi-events/fi-events-hero-subscription"
  );

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);

  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("FormContraffazioni");

  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        formComponent?.data && (
          <LayoutFormContraffazione
            heroData={heroData?.data}
            data={formComponent?.data}
          />
        )
      )}
    </>
  );
}
