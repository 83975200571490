import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  initEventInfo,
  setComponents,
  setError,
  setSubscribeError,
} from "./FormIscrizioneGiftHouseSlice";
import { FETCH_COMPONENTS, SET_USER_REGISTRATION } from "./constants";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-iscrizione-gift-house.json")
    // );
    yield put(setComponents(result.data.data));
    const eventInfoData = result?.data?.data?.components?.find(
      (el) => el.resourceType === "fi-events/fi-event-subscription-giftHouse"
    );

    if (eventInfoData?.data?.error403) {
      yield put(setError({ status: 403, message: "Unauthorized" }));
      return;
    }

    yield put(initEventInfo(eventInfoData));
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export function* SetUserRegistration({ payload }) {
  try {
    const token = payload.token;
    document.body.classList.add("loading");

    yield axiosCallPost(
      `/${payload.lang}/news-and-events/events/subscribe`,
      payload.responseBody,
      token,
      false,
      false
    );
    window.location.replace(payload.path);
  } catch (e) {
    yield put(
      setSubscribeError({ status: e.response.status, data: e.response.data })
    );
  } finally {
    document.body.classList.remove("loading");
  }
}

export default function* FormAnnuncioSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchComponentsWorker);
  yield takeLatest(SET_USER_REGISTRATION, SetUserRegistration);
}
