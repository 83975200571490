import React from "react";
import { Card } from "../Card/Card";
import { Row } from "./style";

export function CardsRow({ data }) {
  return (
    <Row>
      {React.Children.toArray(
        data?.cards?.map((card) => {
          return <Card data={card} />;
        })
      )}
    </Row>
  );
}
