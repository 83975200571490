import styled from "styled-components";

const footerHorizontal = (isHorizontalFooter) => {
  if (isHorizontalFooter) {
    return `
    @media screen and (max-width: 1279px) {
      flex-direction: row;
      width: 100%;
      li {
        text-align: center;
      }
    }
    @media screen and (max-width: 450px) {
      gap: 15px;
    }
    @media screen and (max-width: 350px) {
      font-size: 10px;
    }`;
  } else {
    return `
    @media screen and (max-width: 1279px) {
      flex-direction: column;
      width: unset;
      li {
        text-align: start;
      }
    }`;
  }
};

export const MainFooter = styled.footer`
  background: rgb(48, 48, 48);
  padding: 30px;
  color: rgb(255, 255, 255);
  font-size: 11px;
  margin-top: auto;
  z-index: 1;
  ${(props) =>
    props.isFooterFixed
      ? `position:fixed;
    bottom:0;`
      : ``}
  display:${(props) =>
    props.isFooterFixedOpened || !props.isFooterFixed ? `flex` : `none`};
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  .close-sticky-footer {
    display: ${(props) => (props.isFooterFixedOpened ? `flex` : `none`)};
    padding: 0 0 20px;
    align-items: center;
    justify-content: center;
  }
  .close-sticky-footer img {
    transform: rotate(45deg);
    cursor: pointer;
  }
`;

export const UnorderedList = styled.ul`
  display: flex;
  gap: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
  ${(props) => footerHorizontal(props.isHorizontalFooter)}
`;

export const ListItem = styled.li`
  a {
    color: rgb(255, 255, 255);
  }
`;

export const NavBar = styled.nav`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1279px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
