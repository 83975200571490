import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 270px;
  @media (max-width: 1279px) {
    height: auto;
  }
  opacity: 0;
  transition: opacity 1s;
  a {
    display: flex;
    align-items: center;
    color: var(--cta-color);
    text-transform: uppercase;
    font-size: 12px;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
`;

export const CardCTA = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  span {
    letter-spacing: 1px;
    line-height: 15px;
    font-size: 12px;
    font-weight: 400;
    font-family: ferrari-sans-regular;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(239, 237, 237);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
`;
