import {
  Wrapper,
  Overlay,
  BoxContainer,
  ConfirmButton,
  CloseButton,
} from "./style";

import { useSelector } from "react-redux";
import {
  selectDialogConfirm,
  selectDialogDelete,
} from "../../../../../pages/MyPage/selectors";

export function DialogNote({ confirmCallBack, closeCallBack }) {
  const confirmData = useSelector(selectDialogConfirm());
  const deleteData = useSelector(selectDialogDelete());
  const showCannotDeleteDialog = useSelector(
    (state) => state.myPage.showCannotDeleteDialog
  );
  const data = showCannotDeleteDialog ? deleteData : confirmData;

  return (
    <Wrapper>
      <Overlay />
      <CloseButton onClick={closeCallBack}>
        <img src="/img/close-light.svg" width="40" height="40" />
      </CloseButton>
      <BoxContainer>
        <h2>{data?.title}</h2>
        <div>{data?.description}</div>
        <ConfirmButton onClick={(e) => confirmCallBack(e)}>
          {data?.buttonLabel}
        </ConfirmButton>
      </BoxContainer>
    </Wrapper>
  );
}
