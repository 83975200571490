import { Button, Container, Text1, Text2 } from "./style";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { useEffect } from "react";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";
function GenericError({ data, isPublicPage }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);

  !isPublicPage &&
    ChangeTitleDoc(subjectCode, aggregatedDirection, "Page Error", "", true);
  return (
    <Container>
      <Text1>{data.errorCode}</Text1>
      <Text2>{data.errorMessage}</Text2>
      {data.btnTxt && (
        <Button>
          <Link to={"/"}>{data.btnTxt}</Link>
        </Button>
      )}
    </Container>
  );
}

export default GenericError;
