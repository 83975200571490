import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// actions
import { globalSearchActions } from "../../../../../../../pages/GlobalSearch/globalSearchSlice";
// style
import { Wrapper, Title, Description } from "./style";

function CardResultPage({ data, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToPath = () => {
    navigate(`${data?.linkHref}`);
    dispatch(globalSearchActions.resetState(false));
  };

  const HandleKeySearchItemPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        navigateToPath();
        break;
    }
  };
  return (
    <Wrapper
      tabIndex="0"
      id={id}
      onClick={navigateToPath}
      onKeyDown={(e) => HandleKeySearchItemPressed(e)}
    >
      <Title>{data?.title}</Title>
      <Description>{data?.description}</Description>
    </Wrapper>
  );
}

export default React.memo(CardResultPage);
