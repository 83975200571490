import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1280px) {
    gap: 200px;
    flex-direction: row;
  }
`;

export const Container = styled.div`
  padding-bottom: 90px;
  width: 1216px;
  margin: auto;
  @media screen and (max-width: 1279px) {
    width: 90%;
    margin: auto;
  }
`;

export const ImgHeader = styled.img`
  margin-top: -92px;
  position: relative;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  @media screen and (max-width: 1279px) {
    margin-top: 0;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  line-height: 43px;
  margin: 96px 0px 96px;
  @media screen and (max-width: 1279px) {
    margin: 80px auto 24px;
    font-size: 22px;
  }
`;

export const ContainerAccordion = styled.div`
  width: 100%;
  .accordion {
    padding-bottom: 38px;
    border-bottom: 1px solid rgb(212, 210, 210);
  }
`;
