import { FETCH_ACTIVE_TOKEN, FETCH_IS_USER_ACTIVE } from "./constants";

export const fetchActiveToken = (payload) => ({
  type: FETCH_ACTIVE_TOKEN,
  payload,
});

export const fetchisUserActive = (payload) => ({
  type: FETCH_IS_USER_ACTIVE,
  payload,
});
