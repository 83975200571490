// hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchRubricaComponents } from "./actions";
import { selectTabIndex } from "./selectors";
//components
import LayoutRubrica from "../../components/UI/rubrica/LayoutRubrica";
import Title from "../../components/UI/rubrica/title/Title";
import Search from "../../components/UI/rubrica/search/Search";
import RubricaTabs from "../../components/UI/rubrica/rubricaTabs/RubricaTabs";
import ContactsList from "../../components/UI/rubrica/contactsList/ContactsList";
import UsefulContactsList from "../../components/UI/rubrica/usefulContactsList/UsefulContactsList";
import SearchResultContactsList from "../../components/UI/rubrica/SearchResultContactsList/SearchResultContactsList";

import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function Rubrica({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const showSearchResult = useSelector(
    (state) => state.rubrica.showSearchResult
  );
  const tabIndex = useSelector(selectTabIndex());
  const tabsComponents = useSelector((state) => state.rubrica.tabsArray);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const { status, errorData } = GetStatusAndErrorData("rubrica");

  useMemo(() => {
    path && token && dispatch(fetchRubricaComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(true));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 && <GenericError data={errorData} />}
      {status === 200 && (
        <LayoutRubrica>
          <Title />
          <Search />
          {showSearchResult && <SearchResultContactsList />}
          {!showSearchResult && <RubricaTabs />}
          {!showSearchResult && tabIndex === 1 && <ContactsList />}
          {!showSearchResult && tabIndex === 2 && (
            <UsefulContactsList components={tabsComponents[1]?.components} />
          )}
          {!showSearchResult && tabIndex === 3 && (
            <UsefulContactsList components={tabsComponents[2]?.components} />
          )}
          {!showSearchResult && tabIndex === 4 && (
            <UsefulContactsList components={tabsComponents[3]?.components} />
          )}
        </LayoutRubrica>
      )}
    </>
  );
}

export default Rubrica;
