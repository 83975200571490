export default function RightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="var(--main-color)"
        fillRule="evenodd"
        d="M5 20l9.085-8.066A2.495 2.495 0 0015 10c0-.78-.357-1.475-.915-1.934L5 0l5 10-5 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
