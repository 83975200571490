import styled from "styled-components";

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  overflow: auto;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
