import styled from "styled-components";

export const SelectContainer = styled.div`
  gap: 32px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1279px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const SelectItem = styled.div`
  position: relative;
  display: block;
  min-width: 260px;
  @media screen and (max-width: 1279px) {
    width: 100%;
  }
`;
