import styled from "styled-components";

export const DetailWrapper = styled.div`
  background: var(--alternative-bg-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  margin: 20px 0px;

  @media (max-width: 1279px) {
    margin: 20px 10px;
  }
`;
export const DetailContainerData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-size: 13px;
    color: #181818;
    line-height: 20px;
    color: var(--main-color);
  }
`;

export const DetailDataLabel = styled.div`
  font-size: 11px;
  color: #707070;
  margin: 0px;
  letter-spacing: 1px;
  line-height: 20px;
`;

export const DetailState = styled.p`
  ${(props) =>
    !props.success ? "color: #ff0000" : "color: var(--main-color)"}!important;
`;
