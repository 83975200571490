import ContactCard from "../../../rubrica/contactsList/contactCard/contactCard";
import { offerDetailActions } from "../../../../../pages/OfferDetail/offerDetailSlice";
import { useDispatch } from "react-redux";
import { Wrapper, Overlay, CloseButton, Content } from "./style";
function ContactModal({ data }) {
  const dispatch = useDispatch();
  return (
    <Wrapper data-cs-mask>
      <Overlay></Overlay>
      <Content>
        <CloseButton>
          <img
            onClick={() => dispatch(offerDetailActions.setContactModal(false))}
            src="/img/close-light.svg"
            width="40"
            height="40"
          />
        </CloseButton>
        <ContactCard data={data} showDropDownArrow={false} />
      </Content>
    </Wrapper>
  );
}

export default ContactModal;
