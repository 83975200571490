import styled from "styled-components";

export const ContactWrapper = styled.div`
  grid-area: contact / contact / contact / contact;
  > div {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
  }
`;

export const ContactCard = styled.div`
  margin-top: 2em;
  display: flex;
  column-gap: 32px;
  align-content: center;
  align-items: center;
  max-width: 100%;
`;

export const ContactCardInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  inline-size: 170px;
  overflow-wrap: break-word;
  div {
    padding: 3px;
  }
  ${(props) =>
    props.cta &&
    `
    color: rgb(48, 48, 48);
    text-transform: uppercase;
    font-size: 12px;
    width: 50px;
    color: rgb(112, 112, 112);
  `}
  ${(props) =>
    props.small &&
    `
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1px;
  `}
`;
