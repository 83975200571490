import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// actions
import { coreComponentsActions } from "../../../utils/getCoreComponents/coreComponentsSlice";
// components
import { COMPONENTS_MAPPING } from "../../../App";
import LanguageDropDown from "./languageDropDown/languageDropDown";
// style
import { MainFooter, UnorderedList, ListItem, NavBar } from "./style";

function Footer() {
  const {
    isFooterFixed,
    isFooterFixedOpened,
    hideNavAndFooter,
    isHorizontalFooter,
  } = useSelector((state) => state.coreComponents);
  const footerLinks = useSelector(
    (state) => state.coreComponents?.components?.footer
  );
  const dispatch = useDispatch();
  return (
    <>
      {!hideNavAndFooter && (
        <MainFooter
          isFooterFixed={isFooterFixed}
          isFooterFixedOpened={isFooterFixedOpened}
        >
          <div className="close-sticky-footer">
            <img
              src="/img/show-footer-white.svg"
              onClick={() =>
                dispatch(coreComponentsActions.toggleIsFooterFixedOpened())
              }
            />
          </div>
          <NavBar>
            <UnorderedList isHorizontalFooter={isHorizontalFooter}>
              {React.Children.toArray(
                footerLinks.map((v) => {
                  const ReactGenericComponent = COMPONENTS_MAPPING[v.template];
                  return (
                    <>
                      {ReactGenericComponent && (
                        <ListItem>
                          <Link
                            to={v.path}
                            component={<ReactGenericComponent path={v.path} />}
                          >
                            {v.title}
                          </Link>
                        </ListItem>
                      )}
                    </>
                  );
                })
              )}
            </UnorderedList>
            <LanguageDropDown />
          </NavBar>
        </MainFooter>
      )}
    </>
  );
}

export default React.memo(Footer);
