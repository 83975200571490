import {
  Container,
  TitleForm,
  GenericTitle,
  RecapContainer,
  Divider,
  HeaderWrapper,
} from "./style";
import DetailInfo from "./detailsInfo/DetailInfo";
import { ButtonsCta } from "./buttonsCta/buttonsCta";

export function LayoutSimpleEventDetail({
  data,
  buttonsData,
  heroData,
  eventCode,
}) {
  const pascalName =
    data?.subscription?.name?.charAt(0).toUpperCase() +
    data?.subscription?.name?.slice(1).toLowerCase();
  const pascalLastName =
    data?.subscription?.lastname?.charAt(0).toUpperCase() +
    data?.subscription?.lastname?.slice(1).toLowerCase();

  function generateHtml(argument) {
    return { __html: argument };
  }

  return (
    <>
      {heroData && (
        <HeaderWrapper>
          <img src={heroData?.fileReference} />
        </HeaderWrapper>
      )}
      <Container>
        <GenericTitle>
          <h5>{data?.title}</h5>
        </GenericTitle>
        <RecapContainer>
          <GenericTitle>
            <p
              dangerouslySetInnerHTML={generateHtml(
                `${pascalName} ${pascalLastName} ${data?.description}`
              )}
            />
          </GenericTitle>
        </RecapContainer>
        <Divider />
        <RecapContainer>
          <TitleForm>{data?.detailTitle}</TitleForm>
          <DetailInfo data={data} eventCode={eventCode} />
        </RecapContainer>
        <ButtonsCta data={buttonsData} />
      </Container>
    </>
  );
}
