import { useDispatch, useSelector } from "react-redux";
// actions
import { myPageActions } from "../../../../pages/MyPage/myPageSlice";
import {
  selectTabTitlesFromTabsArray,
  selectTabIndex,
} from "../../../../pages/MyPage/selectors";

// style
import { Wrapper, List, ListItem } from "./style";
import { SelectWrapperWrapper } from "../../mensa/cafeteria-tabs/style";
import {
  Select,
  SelectWrapper,
  WrapperImg,
} from "../../strumenti/toolBoxList/style";
import BottomIcon from "../../../assets/bottom";
import React from "react";

function MyPageTabs() {
  const dispatch = useDispatch();

  const tabIndex = useSelector(selectTabIndex());
  const tabsTitle = useSelector(selectTabTitlesFromTabsArray());

  const handleTabClick = (index) => {
    dispatch(myPageActions.setTabIndex(index));
  };

  return (
    <Wrapper>
      <SelectWrapperWrapper>
        <SelectWrapper>
          <Select
            value={tabsTitle?.[tabIndex - 1]?.id}
            onChange={(e) => handleTabClick(+e.target.value)}
            noborder={true}
          >
            {React.Children.toArray(
              tabsTitle?.map((current) => {
                return (
                  current.title && (
                    <option value={current.id}>{current.title}</option>
                  )
                );
              })
            )}
          </Select>
          <WrapperImg>
            <BottomIcon></BottomIcon>
          </WrapperImg>
        </SelectWrapper>
      </SelectWrapperWrapper>
      <List>
        {React.Children.toArray(
          tabsTitle?.map((current) => {
            return (
              current.title && (
                <ListItem
                  className={tabIndex === current.id && "selected"}
                  onClick={() => handleTabClick(current.id)}
                >
                  {current.title}
                </ListItem>
              )
            );
          })
        )}
      </List>
    </Wrapper>
  );
}

export default MyPageTabs;
