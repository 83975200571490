import styled from "styled-components";

export const HeroWrapper = styled.div`
  margin-top: -92px;
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 100%;

  &::before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &::after {
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    opacity: 0.7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    left: 0px;
    top: 0px;
    width: 100%;
    height: 205px;
    content: "";
  }
  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 96px;
  }
  @media screen and (max-width: 1279px) {
    img {
      object-fit: cover;
      margin: auto;
      min-height: 320px;
    }
  }
`;

export const HeroInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  text-align: center;
`;

export const HeroTitle = styled.h1`
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
`;

export const HeroSubTitle = styled.div`
  color: rgb(255, 255, 255);
  text-align: center;
`;
