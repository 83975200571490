import styled from "styled-components";

export const Container = styled.section`
  width: 600px;
  margin: 0 auto 96px;
  ${(props) =>
    props.center
      ? "display: flex; flex-direction:column; align-items: center"
      : "display: block"};

  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input {
    letter-spacing: 0.5px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
  }

  @media (max-width: 1279px) {
    max-width: 800px;
    width: 80%;
    margin: 0 auto 96px;
    h2 {
      margin: 40px 0 20px;
    }
  }
`;
export const GenericTitle = styled.div`
  width: 100%;
  margin: 0px auto;
  font-size: 28px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 34px;
  padding: 0;
  h2 {
    font-size: 32px;
    line-height: 43px;
    font-weight: 400;
  }
  & a {
    color: var(--main-color);
    text-decoration: revert;
  }

  p {
    font-family: ferrari-sans-regular, sans-serif;
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 1279px) {
    width: auto;
  }
`;
export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;
  &.activeSubmit {
    color: rgb(255, 255, 255);
    background: radial-gradient(
        199.89% 50% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        349.37% 116.89% at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      rgb(218, 41, 28);
  }

  @media (max-width: 1279px) {
    max-width: 100%;
  }
`;

export const ComponentContainer = styled.div`
  h3 {
    text-transform: uppercase;
    span {
      text-transform: lowercase;
      color: #8f8f8f;
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
`;

export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 32px;
  & a {
    color: var(--main-color);
    text-decoration: revert;
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  margin: 10px 0;
  label {
    font-size: 12px;
    color: var(--carousel-dot-color);
    margin: 0px;
    ${(props) => (props.hideLabel ? "opacity:0" : "opacity:1")};
  }
  input {
    background: transparent;
    width: 100%;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-top-color: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-right-style: initial;
    border-right-color: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(143, 143, 143);
    font-size: 14px;
    padding: 7px 0px;
    padding: 10px 0px;
    color: #303030;
    :: -webkit-outer-spin-button,
    :: -webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    ::input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
  & input::placeholder {
    color: #707070;
  }
  input:not(:placeholder-shown) + label {
    opacity: 1;
  }
  input.disabled {
    border-color: none;
    border-width: 0px;
  }
`;
export const RadioButton = styled.div`
  margin-bottom: 18px;
  text-transform: uppercase;
  label {
    vertical-align: bottom;
    font-size: 12px;
  }
  input {
    vertical-align: middle;
    margin-right: 8px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    &:checked {
      outline: 0.1px solid black;
      background-color: #000;
      appearance: none;
      margin: 3px 8px 0px 5px;
      padding: 6px;
      box-shadow: inset 0 0 0 2.5px #ffff;
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #d4d2d2;
  width: 100%;
  margin-top: 35px;
`;

export const RedirectButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  z-index: 2;
  position: relative;
  inset: calc(50% - 250px) 0px 0px;

  @media (max-width: 1279px) {
    inset: calc(50% - 350px) 0px 0px;
  }
`;
