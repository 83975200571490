import { createSlice } from "@reduxjs/toolkit";

export const FORM_TEST_HMI_SCOPE = "FormTestHmi";

export const initialState = {
  components: {},
  status: null,
  requestStatus: null,
  errorMessage: "",
  showModal: false,
  showSuccessModal: false,
  deleteId: null,
  bodyPayload: {},
  savedBodyPayload: {},
  displayError: null,
};

const FormTestHmiSlice = createSlice({
  name: FORM_TEST_HMI_SCOPE,
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    setInitBodyPayload(state, action) {
      const data = action.payload;
      const savedBody = {
        Birthdate: data?.Birthdate || null,
        Phone: data?.Phone || null,
        Nationality: data?.Nationality || null,
        Lang: data?.Lang || null,
        HasChild: data?.HasChild?.toString() || null,
        YoungestChildBirthYear:
          data?.YoungestChildBirthYear?.toString() || null,
        CarBrand: data?.CarBrand || null,
        CarModel: data?.CarModel || null,
        CarModelYear: data?.CarModelYear?.toString() || null,
        AnnualKm: data?.AnnualKm?.toString() || null,
      };
      data?.Phone ? (state.requestStatus = 1) : (state.requestStatus = 0);
      state.deleteId = data?.id;
      state.bodyPayload = savedBody;
      state.savedBodyPayload = savedBody;
      state.displayError = data?.Phone ? true : null;
    },
    setBodyPayload(state, action) {
      state.bodyPayload = action.payload;
    },
    setRequestStatus(state, action) {
      state.requestStatus = action.payload;
    },
    setDisplayError(state, action) {
      state.displayError = action.payload;
    },
    setShowModal(state, action) {
      state.showModal = action.payload;
    },
    setShowSuccessModal(state, action) {
      state.showSuccessModal = action.payload;
    },
    setStatusNull(state) {
      state.status = null;
    },
    resetFormDataTestHmi: () => initialState,
  },
});

export const {
  setComponents,
  setError,
  setStatusNull,
  setBodyPayload,
  setInitBodyPayload,
  setRequestStatus,
  setDisplayError,
  setShowModal,
  setShowSuccessModal,
  resetFormDataTestHmi,
} = FormTestHmiSlice.actions;
export default FormTestHmiSlice;
