import styled from "styled-components";

export const Title = styled.h2`
  margin: 96px 0px 48px;
`;

export const Container = styled.div`
  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 64px;
    margin: 24px 0px;
  }
  margin: 24px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 48px 32px;
`;
