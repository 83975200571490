import { ComponentContainer, TextDescr } from "../style";

import { useSelector } from "react-redux";
import CustomSelect from "../../customSelect/CustomSelect";
import ErrorField from "./ErrorField/ErrorField";
import { useEffect, useState } from "react";

export default function AppointmentId({ data, allowed, onChange }) {
  const [placeholderString, setPlaceholderString] = useState(
    data?.badgeSlotplaceholder
  );
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneFamilyDay.bodyPayload
  );

  const existSlots = data?.event?.appointments?.badge?.find(
    (appointment) => appointment?.slots?.length > 0
  );

  useEffect(() => {
    if (!existSlots) setPlaceholderString(data?.noSlotsLabel);
    if (existSlots && bodyPayload?.appointmentId) {
      let currentGroupSelected = data?.event?.appointments?.badge?.find(
        (appointment) =>
          appointment?.slots?.find(
            (slot) => slot?.id == bodyPayload?.appointmentId
          )
      );
      setPlaceholderString(currentGroupSelected?.title);
    }
  }, [existSlots, bodyPayload?.appointmentId]);
  return (
    <ComponentContainer>
      <h3>{data?.badgeLbl} </h3>
      <TextDescr>{data?.badgeTxt}</TextDescr>
      <CustomSelect
        name="appointmentId"
        defaultValue={bodyPayload?.appointmentId || ""}
        placeholderString={placeholderString}
        onChange={onChange}
      >
        {data?.event?.appointments?.badge?.map(
          (appointment, ind) =>
            appointment?.slots?.length > 0 && (
              //verificare duringEventLabel se selezione data partecipazione coincide
              <optgroup key={"appointment" + ind} label={appointment?.title}>
                {appointment?.slots?.map((slot) => (
                  <option
                    checked={bodyPayload?.appointmentId === slot?.id}
                    key={slot?.id}
                    value={slot?.id}
                  >
                    {slot?.appointmentStartDate.slice(11, 16)} -{" "}
                    {slot?.appointmentEndDate.slice(11, 16)}
                  </option>
                ))}
              </optgroup>
            )
        )}
      </CustomSelect>
      {bodyPayload?.appointmentId === null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
