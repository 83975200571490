import { useEffect } from "react";
import { BrowserUtils } from "@azure/msal-browser";
import PropTypes from "prop-types";
import { InteractionStatus } from "@azure/msal-browser";

const Logout = ({ pca, inProgress }) => {
  const handleLogout = () => {
    // clear the cache
    window.localStorage.clear();
    if (inProgress === InteractionStatus.None) {
      console.log("logout");
      pca.logoutRedirect({
        account: pca.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      });
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};

Logout.propTypes = {
  pca: PropTypes.object,
};
export default Logout;
