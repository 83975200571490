import {
  AllEvents,
  AllEventsCta,
  AllEventsCtaIcon,
  AllEventsCtaIconCircle,
  ArrowIcon,
} from "./style";

function GoToAllWebinars() {
  return (
    <AllEvents className="webinars">
      <AllEventsCta>
        <AllEventsCtaIcon>
          <AllEventsCtaIconCircle>
            <svg viewBox="0 0 24 24">
              <circle cx={12} cy={12} r={11} stroke={1}></circle>
            </svg>
          </AllEventsCtaIconCircle>
          <ArrowIcon>
            <path d="M7.268 9.547L0 16l4-8-4-8 7.268 6.453C7.715 6.82 8 7.377 8 8c0 .623-.285 1.18-.732 1.547z"></path>
          </ArrowIcon>
        </AllEventsCtaIcon>
        <span>TORNA AGLI EVENTI</span>
      </AllEventsCta>
    </AllEvents>
  );
}

export default GoToAllWebinars;
