import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import CustomSelect from "../../../../customSelect/CustomSelect";
import {
  ContainerRequest,
  ContainerRequestForm,
  SectionForm,
  IconsContainer,
  NomeUtente,
  ContainerRequestSection,
  SectionDate,
  ButtonFloppy,
  ButtonFloppyImg,
  ButtonFloppyCircle,
  ButtonEdit,
  ButtonEditImg,
  ButtonEditCircle,
  ButtonAccept,
  ButtonAcceptImg,
  ButtonAcceptCircle,
  ButtonReject,
  ButtonRejectImg,
  ButtonRejectCircle,
} from "./style";

import {
  selectTabIndex,
  selectTabComponentsByTabIndex,
} from "../../../../../../pages/BusinessCardManagement/selectors";
import {
  setShowModal,
  setUserInfo,
  setUserInfoStatus,
  setEditActiveOnCardId,
  updateCard,
} from "../../../../../../pages/BusinessCardManagement/BusinessCardManagementSlice";
import { RequiredValue } from "../../../../formRichiestaEcard/style";

function CardUser({ cardInfo }) {
  const dispatch = useDispatch();
  const selectedTabIndex = useSelector(selectTabIndex());
  const { contactCard, errorLabel } = useSelector(
    selectTabComponentsByTabIndex(selectedTabIndex)
  );
  const components = useSelector(
    (state) => state.businessCardManagement.components
  );

  const approvalCompanyAddress = components?.find(
    (v) =>
      v.resourceType ===
      "fi-ecard-request/fi-ecard-request-companyAddress-select"
  )?.data;
  const isDebugMode =
    components?.find(
      (v) => v.resourceType === "fi-ecard-approval/fi-ecard-approval-tabs"
    )?.data?.isDebugMode === "true";

  const editActiveOnCardId = useSelector(
    (state) => state.businessCardManagement.editActiveOnCardId
  );

  const [displayError, setDisplayError] = useState(null);

  function time(ms) {
    return new Date(ms).toLocaleDateString("it", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }

  const acceptRequest = (e) => {
    e.preventDefault();
    dispatch(setUserInfo({ cardInfo }));
    dispatch(setUserInfoStatus(1));
    dispatch(setShowModal(true));
  };

  const rejectRequest = (e) => {
    e.preventDefault();
    dispatch(setUserInfo({ cardInfo, rejected: true }));
    dispatch(setUserInfoStatus(-1));
    dispatch(setShowModal(true));
  };

  const requiredInfo = [
    "depAndJobPos",
    "company",
    "corporateAddress",
    "mobilePhone",
    "email",
  ];

  const modifyUserInfo = (e) => {
    const { name, value } = e.target;
    const noWhiteSpacesAtStart = value?.trimStart();
    let modifiedUserInfo = { ...cardInfo };
    modifiedUserInfo = { ...modifiedUserInfo, [name]: noWhiteSpacesAtStart };
    dispatch(updateCard(modifiedUserInfo));

    //check if required
    if (requiredInfo.includes(name)) {
      noWhiteSpacesAtStart === ""
        ? e.target.classList.add("required")
        : e.target.classList.remove("required");
    }
    if (!isDebugMode && name === "email") {
      value?.split("@")?.[1] !== "ferrari.com"
        ? e.target.classList.add("required")
        : e.target.classList.remove("required");
    }
  };

  const cleanEndValue = (e) => {
    const { name, value } = e.target;
    const noWhiteSpacesAtEnd = value?.trimEnd();
    let modifiedUserInfo = { ...cardInfo };
    modifiedUserInfo = { ...modifiedUserInfo, [name]: noWhiteSpacesAtEnd };
    dispatch(updateCard(modifiedUserInfo));
  };

  const handleEditMode = (e) => {
    e.preventDefault();
    const currentCard = document.getElementById(e.target.id)?.firstChild;
    if (editActiveOnCardId === cardInfo._id) {
      currentCard?.classList.remove("active");
      dispatch(setEditActiveOnCardId(""));
    } else {
      currentCard?.classList.add("active");
      dispatch(setEditActiveOnCardId(cardInfo._id));
    }
  };

  const isMailValid = () => {
    if (isDebugMode) {
      return true;
    }
    return cardInfo.email.split("@")?.[1] === "ferrari.com";
  };

  const handleError = () => {
    let error = false;
    for (const el of requiredInfo) {
      if (cardInfo[el] === "") {
        error = true;
        break;
      }
      if (!isDebugMode && el === "email" && !isMailValid()) {
        error = true;
        break;
      }
    }
    setDisplayError(error);
  };

  useEffect(() => {
    if (editActiveOnCardId) handleError();
  }, [editActiveOnCardId, cardInfo]);

  return (
    <ContainerRequest id={cardInfo._id}>
      <ContainerRequestForm>
        <ContainerRequestSection gap={"true"} max={"true"}>
          <NomeUtente>
            <h3>
              {cardInfo?.name} {cardInfo?.lastName}
            </h3>
          </NomeUtente>
          {selectedTabIndex === 0 && (
            <IconsContainer>
              {editActiveOnCardId === cardInfo._id ? (
                <ButtonFloppy
                  className={displayError ? "inactive" : ""}
                  id={cardInfo._id}
                  onClick={(e) =>
                    displayError ? undefined : handleEditMode(e)
                  }
                >
                  <ButtonFloppyImg />
                  <ButtonFloppyCircle />
                </ButtonFloppy>
              ) : (
                <ButtonEdit
                  className={editActiveOnCardId ? "inactive" : ""}
                  id={cardInfo._id}
                  onClick={(e) =>
                    editActiveOnCardId ? undefined : handleEditMode(e)
                  }
                >
                  <ButtonEditImg />
                  <ButtonEditCircle />
                </ButtonEdit>
              )}
              <ButtonAccept
                className={
                  editActiveOnCardId || (!isDebugMode && !isMailValid())
                    ? "inactive"
                    : ""
                }
                id={cardInfo._id}
                onClick={(e) =>
                  editActiveOnCardId || (!isDebugMode && !isMailValid())
                    ? undefined
                    : acceptRequest(e)
                }
              >
                <ButtonAcceptImg />
                <ButtonAcceptCircle />
              </ButtonAccept>
              <ButtonReject
                className={editActiveOnCardId ? "inactive" : ""}
                onClick={(e) =>
                  editActiveOnCardId ? undefined : rejectRequest(e)
                }
              >
                <ButtonRejectImg />
                <ButtonRejectCircle />
              </ButtonReject>
            </IconsContainer>
          )}
        </ContainerRequestSection>
        <ContainerRequestSection>
          <SectionForm hideLabel={true} short="true">
            <input
              autoComplete="nope"
              disabled={editActiveOnCardId === cardInfo._id ? false : true}
              id="company"
              name="company"
              placeholder={contactCard?.departmentLabel}
              value={cardInfo?.company}
              type="text"
              onChange={(e) => modifyUserInfo(e)}
              onBlur={(e) => cleanEndValue(e)}
            />
            <label htmlFor="company">{contactCard?.departmentLabel}</label>
          </SectionForm>
          <SectionForm hideLabel={true}>
            <input
              disabled={editActiveOnCardId === cardInfo._id ? false : true}
              id="depAndJobPos"
              name="depAndJobPos"
              placeholder={contactCard?.jobDescriptionLabel}
              value={cardInfo?.depAndJobPos}
              type="text"
              onChange={(e) => modifyUserInfo(e)}
              onBlur={(e) => cleanEndValue(e)}
            />
            <label htmlFor="depAndJobPos">
              {contactCard?.jobDescriptionLabel}
            </label>
          </SectionForm>
        </ContainerRequestSection>
        <ContainerRequestSection>
          <SectionForm>
            {editActiveOnCardId === cardInfo._id ? (
              <CustomSelect
                id="corporateAddress"
                name="corporateAddress"
                defaultValue={cardInfo?.corporateAddress}
                placeholderString={contactCard?.companyAddressLabel}
                onChange={(e) => modifyUserInfo(e)}
              >
                {approvalCompanyAddress?.items?.map((sede, index) => (
                  <option key={index} value={sede.address}>
                    {sede.address}
                  </option>
                ))}
              </CustomSelect>
            ) : (
              <>
                <input
                  disabled={editActiveOnCardId === cardInfo._id ? false : true}
                  id="corporateAddress"
                  name="corporateAddress"
                  placeholder={contactCard?.companyAddressLabel}
                  value={cardInfo?.corporateAddress}
                  type="text"
                />
                <label htmlFor="corporateAddress">
                  {contactCard?.companyAddressLabel}
                </label>
              </>
            )}
          </SectionForm>
        </ContainerRequestSection>
        <ContainerRequestSection>
          <SectionForm hideLabel={true} short="true">
            <input
              autoComplete="nope"
              disabled={editActiveOnCardId === cardInfo._id ? false : true}
              id="mobilePhone"
              name="mobilePhone"
              placeholder={contactCard?.mobileLabel}
              value={cardInfo?.mobilePhone}
              type="tel"
              onChange={(e) => modifyUserInfo(e)}
              onBlur={(e) => cleanEndValue(e)}
            />
            <label htmlFor="mobilePhone">{contactCard?.mobileLabel}</label>
          </SectionForm>
          <SectionForm hideLabel={true}>
            <input
              autoComplete="nope"
              disabled={editActiveOnCardId === cardInfo._id ? false : true}
              id="email"
              name="email"
              className={
                selectedTabIndex === 0 && !isDebugMode && !isMailValid()
                  ? "required"
                  : ""
              }
              placeholder={contactCard?.emailLabel}
              value={cardInfo?.email}
              type="email"
              onChange={(e) => modifyUserInfo(e)}
              onBlur={(e) => cleanEndValue(e)}
            />
            <label htmlFor="email">{contactCard?.emailLabel}</label>
          </SectionForm>
        </ContainerRequestSection>
        <ContainerRequestSection>
          <SectionForm hideLabel={true} short="true">
            <input
              autoComplete="nope"
              disabled={editActiveOnCardId === cardInfo._id ? false : true}
              id="phone"
              name="phone"
              placeholder={contactCard?.phoneLabel}
              value={cardInfo?.phone || ""}
              type="tel"
              onChange={(e) => modifyUserInfo(e)}
              onBlur={(e) => cleanEndValue(e)}
            />
            <label htmlFor="phone">{contactCard?.phoneLabel}</label>
          </SectionForm>
          <SectionForm hideLabel={true}>
            <input
              disabled={editActiveOnCardId === cardInfo._id ? false : true}
              id="fax"
              name="fax"
              placeholder={contactCard?.faxLabel}
              value={cardInfo?.fax || ""}
              type="text"
              onChange={(e) => modifyUserInfo(e)}
              onBlur={(e) => cleanEndValue(e)}
            />
            <label htmlFor="fax">{contactCard?.faxLabel}</label>
          </SectionForm>
        </ContainerRequestSection>
        {cardInfo._id === editActiveOnCardId && displayError && (
          <ContainerRequestSection>
            <RequiredValue id="required">{errorLabel}</RequiredValue>
          </ContainerRequestSection>
        )}
        <SectionDate>
          <p>{time(+cardInfo?.creationDate)}</p>
        </SectionDate>
      </ContainerRequestForm>
    </ContainerRequest>
  );
}

export default CardUser;
