import { takeLatest, put } from "redux-saga/effects";
import { axiosCallGet, axiosCallPost } from "../../utils/axiosCalls/AxiosCalls";

import {
  setComponents,
  setError,
  fetchComponents,
  setStatusNull,
} from "./ProgrammaCoinvolgimentoSlice";

function* fetchComponentsWorker({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    yield put(setComponents(result.data.data));
    if (payload.id) {
      yield put(setStatusNull());
    }
  } catch (e) {
    console.error(e);
    yield put(setError(e));
  }
}

export default function* ProgrammiCoinvolgimentoSaga() {
  yield takeLatest(fetchComponents.type, fetchComponentsWorker);
}
