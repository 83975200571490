import { FocusContainer } from "./style";

//components
import AsideCarousel from "./asideCarousel/AsideCarousel";
import AsideCard from "../asideCard/AsideCard";
import Divider from "../divider/Divider";

function WelfareFocus({ data }) {
  return (
    <>
      <FocusContainer>
        <AsideCarousel data={data}></AsideCarousel>
        <AsideCard data={data}></AsideCard>
      </FocusContainer>
      <Divider />
    </>
  );
}

// WelfareFocus.propTypes = {
//   data: PropTypes.exact({
//     secondNewsImgOverride: PropTypes.string,
//     news: PropTypes.array,
//     position: PropTypes.string,
//   }),
// };

export default WelfareFocus;
