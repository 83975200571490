import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./action";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import { useLocation } from "react-router-dom";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";
import { LayoutSimpleEventDetail } from "../../components/UI/simpleEventDetail/LayoutSimpleEventDetail";

export default function SimpleEventDetail() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const components = useSelector((state) => state.SimpleEventDetail.components);
  const location = useLocation();
  const url = location.search.replace(/\?path=/, "");
  const eventCode = url.split("eventCode=")[1];

  useMemo(() => {
    dispatch(fetchComponents({ path: url, token }));
  }, [dispatch, url]);

  const detailData = components?.components?.find(
    (v) => v.resourceType === "fi-events/fi-internal-events-detail"
  );

  const heroData = components?.components?.find(
    (v) => v.resourceType === "fi-events/fi-events-hero-subscription"
  );

  const settingButtons = components?.components?.find(
    (v) => v.resourceType === "fi-two-buttons"
  );

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);

  ChangeTitleDoc(
    subjectCode,
    aggregatedDirection,
    components?.settings?.jcrTitle,
    url
  );
  const { status, errorData } = GetStatusAndErrorData("SimpleEventDetail");
  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <>
          {detailData?.data && (
            <LayoutSimpleEventDetail
              data={detailData?.data}
              heroData={heroData?.data}
              buttonsData={settingButtons?.data}
              eventCode={eventCode}
            />
          )}
        </>
      )}
    </>
  );
}
