import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  padding-bottom: 90px;
  .th-video {
    aspect-ratio: 16/9;
  }
  @media screen and (max-width: 1279px) {
    h1 {
      font-size: 24px;
      margin-top: 0;
    }
  }
  @media screen and (min-width: 1280px) {
    width: 800px;
    .doc-title-and-data {
      margin-left: 0;
    }
    .download-label {
      display: block;
      margin-right: 10px;
      text-transform: uppercase;
      font-size: 12px;
      color: var(--main-color);
    }
  }
  .download-tag-a {
    display: flex;
    align-items: center;
  }
  .doc-wrapper {
    border-bottom: 1px solid rgb(212, 210, 210);
    padding-bottom: 20px;
  }
  .doc-download {
    margin-top: 20px;
  }
  .doc-container {
    border-bottom: 0;
  }
`;

export const ImgHeader = styled.img`
  margin-top: -92px;
  position: relative;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  @media screen and (max-width: 1279px) {
    margin-top: 0;
  }
`;
