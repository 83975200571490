import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// style
import {
  GoBack,
  Wrapper,
  SvgElement,
  Path,
  FlexWrapper,
  LeftContainer,
  RightContainer,
} from "./style";

function DetailsHeader({
  headerComponents,
  eventType = "",
  link = "/it/news-and-events/events",
  goBackText = "Torna agli Eventi",
  onlyBack = false,
  noCreateWithoutLink,
}) {
  const navigate = useNavigate();
  return (
    <Wrapper link={link} noCreateWithoutLink={noCreateWithoutLink}>
      {React.Children.toArray(
        headerComponents?.map((element) => {
          return (
            <>
              <img src={element.data.fileReference} />
              {link ? (
                <GoBack>
                  <Link to={link}>
                    <span className="cta-icon">
                      <span className="circle">
                        <svg viewBox="0 0 24 24">
                          <circle cx="12" cy="12" r="11" stroke="1"></circle>
                        </svg>
                      </span>
                      <SvgElement>
                        <Path />
                      </SvgElement>
                    </span>
                    <span style={{ marginLeft: "10px" }}>{goBackText}</span>
                  </Link>
                </GoBack>
              ) : !noCreateWithoutLink ? (
                <GoBack>
                  <a href="#" onClick={() => navigate(-1)}>
                    <span className="cta-icon">
                      <span className="circle">
                        <svg viewBox="0 0 24 24">
                          <circle cx="12" cy="12" r="11" stroke="1"></circle>
                        </svg>
                      </span>
                      <SvgElement>
                        <Path />
                      </SvgElement>
                    </span>
                    <span style={{ marginLeft: "10px" }}>{goBackText}</span>
                  </a>
                </GoBack>
              ) : (
                ""
              )}

              {!onlyBack && (
                <FlexWrapper>
                  {element.data.dateDay && eventType !== "webinar" && (
                    <LeftContainer>
                      <div className="number">{element.data.dateDay}</div>
                      <div className="month">{element.data.dateMonth}</div>
                    </LeftContainer>
                  )}
                  <RightContainer
                    className={`${
                      eventType === "webinar" ? "padding-bottom-32" : ""
                    }`}
                  >
                    <div className="title">{element.data.title}</div>
                    {eventType === "live streaming" && (
                      <button className="eventType">{eventType}</button>
                    )}
                  </RightContainer>
                </FlexWrapper>
              )}
            </>
          );
        })
      )}
    </Wrapper>
  );
}

DetailsHeader.propTypes = {
  headerComponents: PropTypes.arrayOf(PropTypes.object),
  eventType: PropTypes.string,
};

export default DetailsHeader;
