import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px;
  gap: 24px;
  right: 0px;
  z-index: 3;
  background: var(--main-bg);
  //top: ${(props) => (props.fromSearch ? "10%" : "40%")};
  top: 40px;
  ${(props) =>
    props.light
      ? "box-shadow: 0px 3px 20px rgba(255,255,255, 0.06)"
      : "box-shadow:  0px 20px 32px rgba(0, 0, 0, 0.06)"};
  a {
    font-size: 12px;
    padding: 0px 24px;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--main-color);
  }
  @media (max-width: 1279px) {
    display: none;
  }
`;

export const Divider = styled.div`
  margin: 0px;
  height: 0px;
  border-bottom: 1px solid rgb(212, 210, 210);
  float: left;
  clear: both;
  width: 100%;
`;

export const Theme = styled.div`
  display: block;
  padding: 0px 24px;
  border: 0px;
`;

export const ImgWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
  & > div {
    cursor: pointer;
  }
  img {
    max-width: 100%;
  }
`;

export const ThemeRecap = styled.div`
  padding: 0px 24px;
  div.recap {
    font-size: 11px;
    color: rgb(143, 143, 143);
  }
`;
