import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 800px;
  padding: 32px 0px 32px 0px;
  border-bottom: 1px solid #d4d2d2;
  div {
    line-height: 8px;
  }
  div.download {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    span {
      margin-right: 10px;
      @media (max-width: 1279px) {
        display: none;
      }
    }
    svg {
      cursor: pointer;
    }
  }
  @media (max-width: 1279px) {
    width: 100%;
    align-items: center;
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
`;

export const Description = styled.p`
  font-size: 13px;
  color: #707070;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
`;
