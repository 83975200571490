export function useBusinessLogic() {
  //manage html injection
  function generateHtml(argument) {
    return { __html: argument };
  }

  function parseLabel(id, stringToParse) {
    if (!stringToParse?.includes("<a")) {
      return stringToParse;
    } else {
      const splittedArray = stringToParse.split("<a");
      const firstPart = splittedArray[0].concat(
        `<a count='${id}' id='ruleLink${id}'`
      );
      const result = firstPart.concat(splittedArray[1]);
      return result;
    }
  }

  //manage checkboxes
  const enablePrivacyCheckbox = (e, id) => {
    const linkElem = e.target;
    linkElem.setAttribute("listener", "true");

    if (document.querySelector(id).disabled === true) {
      document.querySelector(id).disabled = false;
      document.querySelector(id).parentElement.style.color =
        "var(--main-color)";
    }
  };

  const updateCheckboxPrivacy = () => {
    document.querySelectorAll(".checkbox-input").forEach((checkbox) => {
      const rawText = checkbox.getAttribute("rawtext");
      if (!rawText.includes("<a")) {
        checkbox.disabled = false;
      }
      checkbox.disabled
        ? (checkbox.parentElement.style.color = "var(--alternative-grey-color)")
        : (checkbox.parentElement.style.color = "var(--main-color)");
    });

    document.querySelectorAll("a[count]").forEach((link) => {
      if (link.getAttribute("listener") !== "true") {
        const count = link.getAttribute("count");
        link.addEventListener("click", (e) =>
          enablePrivacyCheckbox(e, `#checkBoxPrivacy${count}`)
        );
      }
    });
  };

  //manage date configuration

  const getSubscriptionDay = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  //manage button color
  const updateButtonColor = (x) => {
    if (x === "red") {
      document.querySelector(".submitButton").style = `
        color: rgb(255, 255, 255);
        background: radial-gradient(
            199.89% 50% at 50% 100%,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          radial-gradient(
            349.37% 116.89% at 50% 100%,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
          ),
          rgb(218, 41, 28);
        `;
    } else {
      document.querySelector(".submitButton").style = `
        background: #d4d2d2;
        color: #181818;
        `;
    }
    return;
  };

  return {
    parseLabel,
    updateButtonColor,
    updateCheckboxPrivacy,
    getSubscriptionDay,
    generateHtml,
  };
}
