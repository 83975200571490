/* eslint-disable default-case */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rubricaActions } from "../../../../../pages/Rubrica/rubricaSlice";
import { SaveFavUsefulContact } from "../../../../../pages/Rubrica/actions";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

import {
  Wrapper,
  CardHeading,
  CardHeadingText,
  CardHeadingDetails,
  CardHeadingDetailsInitialCircle,
  CardHeadingDetailsUseful,
  CardHeadingDetailsUsefulName,
  CardHeadingDetailsUsefulInterno,
  CardDetailsFrstRow,
  CardDetailsFrstRowFisso,
  CardDetailsFrstRowCell,
  CardLocation,
  CardBody,
  HeadingDetailsLinks,
  CardHeadingDetailsInitialText,
} from "./style";
import {
  getUsefulMaranelloContactLabels,
  getUsefulScagliettiContactLabels,
  getUsefulContactLabels,
  selectTabIndex,
} from "../../../../../pages/Rubrica/selectors";

function UsefulContactCard({ data }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const [isFavourite, SetIsFavourite] = useState(data.isFavourite);
  const tabIndex = useSelector(selectTabIndex());
  const usefulMaranelloLabels = useSelector(getUsefulMaranelloContactLabels());
  const usefulScagliettiLabels = useSelector(
    getUsefulScagliettiContactLabels()
  );
  const usefulLabels = useSelector(getUsefulContactLabels());
  let contactLabels =
    data.tag === "usefulMaranello"
      ? usefulMaranelloLabels
      : usefulScagliettiLabels;
  switch (data.tag) {
    case "usefulMaranello":
      contactLabels = usefulMaranelloLabels;
      break;
    case "usefulScaglietti":
      contactLabels = usefulScagliettiLabels;
      break;
    case "useful":
      contactLabels = usefulLabels;
      break;
  }

  const handleOnClickFav = (event) => {
    event.preventDefault();
    dispatch(rubricaActions.setFavouriteUsefulContact(data));
    dispatch(rubricaActions.updateTabComponents({ contact: data }));
    dispatch(SaveFavUsefulContact({ data, token }));
    if (tabIndex !== 1) {
      SetIsFavourite(!isFavourite);
    }
  };

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <Wrapper>
      <CardHeading>
        <CardHeadingText>
          <CardHeadingDetails>
            <CardHeadingDetailsInitialCircle>
              <CardHeadingDetailsInitialText>
                {data.shortTitle}
              </CardHeadingDetailsInitialText>
            </CardHeadingDetailsInitialCircle>
            <CardHeadingDetailsUseful>
              <CardHeadingDetailsUsefulName>
                <div>{data.title}</div>
              </CardHeadingDetailsUsefulName>
              {isDesktop && (
                <CardHeadingDetailsUsefulInterno>
                  <CardDetailsFrstRow>
                    <CardDetailsFrstRowFisso>
                      <CardLocation>
                        {contactLabels.internalNumberLabel}
                      </CardLocation>
                      <CardBody>{data.internalNumber}</CardBody>
                    </CardDetailsFrstRowFisso>
                    <CardDetailsFrstRowCell>
                      <CardLocation>
                        {contactLabels.externalNumberLabel}
                      </CardLocation>
                      <CardBody>{data.externalNumber}</CardBody>
                    </CardDetailsFrstRowCell>
                  </CardDetailsFrstRow>
                </CardHeadingDetailsUsefulInterno>
              )}
            </CardHeadingDetailsUseful>
          </CardHeadingDetails>
          <HeadingDetailsLinks>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleOnClickFav}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
                fill="#C4C4C4"
              />
              <path
                d="M19.8096 11.9184C19.8695 11.7342 20.1302 11.7342 20.19 11.9184L22.0504 17.644C22.0772 17.7264 22.154 17.7822 22.2406 17.7822H28.2608C28.4546 17.7822 28.5351 18.0301 28.3784 18.144L23.5079 21.6826C23.4378 21.7335 23.4085 21.8238 23.4353 21.9062L25.2956 27.6318C25.3555 27.816 25.1446 27.9692 24.9879 27.8554L20.1174 24.3168C20.0473 24.2658 19.9524 24.2658 19.8823 24.3168L15.0118 27.8554C14.8551 27.9692 14.6442 27.816 14.7041 27.6318L16.5644 21.9062C16.5912 21.8238 16.5618 21.7335 16.4917 21.6826L11.6213 18.144C11.4645 18.0301 11.5451 17.7822 11.7388 17.7822H17.7591C17.8457 17.7822 17.9225 17.7264 17.9493 17.644L19.8096 11.9184Z"
                fill={isFavourite ? "var(--main-color)" : "transparent"}
                stroke="var(--cta-color)"
                strokeWidth="1.68"
              />
            </svg>
          </HeadingDetailsLinks>
        </CardHeadingText>
        {!isDesktop && (
          <CardHeadingDetailsUsefulInterno>
            <CardDetailsFrstRow>
              <CardDetailsFrstRowFisso>
                <CardLocation>{contactLabels.internalNumberLabel}</CardLocation>
                <CardBody>{data.internalNumber}</CardBody>
              </CardDetailsFrstRowFisso>
              <CardDetailsFrstRowCell>
                <CardLocation>{contactLabels.externalNumberLabel}</CardLocation>
                <CardBody>{data.externalNumber}</CardBody>
              </CardDetailsFrstRowCell>
            </CardDetailsFrstRow>
          </CardHeadingDetailsUsefulInterno>
        )}
      </CardHeading>
    </Wrapper>
  );
}

export default UsefulContactCard;
