import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { fetchContactUsComponents } from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
// selector
import { selectHeroImg } from "./selectors";
import { selectAggregatedDirection } from "../MyPage/selectors";
// components
import LayoutContactUs from "../../components/UI/contactUs/LayoutContactUs";
import InfoTitleSection from "../../components/UI/contactUs/infoTitleSection/InfoTitleSection";
import CardSection from "../../components/UI/contactUs/cardSection/CardSection";
import NewsText from "../../components/UI/news/newsDetailContainer/newsText/NewsText";
import EventDivider from "../../components/UI/event/eventDetails/twoColumnContainer/leftColumnContainer/eventDivider/EventDivider";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";

function ContactUs({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const heroImg = useSelector(selectHeroImg());
  const contactsUsComponents = useSelector(
    (state) => state.contactus.components
  );
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const { status, errorData } = GetStatusAndErrorData("contactus");

  useMemo(() => {
    path && token && dispatch(fetchContactUsComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);

  const componentsMapping = {
    "fi-tools-title-description": InfoTitleSection,
    "fi-contact-us-container": CardSection,
    "fi-text": NewsText,
    "fi-divider": EventDivider,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <LayoutContactUs heroImg={heroImg}>
          {contactsUsComponents?.map((current, idx) => {
            const ReactGenericComponent =
              componentsMapping[current.resourceType];
            return (
              ReactGenericComponent && (
                <ReactGenericComponent
                  key={`${current.resourceType}-${idx}`}
                  data={current.data}
                ></ReactGenericComponent>
              )
            );
          })}
        </LayoutContactUs>
      )}
    </>
  );
}

export default ContactUs;
