import React from "react";
import {
  DetailContainerData,
  DetailDataLabel,
  DetailState,
  DetailWrapper,
} from "./style";
import { useBusinessLogic } from "../../../useBusinessLogic";
import { useSelector } from "react-redux";

export function RecapCard({ subscription, data }) {
  const lang = useSelector((state) => state.coreComponents.language);

  const { getSubscriptionDay } = useBusinessLogic();

  const responsePost = useSelector(
    (state) => state.SimpleEventsSubscribeForm.resultPost
  );

  const successResponses = responsePost?.filter(
    (el) => el.status === "fulfilled"
  );

  let isFulfilled = false;
  if (
    successResponses.find(
      (singleResponseEl) =>
        subscription.id === singleResponseEl?.value?.appointmentId
    )
  ) {
    isFulfilled = true;
  }

  return (
    <>
      <DetailWrapper>
        <DetailContainerData>
          <DetailDataLabel>{data?.eventLabel}</DetailDataLabel>
          <p>{subscription?.title}</p>
        </DetailContainerData>
        <DetailContainerData>
          <DetailDataLabel>{data?.stateLabel}</DetailDataLabel>
          <DetailState success={isFulfilled}>
            {isFulfilled ? data?.successTxt : data?.failedTxt}
          </DetailState>
        </DetailContainerData>
        {isFulfilled && (
          <>
            <DetailContainerData>
              <DetailDataLabel>{data?.slotLabel}</DetailDataLabel>
              <p>
                {getSubscriptionDay(
                  subscription?.appointmentStartDate,
                  subscription?.appointmentEndDate,
                  lang
                )}
              </p>
            </DetailContainerData>
          </>
        )}
      </DetailWrapper>
    </>
  );
}

export default React.memo(RecapCard);
