import styled from "styled-components";

export const VisualErrorField = styled.p`
  color: #da291c;
  font-weight: 400;
  font-size: 11px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0;
`;
