import styled from "styled-components";

export const TitleH1 = styled.h1`
  text-align: center;
  font-size: 36px;
  line-height: 43px;
  @media screen and (min-width: 1280px) {
    margin: 96px auto 64px;
    position: relative;
    width: 1216px;
  }
`;
