import { FilesList } from "./style";
import LoadFiles from "./LoadFiles";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SingleFile } from "./SingleFile";

export default function DroppableImages({
  data,
  files,
  onFileChange,
  setFiles,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );
  return (
    <FilesList>
      {files.length < 4 && <LoadFiles data={data} onChange={onFileChange} />}
      {files.length > 0 && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToParentElement]}
        >
          {files.map((item) => (
            <SingleFile
              key={item?.id}
              id={item?.id}
              file={item?.file}
              files={files}
              setFiles={setFiles}
              data={data}
            />
          ))}
        </DndContext>
      )}
    </FilesList>
  );
}
