import PropTypes from "prop-types";

//components
import VideoPlayer from "../../../videoPlayer/VideoPlayer";

function NewsVideo({ data }) {
  const style = {
    width: 100,
    height: 100,
    margin: 10,
    top: 160,
  };
  return <VideoPlayer style={style} url={data.fileReference} />;
}

NewsVideo.propTypes = {
  data: PropTypes.exact({
    fileReference: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default NewsVideo;
