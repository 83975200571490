import styled from "styled-components";

export const Wrapper = styled.div`
  background: var(--alternative-bg-color);
  @media (min-width: 1280px) {
    width: 384px;
    box-sizing: border-box;
    min-width: 384px;
  }
  @media (max-width: 1279px) {
    width: 100%;
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 24px;
`;

export const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

export const Number = styled.div`
  background: rgb(218, 41, 28);
  color: rgb(255, 255, 255);
  font-size: 11px;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  place-content: center;
  align-items: center;
`;

export const Text = styled.div`
  width: 280px;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 1279px) {
    width: 100% !important;
  }
`;
