import PropTypes from "prop-types";
import { DocContainer, DocContainerTitle, DocumentsWrapper } from "./style";

import NewsDoc from "./newsDoc/NewsDoc";
import React from "react";

function NewsDocContainer({ data }) {
  return (
    <DocumentsWrapper>
      <DocContainerTitle className="doc-container__title">
        {data.title}
      </DocContainerTitle>
      <DocContainer className="doc-container">
        {React.Children.toArray(
          data.assets.map((currentDoc) => <NewsDoc data={currentDoc} />)
        )}
      </DocContainer>
    </DocumentsWrapper>
  );
}

NewsDocContainer.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    assets: PropTypes.array,
    position: PropTypes.string,
  }),
};

export default NewsDocContainer;
