import styled from "styled-components";

export const TitleH1 = styled.h1`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media screen and (min-width: 1280px) {
    margin: 96px auto 64px;
    position: relative;
    width: 1216px;
  }
`;

export const TitleH2 = styled.h2`
  margin: 40px 0px 48px;
  @media screen and (min-width: 1280px) {
    margin: 96px 0px 48px;
  }
`;
