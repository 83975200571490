import styled from "styled-components";

export const Slot = styled.div`
  position: relative;
  display: block;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: linear-gradient(
      rgba(0, 0, 0, 0) 34.68%,
      rgba(32, 27, 27, 0) 34.69%,
      rgba(0, 0, 0, 0.8) 100%
    );
    content: "";
  }
  img {
    display: block;
    max-width: 100%;
  }
`;

export const TextWrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  position: absolute;
  width: 592px;
  height: 184px;
  left: 32px;
  bottom: 32px;
  z-index: 2;
  @media (max-width: 1279px) {
    width: auto;
    height: auto;
    top: 8px;
  }
`;

export const TextHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
`;

export const HeadingChapter = styled.div`
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  letter-spacing: 1px;
`;

export const HeadingTitle = styled.div`
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 36px;
  color: rgb(255, 255, 255);
`;

export const Text = styled.div`
  color: rgb(255, 255, 255);
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;
