import styled from "styled-components";

export const Card = styled.div`
  position: relative;
  &::before {
    background: linear-gradient(
      rgba(48, 48, 48, 0) 0%,
      rgba(24, 24, 24, 0.49) 100%
    );
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  img {
    display: block;
    max-width: 100%;
  }
  h3 {
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    max-width: none;
    margin-top: 0;
    letter-spacing: 1px;
  }
`;

export const FocusText = styled.div`
  position: absolute;
  left: 24px;
  bottom: 0px;
  width: calc(100% - 48px);
  @media screen and (min-width: 1280px) {
    bottom: 24px;
  }
  @media (max-width: 1279px) {
    text-align: left;
  }
`;

export const PreTitle = styled.div`
  color: rgb(255, 255, 255);
  display: flex;
  margin: 0 0 16px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 15px;
  @media screen and (min-width: 1280px) {
    margin-bottom: 16px;
  }
`;

export const EventCta = styled.button`
  color: rgb(255, 255, 255);
  border-radius: 27px;
  padding: 5px 16px 7px;
  text-transform: uppercase;
  font: 8px/12px ferrari-sans-medium;
  border-color: rgba(255, 255, 255, 0.4);
  max-width: 140px;

  background: transparent;
  outline: none;
`;
