import styled from "styled-components";

export const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin: 24px auto 64px;
  @media screen and (min-width: 1280px) {
    margin-bottom: 48px;
  }
  @media screen and (max-width: 1279px) {
    font-size: 11px;
    width: auto;
  }
  a {
    color: var(--main-color);
    text-decoration: underline;
  }
`;
