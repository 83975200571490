import { useSelector } from "react-redux";
import CustomSelect from "../../../customSelect/CustomSelect";
import { ComponentContainer, TextDescr } from "../../style";
//components
import ErrorField from "../ErrorField/ErrorField";
import { useBusinessLogic } from "../../useBusinessLogic";
import React from "react";

export default function SlotGhLe({ data, onChange, check, allowed }) {
  const dataGiftHouse = data?.giftHouse;
  const dataLightExp = data?.lightExperience;
  const { getSubscriptionDay } = useBusinessLogic();
  const lang = useSelector((state) => state.coreComponents.language);
  const eventUserInfo = useSelector(
    (state) => state.FormIscrizioneGiftHouse.defaultUserInfo
  );

  const appointmentsFilter = () => {
    const weAppointments = dataGiftHouse?.appointments?.filter((appointment) =>
      appointment?.configurations?.find(
        (el) =>
          el.groupName === "deliveryMode" &&
          el.fieldValue === "employeeAndFamily"
      )
    );
    //filtro su appuntamenti di giftHouse
    const filteredGh = weAppointments?.filter(
      (el) => el?.availablePlacesFrontEnd - el?.takenSeats > 0
    );
    const filteredGhLe = filteredGh?.filter((appointmentGh) => {
      let slotLe = dataLightExp?.appointments?.find(
        (appointmentLe) =>
          appointmentLe?.id === appointmentGh?.childAppointmentId
      );
      if (
        slotLe?.availablePlacesFrontEnd - slotLe?.takenSeats >=
        1 + eventUserInfo?.familyComponents?.length
      ) {
        return appointmentGh;
      }
    });

    return filteredGhLe;
  };

  const availableAppointments = appointmentsFilter();

  return (
    <ComponentContainer>
      <h3>{dataGiftHouse?.titleGiftLight} </h3>
      <TextDescr>{dataGiftHouse?.descrGiftLight}</TextDescr>
      <CustomSelect
        name="AppointmentIdGhAndLe"
        defaultValue={check || ""}
        placeholderString={dataGiftHouse?.slotLabel}
        onChange={onChange}
      >
        {React.Children.toArray(
          availableAppointments?.map((appointment) => {
            return (
              <option
                className="data-evento"
                value={`${appointment?.id} - ${appointment?.childAppointmentId}`}
                key={appointment?.childAppointmentId}
              >
                {getSubscriptionDay(
                  appointment?.appointmentStartDate,
                  appointment?.appointmentEndDate,
                  lang
                )}
              </option>
            );
          })
        )}
      </CustomSelect>
      {check == null && allowed === false && (
        <ErrorField text={dataGiftHouse?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
