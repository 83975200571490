import { List } from "./style";
import PropTypes from "prop-types";

//components
import EventCard from "./eventCard/EventCard";
import TitleSection from "../titleSection/TitleSection";
import CarouselWrapper from "../../carouselWrapper/CarouselWrapper";

import useMediaQuery from "../../../../hooks/useMediaQuery";

const EVENT_CARD_COMPONENT_NAME = "event-card";

function EventsCardList({ data }) {
  const titleData = data.title
    ? { title: data.title, type: "h2" }
    : { title: "Scopri gli eventi Ferrari", type: "h2" };
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const mapEvent = data.events.map((currentCard, idx) => {
    if (!currentCard.path.includes("premio-di-competitivita")) {
      return (
        <EventCard
          key={`${EVENT_CARD_COMPONENT_NAME}-${idx}`}
          data={currentCard}
        />
      );
    }
  });

  return (
    <>
      <TitleSection data={titleData} />
      {isDesktop ? (
        <List>{mapEvent}</List>
      ) : (
        <CarouselWrapper itemsLength={data?.events?.length}>
          {mapEvent}
        </CarouselWrapper>
      )}
    </>
  );
}

EventsCardList.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    events: PropTypes.array,
    position: PropTypes.string,
  }),
};
export default EventsCardList;
