import { put, select, takeLatest } from "redux-saga/effects";
// utils
import { axiosCallGet } from "../../utils/axiosCalls/AxiosCalls";

import { globalSearchActions } from "./globalSearchSlice";
import { FETCH_RESULTS } from "./constants";
// import axios from "axios";

function* fetchResults({ payload }) {
  try {
    const keyword = payload.keyword
      .toLowerCase()
      .replaceAll("’", "’ ")
      .replaceAll("'", "' ");
    const lang = yield select((state) => state.coreComponents.language);
    const path = `/${lang}/search?text=${encodeURIComponent(keyword)}`;
    let tools = yield select((state) => state.tools.toolsMap);
    let updateTools = tools.map((el) => {
      return (el = { ...el, type: "TOOLS" });
    });
    let filteredTools = updateTools.filter(
      (el) =>
        el?.title?.toLowerCase()?.includes(keyword) ||
        el?.description?.toLowerCase()?.includes(keyword)
    );
    const result = yield axiosCallGet(path, payload.token);
    let results = [...result.data.results, ...filteredTools];
    yield put(globalSearchActions.setResults(results));
    const searchComponentsFromHome = yield select(
      (state) => state.home.globalSearchComponents
    );
    let titlesMap = [
      {
        type: "NEWS",
        title: searchComponentsFromHome.titleNews,
        number: results.filter((result) => result.type === "NEWS").length,
      },
      {
        type: "PAGE",
        title: searchComponentsFromHome.titlePagine,
        number: results.filter((result) => result.type === "PAGE").length,
      },
      {
        type: "EVENT",
        title: searchComponentsFromHome.titleEvents,
        number: results.filter((result) => result.type === "EVENT").length,
      },
      {
        type: "DOCUMENT",
        title: searchComponentsFromHome.titleDocuments,
        number: results.filter((result) => result.type === "DOCUMENT").length,
      },
      {
        type: "TOOLS",
        title: searchComponentsFromHome.titleTools,
        number: results.filter((result) => result.type === "TOOLS").length,
      },
    ];
    titlesMap = titlesMap.filter((el) => el.number > 0);
    titlesMap.sort((a, b) => b.number - a.number);
    yield put(globalSearchActions.setTitlesMap(titlesMap));
    yield put(globalSearchActions.setResultType(titlesMap[0]?.type));
  } catch (e) {
    console.log(e);
  }
}

export default function* globalSearchSaga() {
  yield takeLatest(FETCH_RESULTS, fetchResults);
}
