import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga";

import rootReducer from "./reducer";
import { FETCH_ACTIVE_TOKEN } from "../pages/Login/constants";
import { saveAd } from "../pages/FormAnnuncio/FormAnnuncioSlice";
const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];

const store = configureStore({
  // insert here the map of reducers that derives from the Slice we create
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FETCH_ACTIVE_TOKEN, saveAd.type],
      },
    }).concat(sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export default store;
