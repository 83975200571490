import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1280px) {
    width: 384px;
    box-sizing: border-box;
    min-width: 384px;
  }
  width: 100%;
  margin-bottom: 32px;
`;

export const InfoRecapContainer = styled.div`
  padding: 32px;
  background: var(--alternative-bg-color);
`;

export const Label = styled.div`
  &:first-of-type {
    margin-top: 0px;
  }
  margin-top: 32px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 12px;
`;

export const Content = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

export const RedButton = styled.button`
  background-color: rgb(218, 41, 28);
  background-image: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    50%,
    rgba(255, 255, 255, 0) 100%
  );

  background-blend-mode: overlay;
  padding: 20px 0px;
  width: 100%;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
`;

export const GreyButton = styled.button`
  background-color: rgba(112, 112, 112, 1);
  background-image: none;

  background-blend-mode: overlay;
  padding: 20px 0px;
  width: 100%;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
`;
