//"213.254.15.121"; //VPN SPINDOX

//DA ROGLIERE I SEGUENTI IP, DOPO RILASCIO DEL 21/02/24 RELATIVO A GLOBAL PROTECT
//"2.40.244.64/28",
//"93.145.1.0/24",
//"165.85.167.0/24",
//"63.80.105.103/32",

// GLOBAL PROTECT SPORT CARS & GLOBAL PROTECT RACING
export const internalIPs = [
  "2.40.244.64/28",
  "58.246.27.160/29",
  "93.145.1.0/24",
  "165.85.167.0/24",
  "63.80.105.103/32",
  "66.159.193.43/32",
  "66.159.193.153/32",
  "208.127.122.15/32",
  "208.127.122.113/32",
  "165.85.41.146/32",
  "165.85.253.17/32",
  "165.85.253.16/32",
  "165.85.235.2/32",
  "165.85.222.9/32",
  "165.85.222.40/32",
  "165.85.222.39/32",
  "165.85.222.33/32",
  "165.85.222.32/32",
  "165.85.222.19/32",
  "165.85.222.18/32",
  "165.85.222.15/32",
  "165.85.222.14/32",
  "165.85.222.12/32",
  "165.85.21.206/32",
  "165.85.21.205/32",
  "165.85.173.98/32",
  "165.85.167.27/32",
  "165.85.167.229/32",
  "165.85.167.228/32",
  "165.85.167.227/32",
  "165.85.167.226/32",
  "165.85.167.225/32",
  "165.85.167.224/32",
  "165.85.167.19/32",
  "165.85.167.125/32",
  "165.85.167.124/32",
  "165.85.167.104/32",
  "165.85.158.8/32",
  "165.85.156.39/32",
  "165.85.156.152/32",
  "165.85.141.82/32",
  "165.85.141.109/32",
  "165.85.138.11/32",
  "165.85.138.109/32",
  "165.85.136.214/32",
  "165.85.136.213/32",
  "165.85.134.203/32",
  "165.85.131.202/32",
  "165.1.153.66/32",
  "130.41.253.69/32",
  "130.41.253.250/32",
  "130.41.135.12/32",
  "130.41.135.109/32",
  "130.41.102.40/32",
  "130.41.102.184/32",
  "130.41.102.183/32",
  "130.41.102.179/32",
  "130.41.102.177/32",
  "130.41.102.176/32",
  "130.41.102.175/32",
  "130.41.102.174/32",
  "130.41.102.160/32",
  "130.41.102.159/32",
  "130.41.102.148/32",
  "114.141.126.13/32",
  "114.141.126.107/32",
  "66.159.193.54/32",
  "66.159.193.223/32",
  "64.187.134.31/32",
  "64.187.133.20/32",
  "208.127.32.72/32",
  "208.127.32.23/32",
  "208.127.174.216/32",
  "208.127.174.215/32",
  "208.127.122.27/32",
  "208.127.122.26/32",
  "208.127.12.23/32",
  "208.127.12.22/32",
  "165.85.66.60/32",
  "165.85.66.59/32",
  "165.85.56.2/32",
  "165.85.56.10/32",
  "165.85.34.48/32",
  "165.85.34.175/32",
  "165.85.31.24/32",
  "165.85.31.23/32",
  "165.85.189.164/32",
  "165.85.189.163/32",
  "165.85.167.20/32",
  "165.85.167.191/32",
  "165.85.167.190/32",
  "165.85.167.181/32",
  "165.85.167.180/32",
  "165.85.167.105/32",
  "165.85.156.66/32",
  "165.85.156.65/32",
  "165.85.141.90/32",
  "165.85.141.89/32",
  "165.85.139.13/32",
  "165.85.139.12/32",
  "165.85.138.45/32",
  "165.85.138.44/32",
  "165.85.136.204/32",
  "165.85.136.203/32",
  "165.85.133.56/32",
  "165.85.133.55/32",
  "165.85.128.6/32",
  "165.85.128.4/32",
  "165.1.253.51/32",
  "165.1.253.167/32",
  "165.1.152.94/32",
  "165.1.152.221/32",
  "137.83.242.235/32",
  "137.83.242.234/32",
  "137.83.242.131/32",
  "137.83.242.130/32",
  "130.41.247.201/32",
  "130.41.247.200/32",
  "130.41.239.217/32",
  "130.41.239.216/32",
  "130.41.239.215/32",
  "130.41.239.214/32",
  "130.41.239.209/32",
  "130.41.239.208/32",
  "130.41.135.24/32",
  "130.41.134.220/32",
];
