import {
  FETCH_COMPONENTS,
  SAVE_FAV_TOOLS_ORDER,
  SAVE_FAV_TOOL,
} from "./constants";

export const fetchStrumentiComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const SaveFavToolsOrder = (payload) => ({
  type: SAVE_FAV_TOOLS_ORDER,
  payload,
});

export const SaveFavTool = (payload) => ({
  type: SAVE_FAV_TOOL,
  payload,
});
