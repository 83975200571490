import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 64px;
  width: auto;
  padding: 0px 15px;
  height: 78.87px;
  background: #181818;
  position: fixed;
  right: 0;
  bottom: 6%;
`;

export const Icon = styled.img.attrs((props) => {
  if (props.icon === "success") {
    return { src: `/img/notifications/message-icon-green.svg` };
  } else {
    return { src: `/img/notifications/message-icon-red.svg` };
  }
})``;

export const MessageText = styled.span`
  font-size: 12;
  line-height: 17px;
  color: #ffffff;
`;

export const CloseButton = styled.img.attrs({
  src: `/img/notifications/close-icon.svg`,
})`
  cursor: pointer;
`;
