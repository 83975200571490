import {
  FETCH_CORE_COMPONENTS,
  SET_LANGUAGE,
  SET_HEADER_LINKS,
} from "./constants";

export const fetchCoreComponents = (payload) => ({
  type: FETCH_CORE_COMPONENTS,
  payload,
});

export const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload,
});

export const setHeaderLinks = (payload) => ({
  type: SET_HEADER_LINKS,
  payload,
});
