import {
  FETCH_COMPONENTS,
  RELOAD_COMPONENTS,
  SET_USER_INFORMATION_TEST_HMI,
  DELETE_SUBSCRIPTION,
} from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});
export const reloadComponents = (payload) => ({
  type: RELOAD_COMPONENTS,
  payload,
});

export const SetUserInformationTestHmi = (payload) => ({
  type: SET_USER_INFORMATION_TEST_HMI,
  payload,
});

export const deleteSubscription = (payload) => ({
  type: DELETE_SUBSCRIPTION,
  payload,
});
