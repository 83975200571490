import styled from "styled-components";

export const MainWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  z-index: 1;
  background: var(--main-bg);
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0px;
`;

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: auto;
  img.mobile-logo {
    margin-top: 44px;
  }
`;

export const SearchBox = styled.div`
  background: transparent;
  border: 1px solid #8f8f8f;
  width: 800px;
  padding: 32px;
  position: relative;
  @media (max-width: 1279px) {
    max-width: -webkit-fill-available;
  }
`;
export const Inputs = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: solid 1px #8f8f8f;

  .searchLabel {
    ${(props) =>
      props.color === "true" ? "color: rgb(218, 41, 28)" : "color: #707070"};
  }
  img {
    cursor: pointer;
  }
  span {
    margin-left: 15px;
    text-transform: uppercase;
    color: #707070;
    font-size: 12px;
    cursor: pointer;
    @media (max-width: 1279px) {
      max-width: unset;
    }
  }
  @media (max-width: 1279px) {
    width: -webkit-fill-available;
  }
`;

export const SearchInput = styled.input.attrs({
  type: "text",
})`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #181818;
  &:focus {
    outline: none;
  }
`;

export const CloseButton = styled.button`
  border: 0px;
  cursor: pointer;
  margin-top: 40px;
`;

export const Suggested = styled.div`
  h4 {
    text-transform: uppercase;
    color: #707070;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
  }
  p {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 70px;
  max-width: 900px;
  margin-top: 90px;
  @media (max-width: 1279px) {
    width: 90%;
    gap: 35px;
    margin-top: 30px;
  }
`;
