import styled from "styled-components";
export const ContainerForbidden = styled.div`
  width: 800px;
  margin: 0 auto 96px;
  ${(props) =>
    props.center
      ? "display: flex; flex-direction:column; align-items: center"
      : "display: block"};
  margin-top: 130px;
  @media (max-width: 1279px) {
    margin-top: 80px;
    width: 90%;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;

export const Container = styled.section`
  width: 600px;
  margin: 0 auto 96px;
  ${(props) =>
    props.center
      ? "display: flex; flex-direction:column; align-items: center"
      : "display: block"};
  & select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #8f8f8f;
    padding-left: 0;
    font-size: 14px;
  }
  & input {
    letter-spacing: 0.5px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (max-width: 1279px) {
    max-width: 600px;
    width: 80%;
    margin: 0 auto 96px;
    h2 {
      font-size: 22px;
      margin: 40px 0 20px;
    }
  }
`;
export const TitleForm = styled.h2`
  max-width: 800px;
  font-size: 36px;
  font-family: ferrari-sans-medium, sans-serif;
  line-height: 34px;
  display: flex;
  flex-direction: column;
  text-align: center;
  div {
    text-align: center;
    p {
      line-height: 20px;
      margin-top: 30px;
      font-family: ferrari-sans-regular, sans-serif;
      font-size: 13px;
      font-weight: 400;
    }
  }

  @media (max-width: 1279px) {
    width: auto;
    padding: 0 20px;
  }
`;
export const InputSectionCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  padding: 0px 0px 18px;
  gap: 12px;
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid var(--main-color);
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    padding: 0;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--main-color);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  span {
    max-width: calc(100% - 28px);
    & a {
      color: var(--main-color);
      text-decoration: revert;
    }
  }
`;

export const SendButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  margin-top: 30px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  background-blend-mode: overlay, overlay, normal;
  background: #d4d2d2;
  color: #181818;
  @media (max-width: 1279px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ComponentContainer = styled.div`
  h3 {
    text-transform: uppercase;
    span {
      text-transform: lowercase;
      color: #8f8f8f;
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
`;

export const PrivacyRules = styled.div`
  font-size: 13px;
  line-height: 20px;
  margin-top: 20px;
`;
export const TextDescr = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const RedirectButton = styled.button.attrs((props) => ({
  type: "submit",
}))`
  width: 100%;
  max-width: 240px;
  padding: 20px 0px;
  cursor: pointer;
  border: 0px;
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: radial-gradient(
      199.89% 50% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      349.37% 116.89% at 50% 100%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    rgb(218, 41, 28);
  background-blend-mode: overlay, overlay, normal;
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
