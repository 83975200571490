import { FETCH_COMPONENTS, FETCH_SET_EVENT_PREFERENCES } from "./constants";

export const fetchComponents = (payload) => ({
  type: FETCH_COMPONENTS,
  payload,
});

export const FetchSetEventPreferences = (payload) => ({
  type: FETCH_SET_EVENT_PREFERENCES,
  payload,
});