import styled from "styled-components";

export const NoAdsWrapper = styled.div`
  max-width: 336px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`

export const CardImage = styled.div`
  position: relative;
  .img-plus {
    position: absolute;
    top: 3px;
    right: 15px;
  }
`
export const Description = styled.div`
  text-align: center;
  margin-bottom: 32px;
`