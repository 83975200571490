import { useSelector } from "react-redux";
import { getEmptyFavLabels } from "../../../../pages/Rubrica/selectors";
// style
import { Wrapper, TitleEmptyFav, TextEmptyFav } from "./style";

function EmptyFavourites() {
  const searchComponent = useSelector(getEmptyFavLabels());
  function html() {
    return { __html: searchComponent.addFavoriteDescription };
  }
  return (
    <Wrapper>
      <img src="/img/rubrica-no-prefs.png" />
      <TitleEmptyFav>{searchComponent.addFavoriteLabel}</TitleEmptyFav>
      <TextEmptyFav dangerouslySetInnerHTML={html()}></TextEmptyFav>
    </Wrapper>
  );
}

export default EmptyFavourites;
