import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 50px auto;
  padding: 25px;
  column-gap: 80px;
  column-count: 3;
  column-width: auto;
  column-rule: 1px solid lightgray;
  ul {
    font-size: 15px;
    list-style-type: none;
    li {
      line-height: 40px;
      margin-left: 20px;
      a {
        color: var(--main-color);
      }
    }
  }
  ul.second-level {
    font-size: 13px;
    li {
      list-style-type: none;
    }
  }
`;

export const FirstLevel = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  margin: 25px 0px 5px 25px;
  padding: 15px;
  border-left: 4px solid red;
  a {
    color: var(--main-color);
  }
`;
