import { Container3dMap } from "./style";

function LayoutMap3d({ children, headerAndFooterHeight }) {
  return (
    <Container3dMap heightdynamic={headerAndFooterHeight}>
      {children}
    </Container3dMap>
  );
}

export default LayoutMap3d;
