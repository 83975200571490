import styled from "styled-components";

export const DateCategory = styled.div`
  margin: 24px 0px 16px;
  color: #da291c;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  span:nth-child(2) {
    height: 0px;
    border-bottom: 1px solid #da291c;
    width: 96px;
    margin: 0px 8px;
  }
`;
