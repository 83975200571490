import styled from "styled-components";

export const TagContainer = styled.div`
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1279px) {
    margin-bottom: 40px !important;
  }
`;

export const TagLabel = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: rgb(143, 143, 143);
`;
