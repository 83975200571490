import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { myPageActions } from "../../../../../pages/MyPage/myPageSlice";
import { postContacts } from "../../../../../pages/MyPage/actions";
// style
import { InputSection, Select } from "./style";

function Contacts({ data }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const [postBody, setPostBody] = useState({});

  const labelMap = useMemo(() => {
    return {
      [data?.defaulttextLabel]: 0,
      [data?.telLabel]: 1,
      [data?.mobileLabel]: 2,
      [data?.cordLessLabel]: 3,
      [data?.faxLabel]: 4,
    };
  }, [
    data?.cordLessLabel,
    data?.defaulttextLabel,
    data?.faxLabel,
    data?.mobileLabel,
    data?.telLabel,
  ]);

  const handleModifyButtonClick = (event) => {
    const elementsArray = document.querySelectorAll(".contacts");
    const building = document.getElementById("ddlBuilding");
    if (event.target.textContent === data.modify.toUpperCase()) {
      event.target.textContent = "salva";
      building.classList.toggle("highlight");
      building.disabled = false;
      elementsArray.forEach((element) => {
        element.disabled = false;
        element.classList.toggle("highlight");
      });
    } else {
      event.target.textContent = data.modify;
      building.classList.toggle("highlight");
      building.disabled = true;
      elementsArray.forEach((element) => {
        element.disabled = true;
        element.classList.toggle("highlight");
      });
      dispatchValuedContacts(elementsArray);
      dispatch(postContacts({ postBody, token }));
    }
  };

  const dispatchValuedContacts = useCallback(
    (contactsArray) => {
      const valuedFields = [];
      Array.from(contactsArray)
        .filter((element) => element.value !== "")
        .forEach((input) => valuedFields.push(input.labels[0].innerText));

      dispatch(myPageActions.setValuedContactFields(valuedFields));
    },
    [dispatch]
  );

  const handleInputChange = (event) => {
    setPostBody((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    dispatch(myPageActions.setLabelMap(labelMap));
    dispatchValuedContacts(document.querySelectorAll(".contacts"));
  }, [dispatch, dispatchValuedContacts, labelMap]);

  return (
    <>
      <h3>
        {data?.title}
        <span onClick={handleModifyButtonClick}>{data?.modify}</span>
      </h3>
      {data?.telLabel && (
        <InputSection>
          <label>
            {data?.telLabel}
            <input
              onChange={handleInputChange}
              name="phone"
              className="contacts"
              type="text"
              defaultValue={data?.phone}
              disabled
            />
          </label>
        </InputSection>
      )}
      {data?.mobileLabel && (
        <InputSection>
          <label>
            {data?.mobileLabel}
            <input
              onChange={handleInputChange}
              className="contacts"
              type="text"
              name="mobilePhone"
              defaultValue={data?.mobilePhone}
              disabled
            />
          </label>
        </InputSection>
      )}
      {data?.cordLessLabel && (
        <InputSection>
          <label>
            {data?.cordLessLabel}
            <input
              onChange={handleInputChange}
              className="contacts"
              type="text"
              name="dect"
              defaultValue={data?.dect}
              disabled
            />
          </label>
        </InputSection>
      )}
      {data?.faxLabel && (
        <InputSection>
          <label>
            {data?.faxLabel}
            <input
              onChange={handleInputChange}
              className="contacts"
              type="text"
              name="fax"
              defaultValue={data?.fax}
              disabled
            />
          </label>
        </InputSection>
      )}
      <InputSection>
        <label>{data?.mailLabel}</label>
        <input type="text" defaultValue={data?.email} disabled />
      </InputSection>
      <InputSection>
        <label>{data?.selectBuildingLabel}</label>
        <div>
          <Select
            id="ddlBuilding"
            name="building"
            defaultValue={data?.building}
            onChange={handleInputChange}
            disabled
          >
            {data?.building === undefined && <option value={""}>{""}</option>}
            {React.Children.toArray(
              data?.items?.map((v) => (
                <option value={v.label}>
                  {v.id} - {v.label}
                </option>
              ))
            )}
          </Select>
        </div>
      </InputSection>
    </>
  );
}

export default React.memo(Contacts);
