import { AsideSlot, FocusContainer } from "./style";
import PropTypes from "prop-types";

//components
import EventSlot from "./eventSlot/EventSlot";
import TitleSection from "../titleSection/TitleSection";
import CarouselWrapper from "../../../UI/carouselWrapper/CarouselWrapper";

import useMediaQuery from "../../../../hooks/useMediaQuery";

function EventsFocus({ data }) {
  const titleData = { title: data.title, type: "h2" };
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  return (
    <>
      <TitleSection data={titleData} />
      {isDesktop ? (
        <FocusContainer>
          {data?.events[0] ? (
            <EventSlot
              event={data?.events[0]}
              imageOverride={data?.firstEventImgOverride}
            />
          ) : (
            <></>
          )}
          <AsideSlot>
            {data?.events[1] ? (
              <EventSlot
                event={data?.events[1]}
                imageOverride={data?.secondEventImgOverride}
              />
            ) : (
              <></>
            )}
          </AsideSlot>
        </FocusContainer>
      ) : (
        <CarouselWrapper itemsLength={data?.events?.length}>
          {data?.events[0] ? (
            <EventSlot
              event={data?.events[0]}
              imageOverride={data?.firstEventImgOverride}
            />
          ) : (
            <></>
          )}
          {data?.events[1] ? (
            <EventSlot
              event={data?.events[1]}
              imageOverride={data?.firstEventImgOverride}
            />
          ) : (
            <></>
          )}
        </CarouselWrapper>
      )}
    </>
  );
}

EventsFocus.propTypes = {
  data: PropTypes.exact({
    firstEventImgOverride: PropTypes.string,
    secondEventImgOverride: PropTypes.string,
    title: PropTypes.string,
    events: PropTypes.array,
    position: PropTypes.string,
  }),
};

export default EventsFocus;
