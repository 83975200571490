import styled from "styled-components";

export const Wrapper = styled.div`
  height: 0px;
  border-bottom: 1px solid rgb(212, 210, 210);
  margin: 2em 0px;
  float: left;
  clear: both;
  width: 100%;
`;
