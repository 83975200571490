import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  HeadingText,
  HeadingDetailsText,
  HeadingDetailsLine,
  HeadingDetailsLinks,
  CardDropDown,
  CardDetails,
  CardDetailsEmail,
  CardDetailsDove,
  CardBody,
  CardDetailsFrstRow,
  CardDetailsFrstRowFisso,
  CardDetailsFrstRowCell,
  CardLocation,
  CardDetailsSecondRow,
  HeadingBody,
  CardHeadingDetailsInitialCircle,
  CardHeadingDetailsInitialText,
  CardEmail,
  CardHeadingDetailsImg,
  DefaultNumText,
} from "./style";

// store
import { rubricaActions } from "../../../../../pages/Rubrica/rubricaSlice";
//actions to call for ext services (MW)
import { SaveFavContact } from "../../../../../pages/Rubrica/actions";
import { getContactLabels } from "../../../../../pages/Rubrica/selectors";
import TeamsIcon from "../../../../assets/teamsIcon";
import PhoneIcon from "../../../../assets/phoneIcon";
import useMediaQuery from "../../../../../hooks/useMediaQuery";

const TEAMS_DEEP_LINK_BASE = "https://teams.microsoft.com/l/call/0/0?users=";

function ContactCard({ data, showDropDownArrow }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const showSearchResult = useSelector(
    (state) => state.rubrica.showSearchResult
  );
  const contactLabels = useSelector(getContactLabels());
  const [hideDetails, sethideDetails] = useState(false);
  const [isFavourite, SetIsFavourite] = useState(data?.isFavourite);

  const teamsDeepLink = TEAMS_DEEP_LINK_BASE.concat(data?.email);
  const mailParam = "mailto:" + data?.email;

  const handleOnClickFav = (event) => {
    event.preventDefault();
    dispatch(rubricaActions.setFavouriteContact(data));
    dispatch(SaveFavContact({ data, token }));
    if (showSearchResult || !showDropDownArrow) {
      SetIsFavourite(!isFavourite);
    }
  };

  const defaultNumMap = {
    1: data?.phone,
    2: data?.mobilePhone,
    3: data?.dect,
    4: data?.fax,
  };

  const defaultNumVal = defaultNumMap[data?.defaultNumber];
  const isDesktop = useMediaQuery("(min-width: 1280px)");
  return (
    <Container hideDetails={hideDetails}>
      <HeadingText>
        {!isDesktop && (
          <HeadingDetailsLinks>
            <a href={teamsDeepLink}>
              <TeamsIcon />
            </a>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleOnClickFav}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
                fill="#C4C4C4"
              />
              <path
                d="M19.8096 11.9184C19.8695 11.7342 20.1302 11.7342 20.19 11.9184L22.0504 17.644C22.0772 17.7264 22.154 17.7822 22.2406 17.7822H28.2608C28.4546 17.7822 28.5351 18.0301 28.3784 18.144L23.5079 21.6826C23.4378 21.7335 23.4085 21.8238 23.4353 21.9062L25.2956 27.6318C25.3555 27.816 25.1446 27.9692 24.9879 27.8554L20.1174 24.3168C20.0473 24.2658 19.9524 24.2658 19.8823 24.3168L15.0118 27.8554C14.8551 27.9692 14.6442 27.816 14.7041 27.6318L16.5644 21.9062C16.5912 21.8238 16.5618 21.7335 16.4917 21.6826L11.6213 18.144C11.4645 18.0301 11.5451 17.7822 11.7388 17.7822H17.7591C17.8457 17.7822 17.9225 17.7264 17.9493 17.644L19.8096 11.9184Z"
                fill={isFavourite ? "var(--main-color)" : "transparent"}
                stroke="var(--cta-color)"
                strokeWidth="1.68"
              />
            </svg>
          </HeadingDetailsLinks>
        )}
        {data?.idPicture.length > 0 ? (
          <CardHeadingDetailsImg src={data.idPicture} />
        ) : (
          <CardHeadingDetailsInitialCircle>
            <CardHeadingDetailsInitialText>
              {data?.initialsName}
            </CardHeadingDetailsInitialText>
          </CardHeadingDetailsInitialCircle>
        )}
        <HeadingDetailsText>
          <div className="name-surname">
            {data?.name} {data?.surname}
          </div>
          <HeadingBody>
            <span>{data?.company}</span>
            <HeadingDetailsLine />
            <span>{data?.costCenter}</span>
          </HeadingBody>
          <div>
            <PhoneIcon />
            <DefaultNumText>{defaultNumVal}</DefaultNumText>
          </div>
        </HeadingDetailsText>
        {isDesktop && (
          <HeadingDetailsLinks>
            <a href={teamsDeepLink}>
              <TeamsIcon />
            </a>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleOnClickFav}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
                fill="#C4C4C4"
              />
              <path
                d="M19.8096 11.9184C19.8695 11.7342 20.1302 11.7342 20.19 11.9184L22.0504 17.644C22.0772 17.7264 22.154 17.7822 22.2406 17.7822H28.2608C28.4546 17.7822 28.5351 18.0301 28.3784 18.144L23.5079 21.6826C23.4378 21.7335 23.4085 21.8238 23.4353 21.9062L25.2956 27.6318C25.3555 27.816 25.1446 27.9692 24.9879 27.8554L20.1174 24.3168C20.0473 24.2658 19.9524 24.2658 19.8823 24.3168L15.0118 27.8554C14.8551 27.9692 14.6442 27.816 14.7041 27.6318L16.5644 21.9062C16.5912 21.8238 16.5618 21.7335 16.4917 21.6826L11.6213 18.144C11.4645 18.0301 11.5451 17.7822 11.7388 17.7822H17.7591C17.8457 17.7822 17.9225 17.7264 17.9493 17.644L19.8096 11.9184Z"
                fill={isFavourite ? "var(--main-color)" : "transparent"}
                stroke="var(--cta-color)"
                strokeWidth="1.68"
              />
            </svg>
          </HeadingDetailsLinks>
        )}
      </HeadingText>
      {showDropDownArrow && (
        <CardDropDown hideDetails={hideDetails}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => sethideDetails(!hideDetails)}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 6L9.67956 16.9015C10.2298 17.5721 11.065 18 12 18C12.935 18 13.7702 17.5721 14.3204 16.9015L24 6L12 12L0 6Z"
              fill="var(--main-color)"
            />
          </svg>
        </CardDropDown>
      )}
      <CardDetails hideDetails={hideDetails}>
        <CardDetailsFrstRow>
          <CardDetailsFrstRowFisso>
            <CardLocation>{contactLabels?.internalNumberLabel}</CardLocation>
            <CardBody>{data?.phone}</CardBody>
          </CardDetailsFrstRowFisso>
          <CardDetailsFrstRowCell>
            <CardLocation>{contactLabels?.externalNumberLabel}</CardLocation>
            <CardBody>{data?.externalNumber}</CardBody>
          </CardDetailsFrstRowCell>
        </CardDetailsFrstRow>
        <CardDetailsSecondRow>
          <CardDetailsFrstRowCell>
            <CardLocation>{contactLabels?.mobileLabel}</CardLocation>
            <CardBody>{data?.mobilePhone}</CardBody>
          </CardDetailsFrstRowCell>
          <CardDetailsFrstRowCell>
            <CardLocation>{contactLabels?.superCordlessLabel}</CardLocation>
            <CardBody>{data?.dect}</CardBody>
          </CardDetailsFrstRowCell>
          {isDesktop && (
            <CardDetailsFrstRowCell>
              <CardLocation>{contactLabels?.faxLabel}</CardLocation>
              <CardBody>{data?.fax}</CardBody>
            </CardDetailsFrstRowCell>
          )}
        </CardDetailsSecondRow>
        {!isDesktop && (
          <CardDetailsFrstRowCell>
            <CardLocation>{contactLabels?.faxLabel}</CardLocation>
            <CardBody>{data?.fax}</CardBody>
          </CardDetailsFrstRowCell>
        )}
        <CardDetailsEmail>
          <CardDetailsFrstRowCell>
            <CardLocation>{contactLabels?.emailLabel}</CardLocation>
            <CardEmail onClick={() => (window.location = mailParam)}>
              {data?.email}
            </CardEmail>
          </CardDetailsFrstRowCell>
        </CardDetailsEmail>
        <CardDetailsDove>
          <CardDetailsFrstRowCell>
            <CardLocation>{contactLabels?.positionLabel}</CardLocation>
            <CardBody>{data?.building}</CardBody>
          </CardDetailsFrstRowCell>
        </CardDetailsDove>
      </CardDetails>
    </Container>
  );
}

export default ContactCard;
