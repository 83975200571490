import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./action";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";
import LayoutFormTestHmi from "../../components/UI/formTestHmi/LayoutFormTestHmi";
import DetailsHeader from "../../components/UI/event/eventDetails/header/DetailsHeader";
import { heroComponent, selectTopComponents } from "./selectors";

export default function FormTestHmi({ path }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.activeToken);
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const topComponents = useSelector(selectTopComponents());
  const heroComp = useSelector(heroComponent());
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const components = useSelector((state) => state.FormTestHmi.components);

  useMemo(() => {
    dispatch(fetchComponents({ path, token }));
  }, [dispatch, path]);

  const formComponent = components?.components?.find(
    (v) => v.resourceType === "fi-form-hmi"
  );

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);

  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("FormTestHmi");

  return (
    <>
      {!status && <PageLoader />}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        formComponent?.data && (
          <>
            <DetailsHeader
              headerComponents={topComponents}
              goBackText={heroComp?.data.textHref || "TORNA INDIETRO"}
              link={heroComp?.data.link || ""}
              onlyBack={true}
              noCreateWithoutLink={true}
            />
            <LayoutFormTestHmi data={formComponent?.data} />
          </>
        )
      )}
    </>
  );
}
