import { Container, TitleForm, GenericTitle, RecapContainer } from "./style";
import { ButtonsCta } from "./buttonsCta/buttonsCta";
import DetailInfoGiftHouse from "./detailsInfo/DetailInfoGiftHouse";
import { DetailInfoLightExperience } from "./detailsInfo/DetailInfoLightExperience";
import { DialogNote } from "./DialogNote/DialogNote";
import { useDispatch, useSelector } from "react-redux";
import { RecapAndDetailGiftHouseLeActions } from "../../../pages/RecapAndDetailGiftHouseLe/RecapAndDetailGiftHouseLeSlice";
import { useEffect } from "react";

export function LayoutRecapAndDetailGiftHouseLe({
  data,
  isRecapPage,
  buttonsData,
}) {
  const dispatch = useDispatch();
  const showModal = useSelector(
    (state) => state.RecapAndDetailGiftHouseLe.showModal
  );

  function generateHtml(argument) {
    return { __html: argument };
  }

  const canEditEvent = data?.canEditAction === true ? true : false;

  if (!isRecapPage && !canEditEvent && showModal === null) {
    dispatch(RecapAndDetailGiftHouseLeActions.setShowModal(true));
  }
  useEffect(() => {}, [canEditEvent, showModal]);

  return (
    <Container>
      {showModal && <DialogNote data={data?.cannotEditModal} />}
      <GenericTitle>
        <h5>{data?.title}</h5>
      </GenericTitle>
      <RecapContainer>
        <GenericTitle>
          <p dangerouslySetInnerHTML={generateHtml(`${data?.description}`)} />
        </GenericTitle>
      </RecapContainer>
      <RecapContainer>
        <TitleForm>{data?.detailsTitle}</TitleForm>
        <DetailInfoGiftHouse data={data?.giftHouse} />
        {data?.lightExperience?.appointment && (
          <DetailInfoLightExperience data={data?.lightExperience} />
        )}
      </RecapContainer>
      <ButtonsCta
        data={buttonsData}
        pageData={data}
        isRecapPage={isRecapPage}
        canEditEvent={canEditEvent}
      />
    </Container>
  );
}
