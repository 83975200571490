import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  a {
    border: 1px solid;
    padding: 24px;
    display: block;
    text-align: center;
    color: var(--main-color);
  }
`;
