import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 384px;
  opacity: 0;
  transition: opacity 1s;
  @media (min-width: 769px) {
    width: 304px;
    padding: 32px;
    box-sizing: border-box;
  }
  @media (min-width: 1280px) {
    width: 384px;
  }
  @media (max-width: 769px) {
    height: auto;
    padding: 24px;
  }
  background: var(--alternative-bg-color);
  overflow: hidden;
  a {
    width: 90%;
    @media (max-width: 1279px) {
      width: 100%;
      margin: 0;
    }
    display: flex;
    align-items: center;
    color: var(--cta-color);
    text-transform: uppercase;
    font-size: 12px;
  }
  a:hover circle {
    stroke-dashoffset: 0;
  }
`;

export const CardCTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 256px;
  @media (min-width: 769px) and (max-width: 1279px) {
    min-height: 265px;
  }
`;

export const CTATitle = styled.div`
  font: 11px / 11px ferrari-sans-regular;
  color: var(--main-color);
  width: 90%;
  @media (max-width: 1279px) {
    width: 100%;
  }
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  span:first-of-type {
    margin-right: 10px;
    margin-bottom: 2px;
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  margin-top: 26px;
  @media (max-width: 1280px) {
    margin-top: 16px;
  }
`;

export const SpanCtaIcon = styled.span`
  appearance: none;
  margin: 0px;
  outline: 0px;
  border: 2px solid rgb(239, 237, 237);
  background: transparent;
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 8px;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  font-size: 0px;
  margin-left: 10px;
`;

export const CtaCircle = styled.span`
  position: absolute;
  inset: -3px;
  circle {
    stroke: rgb(218, 41, 28);
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    stroke-width: 1.5px;
    fill: none;
    transition: stroke-dashoffset 0.3s ease 0s;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  a:hover {
  }
`;

export const DescrWrapper = styled.div`
  font-size: 11px;
  line-height: 20px;
  @media (max-width: 1279px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const Title = styled.div`
  width: 90%;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-color);
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const Text = styled.div`
  width: 90%;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-top: 16px;
  color: var(--main-color);
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const BackGroundText = styled.div`
  position: absolute;
  width: 776.1px;
  height: 199px;
  left: -231px;
  top: 140px;
  font-weight: 400;
  font-size: 497.5px;
  line-height: 199px;
  display: flex;
  align-items: flex-end;
  color: var(--main-color);
  opacity: 0.05;
  @media (max-width: 1279px) {
    left: -262px;
  }
`;
