import styled from "styled-components";

export const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 48px;
  margin-top: 0;
  @media (max-width: 1279px) {
    font-size: 16px;
  }
`;

export const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  @media (max-width: 1279px) {
    font-size: 11px;
  }
`;

export const Divider = styled.div`
  display: ${(props) => (props.eventType === "eventLocal" ? "" : "none")};
  height: 0px;
  border-bottom: 1px solid;
  margin: 64px 0px;
  float: left;
  clear: both;
  width: 100%;
`;
