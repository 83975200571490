const LoadThronPlayer = (callback) => {
  const scriptId = "THRON-PLAYER-SCRIPT";
  const existingScript = document.getElementById(scriptId);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://ferrari-cdn.thron.com/shared/ce/bootstrap/1/scripts/embeds-min.js";
    script.id = scriptId;
    script.onload = () => {
      if (callback) callback(script.id);
    };
    document.body.appendChild(script);
  }
  if (existingScript && callback && window.THRONContentExperience)
    callback(existingScript.id);
  if (existingScript && !window.THRONContentExperience) {
    setTimeout(() => {
      LoadThronPlayer(callback);
    }, 100);
  }
};
export default LoadThronPlayer;
