import React from "react";
import { ContactWrapper, ContactCard, ContactCardInner } from "./style";
import PropTypes from "prop-types";

const CafeteriaContacts = ({ cafeteriaContacts }) => {
  return (
    <ContactWrapper>
      <div>{cafeteriaContacts?.title}</div>
      <ContactCard>
        <img src={cafeteriaContacts?.image} alt={cafeteriaContacts?.imageAlt} />
        <ContactCardInner>
          <div className="desktop-cta">{cafeteriaContacts?.mailLabel}</div>
          <div className="desktop-small">{cafeteriaContacts?.mail}</div>
          <div className="desktop-cta">{cafeteriaContacts?.phoneLabel}</div>
          <div className="desktop-small">{cafeteriaContacts?.phone}</div>
        </ContactCardInner>
      </ContactCard>
    </ContactWrapper>
  );
};

CafeteriaContacts.propTypes = {
  cafeteriaContacts: PropTypes.exact({
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    mailLabel: PropTypes.string,
    mail: PropTypes.string,
    title: PropTypes.string.isRequired,
    phoneLabel: PropTypes.string,
    phone: PropTypes.string,
  }),
};

export default CafeteriaContacts;
