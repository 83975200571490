import { useDispatch } from "react-redux";
import { companyMapActions } from "../../../../pages/CompanyMap/containerSlice";
import {
  Wrapper,
  Overlay,
  CloseButton,
  Content,
  ContentWrapper,
  Text,
} from "./style";

function BuildingModal({ data }) {
  function html() {
    return { __html: data?.description };
  }
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Overlay></Overlay>
      <Content>
        <CloseButton>
          <img
            onClick={() => dispatch(companyMapActions.setShowModal(false))}
            src="/img/close-light.svg"
            width="40"
            height="40"
          />
        </CloseButton>
        <ContentWrapper>
          <h3>{data.title}</h3>
          <img src={data.fileReference} />
        </ContentWrapper>
        <Text dangerouslySetInnerHTML={html()}></Text>
      </Content>
    </Wrapper>
  );
}

export default BuildingModal;
