import { Container } from "./style";

function TwoColumnContainer(props) {
  return (
    <Container gap={props.gap} flexDirection={props.flexDirection}>
      {props.children}
    </Container>
  );
}

export default TwoColumnContainer;
