//hooks
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { fetchComponents } from "./actions";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
// components
import LayoutContratto from "../../components/UI/contratto/LayoutContratto";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GenericError from "../GenericErrorPage";
import StrumentiTitle from "../../components/UI/strumenti/strumentiTitle/StrumentiTitle";
import TabsYear from "../../components/UI/company/communications/tabs/TabsYear";
import CardResult from "../../components/UI/company/communications/cardResult/CardResult";
// selectors
import {
  selectTabCategory,
  selectDocumentByYearAndCategory,
  selectFilterYear,
} from "./selectors";
import { selectAggregatedDirection } from "../MyPage/selectors";

const COMPONENTS_MAPPING = {
  "fi-tools-title-description": StrumentiTitle,
  "fi-corporate-comunications-catalogue": TabsYear,
};

function CompanyCom({ path }) {
  const dispatch = useDispatch();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const { components } = useSelector((state) => state.companyCom);
  const tabCategory = useSelector(selectTabCategory());
  const filterYear = useSelector(selectFilterYear());
  const currentDocuments = useSelector(
    selectDocumentByYearAndCategory(tabCategory, filterYear)
  );
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const { status, errorData } = GetStatusAndErrorData("companyCom");

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        components?.components && (
          <LayoutContratto heroImg={components?.settings?.heroImage}>
            {components?.components?.map((current, idx) => {
              const ReactGenericComponent =
                COMPONENTS_MAPPING[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                    setTextAlignToStart={true}
                  ></ReactGenericComponent>
                )
              );
            })}
            {React.Children.toArray(
              currentDocuments.map((document) => {
                return <CardResult data={document} />;
              })
            )}
          </LayoutContratto>
        )
      )}
    </>
  );
}

export default CompanyCom;
