import styled from "styled-components";

export const DetailContainer = styled.div`
  display: block;
  h1 {
    margin: 31px 0px 24px;
    text-transform: uppercase;
    font-family: ferrari-sans-medium;
  }

  @media screen and (min-width: 1280px) {
    width: 800px;
    margin: auto;
    h1 {
      margin: 9px 0px 48px !important;
    }
  }
  @media screen and (max-width: 1279px) {
    .news-detail-container h1 {
      font-size: 22px;
    }
  }
`;
