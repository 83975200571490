import { ComponentContainer, RadioButton, TextDescr } from "../style";

import { useDispatch, useSelector } from "react-redux";
import ErrorField from "./ErrorField/ErrorField";
import { setBodyPayload } from "../../../../pages/FormIscrizioneFamilyDay/FormIscrizioneFamilyDaySlice";

export default function Entrance({ data, allowed }) {
  const dispatch = useDispatch();
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneFamilyDay.bodyPayload
  );
  const entranceOptions = data?.event?.optionsBox?.[0];
  const aemOptionsTitles = [data?.gtLbl, data?.gesLbl, data?.scagliettiLbl];

  const handleEntrance = (e) => {
    const { name, value } = e.currentTarget;
    dispatch(
      setBodyPayload({
        ...bodyPayload,
        optionsValue: [
          {
            idOption: data?.event?.optionsBox?.[0]?.id,
            description: name,
            value: value,
          },
        ],
      })
    );
  };

  return (
    <ComponentContainer>
      <h3>{data?.entranceLbl}</h3>
      <TextDescr>{data?.entranceTxt}</TextDescr>
      {entranceOptions?.options?.map((option, i) => (
        <RadioButton key={option.id}>
          <input
            type="radio"
            id={`ingresso-${option.label}`}
            name={option.label}
            defaultValue={option.optionValue}
            checked={
              bodyPayload?.optionsValue?.[0]?.value === option.optionValue
            }
            onChange={(e) => handleEntrance(e)}
          />
          <label htmlFor={`ingresso-${option.label}`}>
            {aemOptionsTitles[i]}
          </label>
        </RadioButton>
      ))}
      {bodyPayload?.optionsValue?.[0]?.value === null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
