import PropTypes from "prop-types";
// utils
import useMediaQuery from "../../../../../hooks/useMediaQuery";

function NewsDetailTitle({ data }) {
  const isDesktop = useMediaQuery("(min-width: 1280px)");

  return isDesktop ? (
    <h1>{data.jcrTitle}</h1>
  ) : (
    <h1 style={{ fontSize: "22px", lineHeight: "28px", marginTop: "24px" }}>
      {data.jcrTitle}
    </h1>
  );
}

NewsDetailTitle.propTypes = {
  data: PropTypes.exact({
    jcrTitle: PropTypes.string.isRequired,
    position: PropTypes.string,
  }),
};

export default NewsDetailTitle;
