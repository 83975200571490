import styled from "styled-components";

export const List = styled.div`
  margin: 24px 0px;
  a,
  a:visited,
  a:link {
    color: var(--main-color);
  }
  & > a {
    opacity: 0;
    transition: 0.4s opacity;
    width: 100%;
    display: block;
  }
  img {
    width: 100%;
  }
  @media screen and (min-width: 1280px) {
    margin: 24px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 48px 32px;
    width: 1216px;
    a {
      max-width: 384px;
    }
  }
  @media screen and (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    h3 {
      font-size: 16px;
    }
  }
`;

export const ScrollLoader = styled.div`
  border-left: 1px solid #8f8f8f;
  width: 0;
  height: 125px;
  margin: 60px auto;
`;
