import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./ProgrammaCoinvolgimentoSlice";

const State = (state) => state.ProgrammaCoinvolgimento || initialState;

export const getForm = () =>
  createSelector(State, (state) => {
    return state.components?.components?.filter(
      (v) =>
        v.resourceType === "fi-engagement-programs/fi-engagement-programs-form"
    )?.[0];
  });
