import React from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { globalSearchActions } from "../../../../../../../pages/GlobalSearch/globalSearchSlice";
import linkTarget from "../../../../../../../utils/linkTarget";
// style
import { Wrapper, Title, Description } from "./style";
const YOUR_ECARD_TOOL_ID = "ferrari-intranet:tools/your-ecard";

function CardResultTool({ data, id }) {
  const lang = useSelector((state) => state.coreComponents.language);

  const redirectToUrl = () => {
    let linkResult = data?.linkHref;

    if (data?.id === YOUR_ECARD_TOOL_ID) {
      if (data?.isEcardActive) {
        linkResult = linkResult + "?isEcardActive=true";
      } else {
        linkResult = `/${lang}/tools/ecard-request`;
      }
    }
    // dispatch(globalSearchActions.resetState(true));
    window.location.replace(linkResult);
  };
  const HandleKeySearchItemPressed = (e) => {
    switch (e.keyCode) {
      case 13:
      case 32:
        e.preventDefault();
        redirectToUrl();
        break;
    }
  };
  return (
    <Wrapper
      tabIndex="0"
      id={id}
      target={linkTarget(data?.linkHref)}
      onKeyDown={(e) => HandleKeySearchItemPressed(e)}
      onClick={() => redirectToUrl()}
    >
      <img src={data?.image} />
      <div>
        <Title>{data?.title}</Title>
        {data?.description && <Description>{data?.description}</Description>}
      </div>
    </Wrapper>
  );
}

export default React.memo(CardResultTool);
