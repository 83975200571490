import PropTypes from "prop-types";
// style
import { Wrapper } from "./style";

function Image({ data }) {
  return (
    <Wrapper>
      <img src={data.fileReference} alt="user_image" />
    </Wrapper>
  );
}
Image.propTypes = {
  data: PropTypes.shape({
    imageFromPageImage: PropTypes.string,
    fileReference: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default Image;
