export default function PhoneIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M16 12.6667V16C7.16344 16 0 8.83656 0 0H4C5.47276 0 6.66667 1.19391 6.66667 2.66667C6.66667 3.34242 6.66667 5.33333 6.66667 5.33333C6.66667 5.33333 4.79701 5.33333 4 5.33333C3.63424 5.33333 3.33333 5.33333 3 5.33333C4.42509 8.768 7.24155 11.5408 10.682 12.9546C10.6719 12.86 10.6667 12.764 10.6667 12.6667C10.6667 11.9144 10.6667 10 10.6667 10C10.6667 10 12.6129 10 13.3333 10C14.8061 10 16 11.1939 16 12.6667Z"
        fill="var(--main-color)"
      />
    </svg>
  );
}
