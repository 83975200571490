import React from "react";
import PropTypes from "prop-types";

import { NewsTitleH2 } from "./style";

function NewsSubTitleSection({ data }) {
  return <NewsTitleH2>{data}</NewsTitleH2>;
}
NewsSubTitleSection.propTypes = {
  data: PropTypes.string,
};

export default NewsSubTitleSection;
