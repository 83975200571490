import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: [],
  settings: {},
  globalSearchComponents: {},
  notificationsDialogComponents: {},
  toolsDialogComponents: {},
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.components = action.payload;
    },
    setSettings(state, action) {
      state.settings = action.payload;
    },
    setGlobalSearchComponents(state, action) {
      state.globalSearchComponents = action.payload;
    },
    setNotificationsDialogComponents(state, action) {
      state.notificationsDialogComponents = action.payload;
    },
    setToolsDialogComponents(state, action) {
      state.toolsDialogComponents = action.payload;
    },
  },
});

export const homeActions = homeSlice.actions;
export default {
  name: homeSlice.name,
  reducer: homeSlice.reducer,
};
