// hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// actions
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import { fetchComponents } from "./actions";
import {
  selectBottomComponents,
  selectEventType,
  selectLeftComponents,
  selectRightComponents,
  selectTopComponents,
} from "./selectors";
// components
import DetailsHeader from "../../components/UI/event/eventDetails/header/DetailsHeader";
import Layout from "../../components/UI/event/eventDetails/Layout";
import TwoColumnContainer from "../../components/UI/event/eventDetails/twoColumnContainer/TwoColumnContainer";
import LeftColumnContainer from "../../components/UI/event/eventDetails/twoColumnContainer/leftColumnContainer/LeftColumnContainer";
import RightColumnContainer from "../../components/UI/event/eventDetails/twoColumnContainer/rightColumnContainer/RightColumnContainer";
import OthersWebinar from "../../components/UI/event/eventDetails/othersWebinar/OthersWebinar";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
import { selectAggregatedDirection } from "../MyPage/selectors";

function EventDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const settings = useSelector(
    (state) => state.eventDetails.components.settings
  );
  const url = location.search.replace(/\?path=/, "");
  const lang = useSelector((state) => state.coreComponents.language);

  useMemo(() => {
    url &&
      token &&
      dispatch(fetchComponents({ path: url, token, coreLang: lang }));
  }, [url]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(true));
    dispatch(coreComponentsActions.setIsFooterFixed(false));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, settings?.jcrTitle, url);
  const eventType = useSelector(selectEventType());
  const { status, errorData } = GetStatusAndErrorData("eventDetails");

  const topComponents = useSelector(selectTopComponents());
  const rightComponents = useSelector(selectRightComponents());
  const leftComponents = useSelector(selectLeftComponents());
  const bottomComponents = useSelector(selectBottomComponents());

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        <>
          {lang === "it" ? (
            <DetailsHeader
              headerComponents={topComponents}
              eventType={eventType}
            />
          ) : (
            <DetailsHeader
              headerComponents={topComponents}
              eventType={eventType}
              link="/en/news-and-events/events"
            />
          )}

          <Layout>
            <TwoColumnContainer>
              <LeftColumnContainer leftColumnComponents={leftComponents} />
              <RightColumnContainer rightColumnComponents={rightComponents} />
            </TwoColumnContainer>
            {eventType === "webinar" && (
              <OthersWebinar webinarComponents={bottomComponents} />
            )}
          </Layout>
        </>
      )}
    </>
  );
}

export default EventDetails;
