import i18n from "i18next";
import React from "react";
import { Wrapper, SelectItem } from "./style";
import { coreComponentsActions } from "../../../../utils/getCoreComponents/coreComponentsSlice";
import { useDispatch, useSelector } from "react-redux";

function LanguageDropDown() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.coreComponents.language);
  const ChangeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
    window.location.replace("/");
    dispatch(coreComponentsActions.setLanguage(e.target.value));
  };
  return (
    <Wrapper>
      <SelectItem tabIndex="0" aria-haspopup="listbox">
        <select
          defaultValue={language}
          onChange={(e) => ChangeLanguageHandler(e)}
        >
          <option value="it">Italiano</option>
          <option value="en">English</option>
        </select>
        <img src="/img/arrow-white.svg" />
      </SelectItem>
    </Wrapper>
  );
}

export default LanguageDropDown;
