import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTab } from "../../../../../pages/AdsList/AdsListSlice";
import { selectTabsState } from "../../../../../pages/AdsList/selectors";
import { List, ListItem } from "./style";

function Tabs() {
  const dispatch = useDispatch()
  const tabs = useSelector(selectTabsState())
  if (!tabs) return null
  const { offerTab, lookingTab, selected } = tabs
  if (!offerTab || !lookingTab) return null
  return (
    <List id="categories-dishes">
      <ListItem
        key={offerTab}
        className={selected === offerTab && "selected"}
        onClick={() => dispatch(setTab(offerTab))}
      >
        {offerTab}
      </ListItem>
      <ListItem
        key={lookingTab}
        className={selected === lookingTab && "selected"}
        onClick={() => dispatch(setTab(lookingTab))}
      >
        {lookingTab}
      </ListItem>
    </List>
  )
}

Tabs.propTypes = {
  resourceType: PropTypes.string,
  offerType: PropTypes.string,
  tabs: PropTypes.shape({
    offerTab: PropTypes.string,
    lookingTab: PropTypes.string,
    selected: PropTypes.string,
  })
};
export default Tabs;