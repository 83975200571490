import { Select, SelectWrapper, WrapperImg } from "./style";
import BottomIcon from "../../assets/bottom";
import { useState } from "react";
function CustomSelect({
  children,
  onChange,
  placeholderString,
  name = "",
  defaultValue = "",
  setValue = null,
}) {
  const [value, setStandardValue] = useState(defaultValue);
  if (setValue)
    return (
      <SelectWrapper hideLabel={!defaultValue}>
        <Select
          onChange={(e) => {
            setValue(() => e.target.value);
            onChange(e);
          }}
          name={name}
          className={!defaultValue ? "placeholder" : ""}
          value={defaultValue}
        >
          {placeholderString && !defaultValue && (
            <option disabled value="">
              {placeholderString}
            </option>
          )}
          {children}
        </Select>
        <WrapperImg>
          <BottomIcon></BottomIcon>
        </WrapperImg>
        {placeholderString && <label>{placeholderString}</label>}
      </SelectWrapper>
    );
  else {
    return (
      <SelectWrapper hideLabel={!value}>
        <Select
          onChange={(e) => {
            setStandardValue(() => e.target.value);
            onChange(e);
          }}
          name={name}
          className={!value ? "placeholder" : ""}
          defaultValue={defaultValue}
        >
          {placeholderString && !value && (
            <option disabled value="">
              {placeholderString}
            </option>
          )}
          {children}
        </Select>
        <WrapperImg>
          <BottomIcon></BottomIcon>
        </WrapperImg>
        {placeholderString && <label>{placeholderString}</label>}
      </SelectWrapper>
    );
  }
}

export default CustomSelect;
