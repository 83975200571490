import React from "react";
import SetQueryStringPath from "../../../../utils/setQueryStringPath";
import EventCard from "./EventCard";
// style
import { Wrapper } from "./style";
import { useRef, useState } from "react";
import ShowOnIntersection from "../../../../hooks/showOnIntersection";
import linkTarget from "../../../../utils/linkTarget";
import useMediaQuery from "../../../../hooks/useMediaQuery";

function SingleEventCardContainer({ item, category }) {
  const [loaded, setLoaded] = useState(0);
  const loader = useRef(null);
  return (
    <Wrapper ref={loader} style={{ opacity: loaded }}>
      {item?.path ? (
        <a
          href={SetQueryStringPath(item?.path)}
          target={linkTarget(item?.path)}
        >
          <EventCard
            item={item}
            category={category}
            loader={loader}
            setLoaded={setLoaded}
          />
        </a>
      ) : (
        <EventCard
          item={item}
          category={category}
          loader={loader}
          setLoaded={setLoaded}
        />
      )}
    </Wrapper>
  );
}

export default React.memo(SingleEventCardContainer);
