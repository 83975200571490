import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  color: #707070;
  padding: 0px;
  gap: 10px;
  overflow: auto;
  @media (max-width: 1279px) {
    max-width: 100%;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 20px;
  overflow: auto;
  img {
    width: 60px;
    margin: auto;
  }
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
