import React from "react";
import PropTypes from "prop-types";
// style
import { Wrapper } from "./style";
// components
import PersonalInfo from "./personalInfo/PersonalInfo";
import Badge from "./badge/Badge";
import Contacts from "./contacts/Contacts";
import Number from "./number/Number";

function Profile({ components: profileComponents }) {
  return (
    <Wrapper>
      <PersonalInfo data={profileComponents?.infoPersonali} />
      <Badge data={profileComponents?.badge} />
      <Contacts data={profileComponents?.recapiti} />
      <Number data={profileComponents?.recapiti} />
    </Wrapper>
  );
}

Profile.propTypes = {
  title: PropTypes.string,
  infoPersonali: PropTypes.object,
  badge: PropTypes.object,
  recapiti: PropTypes.object,
};

export default React.memo(Profile);
