import { SelectItem } from "../style";
import CustomSelectPlugin from "../../../customSelectPlugin/CustomSelectPlugin";

export default function TypeSelect({
  inputTypeChangeHandler,
  typesFilter,
  data,
}) {
  return (
    <SelectItem>
      <CustomSelectPlugin
        onChange={inputTypeChangeHandler}
        placeholderString={
          typesFilter === "allTypes"
            ? data.placeholderTypes
            : typesFilter.toUpperCase()
        }
        defaultValue={typesFilter}
        options={[{ value: "allTypes", label: data.placeholderTypes }].concat(
          data?.types?.map((v) => {
            return { value: v, label: v.toUpperCase() };
          })
        )}
      />
    </SelectItem>
  );
}
