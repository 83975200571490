import styled from "styled-components";

export const WrapperOverlay = styled.div`
  display: block;
  background: var(--main-bg);
  position: fixed;
  padding: 0px;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 10;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 100%;
`;

export const FerrariIconContainer = styled.div`
  margin-top: 2%;
  path {
    fill: var(--main-color);
  }
`;

export const FerrariIcon = styled.svg.attrs({
  width: "25",
  height: "35",
  viewBox: "0 0 63 86",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})``;

export const CloseButton = styled.button`
  border: 0px;
  cursor: pointer;
  margin-top: 40px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 70px;
  max-width: 900px;
  margin-top: 90px;
  @media (max-width: 1279px) {
    width: 90%;
    gap: 35px;
    margin-top: 30px;
  }
`;

export const SearchBox = styled.div`
  background: transparent;
  border: 1px solid #efeded;
  width: 800px;
  position: relative;
  img {
    position: absolute;
    top: 31px;
    left: 33px;
  }
  @media (max-width: 1279px) {
    width: 100%;
  }
`;

export const SearchInput = styled.input.attrs({
  type: "text",
})`
  width: calc(100% - 64px);
  border: none;
  border-bottom: solid 1px #8f8f8f;
  background: transparent;
  font-size: 16px;
  margin: 17px auto 21px;
  display: block;
  padding-left: 26px;
  box-sizing: border-box;
  color: var(--main-color);
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 40px;
`;

export const FilterButton = styled.button`
  background: transparent;
  text-transform: uppercase;
  border-radius: 4px;
  color: #8f8f8f;
  line-height: 15px;
  padding: 5px 24px 7px;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 15px;

  &.selected {
    background-color: rgb(218, 41, 28);
    border-color: transparent;
    color: #ffffff;
  }
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0px 0px 120px;
  gap: 24px;
  @media (max-width: 1279px) {
    flex-direction: column;
    > a {
      width: 100%;
    }
  }
`;

const actionButtonProperties = (isRed) => {
  if (isRed) {
    return `
      background-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, 50%, rgba(255, 255, 255, 0) 100%);
      background-color: rgb(218, 41, 28);
      background-blend-mode: overlay;
      border: none;
      color: #FFFFFF
    `;
  }
  return `
    background: transparent;
    color: #8f8f8f;
  `;
};

export const ButtonAction = styled.button`
  ${(props) => actionButtonProperties(props.isRed)};
  padding: 20px 32px 22px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  &:disabled {
    background: transparent;
    color: #8f8f8f;
  }
  @media (max-width: 1279px) {
    width: 100%;
  }
`;
