import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// style
import {
  Wrapper,
  SelectContainer,
  SelectItem,
  Title,
  SelectMobileWrapper,
} from "./style.js";
// selectors
import { selectWelfareCards } from "../../../../pages/Welfare/selectors.js";
// actions
import { welfareActions } from "../../../../pages/Welfare/welfareSlice.js";
// components
import WelfareCardList from "../welfareCardList/WelfareCardList.js";
import OffroCasaDialog from "../../dialog/offroCasa/OffroCasaDialog";
import {
  Button,
  DialogContent,
  FilterIcon,
  FilterIconPath1,
  FilterIconPath2,
  SpanFilter,
} from "../../offroCasa/homeAdsCatalogue/mobileContentHeader/style";
import CategorySelect from "./selects/CategorySelect";
import TypeSelect from "./selects/TypeSelect";

function Catalouge({ data }) {
  const dispatch = useDispatch();
  const [categoryFilter, SetCategoryFilter] = useState("allCategories");
  const [typesFilter, SetTypesFilter] = useState("allTypes");
  const welfareCards = useSelector(selectWelfareCards());
  const results = useSelector((state) => state.welfare.welfareCardList);
  const resultsLength = results.length;

  const inputCategoryChangeHandler = (event) => {
    const { value } = event;
    SetCategoryFilter(value);
    let results = welfareCards;
    if (value !== "allCategories") {
      results = results.filter((el) => el.category === value);
    }

    if (typesFilter !== "allTypes") {
      results = results.filter((el) => el.type === typesFilter);
    }
    dispatch(welfareActions.setWelfareCardList(results));
  };

  const inputTypeChangeHandler = (event) => {
    const { value } = event;
    SetTypesFilter(value);
    let results = welfareCards;

    if (categoryFilter !== "allCategories") {
      results = results.filter((el) => el.category === categoryFilter);
    }

    if (value !== "allTypes") {
      results = results.filter((el) => el.type === value);
    }
    dispatch(welfareActions.setWelfareCardList(results));
  };
  const [isDialogOpen, openDialog] = useState(false);
  return (
    <>
      <Wrapper>
        <Title>{data.title}</Title>
        <SelectContainer>
          <CategorySelect
            inputCategoryChangeHandler={inputCategoryChangeHandler}
            categoryFilter={categoryFilter}
            data={data}
          />
          <TypeSelect
            inputTypeChangeHandler={inputTypeChangeHandler}
            typesFilter={typesFilter}
            data={data}
          />
        </SelectContainer>
        <SpanFilter onClick={() => openDialog(true)}>
          <FilterIcon>
            <FilterIconPath1 />
            <FilterIconPath2 />
          </FilterIcon>
        </SpanFilter>
      </Wrapper>
      <WelfareCardList />
      <OffroCasaDialog showDialog={isDialogOpen} openDialog={openDialog}>
        <DialogContent>
          <SelectMobileWrapper>
            <CategorySelect
              inputCategoryChangeHandler={inputCategoryChangeHandler}
              categoryFilter={categoryFilter}
              data={data}
            />
            <TypeSelect
              inputTypeChangeHandler={inputTypeChangeHandler}
              typesFilter={typesFilter}
              data={data}
            />
          </SelectMobileWrapper>
          <Button primary={true} onClick={() => openDialog(false)}>
            Show {resultsLength} results
          </Button>
          <Button
            onClick={() => {
              SetCategoryFilter("allCategories");
              SetTypesFilter("allTypes");
              dispatch(welfareActions.setWelfareCardList(welfareCards));
            }}
          >
            Remove Filters
          </Button>
        </DialogContent>
      </OffroCasaDialog>
    </>
  );
}

export default Catalouge;
