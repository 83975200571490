import React from "react";
import { RulesWrapper } from "./style";
import PropTypes from "prop-types";
import CtaIcon from "../../../ctaIcon/CtaIcon";
import linkTarget from "../../../../../utils/linkTarget";

const CafeteriaRules = ({ cafeteriaRule }) => {
  return (
    <RulesWrapper>
      <div>{cafeteriaRule.title}</div>
      <p>{cafeteriaRule.description}</p>
      <a
        href={cafeteriaRule.linkHref}
        target={linkTarget(cafeteriaRule?.linkHref)}
        aria-label={cafeteriaRule.title + cafeteriaRule.linkTitle}
      >
        <span>{cafeteriaRule.linkTitle}</span>
        <CtaIcon showArrow={true} />
      </a>
    </RulesWrapper>
  );
};
CafeteriaRules.propTypes = {
  cafeteriaRule: PropTypes.exact({
    linkTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    linkHref: PropTypes.string.isRequired,
  }),
};
export default CafeteriaRules;
