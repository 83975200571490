import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
  display: block;
  @media screen and (max-width: 1279px) {
    margin-left: 0px;
    display: block;
    width: 100%;
  }
`;
export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  select {
    color: white !important;
    display: block;
    padding: 12px 35px 14px 16px;
    appearance: none;
    border: 0px;
    outline: 0px;
    background-color: transparent;
    width: 100%;
    position: relative;
    max-width: 100%;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    @media screen and (max-width: 1279px) {
      padding: 12px 0px 14px 0px;
    }
  }
  option {
    font-family: ferrari-sans-regular;
    font-size: 12px;
    padding: 12px 16px 14px;
    max-width: 100%;
    text-transform: uppercase;
  }
  img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
    pointer-events: none;
  }

  @media screen and (max-width: 1279px) {
    margin: 32px 0px 0px;
    padding-top: 32px;
    border-top: 1px solid rgb(112, 112, 112);
    width: 100%;
  }
`;
