import { ComponentContainer, TextDescr } from "../style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomSelect from "../../customSelect/CustomSelect";
import ErrorField from "./ErrorField/ErrorField";

export default function SecondaryAppointment({ data, allowed, onChange }) {
  const bodyPayload = useSelector(
    (state) => state.FormIscrizioneFamilyDay.bodyPayload
  );
  const [placeholderString, setPlaceholderString] = useState(
    data?.dateAndTimePlaceholder
  );

  const existSlots = data?.event?.appointments?.event?.find(
    (appointment) => appointment?.slots?.length > 0
  );

  useEffect(() => {
    if (!existSlots) setPlaceholderString(data?.noSlotsLabel);
    if (existSlots && bodyPayload?.secondaryAppointmentId) {
      let currentGroupSelected = data?.event?.appointments?.event?.find(
        (appointment) =>
          appointment?.slots?.find(
            (slot) => slot?.id == bodyPayload?.secondaryAppointmentId
          )
      );
      setPlaceholderString(currentGroupSelected?.title);
    }
  }, [existSlots, bodyPayload?.secondaryAppointmentId]);

  return (
    <ComponentContainer>
      <h3>{data?.dateAndTimeLbl} </h3>
      <TextDescr>{data?.dateAndTimeTxt}</TextDescr>
      <CustomSelect
        name="secondaryAppointmentId"
        defaultValue={bodyPayload?.secondaryAppointmentId || ""}
        placeholderString={placeholderString}
        onChange={onChange}
      >
        {data?.event?.appointments?.event?.map(
          (appointment, ind) =>
            appointment?.slots?.length > 0 && (
              <optgroup
                key={"eventAppointment" + ind}
                label={appointment?.title}
              >
                {appointment?.slots?.map((slot) => (
                  <option
                    className="data-evento"
                    key={slot?.id}
                    value={slot?.id}
                  >
                    {slot?.appointmentStartDate?.slice(11, 16)} -{" "}
                    {slot?.appointmentEndDate?.slice(11, 16)}
                  </option>
                ))}
              </optgroup>
            )
        )}
      </CustomSelect>
      {bodyPayload?.secondaryAppointmentId === null && allowed === false && (
        <ErrorField text={data?.errorMessage} />
      )}
    </ComponentContainer>
  );
}
