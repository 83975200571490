export default function Freccia() {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
        fill="#EFEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 28L23.2677 21.547C23.7148 21.1801 24 20.6234 24 20C24 19.3766 23.7148 18.8199 23.2677 18.4531L16 12L20 20L16 28Z"
        fill="var(--cta-color)"
      />
    </svg>
  );
}
