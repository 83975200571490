import { useSelector } from "react-redux";

export function useBusinessLogic() {
  //componenti con value e step per gestire lo sbianchamento dei controlli
  const giftHouseLigthExperienceComponents = useSelector(
    (state) => state.FormIscrizioneGiftHouse.giftHouseLeComponents
  );

  //prende in ingesso il Nome del componente e lo estrae dall'array contente value e step
  const currentElement = (x) => {
    return giftHouseLigthExperienceComponents.find((el) => el.name === x);
  };

  const nucleoFamiliare = currentElement("NucleoFamiliare");
  const Guests = currentElement("NumGuests");
  const SlotGiftHouseLe = currentElement("AppointmentIdGhAndLe");
  const GtModel = currentElement("GtModel");
  const SlotLight = currentElement("AppointmentIdLe");
  const yesLight = currentElement("lightExperience");
  const SedeLavoro = currentElement("SedeLavoro");
  const ModalitaRitiro = currentElement("modalitaRitiro");
  const AppointmentGift = currentElement("AppointmentIdGift");

  function generateHtml(argument) {
    return { __html: argument };
  }

  function parseLabel(id, stringToParse) {
    if (!stringToParse?.includes("<a")) {
      return stringToParse;
    } else {
      const splittedArray = stringToParse.split("<a");
      const firstPart = splittedArray[0].concat(
        `<a count='${id}' id='ruleLink${id}'`
      );
      const result = firstPart.concat(splittedArray[1]);
      return result;
    }
  }

  const enablePrivacyCheckbox = (e, id) => {
    const linkElem = e.target;
    linkElem.setAttribute("listener", "true");

    if (document.querySelector(id).disabled === true) {
      document.querySelector(id).disabled = false;
      document.querySelector(id).parentElement.style.color =
        "var(--main-color)";
    }
  };

  const updateCheckboxPrivacy = () => {
    document.querySelectorAll(".checkbox-input").forEach((checkbox) => {
      const rawText = checkbox.getAttribute("rawtext");
      if (!rawText.includes("<a")) {
        checkbox.disabled = false;
      }
      checkbox.disabled
        ? (checkbox.parentElement.style.color = "var(--alternative-grey-color)")
        : (checkbox.parentElement.style.color = "var(--main-color)");
    });

    document.querySelectorAll("a[count]").forEach((link) => {
      if (link.getAttribute("listener") !== "true") {
        const count = link.getAttribute("count");
        link.addEventListener("click", (e) =>
          enablePrivacyCheckbox(e, `#checkBoxPrivacy${count}`)
        );
      }
    });
  };

  const getSubscriptionDay = (startDate, endDate, lang) => {
    let result = new Date(startDate)?.toLocaleString(lang, {
      weekday: "long",
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    });
    result = `${result} - ${new Date(endDate)?.toLocaleString(lang, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const updateButtonColor = (x) => {
    if (x === "red") {
      document.querySelector(".submitButton").style = `
        color: rgb(255, 255, 255);
        background: radial-gradient(
            199.89% 50% at 50% 100%,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          radial-gradient(
            349.37% 116.89% at 50% 100%,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
          ),
          rgb(218, 41, 28);
        `;
    } else {
      document.querySelector(".submitButton").style = `
        background: #d4d2d2;
        color: #181818;
        `;
    }
    return;
  };
  const calculateAppointmentId = (data) => {
    let currentAppointment;
    if (SlotGiftHouseLe?.value !== null) {
      currentAppointment = SlotGiftHouseLe?.value;
    } else if (
      SedeLavoro?.value === "Milano" &&
      ModalitaRitiro?.value === "spedizione"
    ) {
      currentAppointment = data?.giftHouse?.appointments?.filter(
        (appointment) =>
          appointment?.configurations?.find(
            (el) =>
              el.groupName === "deliveryMode" &&
              el.fieldValue === "shipmentMilano"
          )
      )?.[0]?.id;
    } else if (
      SedeLavoro?.value === "Modena" &&
      ModalitaRitiro?.value === "spedizione"
    ) {
      currentAppointment = data?.giftHouse?.appointments?.filter(
        (appointment) =>
          appointment?.configurations?.find(
            (el) =>
              el.groupName === "deliveryMode" &&
              el.fieldValue === "shipmentModena"
          )
      )?.[0]?.id;
    } else if (
      SedeLavoro?.value !== "Maranello" &&
      ModalitaRitiro?.value === "ritiroLe"
    ) {
      currentAppointment = data?.giftHouse?.appointments?.filter(
        (appointment) =>
          appointment?.configurations?.find(
            (el) =>
              el.groupName === "deliveryMode" &&
              el.fieldValue === "afterLigthExperience"
          )
      )?.[0]?.id;
    } else {
      currentAppointment = AppointmentGift?.value;
    }
    return currentAppointment;
  };

  return {
    parseLabel,
    updateButtonColor,
    updateCheckboxPrivacy,
    generateHtml,
    getSubscriptionDay,
    calculateAppointmentId,
    giftHouseLigthExperienceComponents,
    nucleoFamiliare,
    Guests,
    SlotGiftHouseLe,
    GtModel,
    SlotLight,
    yesLight,
    SedeLavoro,
    ModalitaRitiro,
    AppointmentGift,
  };
}
