//hooks
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//utils
import { fetchComponents } from "./actions";
import { ChangeTitleDoc } from "../../utils/changeTitleDoc";
//components
import NewsTitle from "../../components/UI/news/newsTitle/NewsTitle";
import NewsFocus from "../../components/UI/news/newsFocus/NewsFocus";
import NewsCardList from "../../components/UI/news/newsCardList/NewsCardList";
import LayoutNews from "../../components/UI/news/LayoutNews";
import { coreComponentsActions } from "../../utils/getCoreComponents/coreComponentsSlice";
import GenericError from "../GenericErrorPage";
import PageLoader from "../../components/UI/pageLoader/PageLoader";
import GetStatusAndErrorData from "../../utils/getStatusAndErrorData";
import { selectAggregatedDirection } from "../MyPage/selectors";

function News({ path }) {
  const dispatch = useDispatch();
  const newsComponents = useSelector((state) => {
    return state.news.components;
  });
  const subjectCode = useSelector(
    (state) => state.login?.tokenClaims?.extension_subjectCode
  );
  const aggregatedDirection = useSelector(selectAggregatedDirection());
  const token = useSelector((state) => state.login.activeToken);
  const headerLinks = useSelector((state) => state.coreComponents.headerLinks);
  const title = headerLinks?.find((v) => v.path === path)?.title;
  const { status, errorData } = GetStatusAndErrorData("news");

  useMemo(() => {
    path && token && dispatch(fetchComponents({ path, token }));
  }, [path]);

  useEffect(() => {
    dispatch(coreComponentsActions.setWhiteHeader(false));
    dispatch(coreComponentsActions.setIsFooterFixed(true));
  }, [dispatch]);
  ChangeTitleDoc(subjectCode, aggregatedDirection, title, path);
  const componentsMapping = {
    "news/fi-news-title": NewsTitle,
    "news/fi-news-focus": NewsFocus,
    "news/fi-news-listing": NewsCardList,
  };

  return (
    <>
      {!status && <PageLoader></PageLoader>}
      {status && status !== 200 ? (
        <GenericError data={errorData} />
      ) : (
        newsComponents?.components && (
          <LayoutNews>
            {newsComponents?.components?.map((current, idx) => {
              const ReactGenericComponent =
                componentsMapping[current.resourceType];
              return (
                ReactGenericComponent && (
                  <ReactGenericComponent
                    key={`${current.resourceType}-${idx}`}
                    data={current.data}
                  ></ReactGenericComponent>
                )
              );
            })}
          </LayoutNews>
        )
      )}
    </>
  );
}

export default News;
