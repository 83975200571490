import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  components: {},
  showDialog: false,
  status: null,
  errorMessage: "",
  tags: [],
  activeTags: [],
  loading: false,
  allResultsList: {},
  filteredResultsList: {},
  filteredToShow: {},
  inputText: "",
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setComponents(state, action) {
      state.status = 200;
      state.components = action.payload;
      const titleComp = state.components.components.find(
        (el) => el.resourceType === "news/fi-news-title"
      );
      state.tags = titleComp.data.filter.categoryList;
    },
    setActiveTags(state, action) {
      state.activeTags = action.payload;
      if (state.activeTags.length > 0 && state.inputText !== "") {
        state.filteredToShow.results =
          state.filteredResultsList.results.filter((result) =>
            state.activeTags.find((tag) => tag === result.category)
          );
      } else if (state.activeTags.length > 0 && state.inputText === "") {
        state.filteredToShow.results = state.allResultsList.results.filter(
          (result) => state.activeTags.includes(result.category)
        );
      } else if (state.activeTags.length === 0 && state.inputText !== "") {
        state.filteredToShow.results = state.filteredResultsList.results;
      } else if (state.activeTags.length === 0 && state.inputText === "") {
        state.filteredToShow.results = state.allResultsList.results;
      }
    },
    setLoadingTextAPI(state, action) {
      state.loading = action.payload;
    },
    setCardListComponent(state, action) {
      state.cardListComponent = action.payload;
    },
    setAllResultsList(state, action) {
      state.allResultsList = action.payload;
      state.filteredToShow = action.payload;
      state.filteredResultsList = action.payload;
    },
    setFilteredResultsList(state, action) {
      state.filteredToShow = action.payload;
      state.filteredResultsList = action.payload;
    },
    showDialog(state, action) {
      state.showDialog = action.payload;
    },
    setInputText(state, action) {
      state.inputText = action.payload;
    },
    setError(state, action) {
      state.status = action.payload.status;
      state.errorMessage = action.payload.errorMessage;
    },
    resetState: (state) => {
      return {
        ...state,
        showDialog: false,
        activeTags: [],
        inputText: "",
        filteredResultsList: state.allResultsList,
      };
    },
  },
});

export const newsActions = newsSlice.actions;
export default {
  name: newsSlice.name,
  reducer: newsSlice.reducer,
};
