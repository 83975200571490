import { SelectItem } from "../style";
import CustomSelectPlugin from "../../../customSelectPlugin/CustomSelectPlugin";

export default function CategorySelect({
  inputCategoryChangeHandler,
  categoryFilter,
  data,
}) {
  return (
    <SelectItem>
      <CustomSelectPlugin
        hideLabel={true}
        onChange={inputCategoryChangeHandler}
        placeholderString={
          categoryFilter === "allCategories"
            ? data.placeholderCategories
            : categoryFilter.toUpperCase()
        }
        defaultValue={categoryFilter}
        options={[
          { value: "allCategories", label: data.placeholderCategories },
        ].concat(
          data?.categories?.map((v) => {
            return { value: v, label: v.toUpperCase() };
          })
        )}
      />
    </SelectItem>
  );
}
