import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "../OfferDetail/offerDetailSlice";

const offerDetailState = (state) => state.offerDetail || initialState;

export const selectTopComponents = () =>
  createSelector(offerDetailState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "top"
      );
    }
  });

export const selectRightComponents = () =>
  createSelector(offerDetailState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "right"
      );
    }
  });

export const selectLeftComponents = () =>
  createSelector(offerDetailState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "left"
      );
    }
  });

export const selectBottomComponents = () =>
  createSelector(offerDetailState, (state) => {
    if (state.components.components) {
      return state.components.components.filter(
        (element) => element.data.position === "bottom"
      );
    }
  });

export const selectContactCard = () =>
  createSelector(offerDetailState, (state) => {
    if (state.components.components) {
      const contact = state.components.components.find(
        (element) => element.resourceType === "fi-contact-recap"
      );
      return contact.data.detail;
    }
  });
