/* eslint-disable react/style-prop-object */
export default function EventIconRedPriority() {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.0802 15.3495C39.3784 19.2443 39.3108 23.465 37.8887 27.3162C36.4666 31.1674 33.7749 34.4192 30.257 36.5355C26.7392 38.6519 22.6053 39.5066 18.5367 38.9587C14.4681 38.4108 10.7075 36.4931 7.87481 33.5216C5.04213 30.5501 3.30638 26.7021 2.95366 22.6119C2.60093 18.5218 3.65228 14.4335 5.93442 11.0209C8.21656 7.60825 11.5933 5.07502 15.5081 3.83868C19.4228 2.60234 23.642 2.73667 27.4702 4.21955C27.8342 3.61527 28.2708 3.0578 28.7702 2.55955C24.5093 0.739975 19.7513 0.445156 15.2983 1.72479C10.8453 3.00442 6.96972 5.78024 4.32482 9.58432C1.67992 13.3884 0.427489 17.9881 0.77866 22.6079C1.12983 27.2278 3.06312 31.5853 6.25263 34.9459C9.44214 38.3064 13.6928 40.4646 18.288 41.0564C22.8833 41.6483 27.542 40.6376 31.479 38.1949C35.4159 35.7522 38.3903 32.0268 39.9006 27.6467C41.4109 23.2666 41.3649 18.4997 39.7702 14.1495C39.2607 14.6204 38.6927 15.0237 38.0802 15.3495Z"
        fill="#DA291C"
      />
      <path
        d="M38.0802 15.3495C39.3784 19.2443 39.3108 23.465 37.8887 27.3162C36.4666 31.1674 33.7749 34.4192 30.257 36.5355C26.7392 38.6519 22.6053 39.5066 18.5367 38.9587C14.4681 38.4108 10.7075 36.4931 7.87481 33.5216C5.04213 30.5501 3.30638 26.7021 2.95366 22.6119C2.60093 18.5218 3.65228 14.4335 5.93442 11.0209C8.21656 7.60825 11.5933 5.07502 15.5081 3.83868C19.4228 2.60234 23.642 2.73667 27.4702 4.21955C27.8342 3.61527 28.2708 3.0578 28.7702 2.55955C24.5093 0.739975 19.7513 0.445156 15.2983 1.72479C10.8453 3.00442 6.96972 5.78024 4.32482 9.58432C1.67992 13.3884 0.427489 17.9881 0.77866 22.6079C1.12983 27.2278 3.06312 31.5853 6.25263 34.9459C9.44214 38.3064 13.6928 40.4646 18.288 41.0564C22.8833 41.6483 27.542 40.6376 31.479 38.1949C35.4159 35.7522 38.3903 32.0268 39.9006 27.6467C41.4109 23.2666 41.3649 18.4997 39.7702 14.1495C39.2607 14.6204 38.6927 15.0237 38.0802 15.3495Z"
        fill="url(#paint0_radial_14153_43702)"
        fillOpacity="0.5"
        style={{ mixBlendMode: "overlay" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4107 11.1795C39.0478 10.3421 39.3985 9.32175 39.4107 8.26954C39.4107 6.94345 38.8839 5.67168 37.9462 4.734C37.0085 3.79632 35.7368 3.26954 34.4107 3.26954C33.4429 3.26827 32.4965 3.5536 31.6907 4.08954C34.4823 5.86108 36.7911 8.29708 38.4107 11.1795ZM30.1806 5.54956C29.5896 6.49312 29.3304 7.60695 29.4441 8.71451C29.5579 9.82206 30.038 10.86 30.8085 11.6637C31.5789 12.4675 32.5956 12.9911 33.6973 13.1515C34.7991 13.312 35.9229 13.1002 36.8906 12.5496C35.3364 9.64637 33.0155 7.22518 30.1806 5.54956ZM31.6407 4.08954C34.4506 5.85465 36.7771 8.29118 38.4107 11.1795C38.0061 11.7381 37.4881 12.2049 36.8907 12.5495C35.3364 9.64635 33.0155 7.22515 30.1807 5.54954C30.5605 4.96659 31.0577 4.46933 31.6407 4.08954Z"
        fill="black"
      />
      <circle cx="34.4004" cy="8.19922" r="5" fill="#DA291C" />
      <g clipPath="url(#clip0_17170_27267)">
        <path
          d="M27.0003 15.666C27.7367 15.666 28.3337 16.263 28.3337 16.9993V27.666C28.3337 28.4024 27.7367 28.9993 27.0003 28.9993H15.0003C14.2639 28.9993 13.667 28.4024 13.667 27.666V16.9993C13.667 16.263 14.2639 15.666 15.0003 15.666H27.0003ZM27.0003 16.9993H15.0003V27.666H27.0003V16.9993Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3333 13V15.6667H17V14.3333C17 13.597 17.597 13 18.3333 13Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0003 13V15.6667H23.667V14.3333C23.667 13.597 24.2639 13 25.0003 13Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.999 21.668H18.999C19.7354 21.668 20.3324 22.2649 20.3324 23.0013H16.999V21.668Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.999 24.334H18.999C19.7354 24.334 20.3324 24.9309 20.3324 25.6673H16.999V24.334Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.666 21.668H23.666C24.4024 21.668 24.9993 22.2649 24.9993 23.0013H21.666V21.668Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.666 24.334H23.666C24.4024 24.334 24.9993 24.9309 24.9993 25.6673H21.666V24.334Z"
          fill="var(--main-color)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.9316 19.0541V19.6673L14.3316 19.6673V18.334L27.2115 18.334C27.6092 18.334 27.9316 18.6564 27.9316 19.0541Z"
          fill="var(--main-color)"
        />
      </g>
      <defs>
        <clipPath id="clip0_17170_27267">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
