import {
  takeLatest,
  put,
  select,
  // call
} from "redux-saga/effects";
import {
  axiosCallDelete,
  axiosCallGet,
  axiosCallPost,
} from "../../utils/axiosCalls/AxiosCalls";

import {
  FETCH_COMPONENTS,
  POST_DEFAULT_NUMBER,
  POST_CONTACTS,
  DELETE_SUBSCRIPTION,
} from "./constants";

import { myPageActions } from "./myPageSlice";
import {
  selectMyEventComponent,
  selectIsEcardActive,
  selectMyPageComponent,
} from "./selectors";
import { useSelector } from "react-redux";
// import axios from "axios";

function* fetchMyPageComponents({ payload }) {
  try {
    const result = yield axiosCallGet(payload.path, payload.token);
    // const result = yield call(() =>
    //   axios.get("/mocks/mock-my-page.json")
    // );
    yield put(myPageActions.setComponents(result.data.data.components));
    yield put(myPageActions.setSettings(result.data.data.settings));

    const myPageComponent = yield select(selectMyPageComponent());
    const myEventComponent = yield select(selectMyEventComponent());
    const isEcardActive = yield select(selectIsEcardActive());
    const isEcardTabActive = myPageComponent?.ecard.showTab;

    let pickedComponents = (({ profilo, famiglia, ecard }) => ({
      profilo,
      famiglia,
      ecard: isEcardTabActive ? ecard : {},
    }))(myPageComponent);

    pickedComponents = {
      profilo: pickedComponents.profilo,
      famiglia: pickedComponents.famiglia,
      eventi: myEventComponent,
      ecard: pickedComponents.ecard,
    };

    const tabsArray = Object.entries(pickedComponents).map(([_, value], i) => {
      return { id: i + 1, components: value };
    });

    yield put(myPageActions.setTabsArray(tabsArray));
    if (isEcardActive) {
      yield put(myPageActions.setTabIndex(4));
    }
  } catch (e) {
    console.error(e);
    yield put(myPageActions.setError(e));
  }
}

function* postDefaultNumber({ payload }) {
  try {
    yield axiosCallPost(
      "/contact-details",
      { defaultNumber: payload.defaultNumber },
      payload.token,
      true
    );
  } catch (e) {
    console.log(e);
  }
}

function* postContacts({ payload }) {
  try {
    if (Object.keys(payload.postBody).length !== 0) {
      yield axiosCallPost(
        "/contact-details",
        payload.postBody,
        payload.token,
        true
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteSubscription({ payload }) {
  try {
    yield axiosCallDelete(
      `/${payload.lang}/news-and-events/events/subscribe?guid=${payload?.guid}`,
      payload.token
    );
    const events = yield select(selectMyEventComponent());
    yield put(
      myPageActions.deleteEventSubscription({
        events: events,
        guid: payload?.guid,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* myPageSaga() {
  yield takeLatest(FETCH_COMPONENTS, fetchMyPageComponents);
  yield takeLatest(POST_DEFAULT_NUMBER, postDefaultNumber);
  yield takeLatest(POST_CONTACTS, postContacts);
  yield takeLatest(DELETE_SUBSCRIPTION, deleteSubscription);
}
